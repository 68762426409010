export const MainAnchorId = "main";

export const BookmarkStyle = {
  height: 20,
  paddingTop: 8,
};

export const FilterAll = "all";

export const ColorTextPrimary = "#292827";
export const ColorTextSecondary = "#646464";

export const MetaItemGap = 5;
