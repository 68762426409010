import * as React from "react";
import {
  DirectionalHint,
  FocusTrapCallout,
  ICalloutProps,
  IconButton,
  mergeStyles,
  registerIcons,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { Icon } from "@fluentui/react/lib/Icon";
import { ITooltipHost, ITooltipHostProps, ITooltipHostStyles } from "@fluentui/react/lib/Tooltip";
import * as Messages from "../../codegen/Messages";
import { Required } from "../Svg/icons";

// ***********************************************************************************************
// The LabelInfoTooltip component renders the tooltip on hovering over the label info icon
// -----------------------------------------------------------------------------------------------
//
// Follow the Azure portal model:
//
//   * Using the mouse
//     - When the user moves the mouse over (hovers) the tooltip icon, the tooltip callout shows
//       and remains visible as long as the mouse is placed over the icon.
//     - When the user moves the mouse away from the tooltip icon (do not move into the callout),
//       the callout hides after a short delay
//     - When the user moves the mouse away from the tooltip icon and move into the callout,
//       + The callout will remain visible as long as the mouse is placed over it.
//       + Once the user moves the mouse away from the tooltip callout, it will hide after a short
//         delay.
//
//   * Using the keyboard
//     - Pressing TAB to navigate to the tooltip icon will not show the tooltip callout
//       automatically. The user must press ENTER/SPACE to open the callout.
//     - Pressing ENTER/SPACE while toggle the hovering behavior (disable/enable).
//       + When hovering behavior is disabled, a visible callout will remain open until dismissed
//         using keys or clicking outside of the tooltip icon.
//       + When hovering behavior is enabled, a visible callout will close after a delay.
//       + Once the tooltip callout is dismissed, the hovering behavior is re-enabled.
//     - Pressing ESC while the tooltip callout is visible will hide it without moving the focus
//       from the tooltip icon and will enable the hovering behavior.
//     - Pressing TAB while the tooltip callout is open:
//       + If the tooltip content has no links, the focus will move to the next focusable element
//         after the tooltip icon and the callout will hide.
//       + If the tooltip content contains links, the focus will move to the first link and the
//         callout will remain open.
//     - Pressing TAB while the focus is on a tooltip link, if this is the last link,
//       then the focus will move to the next focusable element after the tooltip icon and the
//       callout will hide.
//     - Pressing SHIFT+TAB while the focus is on a tooltip link, if this is the first link,
//       then the focus will move to the tooltip icon and the callout will hide.
// ***********************************************************************************************

// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline", cursor: "pointer" } };

const infoIconBtnStyles = {
  root: {
    color: "rgb(50, 49, 48)",
    width: "14px",
    height: "14px",
    marginLeft: "5px",
    verticalAlign: "middle",
    ":focus-visible": {
      border: "rgb(96, 94, 92) solid 1px",
      "::after": { outline: "none !important" },
    },
  },
  rootHovered: {
    backgroundColor: "unset",
    color: "unset",
  },
  rootPressed: {
    backgroundColor: "unset",
    color: "unset",
  },
};

export interface LabelInfoTooltipProps {
  /**
   * Text to display as Tooltip
   */
  tooltip?: string;
}

export const LabelInfoTooltip = ({ tooltip }: LabelInfoTooltipProps): JSX.Element => {
  const tooltipHostRef = React.useRef<ITooltipHost>(null);
  const tooltipId = useId("tooltip");

  const [tooltipFocusable, setTooltipFocusable] = React.useState<boolean>(false);
  const [enableMouseHovering, setEnableMouseHovering] = React.useState<boolean>(true);

  React.useEffect(() => {
    // Search the tooltip content for any focusable links to adjust the keyboard navigation accordingly
    const tooltipHost = document.getElementById(tooltipId);
    const focusableElems: NodeListOf<HTMLElement> | undefined = tooltipHost?.querySelectorAll(
      // eslint-disable-next-line max-len
      "a[href]",
    );
    setTooltipFocusable(!!(focusableElems && focusableElems.length > 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (): void => {
    // Clicking on the tooltip icon should toggle the hovering behavior
    // and as a result show/hide tooltip callout accordingly
    if (enableMouseHovering) {
      setEnableMouseHovering(false);
      tooltipHostRef.current?.show();
    } else {
      setEnableMouseHovering(true);
      tooltipHostRef.current?.dismiss();
    }
  };

  const onKeyDown = (ev: React.KeyboardEvent<HTMLButtonElement>): void => {
    // console.log(`++++ ${ev.key} ${ev.shiftKey}`);

    // Pressing ESC while the tooltip callout is visible should hide it.
    // Pressing TAB key while tooltip callout is visible:
    // - Should hide it if its content has no focusable links and focus move to next focusable element
    //   after the tooltip icon.
    // - If its content has focusable links, the focus should move to the first link (see CustomTooltip)
    // Pressing SHIFT+TAB key while tooltip callout is visible:
    // - Should hide it and focus should move to focusable element before the tooltip icon.
    // Enable hovering behavior since the tooltip callout has been dismissed.
    if (ev.key === "Escape"
      || (ev.key === "Tab" && !tooltipFocusable)
      || (ev.key === "Tab" && ev.shiftKey)) {
      tooltipHostRef.current?.dismiss();
      setEnableMouseHovering(true);
    }
  };

  return (
    <CustomTooltipHost
      componentRef={tooltipHostRef}
      content={tooltip}
      // This id is used on the tooltip itself, not the host
      // (so an element with this id only exists when the tooltip is shown)
      id={tooltipId}
      onTooltipToggle={isVisible => {
        if (!isVisible) {
          // Enable hovering behavior since the tooltip callout has been dismissed.
          setEnableMouseHovering(true);
        }
      }}
      styles={hostStyles}
      closeDelay={1000} // Enough time for the user to navigate to the tooltip callout before it closes
      enableMouseHovering={enableMouseHovering}
    >
      <IconButton
        iconProps={{ iconName: "Info", styles: { root: { fontSize: "13px" } } }}
        styles={infoIconBtnStyles}
        aria-describedby={tooltipId}
        aria-label={Messages.ariaLabel.info()}
        onClick={onClick}
        onKeyDown={onKeyDown}
      />
    </CustomTooltipHost>
  );
};

/**
 * CustomTooltipHost replacing fluent TooltipHost
 */
const hiddenContentStyle: React.CSSProperties = {
  position: "absolute",
  width: 1,
  height: 1,
  margin: -1,
  padding: 0,
  border: 0,
  overflow: "hidden",
  whiteSpace: "nowrap",
};

interface CustomTooltipHostProps extends Pick<ITooltipHostProps,
"id"
| "children"
| "componentRef"
| "content"
| "calloutProps"
| "onTooltipToggle"
| "styles"
| "closeDelay"
> {
  enableMouseHovering: boolean;
}

const CustomTooltipHost = ({
  id,
  componentRef,
  children,
  content,
  calloutProps,
  onTooltipToggle,
  styles,
  closeDelay,
  enableMouseHovering,
}: CustomTooltipHostProps): JSX.Element => {
  const tooltipId = id;
  const tooltipHostRef = React.useRef<HTMLDivElement>(null);
  const tooltipHostStyles = (styles as Partial<ITooltipHostStyles>).root as React.CSSProperties;

  const [isTooltipVisible, setTooltipVisible] = React.useState<boolean>(false);
  const tooltipTimeoutId = React.useRef<number | undefined>(undefined);

  const renderTooltip = (): void => setTooltipVisible(true);
  const removeTooltip = (): void => setTooltipVisible(false);

  const cancelTimeout = (): void => {
    if (tooltipTimeoutId.current) {
      window.clearTimeout(tooltipTimeoutId.current);
      tooltipTimeoutId.current = undefined;
    }
  };

  const onMouseEnter = (): void => {
    // When the mouse is on the tooltip icon, show the tooltip callout
    // and clear any timeout set to hide the callout.
    cancelTimeout();
    renderTooltip();
  };

  const onMouseLeave = (): void => {
    // If a closing delay has been specified, hide the tooltip after the delay
    // Otherwise hide it right away.
    if (closeDelay) {
      tooltipTimeoutId.current = window.setTimeout(() => {
        removeTooltip();
      }, closeDelay);
    } else {
      removeTooltip();
    }
  };

  const onMouseEnterIcon = React.useCallback((): void => {
    // console.log(">>> Icon: Mouse entered");
    onMouseEnter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMouseLeaveIcon = React.useCallback((): void => {
    // console.log("<<< Icon: Mouse left");
    onMouseLeave();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If hovering behavior is disabled then cancel any pending timeout to avoid dismissing the tooltip callout
  if (!enableMouseHovering) {
    cancelTimeout();
  }

  React.useEffect(() => {
    if (componentRef) {
      const comp = {
        show: renderTooltip,
        dismiss: removeTooltip,
      } as ITooltipHost;

      if (typeof componentRef === "object") {
        (componentRef as { current: ITooltipHost | null }).current = comp;
      } else if (typeof componentRef === "function") {
        componentRef(comp);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Only if the hovering behavior is enebaled, add handlers for the mouse enter/leave events
    // to track when the mouse is on the tooltip icon and show/hide tooltip callout accordingly
    const ref = tooltipHostRef.current;
    if (ref && enableMouseHovering) {
      ref.addEventListener("mouseenter", onMouseEnterIcon);
      ref.addEventListener("mouseleave", onMouseLeaveIcon);
    }

    // Cleanup mouse enter/leave handlers
    return () => {
      ref?.removeEventListener("mouseenter", onMouseEnterIcon);
      ref?.removeEventListener("mouseleave", onMouseLeaveIcon);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableMouseHovering]);

  React.useEffect(() => {
    onTooltipToggle?.(isTooltipVisible);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTooltipVisible]);

  // Props passed to the CustomTooltip
  const tooltipRenderProps: CustomTooltipProps = {
    id: `${tooltipId}--tooltip`,
    content,
    enableMouseHovering,
    targetElement: tooltipHostRef.current || undefined,
    calloutProps,
    onMouseEnter,
    onMouseLeave,
    removeTooltip,
  };

  // Get the content of the tooltip for use in the hidden div used for screen readers
  const tooltipContent = content;
  const showTooltip = isTooltipVisible && !!tooltipContent;

  return (
    <div
      style={tooltipHostStyles}
      ref={tooltipHostRef}
      role="none"
    >
      {children}
      {showTooltip && <CustomTooltip {...tooltipRenderProps} />}
      <div hidden id={tooltipId} style={hiddenContentStyle as React.CSSProperties}>
        {tooltipContent}
      </div>
    </div>
  );
};

/**
 * CustomTooltip replacing fluent Tooltip
 */
const rootStyles: React.CSSProperties = {
  fontSize: "14px",
  fontWeight: "400",
  background: "rbg(255, 255, 255)",
  padding: "8px",
  maxWidth: "364px",
};

const contentStyles: React.CSSProperties = {
  fontSize: "12px",
  fontWeight: "400",
  position: "relative",
  zIndex: 1,
  color: "rgb(50, 49, 48)",
  wordWrap: "break-word",
  overflowWrap: "break-word",
  overflow: "hidden",
};

const subTextStyles: React.CSSProperties = {
  fontSize: "inherit",
  fontWeight: "inherit",
  color: "inherit",
  margin: 0,
};
interface CustomTooltipProps {
  id: string;
  targetElement?: HTMLElement;
  calloutProps?: ICalloutProps;
  content?: string | JSX.Element | JSX.Element[];
  enableMouseHovering: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  removeTooltip: () => void;
}

const CustomTooltip = ({
  id,
  targetElement,
  calloutProps,
  content,
  enableMouseHovering,
  onMouseEnter,
  onMouseLeave,
  removeTooltip,
}: CustomTooltipProps): JSX.Element => {
  const contentRef = React.useRef<HTMLDivElement>(null);

  const [isPositioned, setIsPositioned] = React.useState<boolean>(false);

  const onMouseEnterTooltip = React.useCallback((): void => {
    // console.log(">>> Tooltip: Mouse entered");
    onMouseEnter();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMouseLeaveTooltip = React.useCallback((): void => {
    // console.log("<<< Tooltip: Mouse left");
    onMouseLeave();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabOutTooltip = React.useCallback((ev): void => {
    // console.log(`>>>> ${ev.key} ${ev.shiftKey}`);

    // - When pressing TAB when focus is on the last tooltip link, hide the tooltip callout:
    //   + The focus will move back to the tooltip icon.
    // - When pressing SHIFT+TAB while focus is on the first tooltip link, hide the tooltip callout:
    //   + The focus will move back to the tooltip icon.
    // - Must stop propagation of the key pressed to avoid moving the focus to the item before/after the icon.
    if (ev.key === "Tab") {
      removeTooltip();
      ev.preventDefault();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isPositioned && enableMouseHovering) {
      // Once the callout is positioned (i.e. visible) and
      // only if the hovering behavior is enebaled, the mouse enter/leave handlers
      // are added to control when to hide the callout (when the mouse leave)
      // and when to keep showing it (when the mouse enter)
      const tooltipElem = document.getElementById(id);
      tooltipElem?.addEventListener("mouseenter", onMouseEnterTooltip);
      tooltipElem?.addEventListener("mouseleave", onMouseLeaveTooltip);
    }

    // Cleanup mouse enter/leave handlers
    return () => {
      const tooltipElem = document.getElementById(id);
      tooltipElem?.removeEventListener("mouseenter", onMouseEnterTooltip);
      tooltipElem?.removeEventListener("mouseleave", onMouseLeaveTooltip);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPositioned, enableMouseHovering]);

  React.useEffect(() => {
    // Search the tooltip content for any focusable links to adjust the keyboard navigation accordingly
    const focusableElems = contentRef.current?.querySelectorAll(
      "a[href]",
    );
    // Add keydown handlers to adjust the keyboard navigation to follow the Azure portal model
    // Use keydown for TAB and SHIFT+TAB keys' associated logic and attach them to the first
    // and last links
    const lastFocusableElem = focusableElems?.[focusableElems.length - 1];
    lastFocusableElem?.addEventListener("keydown", onTabOutTooltip);
    const firstFocusableElem = focusableElems?.[0];
    firstFocusableElem?.addEventListener("keydown", onTabOutTooltip);

    // Cleanup keydown handlers
    return () => {
      lastFocusableElem?.removeEventListener("keydown", onTabOutTooltip);
      firstFocusableElem?.removeEventListener("keydown", onTabOutTooltip);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPositioned]);

  const onRenderContent = (tooltipContent?: string | JSX.Element | JSX.Element[]): JSX.Element => {
    if (typeof tooltipContent === "string") {
      return <p style={subTextStyles}>{tooltipContent}</p>;
    }
    return <div style={subTextStyles}>{tooltipContent}</div>;
  };

  // Use FocusTrapCallout to isolate the navigation inside the content from the rest of the document
  // Using a regular Callout will end up breaking the visual order of navigation since the callout is
  // rendered on a separate layer inserted at the end of the HTML document and as a result navigating
  // out of the callout will set the focus to the brower's top action buttons.
  return (
    <FocusTrapCallout
      id={id}
      target={targetElement}
      directionalHint={DirectionalHint.topCenter}
      beakWidth={16}
      gapSpace={0}
      doNotLayer={false}
      onPositioned={() => {
        setIsPositioned(true);
      }}
      onDismiss={() => {
        // console.log("<<< Tooltip dismissed >>>>");
        removeTooltip();
      }}
      {...calloutProps}
      style={rootStyles}
      focusTrapProps={{
        // Do not set focus automatically to the first focusable item
        disableFirstFocus: true,
        // Allow clicking outside the callout to dismiss it
        isClickableOutsideFocusTrap: true,
      }}
    >
      <div ref={contentRef} style={contentStyles}>
        {onRenderContent(content)}
      </div>
    </FocusTrapCallout>
  );
};

// ***********************************************************************************************
// The RequiredTooltip component renders the red asterisk that identifies that a field is mandatory
// ***********************************************************************************************

registerIcons({ icons: { "required-svg": Required } });

const required = mergeStyles({
  fontSize: 15,
  verticalAlign: "super",
  marginLeft: "3px",
  marginRight: "3px",
  lineHeight: 0,
});

export const RequiredTooltip = (): JSX.Element => {
  const tooltipId = useId("tooltip");
  return (
    <span
      aria-describedby={tooltipId}
      aria-label={Messages.ariaLabel.required()}
      className={required}
    >
      <Icon iconName="required-svg" />
    </span>
  );
};
