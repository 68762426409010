// @ts-nocheck
/****************************************************************
 * WARNING THIS FILE IS AUTOGENERATED AND SHOULD NOT BE MODIFIED *
 ****************************************************************/

// tslint:disable
import { LoomI18n, Currency } from "loom-i18n";
import enLocaleData from "loom-i18n/dist/locale-data/en";
import arLocaleData from "loom-i18n/dist/locale-data/ar";
import csLocaleData from "loom-i18n/dist/locale-data/cs";
import daLocaleData from "loom-i18n/dist/locale-data/da";
import deLocaleData from "loom-i18n/dist/locale-data/de";
import elLocaleData from "loom-i18n/dist/locale-data/el";
import esLocaleData from "loom-i18n/dist/locale-data/es";
import fiLocaleData from "loom-i18n/dist/locale-data/fi";
import frLocaleData from "loom-i18n/dist/locale-data/fr";
import hrLocaleData from "loom-i18n/dist/locale-data/hr";
import huLocaleData from "loom-i18n/dist/locale-data/hu";
import itLocaleData from "loom-i18n/dist/locale-data/it";
import jaLocaleData from "loom-i18n/dist/locale-data/ja";
import koLocaleData from "loom-i18n/dist/locale-data/ko";
import nlLocaleData from "loom-i18n/dist/locale-data/nl";
import noLocaleData from "loom-i18n/dist/locale-data/no";
import plLocaleData from "loom-i18n/dist/locale-data/pl";
import ptLocaleData from "loom-i18n/dist/locale-data/pt";
import roLocaleData from "loom-i18n/dist/locale-data/ro";
import ruLocaleData from "loom-i18n/dist/locale-data/ru";
import skLocaleData from "loom-i18n/dist/locale-data/sk";
import slLocaleData from "loom-i18n/dist/locale-data/sl";
import srLocaleData from "loom-i18n/dist/locale-data/sr";
import svLocaleData from "loom-i18n/dist/locale-data/sv";
import thLocaleData from "loom-i18n/dist/locale-data/th";
import trLocaleData from "loom-i18n/dist/locale-data/tr";
import zhLocaleData from "loom-i18n/dist/locale-data/zh";

export const bundled: { [language: string]: any } = {};
bundled["en"] = {
  actions: {
    add: "Add",
    addColumn: "Add column",
    addFilter: "Add filter",
    addTags: "Click here to add tags",
    apply: "Apply",
    clearFilters: "Clear filters",
    copiedToClipboard: "Copied",
    copyToClipboard: "Copy to clipboard",
    create: "Create",
    delete: "Delete",
    edit: "Edit",
    editTag: "Edit tag",
    moveDown: "Move down",
    moveUp: "Move up",
    next: "Next",
    previous: "Previous",
    resetToDefaults: "Reset to defaults",
    reviewCreate: "Review + create",
    selectAll: "Select all",
    selectFile: "Select a file",
    view: "View",
  },
  ariaLabel: {
    actionMenu: "Click to open context menu",
    actionMenuHeader: "Context menu column",
    close: "close",
    collapse: "Collapse",
    datePicker: "Select a date",
    delete: "Delete",
    dismissNotification: "Dismiss notification",
    expand: "Expand",
    fileUpload: "Upload a file",
    groupBy: "Group by",
    info: "Info",
    more: "More",
    nextMonth: "Next month",
    page: "Page",
    previousMonth: "Previous month",
    required: "Required",
    resourceList: "Resource list",
    selectItem: "Select item",
    sliderInput: "Slider input",
    switchView: "Switch between a list view or a summary view of data",
    timePicker: "Select a time",
  },
  common: {
    addressesCount: "({count} addresses)",
    all: "All",
    cancel: "Cancel",
    confirm: "Confirm",
    contains: "Contains",
    discard: "Discard",
    equals: "Equals",
    error: "Error",
    errorLoadingData: "Error loading data",
    filter: "Filter",
    lastViewed: "Last Viewed",
    less: "Less",
    loading: "Loading...",
    more: "More ({count})",
    name: "Name",
    no: "No",
    ok: "OK",
    operator: "Operator",
    required: "Required",
    success: "Success",
    tag: "Tag",
    tags: "Tags",
    type: "Type",
    value: "Value",
    yes: "Yes",
  },
  duration: {
    daysLong: "days",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "hours",
    hoursShort: "hr",
    hoursSymbol: "h",
    millisecondsLong: "milliseconds",
    millisecondsShort: "msec",
    millisecondsSymbol: "ms",
    minutesLong: "minutes",
    minutesShort: "min",
    minutesSymbol: "m",
    secondsLong: "seconds",
    secondsShort: "sec",
    secondsSymbol: "s",
  },
  hints: {
    addToFavorites: "Add resource to favorites",
    filterAnyField: "Filter for any field...",
    filterPlaceholder: "Filter...",
    noResultsFound: "No results were found.",
    removeFromFavorites: "Remove resource from favorites",
    requiredField: "Required field",
    searchForField: "Search for {fieldName}",
    searchPlaceholder: "Search",
    selectAnOption: "Select an option",
    selectExisting: "Select existing...",
    showSelection: "{count} selected",
  },
  labels: {
    addresses: "Addresses",
    essentials: "Essentials",
    noGrouping: "No grouping",
    tags: "Tags",
  },
  listTemplate: {
    learnMore: "Learn more",
    none: "None",
    showing: "Showing {start} to {end} of {total} records.",
    showingAll: "Showing {total} records.",
  },
  notifications: {
    apiErrorMsg:
      "Additional details from the underlying API that might be helpful: {errMsg}",
    pollingErrorMessage: "Failed to check operation status.",
    pollingErrorTitle: "Failed to check",
  },
  pagination: {
    next: "Next",
    of: "of {totalNumberOfPages}",
    page: "Page",
    previous: "Previous",
  },
  status: {
    accepted: "Accepted",
    actionRequired: "Action required",
    active: "Active",
    applied: "Applied",
    applying: "Applying",
    assigned: "Assigned",
    assigning: "Assigning",
    attached: "Attached",
    attaching: "Attaching",
    available: "Available",
    availableNeedsAttention: "Available needs attention",
    backupInProgress: "Backup in progress",
    blocked: "Blocked",
    canceled: "Canceled",
    canceling: "Canceling",
    cancelled: "Cancelled",
    cancelledReturned: "Cancelled returned",
    checking: "Checking",
    closed: "Closed",
    complete: "Complete",
    completed: "Completed",
    converting: "Converting",
    created: "Created",
    creating: "Creating",
    creatingImage: "Creating image",
    deleted: "Deleted",
    deleting: "Deleting",
    detaching: "Detaching",
    disabled: "Disabled",
    disabling: "Disabling",
    down: "Down",
    downForMaintenance: "Down for maintenance",
    enabled: "Enabled",
    enabling: "Enabling",
    error: "Error",
    expired: "Expired",
    exporting: "Exporting",
    failed: "Failed",
    failedApply: "Failed apply",
    failedRollback: "Failed rollback",
    faulty: "Faulty",
    importing: "Importing",
    inactive: "Inactive",
    initiated: "Initiated",
    inProgress: "In progress",
    internalError: "Internal error",
    loading: "Loading",
    maintenanceInProgress: "Maintenance in progress",
    missing: "Missing",
    packaged: "Packaged",
    pendingCustomer: "Pending customer",
    pendingProvider: "Pending provider",
    pendingProvisioning: "Pending provisioning",
    preparing: "Preparing",
    processed: "Processed",
    processing: "Processing",
    provisioned: "Provisioned",
    provisioning: "Provisioning",
    queued: "Queued",
    ready: "Ready",
    received: "Received",
    related: "Related",
    requestReceived: "Request received",
    restoreFailed: "Restore failed",
    restoreInProgress: "Restore in progress",
    restoring: "Restoring",
    returned: "Returned",
    rollingBack: "Rolling back",
    running: "Running",
    scaleInProgress: "Scale in progress",
    shipping: "Shipping",
    starting: "Starting",
    stopped: "Stopped",
    stopping: "Stopping",
    succeeded: "Succeeded",
    terminated: "Terminated",
    terminating: "Terminating",
    up: "Up",
    unassigned: "Unassigned",
    unassigning: "Unassigning",
    unavailable: "Unavailable",
    updated: "Updated",
    updating: "Updating",
    upgrading: "Upgrading",
    waiting: "Waiting",
    warning: "Warning",
  },
  tags: {
    less: "Less",
    more: "More ({count})",
  },
  validation: {
    addressInvalid:
      "Invalid address: 'Malformed IP address or CIDR block: {address}'.",
    addressOverlap: "Invalid address: '{address1} overlaps {address2}'.",
    addressReserved: "Invalid address: '{address} is reserved'.",
    addressReservedRange:
      "Invalid address: '{address} is within reserved range {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Invalid address: '{cidr}' contains reserved address {address}'.",
    cidrHostBitsSet: "Invalid address: 'CIDR cannot have the host bits set'.",
    cidrInvalid: "Invalid address: 'Malformed CIDR block: {cidr}'.",
    cidrPrefixInvalid:
      "Invalid address: 'CIDR prefix must be between {min} and {max} inclusive'.",
    inputRange:
      "The value must be between {minValue} and {maxValue} characters long.",
    integerMax: "The value must be at most {maxValue}.",
    integerMin: "The value must be at least {minValue}.",
    integerRange: "The value must be between {minValue} and {maxValue}.",
    integerValid: "The value must be a valid integer.",
    ipAddressInvalid: "Invalid address: 'Malformed IP address: {address}'",
    maxRows: "There must be at most {maxRows} entries.",
    minRows: "There must be at least {minRows} entries.",
    requiredValidation: "The value must not be empty.",
    radioValidation: "The value provided is not a valid option",
    timePickerValidation: "Invalid time format.",
    validationFailed:
      "Validation failed. Required information is missing or not valid.",
    validationFailedWithTabHeader:
      "Validation failed for the following tab: {tabHeader}. Required information is missing or not valid.",
    validationFailedWithTabHeaders:
      "Validation failed for the following tabs: {tabHeaders}. Required information is missing or not valid.",
    validationInProgress: "Validation in progress",
    validationMaxDate: "The value should be {maxDate}, {maxTime} or before.",
    validationMinDate: "The value should be {minDate}, {minTime} or after.",
    validationMinMaxDateRange:
      "The value should be between {minDate}, {minTime} and {maxDate}, {maxTime}.",
    validationPassed: "Validation passed.",
    validationReservedWord:
      "The value cannot contain the reserved word '{reserved}'.",
    validationReservedWords:
      "The value cannot contain any of the following reserved words: {reserved}.",
    valueMaxLen: "The value must be at most {maxLength} characters in length.",
    valueMinLen: "The value must be at least {minLength} characters in length.",
  },
};
bundled["en-XA"] = {
  actions: {
    add: "[PX][Add é]",
    addFilter: "[PX][Add filter é]",
    addTags: "[PX][Click here to add tags é]",
    apply: "[PX][Apply é]",
    clearFilters: "[PX][Clear filters é]",
    copiedToClipboard: "[PX][Copied é]",
    copyToClipboard: "[PX][Copy to clipboard é]",
    create: "[PX][Create é]",
    delete: "[PX][Delete é]",
    edit: "[PX][Edit é]",
    editTag: "[PX][Edit tag é]",
    next: "[PX][Next é]",
    previous: "[PX][Previous é]",
    reviewCreate: "[PX][Review + create é]",
    selectAll: "[PX][Select all é]",
    selectFile: "[PX][Select a file é]",
    view: "[PX][View é]",
  },
  ariaLabel: {
    actionMenu: "[PX][Click to open context menu éë]",
    actionMenuHeader: "[PX][Context menu column é]",
    close: "[PX][close é]",
    collapse: "[PX][Collapse é]",
    datePicker: "[PX][Select a date é]",
    delete: "[PX][Delete é]",
    dismissNotification: "[PX][Dismiss notification é]",
    expand: "[PX][Expand é]",
    fileUpload: "[PX][Upload a file é]",
    groupBy: "[PX][Group by é]",
    info: "[PX][Info é]",
    more: "[PX][More é]",
    nextMonth: "[PX][Next month é]",
    page: "[PX][Page é]",
    previousMonth: "[PX][Previous month é]",
    required: "[PX][Required é]",
    resourceList: "[PX][Resource list é]",
    selectItem: "[PX][Select item é]",
    sliderInput: "[PX][Slider input é]",
    switchView:
      "[PX][Switch between a list view or a summary view of data éëžéëžéëžé]",
    timePicker: "[PX][Select a time é]",
  },
  common: {
    addressesCount: "[PX][({count} addresses) é]",
    all: "[PX][All é]",
    cancel: "[PX][Cancel é]",
    confirm: "[PX][Confirm é]",
    contains: "[PX][Contains é]",
    discard: "[PX][Discard é]",
    equals: "[PX][Equals é]",
    error: "[PX][Error é]",
    errorLoadingData: "[PX][Error loading data é]",
    filter: "[PX][Filter é]",
    lastViewed: "[PX][Last Viewed é]",
    less: "[PX][Less é]",
    loading: "[PX][Loading... é]",
    more: "[PX][More ({count}) é]",
    name: "[PX][Name é]",
    no: "[PX][No é]",
    ok: "[PX][OK é]",
    operator: "[PX][Operator é]",
    required: "[PX][Required é]",
    success: "[PX][Success é]",
    tag: "[PX][Tag é]",
    tags: "[PX][Tags é]",
    type: "[PX][Type é]",
    value: "[PX][Value é]",
    yes: "[PX][Yes é]",
  },
  duration: {
    daysLong: "[PX][days é]",
    daysShort: "[PX][d é]",
    daysSymbol: "[PX][d é]",
    hoursLong: "[PX][hours é]",
    hoursShort: "[PX][hr é]",
    hoursSymbol: "[PX][h é]",
    millisecondsLong: "[PX][milliseconds é]",
    millisecondsShort: "[PX][msec é]",
    millisecondsSymbol: "[PX][ms é]",
    minutesLong: "[PX][minutes é]",
    minutesShort: "[PX][min é]",
    minutesSymbol: "[PX][m é]",
    secondsLong: "[PX][seconds é]",
    secondsShort: "[PX][sec é]",
    secondsSymbol: "[PX][s é]",
  },
  hints: {
    filterAnyField: "[PX][Filter for any field... é]",
    filterPlaceholder: "[PX][Filter... é]",
    noResultsFound: "[PX][No results were found. é]",
    requiredField: "[PX][Required field é]",
    searchForField: "[PX][Search for {fieldName} é]",
    searchPlaceholder: "[PX][Search é]",
    selectAnOption: "[PX][Select an option é]",
    selectExisting: "[PX][Select existing... é]",
    showSelection: "[PX][{count} selected é]",
  },
  labels: {
    addresses: "[PX][Addresses é]",
    essentials: "[PX][Essentials é]",
    tags: "[PX][Tags é]",
  },
  listTemplate: {
    learnMore: "[PX][Learn more é]",
    none: "[PX][None é]",
    showing: "[PX][Showing {start} to {end} of {total} records. éëžéëžéë]",
  },
  notifications: {
    apiErrorMsg:
      "[PX][Additional details from the underlying API that might be helpful: {errMsg} éëžéëžéëžéëžéëžéë]",
    pollingErrorMessage: "[PX][Failed to check operation status. éëžé]",
    pollingErrorTitle: "[PX][Failed to check é]",
  },
  pagination: {
    next: "[PX][Next é]",
    of: "[PX][of {totalNumberOfPages} é]",
    page: "[PX][Page é]",
    previous: "[PX][Previous é]",
  },
  status: {
    accepted: "[PX][Accepted é]",
    actionRequired: "[PX][Action required é]",
    active: "[PX][Active é]",
    applied: "[PX][Applied é]",
    applying: "[PX][Applying é]",
    assigned: "[PX][Assigned é]",
    assigning: "[PX][Assigning é]",
    attached: "[PX][Attached é]",
    attaching: "[PX][Attaching é]",
    available: "[PX][Available é]",
    availableNeedsAttention: "[PX][Available needs attention éë]",
    backupInProgress: "[PX][Backup in progress é]",
    blocked: "[PX][Blocked é]",
    canceled: "[PX][Canceled é]",
    canceling: "[PX][Canceling é]",
    cancelled: "[PX][Cancelled é]",
    cancelledReturned: "[PX][Cancelled returned é]",
    checking: "[PX][Checking é]",
    closed: "[PX][Closed é]",
    complete: "[PX][Complete é]",
    completed: "[PX][Completed é]",
    converting: "[PX][Converting é]",
    created: "[PX][Created é]",
    creating: "[PX][Creating é]",
    creatingImage: "[PX][Creating image é]",
    deleted: "[PX][Deleted é]",
    deleting: "[PX][Deleting é]",
    detaching: "[PX][Detaching é]",
    disabled: "[PX][Disabled é]",
    disabling: "[PX][Disabling é]",
    down: "[PX][Down é]",
    downForMaintenance: "[PX][Down for maintenance é]",
    enabled: "[PX][Enabled é]",
    enabling: "[PX][Enabling é]",
    error: "[PX][Error é]",
    expired: "[PX][Expired é]",
    exporting: "[PX][Exporting é]",
    failed: "[PX][Failed é]",
    failedApply: "[PX][Failed apply é]",
    failedRollback: "[PX][Failed rollback é]",
    faulty: "[PX][Faulty é]",
    importing: "[PX][Importing é]",
    inactive: "[PX][Inactive é]",
    initiated: "[PX][Initiated é]",
    inProgress: "[PX][In progress é]",
    internalError: "[PX][Internal error é]",
    loading: "[PX][Loading é]",
    maintenanceInProgress: "[PX][Maintenance in progress é]",
    missing: "[PX][Missing é]",
    packaged: "[PX][Packaged é]",
    pendingCustomer: "[PX][Pending customer é]",
    pendingProvider: "[PX][Pending provider é]",
    pendingProvisioning: "[PX][Pending provisioning é]",
    preparing: "[PX][Preparing é]",
    processed: "[PX][Processed é]",
    processing: "[PX][Processing é]",
    provisioned: "[PX][Provisioned é]",
    provisioning: "[PX][Provisioning é]",
    queued: "[PX][Queued é]",
    ready: "[PX][Ready é]",
    received: "[PX][Received é]",
    related: "[PX][Related é]",
    requestReceived: "[PX][Request received é]",
    restoreFailed: "[PX][Restore failed é]",
    restoreInProgress: "[PX][Restore in progress é]",
    restoring: "[PX][Restoring é]",
    returned: "[PX][Returned é]",
    rollingBack: "[PX][Rolling back é]",
    running: "[PX][Running é]",
    scaleInProgress: "[PX][Scale in progress é]",
    shipping: "[PX][Shipping é]",
    starting: "[PX][Starting é]",
    stopped: "[PX][Stopped é]",
    stopping: "[PX][Stopping é]",
    succeeded: "[PX][Succeeded é]",
    terminated: "[PX][Terminated é]",
    terminating: "[PX][Terminating é]",
    up: "[PX][Up é]",
    unassigned: "[PX][Unassigned é]",
    unassigning: "[PX][Unassigning é]",
    unavailable: "[PX][Unavailable é]",
    updated: "[PX][Updated é]",
    updating: "[PX][Updating é]",
    upgrading: "[PX][Upgrading é]",
    waiting: "[PX][Waiting é]",
    warning: "[PX][Warning é]",
  },
  tags: {
    less: "[PX][Less é]",
    more: "[PX][More ({count}) é]",
  },
  validation: {
    addressInvalid:
      "[PX][Invalid address: 'Malformed IP address or CIDR block: {address}'. éëžéëžéëžéëžéë]",
    addressOverlap:
      "[PX][Invalid address: '{address1} overlaps {address2}'. éëžéëžéëžé]",
    addressReserved: "[PX][Invalid address: '{address} is reserved'. éëžéëžé]",
    addressReservedRange:
      "[PX][Invalid address: '{address} is within reserved range {cidr} ({startingIp} - {endingIp})'. éëžéëžéëžéëžéëžéëžéëž]",
    asyncValidationFailed:
      "[PX][<strong>[{tabField}]</strong>: {error} éëžéëž]",
    cidrHasReservedIp:
      "[PX][Invalid address: '{cidr}' contains reserved address {address}'. éëžéëžéëžéëžé]",
    cidrHostBitsSet:
      "[PX][Invalid address: 'CIDR cannot have the host bits set'. éëžéëžéëžéë]",
    cidrInvalid:
      "[PX][Invalid address: 'Malformed CIDR block: {cidr}'. éëžéëžéëž]",
    cidrPrefixInvalid:
      "[PX][Invalid address: 'CIDR prefix must be between {min} and {max} inclusive'. éëžéëžéëžéëžéëžé]",
    inputRange:
      "[PX][The value must be between {minValue} and {maxValue} characters long. éëžéëžéëžéëžéëž]",
    integerMax: "[PX][The value must be at most {maxValue}. éëžéëž]",
    integerMin: "[PX][The value must be at least {minValue}. éëžéëž]",
    integerRange:
      "[PX][The value must be between {minValue} and {maxValue}. éëžéëžéëžé]",
    integerValid: "[PX][The value must be a valid integer. éëžéë]",
    ipAddressInvalid:
      "[PX][Invalid address: 'Malformed IP address: {address}' éëžéëžéëžé]",
    maxRows: "[PX][There must be at most {maxRows} entries. éëžéëžé]",
    minRows: "[PX][There must be at least {minRows} entries. éëžéëžé]",
    requiredValidation: "[PX][The value must not be empty. éëž]",
    radioValidation: "[PX][The value provided is not a valid option éëžéëžé]",
    timePickerValidation: "[PX][Invalid time format. é]",
    validationFailed:
      "[PX][Validation failed. Required information is missing or not valid. éëžéëžéëžéëžéë]",
    validationFailedWithTabHeader:
      "[PX][Validation failed for the following tab: {tabHeader}. Required information is missing or not valid. éëžéëžéëžéëžéëžéëžéëžéëž]",
    validationFailedWithTabHeaders:
      "[PX][Validation failed for the following tabs: {tabHeaders}. Required information is missing or not valid. éëžéëžéëžéëžéëžéëžéëžéëžé]",
    validationInProgress: "[PX][Validation in progress é]",
    validationMaxDate:
      "[PX][The value should be {maxDate}, {maxTime} or before. éëžéëžéëžé]",
    validationMinDate:
      "[PX][The value should be {minDate}, {minTime} or after. éëžéëžéëžé]",
    validationMinMaxDateRange:
      "[PX][The value should be between {minDate}, {minTime} and {maxDate}, {maxTime}. éëžéëžéëžéëžéëžéë]",
    validationPassed: "[PX][Validation passed. é]",
    validationReservedWord:
      "[PX][The value cannot contain the reserved word '{reserved}'. éëžéëžéëžéë]",
    validationReservedWords:
      "[PX][The value cannot contain any of the following reserved words: {reserved}. éëžéëžéëžéëžéëžé]",
    valueMaxLen:
      "[PX][The value must be at most {maxLength} characters in length. éëžéëžéëžéëž]",
    valueMinLen:
      "[PX][The value must be at least {minLength} characters in length. éëžéëžéëžéëžé]",
  },
};
bundled["en-XC"] = {
  actions: {
    add: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:add:::Add]]",
    addFilter:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:addFilter:::Add filter]]",
    addTags:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:addTags:::Click here to add tags]]",
    apply:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:apply:::Apply]]",
    clearFilters:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:clearFilters:::Clear filters]]",
    copiedToClipboard:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:copiedToClipboard:::Copied]]",
    copyToClipboard:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:copyToClipboard:::Copy to clipboard]]",
    create:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:create:::Create]]",
    delete:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:delete:::Delete]]",
    edit: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:edit:::Edit]]",
    editTag:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:editTag:::Edit tag]]",
    next: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:next:::Next]]",
    previous:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:previous:::Previous]]",
    reviewCreate:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:reviewCreate:::Review + create]]",
    selectAll:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:selectAll:::Select all]]",
    selectFile:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:selectFile:::Select a file]]",
    view: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::actions:view:::View]]",
  },
  ariaLabel: {
    actionMenu:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:actionMenu:::Click to open context menu]]",
    actionMenuHeader:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:actionMenuHeader:::Context menu column]]",
    close:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:close:::close]]",
    collapse:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:collapse:::Collapse]]",
    datePicker:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:datePicker:::Select a date]]",
    delete:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:delete:::Delete]]",
    dismissNotification:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:dismissNotification:::Dismiss notification]]",
    expand:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:expand:::Expand]]",
    fileUpload:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:fileUpload:::Upload a file]]",
    groupBy:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:groupBy:::Group by]]",
    info: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:info:::Info]]",
    more: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:more:::More]]",
    nextMonth:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:nextMonth:::Next month]]",
    page: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:page:::Page]]",
    previousMonth:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:previousMonth:::Previous month]]",
    required:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:required:::Required]]",
    resourceList:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:resourceList:::Resource list]]",
    selectItem:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:selectItem:::Select item]]",
    sliderInput:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:sliderInput:::Slider input]]",
    switchView:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:switchView:::Switch between a list view or a summary view of data]]",
    timePicker:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::ariaLabel:timePicker:::Select a time]]",
  },
  common: {
    addressesCount:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:addressesCount:::({count} addresses)]]",
    all: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:all:::All]]",
    cancel:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:cancel:::Cancel]]",
    confirm:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:confirm:::Confirm]]",
    contains:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:contains:::Contains]]",
    discard:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:discard:::Discard]]",
    equals:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:equals:::Equals]]",
    error:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:error:::Error]]",
    errorLoadingData:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:errorLoadingData:::Error loading data]]",
    filter:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:filter:::Filter]]",
    lastViewed:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:lastViewed:::Last Viewed]]",
    less: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:less:::Less]]",
    loading:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:loading:::Loading...]]",
    more: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:more:::More ({count})]]",
    name: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:name:::Name]]",
    no: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:no:::No]]",
    ok: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:ok:::OK]]",
    operator:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:operator:::Operator]]",
    required:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:required:::Required]]",
    success:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:success:::Success]]",
    tag: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:tag:::Tag]]",
    tags: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:tags:::Tags]]",
    type: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:type:::Type]]",
    value:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:value:::Value]]",
    yes: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::common:yes:::Yes]]",
  },
  duration: {
    daysLong:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:daysLong:::days]]",
    daysShort:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:daysShort:::d]]",
    daysSymbol:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:daysSymbol:::d]]",
    hoursLong:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:hoursLong:::hours]]",
    hoursShort:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:hoursShort:::hr]]",
    hoursSymbol:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:hoursSymbol:::h]]",
    millisecondsLong:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:millisecondsLong:::milliseconds]]",
    millisecondsShort:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:millisecondsShort:::msec]]",
    millisecondsSymbol:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:millisecondsSymbol:::ms]]",
    minutesLong:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:minutesLong:::minutes]]",
    minutesShort:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:minutesShort:::min]]",
    minutesSymbol:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:minutesSymbol:::m]]",
    secondsLong:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:secondsLong:::seconds]]",
    secondsShort:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:secondsShort:::sec]]",
    secondsSymbol:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::duration:secondsSymbol:::s]]",
  },
  hints: {
    filterAnyField:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::hints:filterAnyField:::Filter for any field...]]",
    filterPlaceholder:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::hints:filterPlaceholder:::Filter...]]",
    noResultsFound:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::hints:noResultsFound:::No results were found.]]",
    requiredField:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::hints:requiredField:::Required field]]",
    searchForField:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::hints:searchForField:::Search for {fieldName}]]",
    searchPlaceholder:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::hints:searchPlaceholder:::Search]]",
    selectAnOption:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::hints:selectAnOption:::Select an option]]",
    selectExisting:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::hints:selectExisting:::Select existing...]]",
    showSelection:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::hints:showSelection:::{count} selected]]",
  },
  labels: {
    addresses:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::labels:addresses:::Addresses]]",
    essentials:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::labels:essentials:::Essentials]]",
    tags: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::labels:tags:::Tags]]",
  },
  listTemplate: {
    learnMore:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::listTemplate:learnMore:::Learn more]]",
    none: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::listTemplate:none:::None]]",
    showing:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::listTemplate:showing:::Showing {start} to {end} of {total} records.]]",
  },
  notifications: {
    apiErrorMsg:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::notifications:apiErrorMsg:::Additional details from the underlying API that might be helpful: {errMsg}]]",
    pollingErrorMessage:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::notifications:pollingErrorMessage:::Failed to check operation status.]]",
    pollingErrorTitle:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::notifications:pollingErrorTitle:::Failed to check]]",
  },
  pagination: {
    next: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::pagination:next:::Next]]",
    of: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::pagination:of:::of {totalNumberOfPages}]]",
    page: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::pagination:page:::Page]]",
    previous:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::pagination:previous:::Previous]]",
  },
  status: {
    accepted:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:accepted:::Accepted]]",
    actionRequired:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:actionRequired:::Action required]]",
    active:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:active:::Active]]",
    applied:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:applied:::Applied]]",
    applying:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:applying:::Applying]]",
    assigned:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:assigned:::Assigned]]",
    assigning:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:assigning:::Assigning]]",
    attached:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:attached:::Attached]]",
    attaching:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:attaching:::Attaching]]",
    available:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:available:::Available]]",
    availableNeedsAttention:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:availableNeedsAttention:::Available needs attention]]",
    backupInProgress:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:backupInProgress:::Backup in progress]]",
    blocked:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:blocked:::Blocked]]",
    canceled:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:canceled:::Canceled]]",
    canceling:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:canceling:::Canceling]]",
    cancelled:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:cancelled:::Cancelled]]",
    cancelledReturned:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:cancelledReturned:::Cancelled returned]]",
    checking:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:checking:::Checking]]",
    closed:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:closed:::Closed]]",
    complete:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:complete:::Complete]]",
    completed:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:completed:::Completed]]",
    converting:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:converting:::Converting]]",
    created:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:created:::Created]]",
    creating:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:creating:::Creating]]",
    creatingImage:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:creatingImage:::Creating image]]",
    deleted:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:deleted:::Deleted]]",
    deleting:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:deleting:::Deleting]]",
    detaching:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:detaching:::Detaching]]",
    disabled:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:disabled:::Disabled]]",
    disabling:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:disabling:::Disabling]]",
    down: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:down:::Down]]",
    downForMaintenance:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:downForMaintenance:::Down for maintenance]]",
    enabled:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:enabled:::Enabled]]",
    enabling:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:enabling:::Enabling]]",
    error:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:error:::Error]]",
    expired:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:expired:::Expired]]",
    exporting:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:exporting:::Exporting]]",
    failed:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:failed:::Failed]]",
    failedApply:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:failedApply:::Failed apply]]",
    failedRollback:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:failedRollback:::Failed rollback]]",
    faulty:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:faulty:::Faulty]]",
    importing:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:importing:::Importing]]",
    inactive:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:inactive:::Inactive]]",
    initiated:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:initiated:::Initiated]]",
    inProgress:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:inProgress:::In progress]]",
    internalError:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:internalError:::Internal error]]",
    loading:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:loading:::Loading]]",
    maintenanceInProgress:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:maintenanceInProgress:::Maintenance in progress]]",
    missing:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:missing:::Missing]]",
    packaged:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:packaged:::Packaged]]",
    pendingCustomer:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:pendingCustomer:::Pending customer]]",
    pendingProvider:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:pendingProvider:::Pending provider]]",
    pendingProvisioning:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:pendingProvisioning:::Pending provisioning]]",
    preparing:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:preparing:::Preparing]]",
    processed:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:processed:::Processed]]",
    processing:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:processing:::Processing]]",
    provisioned:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:provisioned:::Provisioned]]",
    provisioning:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:provisioning:::Provisioning]]",
    queued:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:queued:::Queued]]",
    ready:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:ready:::Ready]]",
    received:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:received:::Received]]",
    related:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:related:::Related]]",
    requestReceived:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:requestReceived:::Request received]]",
    restoreFailed:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:restoreFailed:::Restore failed]]",
    restoreInProgress:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:restoreInProgress:::Restore in progress]]",
    restoring:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:restoring:::Restoring]]",
    returned:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:returned:::Returned]]",
    rollingBack:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:rollingBack:::Rolling back]]",
    running:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:running:::Running]]",
    scaleInProgress:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:scaleInProgress:::Scale in progress]]",
    shipping:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:shipping:::Shipping]]",
    starting:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:starting:::Starting]]",
    stopped:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:stopped:::Stopped]]",
    stopping:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:stopping:::Stopping]]",
    succeeded:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:succeeded:::Succeeded]]",
    terminated:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:terminated:::Terminated]]",
    terminating:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:terminating:::Terminating]]",
    up: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:up:::Up]]",
    unassigned:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:unassigned:::Unassigned]]",
    unassigning:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:unassigning:::Unassigning]]",
    unavailable:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:unavailable:::Unavailable]]",
    updated:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:updated:::Updated]]",
    updating:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:updating:::Updating]]",
    upgrading:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:upgrading:::Upgrading]]",
    waiting:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:waiting:::Waiting]]",
    warning:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::status:warning:::Warning]]",
  },
  tags: {
    less: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::tags:less:::Less]]",
    more: "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::tags:more:::More ({count})]]",
  },
  validation: {
    addressInvalid:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:addressInvalid:::Invalid address: 'Malformed IP address or CIDR block: {address}'.]]",
    addressOverlap:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:addressOverlap:::Invalid address: '{address1} overlaps {address2}'.]]",
    addressReserved:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:addressReserved:::Invalid address: '{address} is reserved'.]]",
    addressReservedRange:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:addressReservedRange:::Invalid address: '{address} is within reserved range {cidr} ({startingIp} - {endingIp})'.]]",
    asyncValidationFailed:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:asyncValidationFailed:::<strong>[{tabField}]</strong>: {error}]]",
    cidrHasReservedIp:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:cidrHasReservedIp:::Invalid address: '{cidr}' contains reserved address {address}'.]]",
    cidrHostBitsSet:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:cidrHostBitsSet:::Invalid address: 'CIDR cannot have the host bits set'.]]",
    cidrInvalid:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:cidrInvalid:::Invalid address: 'Malformed CIDR block: {cidr}'.]]",
    cidrPrefixInvalid:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:cidrPrefixInvalid:::Invalid address: 'CIDR prefix must be between {min} and {max} inclusive'.]]",
    inputRange:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:inputRange:::The value must be between {minValue} and {maxValue} characters long.]]",
    integerMax:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:integerMax:::The value must be at most {maxValue}.]]",
    integerMin:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:integerMin:::The value must be at least {minValue}.]]",
    integerRange:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:integerRange:::The value must be between {minValue} and {maxValue}.]]",
    integerValid:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:integerValid:::The value must be a valid integer.]]",
    ipAddressInvalid:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:ipAddressInvalid:::Invalid address: 'Malformed IP address: {address}']]",
    maxRows:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:maxRows:::There must be at most {maxRows} entries.]]",
    minRows:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:minRows:::There must be at least {minRows} entries.]]",
    requiredValidation:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:requiredValidation:::The value must not be empty.]]",
    radioValidation:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:radioValidation:::The value provided is not a valid option]]",
    timePickerValidation:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:timePickerValidation:::Invalid time format.]]",
    validationFailed:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:validationFailed:::Validation failed. Required information is missing or not valid.]]",
    validationFailedWithTabHeader:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:validationFailedWithTabHeader:::Validation failed for the following tab: {tabHeader}. Required information is missing or not valid.]]",
    validationFailedWithTabHeaders:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:validationFailedWithTabHeaders:::Validation failed for the following tabs: {tabHeaders}. Required information is missing or not valid.]]",
    validationInProgress:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:validationInProgress:::Validation in progress]]",
    validationMaxDate:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:validationMaxDate:::The value should be {maxDate}, {maxTime} or before.]]",
    validationMinDate:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:validationMinDate:::The value should be {minDate}, {minTime} or after.]]",
    validationMinMaxDateRange:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:validationMinMaxDateRange:::The value should be between {minDate}, {minTime} and {maxDate}, {maxTime}.]]",
    validationPassed:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:validationPassed:::Validation passed.]]",
    validationReservedWord:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:validationReservedWord:::The value cannot contain the reserved word '{reserved}'.]]",
    validationReservedWords:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:validationReservedWords:::The value cannot contain any of the following reserved words: {reserved}.]]",
    valueMaxLen:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:valueMaxLen:::The value must be at most {maxLength} characters in length.]]",
    valueMinLen:
      "[[oci_console_shared_dbtools/packages/o4a-react/locales/en.json:::validation:valueMinLen:::The value must be at least {minLength} characters in length.]]",
  },
};
bundled["ar-XB"] = {
  actions: {
    add: "[‏‮Add‬‏]",
    addFilter: "[‏‮Add filter‬‏]",
    addTags: "[‏‮Click here to add tags‬‏]",
    apply: "[‏‮Apply‬‏]",
    clearFilters: "[‏‮Clear filters‬‏]",
    copiedToClipboard: "[‏‮Copied‬‏]",
    copyToClipboard: "[‏‮Copy to clipboard‬‏]",
    create: "[‏‮Create‬‏]",
    delete: "[‏‮Delete‬‏]",
    edit: "[‏‮Edit‬‏]",
    editTag: "[‏‮Edit tag‬‏]",
    next: "[‏‮Next‬‏]",
    previous: "[‏‮Previous‬‏]",
    reviewCreate: "[‏‮Review + create‬‏]",
    selectAll: "[‏‮Select all‬‏]",
    selectFile: "[‏‮Select a file‬‏]",
    view: "[‏‮View‬‏]",
  },
  ariaLabel: {
    actionMenu: "[‏‮Click to open context menu‬‏]",
    actionMenuHeader: "[‏‮Context menu column‬‏]",
    close: "[‏‮close‬‏]",
    collapse: "[‏‮Collapse‬‏]",
    datePicker: "[‏‮Select a date‬‏]",
    delete: "[‏‮Delete‬‏]",
    dismissNotification: "[‏‮Dismiss notification‬‏]",
    expand: "[‏‮Expand‬‏]",
    fileUpload: "[‏‮Upload a file‬‏]",
    groupBy: "[‏‮Group by‬‏]",
    info: "[‏‮Info‬‏]",
    more: "[‏‮More‬‏]",
    nextMonth: "[‏‮Next month‬‏]",
    page: "[‏‮Page‬‏]",
    previousMonth: "[‏‮Previous month‬‏]",
    required: "[‏‮Required‬‏]",
    resourceList: "[‏‮Resource list‬‏]",
    selectItem: "[‏‮Select item‬‏]",
    sliderInput: "[‏‮Slider input‬‏]",
    switchView: "[‏‮Switch between a list view or a summary view of data‬‏]",
    timePicker: "[‏‮Select a time‬‏]",
  },
  common: {
    addressesCount: "[‏‮({count} addresses)‬‏]",
    all: "[‏‮All‬‏]",
    cancel: "[‏‮Cancel‬‏]",
    confirm: "[‏‮Confirm‬‏]",
    contains: "[‏‮Contains‬‏]",
    discard: "[‏‮Discard‬‏]",
    equals: "[‏‮Equals‬‏]",
    error: "[‏‮Error‬‏]",
    errorLoadingData: "[‏‮Error loading data‬‏]",
    filter: "[‏‮Filter‬‏]",
    lastViewed: "[‏‮Last Viewed‬‏]",
    less: "[‏‮Less‬‏]",
    loading: "[‏‮Loading...‬‏]",
    more: "[‏‮More ({count})‬‏]",
    name: "[‏‮Name‬‏]",
    no: "[‏‮No‬‏]",
    ok: "[‏‮OK‬‏]",
    operator: "[‏‮Operator‬‏]",
    required: "[‏‮Required‬‏]",
    success: "[‏‮Success‬‏]",
    tag: "[‏‮Tag‬‏]",
    tags: "[‏‮Tags‬‏]",
    type: "[‏‮Type‬‏]",
    value: "[‏‮Value‬‏]",
    yes: "[‏‮Yes‬‏]",
  },
  duration: {
    daysLong: "[‏‮days‬‏]",
    daysShort: "[‏‮d‬‏]",
    daysSymbol: "[‏‮d‬‏]",
    hoursLong: "[‏‮hours‬‏]",
    hoursShort: "[‏‮hr‬‏]",
    hoursSymbol: "[‏‮h‬‏]",
    millisecondsLong: "[‏‮milliseconds‬‏]",
    millisecondsShort: "[‏‮msec‬‏]",
    millisecondsSymbol: "[‏‮ms‬‏]",
    minutesLong: "[‏‮minutes‬‏]",
    minutesShort: "[‏‮min‬‏]",
    minutesSymbol: "[‏‮m‬‏]",
    secondsLong: "[‏‮seconds‬‏]",
    secondsShort: "[‏‮sec‬‏]",
    secondsSymbol: "[‏‮s‬‏]",
  },
  hints: {
    filterAnyField: "[‏‮Filter for any field...‬‏]",
    filterPlaceholder: "[‏‮Filter...‬‏]",
    noResultsFound: "[‏‮No results were found.‬‏]",
    requiredField: "[‏‮Required field‬‏]",
    searchForField: "[‏‮Search for {fieldName}‬‏]",
    searchPlaceholder: "[‏‮Search‬‏]",
    selectAnOption: "[‏‮Select an option‬‏]",
    selectExisting: "[‏‮Select existing...‬‏]",
    showSelection: "[‏‮{count} selected‬‏]",
  },
  labels: {
    addresses: "[‏‮Addresses‬‏]",
    essentials: "[‏‮Essentials‬‏]",
    tags: "[‏‮Tags‬‏]",
  },
  listTemplate: {
    learnMore: "[‏‮Learn more‬‏]",
    none: "[‏‮None‬‏]",
    showing: "[‏‮Showing {start} to {end} of {total} records.‬‏]",
  },
  notifications: {
    apiErrorMsg:
      "[‏‮Additional details from the underlying API that might be helpful: {errMsg}‬‏]",
    pollingErrorMessage: "[‏‮Failed to check operation status.‬‏]",
    pollingErrorTitle: "[‏‮Failed to check‬‏]",
  },
  pagination: {
    next: "[‏‮Next‬‏]",
    of: "[‏‮of {totalNumberOfPages}‬‏]",
    page: "[‏‮Page‬‏]",
    previous: "[‏‮Previous‬‏]",
  },
  status: {
    accepted: "[‏‮Accepted‬‏]",
    actionRequired: "[‏‮Action required‬‏]",
    active: "[‏‮Active‬‏]",
    applied: "[‏‮Applied‬‏]",
    applying: "[‏‮Applying‬‏]",
    assigned: "[‏‮Assigned‬‏]",
    assigning: "[‏‮Assigning‬‏]",
    attached: "[‏‮Attached‬‏]",
    attaching: "[‏‮Attaching‬‏]",
    available: "[‏‮Available‬‏]",
    availableNeedsAttention: "[‏‮Available needs attention‬‏]",
    backupInProgress: "[‏‮Backup in progress‬‏]",
    blocked: "[‏‮Blocked‬‏]",
    canceled: "[‏‮Canceled‬‏]",
    canceling: "[‏‮Canceling‬‏]",
    cancelled: "[‏‮Cancelled‬‏]",
    cancelledReturned: "[‏‮Cancelled returned‬‏]",
    checking: "[‏‮Checking‬‏]",
    closed: "[‏‮Closed‬‏]",
    complete: "[‏‮Complete‬‏]",
    completed: "[‏‮Completed‬‏]",
    converting: "[‏‮Converting‬‏]",
    created: "[‏‮Created‬‏]",
    creating: "[‏‮Creating‬‏]",
    creatingImage: "[‏‮Creating image‬‏]",
    deleted: "[‏‮Deleted‬‏]",
    deleting: "[‏‮Deleting‬‏]",
    detaching: "[‏‮Detaching‬‏]",
    disabled: "[‏‮Disabled‬‏]",
    disabling: "[‏‮Disabling‬‏]",
    down: "[‏‮Down‬‏]",
    downForMaintenance: "[‏‮Down for maintenance‬‏]",
    enabled: "[‏‮Enabled‬‏]",
    enabling: "[‏‮Enabling‬‏]",
    error: "[‏‮Error‬‏]",
    expired: "[‏‮Expired‬‏]",
    exporting: "[‏‮Exporting‬‏]",
    failed: "[‏‮Failed‬‏]",
    failedApply: "[‏‮Failed apply‬‏]",
    failedRollback: "[‏‮Failed rollback‬‏]",
    faulty: "[‏‮Faulty‬‏]",
    importing: "[‏‮Importing‬‏]",
    inactive: "[‏‮Inactive‬‏]",
    initiated: "[‏‮Initiated‬‏]",
    inProgress: "[‏‮In progress‬‏]",
    internalError: "[‏‮Internal error‬‏]",
    loading: "[‏‮Loading‬‏]",
    maintenanceInProgress: "[‏‮Maintenance in progress‬‏]",
    missing: "[‏‮Missing‬‏]",
    packaged: "[‏‮Packaged‬‏]",
    pendingCustomer: "[‏‮Pending customer‬‏]",
    pendingProvider: "[‏‮Pending provider‬‏]",
    pendingProvisioning: "[‏‮Pending provisioning‬‏]",
    preparing: "[‏‮Preparing‬‏]",
    processed: "[‏‮Processed‬‏]",
    processing: "[‏‮Processing‬‏]",
    provisioned: "[‏‮Provisioned‬‏]",
    provisioning: "[‏‮Provisioning‬‏]",
    queued: "[‏‮Queued‬‏]",
    ready: "[‏‮Ready‬‏]",
    received: "[‏‮Received‬‏]",
    related: "[‏‮Related‬‏]",
    requestReceived: "[‏‮Request received‬‏]",
    restoreFailed: "[‏‮Restore failed‬‏]",
    restoreInProgress: "[‏‮Restore in progress‬‏]",
    restoring: "[‏‮Restoring‬‏]",
    returned: "[‏‮Returned‬‏]",
    rollingBack: "[‏‮Rolling back‬‏]",
    running: "[‏‮Running‬‏]",
    scaleInProgress: "[‏‮Scale in progress‬‏]",
    shipping: "[‏‮Shipping‬‏]",
    starting: "[‏‮Starting‬‏]",
    stopped: "[‏‮Stopped‬‏]",
    stopping: "[‏‮Stopping‬‏]",
    succeeded: "[‏‮Succeeded‬‏]",
    terminated: "[‏‮Terminated‬‏]",
    terminating: "[‏‮Terminating‬‏]",
    up: "[‏‮Up‬‏]",
    unassigned: "[‏‮Unassigned‬‏]",
    unassigning: "[‏‮Unassigning‬‏]",
    unavailable: "[‏‮Unavailable‬‏]",
    updated: "[‏‮Updated‬‏]",
    updating: "[‏‮Updating‬‏]",
    upgrading: "[‏‮Upgrading‬‏]",
    waiting: "[‏‮Waiting‬‏]",
    warning: "[‏‮Warning‬‏]",
  },
  tags: {
    less: "[‏‮Less‬‏]",
    more: "[‏‮More ({count})‬‏]",
  },
  validation: {
    addressInvalid:
      "[‏‮Invalid address: 'Malformed IP address or CIDR block: {address}'.‬‏]",
    addressOverlap: "[‏‮Invalid address: '{address1} overlaps {address2}'.‬‏]",
    addressReserved: "[‏‮Invalid address: '{address} is reserved'.‬‏]",
    addressReservedRange:
      "[‏‮Invalid address: '{address} is within reserved range {cidr} ({startingIp} - {endingIp})'.‬‏]",
    asyncValidationFailed: "[‏‮<strong>[{tabField}]</strong>: {error}‬‏]",
    cidrHasReservedIp:
      "[‏‮Invalid address: '{cidr}' contains reserved address {address}'.‬‏]",
    cidrHostBitsSet:
      "[‏‮Invalid address: 'CIDR cannot have the host bits set'.‬‏]",
    cidrInvalid: "[‏‮Invalid address: 'Malformed CIDR block: {cidr}'.‬‏]",
    cidrPrefixInvalid:
      "[‏‮Invalid address: 'CIDR prefix must be between {min} and {max} inclusive'.‬‏]",
    inputRange:
      "[‏‮The value must be between {minValue} and {maxValue} characters long.‬‏]",
    integerMax: "[‏‮The value must be at most {maxValue}.‬‏]",
    integerMin: "[‏‮The value must be at least {minValue}.‬‏]",
    integerRange: "[‏‮The value must be between {minValue} and {maxValue}.‬‏]",
    integerValid: "[‏‮The value must be a valid integer.‬‏]",
    ipAddressInvalid:
      "[‏‮Invalid address: 'Malformed IP address: {address}'‬‏]",
    maxRows: "[‏‮There must be at most {maxRows} entries.‬‏]",
    minRows: "[‏‮There must be at least {minRows} entries.‬‏]",
    requiredValidation: "[‏‮The value must not be empty.‬‏]",
    radioValidation: "[‏‮The value provided is not a valid option‬‏]",
    timePickerValidation: "[‏‮Invalid time format.‬‏]",
    validationFailed:
      "[‏‮Validation failed. Required information is missing or not valid.‬‏]",
    validationFailedWithTabHeader:
      "[‏‮Validation failed for the following tab: {tabHeader}. Required information is missing or not valid.‬‏]",
    validationFailedWithTabHeaders:
      "[‏‮Validation failed for the following tabs: {tabHeaders}. Required information is missing or not valid.‬‏]",
    validationInProgress: "[‏‮Validation in progress‬‏]",
    validationMaxDate:
      "[‏‮The value should be {maxDate}, {maxTime} or before.‬‏]",
    validationMinDate:
      "[‏‮The value should be {minDate}, {minTime} or after.‬‏]",
    validationMinMaxDateRange:
      "[‏‮The value should be between {minDate}, {minTime} and {maxDate}, {maxTime}.‬‏]",
    validationPassed: "[‏‮Validation passed.‬‏]",
    validationReservedWord:
      "[‏‮The value cannot contain the reserved word '{reserved}'.‬‏]",
    validationReservedWords:
      "[‏‮The value cannot contain any of the following reserved words: {reserved}.‬‏]",
    valueMaxLen:
      "[‏‮The value must be at most {maxLength} characters in length.‬‏]",
    valueMinLen:
      "[‏‮The value must be at least {minLength} characters in length.‬‏]",
  },
};
bundled["cs"] = {
  actions: {
    add: "Přidat",
    addFilter: "Přidat filtr",
    addTags: "Kliknutím sem přidáte tagy",
    apply: "Použít",
    clearFilters: "Vymazat filtry",
    copiedToClipboard: "Zkopírováno",
    copyToClipboard: "Kopírovat do schránky",
    create: "Vytvořit",
    delete: "Odstranit",
    edit: "Upravit",
    editTag: "Upravit tag",
    next: "Další",
    previous: "Předchozí",
    reviewCreate: "Zkontrolovat a vytvořit",
    selectAll: "Vybrat vše",
    selectFile: "Zvolte soubor",
    view: "Zobrazit",
  },
  ariaLabel: {
    actionMenu: "Kliknutím otevřete místní nabídku",
    actionMenuHeader: "Sloupec místní nabídky",
    close: "zavřít",
    collapse: "Sbalit",
    datePicker: "Vyberte datum",
    delete: "Odstranit",
    dismissNotification: "Zavřít upozornění",
    expand: "Rozšířit",
    fileUpload: "Odeslat soubor",
    groupBy: "Seskupit podle",
    info: "Informace",
    more: "Více",
    nextMonth: "Další měsíc",
    page: "Stránka",
    previousMonth: "Předchozí měsíc",
    required: "Povinné",
    resourceList: "Seznam zdrojů",
    selectItem: "Vybrat položku",
    sliderInput: "Vstup posuvníku",
    switchView:
      "Přepnout mezi zobrazením seznamu nebo souhrnného zobrazení dat",
    timePicker: "Vyberte čas",
  },
  common: {
    addressesCount: "(Počet adres: {count})",
    all: "Vše",
    cancel: "Zrušit",
    confirm: "Potvrdit",
    contains: "Obsahuje",
    discard: "Zahodit",
    equals: "Je rovno",
    error: "Chyba",
    errorLoadingData: "Chyba při načítání dat",
    filter: "Filtrovat",
    lastViewed: "Naposledy zobrazeno",
    less: "Méně",
    loading: "Načítání...",
    more: "Více ({count})",
    name: "Název",
    no: "Ne",
    ok: "OK",
    operator: "Operátor",
    required: "Povinné",
    success: "Úspěch",
    tag: "Tag",
    tags: "Tagy",
    type: "Typ",
    value: "Hodnota",
    yes: "Ano",
  },
  duration: {
    daysLong: "dny",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "hodiny",
    hoursShort: "hod.",
    hoursSymbol: "h",
    millisecondsLong: "milisekundy",
    millisecondsShort: "ms",
    millisecondsSymbol: "ms",
    minutesLong: "minuty",
    minutesShort: "min.",
    minutesSymbol: "m",
    secondsLong: "sekundy",
    secondsShort: "s",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtr pro libovolné pole...",
    filterPlaceholder: "Filtr...",
    noResultsFound: "Nebyly nalezeny žádné výsledky.",
    requiredField: "Požadované pole",
    searchForField: "Hledání {fieldName}",
    searchPlaceholder: "Vyhledávání",
    selectAnOption: "Vyberte volbu",
    selectExisting: "Vybrat existující...",
    showSelection: "{count} vybráno",
  },
  labels: {
    addresses: "Adresy",
    essentials: "Základy",
    tags: "Tagy",
  },
  listTemplate: {
    learnMore: "Další informace",
    none: "Žádný",
    showing: "Zobrazuje se {start} až {end} z {total} záznamů.",
  },
  notifications: {
    apiErrorMsg:
      "Další podrobnosti ze základního rozhraní API, které by mohly být užitečné: {errMsg}",
    pollingErrorMessage: "Selhala kontrola stavu operace.",
    pollingErrorTitle: "Kontrola selhala",
  },
  pagination: {
    next: "Další",
    of: "z {totalNumberOfPages}",
    page: "Stránka",
    previous: "Předchozí",
  },
  status: {
    accepted: "Přijato",
    actionRequired: "Vyžadována akce",
    active: "Aktivní",
    applied: "Použito",
    applying: "Aplikuje se",
    assigned: "Přiřazeno",
    assigning: "Přiřazuje se",
    attached: "Připojeno",
    attaching: "Připojování",
    available: "Dostupné",
    availableNeedsAttention: "Dostupné, vyžaduje pozornost",
    backupInProgress: "Probíhá zálohování",
    blocked: "Blokováno",
    canceled: "Zrušeno",
    canceling: "Ruší se",
    cancelled: "Zrušeno",
    cancelledReturned: "Vrácení zrušeno",
    checking: "Probíhá kontrola",
    closed: "Zavřeno",
    complete: "Dokončit",
    completed: "Dokončeno",
    converting: "Probíhá převod",
    created: "Vytvořeno",
    creating: "Vytváří se",
    creatingImage: "Vytváření bitové kopie",
    deleted: "Odstraněno",
    deleting: "Odstraňuje se",
    detaching: "Odpojování",
    disabled: "Deaktivováno",
    disabling: "Probíhá deaktivace",
    down: "Vypnuto",
    downForMaintenance: "Vypnuto kvůli údržbě",
    enabled: "Aktivováno",
    enabling: "Probíhá aktivace",
    error: "Chyba",
    expired: "Platnost skončila",
    exporting: "Exportuje se",
    failed: "Selhání",
    failedApply: "Aplikace selhala",
    failedRollback: "Vrácení zpět selhalo",
    faulty: "S chybou",
    importing: "Probíhá import",
    inactive: "Neaktivní",
    initiated: "Zahájeno",
    inProgress: "Probíhá zpracování",
    internalError: "Vnitřní chyba",
    loading: "Načítá se",
    maintenanceInProgress: "Probíhá údržba",
    missing: "Chybí",
    packaged: "Zabaleno",
    pendingCustomer: "Zákazník čekající na vyřízení",
    pendingProvider: "Poskytovatel čekající na vyřízení",
    pendingProvisioning: "Zřizování čekající na vyřízení",
    preparing: "Probíhá příprava",
    processed: "Zpracováno",
    processing: "Probíhá zpracování",
    provisioned: "Poskytnuto",
    provisioning: "Poskytování",
    queued: "Zařazeno do fronty",
    ready: "Připraveno",
    received: "Přijato",
    related: "Související",
    requestReceived: "Požadavek přijat",
    restoreFailed: "Obnovení selhalo",
    restoreInProgress: "Probíhá obnovení",
    restoring: "Obnovuje se",
    returned: "Vráceno",
    rollingBack: "Probíhá vrácení zpět",
    running: "Spuštěno",
    scaleInProgress: "Probíhá škálování",
    shipping: "Expedice",
    starting: "Spouští se",
    stopped: "Zastaveno",
    stopping: "Zastavuje se",
    succeeded: "Úspěšné",
    terminated: "Ukončeno",
    terminating: "Ukončuje se",
    up: "Spuštěno",
    unassigned: "Nepřiřazeno",
    unassigning: "Ruší se přiřazení",
    unavailable: "Není k dispozici",
    updated: "Aktualizováno",
    updating: "Aktualizuje se",
    upgrading: "Přechod na vyšší verzi",
    waiting: "Čeká se",
    warning: "Varování",
  },
  tags: {
    less: "Méně",
    more: "Více ({count})",
  },
  validation: {
    addressInvalid:
      "Neplatná adresa: 'Chybně formulovaná IP adresa nebo blok CIDR: {address}'.",
    addressOverlap: "Neplatná adresa: '{address1} překrývá {address2}'.",
    addressReserved: "Neplatná adresa: '{address} je vyhrazena'.",
    addressReservedRange:
      "Neplatná adresa: '{address} je v rámci vyhrazeného rozsahu {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Neplatná adresa: '{cidr}' obsahuje vyhrazenou adresu {address}'.",
    cidrHostBitsSet:
      "Neplatná adresa: 'CIDR nemůže mít nastavené bity hostitelského počítače'.",
    cidrInvalid: "Neplatná adresa: 'Chybně formulovaný blok CIDR: {cidr}'.",
    cidrPrefixInvalid:
      "Neplatná adresa: 'Předpona CIDR musí být mezi {min} a {max} včetně'.",
    inputRange: "Hodnota musí mít délku {minValue} až {maxValue} znaků",
    integerMax: "Hodnota musí být nejvíce {maxValue}.",
    integerMin: "Hodnota musí být nejméně {minValue}.",
    integerRange: "Hodnota musí být v rozsahu od {minValue} do {maxValue}.",
    integerValid: "Hodnota musí být platné celé číslo.",
    ipAddressInvalid:
      "Neplatná adresa: 'Chybně formulovaná IP adresa: {address}'",
    maxRows: "Je vyžadován maximální počet položek: {maxRows}.",
    minRows: "Je vyžadován minimální počet položek: {minRows}.",
    requiredValidation: "Hodnota nesmí být prázdná",
    radioValidation: "Poskytnutá hodnota není platnou volbou",
    timePickerValidation: "Neplatný formát času.",
    validationFailed:
      "Ověření selhalo. Požadované informace chybí nebo nejsou platné.",
    validationFailedWithTabHeader:
      "Ověření selhalo pro následující kartu: {tabHeader}. Požadované informace chybí nebo nejsou platné.",
    validationFailedWithTabHeaders:
      "Ověření selhalo pro následující karty: {tabHeaders}. Požadované informace chybí nebo nejsou platné.",
    validationInProgress: "Probíhá ověření",
    validationMaxDate:
      "Hodnota by měla být z {maxDate}, {maxTime} nebo dřívější.",
    validationMinDate:
      "Hodnota by měla být z {minDate}, {minTime} nebo pozdější.",
    validationMinMaxDateRange:
      "Hodnota by měla být z intervalu mezi {minDate}, {minTime} a {maxDate}, {maxTime}.",
    validationPassed: "Ověření platnosti uspělo.",
    validationReservedWord:
      "Hodnota nesmí obsahovat vyhrazené slovo '{reserved}'.",
    validationReservedWords:
      "Hodnota nesmí obsahovat žádné z následujících vyhrazených slov: {reserved}.",
    valueMaxLen:
      "Hodnota může obsahovat maximálně následující počet znaků: {maxLength}.",
    valueMinLen:
      "Hodnota musí mít nejméně následující počet znaků {minLength}.",
  },
};
bundled["da"] = {
  actions: {
    add: "Tilføj",
    addFilter: "Tilføj filter",
    addTags: "Klik her for at tilføje tags",
    apply: "Anvend",
    clearFilters: "Ryd filtre",
    copiedToClipboard: "Kopieret",
    copyToClipboard: "Kopier til Udklipsholder",
    create: "Opret",
    delete: "Slet",
    edit: "Rediger",
    editTag: "Rediger tag",
    next: "Næste",
    previous: "Forrige",
    reviewCreate: "Gennemgå + opret",
    selectAll: "Vælg alle",
    selectFile: "Vælg en fil",
    view: "Se",
  },
  ariaLabel: {
    actionMenu: "Klik for at åbne kontekstmenuen",
    actionMenuHeader: "Kontekstmenukolonne",
    close: "luk",
    collapse: "Skjul",
    datePicker: "Vælg en dato",
    delete: "Slet",
    dismissNotification: "Afslå underretning",
    expand: "Udvid",
    fileUpload: "Upload en fil",
    groupBy: "Grupper efter",
    info: "Info",
    more: "Flere",
    nextMonth: "Næste måned",
    page: "Side",
    previousMonth: "Forrige måned",
    required: "Påkrævet",
    resourceList: "Ressourceliste",
    selectItem: "Vælg element",
    sliderInput: "Skyderinput",
    switchView:
      "Skift mellem en listevisning og en opsummeringsvisning af data",
    timePicker: "Vælg et tidspunkt",
  },
  common: {
    addressesCount: "({count} adresser)",
    all: "Alle",
    cancel: "Annuller",
    confirm: "Bekræft",
    contains: "Indeholder",
    discard: "Kasser",
    equals: "Er lig med",
    error: "Fejl",
    errorLoadingData: "Fejl under indlæsning af data",
    filter: "Filtrer",
    lastViewed: "Sidst set",
    less: "Færre",
    loading: "Indlæser...",
    more: "Flere ({count})",
    name: "Navn",
    no: "Nej",
    ok: "OK",
    operator: "Operator",
    required: "Påkrævet",
    success: "Succes",
    tag: "Tag",
    tags: "Tags",
    type: "Type",
    value: "Værdi",
    yes: "Ja",
  },
  duration: {
    daysLong: "dage",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "timer",
    hoursShort: "t.",
    hoursSymbol: "t",
    millisecondsLong: "millisekunder",
    millisecondsShort: "msek.",
    millisecondsSymbol: "ms",
    minutesLong: "minutter",
    minutesShort: "min.",
    minutesSymbol: "m",
    secondsLong: "sekunder",
    secondsShort: "sek.",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtrer for ethvert felt...",
    filterPlaceholder: "Filtrer...",
    noResultsFound: "Ingen resultater blev fundet.",
    requiredField: "Påkrævet felt",
    searchForField: "Søg efter {fieldName}",
    searchPlaceholder: "Søg",
    selectAnOption: "Vælg en mulighed",
    selectExisting: "Vælg eksisterende...",
    showSelection: "{count} valgt",
  },
  labels: {
    addresses: "Adresser",
    essentials: "Væsentlige forudsætninger",
    tags: "Tags",
  },
  listTemplate: {
    learnMore: "Lær mere",
    none: "Ingen",
    showing: "Viser {start} til {end} af {total} records.",
  },
  notifications: {
    apiErrorMsg:
      "Yderligere detaljer fra den underliggende API, der kan være nyttige: {errMsg}",
    pollingErrorMessage: "Kunne ikke tjekke operationsstatus",
    pollingErrorTitle: "Kunne ikke tjekke",
  },
  pagination: {
    next: "Næste",
    of: "af {totalNumberOfPages}",
    page: "Side",
    previous: "Forrige",
  },
  status: {
    accepted: "Accepteret",
    actionRequired: "Handling påkrævet",
    active: "Aktiv",
    applied: "Anvendt",
    applying: "Anvender",
    assigned: "Tildelt",
    assigning: "Tildeler",
    attached: "Tilknyttet",
    attaching: "Tilknytter",
    available: "Tilgængelig",
    availableNeedsAttention: "Tilgængelige kræver opmærksomhed",
    backupInProgress: "Backup i gang",
    blocked: "Blokeret",
    canceled: "Annulleret",
    canceling: "Annullerer",
    cancelled: "Annulleret",
    cancelledReturned: "Annulleret returneret",
    checking: "Tjekker",
    closed: "Lukket",
    complete: "Fuldfør",
    completed: "Fuldført",
    converting: "Konverterer",
    created: "Oprettet",
    creating: "Opretter",
    creatingImage: "Opretter billede",
    deleted: "Slettet",
    deleting: "Sletter",
    detaching: "Ophæver tilknytning",
    disabled: "Deaktiveret",
    disabling: "Deaktiverer",
    down: "Nede",
    downForMaintenance: "Nede pga. vedligeholdelse",
    enabled: "Aktiveret",
    enabling: "Aktiverer",
    error: "Fejl",
    expired: "Udløbet",
    exporting: "Eksporterer",
    failed: "Fejlet",
    failedApply: "Fejlede anvendelse",
    failedRollback: "Fejlede tilbagestilling",
    faulty: "Fejlbehæftet",
    importing: "Importerer",
    inactive: "Inaktiv",
    initiated: "Initieret",
    inProgress: "Igangværende",
    internalError: "Intern fejl",
    loading: "Indlæser",
    maintenanceInProgress: "Vedligeholdelse i gang",
    missing: "Mangler",
    packaged: "Pakket",
    pendingCustomer: "Afventer kunde",
    pendingProvider: "Afventer udbyder",
    pendingProvisioning: "Afventer provisionering",
    preparing: "Forbereder",
    processed: "Behandlet",
    processing: "Behandler",
    provisioned: "Provisioneret",
    provisioning: "Provisionerer",
    queued: "I kø",
    ready: "Klar",
    received: "Modtaget",
    related: "Relateret",
    requestReceived: "Anmodning modtaget",
    restoreFailed: "Gendannelse fejlede",
    restoreInProgress: "Gendannelse i gang",
    restoring: "Gendanner",
    returned: "Returneret",
    rollingBack: "Tilbagestiller",
    running: "Kører",
    scaleInProgress: "Skalering i gang",
    shipping: "Afsender",
    starting: "Starter",
    stopped: "Stoppet",
    stopping: "Stopper",
    succeeded: "Gennemført",
    terminated: "Afsluttet",
    terminating: "Afslutter",
    up: "Oppe",
    unassigned: "Ikke tildelt",
    unassigning: "Ophæver tildeling",
    unavailable: "Utilgængelig",
    updated: "Opdateret",
    updating: "Opdaterer",
    upgrading: "Opgraderer",
    waiting: "Venter",
    warning: "Advarsel",
  },
  tags: {
    less: "Færre",
    more: "Flere ({count})",
  },
  validation: {
    addressInvalid:
      "Ugyldig adresse: 'Forkert udformet IP-adresse eller CIDR-blok: {address}'.",
    addressOverlap: "Ugyldig adresse: '{address1} overlapper {address2}'.",
    addressReserved: "Ugyldig adresse: '{address} er reserveret'.",
    addressReservedRange:
      "Ugyldig adresse: '{address} er inden for det reserverede interval {cidr} ({startingIp}-{endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Ugyldig adresse: '{cidr}' indeholder den reserverede adresse {address}'.",
    cidrHostBitsSet: "Ugyldig adresse: 'CIDR kan ikke have værtsbittene sat'.",
    cidrInvalid: "Ugyldig adresse: 'Forkert udformet CIDR-blok: {cidr}'.",
    cidrPrefixInvalid:
      "Ugyldig adresse: 'CIDR-præfiks skal være mellem {min} og {max} inkl'.",
    inputRange: "Værdien skal være mellem {minValue} og {maxValue} tegn lang.",
    integerMax: "Værdien må højst være {maxValue}.",
    integerMin: "Værdien skal mindst være {minValue}.",
    integerRange: "Værdien skal være mellem {minValue} og {maxValue}.",
    integerValid: "Værdien skal være et gyldigt heltal.",
    ipAddressInvalid:
      "Ugyldig adresse: 'Forkert udformet IP-adresse: {address}'",
    maxRows: "Der må højst være {maxRows} indtastninger.",
    minRows: "Der skal være mindst {minRows} indtastninger.",
    requiredValidation: "Værdien må ikke være tom.",
    radioValidation: "Den angivne værdi er ikke en gyldig valgmulighed",
    timePickerValidation: "Ugyldigt klokkeslætsformat.",
    validationFailed:
      "Validering fejlede. Påkrævede oplysninger mangler eller er ikke gyldige.",
    validationFailedWithTabHeader:
      "Validering fejlede for følgende fane: {tabHeader}. Påkrævede oplysninger mangler eller er ikke gyldige.",
    validationFailedWithTabHeaders:
      "Validering fejlede for følgende faner: {tabHeaders}. Påkrævede oplysninger mangler eller er ikke gyldige.",
    validationInProgress: "Validering i gang",
    validationMaxDate: "Værdien skal være {maxDate}, {maxTime} eller før.",
    validationMinDate: "Værdien skal være {minDate}, {minTime} eller efter.",
    validationMinMaxDateRange:
      "Værdien skal være mellem {minDate}, {minTime} og {maxDate}, {maxTime}.",
    validationPassed: "Validering gennemført.",
    validationReservedWord:
      "Værdien kan ikke indeholde det reserverede ord '{reserved}'.",
    validationReservedWords:
      "Værdien kan ikke indeholde nogen af følgende reserverede ord: {reserved}.",
    valueMaxLen: "Værdien skal være på højst {maxLength} tegn.",
    valueMinLen: "Værdien skal være på mindst {minLength} tegn.",
  },
};
bundled["de"] = {
  actions: {
    add: "Hinzufügen",
    addFilter: "Filter hinzufügen",
    addTags: "Klicken Sie hier, um Tags hinzuzufügen",
    apply: "Anwenden",
    clearFilters: "Filter löschen",
    copiedToClipboard: "Kopiert",
    copyToClipboard: "In Zwischenablage kopieren",
    create: "Erstellen",
    delete: "Löschen",
    edit: "Bearbeiten",
    editTag: "Tag bearbeiten",
    next: "Weiter",
    previous: "Zurück",
    reviewCreate: "Prüfen + erstellen",
    selectAll: "Alle auswählen",
    selectFile: "Datei auswählen",
    view: "Anzeigen",
  },
  ariaLabel: {
    actionMenu: "Hier klicken, um das Kontextmenü zu öffnen",
    actionMenuHeader: "Kontextmenüspalte",
    close: "schließen",
    collapse: "Ausblenden",
    datePicker: "Datum wählen",
    delete: "Löschen",
    dismissNotification: "Benachrichtigung verwerfen",
    expand: "Einblenden",
    fileUpload: "Datei hochladen",
    groupBy: "Gruppieren nach",
    info: "Info",
    more: "Mehr",
    nextMonth: "Nächster Monat",
    page: "Seite",
    previousMonth: "Vorheriger Monat",
    required: "Erforderlich",
    resourceList: "Ressourcenliste",
    selectItem: "Element auswählen",
    sliderInput: "Schiebereglereingabe",
    switchView: "Wechseln Sie zwischen Listenansicht und Datenübersicht",
    timePicker: "Zeit auswählen",
  },
  common: {
    addressesCount: "({count} Adressen)",
    all: "Alle",
    cancel: "Abbrechen",
    confirm: "Bestätigen",
    contains: "Enthält",
    discard: "Verwerfen",
    equals: "Ist gleich",
    error: "Fehler",
    errorLoadingData: "Fehler beim Laden von Daten",
    filter: "Filter",
    lastViewed: "Zuletzt angezeigt",
    less: "Weniger",
    loading: "Wird geladen...",
    more: "Mehr ({count})",
    name: "Name",
    no: "Nein",
    ok: "OK",
    operator: "Operator",
    required: "Erforderlich",
    success: "Erfolgreich",
    tag: "Tag",
    tags: "Tags",
    type: "Typ",
    value: "Wert",
    yes: "Ja",
  },
  duration: {
    daysLong: "Tage",
    daysShort: "T",
    daysSymbol: "T",
    hoursLong: "Stunden",
    hoursShort: "Std.",
    hoursSymbol: "Std.",
    millisecondsLong: "Millisekunden",
    millisecondsShort: "ms",
    millisecondsSymbol: "ms",
    minutesLong: "Minuten",
    minutesShort: "Min.",
    minutesSymbol: "Min.",
    secondsLong: "Sekunden",
    secondsShort: "Sek.",
    secondsSymbol: "Sek.",
  },
  hints: {
    filterAnyField: "Nach beliebigem Feld filtern...",
    filterPlaceholder: "Filtern...",
    noResultsFound: "Es wurden keine Ergebnisse gefunden.",
    requiredField: "Pflichtfeld",
    searchForField: "Suchen nach {fieldName}",
    searchPlaceholder: "Suchen",
    selectAnOption: "Option auswählen",
    selectExisting: "Vorhandene auswählen...",
    showSelection: "{count} ausgewählt",
  },
  labels: {
    addresses: "Adressen",
    essentials: "Hauptinformationen",
    tags: "Tags",
  },
  listTemplate: {
    learnMore: "Weitere Informationen",
    none: "Kein Wert",
    showing: "{start} bis {end} von {total} Datensätzen werden angezeigt.",
  },
  notifications: {
    apiErrorMsg:
      "Zusätzliche Details von der zugrunde liegenden API, die nützlich sein könnten: {errMsg}",
    pollingErrorMessage: "Vorgangsstatus konnte nicht geprüft werden.",
    pollingErrorTitle: "Prüfung nicht erfolgreich",
  },
  pagination: {
    next: "Weiter",
    of: "von {totalNumberOfPages}",
    page: "Seite",
    previous: "Zurück",
  },
  status: {
    accepted: "Akzeptiert",
    actionRequired: "Aktion erforderlich",
    active: "Aktiv",
    applied: "Angewendet",
    applying: "Wird angewendet",
    assigned: "Zugewiesen",
    assigning: "Wird zugewiesen",
    attached: "Angehängt",
    attaching: "Wird angehängt",
    available: "Verfügbar",
    availableNeedsAttention: "Verfügbar, Aktion erforderlich",
    backupInProgress: "Backup wird erstellt",
    blocked: "Blockiert",
    canceled: "Abgebrochen",
    canceling: "Wird abgebrochen",
    cancelled: "Abgebrochen",
    cancelledReturned: '"Abgebrochen" wurde zurückgegeben',
    checking: "Wird geprüft",
    closed: "Geschlossen",
    complete: "Vollständig",
    completed: "Abgeschlossen",
    converting: "Wird konvertiert",
    created: "Erstellt",
    creating: "Wird erstellt",
    creatingImage: "Image wird erstellt",
    deleted: "Gelöscht",
    deleting: "Wird gelöscht",
    detaching: "Wird getrennt",
    disabled: "Deaktiviert",
    disabling: "Wird deaktiviert",
    down: "Heruntergefahren",
    downForMaintenance: "Wegen Wartung heruntergefahren",
    enabled: "Aktiviert",
    enabling: "Wird aktiviert",
    error: "Fehler",
    expired: "Abgelaufen",
    exporting: "Wird exportiert",
    failed: "Nicht erfolgreich",
    failedApply: "Anwenden nicht erfolgreich",
    failedRollback: "Rollback nicht erfolgreich",
    faulty: "Fehlerhaft",
    importing: "Wird importiert",
    inactive: "Inaktiv",
    initiated: "Initiiert",
    inProgress: "Wird bearbeitet",
    internalError: "Interner Fehler",
    loading: "Wird geladen",
    maintenanceInProgress: "Wartung wird ausgeführt",
    missing: "Fehlt",
    packaged: "Verpackt",
    pendingCustomer: "Ausstehend bei Kunde",
    pendingProvider: "Ausstehend bei Provider",
    pendingProvisioning: "Provisioning steht aus",
    preparing: "Wird vorbereitet",
    processed: "Verarbeitet",
    processing: "Wird verarbeitet",
    provisioned: "Bereitgestellt",
    provisioning: "Provisioning wird ausgeführt",
    queued: "In Queue",
    ready: "Bereit",
    received: "Empfangen",
    related: "Zugehörig",
    requestReceived: "Anforderung empfangen",
    restoreFailed: "Wiederherstellung nicht erfolgreich",
    restoreInProgress: "Wiederherstellung wird ausgeführt",
    restoring: "Wird wiederhergestellt",
    returned: "Zurückgesendet",
    rollingBack: "Rollback wird ausgeführt",
    running: "Wird ausgeführt",
    scaleInProgress: "Skalierung wird ausgeführt",
    shipping: "Wird zugestellt",
    starting: "Wird gestartet",
    stopped: "Gestoppt",
    stopping: "Wird gestoppt",
    succeeded: "Erfolgreich",
    terminated: "Beendet",
    terminating: "Wird beendet",
    up: "Hochgefahren",
    unassigned: "Nicht zugewiesen",
    unassigning: "Zuweisung wird aufgehoben",
    unavailable: "Nicht verfügbar",
    updated: "Aktualisiert",
    updating: "Wird aktualisiert",
    upgrading: "Upgrade wird ausgeführt",
    waiting: "Wartend",
    warning: "Warnung",
  },
  tags: {
    less: "Weniger",
    more: "Mehr ({count})",
  },
  validation: {
    addressInvalid:
      'Ungültige Adresse: "IP-Adresse oder CIDR-Block nicht wohlgeformt: {address}".',
    addressOverlap:
      'Ungültige Adresse: "{address1} überschneidet sich mit {address2}".',
    addressReserved: 'Ungültige Adresse: "{address} ist reserviert".',
    addressReservedRange:
      'Ungültige Adresse: "{address} liegt innerhalb des reservierten Bereichs {cidr} ({startingIp}-{endingIp})".',
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      'Ungültige Adresse: "{cidr} enthält die reservierte Adresse {address}".',
    cidrHostBitsSet:
      'Ungültige Adresse: "Für CIDR dürfen keine Hostbits festgelegt sein".',
    cidrInvalid: 'Ungültige Adresse: "CIDR-Block nicht wohlgeformt: {cidr}".',
    cidrPrefixInvalid:
      'Ungültige Adresse: "CIDR-Präfix muss zwischen {min} und {max} (einschließlich) liegen".',
    inputRange:
      "Der Wert muss zwischen {minValue} und {maxValue} Zeichen lang sein.",
    integerMax: "Der Wert darf höchstens {maxValue} betragen.",
    integerMin: "Der Wert muss mindestens {minValue} betragen.",
    integerRange: "Der Wert muss zwischen {minValue} und {maxValue} liegen.",
    integerValid: "Der Wert muss eine gültige Ganzzahl sein.",
    ipAddressInvalid:
      'Ungültige Adresse: "IP-Adresse nicht wohlgeformt: {address}"',
    maxRows: "Es dürfen höchstens {maxRows} Einträge vorhanden sein.",
    minRows: "Es müssen mindestens {minRows} Einträge vorhanden sein.",
    requiredValidation: "Der Wert darf nicht leer sein.",
    radioValidation: "Der angegebene Wert ist keine gültige Option.",
    timePickerValidation: "Ungültiges Zeitformat.",
    validationFailed:
      "Validierung nicht erfolgreich. Erforderliche Informationen fehlen oder sind ungültig.",
    validationFailedWithTabHeader:
      "Die Validierung war für folgende Registerkarte nicht erfolgreich: {tabHeader}. Erforderliche Informationen fehlen oder sind ungültig.",
    validationFailedWithTabHeaders:
      "Die Validierung war für folgende Registerkarten nicht erfolgreich: {tabHeaders}. Erforderliche Informationen fehlen oder sind ungültig.",
    validationInProgress: "Validierung wird ausgeführt",
    validationMaxDate:
      "Der Wert muss am {maxDate}, {maxTime} oder davor liegen.",
    validationMinDate:
      "Der Wert muss am {minDate}, {minTime} oder danach liegen.",
    validationMinMaxDateRange:
      "Der Wert muss zwischen {minDate}, {minTime} und {maxDate}, {maxTime} liegen.",
    validationPassed: "Validierung erfolgreich.",
    validationReservedWord:
      'Der Wert darf nicht das reservierte Wort "{reserved}" enthalten.',
    validationReservedWords:
      "Der Wert darf keines der folgenden reservierten Wörter enthalten: {reserved}.",
    valueMaxLen: "Der Wert darf höchstens {maxLength} Zeichen lang sein.",
    valueMinLen: "Der Wert muss mindestens {minLength} Zeichen lang sein.",
  },
};
bundled["el"] = {
  actions: {
    add: "Προσθήκη",
    addFilter: "Προσθήκη φίλτρου",
    addTags: "Κάντε κλικ εδώ για να προσθέσετε ετικέτες",
    apply: "Εφαρμογή",
    clearFilters: "Εκκαθάριση φίλτρων",
    copiedToClipboard: "Αντιγράφηκε",
    copyToClipboard: "Αντιγραφή στο Πρόχειρο",
    create: "Δημιουργία",
    delete: "Διαγραφή",
    edit: "Επεξεργασία",
    editTag: "Επεξεργασία ετικέτας",
    next: "Επόμενο",
    previous: "Προηγούμενο",
    reviewCreate: "Αναθεώρηση + δημιουργία",
    selectAll: "Επιλογή όλων",
    selectFile: "Επιλογή αρχείου",
    view: "Προβολή",
  },
  ariaLabel: {
    actionMenu: "Κάντε κλικ για άνοιγμα του μενού περιβάλλοντος",
    actionMenuHeader: "Στήλη μενού περιβάλλοντος",
    close: "κλείσιμο",
    collapse: "Σύμπτυξη",
    datePicker: "Επιλέξτε μια ημερομηνία",
    delete: "Διαγραφή",
    dismissNotification: "Απόρριψη ειδοποίησης",
    expand: "Ανάπτυξη",
    fileUpload: "Αποστολή αρχείου",
    groupBy: "Ομαδοποίηση κατά",
    info: "Πληροφορίες",
    more: "Περισσότερα",
    nextMonth: "Επόμενος μήνας",
    page: "Σελίδα",
    previousMonth: "Προηγούμενος μήνας",
    required: "Απαιτείται",
    resourceList: "Λίστα πόρων",
    selectItem: "Επιλογή στοιχείου",
    sliderInput: "Εισαγωγή ρυθμιστικού",
    switchView:
      "Εναλλαγή μεταξύ προβολής λίστας ή συνοπτικής προβολής δεδομένων",
    timePicker: "Επιλογή ώρας",
  },
  common: {
    addressesCount: "({count} διευθύνσεις)",
    all: "Όλα",
    cancel: "Ακύρωση",
    confirm: "Επιβεβαίωση",
    contains: "Περιέχει",
    discard: "Απόρριψη",
    equals: "Ισούται με",
    error: "Σφάλμα",
    errorLoadingData: "Σφάλμα κατά τη φόρτωση δεδομένων",
    filter: "Φίλτρο",
    lastViewed: "Τελευταία προβολή",
    less: "Λιγότερα",
    loading: "Φόρτωση...",
    more: "Περισσότερα ({count})",
    name: "Όνομα",
    no: "Όχι",
    ok: "OK",
    operator: "Τελεστής",
    required: "Απαιτείται",
    success: "Επιτυχία",
    tag: "Ετικέτα",
    tags: "Ετικέτες",
    type: "Τύπος",
    value: "Τιμή",
    yes: "Ναι",
  },
  duration: {
    daysLong: "ημέρες",
    daysShort: "η",
    daysSymbol: "η",
    hoursLong: "ώρες",
    hoursShort: "ώρ.",
    hoursSymbol: "ώ",
    millisecondsLong: "χιλιοστά του δευτερολέπτου",
    millisecondsShort: "msec",
    millisecondsSymbol: "ms",
    minutesLong: "λεπτά",
    minutesShort: "λεπτ.",
    minutesSymbol: "λ",
    secondsLong: "δευτερόλεπτα",
    secondsShort: "δευτ.",
    secondsSymbol: "δ",
  },
  hints: {
    filterAnyField: "Πεδίο φίλτρου",
    filterPlaceholder: "Φιλτράρισμα...",
    noResultsFound: "Δεν βρέθηκαν αποτελέσματα.",
    requiredField: "Απαιτούμενο πεδίο",
    searchForField: 'Αναζήτηση για "{fieldName}"',
    searchPlaceholder: "Αναζήτηση",
    selectAnOption: "Επιλέξτε μια δυνατότητα",
    selectExisting: "Επιλογή υπάρχουσας...",
    showSelection: "{count} επιλέχθηκαν",
  },
  labels: {
    addresses: "Διευθύνσεις",
    essentials: "Βασικές πληροφορίες",
    tags: "Ετικέτες",
  },
  listTemplate: {
    learnMore: "Μάθετε περισσότερα",
    none: "Κανένα",
    showing: "Εμφάνιση {start} έως {end} από {total} εγγραφές.",
  },
  notifications: {
    apiErrorMsg:
      "Πρόσθετες λεπτομέρειες από την υποκείμενη διασύνδεση API που μπορεί να είναι χρήσιμες: {errMsg}",
    pollingErrorMessage: "Ο έλεγχος κατάστασης λειτουργίας απέτυχε.",
    pollingErrorTitle: "Αποτυχία ελέγχου",
  },
  pagination: {
    next: "Επόμενο",
    of: "από {totalNumberOfPages}",
    page: "Σελίδα",
    previous: "Προηγούμενο",
  },
  status: {
    accepted: "Έγινε αποδεκτό",
    actionRequired: "Απαιτείται ενέργεια",
    active: "Ενεργό",
    applied: "Εφαρμόστηκε",
    applying: "Γίνεται εφαρμογή",
    assigned: "Αντιστοιχίστηκε",
    assigning: "Γίνεται αντιστοίχιση",
    attached: "Προσαρτήθηκε",
    attaching: "Γίνεται προσάρτηση",
    available: "Διαθέσιμο",
    availableNeedsAttention: "Το διαθέσιμο χρειάζεται προσοχή",
    backupInProgress: "Δημιουργία αντιγράφου ασφαλείας σε εξέλιξη",
    blocked: "Αποκλείστηκε",
    canceled: "Ακυρώθηκε",
    canceling: "Γίνεται ακύρωση",
    cancelled: "Ακυρώθηκε",
    cancelledReturned: "Επιστράφηκαν ακυρωμένα",
    checking: "Γίνεται έλεγχος",
    closed: "Έκλεισε",
    complete: "Ολοκληρωμένο",
    completed: "Ολοκληρώθηκε",
    converting: "Γίνεται μετατροπή",
    created: "Δημιουργήθηκε",
    creating: "Γίνεται δημιουργία",
    creatingImage: "Δημιουργία εικόνας",
    deleted: "Διαγράφηκε",
    deleting: "Γίνεται διαγραφή",
    detaching: "Γίνεται αποπροσάρτηση",
    disabled: "Απενεργοποιήθηκε",
    disabling: "Γίνεται απενεργοποίηση",
    down: "Εκτός λειτουργίας",
    downForMaintenance: "Εκτός λειτουργίας για συντήρηση",
    enabled: "Ενεργοποιήθηκε",
    enabling: "Γίνεται ενεργοποίηση",
    error: "Σφάλμα",
    expired: "Έληξε",
    exporting: "Γίνεται εξαγωγή",
    failed: "Απέτυχε",
    failedApply: "Εφαρμογή αποτυχημένων",
    failedRollback: "Αναίρεση αλλαγών αποτυχημένων",
    faulty: "Ελαττωματικό",
    importing: "Γίνεται εισαγωγή",
    inactive: "Ανενεργό",
    initiated: "Εκκινήθηκε",
    inProgress: "Σε εξέλιξη",
    internalError: "Εσωτερικό σφάλμα",
    loading: "Γίνεται φόρτωση",
    maintenanceInProgress: "Συντήρηση σε εξέλιξη",
    missing: "Λείπει",
    packaged: "Συσκευάστηκε",
    pendingCustomer: "Πελάτης σε εκκρεμότητα",
    pendingProvider: "Πάροχος σε εκκρεμότητα",
    pendingProvisioning: "Παροχή σε εκκρεμότητα",
    preparing: "Γίνεται προετοιμασία",
    processed: "Έγινε επεξεργασία",
    processing: "Γίνεται επεξεργασία",
    provisioned: "Παρασχέθηκε",
    provisioning: "Γίνεται παροχή",
    queued: "Τοποθετήθηκε στην ουρά",
    ready: "Έτοιμο",
    received: "Παραλήφθηκε",
    related: "Σχετιζόμενο",
    requestReceived: "Το αίτημα παραλήφθηκε",
    restoreFailed: "Η επαναφορά απέτυχε",
    restoreInProgress: "Επαναφορά σε εξέλιξη",
    restoring: "Γίνεται επαναφορά",
    returned: "Επιστράφηκε",
    rollingBack: "Γίνεται αναίρεση αλλαγών",
    running: "Εκτελείται",
    scaleInProgress: "Κλίμακα σε εξέλιξη",
    shipping: "Αποστολή",
    starting: "Γίνεται έναρξη",
    stopped: "Διακόπηκε",
    stopping: "Γίνεται διακοπή",
    succeeded: "Ολοκληρώθηκε με επιτυχία",
    terminated: "Τερματίστηκε",
    terminating: "Γίνεται τερματισμός",
    up: "Σε λειτουργία",
    unassigned: "Μη αντιστοιχισμένο",
    unassigning: "Γίνεται αναίρεση αντιστοίχισης",
    unavailable: "Μη διαθέσιμο",
    updated: "Ενημερώθηκε",
    updating: "Γίνεται ενημέρωση",
    upgrading: "Γίνεται αναβάθμιση",
    waiting: "Σε αναμονή",
    warning: "Προειδοποίηση",
  },
  tags: {
    less: "Λιγότερα",
    more: "Περισσότερα ({count})",
  },
  validation: {
    addressInvalid:
      "Μη έγκυρη διεύθυνση: 'Λανθασμένη μορφή διεύθυνσης IP ή μπλοκ CIDR: {address}'.",
    addressOverlap:
      "Μη έγκυρη διεύθυνση: 'Η διεύθυνση {address1} συμπίπτει με τη διεύθυνση {address2}'.",
    addressReserved:
      "Μη έγκυρη διεύθυνση: 'Η διεύθυνση {address} είναι δεσμευμένη'.",
    addressReservedRange:
      "Μη έγκυρη διεύθυνση: 'Η διεύθυνση {address} βρίσκεται εντός της δεσμευμένης περιοχής {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Μη έγκυρη διεύθυνση: 'Η τιμή '{cidr}' περιέχει τη δεσμευμένη διεύθυνση {address}'.",
    cidrHostBitsSet:
      "Μη έγκυρη διεύθυνση: 'Το CIDR δεν μπορεί να έχει καθορισμένα bit κεντρικού υπολογιστή'.",
    cidrInvalid: "Μη έγκυρη διεύθυνση: 'Λανθασμένη μορφή μπλοκ CIDR: {cidr}'.",
    cidrPrefixInvalid:
      "Μη έγκυρη διεύθυνση: 'Το πρόθεμα CIDR πρέπει να κυμαίνεται μεταξύ {min} και {max} συμπεριλαμβανομένων'.",
    inputRange:
      "Η τιμή πρέπει να είναι μεταξύ {minValue} και {maxValue} χαρακτήρων σε μήκος.",
    integerMax: "Η τιμή πρέπει να είναι το πολύ {maxValue}.",
    integerMin: "Η τιμή πρέπει να είναι τουλάχιστον {minValue}.",
    integerRange: "Η τιμή πρέπει να είναι μεταξύ {minValue} και {maxValue}.",
    integerValid: "Η τιμή πρέπει να είναι έγκυρος ακέραιος.",
    ipAddressInvalid:
      "Μη έγκυρη διεύθυνση: 'Λανθασμένη μορφή διεύθυνσης IP: {address}'",
    maxRows: "Πρέπει να υπάρχουν έως και {maxRows} καταχωρίσεις.",
    minRows: "Πρέπει να υπάρχουν τουλάχιστον {minRows} καταχωρίσεις.",
    requiredValidation: "Η τιμή δεν πρέπει να είναι κενή.",
    radioValidation: "Η παρεχόμενη τιμή δεν είναι έγκυρη επιλογή",
    timePickerValidation: "Μη έγκυρη μορφή ώρας.",
    validationFailed:
      "Αποτυχία επικύρωσης. Λείπουν οι απαιτούμενες πληροφορίες ή δεν είναι έγκυρες.",
    validationFailedWithTabHeader:
      "Η επικύρωση απέτυχε για την ακόλουθη καρτέλα: {tabHeader}. Λείπουν απαιτούμενες πληροφορίες ή δεν είναι έγκυρες.",
    validationFailedWithTabHeaders:
      "Η επικύρωση απέτυχε για τις ακόλουθες καρτέλες: {tabHeaders}. Λείπουν απαιτούμενες πληροφορίες ή δεν είναι έγκυρες.",
    validationInProgress: "Επικύρωση σε εξέλιξη",
    validationMaxDate:
      "Η τιμή πρέπει να είναι {maxDate}, {maxTime} ή νωρίτερα.",
    validationMinDate:
      "Η τιμή πρέπει να είναι {minDate}, {minTime} ή αργότερα.",
    validationMinMaxDateRange:
      "Η τιμή πρέπει να είναι μεταξύ {minDate}, {minTime} και {maxDate}, {maxTime}.",
    validationPassed: "Επιτυχής επικύρωση.",
    validationReservedWord:
      "Η τιμή δεν μπορεί να περιέχει τη δεσμευμένη λέξη '{reserved}'.",
    validationReservedWords:
      "Η τιμή δεν μπορεί να περιέχει κάποια από τις δεσμευμένες λέξεις: {reserved}.",
    valueMaxLen: "Η τιμή πρέπει να έχει μήκος έως και {maxLength} χαρακτήρες.",
    valueMinLen:
      "Η τιμή πρέπει να έχει μήκος τουλάχιστον {minLength} χαρακτήρες.",
  },
};
bundled["es"] = {
  actions: {
    add: "Agregar",
    addFilter: "Agregar Filtro",
    addTags: "Haga clic aquí para agregar etiquetas",
    apply: "Aplicar",
    clearFilters: "Borrar filtros",
    copiedToClipboard: "Copiado",
    copyToClipboard: "Copiar en el portapapeles",
    create: "Crear",
    delete: "Suprimir",
    edit: "Editar",
    editTag: "Editar etiqueta",
    next: "Siguiente",
    previous: "Anterior",
    reviewCreate: "Revisar y crear",
    selectAll: "Seleccionar todo",
    selectFile: "Seleccione un archivo",
    view: "Ver",
  },
  ariaLabel: {
    actionMenu: "Haga clic para abrir el menú contextual",
    actionMenuHeader: "Columna de menú contextual",
    close: "cerrar",
    collapse: "Reducir",
    datePicker: "Seleccionar Fecha",
    delete: "Suprimir",
    dismissNotification: "Descartar notificación",
    expand: "Ampliar",
    fileUpload: "Cargar Archivo",
    groupBy: "Agrupar por",
    info: "Información",
    more: "Más",
    nextMonth: "Mes siguiente",
    page: "Página",
    previousMonth: "Mes anterior",
    required: "Necesario",
    resourceList: "Lista de recursos",
    selectItem: "Seleccionar elemento",
    sliderInput: "Entrada de control deslizante",
    switchView:
      "Cambiar entre una vista de lista o una vista de resumen de datos",
    timePicker: "Seleccionar una hora",
  },
  common: {
    addressesCount: "({count} direcciones)",
    all: "Todo",
    cancel: "Cancelar",
    confirm: "Confirmar",
    contains: "Contiene",
    discard: "Desechar",
    equals: "Igual que",
    error: "Error",
    errorLoadingData: "Error al cargar datos",
    filter: "Filtrar",
    lastViewed: "Última Visualización",
    less: "Menos",
    loading: "Cargando...",
    more: "Más ({count})",
    name: "Nombre",
    no: "No",
    ok: "Aceptar",
    operator: "Operador",
    required: "Necesario",
    success: "Correcto",
    tag: "Etiqueta",
    tags: "Etiquetas",
    type: "Tipo",
    value: "Valor",
    yes: "Sí",
  },
  duration: {
    daysLong: "días",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "horas",
    hoursShort: "h",
    hoursSymbol: "h",
    millisecondsLong: "milisegundos",
    millisecondsShort: "mseg",
    millisecondsSymbol: "ms",
    minutesLong: "minutos",
    minutesShort: "min",
    minutesSymbol: "min",
    secondsLong: "segundos",
    secondsShort: "seg",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtrar para cualquier campo...",
    filterPlaceholder: "Filtrar...",
    noResultsFound: "No se ha encontrado ningún resultado.",
    requiredField: "Campo necesario",
    searchForField: "Buscar {fieldName}",
    searchPlaceholder: "Buscar",
    selectAnOption: "Seleccionar una opción",
    selectExisting: "Seleccionar existente...",
    showSelection: "{count} seleccionados",
  },
  labels: {
    addresses: "Direcciones",
    essentials: "Básicos",
    tags: "Etiquetas",
  },
  listTemplate: {
    learnMore: "Más información",
    none: "Ninguno",
    showing: "Se muestra de {start} a {end} de {total} registros.",
  },
  notifications: {
    apiErrorMsg:
      "Detalles adicionales de la API subyacente que pueden servir de ayuda: {errMsg}",
    pollingErrorMessage: "Fallo al comprobar el estado de la operación.",
    pollingErrorTitle: "Fallo de comprobación",
  },
  pagination: {
    next: "Siguiente",
    of: "de {totalNumberOfPages}",
    page: "Página",
    previous: "Anterior",
  },
  status: {
    accepted: "Aceptado",
    actionRequired: "Acción necesaria",
    active: "Activo",
    applied: "Aplicado",
    applying: "Aplicando",
    assigned: "Asignado",
    assigning: "Asignando",
    attached: "Adjunto",
    attaching: "Adjuntando",
    available: "Disponible",
    availableNeedsAttention: "Disponible, pero precisa atención",
    backupInProgress: "Copia de seguridad en curso",
    blocked: "Bloqueado",
    canceled: "Cancelado",
    canceling: "Cancelando",
    cancelled: "Cancelado",
    cancelledReturned: "Devolución cancelada",
    checking: "Comprobando",
    closed: "Cerrado",
    complete: "Completo",
    completed: "Completando",
    converting: "Convirtiendo",
    created: "Creado",
    creating: "Creando",
    creatingImage: "Creando imagen",
    deleted: "Suprimido",
    deleting: "Suprimiendo",
    detaching: "Desasociando",
    disabled: "Desactivado",
    disabling: "Desactivando",
    down: "Fuera de servicio",
    downForMaintenance: "Inactivo por motivos de mantenimiento",
    enabled: "Activado",
    enabling: "Activando",
    error: "Error",
    expired: "Caducado",
    exporting: "Exportando",
    failed: "Con fallos",
    failedApply: "Fallo al aplicar",
    failedRollback: "Fallo en el rollback",
    faulty: "Con error",
    importing: "Importando",
    inactive: "Inactivo",
    initiated: "Iniciado",
    inProgress: "En curso",
    internalError: "Error interno",
    loading: "Cargando",
    maintenanceInProgress: "Mantenimiento en curso",
    missing: "Falta",
    packaged: "Empaquetado",
    pendingCustomer: "Esperando cliente",
    pendingProvider: "Esperando proveedor",
    pendingProvisioning: "Esperando aprovisionamiento",
    preparing: "Preparando",
    processed: "Procesado",
    processing: "Procesando",
    provisioned: "Aprovisionado",
    provisioning: "Aprovisionando",
    queued: "En cola",
    ready: "Listo",
    received: "Recibido",
    related: "Relacionado",
    requestReceived: "Solicitud recibida",
    restoreFailed: "Fallo de restauración",
    restoreInProgress: "Restauración en curso",
    restoring: "Restaurando",
    returned: "Devuelto",
    rollingBack: "Realizando rollback",
    running: "En ejecución",
    scaleInProgress: "Ampliación en curso",
    shipping: "Envío en curso",
    starting: "Iniciando",
    stopped: "Parado",
    stopping: "Parando",
    succeeded: "Finalizado correctamente",
    terminated: "Terminado",
    terminating: "Terminando",
    up: "En funcionamiento",
    unassigned: "Sin asignar",
    unassigning: "Anulando asignación",
    unavailable: "No disponible",
    updated: "Actualizado",
    updating: "Actualizando",
    upgrading: "Actualizando",
    waiting: "En espera",
    warning: "Advertencia",
  },
  tags: {
    less: "Menos",
    more: "Más ({count})",
  },
  validation: {
    addressInvalid:
      "Dirección no válida: 'Dirección IP o bloque de CIDR con formato incorrecto: {address}'.",
    addressOverlap:
      "Dirección no válida: '{address1} se solapa con {address2}'.",
    addressReserved: "Dirección no válida: '{address} está reservada'.",
    addressReservedRange:
      "Dirección no válida: '{address} está en el rango reservado {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Dirección no válida: '{cidr}' contiene la dirección reservada {address}'.",
    cidrHostBitsSet:
      "Dirección no válida: 'el CIDR no puede tener los bits del host definidos'.",
    cidrInvalid:
      "Dirección no válida: 'Bloque de CIDR con formato incorrecto: {cidr}'.",
    cidrPrefixInvalid:
      "Dirección no válida: 'El prefijo de CIDR debe estar comprendido entre {min} y {max} con ambos valores incluidos'.",
    inputRange: "El valor debe tener entre {minValue} y {maxValue} caracteres.",
    integerMax: "El valor debe ser {maxValue} como máximo.",
    integerMin: "El valor debe ser {minValue} como mínimo.",
    integerRange: "El valor debe estar entre {minValue} y {maxValue}.",
    integerValid: "El valor debe ser un número entero válido.",
    ipAddressInvalid:
      "Dirección no válida: 'Dirección IP con formato incorrecto: {address}'",
    maxRows: "Debe haber como máximo {maxRows} entradas.",
    minRows: "Debe haber al menos {minRows} entradas.",
    requiredValidation: "El valor no debe estar vacío.",
    radioValidation: "El valor proporcionado no es una opción válida",
    timePickerValidation: "Formato de hora no válido.",
    validationFailed:
      "Fallo al validar. Falta información necesaria o no es válida.",
    validationFailedWithTabHeader:
      "Fallo al validar el siguiente separador {tabHeader}. Falta información necesaria o no es válida.",
    validationFailedWithTabHeaders:
      "Fallo al validar los siguientes separadores {tabHeaders}. Falta información necesaria o no es válida.",
    validationInProgress: "Validación en curso",
    validationMaxDate:
      "El valor debe ser {maxDate}, {maxTime} o una fecha anterior.",
    validationMinDate:
      "El valor debe ser {minDate}, {minTime} o una fecha posterior.",
    validationMinMaxDateRange:
      "El valor debe estar comprendido entre las fechas {minDate}, {minTime} y {maxDate}, {maxTime}.",
    validationPassed: "Validación superada.",
    validationReservedWord:
      "El valor no puede contener la palabra reservada '{reserved}'.",
    validationReservedWords:
      "El valor no puede contener ninguna de las siguientes palabras reservadas '{reserved}'.",
    valueMaxLen: "El valor debe tener {maxLength} caracteres como máximo.",
    valueMinLen: "El valor debe tener al menos {minLength} caracteres.",
  },
};
bundled["fi"] = {
  actions: {
    add: "Lisää",
    addFilter: "Lisää suodatin",
    addTags: "Lisää tunnisteita napsauttamalla tätä",
    apply: "Käytä",
    clearFilters: "Tyhjennä suodattimet",
    copiedToClipboard: "Kopioitu",
    copyToClipboard: "Kopioi leikepöydälle",
    create: "Luo",
    delete: "Poista",
    edit: "Muokkaa",
    editTag: "Muokkaa tunnistetta",
    next: "Seuraava",
    previous: "Edellinen",
    reviewCreate: "Tarkista + luo",
    selectAll: "Valitse kaikki",
    selectFile: "Valitse tiedosto",
    view: "Näytä",
  },
  ariaLabel: {
    actionMenu: "Avaa kontekstivalikko napsauttamalla",
    actionMenuHeader: "Kontekstivalikon sarake",
    close: "sulje",
    collapse: "Tiivistä",
    datePicker: "Valitse pvm",
    delete: "Poista",
    dismissNotification: "Hylkää ilmoitus",
    expand: "Laajenna",
    fileUpload: "Lataa tiedosto",
    groupBy: "Ryhmittelyperuste",
    info: "Tiedot",
    more: "Lisää",
    nextMonth: "Seuraava kuukausi",
    page: "Sivu",
    previousMonth: "Edellinen kuukausi",
    required: "Pakollinen",
    resourceList: "Resurssiluettelo",
    selectItem: "Valitse kohde",
    sliderInput: "Liukusäätimen syöte",
    switchView: "Vaihda tietojen luettelonäkymän ja yhteenvetonäkymän välillä",
    timePicker: "Valitse aika",
  },
  common: {
    addressesCount: "({count} osoitetta)",
    all: "Kaikki",
    cancel: "Peruuta",
    confirm: "Vahvista",
    contains: "Sisältää",
    discard: "Hylkää",
    equals: "On yhtä suuri kuin",
    error: "Virhe",
    errorLoadingData: "Virhe ladattaessa tietoja",
    filter: "Suodata",
    lastViewed: "Viimeksi katseltu",
    less: "Vähemmän",
    loading: "Ladataan...",
    more: "Enemmän ({count})",
    name: "Nimi",
    no: "Ei",
    ok: "OK",
    operator: "Operaattori",
    required: "Pakollinen",
    success: "Onnistui",
    tag: "Tunniste",
    tags: "Tunnisteet",
    type: "Tyyppi",
    value: "Arvo",
    yes: "Kyllä",
  },
  duration: {
    daysLong: "päivää",
    daysShort: "pv",
    daysSymbol: "pv",
    hoursLong: "tuntia",
    hoursShort: "h",
    hoursSymbol: "h",
    millisecondsLong: "millisekuntia",
    millisecondsShort: "ms",
    millisecondsSymbol: "ms",
    minutesLong: "minuuttia",
    minutesShort: "min",
    minutesSymbol: "min",
    secondsLong: "sekuntia",
    secondsShort: "s",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Suodata mitä tahansa kenttää varten...",
    filterPlaceholder: "Suodata...",
    noResultsFound: "Tuloksia ei löytynyt.",
    requiredField: "Pakollinen kenttä",
    searchForField: "Hae kohdetta {fieldName}",
    searchPlaceholder: "Haku",
    selectAnOption: "Tee valinta",
    selectExisting: "Valitse olemassa oleva...",
    showSelection: "{count} valittu",
  },
  labels: {
    addresses: "Osoitteet",
    essentials: "Tärkeimmät ominaisuudet",
    tags: "Tunnisteet",
  },
  listTemplate: {
    learnMore: "Lisätietoja",
    none: "Ei mitään",
    showing: "Näytetään {start}–{end}/{total} tietuetta.",
  },
  notifications: {
    apiErrorMsg:
      "Mahdollisesti hyödyllisiä lisätietoja perustana olevasta API-rajapinnasta: {errMsg}",
    pollingErrorMessage: "Toiminnon tilan tarkistus epäonnistui.",
    pollingErrorTitle: "Tarkistus epäonnistui",
  },
  pagination: {
    next: "Seuraava",
    of: "/{totalNumberOfPages}",
    page: "Sivu",
    previous: "Edellinen",
  },
  status: {
    accepted: "Hyväksytty",
    actionRequired: "Toimi vaaditaan",
    active: "Aktiivinen",
    applied: "Otettu käyttöön",
    applying: "Otetaan käyttöön",
    assigned: "Määritetty",
    assigning: "Määritetään",
    attached: "Liitetty",
    attaching: "Liitetään",
    available: "Käytettävissä",
    availableNeedsAttention: "Käytettävissä vaatii huomiota",
    backupInProgress: "Varmuuskopiointi meneillään",
    blocked: "Estetty",
    canceled: "Peruutettu",
    canceling: "Peruutetaan",
    cancelled: "Peruutettu",
    cancelledReturned: "Peruutettu palautettu",
    checking: "Tarkistetaan",
    closed: "Suljettu",
    complete: "Valmis",
    completed: "Valmis",
    converting: "Muunnetaan",
    created: "Luotu",
    creating: "Luodaan",
    creatingImage: "Luodaan näköistiedostoa",
    deleted: "Poistettu",
    deleting: "Poistetaan",
    detaching: "Irrotetaan",
    disabled: "Poistettu käytöstä",
    disabling: "Poistetaan käytöstä",
    down: "Ei käynnissä",
    downForMaintenance: "Suljettu ylläpitoa varten",
    enabled: "Käytössä",
    enabling: "Otetaan käyttöön",
    error: "Virhe",
    expired: "Vanhentunut",
    exporting: "Viedään",
    failed: "Epäonnistui",
    failedApply: "Käyttöönotto epäonnistui",
    failedRollback: "Peruutus epäonnistui",
    faulty: "Viallinen",
    importing: "Tuodaan",
    inactive: "Ei-aktiivinen",
    initiated: "Käynnistetty",
    inProgress: "Käynnissä",
    internalError: "Sisäinen virhe",
    loading: "Ladataan",
    maintenanceInProgress: "Ylläpito käynnissä",
    missing: "Puuttuu",
    packaged: "Pakattu",
    pendingCustomer: "Odottaa asiakasta",
    pendingProvider: "Odottaa toimittajaa",
    pendingProvisioning: "Odottaa valmistelua",
    preparing: "Valmistellaan",
    processed: "Käsitelty",
    processing: "Käsitellään",
    provisioned: "Valmisteltu",
    provisioning: "Valmistellaan",
    queued: "Jonossa",
    ready: "Valmis",
    received: "Vastaanotettu",
    related: "Liittyvä",
    requestReceived: "Pyyntö vastaanotettu",
    restoreFailed: "Palautus epäonnistui",
    restoreInProgress: "Palautus meneillään",
    restoring: "Palautetaan",
    returned: "Palautettu",
    rollingBack: "Peruutetaan",
    running: "Käynnissä",
    scaleInProgress: "Skaalaus käynnissä",
    shipping: "Lähetetään",
    starting: "Käynnistetään",
    stopped: "Pysäytetty",
    stopping: "Pysähtyy",
    succeeded: "Onnistui",
    terminated: "Lopetettu",
    terminating: "Lopetetaan",
    up: "Käynnissä",
    unassigned: "Määrittämätön",
    unassigning: "Poistetaan määritystä",
    unavailable: "Ei käytettävissä",
    updated: "Päivitetty",
    updating: "Päivitetään",
    upgrading: "Päivitetään",
    waiting: "Odotetaan",
    warning: "Varoitus",
  },
  tags: {
    less: "Vähemmän",
    more: "Enemmän ({count})",
  },
  validation: {
    addressInvalid:
      "Virheellinen osoite: väärin muodostettu IP-osoite tai CIDR-lohko: {address}.",
    addressOverlap:
      "Virheellinen osoite: {address1} on päällekkäinen osoitteen {address2} kanssa.",
    addressReserved: "Virheellinen osoite: {address} on varattu.",
    addressReservedRange:
      "Virheellinen osoite: {address} on varatulla alueella {cidr} ({startingIp}–{endingIp}).",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Virheellinen osoite: {cidr} sisältää varatun osoitteen {address}.",
    cidrHostBitsSet: "Virheellinen osoite: CIDR:n isäntäosia ei voi määrittää.",
    cidrInvalid: "Virheellinen osoite: väärin muodostettu CIDR-lohko: {cidr}.",
    cidrPrefixInvalid:
      "Virheellinen osoite: CIDR-prefiksin on oltava {min}–{max} (raja-arvot mukaan lukien).",
    inputRange: "Arvon on oltava {minValue} - {maxValue} merkkiä pitkä.",
    integerMax: "Arvon on oltava enintään {maxValue}.",
    integerMin: "Arvon on oltava vähintään {minValue}.",
    integerRange: "Arvon on oltava välillä {minValue}–{maxValue}.",
    integerValid: "Arvon on oltava sallittu kokonaisluku.",
    ipAddressInvalid:
      "Virheellinen osoite: väärin muodostettu IP-osoite: {address}",
    maxRows: "Saa olla enintään {maxRows} syötettä.",
    minRows: "On oltava vähintään {minRows} syötettä.",
    requiredValidation: "Arvo ei voi olla tyhjä.",
    radioValidation: "Annettu arvo ei ole sallittu vaihtoehto",
    timePickerValidation: "Virheellinen ajan muoto.",
    validationFailed:
      "Tarkistus epäonnistui. Pakolliset tiedot puuttuvat tai eivät ole sallittuja.",
    validationFailedWithTabHeader:
      "Seuraavan välilehden tarkistus epäonnistui: {tabHeader}. Vaaditut tiedot puuttuvat tai ne eivät ole sallittuja.",
    validationFailedWithTabHeaders:
      "Seuraavien välilehtien tarkistus epäonnistui: {tabHeaders}. Vaaditut tiedot puuttuvat tai ne eivät ole sallittuja.",
    validationInProgress: "Tarkistus käynnissä",
    validationMaxDate: "Arvon on oltava {maxDate}, {maxTime} tai ennen.",
    validationMinDate: "Arvon on oltava {minDate}, {minTime} tai jälkeen.",
    validationMinMaxDateRange:
      "Arvon on oltava välillä {minDate}, {minTime} ja {maxDate}, {maxTime}.",
    validationPassed: "Tarkistus läpäisty.",
    validationReservedWord: "Arvossa ei saa olla varattua sanaa {reserved}.",
    validationReservedWords:
      "Arvossa ei saa olla mitään seuraavista varatuista sanoista: {reserved}.",
    valueMaxLen: "Arvo saa olla enintään {maxLength} merkin pituinen.",
    valueMinLen: "Arvon on oltava vähintään {minLength} merkin pituinen.",
  },
};
bundled["fr-CA"] = {
  actions: {
    add: "Ajouter",
    addFilter: "Ajouter un filtre",
    addTags: "Cliquer ici pour ajouter des marqueurs",
    apply: "Appliquer",
    clearFilters: "Effacer les filtres",
    copiedToClipboard: "Copié",
    copyToClipboard: "Copier dans le presse-papiers",
    create: "Créer",
    delete: "Supprimer",
    edit: "Modifier",
    editTag: "Modifier le marqueur",
    next: "Suivant",
    previous: "Précédent",
    reviewCreate: "Vérifier + créer",
    selectAll: "Tout sélectionner",
    selectFile: "Sél. fichier",
    view: "Voir",
  },
  ariaLabel: {
    actionMenu: "Cliquer ici pour ouvrir le menu contextuel",
    actionMenuHeader: "Colonne de menu contextuel",
    close: "fermer",
    collapse: "Réduire",
    datePicker: "Sélectionner une date",
    delete: "Supprimer",
    dismissNotification: "Ignorer l'avis",
    expand: "Développer",
    fileUpload: "Charger un fichier",
    groupBy: "Regrouper par",
    info: "Informations",
    more: "Plus",
    nextMonth: "Mois suivant",
    page: "Page",
    previousMonth: "Mois précédent",
    required: "Requis",
    resourceList: "Liste des ressources",
    selectItem: "Sélectionner un élément",
    sliderInput: "Entrée de curseur",
    switchView:
      "Basculer entre une vue de liste ou une vue sommaire des données",
    timePicker: "Sélectionner une heure",
  },
  common: {
    addressesCount: "({count} adresses)",
    all: "Tout",
    cancel: "Annuler",
    confirm: "Confirmer",
    contains: "Contient",
    discard: "Abandonner",
    equals: "Est égal à",
    error: "Erreur",
    errorLoadingData: "Erreur lors du chargement des données",
    filter: "Filtrer",
    lastViewed: "Dernière consultation",
    less: "Moins",
    loading: "Chargement...",
    more: "Plus ({count})",
    name: "Nom",
    no: "Non",
    ok: "OK",
    operator: "Opérateur",
    required: "Requis",
    success: "Réussite",
    tag: "Marqueur",
    tags: "Marqueurs",
    type: "Type",
    value: "Valeur",
    yes: "Oui",
  },
  duration: {
    daysLong: "jours",
    daysShort: "j",
    daysSymbol: "j",
    hoursLong: "heures",
    hoursShort: "h",
    hoursSymbol: "h",
    millisecondsLong: "millisecondes",
    millisecondsShort: "ms",
    millisecondsSymbol: "ms",
    minutesLong: "minutes",
    minutesShort: "min",
    minutesSymbol: "mn.",
    secondsLong: "secondes",
    secondsShort: "sec",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtrer sur un champ...",
    filterPlaceholder: "Filtrer...",
    noResultsFound: "Aucun résultat trouvé.",
    requiredField: "Champ obligatoire",
    searchForField: "Recherche de {fieldName}",
    searchPlaceholder: "Rechercher",
    selectAnOption: "Sélectionner une option",
    selectExisting: "Sélectionner existant...",
    showSelection: "{count} sélectionné(s)",
  },
  labels: {
    addresses: "Adresses",
    essentials: "Notions de base",
    tags: "Marqueurs",
  },
  listTemplate: {
    learnMore: "En savoir plus",
    none: "Aucune",
    showing: "Affichage de {start} à {end} de {total} enregistrements.",
  },
  notifications: {
    apiErrorMsg:
      "Les détails supplémentaires fournis par l'API sous-jacente peuvent être utiles : {errMsg}",
    pollingErrorMessage: "Échec de la vérification du statut de l'opération.",
    pollingErrorTitle: "Échec de la vérification",
  },
  pagination: {
    next: "Suivant",
    of: "de {totalNumberOfPages}",
    page: "Page",
    previous: "Précédent",
  },
  status: {
    accepted: "Accepté",
    actionRequired: "Action requise",
    active: "Actif",
    applied: "Appliqué",
    applying: "Application",
    assigned: "Affecté",
    assigning: "Affectation",
    attached: "Attaché",
    attaching: "Attachement",
    available: "Disponible",
    availableNeedsAttention: "Disponible mais attention nécessaire",
    backupInProgress: "Sauvegarde en cours",
    blocked: "Bloqué",
    canceled: "Annulé",
    canceling: "Annulation",
    cancelled: "Annulé",
    cancelledReturned: "Annulé et retourné",
    checking: "Vérification",
    closed: "Fermé",
    complete: "Fin",
    completed: "Terminé",
    converting: "Conversion",
    created: "Créé",
    creating: "Création",
    creatingImage: "Création de l'image",
    deleted: "Supprimé",
    deleting: "Suppression",
    detaching: "Détachement",
    disabled: "Désactivé",
    disabling: "Désactivation",
    down: "Hors fonction",
    downForMaintenance: "Hors fonction pour maintenance",
    enabled: "Activé",
    enabling: "Activation",
    error: "Erreur",
    expired: "Expiré",
    exporting: "Exportation",
    failed: "Échec",
    failedApply: "Échec de l'application",
    failedRollback: "Échec du repositionnement",
    faulty: "Défaillant",
    importing: "Importation",
    inactive: "Inactif",
    initiated: "Lancé",
    inProgress: "En cours",
    internalError: "Erreur interne",
    loading: "Chargement",
    maintenanceInProgress: "Maintenance en cours",
    missing: "Manquant",
    packaged: "Assemblé",
    pendingCustomer: "En attente du client",
    pendingProvider: "En attente du fournisseur",
    pendingProvisioning: "En attente de provisionnement",
    preparing: "Préparation",
    processed: "Traité",
    processing: "Traitement",
    provisioned: "Provisionné",
    provisioning: "Provisionnement",
    queued: "En file d'attente",
    ready: "Prêt",
    received: "Reçu",
    related: "Connexe",
    requestReceived: "Demande reçue",
    restoreFailed: "Échec de la restauration",
    restoreInProgress: "Restauration en cours",
    restoring: "Restauration",
    returned: "Retourné",
    rollingBack: "Repositionnement",
    running: "Exécution",
    scaleInProgress: "Ajustement en cours",
    shipping: "Expédition",
    starting: "Démarrage",
    stopped: "Arrêté",
    stopping: "Arrêt",
    succeeded: "Réussite",
    terminated: "Fini",
    terminating: "Fin en cours",
    up: "En fonction",
    unassigned: "Non affecté",
    unassigning: "Annulation d'affectation",
    unavailable: "Non disponible",
    updated: "Mis à jour",
    updating: "Mise à jour",
    upgrading: "Mise à niveau",
    waiting: "Attente",
    warning: "Avertissement",
  },
  tags: {
    less: "Moins",
    more: "Plus ({count})",
  },
  validation: {
    addressInvalid:
      "Adresse non valide : 'Adresse IP ou bloc CIDR de format incorrect : {address}'.",
    addressOverlap: "Adresse non valide : '{address1} chevauche {address2}'.",
    addressReserved:
      "Adresse non valide : '{address} est une adresse réservée'.",
    addressReservedRange:
      "Adresse non valide : '{address} est dans un intervalle réservé {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Adresse non valide : '{cidr}' contient l'adresse réservée {address}'.",
    cidrHostBitsSet:
      "Adresse non valide : 'Les bits hôte ne doivent pas être définis dans le bloc CIDR'.",
    cidrInvalid:
      "Adresse non valide : 'Bloc CIDR de format incorrect : {cidr}'.",
    cidrPrefixInvalid:
      "Adresse non valide : 'Le préfixe CIDR doit être compris entre {min} et {max}'.",
    inputRange:
      "La valeur doit comporter entre {minValue} et {maxValue} caractères.",
    integerMax: "La valeur doit être au plus {maxValue}.",
    integerMin: "La valeur doit être au moins {minValue}.",
    integerRange:
      "La valeur doit être comprise entre {minValue} et {maxValue}.",
    integerValid: "La valeur doit avoir un nombre entier valide.",
    ipAddressInvalid:
      "Adresse non valide : 'Adresse IP de format incorrect : {address}'",
    maxRows: "Il doit y avoir au plus {maxRows} entrées.",
    minRows: "Il doit y avoir au moins {minRows} entrées.",
    requiredValidation: "La valeur ne doit pas être vide.",
    radioValidation: "La valeur fournie n'est pas une option valide.",
    timePickerValidation: "Format d'heure non valide.",
    validationFailed:
      "Échec de la validation. Des informations requises sont manquantes ou non valides.",
    validationFailedWithTabHeader:
      "Échec de la validation pour l'onglet suivant : {tabHeader}. Des informations requises sont manquantes ou non valides.",
    validationFailedWithTabHeaders:
      "Échec de la validation pour les onglets suivants : {tabHeaders}. Des informations requises sont manquantes ou non valides.",
    validationInProgress: "Validation en cours",
    validationMaxDate:
      "La valeur doit être {maxDate}, {maxTime} ou un moment antérieur.",
    validationMinDate:
      "La valeur doit être {minDate}, {minTime} ou un moment postérieur.",
    validationMinMaxDateRange:
      "La valeur doit être comprise entre {minDate}, {minTime} et {maxDate}, {maxTime}.",
    validationPassed: "Validation réussie.",
    validationReservedWord:
      "La valeur ne peut pas contenir le mot réservé '{reserved}'.",
    validationReservedWords:
      "La valeur ne peut contenir aucun des mots réservés suivants : {reserved}.",
    valueMaxLen: "La valeur doit comporter au maximum {maxLength} caractères.",
    valueMinLen: "La valeur doit comporter au moins {minLength} caractères.",
  },
};
bundled["fr"] = {
  actions: {
    add: "Ajouter",
    addFilter: "Ajouter un filtre",
    addTags: "Cliquez ici pour ajouter des balises",
    apply: "Appliquer",
    clearFilters: "Effacer les filtres",
    copiedToClipboard: "Copié",
    copyToClipboard: "Copier dans le presse-papiers",
    create: "Créer",
    delete: "Supprimer",
    edit: "Modifier",
    editTag: "Modifier la balise",
    next: "Suivant",
    previous: "Précédent",
    reviewCreate: "Vérifier + créer",
    selectAll: "Sélectionner tout",
    selectFile: "Sélectionner un fichier",
    view: "Visualiser",
  },
  ariaLabel: {
    actionMenu: "Cliquez sur cette option pour ouvrir le menu contextuel",
    actionMenuHeader: "Colonne de menu contextuel",
    close: "fermer",
    collapse: "Réduire",
    datePicker: "Sélectionner une date",
    delete: "Supprimer",
    dismissNotification: "Ignorer la notification",
    expand: "Développer",
    fileUpload: "Télécharger un fichier",
    groupBy: "Regrouper par",
    info: "Informations",
    more: "Plus",
    nextMonth: "Mois suivant",
    page: "Page",
    previousMonth: "Mois précédent",
    required: "Requis",
    resourceList: "Liste des ressources",
    selectItem: "Sélectionner un élément",
    sliderInput: "Entrée de curseur",
    switchView:
      "Basculer entre une vue de liste ou une vue récapitulative des données",
    timePicker: "Sélectionner une heure",
  },
  common: {
    addressesCount: "({count} adresses)",
    all: "Tout",
    cancel: "Annuler",
    confirm: "Confirmer",
    contains: "Contient",
    discard: "Ignorer",
    equals: "Est égal à",
    error: "Erreur",
    errorLoadingData: "Erreur lors du chargement des données",
    filter: "Filtre",
    lastViewed: "Dernière visualisation",
    less: "Moins",
    loading: "Chargement...",
    more: "Plus ({count})",
    name: "Nom",
    no: "Non",
    ok: "OK",
    operator: "Opérateur",
    required: "Requis",
    success: "Succès",
    tag: "Balise",
    tags: "Balises",
    type: "Type",
    value: "Valeur",
    yes: "Oui",
  },
  duration: {
    daysLong: "jours",
    daysShort: "j",
    daysSymbol: "j",
    hoursLong: "heures",
    hoursShort: "h",
    hoursSymbol: "h",
    millisecondsLong: "millisecondes",
    millisecondsShort: "ms",
    millisecondsSymbol: "ms",
    minutesLong: "minutes",
    minutesShort: "min",
    minutesSymbol: "m",
    secondsLong: "secondes",
    secondsShort: "s",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtre pour n'importe quel champ...",
    filterPlaceholder: "Filtrer...",
    noResultsFound: "Aucun résultat trouvé.",
    requiredField: "Champ obligatoire",
    searchForField: "Rechercher {fieldName}",
    searchPlaceholder: "Rechercher",
    selectAnOption: "Sélectionner une option",
    selectExisting: "Sélectionner un élément existant...",
    showSelection: "Sélection : {count}",
  },
  labels: {
    addresses: "Adresses",
    essentials: "Notions de base",
    tags: "Balises",
  },
  listTemplate: {
    learnMore: "En savoir plus",
    none: "Aucun",
    showing: "Affichage de {start} à {end} sur {total} enregistrements.",
  },
  notifications: {
    apiErrorMsg:
      "Détails supplémentaires de l'API sous-jacente qui pourraient être utiles : {errMsg}",
    pollingErrorMessage: "Echec de la vérification du statut de l'opération.",
    pollingErrorTitle: "Echec de la vérification",
  },
  pagination: {
    next: "Suivant",
    of: "sur {totalNumberOfPages}",
    page: "Page",
    previous: "Précédent",
  },
  status: {
    accepted: "Accepté",
    actionRequired: "Action requise",
    active: "Actif",
    applied: "Appliqué",
    applying: "Application",
    assigned: "Affecté",
    assigning: "Affectation",
    attached: "Attaché",
    attaching: "Attachement",
    available: "Disponible",
    availableNeedsAttention: "Disponible, attention requise",
    backupInProgress: "Sauvegarde en cours",
    blocked: "Bloqué",
    canceled: "Annulé",
    canceling: "Annulation",
    cancelled: "Annulé",
    cancelledReturned: "Annulé et renvoyé",
    checking: "Vérification",
    closed: "Fermé",
    complete: "Exécution",
    completed: "Terminé",
    converting: "Conversion en cours",
    created: "Créé",
    creating: "Création",
    creatingImage: "Création de l'image",
    deleted: "Supprimé",
    deleting: "Suppression",
    detaching: "Détachement",
    disabled: "Désactivé",
    disabling: "Désactivation",
    down: "Arrêté",
    downForMaintenance: "Arrêté pour maintenance",
    enabled: "Activé",
    enabling: "Activation",
    error: "Erreur",
    expired: "Expiré",
    exporting: "Export",
    failed: "Echec",
    failedApply: "Echec de l'application",
    failedRollback: "Echec de l'annulation",
    faulty: "Défectueux",
    importing: "Import",
    inactive: "Inactif",
    initiated: "Lancé",
    inProgress: "En cours",
    internalError: "Erreur interne",
    loading: "Chargement",
    maintenanceInProgress: "Maintenance en cours",
    missing: "Manquant",
    packaged: "Packagé",
    pendingCustomer: "Client en attente",
    pendingProvider: "Fournisseur en attente",
    pendingProvisioning: "Provisionnement en attente",
    preparing: "Préparation",
    processed: "Traité",
    processing: "Traitement",
    provisioned: "Provisionné",
    provisioning: "Provisionnement",
    queued: "En file d'attente",
    ready: "Prêt",
    received: "Reçu",
    related: "Associé",
    requestReceived: "Demande reçue",
    restoreFailed: "Echec de la restauration",
    restoreInProgress: "Restauration en cours",
    restoring: "Restauration",
    returned: "Renvoyé",
    rollingBack: "Annulation (rollback)",
    running: "En cours d'exécution",
    scaleInProgress: "Redimensionnement en cours",
    shipping: "Expédition",
    starting: "Démarrage",
    stopped: "Arrêté",
    stopping: "Arrêt",
    succeeded: "Succès",
    terminated: "Ayant pris fin",
    terminating: "Terminaison",
    up: "Démarré",
    unassigned: "Non affecté",
    unassigning: "Annulation de l'affectation",
    unavailable: "Indisponible",
    updated: "Mis à jour",
    updating: "Mise à jour",
    upgrading: "Mise à niveau",
    waiting: "En attente",
    warning: "Avertissement",
  },
  tags: {
    less: "Moins",
    more: "Plus ({count})",
  },
  validation: {
    addressInvalid:
      "Adresse non valide. Format de l'adresse IP ou du bloc CIDR incorrect : {address}.",
    addressOverlap: "Adresse non valide : {address1} chevauche {address2}.",
    addressReserved: "Adresse non valide : {address} est réservée.",
    addressReservedRange:
      "Adresse non valide : {address} se trouve dans la plage réservée {cidr} ({startingIp} - {endingIp}).",
    asyncValidationFailed: "<strong>[{tabField}]</strong> : {error}",
    cidrHasReservedIp:
      "Adresse non valide : '{cidr}' contient l'adresse réservée {address}.",
    cidrHostBitsSet:
      "Adresse non valide : les bits hôte ne peuvent pas être définis dans le CIDR.",
    cidrInvalid: "Adresse non valide. Format du bloc CIDR incorrect : {cidr}.",
    cidrPrefixInvalid:
      "Adresse non valide : le préfixe CIDR doit être compris entre {min} et {max} inclus.",
    inputRange:
      "La valeur doit comporter entre {minValue} et {maxValue} caractères.",
    integerMax: "La valeur doit être au maximum de {maxValue}.",
    integerMin: "La valeur doit être au minimum de {minValue}.",
    integerRange:
      "La valeur doit être comprise entre {minValue} et {maxValue}.",
    integerValid: "La valeur doit être un entier valide.",
    ipAddressInvalid:
      "Adresse non valide. Format de l'adresse IP incorrect : {address}",
    maxRows: "Le nombre maximal d'entrées est de {maxRows}.",
    minRows: "Le nombre minimal d'entrées est de {minRows}.",
    requiredValidation: "La valeur ne doit pas être vide.",
    radioValidation: "La valeur fournie n'est pas une option valide",
    timePickerValidation: "Format d'heure non valide.",
    validationFailed:
      "Echec de la validation. Des informations requises sont manquantes ou non valides.",
    validationFailedWithTabHeader:
      "Echec de la validation pour l'onglet suivant : {tabHeader}. Des informations requises sont manquantes ou ne sont pas valides.",
    validationFailedWithTabHeaders:
      "Echec de la validation pour les onglets suivants : {tabHeaders}. Des informations requises sont manquantes ou ne sont pas valides.",
    validationInProgress: "Validation en cours",
    validationMaxDate:
      "La valeur doit être {maxDate}, {maxTime} ou une valeur antérieure.",
    validationMinDate:
      "La valeur doit être {minDate}, {minTime} ou une valeur postérieure.",
    validationMinMaxDateRange:
      "La valeur doit être comprise entre {minDate}, {minTime} et {maxDate}, {maxTime}.",
    validationPassed: "Validation réussie.",
    validationReservedWord:
      "La valeur ne peut pas contenir le mot réservé '{reserved}'.",
    validationReservedWords:
      "La valeur ne peut contenir aucun des mots réservés suivants : {reserved}.",
    valueMaxLen: "La valeur doit comporter {maxLength} caractères au maximum.",
    valueMinLen: "La valeur doit comporter au moins {minLength} caractères.",
  },
};
bundled["hr"] = {
  actions: {
    add: "Dodaj",
    addFilter: "Dodaj filtar",
    addTags: "Pritisnite ovdje za dodavanje oznaka",
    apply: "Primijeni",
    clearFilters: "Očisti filtre",
    copiedToClipboard: "Kopirano",
    copyToClipboard: "Kopiraj u međuspremnik",
    create: "Stvori",
    delete: "Izbriši",
    edit: "Uredi",
    editTag: "Uredi oznaku",
    next: "Sljedeće",
    previous: "Prethodno",
    reviewCreate: "Pregledaj + stvori",
    selectAll: "Odaberi sve",
    selectFile: "Odaberite datoteku",
    view: "Prikaži",
  },
  ariaLabel: {
    actionMenu: "Pritisnite za otvaranje kontekstnog izbornika",
    actionMenuHeader: "Stupac kontekstnog izbornika",
    close: "zatvori",
    collapse: "Sakrij",
    datePicker: "Odaberite datum",
    delete: "Izbriši",
    dismissNotification: "Odbaci obavijest",
    expand: "Proširi",
    fileUpload: "Učitaj datoteku",
    groupBy: "Način grupiranja",
    info: "Informacije",
    more: "Više",
    nextMonth: "Sljedeći mjesec",
    page: "Stranica",
    previousMonth: "Prethodni mjesec",
    required: "Obavezno",
    resourceList: "Popis resursa",
    selectItem: "Odaberi proizvod",
    sliderInput: "Unos klizača",
    switchView:
      "Prebacuj se između prikaza popisa ili prikaza sažetka podataka",
    timePicker: "Odaberite vrijeme",
  },
  common: {
    addressesCount: "(broj adresa: {count})",
    all: "Sve",
    cancel: "Odustani",
    confirm: "Potvrdi",
    contains: "Sadrži",
    discard: "Odbaci",
    equals: "Jednako",
    error: "Pogreška",
    errorLoadingData: "Pogreška pri učitavanju podataka",
    filter: "Filtriraj",
    lastViewed: "Zadnje prikazano",
    less: "Manje",
    loading: "Učitava se...",
    more: "Više ({count})",
    name: "Naziv",
    no: "Ne",
    ok: "U redu",
    operator: "Operator",
    required: "Obavezno",
    success: "Uspjeh",
    tag: "Oznaka",
    tags: "Oznake",
    type: "Vrsta",
    value: "Vrijednost",
    yes: "Da",
  },
  duration: {
    daysLong: "dana",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "h",
    hoursShort: "h",
    hoursSymbol: "h",
    millisecondsLong: "milisekunde",
    millisecondsShort: "ms",
    millisecondsSymbol: "ms",
    minutesLong: "min",
    minutesShort: "min",
    minutesSymbol: "m",
    secondsLong: "s",
    secondsShort: "s",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtar za bilo koje polje...",
    filterPlaceholder: "Filtriraj...",
    noResultsFound: "Nisu pronađeni rezultati.",
    requiredField: "Obavezno polje",
    searchForField: "Traži {fieldName}",
    searchPlaceholder: "Traži",
    selectAnOption: "Odaberite opciju",
    selectExisting: "Odaberi postojeće...",
    showSelection: "{count} - odabrano",
  },
  labels: {
    addresses: "Adrese",
    essentials: "Osnove",
    tags: "Oznake",
  },
  listTemplate: {
    learnMore: "Saznajte više",
    none: "Ništa",
    showing: "Prikazuju se zapisi {start} do {end} od {total} zapisa.",
  },
  notifications: {
    apiErrorMsg:
      "Dodatne pojedinosti iz pripadajućeg API-ja koje mogu biti od pomoći: {errMsg}",
    pollingErrorMessage: "Provjera statusa operacije nije uspjela.",
    pollingErrorTitle: "Provjera nije uspjela",
  },
  pagination: {
    next: "Sljedeće",
    of: "od {totalNumberOfPages}",
    page: "Stranica",
    previous: "Prethodno",
  },
  status: {
    accepted: "Prihvaćeno",
    actionRequired: "Potrebna akcija",
    active: "Aktivno",
    applied: "Primijenjeno",
    applying: "Primjenjuje se",
    assigned: "Dodijeljeno",
    assigning: "Dodjeljuje se",
    attached: "Pridruženo",
    attaching: "Pridružuje se",
    available: "Dostupno",
    availableNeedsAttention: "Dostupno, potrebna provjera",
    backupInProgress: "Sigurnosno kopiranje u tijeku",
    blocked: "Blokirano",
    canceled: "Poništeno",
    canceling: "Poništava se",
    cancelled: "Poništeno",
    cancelledReturned: "Poništen povrat",
    checking: "Provjerava se",
    closed: "Zatvoreno",
    complete: "Dovrši",
    completed: "Dovršeno",
    converting: "Konverzija",
    created: "Stvoreno",
    creating: "Stvara se",
    creatingImage: "Stvara se slika",
    deleted: "Izbrisano",
    deleting: "Briše se",
    detaching: "Odvaja se",
    disabled: "Onemogućeno",
    disabling: "Onemogućava se",
    down: "Ne radi",
    downForMaintenance: "Ne radi zbog održavanja",
    enabled: "Omogućeno",
    enabling: "Omogućava se",
    error: "Pogreška",
    expired: "Isteklo",
    exporting: "Izvozi se",
    failed: "Nije uspjelo",
    failedApply: "Neuspjela primjena",
    failedRollback: "Neuspjelo vraćanje",
    faulty: "Neispravno",
    importing: "Uvozi se",
    inactive: "Neaktivno",
    initiated: "Pokrenuto",
    inProgress: "U tijeku",
    internalError: "Interna pogreška",
    loading: "Učitava se",
    maintenanceInProgress: "U tijeku je održavanje",
    missing: "Nedostaje",
    packaged: "Zapakirano",
    pendingCustomer: "Čeka se korisnik",
    pendingProvider: "Čeka se dobavljač",
    pendingProvisioning: "Čeka se implementacija",
    preparing: "Priprema se",
    processed: "Obrađeno",
    processing: "Obrađuje se",
    provisioned: "Implementirano",
    provisioning: "Implementira se",
    queued: "U redu čekanja",
    ready: "Spremno",
    received: "Primljeno",
    related: "Povezano",
    requestReceived: "Primljen zahtjev",
    restoreFailed: "Vraćanje nije uspjelo",
    restoreInProgress: "Vraćanje u tijeku",
    restoring: "Vraća se",
    returned: "Vraćeno",
    rollingBack: "Vraća se na raniju verziju",
    running: "Pokrenuto",
    scaleInProgress: "U tijeku prilagodba veličine",
    shipping: "Otprema se",
    starting: "Pokreće se",
    stopped: "Zaustavljeno",
    stopping: "Zaustavlja se",
    succeeded: "Uspjelo",
    terminated: "Ukinuto",
    terminating: "Ukida se",
    up: "Aktivno",
    unassigned: "Nedodijeljeno",
    unassigning: "Poništava se dodjela",
    unavailable: "Nedostupno",
    updated: "Ažurirano",
    updating: "Ažurira se",
    upgrading: "Nadogradnja",
    waiting: "Na čekanju",
    warning: "Upozorenje",
  },
  tags: {
    less: "Manje",
    more: "Više ({count})",
  },
  validation: {
    addressInvalid:
      "Adresa nije valjana: 'neispravno oblikovana IP adresa ili CIDR blok: {address}'.",
    addressOverlap:
      "Adresa nije valjana: '{address1} preklapa se s {address2}'.",
    addressReserved: "Adresa nije valjana: '{address} je rezervirana adresa'.",
    addressReservedRange:
      "Adresa nije valjana: '{address} nalazi se unutar rezerviranog raspona {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Adresa nije valjana: '{cidr}' sadrži rezerviranu adresu {address}'.",
    cidrHostBitsSet:
      "Adresa nije valjana: 'CIDR ne može sadržavati skup bitova glavnog računala'.",
    cidrInvalid:
      "Adresa nije valjana: neispravno oblikovan CIDR blok: {cidr}'.",
    cidrPrefixInvalid:
      "Adresa nije valjana: 'CIDR prefiks mora biti između {min} i {max}, uključujući te vrijednosti'.",
    inputRange:
      "Vrijednost mora sadržavati između {minValue} i {maxValue} znakova.",
    integerMax: "Vrijednost mora biti najviše {maxValue}.",
    integerMin: "Vrijednost mora biti najmanje {minValue}.",
    integerRange: "Vrijednost mora biti između {minValue} i {maxValue}.",
    integerValid: "Vrijednost mora biti valjani cijeli broj.",
    ipAddressInvalid:
      "Adresa nije valjana: neispravno oblikovana IP adresa: {address}'",
    maxRows: "Maksimalni je broj unosa {maxRows}.",
    minRows: "Minimalni je broj unosa {minRows}.",
    requiredValidation: "Vrijednost ne smije biti prazna.",
    radioValidation: "Navedena vrijednost nije valjana opcija",
    timePickerValidation: " Format vremena nije valjan.",
    validationFailed:
      "Provjera valjanosti nije uspjela. Tražene informacije nedostaju ili nisu valjane.",
    validationFailedWithTabHeader:
      "Provjera valjanosti nije uspjela za sljedeću karticu: {tabHeader}. Tražene informacije nedostaju ili nisu valjane.",
    validationFailedWithTabHeaders:
      "Provjera valjanosti nije uspjela za sljedeće kartice: {tabHeaders}. Tražene informacije nedostaju ili nisu valjane.",
    validationInProgress: "Provjera valjanosti u tijeku",
    validationMaxDate:
      "Vrijednost treba iznositi {maxDate}, {maxTime} ili prije.",
    validationMinDate:
      "Vrijednost treba iznositi {minDate}, {minTime} ili kasnije.",
    validationMinMaxDateRange:
      "Vrijednost bi trebala biti između {minDate}, {minTime} i {maxDate}. {maxTime}.",
    validationPassed: "Provjera valjanosti propuštena.",
    validationReservedWord:
      "Vrijednost ne može sadržavati rezerviranu riječ '{reserved}'.",
    validationReservedWords:
      "Vrijednost ne može sadržavati nijednu od sljedećih rezerviranih riječi: {reserved}.",
    valueMaxLen: "Vrijednost smije imati maksimalno {maxLength} znakova.",
    valueMinLen:
      "Vrijednost smije imati minimalno sljedeći broj znakova: {minLength}.",
  },
};
bundled["hu"] = {
  actions: {
    add: "Hozzáadás",
    addFilter: "Szűrő hozzáadása",
    addTags: "Kattintson ide a címkék hozzáadásához",
    apply: "Alkalmaz",
    clearFilters: "Szűrők törlése",
    copiedToClipboard: "Másolva",
    copyToClipboard: "Másolás a vágólapra",
    create: "Létrehozás",
    delete: "Törlés",
    edit: "Szerkesztés",
    editTag: "Címke szerkesztése",
    next: "Következő",
    previous: "Előző",
    reviewCreate: "Ellenőrzés és létrehozás",
    selectAll: "Összes kijelölése",
    selectFile: "Válasszon fájlt",
    view: "Megtekintés",
  },
  ariaLabel: {
    actionMenu: "Kattintson ide a helyi menü megnyitásához",
    actionMenuHeader: "Helyi menü oszlopa",
    close: "bezárás",
    collapse: "Összecsukás",
    datePicker: "Dátum kiválasztása",
    delete: "Törlés",
    dismissNotification: "Értesítés elvetése",
    expand: "Kibontás",
    fileUpload: "Fájl feltöltése",
    groupBy: "Csoportosítási szempont",
    info: "Információ",
    more: "Továbbiak",
    nextMonth: "Következő hónap",
    page: "Oldal",
    previousMonth: "Előző hónap",
    required: "Kötelező",
    resourceList: "Erőforráslista",
    selectItem: "Elem kiválasztása",
    sliderInput: "Csúszka bevitele",
    switchView: "Váltás listanézet és az adatok összesítő nézete között",
    timePicker: "Időpont kiválasztása",
  },
  common: {
    addressesCount: "({count} cím)",
    all: "Mind",
    cancel: "Mégse",
    confirm: "Megerősítés",
    contains: "Tartalmazza",
    discard: "Elvetés",
    equals: "Egyenlő",
    error: "Hiba",
    errorLoadingData: "Hiba történt az adatok betöltésekor",
    filter: "Szűrő",
    lastViewed: "Utolsó megtekintés",
    less: "Kevesebb",
    loading: "Betöltés...",
    more: "Több ({count})",
    name: "Név",
    no: "Nem",
    ok: "OK",
    operator: "Operátor",
    required: "Kötelező",
    success: "Siker",
    tag: "Címke",
    tags: "Címkék",
    type: "Típus",
    value: "Érték",
    yes: "Igen",
  },
  duration: {
    daysLong: "nap",
    daysShort: "n",
    daysSymbol: "n",
    hoursLong: "óra",
    hoursShort: "óra",
    hoursSymbol: "ó",
    millisecondsLong: "ezredmásodperc",
    millisecondsShort: "ezredmp",
    millisecondsSymbol: "e.mp",
    minutesLong: "perc",
    minutesShort: "perc",
    minutesSymbol: "p",
    secondsLong: "másodperc",
    secondsShort: "mp",
    secondsSymbol: "mp",
  },
  hints: {
    filterAnyField: "Szűrés bármilyen mezőhöz...",
    filterPlaceholder: "Szűrés...",
    noResultsFound: "Nincs találat.",
    requiredField: "Kötelező mező",
    searchForField: "{fieldName} keresése",
    searchPlaceholder: "Keresés",
    selectAnOption: "Válasszon egy beállítást",
    selectExisting: "Meglévő kiválasztása...",
    showSelection: "{count} kiválasztva",
  },
  labels: {
    addresses: "Címek",
    essentials: "Alapvető dolgok",
    tags: "Címkék",
  },
  listTemplate: {
    learnMore: "További tudnivalók",
    none: "Nincs",
    showing: "{total} rekordból {start} - {end} megjelenítése.",
  },
  notifications: {
    apiErrorMsg:
      "A háttérben futó API-tól származó további részletek, melyek hasznosak lehetnek: {errMsg}",
    pollingErrorMessage: "Nem sikerült ellenőrizni a művelet állapotát.",
    pollingErrorTitle: "Sikertelen ellenőrzés",
  },
  pagination: {
    next: "Következő",
    of: "összesen: {totalNumberOfPages}",
    page: "Oldal",
    previous: "Előző",
  },
  status: {
    accepted: "Elfogadva",
    actionRequired: "Beavatkozás szükséges",
    active: "Aktív",
    applied: "Alkalmazva",
    applying: "Alkalmazás",
    assigned: "Hozzárendelve",
    assigning: "Hozzárendelés",
    attached: "Csatolva",
    attaching: "Csatolás",
    available: "Elérhető",
    availableNeedsAttention: "Elérhető - figyelmet igényel",
    backupInProgress: "Biztonsági mentés folyamatban",
    blocked: "Blokkolva",
    canceled: "Megszakítva",
    canceling: "Megszakítás",
    cancelled: "Megszakítva",
    cancelledReturned: "Érvénytelenítve és visszaküldve",
    checking: "Ellenőrzés",
    closed: "Lezárva",
    complete: "Befejezett",
    completed: "Befejezve",
    converting: "Átalakítás",
    created: "Létrehozva",
    creating: "Létrehozás",
    creatingImage: "Kép létrehozása",
    deleted: "Törölve",
    deleting: "Törlés",
    detaching: "Leválasztás",
    disabled: "Letiltva",
    disabling: "Letiltás",
    down: "Nem üzemel",
    downForMaintenance: "Karbantartási leállás",
    enabled: "Engedélyezve",
    enabling: "Engedélyezés",
    error: "Hiba",
    expired: "Lejárt",
    exporting: "Exportálás",
    failed: "Sikertelen",
    failedApply: "Sikertelen alkalmazás",
    failedRollback: "Sikertelen visszagörgetés",
    faulty: "Hibás",
    importing: "Importálás",
    inactive: "Inaktív",
    initiated: "Elindítva",
    inProgress: "Folyamatban",
    internalError: "Belső hiba",
    loading: "Betöltés",
    maintenanceInProgress: "Karbantartás folyamatban",
    missing: "Hiányzik",
    packaged: "Csomagolt",
    pendingCustomer: "Függőben lévő ügyfél",
    pendingProvider: "Függőben lévő szolgáltató",
    pendingProvisioning: "Függőben lévő előkészítés",
    preparing: "Előkészítés",
    processed: "Feldolgozva",
    processing: "Feldolgozás",
    provisioned: "Előkészítve",
    provisioning: "Előkészítés",
    queued: "Sorba állítva",
    ready: "Kész",
    received: "Beérkezett",
    related: "Kapcsolódó",
    requestReceived: "Kérelem érkezett",
    restoreFailed: "Sikertelen visszaállítás",
    restoreInProgress: "Visszaállítás folyamatban",
    restoring: "Visszaállítás",
    returned: "Visszaküldve",
    rollingBack: "Visszagörgetés",
    running: "Fut",
    scaleInProgress: "Méretezés folyamatban",
    shipping: "Szállítás",
    starting: "Indítás",
    stopped: "Leállítva",
    stopping: "Leállítás",
    succeeded: "Sikeres",
    terminated: "Megszüntetve",
    terminating: "Megszüntetés",
    up: "Üzemel",
    unassigned: "Nincs hozzárendelve",
    unassigning: "Hozzárendelés megszüntetése",
    unavailable: "Nem áll rendelkezésre",
    updated: "Módosítva",
    updating: "Módosítás",
    upgrading: "Frissítés",
    waiting: "Várakozik",
    warning: "Figyelmeztetés",
  },
  tags: {
    less: "Kevesebb",
    more: "Több ({count})",
  },
  validation: {
    addressInvalid:
      "Érvénytelen cím: 'Helytelen formátumú IP-cím vagy CIDR-blokk: {address}'.",
    addressOverlap:
      "Érvénytelen cím: 'A(z) {address1} cím átfedésben van a(z) {address2} címmel'.",
    addressReserved: "Érvénytelen cím: 'A(z) {address} cím foglalt'.",
    addressReservedRange:
      "Érvénytelen cím: 'A(z) {address} cím a lefoglalt tartományon belül van - {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Érvénytelen cím 'A(z) {cidr} a(z) {address} foglalt címet tartalmazza'.",
    cidrHostBitsSet:
      "Érvénytelen cím: 'A CIDR nem rendelkezhet a gazdagépbitek számával'.",
    cidrInvalid: "Érvénytelen cím: 'Helytelen formátumú CIDR-blokk: {cidr}'.",
    cidrPrefixInvalid:
      "Érvénytelen cím: 'A CIDR-előtagnak {min} és {max} közé kell esnie (a határokat is beleértve)'.",
    inputRange:
      "Az értéknek {minValue} és {maxValue} közötti számú karakterből kell állnia.",
    integerMax: "Az érték legfeljebb {maxValue} lehet.",
    integerMin: "Az értéknek legalább ennyinek kell lennie: {minValue}.",
    integerRange:
      "Az értéknek {minValue} és {maxValue} közöttinek kell lennie.",
    integerValid: "Az értéknek érvényes egész számnak kell lennie.",
    ipAddressInvalid:
      "Érvénytelen cím: 'Helytelen formátumú IP-cím: {address}'",
    maxRows: "Legfeljebb {maxRows} bejegyzésnek kell lennie.",
    minRows: "Legalább {minRows} bejegyzésnek kell lennie.",
    requiredValidation: "Az érték nem lehet üres.",
    radioValidation: "A megadott érték nem érvényes beállítás",
    timePickerValidation: "Érvénytelen időformátum.",
    validationFailed:
      "Sikertelen érvényesítés. Hiányzik a szükséges információ, vagy nem érvényes.",
    validationFailedWithTabHeader:
      "Az érvényesítés sikertelen volt a következő lapnál: {tabHeader}. Hiányzik a szükséges információ, vagy nem érvényes.",
    validationFailedWithTabHeaders:
      "Az érvényesítés sikertelen volt a következő lapoknál: {tabHeaders}. Hiányzik a szükséges információ, vagy nem érvényes.",
    validationInProgress: "Az érvényesítés folyamatban van",
    validationMaxDate:
      "Az értéknek korábbinak vagy egyenlőnek kell lennie a következővel: {maxDate}, {maxTime}.",
    validationMinDate:
      "Az értéknek későbbinek vagy egyenlőnek kell lennie a következővel: {minDate}, {minTime}.",
    validationMinMaxDateRange:
      "Az értéknek {minDate}, {minTime} és {maxDate}, {maxTime} közöttinek kell lennie.",
    validationPassed: "Sikeres érvényesítés.",
    validationReservedWord:
      "Az érték nem tartalmazhatja a rendszer által lefoglalt '{reserved}' szót.",
    validationReservedWords:
      "Az érték nem tartalmazhatja a rendszer által lefoglalt következő szavakat: {reserved}.",
    valueMaxLen: "Az érték legfeljebb {maxLength} karakterből állhat.",
    valueMinLen:
      "Az értéknek legalább {minLength} karakter hosszúságúnak kell lennie.",
  },
};
bundled["it"] = {
  actions: {
    add: "Aggiungi",
    addFilter: "Aggiungi filtro",
    addTags: "Fare clic qui per aggiungere tag",
    apply: "Applica",
    clearFilters: "Cancella filtri",
    copiedToClipboard: "Copia eseguita",
    copyToClipboard: "Copia negli Appunti",
    create: "Crea",
    delete: "Elimina",
    edit: "Modifica",
    editTag: "Modifica tag",
    next: "Successivo",
    previous: "Precedente",
    reviewCreate: "Rivedi + crea",
    selectAll: "Seleziona tutto",
    selectFile: "Selezionare un file",
    view: "Visualizza",
  },
  ariaLabel: {
    actionMenu: "Fare clic per aprire il menu di scelta rapida",
    actionMenuHeader: "Colonna del menu di scelta rapida",
    close: "chiudi",
    collapse: "Comprimi",
    datePicker: "Seleziona una data",
    delete: "Elimina",
    dismissNotification: "Ignora notifica",
    expand: "Espandi",
    fileUpload: "Carica un file",
    groupBy: "Raggruppa per",
    info: "Informazioni",
    more: "Altro",
    nextMonth: "Mese successivo",
    page: "Pagina",
    previousMonth: "Mese precedente",
    required: "Obbligatorio",
    resourceList: "Lista risorse",
    selectItem: "Seleziona elemento",
    sliderInput: "Input del dispositivo di scorrimento",
    switchView:
      "Passare da una vista lista a una vista di riepilogo dei dati e viceversa",
    timePicker: "Seleziona un orario",
  },
  common: {
    addressesCount: "({count} indirizzi)",
    all: "Tutto",
    cancel: "Annulla",
    confirm: "Conferma",
    contains: "Contiene",
    discard: "Elimina",
    equals: "Uguale a",
    error: "Errore",
    errorLoadingData: "Errore durante il caricamento dei dati",
    filter: "Filtro",
    lastViewed: "Ultima visualizzazione",
    less: "Meno",
    loading: "Caricamento in corso...",
    more: "Altro/i ({count})",
    name: "Nome",
    no: "No",
    ok: "OK",
    operator: "Operatore",
    required: "Obbligatorio",
    success: "Operazione riuscita",
    tag: "Tag",
    tags: "Tag",
    type: "Tipo",
    value: "Valore",
    yes: "Sì",
  },
  duration: {
    daysLong: "giorni",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "ore",
    hoursShort: "h",
    hoursSymbol: "h",
    millisecondsLong: "millisecondi",
    millisecondsShort: "msec",
    millisecondsSymbol: "ms",
    minutesLong: "minuti",
    minutesShort: "min",
    minutesSymbol: "m",
    secondsLong: "secondi",
    secondsShort: "sec",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtra per qualsiasi campo...",
    filterPlaceholder: "Filtra...",
    noResultsFound: "Nessun risultato trovato.",
    requiredField: "Campo obbligatorio",
    searchForField: "Cerca {fieldName}",
    searchPlaceholder: "Cerca",
    selectAnOption: "Selezionare un'opzione",
    selectExisting: "Seleziona esistenti...",
    showSelection: "{count} selezionati",
  },
  labels: {
    addresses: "Indirizzi",
    essentials: "Nozioni fondamentali",
    tags: "Tag",
  },
  listTemplate: {
    learnMore: "Ulteriori informazioni",
    none: "Nessuno",
    showing: "Visualizzazione da {start} a {end} per {total} record totali.",
  },
  notifications: {
    apiErrorMsg:
      "Ulteriori dettagli dall'API di base che potrebbero essere utili: {errMsg}",
    pollingErrorMessage: "Controllo dello stato dell'operazione non riuscito.",
    pollingErrorTitle: "Controllo non riuscito",
  },
  pagination: {
    next: "Successivo",
    of: "di {totalNumberOfPages}",
    page: "Pagina",
    previous: "Precedente",
  },
  status: {
    accepted: "Accettato",
    actionRequired: "Azione richiesta",
    active: "Attivo",
    applied: "Applicato",
    applying: "In fase di applicazione",
    assigned: "Assegnato",
    assigning: "In fase di assegnazione",
    attached: "Collegato",
    attaching: "In fase di collegamento",
    available: "Disponibile",
    availableNeedsAttention: "Disponibile richiede attenzione",
    backupInProgress: "Backup in corso",
    blocked: "Bloccato",
    canceled: "Annullato",
    canceling: "In fase di annullamento",
    cancelled: "Annullato",
    cancelledReturned: "Annullato restituito",
    checking: "In fase di controllo",
    closed: "Chiuso",
    complete: "Completo",
    completed: "Completato",
    converting: "Conversione",
    created: "Creato",
    creating: "In fase di creazione",
    creatingImage: "In fase di creazione immagine",
    deleted: "Eliminato",
    deleting: "In fase di eliminazione",
    detaching: "In fase di scollegamento",
    disabled: "Disabilitato",
    disabling: "In fase di disabilitazione",
    down: "Inattivo",
    downForMaintenance: "Inattivo per manutenzione",
    enabled: "Abilitato",
    enabling: "In fase di abilitazione",
    error: "Errore",
    expired: "Scaduto",
    exporting: "In fase di esportazione",
    failed: "Non riuscito",
    failedApply: "Applicazione non riuscita",
    failedRollback: "Rollback non riuscito",
    faulty: "Con errori",
    importing: "In fase di importazione",
    inactive: "Inattivo",
    initiated: "Avviato",
    inProgress: "In corso",
    internalError: "Errore interno",
    loading: "In fase di caricamento",
    maintenanceInProgress: "Manutenzione in corso",
    missing: "Mancante",
    packaged: "Nel package",
    pendingCustomer: "Cliente in sospeso",
    pendingProvider: "Provider in sospeso",
    pendingProvisioning: "Provisioning in sospeso",
    preparing: "In fase di preparazione",
    processed: "Elaborato",
    processing: "In fase di elaborazione",
    provisioned: "Provisioning eseguito",
    provisioning: "In fase di provisioning",
    queued: "In coda",
    ready: "Pronto",
    received: "Ricevuto",
    related: "Correlato",
    requestReceived: "Richiesta ricevuta",
    restoreFailed: "Ripristino non riuscito",
    restoreInProgress: "Ripristino in corso",
    restoring: "In fase di ripristino",
    returned: "Restituito",
    rollingBack: "In fase di rollback",
    running: "In fase di esecuzione",
    scaleInProgress: "Scale in in corso",
    shipping: "In fase di spedizione",
    starting: "In fase di avvio",
    stopped: "Interrotto",
    stopping: "In fase di interruzione",
    succeeded: "Riuscito",
    terminated: "Arrestato",
    terminating: "In fase di arresto",
    up: "Attivo",
    unassigned: "Non assegnato",
    unassigning: "In fase di rimozione assegnazione",
    unavailable: "Non disponibile",
    updated: "Aggiornato",
    updating: "In fase di aggiornamento",
    upgrading: "Upgrade in corso",
    waiting: "In attesa",
    warning: "Avvertenza",
  },
  tags: {
    less: "Meno",
    more: "Altro/i ({count})",
  },
  validation: {
    addressInvalid:
      "Indirizzo non valido: 'formato indirizzo IP o blocco CIDR non corretto: {address}'.",
    addressOverlap:
      "Indirizzo non valido: '{address1} si sovrappone a {address2}'.",
    addressReserved: "Indirizzo non valido: '{address} è riservato'.",
    addressReservedRange:
      "Indirizzo non valido: '{address} rientra nell'intervallo riservato {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Indirizzo non valido: '{cidr}' contiene l'indirizzo riservato {address}'.",
    cidrHostBitsSet:
      "Indirizzo non valido: 'CIDR non può avere i bit host impostati'.",
    cidrInvalid: "Indirizzo non valido: 'Blocco CIDR non corretto: {cidr}'.",
    cidrPrefixInvalid:
      "Indirizzo non valido: 'il prefisso CIDR deve essere compreso tra {min} e {max} incluso'.",
    inputRange:
      "Il valore deve avere una lunghezza compresa tra {minValue} e {maxValue} caratteri.",
    integerMax: "Il valore deve essere al massimo {maxValue}.",
    integerMin: "Il valore deve essere almeno {minValue}.",
    integerRange: "Il valore deve essere compreso tra {minValue} e {maxValue}.",
    integerValid: "Il valore deve essere un numero intero valido.",
    ipAddressInvalid:
      "Indirizzo non valido: 'formato indirizzo IP non corretto: {address}'",
    maxRows: "Devono esserci al massimo {maxRows} voci.",
    minRows: "Devono esserci almeno {minRows} voci.",
    requiredValidation: "Il valore non deve essere vuoto.",
    radioValidation: "Il valore fornito non è un'opzione valida",
    timePickerValidation: "Formato dell'ora non valido.",
    validationFailed:
      "Convalida non riuscita. Le informazioni richieste sono mancanti o non valide.",
    validationFailedWithTabHeader:
      "Convalida non riuscita per la scheda seguente: {tabHeader}. Le informazioni richieste sono mancanti o non valide.",
    validationFailedWithTabHeaders:
      "Convalida non riuscita per le schede seguenti: {tabHeaders}. Le informazioni richieste sono mancanti o non valide.",
    validationInProgress: "Convalida in corso",
    validationMaxDate:
      "Il valore deve essere {maxDate}, {maxTime} o precedente.",
    validationMinDate:
      "Il valore deve essere {minDate}, {minTime} o successivo.",
    validationMinMaxDateRange:
      "Il valore deve essere compreso tra {minDate}, {minTime} e {maxDate}, {maxTime}.",
    validationPassed: "Convalida superata.",
    validationReservedWord:
      "Il valore non può contenere la parola riservata '{reserved}'.",
    validationReservedWords:
      "Il valore non può contenere nessuna delle seguenti parole riservate: {reserved}.",
    valueMaxLen: "Il valore deve contenere al massimo {maxLength} caratteri.",
    valueMinLen:
      "Il valore deve essere composto da almeno {minLength} caratteri di lunghezza.",
  },
};
bundled["ja"] = {
  actions: {
    add: "追加",
    addFilter: "フィルタの追加",
    addTags: "ここをクリックしてタグを追加します",
    apply: "適用",
    clearFilters: "フィルタのクリア",
    copiedToClipboard: "コピー済",
    copyToClipboard: "クリップボードにコピー",
    create: "作成",
    delete: "削除",
    edit: "編集",
    editTag: "タグの編集",
    next: "次",
    previous: "前",
    reviewCreate: "確認および作成",
    selectAll: "すべて選択",
    selectFile: "ファイルの選択",
    view: "表示",
  },
  ariaLabel: {
    actionMenu: "クリックしてコンテキスト・メニューを開く",
    actionMenuHeader: "コンテキスト・メニュー列",
    close: "閉じる",
    collapse: "縮小",
    datePicker: "日付を選択",
    delete: "削除",
    dismissNotification: "通知を閉じる",
    expand: "展開",
    fileUpload: "ファイルのアップロード",
    groupBy: "グループ化基準",
    info: "情報",
    more: "詳細",
    nextMonth: "次の月",
    page: "ページ",
    previousMonth: "前の月",
    required: "必須",
    resourceList: "リソース・リスト",
    selectItem: "項目の選択",
    sliderInput: "スライダ入力",
    switchView: "データのリスト・ビューとサマリー・ビューを切替え",
    timePicker: "時間の選択",
  },
  common: {
    addressesCount: "({count}アドレス)",
    all: "すべて",
    cancel: "取消",
    confirm: "確認",
    contains: "含む",
    discard: "破棄",
    equals: "次と等しい",
    error: "エラー",
    errorLoadingData: "データのロード中にエラーが発生しました",
    filter: "フィルタ",
    lastViewed: "最終表示",
    less: "減らす",
    loading: "ロード中...",
    more: "増やす({count})",
    name: "名前",
    no: "いいえ",
    ok: "OK",
    operator: "演算子",
    required: "必須",
    success: "成功",
    tag: "タグ",
    tags: "タグ",
    type: "タイプ",
    value: "値",
    yes: "はい",
  },
  duration: {
    daysLong: "日",
    daysShort: "日",
    daysSymbol: "日",
    hoursLong: "時間",
    hoursShort: "時間",
    hoursSymbol: "時間",
    millisecondsLong: "ミリ秒",
    millisecondsShort: "ミリ秒",
    millisecondsSymbol: "ミリ秒",
    minutesLong: "分",
    minutesShort: "分",
    minutesSymbol: "分",
    secondsLong: "秒",
    secondsShort: "秒",
    secondsSymbol: "秒",
  },
  hints: {
    filterAnyField: "任意のフィールドをフィルタします...",
    filterPlaceholder: "フィルタ...",
    noResultsFound: "結果が見つかりませんでした。",
    requiredField: "必須フィールド",
    searchForField: "{fieldName}の検索",
    searchPlaceholder: "検索",
    selectAnOption: "オプションの選択",
    selectExisting: "既存の選択...",
    showSelection: "{count}個選択されました",
  },
  labels: {
    addresses: "アドレス",
    essentials: "基本情報",
    tags: "タグ",
  },
  listTemplate: {
    learnMore: "さらに学ぶ",
    none: "なし",
    showing: "{total}のうち{start}から{end}のレコードを表示しています。",
  },
  notifications: {
    apiErrorMsg:
      "基盤となるAPIの追加の詳細が役に立つ可能性があります: {errMsg}",
    pollingErrorMessage: "操作のステータスを確認できませんでした。",
    pollingErrorTitle: "確認できませんでした",
  },
  pagination: {
    next: "次",
    of: "/{totalNumberOfPages}",
    page: "ページ",
    previous: "前",
  },
  status: {
    accepted: "受入れ済",
    actionRequired: "必要なアクション",
    active: "アクティブ",
    applied: "適用済",
    applying: "適用中",
    assigned: "割当て済",
    assigning: "割当て中",
    attached: "アタッチ済",
    attaching: "アタッチ中",
    available: "使用可能",
    availableNeedsAttention: "使用可能だが注意が必要",
    backupInProgress: "バックアップ進行中",
    blocked: "ブロック済",
    canceled: "取消済",
    canceling: "取消中",
    cancelled: "取消済",
    cancelledReturned: "返却済を取消済",
    checking: "チェック中",
    closed: "クローズ済",
    complete: "完了",
    completed: "完了済",
    converting: "変換中",
    created: "作成済",
    creating: "作成中",
    creatingImage: "イメージの作成中",
    deleted: "削除済",
    deleting: "削除中",
    detaching: "デタッチ中",
    disabled: "無効",
    disabling: "無効化中",
    down: "停止",
    downForMaintenance: "メンテナンスのための停止",
    enabled: "有効",
    enabling: "有効化中",
    error: "エラー",
    expired: "失効",
    exporting: "エクスポート中",
    failed: "失敗",
    failedApply: "適用の失敗",
    failedRollback: "ロールバックの失敗",
    faulty: "障害",
    importing: "インポート中",
    inactive: "非アクティブ",
    initiated: "開始済",
    inProgress: "進行中",
    internalError: "内部エラー",
    loading: "ロード中",
    maintenanceInProgress: "メンテナンス進行中",
    missing: "なし",
    packaged: "パッケージ済",
    pendingCustomer: "顧客保留中",
    pendingProvider: "プロバイダ保留中",
    pendingProvisioning: "プロビジョニング保留中",
    preparing: "準備中",
    processed: "処理済",
    processing: "処理中",
    provisioned: "プロビジョニング済",
    provisioning: "プロビジョニング中",
    queued: "キュー済",
    ready: "準備完了",
    received: "受信済",
    related: "関連",
    requestReceived: "リクエスト受信済",
    restoreFailed: "リストアに失敗しました",
    restoreInProgress: "リストア進行中",
    restoring: "リストア中",
    returned: "返却済",
    rollingBack: "ロール・バック中",
    running: "実行中",
    scaleInProgress: "スケーリング進行中",
    shipping: "出荷中",
    starting: "起動中",
    stopped: "停止済",
    stopping: "停止中",
    succeeded: "成功",
    terminated: "終了済",
    terminating: "終了中",
    up: "稼働中",
    unassigned: "割当て解除済",
    unassigning: "割当て解除中",
    unavailable: "使用不可",
    updated: "更新済",
    updating: "更新中",
    upgrading: "アップグレード中",
    waiting: "待機中",
    warning: "警告",
  },
  tags: {
    less: "減らす",
    more: "増やす({count})",
  },
  validation: {
    addressInvalid:
      "無効なアドレス: '不正なIPアドレスまたはCIDRブロック: {address}'。",
    addressOverlap:
      "無効なアドレス: '{address1}は{address2}と重複しています'。",
    addressReserved: "無効なアドレス: '{address}は予約されています'。",
    addressReservedRange:
      "無効なアドレス: '{address}は予約された範囲{cidr} ({startingIp} - {endingIp})に含まれています'。",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "無効なアドレス: '{cidr}'には予約されたアドレス{address}が含まれています'。",
    cidrHostBitsSet:
      "無効なアドレス: 'CIDRにはホスト・ビットは設定できません'。",
    cidrInvalid: "無効なアドレス: '不正なCIDRブロック: {cidr}'。",
    cidrPrefixInvalid:
      "無効なアドレス: 'CIDR接頭辞は{min}から{max} (両方の値を含む)の範囲である必要があります'。",
    inputRange: "値は{minValue}から{maxValue}の間の文字数にしてください",
    integerMax: "値は{maxValue}以下にする必要があります。",
    integerMin: "値は{minValue}以上にする必要があります。",
    integerRange: "値は{minValue}から{maxValue}である必要があります。",
    integerValid: "値は有効な整数である必要があります。",
    ipAddressInvalid: "無効なアドレス: '不正なIPアドレス: {address}'",
    maxRows: "最大{maxRows}個のエントリが必要です。",
    minRows: "少なくとも{minRows}個のエントリが必要です。",
    requiredValidation: "値は空にできません。",
    radioValidation: "指定された値は有効なオプションではありません",
    timePickerValidation: "時間書式が無効です。",
    validationFailed:
      "検証に失敗しました。必要な情報がないか、有効ではありません。",
    validationFailedWithTabHeader:
      "次のタブの検証に失敗しました: {tabHeader}。必要な情報がないか、有効ではありません。",
    validationFailedWithTabHeaders:
      "次のタブの検証に失敗しました: {tabHeaders}。必要な情報がないか、有効ではありません。",
    validationInProgress: "検証が進行中です",
    validationMaxDate: "値は{maxDate}、{maxTime}以前である必要があります。",
    validationMinDate: "値は{minDate}、{minTime}以降である必要があります。",
    validationMinMaxDateRange:
      "値は{minDate}、{minTime}から{maxDate}、{maxTime}である必要があります。",
    validationPassed: "検証に成功しました。",
    validationReservedWord: "値に予約語'{reserved}'を含めることはできません。",
    validationReservedWords:
      "値に次の予約語のいずれも含めることはできません: {reserved}。",
    valueMaxLen: "値の長さは最大{maxLength}文字である必要があります。",
    valueMinLen: "値は{minLength}文字以上の長さにする必要があります。",
  },
};
bundled["ko"] = {
  actions: {
    add: "추가",
    addFilter: "필터 추가",
    addTags: "여기를 눌러서 태그 추가",
    apply: "적용",
    clearFilters: "필터 지우기",
    copiedToClipboard: "복사됨",
    copyToClipboard: "클립보드에 복사",
    create: "생성",
    delete: "삭제",
    edit: "편집",
    editTag: "태그 편집",
    next: "다음",
    previous: "이전",
    reviewCreate: "검토 및 생성",
    selectAll: "모두 선택",
    selectFile: "파일 선택",
    view: "보기",
  },
  ariaLabel: {
    actionMenu: "눌러서 컨텍스트 메뉴 열기",
    actionMenuHeader: "컨텍스트 메뉴 열",
    close: "닫기",
    collapse: "축소",
    datePicker: "날짜 선택",
    delete: "삭제",
    dismissNotification: "통지 해제",
    expand: "확장",
    fileUpload: "파일 업로드",
    groupBy: "그룹화 기준",
    info: "정보",
    more: "자세히",
    nextMonth: "다음 달",
    page: "페이지",
    previousMonth: "이전 달",
    required: "필수",
    resourceList: "리소스 목록",
    selectItem: "항목 선택",
    sliderInput: "슬라이더 입력",
    switchView: "데이터의 목록 보기 또는 요약 보기 간 전환",
    timePicker: "시간 선택",
  },
  common: {
    addressesCount: "({count}개 주소)",
    all: "모두",
    cancel: "취소",
    confirm: "확인",
    contains: "포함",
    discard: "무시",
    equals: "같음",
    error: "오류",
    errorLoadingData: "데이터 로드 중 오류",
    filter: "필터",
    lastViewed: "최종 확인됨",
    less: "간단히",
    loading: "로드 중...",
    more: "자세히({count})",
    name: "이름",
    no: "아니오",
    ok: "확인",
    operator: "연산자",
    required: "필수",
    success: "성공",
    tag: "태그",
    tags: "태그",
    type: "유형",
    value: "값",
    yes: "예",
  },
  duration: {
    daysLong: "일",
    daysShort: "일",
    daysSymbol: "일",
    hoursLong: "시간",
    hoursShort: "시간",
    hoursSymbol: "시간",
    millisecondsLong: "밀리초",
    millisecondsShort: "밀리초",
    millisecondsSymbol: "밀리초",
    minutesLong: "분",
    minutesShort: "분",
    minutesSymbol: "분",
    secondsLong: "초",
    secondsShort: "초",
    secondsSymbol: "초",
  },
  hints: {
    filterAnyField: "모든 필드에 대해 필터링...",
    filterPlaceholder: "필터...",
    noResultsFound: "결과를 찾을 수 없습니다.",
    requiredField: "필수 필드",
    searchForField: "{fieldName} 검색",
    searchPlaceholder: "검색",
    selectAnOption: "옵션 선택",
    selectExisting: "기존 선택...",
    showSelection: "{count}개 선택됨",
  },
  labels: {
    addresses: "주소",
    essentials: "필수",
    tags: "태그",
  },
  listTemplate: {
    learnMore: "자세히 알아보기",
    none: "없음",
    showing: "{total}개의 레코드 중 {start}에서 {end}까지 표시 중입니다.",
  },
  notifications: {
    apiErrorMsg: "도움이 될 수 있는 기본 API의 추가 세부정보: {errMsg}",
    pollingErrorMessage: "작업 상태 확인을 실패했습니다.",
    pollingErrorTitle: "확인 실패",
  },
  pagination: {
    next: "다음",
    of: "/{totalNumberOfPages}",
    page: "페이지",
    previous: "이전",
  },
  status: {
    accepted: "수락됨",
    actionRequired: "작업 필요",
    active: "활성",
    applied: "적용됨",
    applying: "적용 중",
    assigned: "지정됨",
    assigning: "지정 중",
    attached: "연결됨",
    attaching: "연결 중",
    available: "사용 가능",
    availableNeedsAttention: "사용 가능(주의 필요)",
    backupInProgress: "백업 진행 중",
    blocked: "차단됨",
    canceled: "취소됨",
    canceling: "취소 중",
    cancelled: "취소됨",
    cancelledReturned: "취소 후 되돌려짐",
    checking: "확인 중",
    closed: "종결됨",
    complete: "완료",
    completed: "완료됨",
    converting: "변환 중",
    created: "생성됨",
    creating: "생성 중",
    creatingImage: "이미지 생성 중",
    deleted: "삭제됨",
    deleting: "삭제 중",
    detaching: "분리 중",
    disabled: "사용 안함",
    disabling: "사용 안함으로 설정 중",
    down: "아래로",
    downForMaintenance: "유지보수를 위한 작동 중지",
    enabled: "사용",
    enabling: "사용으로 설정 중",
    error: "오류",
    expired: "만료됨",
    exporting: "익스포트 중",
    failed: "실패",
    failedApply: "적용 실패",
    failedRollback: "롤백 실패",
    faulty: "결함 발생",
    importing: "임포트 중",
    inactive: "비활성",
    initiated: "시작됨",
    inProgress: "진행 중",
    internalError: "내부 오류",
    loading: "로드 중",
    maintenanceInProgress: "유지보수 진행 중",
    missing: "누락",
    packaged: "패키지화됨",
    pendingCustomer: "보류 중인 고객",
    pendingProvider: "보류 중인 제공자",
    pendingProvisioning: "보류 중인 프로비전",
    preparing: "준비 중",
    processed: "처리됨",
    processing: "처리 중",
    provisioned: "프로비전됨",
    provisioning: "프로비전 중",
    queued: "대기열에 있음",
    ready: "준비",
    received: "수신됨",
    related: "관련",
    requestReceived: "요청 접수됨",
    restoreFailed: "복원 실패",
    restoreInProgress: "복원 진행 중",
    restoring: "복원 중",
    returned: "되돌려짐",
    rollingBack: "롤백 중",
    running: "실행 중",
    scaleInProgress: "스케일링 진행 중",
    shipping: "배송 중",
    starting: "시작 중",
    stopped: "정지됨",
    stopping: "정지 중",
    succeeded: "성공",
    terminated: "종료됨",
    terminating: "종료 중",
    up: "위로",
    unassigned: "지정 해제됨",
    unassigning: "지정 해제 중",
    unavailable: "사용할 수 없음",
    updated: "업데이트됨",
    updating: "업데이트 중",
    upgrading: "업그레이드 중",
    waiting: "대기 중",
    warning: "경고",
  },
  tags: {
    less: "간단히",
    more: "자세히({count})",
  },
  validation: {
    addressInvalid:
      "부적합한 주소:'형식이 잘못된 IP 주소 또는 CIDR 블록: {address}'.",
    addressOverlap:
      "부적합한 주소: '{address1}이(가) {address2}과(와) 겹칩니다.'",
    addressReserved: "부적합한 주소: '{address}은(는) 예약되어 있습니다.'",
    addressReservedRange:
      "부적합한 주소: '{address}은(는) 예약된 범위 {cidr} ({startingIp} - {endingIp}) 내에 있습니다.'",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "부적합한 주소: '{cidr}'은(는) 예약된 주소 {address}을(를) 포함합니다.'",
    cidrHostBitsSet:
      "부적합한 주소: 'CIDR은 호스트 비트를 설정할 수 없습니다.'",
    cidrInvalid: "부적합한 주소: '형식이 잘못된 CIDR 블록: {cidr}'.",
    cidrPrefixInvalid:
      "부적합한 주소: 'CIDR 접두어는 {min}에서 {max}(포함) 사이여야 합니다.'",
    inputRange: "값은 {minValue}자에서 {maxValue}자 사이여야 합니다.",
    integerMax: "값은 {maxValue} 이하여야 합니다.",
    integerMin: "값은 {minValue} 이상이어야 합니다.",
    integerRange: "값은 {minValue}과(와) {maxValue} 사이여야 합니다.",
    integerValid: "값은 적합한 정수여야 합니다.",
    ipAddressInvalid: "부적합한 주소: '형식이 잘못된 IP 주소: {address}'",
    maxRows: "항목은 {maxRows}개 이하여야 합니다.",
    minRows: "항목은 {minRows}개 이상이어야 합니다.",
    requiredValidation: "값은 비어 있지 않아야 합니다.",
    radioValidation: "제공된 값은 적합한 옵션이 아닙니다.",
    timePickerValidation: "시간 형식이 부적합합니다.",
    validationFailed:
      "검증을 실패했습니다. 필수 정보가 누락되었거나 적합하지 않습니다.",
    validationFailedWithTabHeader:
      "{tabHeader} 탭에 대한 검증을 실패했습니다. 필수 정보가 누락되었거나 부적합합니다.",
    validationFailedWithTabHeaders:
      "{tabHeaders} 탭에 대한 검증을 실패했습니다. 필수 정보가 누락되었거나 부적합합니다.",
    validationInProgress: "검증 진행 중",
    validationMaxDate: "값은 {maxDate}, {maxTime} 또는 그 이전이어야 합니다.",
    validationMinDate: "값은 {minDate}, {minTime} 또는 그 이후여야 합니다.",
    validationMinMaxDateRange:
      "값은 {minDate}, {minTime}과(와) {maxDate}, {maxTime} 사이여야 합니다.",
    validationPassed: "검증을 성공했습니다.",
    validationReservedWord:
      "값에 예약어 '{reserved}'을(를) 포함할 수 없습니다.",
    validationReservedWords: "값에 다음 예약어를 포함할 수 없음:{reserved}.",
    valueMaxLen: "값은 {maxLength}자 이하여야 합니다.",
    valueMinLen: "값은 {minLength}자 이상이어야 합니다.",
  },
};
bundled["nl"] = {
  actions: {
    add: "Toevoegen",
    addFilter: "Filter toevoegen",
    addTags: "Klik hier om tags toe te voegen.",
    apply: "Toepassen",
    clearFilters: "Filters wissen",
    copiedToClipboard: "Gekopieerd",
    copyToClipboard: "Naar klembord kopiëren",
    create: "Maken",
    delete: "Verwijderen",
    edit: "Bewerken",
    editTag: "Tag bewerken",
    next: "Volgende",
    previous: "Vorige",
    reviewCreate: "Bekijken en maken",
    selectAll: "Alles selecteren",
    selectFile: "Bestand selecteren",
    view: "Bekijken",
  },
  ariaLabel: {
    actionMenu: "Klikken om contextmenu te openen",
    actionMenuHeader: "Kolom contextmenu",
    close: "sluiten",
    collapse: "Samenvouwen",
    datePicker: "Selecteer een datum",
    delete: "Verwijderen",
    dismissNotification: "Melding negeren",
    expand: "Uitvouwen",
    fileUpload: "Een bestand uploaden",
    groupBy: "Groeperen op",
    info: "Info",
    more: "Meer",
    nextMonth: "Volgende maand",
    page: "Pagina",
    previousMonth: "Vorige maand",
    required: "Vereist",
    resourceList: "Lijst met resources",
    selectItem: "Item selecteren",
    sliderInput: "Invoer voor schuifregelaar",
    switchView: "Schakelen tussen lijst- en overzichtsweergave van gegevens",
    timePicker: "Selecteer een tijd.",
  },
  common: {
    addressesCount: "({count} adressen)",
    all: "Alles",
    cancel: "Annuleren",
    confirm: "Bevestigen",
    contains: "Bevat",
    discard: "Negeren",
    equals: "Is gelijk aan",
    error: "Fout",
    errorLoadingData: "Fout bij laden van gegevens",
    filter: "Filteren",
    lastViewed: "Laatst bekeken",
    less: "Minder",
    loading: "Wordt geladen...",
    more: "Meer ({count})",
    name: "Naam",
    no: "Nee",
    ok: "OK",
    operator: "Operator",
    required: "Vereist",
    success: "Geslaagd",
    tag: "Tag",
    tags: "Tags",
    type: "Type",
    value: "Waarde",
    yes: "Ja",
  },
  duration: {
    daysLong: "dagen",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "uren",
    hoursShort: "uur",
    hoursSymbol: "u",
    millisecondsLong: "milliseconden",
    millisecondsShort: "ms",
    millisecondsSymbol: "ms",
    minutesLong: "minuten",
    minutesShort: "min.",
    minutesSymbol: "m",
    secondsLong: "seconden",
    secondsShort: "sec.",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filter voor elk veld",
    filterPlaceholder: "Filter...",
    noResultsFound: "Geen resultaten gevonden",
    requiredField: "Verplicht veld",
    searchForField: "Zoeken naar {fieldName}",
    searchPlaceholder: "Zoeken",
    selectAnOption: "Selecteer een optie.",
    selectExisting: "Bestaande selecteren...",
    showSelection: "{count} geselecteerd",
  },
  labels: {
    addresses: "Adressen",
    essentials: "Essentiële functies",
    tags: "Tags",
  },
  listTemplate: {
    learnMore: "Meer info",
    none: "Geen",
    showing: "{start} t/m {end} van de {total} records worden getoond.",
  },
  notifications: {
    apiErrorMsg:
      "Aanvullende gegevens van de onderliggende API die nuttig kunnen zijn: {errMsg}",
    pollingErrorMessage: "Bewerkingsstatus is niet gecontroleerd.",
    pollingErrorTitle: "Niet gecontroleerd",
  },
  pagination: {
    next: "Volgende",
    of: "van {totalNumberOfPages}",
    page: "Pagina",
    previous: "Vorige",
  },
  status: {
    accepted: "Geaccepteerd",
    actionRequired: "Actie vereist",
    active: "Actief",
    applied: "Toegepast",
    applying: "Wordt toegepast.",
    assigned: "Toegewezen",
    assigning: "Wordt toegewezen.",
    attached: "Bijgevoegd",
    attaching: "Wordt bijgevoegd.",
    available: "Beschikbaar",
    availableNeedsAttention: "Beschikbaar, aandacht vereist",
    backupInProgress: "Back-up wordt uitgevoerd.",
    blocked: "Geblokkeerd",
    canceled: "Geannuleerd",
    canceling: "Bezig met annuleren",
    cancelled: "Geannuleerd",
    cancelledReturned: "Geannuleerd en geretourneerd",
    checking: "Wordt gecontroleerd.",
    closed: "Gesloten",
    complete: "Voltooien",
    completed: "Voltooid",
    converting: "Bezig met converteren",
    created: "Gemaakt",
    creating: "Wordt gemaakt.",
    creatingImage: "Image wordt gemaakt.",
    deleted: "Verwijderd",
    deleting: "Wordt verwijderd.",
    detaching: "Wordt ontkoppeld.",
    disabled: "Gedeactiveerd",
    disabling: "Wordt gedeactiveerd.",
    down: "Niet beschikbaar",
    downForMaintenance: "Niet beschikbaar wegens onderhoud",
    enabled: "Actief",
    enabling: "Wordt geactiveerd.",
    error: "Fout",
    expired: "Vervallen",
    exporting: "Wordt geëxporteerd.",
    failed: "Mislukt",
    failedApply: "Toepassen mislukt",
    failedRollback: "Terugdraaien mislukt",
    faulty: "Foutief",
    importing: "Wordt geïmporteerd.",
    inactive: "Inactief",
    initiated: "Geïnitieerd",
    inProgress: "Wordt uitgevoerd",
    internalError: "Interne fout",
    loading: "Wordt geladen.",
    maintenanceInProgress: "Er wordt momenteel onderhoud uitgevoerd.",
    missing: "Ontbreekt",
    packaged: "Verpakt",
    pendingCustomer: "In behandeling door klant",
    pendingProvider: "In behandeling door provider",
    pendingProvisioning: "Wachtend op initialisatie",
    preparing: "Wordt voorbereid.",
    processed: "Verwerkt",
    processing: "Wordt verwerkt.",
    provisioned: "Geïnitialiseerd",
    provisioning: "Wordt geïnitialiseerd.",
    queued: "In wachtrij",
    ready: "Gereed",
    received: "Ontvangen",
    related: "Gerelateerd",
    requestReceived: "Aanvraag ontvangen",
    restoreFailed: "Herstellen mislukt",
    restoreInProgress: "Wordt hersteld.",
    restoring: "Wordt hersteld.",
    returned: "Geretourneerd",
    rollingBack: "Bezig met terugdraaien",
    running: "Wordt uitgevoerd",
    scaleInProgress: "Bezig met schalen",
    shipping: "Wordt verzonden.",
    starting: "Wordt gestart.",
    stopped: "Gestopt",
    stopping: "Wordt gestopt.",
    succeeded: "Geslaagd",
    terminated: "Beëindigd",
    terminating: "Wordt beëindigd.",
    up: "Beschikbaar",
    unassigned: "Niet toegewezen",
    unassigning: "Toewijzing wordt ongedaan gemaakt.",
    unavailable: "Niet beschikbaar",
    updated: "Bijgewerkt",
    updating: "Wordt bijgewerkt.",
    upgrading: "Bezig met upgraden",
    waiting: "Wachten",
    warning: "Waarschuwing",
  },
  tags: {
    less: "Minder",
    more: "Meer ({count})",
  },
  validation: {
    addressInvalid:
      "Ongeldig adres: 'Onjuist IP-adres of CIDR-blok: {address}'.",
    addressOverlap: "Ongeldig adres: '{address1} overlapt met {address2}'.",
    addressReserved: "Ongeldig adres: '{address} is gereserveerd'.",
    addressReservedRange:
      "Ongeldig adres: '{address} valt binnen gereserveerd bereik {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Ongeldig adres: '{cidr}' bevat gereserveerd adres {address}'.",
    cidrHostBitsSet:
      "Ongeldig adres: 'voor CIDR kunnen de hostbits niet worden ingesteld'.",
    cidrInvalid: "Ongeldig adres: 'Onjuist CIDR-blok: {cidr}'.",
    cidrPrefixInvalid:
      "Ongeldig adres: 'CIDR-prefix moet vallen in het bereik van {min} tot en met {max}'.",
    inputRange:
      "De waarde moet tussen {minValue} en {maxValue} tekens lang zijn.",
    integerMax: "De waarde mag niet groter zijn dan {maxValue}.",
    integerMin: "De waarde mag niet kleiner zijn dan {minValue}.",
    integerRange: "De waarde moet tussen {minValue} en {maxValue} liggen.",
    integerValid: "De waarde moet een geldig geheel getal zijn.",
    ipAddressInvalid: "Ongeldig adres: 'Onjuist IP-adres: {address}'",
    maxRows: "Er mogen maximaal {maxRows} invoerwaarden zijn.",
    minRows: "Er moeten ten minste {minRows} invoerwaarden zijn.",
    requiredValidation: "De waarde mag niet leeg zijn.",
    radioValidation: "De opgegeven waarde is geen geldige optie.",
    timePickerValidation: "Ongeldige tijdnotatie",
    validationFailed:
      "Validatie is mislukt. Vereiste gegevens ontbreken of zijn niet geldig.",
    validationFailedWithTabHeader:
      "Validatie is mislukt voor het volgende tabblad: {tabHeader}. Vereiste gegevens ontbreken of zijn niet geldig.",
    validationFailedWithTabHeaders:
      "Validatie is mislukt voor de volgende tabbladen: {tabHeaders}. Vereiste gegevens ontbreken of zijn niet geldig.",
    validationInProgress: "Bezig met valideren",
    validationMaxDate: "De waarde moet {maxDate}, {maxTime} of eerder zijn.",
    validationMinDate: "De waarde moet {minDate}, {minTime} of later zijn.",
    validationMinMaxDateRange:
      "De waarde moet tussen {minDate}, {minTime} en {maxDate}, {maxTime} vallen.",
    validationPassed: "Validatie geslaagd",
    validationReservedWord:
      "De waarde mag niet het gereserveerde woord '{reserved}' bevatten.",
    validationReservedWords:
      "De waarde mag geen van de volgende gereserveerde woorden bevatten: {reserved}.",
    valueMaxLen: "De waarde kan maximaal {maxLength} tekens lang zijn.",
    valueMinLen: "De waarde moet minstens {minLength} tekens lang zijn.",
  },
};
bundled["no"] = {
  actions: {
    add: "Legg til",
    addFilter: "Legg til filter",
    addTags: "Klikk her for å legge til koder",
    apply: "Bruk",
    clearFilters: "Nullstill filtre",
    copiedToClipboard: "Kopiert",
    copyToClipboard: "Kopier til utklippstavle",
    create: "Opprett",
    delete: "Slett",
    edit: "Rediger",
    editTag: "Rediger kode",
    next: "Neste",
    previous: "Forrige",
    reviewCreate: "Vurder og opprett",
    selectAll: "Velg alle",
    selectFile: "Velg en fil",
    view: "Vis",
  },
  ariaLabel: {
    actionMenu: "Klikk for å åpne kontekstmenyen",
    actionMenuHeader: "Kolonne for kontekstmeny",
    close: "lukk",
    collapse: "Trekk sammen",
    datePicker: "Velg en dato",
    delete: "Slett",
    dismissNotification: "Lukk varsling",
    expand: "Utvid",
    fileUpload: "Last opp en fil",
    groupBy: "Grupper etter",
    info: "Opplysninger",
    more: "Mer",
    nextMonth: "Neste måned",
    page: "Side",
    previousMonth: "Forrige måned",
    required: "Obligatorisk",
    resourceList: "Ressursliste",
    selectItem: "Velg element",
    sliderInput: "Inndata for glidebryter",
    switchView: "Bytt mellom en listevisning og en sammendragsvisning av data",
    timePicker: "Velg et tidspunkt",
  },
  common: {
    addressesCount: "({count} adresser)",
    all: "Alle",
    cancel: "Avbryt",
    confirm: "Bekreft",
    contains: "Inneholder",
    discard: "Forkast",
    equals: "Er lik",
    error: "Feil",
    errorLoadingData: "Feil ved lasting av data",
    filter: "Filter",
    lastViewed: "Sist vist",
    less: "Mindre",
    loading: "Laster ...",
    more: "Mer ({count})",
    name: "Navn",
    no: "Nei",
    ok: "OK",
    operator: "Operator",
    required: "Obligatorisk",
    success: "Vellykket",
    tag: "Kode",
    tags: "Koder",
    type: "Type",
    value: "Verdi",
    yes: "Ja",
  },
  duration: {
    daysLong: "dager",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "timer",
    hoursShort: "tm",
    hoursSymbol: "t",
    millisecondsLong: "millisekunder",
    millisecondsShort: "msek",
    millisecondsSymbol: "ms",
    minutesLong: "minutter",
    minutesShort: "min",
    minutesSymbol: "m",
    secondsLong: "sekunder",
    secondsShort: "sek",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtrer for hvilket som helst felt ...",
    filterPlaceholder: "Filtrer ...",
    noResultsFound: "Fant ingen resultater.",
    requiredField: "Nødvendig felt",
    searchForField: "Søk etter {fieldName}",
    searchPlaceholder: "Søk",
    selectAnOption: "Angi et valg",
    selectExisting: "Velg eksisterende ...",
    showSelection: "{count} valgt",
  },
  labels: {
    addresses: "Adresser",
    essentials: "Grunnleggende",
    tags: "Koder",
  },
  listTemplate: {
    learnMore: "Finn ut mer",
    none: "Ingen",
    showing: "Viser fra {start} til {end} av {total} poster.",
  },
  notifications: {
    apiErrorMsg:
      "Flere opplysninger fra det underliggende API-et som kan være nyttige: {errMsg}",
    pollingErrorMessage: "Kan ikke kontrollere operasjonsstatusen.",
    pollingErrorTitle: "Kan ikke kontrollere",
  },
  pagination: {
    next: "Neste",
    of: "av {totalNumberOfPages}",
    page: "Side",
    previous: "Forrige",
  },
  status: {
    accepted: "Godtatt",
    actionRequired: "Handling kreves",
    active: "Aktiv",
    applied: "Tatt i bruk",
    applying: "Tar i bruk",
    assigned: "Tilordnet",
    assigning: "Tilordner",
    attached: "Tilknyttet",
    attaching: "Tilknytter",
    available: "Tilgjengelig",
    availableNeedsAttention: "Tilgjengelig, trenger tilsyn",
    backupInProgress: "Sikkerhetskopiering pågår",
    blocked: "Blokkert",
    canceled: "Avbrutt",
    canceling: "Avbryter",
    cancelled: "Annullert",
    cancelledReturned: "Annullert returnert",
    checking: "Kontrollerer",
    closed: "Lukket",
    complete: "Fullfør",
    completed: "Fullført",
    converting: "Konverterer",
    created: "Opprettet",
    creating: "Oppretter",
    creatingImage: "Oppretter avbildning",
    deleted: "Slettet",
    deleting: "Sletter",
    detaching: "Kobler fra",
    disabled: "Deaktivert",
    disabling: "Deaktiverer",
    down: "Nede",
    downForMaintenance: "Nede for vedlikehold",
    enabled: "Aktivert",
    enabling: "Aktiverer",
    error: "Feil",
    expired: "Utløpt",
    exporting: "Eksporterer",
    failed: "Mislykket",
    failedApply: "Kan ikke brukes",
    failedRollback: "Kan ikke tilbakestilles",
    faulty: "Defekt",
    importing: "Importerer",
    inactive: "Inaktiv",
    initiated: "Startet",
    inProgress: "Pågår",
    internalError: "Intern feil",
    loading: "Laster",
    maintenanceInProgress: "Vedlikehold pågår",
    missing: "Mangler",
    packaged: "Pakket",
    pendingCustomer: "Venter på kunde",
    pendingProvider: "Venter på leverandør",
    pendingProvisioning: "Venter på klargjøring",
    preparing: "Klargjør",
    processed: "Behandlet",
    processing: "Behandler",
    provisioned: "Klargjort",
    provisioning: "Klargjør",
    queued: "I kø",
    ready: "Klar",
    received: "Mottatt",
    related: "Relatert",
    requestReceived: "Forespørsel mottatt",
    restoreFailed: "Gjenopprettingen mislyktes",
    restoreInProgress: "Gjenoppretting pågår",
    restoring: "Gjenoppretter",
    returned: "Returnert",
    rollingBack: "Tilbakestiller",
    running: "Kjører",
    scaleInProgress: "Skalering pågår ",
    shipping: "Sendes",
    starting: "Starter",
    stopped: "Stoppet",
    stopping: "Stopper",
    succeeded: "Vellykket",
    terminated: "Avsluttet",
    terminating: "Avslutter",
    up: "Oppe",
    unassigned: "Ikke tilordnet",
    unassigning: "Opphever tilordning",
    unavailable: "Utilgjengelig",
    updated: "Oppdatert",
    updating: "Oppdaterer",
    upgrading: "Oppgraderer",
    waiting: "Venter",
    warning: "Advarsel",
  },
  tags: {
    less: "Mindre",
    more: "Mer ({count})",
  },
  validation: {
    addressInvalid:
      "Ugyldig adresse: 'Misformet IP-adresse eller CIDR-blokk: {address}'.",
    addressOverlap: "Ugyldig adresse: '{address1} overlapper med {address2}'.",
    addressReserved: "Ugyldig adresse: '{address} er reservert'.",
    addressReservedRange:
      "Ugyldig adresse: '{address} er innenfor det reserverte området {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Ugyldig adresse: '{cidr} inneholder den reserverte adressen {address}'.",
    cidrHostBitsSet:
      "Ugyldig adresse: 'Vertsbitene kan ikke være angitt for CIDR'.",
    cidrInvalid: "Ugyldig adresse: 'Misformet CIDR-blokk: {cidr}'.",
    cidrPrefixInvalid:
      "Ugyldig adresse: 'CIDR-prefikset må være mellom {min} og {max} (inklusiv)'",
    inputRange: "Verdien må inneholde mellom {minValue} og {maxValue} tegn.",
    integerMax: "Verdien kan være maksimalt {maxValue}.",
    integerMin: "Verdien må være minst {minValue}.",
    integerRange: "Verdien må være mellom {minValue} og {maxValue}.",
    integerValid: "Verdien må være et gyldig heltall.",
    ipAddressInvalid: "Ugyldig adresse: 'Misformet IP-adresse: {address}'",
    maxRows: "Det kan ikke være mer enn {maxRows} oppføringer.",
    minRows: "Det må være minst {minRows} oppføringer.",
    requiredValidation: "Verdien kan ikke være tom.",
    radioValidation: "Den angitte verdien er ikke et gyldig valg",
    timePickerValidation: "Ugyldig klokkeslettformat.",
    validationFailed:
      "Valideringen mislyktes. Nødvendige opplysninger mangler eller er ikke gyldige.",
    validationFailedWithTabHeader:
      "Valideringen mislyktes for følgende fane: {tabHeader}. Nødvendige opplysninger mangler eller er ikke gyldige.",
    validationFailedWithTabHeaders:
      "Valideringen mislyktes for følgende faner: {tabHeaders}. Nødvendige opplysninger mangler eller er ikke gyldige.",
    validationInProgress: "Validering pågår",
    validationMaxDate: "Verdien må være {maxDate}, {maxTime} eller før.",
    validationMinDate: "Verdien må være {minDate}, {minTime} eller etter.",
    validationMinMaxDateRange:
      "Verdien må være mellom {minDate}, {minTime} og {maxDate}, {maxTime}.",
    validationPassed: "Validering bestått.",
    validationReservedWord:
      "Verdien kan ikke inneholde det reserverte ordet {reserved}.",
    validationReservedWords:
      "Verdien kan ikke inneholde noen av følgende reserverte ord: {reserved}.",
    valueMaxLen: "Verdien kan inneholde maksimalt {maxLength} tegn.",
    valueMinLen: "Verdien må bestå av minst {minLength} tegn.",
  },
};
bundled["pl"] = {
  actions: {
    add: "Dodaj",
    addFilter: "Dodaj filtr",
    addTags: "Aby dodać tagi, proszę kliknąć tutaj",
    apply: "Zastosuj",
    clearFilters: "Wyczyść filtry",
    copiedToClipboard: "Skopiowano",
    copyToClipboard: "Kopiuj do Schowka",
    create: "Utwórz",
    delete: "Usuń",
    edit: "Edytuj",
    editTag: "Edytuj tag",
    next: "Dalej",
    previous: "Wstecz",
    reviewCreate: "Sprawdź i utwórz",
    selectAll: "Wybierz wszystkie",
    selectFile: "Proszę wybrać plik",
    view: "Wyświetl",
  },
  ariaLabel: {
    actionMenu: "Proszę kliknąć, aby otworzyć menu kontekstowe",
    actionMenuHeader: "Kolumna menu kontekstowego",
    close: "zamknij",
    collapse: "Zwiń",
    datePicker: "Proszę wybrać datę",
    delete: "Usuń",
    dismissNotification: "Odrzuć powiadomienie",
    expand: "Rozwiń",
    fileUpload: "Wyślij plik",
    groupBy: "Grupuj wg",
    info: "Informacje",
    more: "Więcej",
    nextMonth: "Następny miesiąc",
    page: "Strona",
    previousMonth: "Poprzedni miesiąc",
    required: "Wymagane",
    resourceList: "Lista zasobów",
    selectItem: "Wybór elementu",
    sliderInput: "Wprowadzanie danych za pomocą suwaka",
    switchView:
      "Można się przełączać między widokiem listy a widokiem podsumowania danych",
    timePicker: "Wybór czasu",
  },
  common: {
    addressesCount: "(liczba adresów: {count})",
    all: "Wszystkie",
    cancel: "Anuluj",
    confirm: "Potwierdzenie",
    contains: "Zawiera",
    discard: "Odrzuć",
    equals: "Równa się",
    error: "Błąd",
    errorLoadingData: "Błąd podczas ładowania danych",
    filter: "Filtr",
    lastViewed: "Ostatnio wyświetlane",
    less: "Mniej",
    loading: "Ładowanie...",
    more: "Więcej ({count})",
    name: "Nazwa",
    no: "Nie",
    ok: "OK",
    operator: "Operator",
    required: "Wymagane",
    success: "Powodzenie",
    tag: "Tag",
    tags: "Tagi",
    type: "Typ",
    value: "Wartość",
    yes: "Tak",
  },
  duration: {
    daysLong: "dni",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "godzin(y)",
    hoursShort: "godz.",
    hoursSymbol: "h",
    millisecondsLong: "milisekund(y)",
    millisecondsShort: "ms",
    millisecondsSymbol: "ms",
    minutesLong: "minut(y)",
    minutesShort: "min",
    minutesSymbol: "m",
    secondsLong: "sekund(y)",
    secondsShort: "s",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtruj z użyciem dowolnego pola...",
    filterPlaceholder: "Filtruj...",
    noResultsFound: "Brak wyników.",
    requiredField: "Wymagane pole",
    searchForField: "Szukaj {fieldName}",
    searchPlaceholder: "Szukaj",
    selectAnOption: "Wybór opcji",
    selectExisting: "Wybór istniejącej...",
    showSelection: "Wybrano: {count}",
  },
  labels: {
    addresses: "Adresy",
    essentials: "Istotne",
    tags: "Tagi",
  },
  listTemplate: {
    learnMore: "Więcej informacji",
    none: "Brak",
    showing: "Pokazywanie od {start} do {end} z {total} rekordów.",
  },
  notifications: {
    apiErrorMsg:
      "Dodatkowe szczegóły z użytego API, które mogą być pomocne: {errMsg}",
    pollingErrorMessage: "Nie udało się sprawdzić statusu operacji.",
    pollingErrorTitle: "Nie udało się sprawdzić",
  },
  pagination: {
    next: "Dalej",
    of: "z {totalNumberOfPages}",
    page: "Strona",
    previous: "Wstecz",
  },
  status: {
    accepted: "Zaakceptowane",
    actionRequired: "Wymagana czynność",
    active: "Aktywne",
    applied: "Zastosowane",
    applying: "Stosowanie",
    assigned: "Przypisane",
    assigning: "Przypisywanie",
    attached: "Dołączone",
    attaching: "Dołączanie",
    available: "Dostępne",
    availableNeedsAttention: '"Dostępne" wymaga uwagi',
    backupInProgress: "Trwa tworzenie kopii zapasowej",
    blocked: "Zablokowane",
    canceled: "Anulowane",
    canceling: "Anulowanie",
    cancelled: "Anulowane",
    cancelledReturned: "Zwrócono anulowane",
    checking: "Sprawdzanie",
    closed: "Zamknięte",
    complete: "Ukończone",
    completed: "Ukończono",
    converting: "Konwertowanie",
    created: "Utworzone",
    creating: "Tworzenie",
    creatingImage: "Tworzenie obrazu",
    deleted: "Usunięte",
    deleting: "Usuwanie",
    detaching: "Odłączanie",
    disabled: "Wyłączone",
    disabling: "Wyłączanie",
    down: "Nie działa",
    downForMaintenance: "Wyłączono w celu konserwacji",
    enabled: "Włączone",
    enabling: "Włączanie",
    error: "Błąd",
    expired: "Wygasłe",
    exporting: "Eksportowanie",
    failed: "Niepowodzenie",
    failedApply: "Nie udało się zastosować",
    failedRollback: "Nie udało się wycofać",
    faulty: "Z błędem",
    importing: "Importowanie",
    inactive: "Nieaktywne",
    initiated: "Zainicjowane",
    inProgress: "W toku",
    internalError: "Błąd wewnętrzny",
    loading: "Ładowanie",
    maintenanceInProgress: "Konserwacja w toku",
    missing: "Brak",
    packaged: "Spakowane",
    pendingCustomer: "Oczekiwanie na klienta",
    pendingProvider: "Oczekiwanie na dostawcę",
    pendingProvisioning: "Oczekiwanie na udostępnienie",
    preparing: "Przygotowywanie",
    processed: "Przetworzone",
    processing: "Przetwarzanie",
    provisioned: "Udostępnione",
    provisioning: "Udostępnianie",
    queued: "Zakolejkowano",
    ready: "Gotowe",
    received: "Otrzymane",
    related: "Powiązane",
    requestReceived: "Otrzymano zlecenie",
    restoreFailed: "Nie udało się odtworzyć",
    restoreInProgress: "Odtwarzanie w toku",
    restoring: "Odtwarzanie",
    returned: "Zwrócone",
    rollingBack: "Wycofywanie",
    running: "Działające",
    scaleInProgress: "Skalowanie w toku",
    shipping: "Wysyłanie",
    starting: "Uruchamianie",
    stopped: "Zatrzymane",
    stopping: "Zatrzymywanie",
    succeeded: "Powodzenie",
    terminated: "Zakończone trwale",
    terminating: "Kończenie trwałe",
    up: "Działa",
    unassigned: "Nieprzypisane",
    unassigning: "Cofanie przypisania",
    unavailable: "Niedostępne",
    updated: "Zaktualizowane",
    updating: "Aktualizowanie",
    upgrading: "Uaktualnianie",
    waiting: "Oczekiwanie",
    warning: "Ostrzeżenie",
  },
  tags: {
    less: "Mniej",
    more: "Więcej ({count})",
  },
  validation: {
    addressInvalid:
      'Niepoprawny adres: "Niepoprawna postać adresu IP lub bloku CIDR: {address}".',
    addressOverlap:
      'Niepoprawny adres: "{address1} nakłada się na {address2}".',
    addressReserved: 'Niepoprawny adres: "{address} jest zarezerwowany".',
    addressReservedRange:
      'Niepoprawny adres: "{address} zawiera się w zarezerwowanym przedziale {cidr} ({startingIp} - {endingIp})".',
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Invalid address: '{cidr}' contains reserved address {address}'.",
    cidrHostBitsSet:
      'Niepoprawny adres: "CIDR nie może mieć ustawionych bitów hosta".',
    cidrInvalid: 'Niepoprawny adres: "Niepoprawna postać bloku CIDR: {cidr}".',
    cidrPrefixInvalid:
      'Niepoprawny adres: "Prefiks bloku CIDR musi się zawierać w przedziale od {min} do {max} (włącznie)".',
    inputRange:
      "Wartość musi się składać z od {minValue} do {maxValue} znaków.",
    integerMax: "Wartość musi wynosić co najwyżej {maxValue}.",
    integerMin: "Wartość musi wynosić przynajmniej {minValue}.",
    integerRange:
      "Wartość musi się zawierać w przedziale od {minValue} do {maxValue}.",
    integerValid: "Wartość musi być poprawną liczbą całkowitą.",
    ipAddressInvalid:
      'Niepoprawny adres: "Niepoprawna postać adresu IP: {address}"',
    maxRows: "Liczba wpisów nie może przekraczać {maxRows}.",
    minRows: "Liczba wpisów musi wynosić przynajmniej {minRows}.",
    requiredValidation: "Wartość nie może być pusta",
    radioValidation: "Podana wartość nie jest poprawną opcją",
    timePickerValidation: "Niepoprawny format godziny.",
    validationFailed:
      "Niepowodzenie weryfikacji. Brak wymaganych informacji lub podane informacje są niepoprawne.",
    validationFailedWithTabHeader:
      "Niepowodzenie weryfikacji dla następującej karty: {tabHeader}. Brak wymaganych informacji lub podane informacje są niepoprawne.",
    validationFailedWithTabHeaders:
      "Niepowodzenie weryfikacji dla następujących kart: {tabHeaders}. Brak wymaganych informacji lub podane informacje są niepoprawne.",
    validationInProgress: "Weryfikacja w toku",
    validationMaxDate: "Wartość nie może przypadać po {maxDate}, {maxTime}.",
    validationMinDate: "Wartość nie może przypadać przed {minDate}, {minTime}.",
    validationMinMaxDateRange:
      "Wartość powinna się zawierać między {minDate}, {minTime} a {maxDate}, {maxTime}.",
    validationPassed: "Powodzenie weryfikacji.",
    validationReservedWord:
      'Wartość nie może zawierać zastrzeżonego wyrazu "{reserved}".',
    validationReservedWords:
      "Wartość nie może zawierać żadnego z następujących zastrzeżonych wyrazów: {reserved}.",
    valueMaxLen: "Wartość musi się składać z co najwyżej {maxLength} znaków.",
    valueMinLen: "Wartość musi się składać z co najmniej {minLength} znaków.",
  },
};
bundled["pt-PT"] = {
  actions: {
    add: "Acrescentar",
    addFilter: "Acrescentar filtro",
    addTags: "Clicar aqui para acrescentar identificadores",
    apply: "Aplicar",
    clearFilters: "Limpar filtros",
    copiedToClipboard: "Copiado",
    copyToClipboard: "Copiar para área de transferência",
    create: "Criar",
    delete: "Apagar",
    edit: "Editar",
    editTag: "Editar identificador",
    next: "Seguinte",
    previous: "Anterior",
    reviewCreate: "Analisar + criar",
    selectAll: "Selecionar todos",
    selectFile: "Selecionar um ficheiro",
    view: "Visualizar",
  },
  ariaLabel: {
    actionMenu: "Clique para abrir o menu de contexto",
    actionMenuHeader: "Coluna do menu de contexto",
    close: "fechar",
    collapse: "Contrair",
    datePicker: "Selecionar uma data",
    delete: "Apagar",
    dismissNotification: "Fechar notificação",
    expand: "Expandir",
    fileUpload: "Importar ficheiro",
    groupBy: "Agrupar Por",
    info: "Informações",
    more: "Mais",
    nextMonth: "Mês seguinte",
    page: "Página",
    previousMonth: "Mês anterior",
    required: "Obrigatório",
    resourceList: "Lista de recursos",
    selectItem: "Selecionar item",
    sliderInput: "Entrada do Cursor de Deslocação",
    switchView:
      "Mudar entre uma visualização de lista ou uma visualização de resumo de dados",
    timePicker: "Selecionar uma hora",
  },
  common: {
    addressesCount: "({count} endereços)",
    all: "Todos",
    cancel: "Cancelar",
    confirm: "Confirmar",
    contains: "Contém",
    discard: "Excluir",
    equals: "Igual a",
    error: "Erro",
    errorLoadingData: "Erro ao carregar os dados",
    filter: "Filtrar",
    lastViewed: "Última Visualização",
    less: "Menos",
    loading: "A Carregar...",
    more: "Mais ({count})",
    name: "Nome",
    no: "Não",
    ok: "OK",
    operator: "Operador",
    required: "Obrigatório",
    success: "Êxito",
    tag: "Identificador",
    tags: "Identificadores",
    type: "Tipo",
    value: "Valor",
    yes: "Sim",
  },
  duration: {
    daysLong: "dias",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "horas",
    hoursShort: "hr",
    hoursSymbol: "h",
    millisecondsLong: "milésimos de segundo",
    millisecondsShort: "mseg",
    millisecondsSymbol: "ms",
    minutesLong: "minutos",
    minutesShort: "min",
    minutesSymbol: "m",
    secondsLong: "segundos",
    secondsShort: "seg",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtrar em qualquer campo...",
    filterPlaceholder: "Filtrar...",
    noResultsFound: "Não foram encontrados resultados.",
    requiredField: "Campo obrigatório",
    searchForField: "Pesquisar {fieldName}",
    searchPlaceholder: "Pesquisar",
    selectAnOption: "Selecione uma opção",
    selectExisting: "Selecionar existente...",
    showSelection: "{count} selecionado",
  },
  labels: {
    addresses: "Endereços",
    essentials: "Elementos essenciais",
    tags: "Identificadores",
  },
  listTemplate: {
    learnMore: "Mais informações",
    none: "Nenhum",
    showing: "A mostrar {start} a {end} de {total} registos.",
  },
  notifications: {
    apiErrorMsg:
      "Detalhes adicionais da API subjacente que poderão ser úteis: {errMsg}",
    pollingErrorMessage: "Falha ao verificar o estado da operação.",
    pollingErrorTitle: "Falha na verificação",
  },
  pagination: {
    next: "Seguinte",
    of: "de {totalNumberOfPages}",
    page: "Página",
    previous: "Anterior",
  },
  status: {
    accepted: "Aceite",
    actionRequired: "Ação obrigatória",
    active: "Ativo",
    applied: "Aplicado",
    applying: "A Aplicar",
    assigned: "Atribuído",
    assigning: "A Atribuir",
    attached: "Anexado",
    attaching: "A Anexar",
    available: "Disponível",
    availableNeedsAttention: "Disponível, necessita de atenção",
    backupInProgress: "Cópia de segurança a decorrer",
    blocked: "Bloqueado",
    canceled: "Cancelado",
    canceling: "A Cancelar",
    cancelled: "Cancelado",
    cancelledReturned: "Cancelado/Devolvido",
    checking: "A Verificar",
    closed: "Fechado",
    complete: "Concluir",
    completed: "Concluído",
    converting: "A converter",
    created: "Criado",
    creating: "A Criar",
    creatingImage: "A criar imagem",
    deleted: "Apagado",
    deleting: "A Apagar",
    detaching: "A Desanexar",
    disabled: "Desativado",
    disabling: "A Desativar",
    down: "Inativo",
    downForMaintenance: "Inativo para manutenção",
    enabled: "Ativado",
    enabling: "A Ativar",
    error: "Erro",
    expired: "Expirado",
    exporting: "A Exportar",
    failed: "Com Falha",
    failedApply: "Aplicação com falha",
    failedRollback: "Anulação com falha",
    faulty: "Com Erros",
    importing: "A Importar",
    inactive: "Inativo",
    initiated: "Iniciado",
    inProgress: "A decorrer",
    internalError: "Erro interno",
    loading: "A Carregar",
    maintenanceInProgress: "Manutenção a decorrer",
    missing: "Em Falta",
    packaged: "Em Pacote",
    pendingCustomer: "Cliente pendente",
    pendingProvider: "Fornecedor pendente",
    pendingProvisioning: "Provisionamento pendente",
    preparing: "A Preparar",
    processed: "Processado",
    processing: "Em Processamento",
    provisioned: "Provisionado",
    provisioning: "A Provisionar",
    queued: "Em fila",
    ready: "Pronto",
    received: "Recebido",
    related: "Relacionado",
    requestReceived: "Pedido recebido",
    restoreFailed: "Falha na reposição",
    restoreInProgress: "Reposição a decorrer",
    restoring: "A Repor",
    returned: "Devolvido",
    rollingBack: "A anular",
    running: "A Executar",
    scaleInProgress: "Escalabilidade a decorrer",
    shipping: "A Expedir",
    starting: "A Iniciar",
    stopped: "Parado",
    stopping: "A Parar",
    succeeded: "Com Êxito",
    terminated: "Terminado",
    terminating: "A Terminar",
    up: "Ativo",
    unassigned: "Não Atribuído",
    unassigning: "A Anular Atribuição",
    unavailable: "Não Disponível",
    updated: "Atualizado",
    updating: "A Atualizar",
    upgrading: "A atualizar",
    waiting: "A Aguardar",
    warning: "Aviso",
  },
  tags: {
    less: "Menos",
    more: "Mais ({count})",
  },
  validation: {
    addressInvalid:
      "Endereço inválido: 'Endereço IP ou bloco de CIDR incorreto: {address}'.",
    addressOverlap: "Endereço inválido: '{address1} sobrepõe-se a {address2}'.",
    addressReserved: "Endereço inválido: '{address} está reservado'.",
    addressReservedRange:
      "Endereço inválido: '{address} está no intervalo reservado {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Endereço inválido: '{cidr} contém o endereço reservado {address}'.",
    cidrHostBitsSet:
      "Endereço inválido: 'o CIDR não pode ter os bits definidos do host'.",
    cidrInvalid: "Endereço inválido: 'Bloco de CIDR incorreto: {cidr}'.",
    cidrPrefixInvalid:
      "Endereço inválido: 'o prefixo de CIDR deve estar entre {min} e {max}, inclusive'.",
    inputRange:
      "O valor deve ter entre {minValue} e {maxValue} caracteres de comprimento.",
    integerMax: "O valor deve ser no máximo {maxValue}.",
    integerMin: "O valor deve ser pelo menos {minValue}.",
    integerRange: "O valor deve ser entre {minValue} e {maxValue}.",
    integerValid: "O valor deve ser um número inteiro válido.",
    ipAddressInvalid: "Endereço inválido: 'Endereço IP incorreto: {address}'",
    maxRows: "Devem existir, no máximo,{maxRows} entradas.",
    minRows: "Devem existir, pelo menos, {minRows} entradas.",
    requiredValidation: "O valor não pode estar vazio.",
    radioValidation: "O valor fornecido não é uma opção válida",
    timePickerValidation: "Formato de hora inválido.",
    validationFailed:
      "Falha na validação. Faltam informações obrigatórias ou as existentes não são válidas.",
    validationFailedWithTabHeader:
      "Falha na validação para o seguinte separador: {tabHeader}. Faltam informações obrigatórias ou as existentes não são válidas.",
    validationFailedWithTabHeaders:
      "Falha na validação para os seguintes separadores: {tabHeaders}. Faltam informações obrigatórias ou as existentes não são válidas.",
    validationInProgress: "Validação a decorrer",
    validationMaxDate: "O valor deve ser {maxDate}, {maxTime} ou anterior.",
    validationMinDate: "O valor deve ser {minDate}, {minTime} ou posterior.",
    validationMinMaxDateRange:
      "O valor deve ser entre {minDate}, {minTime} e {maxDate}, {maxTime}.",
    validationPassed: "Validação transmitida.",
    validationReservedWord:
      "O valor não pode conter a palavra reservada '{reserved}'.",
    validationReservedWords:
      "O valor não pode conter nenhuma das seguintes palavras reservadas: {reserved}.",
    valueMaxLen:
      "O valor deve ter, no máximo, {maxLength} caracteres de comprimento.",
    valueMinLen:
      "O valor deve ter, pelo menos, {minLength} caracteres de comprimento.",
  },
};
bundled["pt"] = {
  actions: {
    add: "Adicionar",
    addFilter: "Adicionar filtro",
    addTags: "Clique aqui para adicionar tags",
    apply: "Aplicar",
    clearFilters: "Limpar filtros",
    copiedToClipboard: "Copiado",
    copyToClipboard: "Copiar para a área de transferência",
    create: "Criar",
    delete: "Excluir",
    edit: "Editar",
    editTag: "Editar tag",
    next: "Próximo",
    previous: "Anterior",
    reviewCreate: "Verificar + criar",
    selectAll: "Selecionar tudo",
    selectFile: "Selecione um arquivo",
    view: "Exibir",
  },
  ariaLabel: {
    actionMenu: "Clique para abrir o menu de contexto",
    actionMenuHeader: "Coluna do menu de contexto",
    close: "fechar",
    collapse: "Contrair",
    datePicker: "Selecione uma data",
    delete: "Excluir",
    dismissNotification: "Descartar notificação",
    expand: "Expandir",
    fileUpload: "Fazer upload de um arquivo",
    groupBy: "Agrupar por",
    info: "Informações",
    more: "Mais",
    nextMonth: "Próximo mês",
    page: "Página",
    previousMonth: "Mês anterior",
    required: "Obrigatório",
    resourceList: "Lista de recursos",
    selectItem: "Selecionar Item",
    sliderInput: "Entrada do controle deslizante",
    switchView:
      "Alterne entre uma view de lista ou uma view resumida dos dados",
    timePicker: "Selecione um horário",
  },
  common: {
    addressesCount: "({count} endereços)",
    all: "Tudo",
    cancel: "Cancelar",
    confirm: "Confirmar",
    contains: "Contém",
    discard: "Descartar",
    equals: "Igual a",
    error: "Erro",
    errorLoadingData: "Erro ao carregar os dados",
    filter: "Filtrar",
    lastViewed: "Exibido por Último",
    less: "Menos",
    loading: "Carregando...",
    more: "Mais ({count})",
    name: "Nome",
    no: "Não",
    ok: "OK",
    operator: "Operador",
    required: "Obrigatório",
    success: "Operação bem-sucedida",
    tag: "Tag",
    tags: "Tags",
    type: "Tipo",
    value: "Valor",
    yes: "Sim",
  },
  duration: {
    daysLong: "dias",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "horas",
    hoursShort: "hr",
    hoursSymbol: "h",
    millisecondsLong: "milissegundos",
    millisecondsShort: "mseg",
    millisecondsSymbol: "ms",
    minutesLong: "minutos",
    minutesShort: "min",
    minutesSymbol: "m",
    secondsLong: "segundos",
    secondsShort: "seg",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtrar qualquer campo...",
    filterPlaceholder: "Filtrar...",
    noResultsFound: "Não foram encontrados resultados.",
    requiredField: "Campo obrigatório",
    searchForField: "Procurar por {fieldName}",
    searchPlaceholder: "Pesquisar",
    selectAnOption: "Selecione uma opção",
    selectExisting: "Selecionar existente...",
    showSelection: "{count} selecionado",
  },
  labels: {
    addresses: "Endereços",
    essentials: "Fundamentos",
    tags: "Tags",
  },
  listTemplate: {
    learnMore: "Saiba mais",
    none: "Nenhum",
    showing: "Mostrando de {start} a {end} do total de {total} registros.",
  },
  notifications: {
    apiErrorMsg:
      "Detalhes adicionais da API subjacente que podem ser úteis: {errMsg}",
    pollingErrorMessage: "Falha ao verificar o status da operação.",
    pollingErrorTitle: "Falha ao verificar",
  },
  pagination: {
    next: "Próximo",
    of: "de {totalNumberOfPages}",
    page: "Página",
    previous: "Anterior",
  },
  status: {
    accepted: "Aceito",
    actionRequired: "Ação necessária",
    active: "Ativo",
    applied: "Aplicado",
    applying: "Aplicando",
    assigned: "Designado",
    assigning: "Designando",
    attached: "Anexado",
    attaching: "Anexando",
    available: "Disponível",
    availableNeedsAttention: "Disponível exige atenção",
    backupInProgress: "Backup em andamento",
    blocked: "Bloqueado",
    canceled: "Cancelado",
    canceling: "Cancelando",
    cancelled: "Cancelado",
    cancelledReturned: "Cancelado retornado",
    checking: "Verificando",
    closed: "Fechado",
    complete: "Concluído",
    completed: "Concluído",
    converting: "Convertendo",
    created: "Criado",
    creating: "Criando",
    creatingImage: "Criando imagem",
    deleted: "Excluído",
    deleting: "Excluindo",
    detaching: "Desanexando",
    disabled: "Desativado",
    disabling: "Desativando",
    down: "Desativado",
    downForMaintenance: "Desativado para fins de manutenção",
    enabled: "Ativado",
    enabling: "Ativando",
    error: "Erro",
    expired: "Expirado",
    exporting: "Exportando",
    failed: "Com falha",
    failedApply: "Aplicação com falha",
    failedRollback: "Rollback com falha",
    faulty: "Com falhas",
    importing: "Importando",
    inactive: "Inativo",
    initiated: "Iniciado",
    inProgress: "Em andamento",
    internalError: "Erro interno",
    loading: "Carregando",
    maintenanceInProgress: "Manutenção em andamento",
    missing: "Ausente",
    packaged: "Empacotado",
    pendingCustomer: "Cliente pendente",
    pendingProvider: "Provedor pendente",
    pendingProvisioning: "Provisionamento pendente",
    preparing: "Preparando",
    processed: "Processado",
    processing: "Processando",
    provisioned: "Provisionado",
    provisioning: "Provisionando",
    queued: "Na fila",
    ready: "Pronto",
    received: "Recebido",
    related: "Relacionado",
    requestReceived: "Solicitação recebida",
    restoreFailed: "Falha na restauração",
    restoreInProgress: "Restauração em andamento",
    restoring: "Restaurando",
    returned: "Retornado",
    rollingBack: "Fazendo rollback",
    running: "Em execução",
    scaleInProgress: "Dimensionamento em andamento",
    shipping: "Enviando",
    starting: "Iniciando",
    stopped: "Interrompido",
    stopping: "Interrompendo",
    succeeded: "Bem-sucedido",
    terminated: "Encerrado",
    terminating: "Encerrando",
    up: "Em funcionamento",
    unassigned: "Não designado",
    unassigning: "Cancelando designação",
    unavailable: "Indisponível",
    updated: "Atualizado",
    updating: "Atualizando",
    upgrading: "Fazendo Upgrade",
    waiting: "Aguardando",
    warning: "Advertência",
  },
  tags: {
    less: "Menos",
    more: "Mais ({count})",
  },
  validation: {
    addressInvalid:
      "Endereço inválido: 'Endereço IP ou bloco CIDR mal formado: {address}'.",
    addressOverlap: "Endereço inválido: {address1} se sobrepõe a {address2}'.",
    addressReserved: "Endereço inválido: '{address} está reservado'.",
    addressReservedRange:
      "Endereço inválido: '{address} está dentro do intervalo reservado {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Endereço inválido: '{cidr}' contém o endereço reservado {address}'.",
    cidrHostBitsSet:
      "Endereço inválido: 'O CIDR não pode definir os bits do host'.",
    cidrInvalid: "Endereço inválido: 'Bloco CIDR mal formado: {cidr}'.",
    cidrPrefixInvalid:
      "Endereço inválido: 'O prefixo CIDR deve estar entre {min} e {max} inclusive'.",
    inputRange: "O valor deve ter entre {minValue} e {maxValue} caracteres.",
    integerMax: "O valor deve ser no máximo {maxValue}.",
    integerMin: "O valor deve ser pelo menos {minValue}.",
    integerRange: "O valor deve ser entre {minValue} e {maxValue}.",
    integerValid: "O valor deve ser um número inteiro válido.",
    ipAddressInvalid: "Endereço inválido: 'Endereço IP mal formado: {address}'",
    maxRows: "Deve haver no máximo {maxRows} entradas.",
    minRows: "Deve haver pelo menos {minRows} entradas.",
    requiredValidation: "O valor não deve estar vazio.",
    radioValidation: "O valor informado não é uma opção válida",
    timePickerValidation: "Formato de hora inválido.",
    validationFailed:
      "Falha na validação. Faltam as informações necessárias ou elas não são válidas.",
    validationFailedWithTabHeader:
      "Falha na validação para a seguinte guia: {tabHeader}. Faltam as informações necessárias ou elas não são válidas.",
    validationFailedWithTabHeaders:
      "Falha na validação para as seguintes guias: {tabHeaders}. Faltam as informações necessárias ou elas não são válidas.",
    validationInProgress: "Validação em andamento",
    validationMaxDate: "O valor deve ser {maxDate}, {maxTime} ou anterior.",
    validationMinDate: "O valor deve ser {minDate}, {minTime} ou posterior.",
    validationMinMaxDateRange:
      "O valor deve estar entre {minDate}, {minTime} e {maxDate}. {maxTime}.",
    validationPassed: "Validação bem-sucedida.",
    validationReservedWord:
      "O valor não pode conter a palavra reservada '{reserved}'.",
    validationReservedWords:
      "O valor não pode conter nenhuma das seguintes palavras reservadas: {reserved}.",
    valueMaxLen: "O valor deve ter no máximo {maxLength} caracteres.",
    valueMinLen: "O valor deve ter pelo menos {minLength} caracteres.",
  },
};
bundled["ro"] = {
  actions: {
    add: "Adăugare",
    addFilter: "Adăugare filtru",
    addTags: "Faceţi clic aici pentru a adăuga taguri",
    apply: "Aplicare",
    clearFilters: "Golire filtre",
    copiedToClipboard: "Copiate",
    copyToClipboard: "Copiere în clipboard",
    create: "Creare",
    delete: "Ştergere",
    edit: "Editare",
    editTag: "Editare tag",
    next: "Înainte",
    previous: "Înapoi",
    reviewCreate: "Examinare şi creare",
    selectAll: "Selectare toate",
    selectFile: "Selectaţi un fişier",
    view: "Vizualizare",
  },
  ariaLabel: {
    actionMenu: "Faceţi clic pt. a deschide meniul contextual",
    actionMenuHeader: "Coloană meniu contextual",
    close: "închidere",
    collapse: "Restrângere",
    datePicker: "Selectaţi o dată",
    delete: "Ştergere",
    dismissNotification: "Ignorare înştiinţare",
    expand: "Extindere",
    fileUpload: "Încărcare fişier",
    groupBy: "Grupare după ",
    info: "Informaţii",
    more: "Mai multe",
    nextMonth: "Luna următoare",
    page: "Pagină",
    previousMonth: "Luna precedentă",
    required: "Obligatorie",
    resourceList: "Listă de resurse",
    selectItem: "Selectare element",
    sliderInput: "Intrare slider",
    switchView:
      "Comutaţi între o vizualizare de tip listă şi o vizualizare sumarizată a datelor",
    timePicker: "Selectaţi o oră",
  },
  common: {
    addressesCount: "({count} adrese)",
    all: "Toate",
    cancel: "Anulare",
    confirm: "Confirmare",
    contains: "Conţine",
    discard: "Abandonare",
    equals: "Este egal cu",
    error: "Eroare",
    errorLoadingData: "Eroare la încărcarea datelor",
    filter: "Filtrare",
    lastViewed: "Ultima vizualizare",
    less: "Mai puţine",
    loading: "Se încarcă...",
    more: "Mai multe ({count})",
    name: "Nume",
    no: "Nu",
    ok: "OK",
    operator: "Operator",
    required: "Obligatorie",
    success: "Succes",
    tag: "Tag",
    tags: "Taguri",
    type: "Tip",
    value: "Valoare",
    yes: "Da",
  },
  duration: {
    daysLong: "zile",
    daysShort: "z",
    daysSymbol: "z",
    hoursLong: "ore",
    hoursShort: "h",
    hoursSymbol: "h",
    millisecondsLong: "milisecunde",
    millisecondsShort: "msec",
    millisecondsSymbol: "ms",
    minutesLong: "minute",
    minutesShort: "min",
    minutesSymbol: "m",
    secondsLong: "secunde",
    secondsShort: "sec",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtrare pentru orice câmp...",
    filterPlaceholder: "Filtrare...",
    noResultsFound: "Nu s-a găsit niciun rezultat.",
    requiredField: "Câmp obligatoriu",
    searchForField: "Căutare: {fieldName}",
    searchPlaceholder: "Căutare",
    selectAnOption: "Selectaţi o opţiune",
    selectExisting: "Selectare element existent...",
    showSelection: "{count} selectate",
  },
  labels: {
    addresses: "Adrese",
    essentials: "Esenţiale",
    tags: "Taguri",
  },
  listTemplate: {
    learnMore: "Aflaţi mai multe",
    none: "Fără",
    showing: "Se afişează {start} la {end} din {total} înregistrări.",
  },
  notifications: {
    apiErrorMsg:
      "Detalii suplimentare din API-ul de bază ce pot fi utile: {errMsg}",
    pollingErrorMessage: "Eşec la verificarea stării operaţiei.",
    pollingErrorTitle: "Eşec la verificare",
  },
  pagination: {
    next: "Înainte",
    of: "din {totalNumberOfPages}",
    page: "Pagină",
    previous: "Înapoi",
  },
  status: {
    accepted: "Acceptat",
    actionRequired: "Acţiune obligatorie",
    active: "Activ",
    applied: "Aplicat",
    applying: "Se aplică",
    assigned: "Asignat",
    assigning: "Se asignează",
    attached: "Ataşat",
    attaching: "Se ataşează",
    available: "Disponibil",
    availableNeedsAttention: "Disponibil, necesită atenţie",
    backupInProgress: "Se creează copia de siguranţă",
    blocked: "Blocat",
    canceled: "Anulat",
    canceling: "Se anulează",
    cancelled: "Anulat",
    cancelledReturned: "Anulat returnat",
    checking: "Se verifică",
    closed: "Închis",
    complete: "Finalizare",
    completed: "Finalizat",
    converting: "Se converteşte",
    created: "Creat",
    creating: "Se creează",
    creatingImage: "Se creează imaginea",
    deleted: "Şters",
    deleting: "Se şterge",
    detaching: "Se detaşează",
    disabled: "Dezactivat",
    disabling: "Se dezactivează",
    down: "Jos",
    downForMaintenance: "Oprit pt. mentenanţă",
    enabled: "Activat",
    enabling: "Se activează",
    error: "Eroare",
    expired: "Expirat",
    exporting: "Se exportă",
    failed: "Eşuat",
    failedApply: "Aplicare eşuată",
    failedRollback: "Derulare înapoi eşuată",
    faulty: "Defect",
    importing: "Se importă",
    inactive: "Inactiv",
    initiated: "Iniţiat",
    inProgress: "În desfăşurare",
    internalError: "Eroare internă",
    loading: "Se încarcă",
    maintenanceInProgress: "Întreţinere în curs de desfăşurare",
    missing: "Lipsă",
    packaged: "Împachetat",
    pendingCustomer: "În aşteptarea clientului",
    pendingProvider: "În aşteptarea furnizorului",
    pendingProvisioning: "În aşteptarea alocării",
    preparing: "Se pregăteşte",
    processed: "Procesat",
    processing: "Se procesează",
    provisioned: "Alocat",
    provisioning: "Se alocă",
    queued: "În coadă",
    ready: "Pregătit",
    received: "Primit",
    related: "Corelat",
    requestReceived: "Cerere primită",
    restoreFailed: "Restabilirea a eşuat",
    restoreInProgress: "Restabilire în curs",
    restoring: "Se restabileşte",
    returned: "Returnat",
    rollingBack: "Se derulează înapoi",
    running: "În curs de rulare",
    scaleInProgress: "Scalare în curs",
    shipping: "Expediere",
    starting: "Se porneşte",
    stopped: "Oprit",
    stopping: "Se opreşte",
    succeeded: "Reuşit",
    terminated: "Deconectat",
    terminating: "Se deconectează",
    up: "Sus",
    unassigned: "Neasignat",
    unassigning: "Se dezasignează",
    unavailable: "Indisponibil",
    updated: "Actualizat",
    updating: "Se actualizează",
    upgrading: "Se upgradează",
    waiting: "Se aşteaptă",
    warning: "Avertisment",
  },
  tags: {
    less: "Mai puţine",
    more: "Mai multe ({count})",
  },
  validation: {
    addressInvalid:
      "Adresă nevalidă: 'Adresă de IP sau bloc CIDR format greşit: {address}'.",
    addressOverlap: "Adresă nevalidă: '{address1} se suprapune cu {address2}'.",
    addressReserved: "Adresă nevalidă: '{address} este rezervată'.",
    addressReservedRange:
      "Adresă nevalidă: '{address} este în intervalul rezervat {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Invalid address: '{cidr}' conţine adresa rezervată {address}'.",
    cidrHostBitsSet:
      "Adresă nevalidă: 'CIDR nu poate avea setaţi biţii pentru gazdă'.",
    cidrInvalid: "Adresă nevalidă: 'Bloc CIDR format greşit: {cidr}'.",
    cidrPrefixInvalid:
      "Adresă nevalidă: 'Prefixul CIDR trebuie să fie între {min} şi {max}, inclusiv'.",
    inputRange:
      "Valoarea trebuie să aibă între {minValue} şi {maxValue} caractere.",
    integerMax: "Valoarea trebuie să fie maximum {maxValue}.",
    integerMin: "Valoarea trebuie să fie minimum {minValue}.",
    integerRange:
      "Valoarea trebuie să fie cuprinsă între {minValue} şi {maxValue}.",
    integerValid: "Valoarea trebuie să fie un număr întreg valid.",
    ipAddressInvalid:
      "Adresă nevalidă: 'Adresă de IP formată greşit: {address}'",
    maxRows: "Trebuie să existe maximum {maxRows} intrări.",
    minRows: "Trebuie să existe minimum {minRows} intrări.",
    requiredValidation: "Valoarea nu trebuie să fie goală.",
    radioValidation: "Valoarea introdusă nu este o opţiune validă",
    timePickerValidation: "Format nevalid al orei.",
    validationFailed:
      "Validare eşuată. Informaţiile obligatorii lipsesc sau nu sunt valide.",
    validationFailedWithTabHeader:
      "Eşec la validarea pt. următoarea filă: {tabHeader}. Informaţiile obligatorii lipsesc sau nu sunt valide.",
    validationFailedWithTabHeaders:
      "Eşec la validarea pt. următoarele file: {tabHeaders}. Informaţiile obligatorii lipsesc sau nu sunt valide.",
    validationInProgress: "Validare în curs",
    validationMaxDate:
      "Valoarea trebuie să fie {maxDate}, {maxTime} sau o dată anterioară acesteia.",
    validationMinDate:
      "Valoarea trebuie să fie {minDate}, {minTime} sau o dată ulterioară acesteia.",
    validationMinMaxDateRange:
      "Valoarea trebuie să se încadreze între {minDate}, {minTime} şi {maxDate}, {maxTime}.",
    validationPassed: "Validare reuşită.",
    validationReservedWord:
      "Valoarea nu poate conţine cuvântul rezervat '{reserved}'.",
    validationReservedWords:
      "Valoarea nu poate conţine niciunul din următoarele cuvinte rezervate: {reserved}.",
    valueMaxLen: "Valoarea trebuie să aibă maximum {maxLength} caractere.",
    valueMinLen:
      "Valoarea trebuie să aibă o lungime de cel puţin {minLength} caractere.",
  },
};
bundled["ru"] = {
  actions: {
    add: "Добавить",
    addFilter: "Добавить фильтр",
    addTags: "Щелкните здесь для добавления тегов",
    apply: "Применить",
    clearFilters: "Очистить фильтры",
    copiedToClipboard: "Скопировано",
    copyToClipboard: "Копировать в буфер обмена",
    create: "Создать",
    delete: "Удалить",
    edit: "Изменить",
    editTag: "Изменить тег",
    next: "Вперед",
    previous: "Назад",
    reviewCreate: "Просмотр и создание",
    selectAll: "Выбрать все",
    selectFile: "Выберите файл",
    view: "Просмотр",
  },
  ariaLabel: {
    actionMenu: "Нажмите, чтобы открыть контекстное меню",
    actionMenuHeader: "Столбец контекстного меню",
    close: "закрыть",
    collapse: "Свернуть",
    datePicker: "Выбрать дату",
    delete: "Удалить",
    dismissNotification: "Закрыть уведомление",
    expand: "Развернуть",
    fileUpload: "Загрузить файл в сеть",
    groupBy: "Группировать по",
    info: "Информация",
    more: "Больше",
    nextMonth: "Следующий месяц",
    page: "Стр.",
    previousMonth: "Предыдущий месяц",
    required: "Обязательно",
    resourceList: "Список ресурсов",
    selectItem: "Выберите элемент",
    sliderInput: "Ползунок входных данных",
    switchView:
      "Переключение между представлением списка и сводным представлением данных",
    timePicker: "Выберите время",
  },
  common: {
    addressesCount: "({count} адрес.)",
    all: "Все",
    cancel: "Отмена",
    confirm: "Подтвердить",
    contains: "Содержит",
    discard: "Отменить",
    equals: "Равно",
    error: "Ошибка",
    errorLoadingData: "Ошибка при загрузке данных",
    filter: "Фильтр",
    lastViewed: "Последний просмотр",
    less: "Меньше",
    loading: "Загрузка...",
    more: "Еще {count}",
    name: "Имя",
    no: "Нет",
    ok: "ОК",
    operator: "Оператор",
    required: "Обязательно",
    success: "Успешно",
    tag: "Тег",
    tags: "Теги",
    type: "Тип",
    value: "Значение",
    yes: "Да",
  },
  duration: {
    daysLong: "дн.",
    daysShort: "д",
    daysSymbol: "д",
    hoursLong: "час.",
    hoursShort: "ч",
    hoursSymbol: "ч",
    millisecondsLong: "миллисек.",
    millisecondsShort: "мс",
    millisecondsSymbol: "мс",
    minutesLong: "мин.",
    minutesShort: "мин",
    minutesSymbol: "м",
    secondsLong: "сек.",
    secondsShort: "с",
    secondsSymbol: "с",
  },
  hints: {
    filterAnyField: "Фильтр для любого поля...",
    filterPlaceholder: "Фильтр...",
    noResultsFound: "Результаты не найдены.",
    requiredField: "Обязательное поле",
    searchForField: "Искать {fieldName}",
    searchPlaceholder: "Поиск",
    selectAnOption: "Выбор варианта",
    selectExisting: "Выбрать существующий...",
    showSelection: "{count} выбрано",
  },
  labels: {
    addresses: "Адреса",
    essentials: "Основы",
    tags: "Теги",
  },
  listTemplate: {
    learnMore: "Подробнее",
    none: "Нет",
    showing: "Показаны записи с {start} по {end} из {total} записей.",
  },
  notifications: {
    apiErrorMsg:
      "Дополнительные сведения из базового API, которые могут быть полезны: {errMsg}",
    pollingErrorMessage: "Не удалось проверить статус операции.",
    pollingErrorTitle: "Сбой при проверке",
  },
  pagination: {
    next: "Вперед",
    of: "из {totalNumberOfPages}",
    page: "Стр.",
    previous: "Назад",
  },
  status: {
    accepted: "Принято",
    actionRequired: "Требуется действие",
    active: "Активно",
    applied: "Применено",
    applying: "Применяется",
    assigned: "Назначено",
    assigning: "Назначается",
    attached: "Прикреплено",
    attaching: "Прикрепление",
    available: "Доступно",
    availableNeedsAttention: "Доступно - требует внимания",
    backupInProgress: "Выполняется резервное копирование",
    blocked: "Блокировано",
    canceled: "Отменено",
    canceling: "Выполняется отмена",
    cancelled: "Отменено",
    cancelledReturned: "Отменено, возвращено",
    checking: "Выполняется проверка",
    closed: "Закрыто",
    complete: "Завершение",
    completed: "Завершено",
    converting: "Выполняется преобразование",
    created: "Создано",
    creating: "Создание",
    creatingImage: "Создание образа",
    deleted: "Удалено",
    deleting: "Удаление",
    detaching: "Открепление",
    disabled: "Отключено",
    disabling: "Выполняется отключение",
    down: "Не работает",
    downForMaintenance: "Отключено для техобслуживания",
    enabled: "Включено",
    enabling: "Выполняется включение",
    error: "Ошибка",
    expired: "Истек срок действия",
    exporting: "Выполняется экспорт",
    failed: "Сбой",
    failedApply: "Не удалось применить",
    failedRollback: "Не удалось выполнить откат",
    faulty: "Неисправно",
    importing: "Выполняется импорт",
    inactive: "Неактивно",
    initiated: "Инициировано",
    inProgress: "Выполняется",
    internalError: "Внутр. ошибка",
    loading: "Загрузка",
    maintenanceInProgress: "Проводится техобслуживание",
    missing: "Отсутствует",
    packaged: "Упаковано",
    pendingCustomer: "Ожидание клиента",
    pendingProvider: "Ожидание провайдера",
    pendingProvisioning: "Ожидает предоставления",
    preparing: "Подготовка",
    processed: "Обработано",
    processing: "Обработка",
    provisioned: "Инициализировано",
    provisioning: "Выполняется предоставление",
    queued: "В очереди",
    ready: "Готово",
    received: "Получено",
    related: "Связано",
    requestReceived: "Запрос получен",
    restoreFailed: "Сбой при восстановлении",
    restoreInProgress: "Выполняется восстановление",
    restoring: "Восстановление",
    returned: "Возвращено",
    rollingBack: "Выполняется откат",
    running: "Выполняется",
    scaleInProgress: "Выполняется масштабирование",
    shipping: "Отгрузка",
    starting: "Выполняется запуск",
    stopped: "Остановлено",
    stopping: "Выполняется останов",
    succeeded: "Успешно выполнено",
    terminated: "Прекращено",
    terminating: "Прекращение",
    up: "Работает",
    unassigned: "Не назначено",
    unassigning: "Отмена назначения",
    unavailable: "Недоступно",
    updated: "Обновлено",
    updating: "Обновление",
    upgrading: "Повышение уровня",
    waiting: "Ожидание",
    warning: "Предупреждение",
  },
  tags: {
    less: "Меньше",
    more: "Еще {count}",
  },
  validation: {
    addressInvalid:
      "Недопустимый адрес. Неверный формат IP-адреса или блока CIDR: {address}.",
    addressOverlap:
      "Недопустимый адрес, {address1} перекрывается с {address2}.",
    addressReserved: "Недопустимый адрес, {address} зарезервирован.",
    addressReservedRange:
      "Недопустимый адрес, {address} находится в зарезервированном диапазоне {cidr} ({startingIp} - {endingIp}).",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Недопустимый адрес, {cidr} содержит зарезервированный адрес {address}.",
    cidrHostBitsSet:
      "Недопустимый адрес, невозможно задать биты хоста для CIDR.",
    cidrInvalid: "Недопустимый адрес. Неверный формат блока CIDR: {cidr}.",
    cidrPrefixInvalid:
      "Недопустимый адрес, префикс CIDR должен быть в диапазоне от {min} до {max} включительно.",
    inputRange:
      "Значение должно быть длиной от {minValue} до {maxValue} символов.",
    integerMax: "Значение должно быть не больше {maxValue}.",
    integerMin: "Значение должно быть не меньше {minValue}.",
    integerRange:
      "Значение должно быть в диапазоне от {minValue} до {maxValue}.",
    integerValid: "Значение должно быть допустимым целым числом.",
    ipAddressInvalid:
      "Недопустимый адрес. Неверный формат IP-адреса: {address}",
    maxRows: "Должно быть не более {maxRows} записей.",
    minRows: "Должно быть не менее {minRows} записей.",
    requiredValidation: "Значение должно быть непустым.",
    radioValidation: "Указанное значение не является допустимым параметром",
    timePickerValidation: "Неверный формат времени.",
    validationFailed:
      "Ошибка проверки. Отсутствует или недопустима обязательная информация.",
    validationFailedWithTabHeader:
      "Сбой при проверке на вкладке {tabHeader}. Необходимая информация отсутствует или недопустима.",
    validationFailedWithTabHeaders:
      "Сбой при проверке на вкладках: {tabHeaders}. Необходимая информация отсутствует или недопустима.",
    validationInProgress: "Выполняется проверка",
    validationMaxDate: "Значение должно быть не позднее {maxDate}, {maxTime}.",
    validationMinDate: "Значение должно быть не ранее {minDate}, {minTime}.",
    validationMinMaxDateRange:
      "Значение должно быть в диапазоне от {minDate}, {minTime} до {maxDate}, {maxTime}.",
    validationPassed: "Проверка пройдена.",
    validationReservedWord:
      'Значение не может содержать зарезервированное слово "{reserved}".',
    validationReservedWords:
      "Значение не может содержать зарезервированные слова: {reserved}.",
    valueMaxLen: "Значение должно быть длиной не более {maxLength} символов.",
    valueMinLen: "Длина слова должна быть как минимум {minLength} символа.",
  },
};
bundled["sk"] = {
  actions: {
    add: "Pridať",
    addFilter: "Pridať filter",
    addTags: "Kliknite sem a pridajte značky",
    apply: "Použiť",
    clearFilters: "Vymazať filtre",
    copiedToClipboard: "Skopírované",
    copyToClipboard: "Skopírovať do schránky",
    create: "Vytvoriť",
    delete: "Odstrániť",
    edit: "Upraviť",
    editTag: "Upraviť značku",
    next: "Ďalej",
    previous: "Späť",
    reviewCreate: "Skontrolovať + vytvoriť",
    selectAll: "Vybrať všetko",
    selectFile: "Vyberte súbor",
    view: "Zobraziť",
  },
  ariaLabel: {
    actionMenu: "Kliknutím otvoríte kontextovú ponuku",
    actionMenuHeader: "Stĺpec kontextovej ponuky",
    close: "zatvoriť",
    collapse: "Zbaliť",
    datePicker: "Vyberte dátum",
    delete: "Odstrániť",
    dismissNotification: "Zrušiť upozornenie",
    expand: "Rozbaliť",
    fileUpload: "Nahrajte súbor",
    groupBy: "Zoskupiť podľa",
    info: "Informácie",
    more: "Viac",
    nextMonth: "Nasledujúci mesiac",
    page: "Stránka",
    previousMonth: "Predchádzajúci mesiac",
    required: "Povinné",
    resourceList: "Zoznam prostriedkov",
    selectItem: "Vybrať položku",
    sliderInput: "Vstup jazdca",
    switchView: "Prepínať medzi zobrazením zoznamu a súhrnným zobrazením dát.",
    timePicker: "Vyberte čas",
  },
  common: {
    addressesCount: "(počet adries: {count})",
    all: "Všetko",
    cancel: "Zrušiť",
    confirm: "Potvrdiť",
    contains: "Obsahuje",
    discard: "Vyradiť",
    equals: "Rovná sa",
    error: "Chyba",
    errorLoadingData: "Chyba pri načítavaní dát",
    filter: "Filter",
    lastViewed: "Naposledy zobrazené",
    less: "Menej",
    loading: "Načítava sa...",
    more: "Viac ({count})",
    name: "Názov",
    no: "Nie",
    ok: "OK",
    operator: "Operátor",
    required: "Povinné",
    success: "Úspech",
    tag: "Značka",
    tags: "Značky",
    type: "Typ",
    value: "Hodnota",
    yes: "Áno",
  },
  duration: {
    daysLong: "dni",
    daysShort: "d.",
    daysSymbol: "d",
    hoursLong: "hodiny",
    hoursShort: "hod.",
    hoursSymbol: "h",
    millisecondsLong: "milisekundy",
    millisecondsShort: "milisek.",
    millisecondsSymbol: "ms",
    minutesLong: "minúty",
    minutesShort: "min.",
    minutesSymbol: "min",
    secondsLong: "sekundy",
    secondsShort: "sek.",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtrovať ľubovoľné pole...",
    filterPlaceholder: "Filter...",
    noResultsFound: "Nenašli sa žiadne výsledky.",
    requiredField: "Povinné pole",
    searchForField: "Hľadať pole {fieldName}",
    searchPlaceholder: "Vyhľadať",
    selectAnOption: "Vyberte voľbu",
    selectExisting: "Vybrať existujúce...",
    showSelection: "Vybrané: {count}",
  },
  labels: {
    addresses: "Adresy",
    essentials: "Základné informácie",
    tags: "Značky",
  },
  listTemplate: {
    learnMore: "Ďalšie informácie",
    none: "Žiadne",
    showing: "Zobrazuje sa {start} až {end} z {total} záznamov.",
  },
  notifications: {
    apiErrorMsg:
      "Ďalšie detaily zo základného rozhrania API, ktoré môžu byť užitočné: {errMsg}",
    pollingErrorMessage: "Nepodarilo sa skontrolovať stav operácie.",
    pollingErrorTitle: "Nepodarilo sa skontrolovať",
  },
  pagination: {
    next: "Ďalej",
    of: "z {totalNumberOfPages}",
    page: "Stránka",
    previous: "Späť",
  },
  status: {
    accepted: "Prijaté",
    actionRequired: "Požaduje sa akcia",
    active: "Aktívne",
    applied: "Použité",
    applying: "Aplikuje sa",
    assigned: "Priradené",
    assigning: "Priraďuje sa",
    attached: "Pripojené",
    attaching: "Pripája sa",
    available: "Dostupné",
    availableNeedsAttention: "Dostupné vyžaduje pozornosť",
    backupInProgress: "Prebieha zálohovanie",
    blocked: "Blokované",
    canceled: "Zrušené",
    canceling: "Ruší sa",
    cancelled: "Zrušené",
    cancelledReturned: "Vrátil sa stav Zrušené",
    checking: "Kontroluje sa",
    closed: "Uzavreté",
    complete: "Dokončuje sa",
    completed: "Dokončené",
    converting: "Prebieha konverzia",
    created: "Vytvorené",
    creating: "Vytvára sa",
    creatingImage: "Vytvára sa obraz",
    deleted: "Odstránené",
    deleting: "Odstraňuje sa",
    detaching: "Odpája sa",
    disabled: "Deaktivované",
    disabling: "Deaktivuje sa",
    down: "Mimo prevádzky",
    downForMaintenance: "Mimo prevádzky z dôvodu údržby",
    enabled: "Aktivované",
    enabling: "Aktivuje sa",
    error: "Chyba",
    expired: "Platnosť uplynula",
    exporting: "Exportuje sa",
    failed: "Neúspešné",
    failedApply: "Nepodarilo sa použiť",
    failedRollback: "Rollback zlyhal",
    faulty: "Chybné",
    importing: "Importuje sa",
    inactive: "Neaktívne",
    initiated: "Iniciované",
    inProgress: "Prebieha",
    internalError: "Interná chyba",
    loading: "Načítava sa",
    maintenanceInProgress: "Prebieha údržba",
    missing: "Chýbajúce",
    packaged: "Zbalené",
    pendingCustomer: "Čaká sa na zákazníka",
    pendingProvider: "Čaká sa na poskytovateľa",
    pendingProvisioning: "Čaká sa na poskytnutie",
    preparing: "Pripravuje sa",
    processed: "Spracované",
    processing: "Spracúva sa",
    provisioned: "Poskytnuté",
    provisioning: "Poskytuje sa",
    queued: "Vo fronte",
    ready: "Pripravené",
    received: "Prijaté",
    related: "Súvisiace",
    requestReceived: "Požiadavka prijatá",
    restoreFailed: "Obnovenie zlyhalo",
    restoreInProgress: "Prebieha obnovenie",
    restoring: "Obnovuje sa",
    returned: "Vrátené",
    rollingBack: "Vykonáva sa rollback",
    running: "Spustené",
    scaleInProgress: "Prebieha škálovanie",
    shipping: "Dodáva sa",
    starting: "Spúšťa sa",
    stopped: "Zastavené",
    stopping: "Zastavuje sa",
    succeeded: "Úspešné",
    terminated: "Ukončené",
    terminating: "Ukončuje sa",
    up: "V prevádzke",
    unassigned: "Nepriradené",
    unassigning: "Ruší sa priradenie",
    unavailable: "Nedostupné",
    updated: "Aktualizované",
    updating: "Aktualizuje sa",
    upgrading: "Zvyšuje sa verzia",
    waiting: "Čaká sa",
    warning: "Upozornenie",
  },
  tags: {
    less: "Menej",
    more: "Viac ({count})",
  },
  validation: {
    addressInvalid:
      "Neplatná adresa: 'Chybne vytvorená IP adresa alebo blok CIDR: {address}'.",
    addressOverlap:
      "Neplatná adresa: '{address1} sa prekrýva s adresou {address2}'.",
    addressReserved: "Neplatná adresa: adresa '{address} je rezervovaná'.",
    addressReservedRange:
      "Neplatná adresa: '{address} je v rámci rezervovaného rozsahu {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Neplatná adresa: '{cidr}' obsahuje rezervovanú adresu {address}'.",
    cidrHostBitsSet:
      "Neplatná adresa: 'CIDR nemôže mať nastavené bity hostiteľa'.",
    cidrInvalid: "Neplatná adresa: 'Chybne vytvorený blok CIDR: {cidr}'.",
    cidrPrefixInvalid:
      "Neplatná adresa: 'predpona CIDR musí byť medzi {min} a {max} vrátane'.",
    inputRange: "Dĺžka hodnoty musí byť medzi {minValue} a {maxValue} znakmi.",
    integerMax: "Hodnota musí byť maximálne {maxValue}.",
    integerMin: "Hodnota musí byť minimálne {minValue}.",
    integerRange: "Hodnota musí byť medzi {minValue} a {maxValue}.",
    integerValid: "Hodnota musí byť platné celé číslo.",
    ipAddressInvalid:
      "Neplatná adresa: 'Chybne vytvorená IP adresa: {address}'",
    maxRows: "Môže existovať maximálne {maxRows} položiek.",
    minRows: "Minimálny počet položiek musí byť {minRows}.",
    requiredValidation: "Táto hodnota nesmie byť prázdna.",
    radioValidation: "Zadaná hodnota nie je platná voľba",
    timePickerValidation: "Neplatný formát času.",
    validationFailed:
      "Overenie zlyhalo. Požadované informácie chýbajú alebo nie sú platné.",
    validationFailedWithTabHeader:
      "Overenie zlyhalo pre nasledujúcu kartu: {tabHeader}. Požadované informácie chýbajú alebo nie sú platné.",
    validationFailedWithTabHeaders:
      "Overenie zlyhalo pre nasledujúce karty: {tabHeaders}. Požadované informácie chýbajú alebo nie sú platné.",
    validationInProgress: "Prebieha overenie platnosti",
    validationMaxDate:
      "Hodnota by mala byť {maxDate}, {maxTime} alebo skoršia.",
    validationMinDate:
      "Hodnota by mala byť {minDate}, {minTime} alebo neskoršia.",
    validationMinMaxDateRange:
      "Hodnota by mala byť medzi {minDate}, {minTime} a {maxDate}, {maxTime}.",
    validationPassed: "Overenie bolo úspešné.",
    validationReservedWord:
      "Hodnota nemôže obsahovať rezervované slovo '{reserved}'.",
    validationReservedWords:
      "Hodnota nemôže obsahovať žiadne z nasledujúcich rezervovaných slov: {reserved}.",
    valueMaxLen: "Dĺžka hodnoty môže byť najviac {maxLength} znakov.",
    valueMinLen: "Dĺžka hodnoty musí byť najmenej {minLength} znakov.",
  },
};
bundled["sl"] = {
  actions: {
    add: "Dodaj",
    addFilter: "Dodaj filter",
    addTags: "Kliknite tukaj za dodajanje oznak",
    apply: "Dodeli",
    clearFilters: "Počisti filtre",
    copiedToClipboard: "Kopirano",
    copyToClipboard: "Kopiraj v odložišče",
    create: "Ustvari",
    delete: "Izbriši",
    edit: "Uredi",
    editTag: "Uredi oznako",
    next: "Naprej",
    previous: "Nazaj",
    reviewCreate: "Preglej + ustvari",
    selectAll: "Izberi vse",
    selectFile: "Izbira datoteke",
    view: "Ogled",
  },
  ariaLabel: {
    actionMenu: "Kliknite za odpiranje kontekstnega menija",
    actionMenuHeader: "Stolpec kontekstnega menija",
    close: "zapri",
    collapse: "Strni",
    datePicker: "Izbira datuma",
    delete: "Izbriši",
    dismissNotification: "Opusti obvestilo",
    expand: "Razširi",
    fileUpload: "Naloži datoteko",
    groupBy: "Grupiraj po",
    info: "Informacije",
    more: "Več",
    nextMonth: "Naslednji mesec",
    page: "Stran",
    previousMonth: "Prejšnji mesec",
    required: "Zahtevano",
    resourceList: "Seznam virov",
    selectItem: "Izberite element",
    sliderInput: "Vhod drsnika",
    switchView:
      "Preklopite med pogledom seznama ali pogledom povzetka podatkov",
    timePicker: "Izbira časa",
  },
  common: {
    addressesCount: "(Št. naslovov: {count})",
    all: "Vse",
    cancel: "Prekliči",
    confirm: "Potrdi",
    contains: "Vsebuje",
    discard: "Zavrzi",
    equals: "Je enako",
    error: "Napaka",
    errorLoadingData: "Napaka pri nalaganju podatkov",
    filter: "Filter",
    lastViewed: "Zadnji ogled",
    less: "Manj",
    loading: "Nalaganje ...",
    more: "Več ({count})",
    name: "Ime",
    no: "Ne",
    ok: "V redu",
    operator: "Operator",
    required: "Zahtevano",
    success: "Uspeh",
    tag: "Oznaka",
    tags: "Oznake",
    type: "Tip",
    value: "Vrednost",
    yes: "Da",
  },
  duration: {
    daysLong: "dnevi",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "ure",
    hoursShort: "h",
    hoursSymbol: "h",
    millisecondsLong: "milisekunde",
    millisecondsShort: "ms",
    millisecondsSymbol: "ms",
    minutesLong: "minute",
    minutesShort: "min",
    minutesSymbol: "min",
    secondsLong: "sekunde",
    secondsShort: "s",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtriranje po kateremkoli polju ...",
    filterPlaceholder: "Filtriranje ...",
    noResultsFound: "Ni najdenih rezultatov.",
    requiredField: "Zahtevano polje",
    searchForField: "Poišči {fieldName}",
    searchPlaceholder: "Iščite",
    selectAnOption: "Izberite možnost",
    selectExisting: "Izbira obstoječega...",
    showSelection: "Št. izbranih: {count}",
  },
  labels: {
    addresses: "Naslovi",
    essentials: "Osnove",
    tags: "Oznake",
  },
  listTemplate: {
    learnMore: "Več o tem",
    none: "Brez",
    showing:
      "Prikazani so zapisi od {start} do {end} od toliko zapisov: {total}.",
  },
  notifications: {
    apiErrorMsg:
      "Dodatne podrobnosti iz osnovnega API-ja, ki bodo morda v pomoč: {errMsg}",
    pollingErrorMessage: "Statusa operacije ni bilo mogoče preveriti.",
    pollingErrorTitle: "Ni bilo mogoče preveriti",
  },
  pagination: {
    next: "Naprej",
    of: "od {totalNumberOfPages}",
    page: "Stran",
    previous: "Nazaj",
  },
  status: {
    accepted: "Sprejeto",
    actionRequired: "Zahtevano dejanje",
    active: "Aktivno",
    applied: "Dodeljeno",
    applying: "Dodeljevanje",
    assigned: "Dodeljeno",
    assigning: "Dodeljevanje",
    attached: "Priključeno",
    attaching: "Priključevanje",
    available: "Razpoložljivo",
    availableNeedsAttention: "Na voljo, zahteva pozornost",
    backupInProgress: "Varnostno kopiranje v teku",
    blocked: "Blokirano",
    canceled: "Preklicano",
    canceling: "Preklicevanje",
    cancelled: "Preklicano",
    cancelledReturned: "Preklicano vrnjeno",
    checking: "Preverjanje",
    closed: "Zaprto",
    complete: "Dokončano",
    completed: "Dokončano",
    converting: "Pretvarjanje",
    created: "Ustvarjeno",
    creating: "Ustvarjanje",
    creatingImage: "Ustvarjanje slike",
    deleted: "Izbrisano",
    deleting: "Brisanje",
    detaching: "Izključevanje",
    disabled: "Onemogočeno",
    disabling: "Onemogočanje",
    down: "Zaustavljeno",
    downForMaintenance: "Zaustavljeno zaradi vzdrževanja",
    enabled: "Omogočeno",
    enabling: "Omogočanje",
    error: "Napaka",
    expired: "Poteklo",
    exporting: "Izvažanje",
    failed: "Neuspelo",
    failedApply: "Neuspela dodelitev",
    failedRollback: "Neuspela razveljavitev",
    faulty: "Z napako",
    importing: "Uvažanje",
    inactive: "Neaktivno",
    initiated: "Inicializirano",
    inProgress: "V teku",
    internalError: "Notranja napaka",
    loading: "Nalaganje",
    maintenanceInProgress: "Vzdrževanje v teku",
    missing: "Manjkajoče",
    packaged: "Pakirano",
    pendingCustomer: "Čakajoče stranko",
    pendingProvider: "Čakajoče ponudnika",
    pendingProvisioning: "Čakajoče provizioniranje",
    preparing: "Pripravljanje",
    processed: "Obdelano",
    processing: "Obdelava",
    provisioned: "Provizionirano",
    provisioning: "Provizioniranje",
    queued: "V čakalni vrsti",
    ready: "Pripravljeno",
    received: "Prejeto",
    related: "Sorodno",
    requestReceived: "Zahteva prejeta",
    restoreFailed: "Obnovitev ni uspela",
    restoreInProgress: "Obnovitev v teku",
    restoring: "Obnavljanje",
    returned: "Vrnjeno",
    rollingBack: "Razveljavljanje",
    running: "Izvajanje",
    scaleInProgress: "Prilagajanje v teku",
    shipping: "Odprema",
    starting: "Zaganjanje",
    stopped: "Ustavljeno",
    stopping: "Ustavljanje",
    succeeded: "Uspelo",
    terminated: "Prekinjeno",
    terminating: "Prekinjanje",
    up: "Delujoče",
    unassigned: "Oddeljeno",
    unassigning: "Oddeljevanje",
    unavailable: "Nerazpoložljivo",
    updated: "Posodobljeno",
    updating: "Posodabljanje",
    upgrading: "Nadgrajevanje",
    waiting: "V čakanju",
    warning: "Opozorilo",
  },
  tags: {
    less: "Manj",
    more: "Več ({count})",
  },
  validation: {
    addressInvalid:
      'Neveljaven naslov: "Poškodovan naslov IP ali blok CIDR: {address}".',
    addressOverlap:
      'Neveljaven naslov: "{address1} se prekriva z naslovom {address2}".',
    addressReserved: 'Neveljaven naslov: "{address} je rezerviran naslov".',
    addressReservedRange:
      'Neveljaven naslov: "{address} je znotraj rezerviranega razpona {cidr} ({startingIp} - {endingIp})".',
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      'Neveljaven naslov: "{cidr} vsebuje rezerviran naslov {address}".',
    cidrHostBitsSet:
      'Neveljaven naslov: "CIDR ne more imeti nabora bitov gostitelja".',
    cidrInvalid: 'Neveljaven naslov: "Poškodovan blok CIDR: {cidr}".',
    cidrPrefixInvalid:
      'Neveljavna predpona: "Predpona CIDR mora biti med {min} in {max}, vključno s tema številkama".',
    inputRange:
      "Vrednost mora biti dolga toliko znakov: od {minValue} do {maxValue}.",
    integerMax: "Vrednost je lahko največ {maxValue}.",
    integerMin: "Vrednost je lahko najmanj {minValue}.",
    integerRange: "Vrednost mora biti od {minValue} do {maxValue}.",
    integerValid: "Vrednost mora biti veljavno celo število.",
    ipAddressInvalid: 'Neveljaven naslov: "Poškodovan naslov IP: {address}"',
    maxRows: "Obstaja lahko največ toliko vnosov: {maxRows}.",
    minRows: "Obstajati mora najmanj toliko vnosov: {minRows}.",
    requiredValidation: "Vrednost ne sme biti prazna.",
    radioValidation: "Navedena vrednost ni veljavna možnost",
    timePickerValidation: "Neveljaven format časa.",
    validationFailed:
      "Preverjanje ni uspelo. Zahtevane informacije manjkajo ali niso veljavne.",
    validationFailedWithTabHeader:
      "Preverjanje ni uspelo za naslednji zavihek: {tabHeader}. Zahtevane informacije manjkajo ali niso veljavne.",
    validationFailedWithTabHeaders:
      "Preverjanje ni uspelo za naslednje zavihke: {tabHeaders}. Zahtevane informacije manjkajo ali niso veljavne.",
    validationInProgress: "Preverjanje je v teku",
    validationMaxDate: "Vrednost mora biti {maxDate} ob {maxTime} ali prej.",
    validationMinDate: "Vrednost mora biti {minDate} ob {minTime} ali pozneje.",
    validationMinMaxDateRange:
      "Vrednost mora biti med {minDate} ob {minTime} in {maxDate} ob {maxTime}.",
    validationPassed: "Preverjanje je uspešno opravljeno.",
    validationReservedWord:
      'Vrednost ne sme vsebovati rezervirane besede "{reserved}".',
    validationReservedWords:
      "Vrednost ne sme vsebovati nobene od naslednjih rezerviranih besed: {reserved}.",
    valueMaxLen: "Vrednost je lahko dolga največ toliko znakov: {maxLength}.",
    valueMinLen: "Vrednost mora biti dolga vsaj toliko znakov: {minLength}.",
  },
};
bundled["sr-Latn"] = {
  actions: {
    add: "Dodaj",
    addFilter: "Dodaj filter",
    addTags: "Kliknite ovde da biste dodali oznake",
    apply: "Primeni",
    clearFilters: "Obriši filtere",
    copiedToClipboard: "Kopirano",
    copyToClipboard: "Kopiraj u ostavu",
    create: "Kreiraj",
    delete: "Izbriši",
    edit: "Uredi",
    editTag: "Uredi oznaku",
    next: "Sledeće",
    previous: "Prethodno",
    reviewCreate: "Pregledaj i kreiraj",
    selectAll: "Izaberi sve",
    selectFile: "Izaberite datoteku",
    view: "Prikaži",
  },
  ariaLabel: {
    actionMenu: "Kliknite ovde da biste otvorili konktekstualni meni",
    actionMenuHeader: "Kolona kontekstualnog menija",
    close: "zatvori",
    collapse: "Skupi",
    datePicker: "Izaberite datum",
    delete: "Izbriši",
    dismissNotification: "Odbaci obaveštenje",
    expand: "Raširi",
    fileUpload: "Otpremanje datoteke",
    groupBy: "Grupisanje prema",
    info: "Informacije",
    more: "Više",
    nextMonth: "Sledeći mesec",
    page: "Stranica",
    previousMonth: "Prethodni mesec",
    required: "Obavezno",
    resourceList: "Lista resursa",
    selectItem: "Izaberite stavku",
    sliderInput: "Unos klizača",
    switchView:
      "Prelazite sa prikaza liste na rezimirani prikaz podataka i obratno",
    timePicker: "Izaberite vreme",
  },
  common: {
    addressesCount: "(adrese: {count})",
    all: "Sve",
    cancel: "Otkaži",
    confirm: "Potvrdi",
    contains: "Sadrži",
    discard: "Odbaci",
    equals: "Jednako",
    error: "Greška",
    errorLoadingData: "Greška pri učitavanju podataka",
    filter: "Filter",
    lastViewed: "Poslednje prikazano",
    less: "Manje",
    loading: "Učitava se...",
    more: "Više ({count})",
    name: "Ime",
    no: "Ne",
    ok: "U redu",
    operator: "Operator",
    required: "Obavezno",
    success: "Uspešno",
    tag: "Oznaka",
    tags: "Oznake",
    type: "Tip",
    value: "Vrednost",
    yes: "Da",
  },
  duration: {
    daysLong: "dani",
    daysShort: "d.",
    daysSymbol: "d.",
    hoursLong: "sati",
    hoursShort: "č.",
    hoursSymbol: "č.",
    millisecondsLong: "milisekunde",
    millisecondsShort: "milisek.",
    millisecondsSymbol: "msek.",
    minutesLong: "minuti",
    minutesShort: "min.",
    minutesSymbol: "min.",
    secondsLong: "sekunde",
    secondsShort: "sek.",
    secondsSymbol: "sek.",
  },
  hints: {
    filterAnyField: "Filtrirajte bilo koje polje...",
    filterPlaceholder: "Filtriranje...",
    noResultsFound: "Nije pronađen nijedan rezultat.",
    requiredField: "Obavezno polje",
    searchForField: "Potraži {fieldName}",
    searchPlaceholder: "Pretraga",
    selectAnOption: "Izaberite opciju",
    selectExisting: "Izaberite postojeće...",
    showSelection: "Izabranih: {count}",
  },
  labels: {
    addresses: "Adrese",
    essentials: "Osnovne stavke",
    tags: "Oznake",
  },
  listTemplate: {
    learnMore: "Saznajte više",
    none: "Nijedno",
    showing: "Prikazuje se od {start} do {end} od {total} zapisa.",
  },
  notifications: {
    apiErrorMsg:
      "Dodatni detalji iz osnovnog API-ja koji mogu da budu korisni: {errMsg}",
    pollingErrorMessage: "Provera statusa operacije nije uspela",
    pollingErrorTitle: "Provera nije uspela",
  },
  pagination: {
    next: "Sledeće",
    of: "od {totalNumberOfPages}",
    page: "Stranica",
    previous: "Prethodno",
  },
  status: {
    accepted: "Prihvaćeno",
    actionRequired: "Potrebno je da preduzmete nešto",
    active: "Aktivno",
    applied: "Primenjeno",
    applying: "Primenjuje se",
    assigned: "Dodeljeno",
    assigning: "Dodeljuje se",
    attached: "Priključeno",
    attaching: "Priključuje se",
    available: "Dostupno",
    availableNeedsAttention: "Dostupno - potrebno je obratiti pažnju",
    backupInProgress: "U toku je pravljenje rezervne kopije",
    blocked: "Blokirano",
    canceled: "Otkazano",
    canceling: "Otkazuje se",
    cancelled: "Otkazano",
    cancelledReturned: "Otkazano i vraćeno",
    checking: "Proverava se",
    closed: "Zatvoreno",
    complete: "Završi",
    completed: "Završeno",
    converting: "Konvertovanje",
    created: "Kreirano",
    creating: "Kreira se ",
    creatingImage: "Kreira se slika",
    deleted: "Izbrisano",
    deleting: "Briše se ",
    detaching: "Uklanja se",
    disabled: "Onemogućeno",
    disabling: "Onemogućava se",
    down: "Neaktivno",
    downForMaintenance: "Van funkcije usled održavanja",
    enabled: "Omogućeno",
    enabling: "Omogućavanje",
    error: "Greška",
    expired: "Isteklo",
    exporting: "Izvozi se",
    failed: "Neuspešno",
    failedApply: "Neuspela primena",
    failedRollback: "Neuspelo vraćanje na prethodno",
    faulty: "Neispravno",
    importing: "Uvozi se",
    inactive: "Neaktivno",
    initiated: "Pokrenuto",
    inProgress: "U toku",
    internalError: "Interna greška",
    loading: "Učitava se",
    maintenanceInProgress: "Održavanje je u toku",
    missing: "Nedostaje",
    packaged: "Upakovano",
    pendingCustomer: "Čeka se klijent",
    pendingProvider: "Čeka se dobavljač",
    pendingProvisioning: "Čeka se dodela privilegija",
    preparing: "Priprema se",
    processed: "Obrađeno",
    processing: "Obrađuje se",
    provisioned: "Privilegije su dodeljene",
    provisioning: "Privilegije se dodeljuju",
    queued: "U redosledu",
    ready: "Spremno",
    received: "Primljeno",
    related: "Povezano",
    requestReceived: "Zahtev je primljen",
    restoreFailed: "Vraćanje u prethodno stanje nije uspelo",
    restoreInProgress: "U toku je vraćanje u prethodno stanje",
    restoring: "Vraćanje u prethodno stanje",
    returned: "Vraćeno",
    rollingBack: "Poništavanje",
    running: "Izvršava se",
    scaleInProgress: "U toku je prilagođavanje",
    shipping: "Otprema se",
    starting: "Pokreće se",
    stopped: "Zaustavljeno",
    stopping: "Zaustavlja se",
    succeeded: "Uspešno",
    terminated: "Ukinuto",
    terminating: "Ukida se",
    up: "Aktivno",
    unassigned: "Nedodeljeno",
    unassigning: "Dodela se poništava",
    unavailable: "Nedostupno",
    updated: "Ažurirano",
    updating: "Ažurira se",
    upgrading: "Nadograđuje se",
    waiting: "Na čekanju",
    warning: "Upozorenje",
  },
  tags: {
    less: "Manje",
    more: "Više ({count})",
  },
  validation: {
    addressInvalid:
      "Nevažeća adresa: 'Neispravno oblikovana IP adresa ili CIDR blok: {address}'.",
    addressOverlap: "Nevažeća adresa: '{address1} se preklapa sa {address2}'.",
    addressReserved: "Nevažeća adresa: 'Adresa {address} je rezervisana'.",
    addressReservedRange:
      "Nevažeća adresa: '{address} se nalazi u rezervisanom opsegu za {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Nevažeća adresa: '{cidr} sadrži rezervisanu adresu {address}'.",
    cidrHostBitsSet:
      "Nevažeća adresa: 'CIDR ne može da ima skup bitova hosta'.",
    cidrInvalid: "Nevažeća adresa: 'Neispravno oblikovan CIDR blok: {cidr}'.",
    cidrPrefixInvalid:
      "Nevažeća adresa: 'Prefiks CIDR bloka mora da bude između {min} i {max}, uključujući te vrednosti'.",
    inputRange:
      "Broj znakova koji vrednost sadrži mora da bude između {minValue} i {maxValue}.",
    integerMax: "Vrednost mora da bude najviše {maxValue}.",
    integerMin: "Vrednost mora da bude najmanje {minValue}.",
    integerRange: "Vrednost mora da bude između {minValue} i {maxValue}.",
    integerValid: "Vrednost mora da bude važeći ceo broj.",
    ipAddressInvalid:
      "Nevažeća adresa: 'Neispravno oblikovana IP adresa: {address}'",
    maxRows: "Broj unosa ne sme da bude veći od {maxRows}.",
    minRows: "Broj unosa ne sme da bude manji od {minRows}.",
    requiredValidation: "Vrednost ne sme da bude prazna.",
    radioValidation: "Navedena vrednost nije važeća opcija",
    timePickerValidation: "Format vremena nije važeći.",
    validationFailed:
      "Provera ispravnosti nije uspela. Obavezne informacije nedostaju ili nisu važeće.",
    validationFailedWithTabHeader:
      "Provera ispravnosti nije uspela za sledeću karticu: {tabHeader}. Obavezne informacije nedostaju ili nisu važeće.",
    validationFailedWithTabHeaders:
      "Provera ispravnosti nije uspela za sledeće kartice: {tabHeaders}. Obavezne informacije nedostaju ili nisu važeće.",
    validationInProgress: "Provera ispravnosti je u toku",
    validationMaxDate:
      "Vrednost treba da bude {maxDate}, {maxTime} ili pre toga.",
    validationMinDate:
      "Vrednost treba da bude {minDate}, {minTime} ili nakon toga.",
    validationMinMaxDateRange:
      "Vrednost treba da bude između {minDate}, {minTime} i {maxDate}, {maxTime}.",
    validationPassed: "Provera ispravnosti je uspela.",
    validationReservedWord:
      "Vrednost ne može da sadrži rezervisanu reč '{reserved}'.",
    validationReservedWords:
      "Vrednost ne može da sadrži nijednu od sledećih rezervisanih reči: {reserved}.",
    valueMaxLen:
      "Vrednost ne sme da sadrži više od sledećeg broja znakova: {maxLength}.",
    valueMinLen:
      "Vrednost ne sme da sadrži manje od sledećeg broja znakova: {minLength}.",
  },
};
bundled["sr"] = {
  actions: {
    add: "Додај",
    addFilter: "Додај филтер",
    addTags: "Кликните овде да бисте додали ознаке",
    apply: "Примени",
    clearFilters: "Обриши филтере",
    copiedToClipboard: "Копирано",
    copyToClipboard: "Копирај у оставу",
    create: "Креирај",
    delete: "Избриши",
    edit: "Уреди",
    editTag: "Уреди ознаку",
    next: "Следеће",
    previous: "Претходно",
    reviewCreate: "Прегледај и креирај",
    selectAll: "Изабери све",
    selectFile: "Изаберите датотеку",
    view: "Прикажи",
  },
  ariaLabel: {
    actionMenu: "Кликните овде да бисте отворили конктекстуални мени",
    actionMenuHeader: "Колона контекстуалног менија",
    close: "затвори",
    collapse: "Скупи",
    datePicker: "Изаберите датум",
    delete: "Избриши",
    dismissNotification: "Одбаци обавештење",
    expand: "Рашири",
    fileUpload: "Отпремање датотеке",
    groupBy: "Груписање према",
    info: "Информације",
    more: "Више",
    nextMonth: "Следећи месец",
    page: "Страница",
    previousMonth: "Претходни месец",
    required: "Обавезно",
    resourceList: "Листа ресурса",
    selectItem: "Изаберите ставку",
    sliderInput: "Унос клизача",
    switchView:
      "Прелазите са приказа листе на резимирани приказ података и обратно",
    timePicker: "Изаберите време",
  },
  common: {
    addressesCount: "(адресе: {count})",
    all: "Све",
    cancel: "Откажи",
    confirm: "Потврди",
    contains: "Садржи",
    discard: "Одбаци",
    equals: "Једнако",
    error: "Грешка",
    errorLoadingData: "Грешка при учитавању података",
    filter: "Филтер",
    lastViewed: "Последње приказано",
    less: "Мање",
    loading: "Учитава се...",
    more: "Више ({count})",
    name: "Име",
    no: "Не",
    ok: "У реду",
    operator: "Оператор",
    required: "Обавезно",
    success: "Успешно",
    tag: "Ознака",
    tags: "Ознаке",
    type: "Тип",
    value: "Вредност",
    yes: "Да",
  },
  duration: {
    daysLong: "дани",
    daysShort: "д.",
    daysSymbol: "д.",
    hoursLong: "сати",
    hoursShort: "ч.",
    hoursSymbol: "ч.",
    millisecondsLong: "милисекунде",
    millisecondsShort: "милисек.",
    millisecondsSymbol: "мсек.",
    minutesLong: "минути",
    minutesShort: "мин.",
    minutesSymbol: "мин.",
    secondsLong: "секунде",
    secondsShort: "сек.",
    secondsSymbol: "сек.",
  },
  hints: {
    filterAnyField: "Филтрирајте било које поље...",
    filterPlaceholder: "Филтрирање...",
    noResultsFound: "Није пронађен ниједан резултат.",
    requiredField: "Обавезно поље",
    searchForField: "Потражи {fieldName}",
    searchPlaceholder: "Претрага",
    selectAnOption: "Изаберите опцију",
    selectExisting: "Изаберите постојеће...",
    showSelection: "Изабраних: {count}",
  },
  labels: {
    addresses: "Адресе",
    essentials: "Основне ставке",
    tags: "Ознаке",
  },
  listTemplate: {
    learnMore: "Сазнајте више",
    none: "Ниједно",
    showing: "Приказује се од {start} до {end} од {total} записа.",
  },
  notifications: {
    apiErrorMsg:
      "Додатни детаљи из основног API-ја који могу да буду корисни: {errMsg}",
    pollingErrorMessage: "Провера статуса операције није успела",
    pollingErrorTitle: "Провера није успела",
  },
  pagination: {
    next: "Следеће",
    of: "од {totalNumberOfPages}",
    page: "Страница",
    previous: "Претходно",
  },
  status: {
    accepted: "Прихваћено",
    actionRequired: "Потребно је да предузмете нешто",
    active: "Активно",
    applied: "Примењено",
    applying: "Примењује се",
    assigned: "Додељено",
    assigning: "Додељује се",
    attached: "Прикључено",
    attaching: "Прикључује се",
    available: "Доступно",
    availableNeedsAttention: "Доступно - потребно је обратити пажњу",
    backupInProgress: "У току је прављење резервне копије",
    blocked: "Блокирано",
    canceled: "Отказано",
    canceling: "Отказује се",
    cancelled: "Отказано",
    cancelledReturned: "Отказано и враћено",
    checking: "Проверава се",
    closed: "Затворено",
    complete: "Заврши",
    completed: "Завршено",
    converting: "Конвертовање",
    created: "Креирано",
    creating: "Креира се ",
    creatingImage: "Креира се слика",
    deleted: "Избрисано",
    deleting: "Брише се ",
    detaching: "Уклања се",
    disabled: "Онемогућено",
    disabling: "Онемогућава се",
    down: "Неактивно",
    downForMaintenance: "Ван функције услед одржавања",
    enabled: "Омогућено",
    enabling: "Омогућавање",
    error: "Грешка",
    expired: "Истекло",
    exporting: "Извози се",
    failed: "Неуспешно",
    failedApply: "Неуспела примена",
    failedRollback: "Неуспело враћање на претходно",
    faulty: "Неисправно",
    importing: "Увози се",
    inactive: "Неактивно",
    initiated: "Покренуто",
    inProgress: "У току",
    internalError: "Интерна грешка",
    loading: "Учитава се",
    maintenanceInProgress: "Одржавање је у току",
    missing: "Недостаје",
    packaged: "Упаковано",
    pendingCustomer: "Чека се клијент",
    pendingProvider: "Чека се добављач",
    pendingProvisioning: "Чека се додела привилегија",
    preparing: "Припрема се",
    processed: "Обрађено",
    processing: "Обрађује се",
    provisioned: "Привилегије су додељене",
    provisioning: "Привилегије се додељују",
    queued: "У редоследу",
    ready: "Спремно",
    received: "Примљено",
    related: "Повезано",
    requestReceived: "Захтев је примљен",
    restoreFailed: "Враћање у претходно стање није успело",
    restoreInProgress: "У току је враћање у претходно стање",
    restoring: "Враћање у претходно стање",
    returned: "Враћено",
    rollingBack: "Поништавање",
    running: "Извршава се",
    scaleInProgress: "У току је прилагођавање",
    shipping: "Отпрема се",
    starting: "Покреће се",
    stopped: "Заустављено",
    stopping: "Зауставља се",
    succeeded: "Успешно",
    terminated: "Укинуто",
    terminating: "Укида се",
    up: "Активно",
    unassigned: "Недодељено",
    unassigning: "Додела се поништава",
    unavailable: "Недоступно",
    updated: "Ажурирано",
    updating: "Ажурира се",
    upgrading: "Надограђује се",
    waiting: "На чекању",
    warning: "Упозорење",
  },
  tags: {
    less: "Мање",
    more: "Више ({count})",
  },
  validation: {
    addressInvalid:
      "Неважећа адреса: 'Неисправно обликована IP адреса или CIDR блок: {address}'.",
    addressOverlap: "Неважећа адреса: '{address1} се преклапа са {address2}'.",
    addressReserved: "Неважећа адреса: 'Адреса {address} је резервисана'.",
    addressReservedRange:
      "Неважећа адреса: '{address} се налази у резервисаном опсегу за {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Неважећа адреса: '{cidr} садржи резервисану адресу {address}'.",
    cidrHostBitsSet:
      "Неважећа адреса: 'CIDR не може да има скуп битова хоста'.",
    cidrInvalid: "Неважећа адреса: 'Неисправно обликован CIDR блок: {cidr}'.",
    cidrPrefixInvalid:
      "Неважећа адреса: 'Префикс CIDR блока мора да буде између {min} и {max}, укључујући те вредности'.",
    inputRange:
      "Број знакова који вредност садржи мора да буде између {minValue} и {maxValue}.",
    integerMax: "Вредност мора да буде највише {maxValue}.",
    integerMin: "Вредност мора да буде најмање {minValue}.",
    integerRange: "Вредност мора да буде између {minValue} и {maxValue}.",
    integerValid: "Вредност мора да буде важећи цео број.",
    ipAddressInvalid:
      "Неважећа адреса: 'Неисправно обликована IP адреса: {address}'",
    maxRows: "Број уноса не сме да буде већи од {maxRows}.",
    minRows: "Број уноса не сме да буде мањи од {minRows}.",
    requiredValidation: "Вредност не сме да буде празна.",
    radioValidation: "Наведена вредност није важећа опција",
    timePickerValidation: "Формат времена није важећи.",
    validationFailed:
      "Провера исправности није успела. Обавезне информације недостају или нису важеће.",
    validationFailedWithTabHeader:
      "Провера исправности није успела за следећу картицу: {tabHeader}. Обавезне информације недостају или нису важеће.",
    validationFailedWithTabHeaders:
      "Провера исправности није успела за следеће картице: {tabHeaders}. Обавезне информације недостају или нису важеће.",
    validationInProgress: "Провера исправности је у току",
    validationMaxDate:
      "Вредност треба да буде {maxDate}, {maxTime} или пре тога.",
    validationMinDate:
      "Вредност треба да буде {minDate}, {minTime} или након тога.",
    validationMinMaxDateRange:
      "Вредност треба да буде између {minDate}, {minTime} и {maxDate}, {maxTime}.",
    validationPassed: "Провера исправности је успела.",
    validationReservedWord:
      "Вредност не може да садржи резервисану реч '{reserved}'.",
    validationReservedWords:
      "Вредност не може да садржи ниједну од следећих резервисаних речи: {reserved}.",
    valueMaxLen:
      "Вредност не сме да садржи више од следећег броја знакова: {maxLength}.",
    valueMinLen:
      "Вредност не сме да садржи мање од следећег броја знакова: {minLength}.",
  },
};
bundled["sv"] = {
  actions: {
    add: "Lägg till",
    addFilter: "Lägg till filter",
    addTags: "Klicka här för att lägga till taggar",
    apply: "Tillämpa",
    clearFilters: "Rensa filter",
    copiedToClipboard: "Kopierad",
    copyToClipboard: "Kopiera till urklipp",
    create: "Skapa",
    delete: "Ta bort",
    edit: "Redigera",
    editTag: "Redigera tagg",
    next: "Nästa",
    previous: "Föregående",
    reviewCreate: "Granska och skapa",
    selectAll: "Välj alla",
    selectFile: "Välj en fil",
    view: "Visa",
  },
  ariaLabel: {
    actionMenu: "Klicka för att öppna snabbmenyn",
    actionMenuHeader: "Kolumn för snabbmeny",
    close: "stäng",
    collapse: "Komprimera",
    datePicker: "Välj ett datum",
    delete: "Ta bort",
    dismissNotification: "Avvisa meddelande",
    expand: "Expandera",
    fileUpload: "Ladda upp en fil",
    groupBy: "Gruppera per",
    info: "Information",
    more: "Mer",
    nextMonth: "Nästa månad",
    page: "Sida",
    previousMonth: "Föregående månad",
    required: "Obligatoriskt",
    resourceList: "Resurslista",
    selectItem: "Välj objekt",
    sliderInput: "Indata för skjutreglage",
    switchView: "Växla mellan en listvy och en översiktsvy över data",
    timePicker: "Välj en tidpunkt",
  },
  common: {
    addressesCount: "({count} adresser)",
    all: "Alla",
    cancel: "Avbryt",
    confirm: "Bekräfta",
    contains: "Innehåller",
    discard: "Släng",
    equals: "Är lika med",
    error: "Fel",
    errorLoadingData: "Fel vid laddning av data",
    filter: "Filtrera",
    lastViewed: "Senast visad",
    less: "Mindre",
    loading: "Laddar...",
    more: "Mer ({count})",
    name: "Namn",
    no: "Nej",
    ok: "OK",
    operator: "Operator",
    required: "Obligatoriskt",
    success: "Utförd",
    tag: "Tagg",
    tags: "Taggar",
    type: "Typ",
    value: "Värde",
    yes: "Ja",
  },
  duration: {
    daysLong: "dagar",
    daysShort: "d",
    daysSymbol: "d",
    hoursLong: "timmar",
    hoursShort: "tim",
    hoursSymbol: "t",
    millisecondsLong: "millisekunder",
    millisecondsShort: "msek",
    millisecondsSymbol: "ms",
    minutesLong: "minuter",
    minutesShort: "min",
    minutesSymbol: "m",
    secondsLong: "sekunder",
    secondsShort: "sek",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "Filtrera för valfritt fält...",
    filterPlaceholder: "Filter...",
    noResultsFound: "Inga resultat hittades.",
    requiredField: "Obligatoriskt fält",
    searchForField: "Sök efter {fieldName}",
    searchPlaceholder: "Sök",
    selectAnOption: "Välj ett alternativ",
    selectExisting: "Välj befintlig...",
    showSelection: "{count} valda",
  },
  labels: {
    addresses: "Adresser",
    essentials: "Grunderna",
    tags: "Taggar",
  },
  listTemplate: {
    learnMore: "Lär dig mer",
    none: "Ingen",
    showing: "Visar {start} till {end} av {total} poster.",
  },
  notifications: {
    apiErrorMsg:
      "Ytterligare uppgifter från underliggande API som kan vara till hjälp: {errMsg}",
    pollingErrorMessage: "Kunde inte kontrollera åtgärdsstatus.",
    pollingErrorTitle: "Kunde inte kontrollera",
  },
  pagination: {
    next: "Nästa",
    of: "av {totalNumberOfPages}",
    page: "Sida",
    previous: "Föregående",
  },
  status: {
    accepted: "Accepterad",
    actionRequired: "Åtgärd krävs",
    active: "Aktiv",
    applied: "Tillämpad",
    applying: "Tillämpar",
    assigned: "Tilldelad",
    assigning: "Tilldelar",
    attached: "Kopplad",
    attaching: "Kopplar",
    available: "Tillgänglig",
    availableNeedsAttention: "Tillgänglig, kräver åtgärd",
    backupInProgress: "Säkerhetskopiering pågår",
    blocked: "Blockerad",
    canceled: "Avbruten",
    canceling: "Avbryter",
    cancelled: "Annullerad",
    cancelledReturned: "Annullerad returnerad",
    checking: "Kontrollerar",
    closed: "Stängd",
    complete: "Slutför",
    completed: "Slutförd",
    converting: "Konverterar",
    created: "Skapad",
    creating: "Skapar",
    creatingImage: "Skapar avbild",
    deleted: "Borttagen",
    deleting: "Tar bort",
    detaching: "Kopplar från",
    disabled: "Inaktiv",
    disabling: "Avaktiverar",
    down: "Nere",
    downForMaintenance: "Nere pga. underhåll",
    enabled: "Aktiv",
    enabling: "Aktiverar",
    error: "Fel",
    expired: "Upphörd",
    exporting: "Exporterar",
    failed: "Ej utförd",
    failedApply: "Kunde inte tillämpas",
    failedRollback: "Kunde inte återställas",
    faulty: "Felaktig",
    importing: "Importerar",
    inactive: "Inaktiv",
    initiated: "Initierad",
    inProgress: "Pågår",
    internalError: "Internt fel",
    loading: "Laddar",
    maintenanceInProgress: "Underhåll pågår",
    missing: "Saknas",
    packaged: "Paketerad",
    pendingCustomer: "Avvaktande kund",
    pendingProvider: "Avvaktande leverantör",
    pendingProvisioning: "Avvaktande behörighetstilldelning",
    preparing: "Förbereder",
    processed: "Bearbetad",
    processing: "Bearbetar",
    provisioned: "Tilldelad",
    provisioning: "Tilldelar",
    queued: "Köplacerad",
    ready: "Klar",
    received: "Mottagen",
    related: "Relaterad",
    requestReceived: "Begäran har tagits emot",
    restoreFailed: "Kunde inte återställa",
    restoreInProgress: "Återställning pågår",
    restoring: "Återställer",
    returned: "Returnerad",
    rollingBack: "Återställs",
    running: "Körs",
    scaleInProgress: "Skaländring pågår",
    shipping: "Skickar",
    starting: "Startar",
    stopped: "Stoppad",
    stopping: "Stoppar",
    succeeded: "Har utförts",
    terminated: "Avslutad",
    terminating: "Avslutar",
    up: "Aktiv",
    unassigned: "Ej tilldelad",
    unassigning: "Tar bort tilldelning",
    unavailable: "Ej tillgänglig",
    updated: "Uppdaterad",
    updating: "Uppdaterar",
    upgrading: "Uppgraderar",
    waiting: "Väntar",
    warning: "Varning",
  },
  tags: {
    less: "Mindre",
    more: "Mer ({count})",
  },
  validation: {
    addressInvalid:
      "Ogiltig adress: IP-adress eller CIDR-block har felaktig utformning: {address}.",
    addressOverlap: "Ogiltig adress: {address1} överlappar {address2}.",
    addressReserved: "Ogiltig adress: {address} är reserverad.",
    addressReservedRange:
      "Ogiltig adress: {address} är inom det reserverade intervallet {cidr} ({startingIp} - {endingIp}).",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Ogiltig adress: {cidr} innehåller den reserverade adressen {address}.",
    cidrHostBitsSet: "Ogiltig adress: CIDR får inte ha värddelarna inställda.",
    cidrInvalid: "Ogiltig adress: felaktigt utformat CIDR-block: {cidr}.",
    cidrPrefixInvalid:
      "Ogiltig adress: CIDR-prefix måste vara minst {min} och maximalt {max}.",
    inputRange:
      "Värdet måste vara mellan {minValue} och {maxValue} tecken långt.",
    integerMax: "Värdet får vara högst {maxValue}.",
    integerMin: "Värdet måste vara minst {minValue}.",
    integerRange: "Värdet måste vara mellan {minValue} och {maxValue}.",
    integerValid: "Värdet måste vara ett giltigt heltal.",
    ipAddressInvalid: "Ogiltig adress: felaktigt utformad IP-adress: {address}",
    maxRows: "Det får inte finnas fler än {maxRows} poster.",
    minRows: "Det måste finnas minst {minRows} poster.",
    requiredValidation: "Värdet får inte vara tomt.",
    radioValidation: "Det angivna värdet är inte ett giltigt alternativ.",
    timePickerValidation: "Ogiltigt tidsformat.",
    validationFailed:
      "Kunde inte utföra valideringen. Obligatorisk information saknas eller är ogiltig.",
    validationFailedWithTabHeader:
      "Validering utfördes inte för följande flik: {tabHeader}. Obligatorisk information saknas eller är ogiltig.",
    validationFailedWithTabHeaders:
      "Validering utfördes inte för följande flikar: {tabHeaders}. Obligatorisk information saknas eller är ogiltig.",
    validationInProgress: "Validering pågår",
    validationMaxDate:
      "Datumvärdet ska infalla {maxDate}, {maxTime} eller före.",
    validationMinDate:
      "Datumvärdet ska infalla {minDate}, {minTime} eller efter.",
    validationMinMaxDateRange:
      "Datumvärdet ska infalla mellan {minDate}, {minTime} och {maxDate}, {maxTime}.",
    validationPassed: "Valideringen har slutförts.",
    validationReservedWord:
      "Värdet får inte innehålla det reserverade ordet '{reserved}'",
    validationReservedWords:
      "Värdet får inte innehålla något av följande reserverade ord: {reserved}.",
    valueMaxLen: "Värdet får vara högst {maxLength} tecken långt.",
    valueMinLen: "Värdet måste innehålla minst {minLength} tecken.",
  },
};
bundled["th"] = {
  actions: {
    add: "เพิ่ม",
    addFilter: "เพิ่มฟิลเตอร์",
    addTags: "คลิกที่นี่เพื่อเพิ่มแท็ก",
    apply: "ใช้",
    clearFilters: "ล้างฟิลเตอร์",
    copiedToClipboard: "คัดลอกแล้ว",
    copyToClipboard: "คัดลอกไปยังคลิปบอร์ด",
    create: "สร้าง",
    delete: "ลบ",
    edit: "แก้ไข",
    editTag: "แก้ไขแท็ก",
    next: "ถัดไป",
    previous: "ก่อนหน้า",
    reviewCreate: "ตรวจดู + สร้าง",
    selectAll: "เลือกทั้งหมด",
    selectFile: "เลือกไฟล์",
    view: "ดู",
  },
  ariaLabel: {
    actionMenu: "คลิกเพื่อเปิดเมนูคอนเท็กซ์",
    actionMenuHeader: "คอลัมน์เมนูคอนเท็กซ์",
    close: "ปิด",
    collapse: "ยุบ",
    datePicker: "เลือกวันที่",
    delete: "ลบ",
    dismissNotification: "ปิดการแจ้ง",
    expand: "ขยาย",
    fileUpload: "อัปโหลดไฟล์",
    groupBy: "จัดกลุ่มตาม",
    info: "ข้อมูล",
    more: "เพิ่มเติม",
    nextMonth: "เดือนถัดไป",
    page: "เพจ",
    previousMonth: "เดือนก่อนหน้า",
    required: "ต้องระบุ",
    resourceList: "ลิสต์ทรัพยากร",
    selectItem: "เลือกรายการ",
    sliderInput: "อินพุตตัวเลื่อน",
    switchView: "สลับระหว่างวิวแบบลิสต์หรือวิวสรุปข้อมูล",
    timePicker: "เลือกเวลา",
  },
  common: {
    addressesCount: "({count} แอดเดรส)",
    all: "ทั้งหมด",
    cancel: "ยกเลิก",
    confirm: "ยืนยัน",
    contains: "มี",
    discard: "ยกเลิก",
    equals: "เท่ากับ",
    error: "ข้อผิดพลาด",
    errorLoadingData: "เกิดข้อผิดพลาดขณะโหลดข้อมูล",
    filter: "ฟิลเตอร์",
    lastViewed: "ดูครั้งล่าสุด",
    less: "น้อยลง",
    loading: "กำลังโหลด...",
    more: "เพิ่มเติม ({count})",
    name: "ชื่อ",
    no: "ไม่",
    ok: "ตกลง",
    operator: "โอเปอเรเตอร์",
    required: "ต้องระบุ",
    success: "ความสำเร็จ",
    tag: "แท็ก",
    tags: "แท็ก",
    type: "ประเภท",
    value: "ค่า",
    yes: "ใช่",
  },
  duration: {
    daysLong: "วัน",
    daysShort: "วัน",
    daysSymbol: "วัน",
    hoursLong: "ชั่วโมง",
    hoursShort: "ชม.",
    hoursSymbol: "ชม.",
    millisecondsLong: "มิลลิวินาที",
    millisecondsShort: "มิลลิวินาที",
    millisecondsSymbol: "มิลลิวินาที",
    minutesLong: "นาที",
    minutesShort: "นาที",
    minutesSymbol: "นาที",
    secondsLong: "วินาที",
    secondsShort: "วินาที",
    secondsSymbol: "วินาที",
  },
  hints: {
    filterAnyField: "ฟิลเตอร์สำหรับฟิลด์ใดๆ...",
    filterPlaceholder: "ฟิลเตอร์...",
    noResultsFound: "ไม่พบผลลัพธ์",
    requiredField: "ฟิลด์ที่จำเป็น",
    searchForField: "ค้นหา {fieldName}",
    searchPlaceholder: "ค้นหา",
    selectAnOption: "เลือกตัวเลือก",
    selectExisting: "เลือกรายการที่มีอยู่...",
    showSelection: "เลือกไว้ {count} รายการ",
  },
  labels: {
    addresses: "แอดเดรส",
    essentials: "จำเป็น",
    tags: "แท็ก",
  },
  listTemplate: {
    learnMore: "เรียนรู้เพิ่มเติม",
    none: "ไม่มี",
    showing: "กำลังแสดงเรคคอร์ด {start} ถึง {end} จาก {total} รายการ",
  },
  notifications: {
    apiErrorMsg:
      "รายละเอียดเพิ่มเติมจาก API ที่ใช้งานอยู่ ซึ่งอาจมีประโยชน์มาก: {errMsg}",
    pollingErrorMessage: "ไม่สามารถตรวจสอบสถานะการดำเนินการ",
    pollingErrorTitle: "ไม่สามารถตรวจสอบ",
  },
  pagination: {
    next: "ถัดไป",
    of: "จาก {totalNumberOfPages}",
    page: "เพจ",
    previous: "ก่อนหน้า",
  },
  status: {
    accepted: "ยอมรับ",
    actionRequired: "ต้องมีการดำเนินการ",
    active: "ใช้งานอยู่",
    applied: "ใช้แล้ว",
    applying: "กำลังใช้",
    assigned: "ระบุแล้ว",
    assigning: "กำลังระบุ",
    attached: "เชื่อมโยงแล้ว",
    attaching: "กำลังเชื่อมโยง",
    available: "ใช้ได้",
    availableNeedsAttention: "รายการที่ใช้ได้ต้องมีการดำเนินการ",
    backupInProgress: "การสำรองข้อมูลอยู่ระหว่างดำเนินการ",
    blocked: "บล็อคแล้ว",
    canceled: "ยกเลิกแล้ว",
    canceling: "กำลังยกเลิก",
    cancelled: "ยกเลิกแล้ว",
    cancelledReturned: "ยกเลิกและส่งคืนแล้ว",
    checking: "กำลังตรวจสอบ",
    closed: "ปิดแล้ว",
    complete: "เสร็จสมบูรณ์",
    completed: "เสร็จสมบูรณ์แล้ว",
    converting: "กำลังแปลงค่า",
    created: "สร้างแล้ว",
    creating: "กำลังสร้าง",
    creatingImage: "กำลังสร้างอิมเมจ",
    deleted: "ลบแล้ว",
    deleting: "กำลังลบ",
    detaching: "กำลังตัดการเชื่อมโยง",
    disabled: "เลิกใช้แล้ว",
    disabling: "กำลังเลิกใช้",
    down: "หยุดทำงาน",
    downForMaintenance: "หยุดทำงานเพื่อบำรุงรักษา",
    enabled: "ใช้งานแล้ว",
    enabling: "กำลังใช้งาน",
    error: "ข้อผิดพลาด",
    expired: "หมดอายุแล้ว",
    exporting: "กำลังเอ็กซ์ปอร์ต",
    failed: "ล้มเหลว",
    failedApply: "ใช้ไม่สำเร็จ",
    failedRollback: "โรลแบ็คไม่สำเร็จ",
    faulty: "ข้อผิดพลาด",
    importing: "กำลังอิมปอร์ต",
    inactive: "ไม่ใช้งาน",
    initiated: "เริ่มต้นแล้ว",
    inProgress: "อยู่ระหว่างดำเนินการ",
    internalError: "ข้อผิดพลาดภายใน",
    loading: "กำลังโหลด",
    maintenanceInProgress: "การบำรุงรักษาอยู่ระหว่างดำเนินการ",
    missing: "ไม่มี",
    packaged: "บรรจุแล้ว",
    pendingCustomer: "ลูกค้าที่รอดำเนินการ",
    pendingProvider: "ผู้ให้บริการที่รอดำเนินการ",
    pendingProvisioning: "การจัดเตรียมที่รอดำเนินการ",
    preparing: "กำลังจัดเตรียม",
    processed: "ประมวลผลแล้ว",
    processing: "กำลังประมวลผล",
    provisioned: "จัดเตรียมแล้ว",
    provisioning: "กำลังจัดเตรียม",
    queued: "อยู่ในคิว",
    ready: "พร้อมทำงาน",
    received: "ได้รับแล้ว",
    related: "เกี่ยวข้อง",
    requestReceived: "ได้รับคำขอแล้ว",
    restoreFailed: "การเรียกคืนล้มเหลว",
    restoreInProgress: "การเรียกคืนอยู่ระหว่างดำเนินการ",
    restoring: "กำลังเรียกคืน",
    returned: "ส่งคืนแล้ว",
    rollingBack: "กำลังโรลแบ็ค",
    running: "กำลังรัน",
    scaleInProgress: "การปรับสเกลอยู่ระหว่างดำเนินการ",
    shipping: "กำลังส่งสินค้า",
    starting: "กำลังเริ่มต้น",
    stopped: "หยุดแล้ว",
    stopping: "กำลังหยุด",
    succeeded: "สำเร็จแล้ว",
    terminated: "ยกเลิกแล้ว",
    terminating: "กำลังยกเลิก",
    up: "ทำงาน",
    unassigned: "ไม่ได้ระบุ",
    unassigning: "กำลังยกเลิกการระบุ",
    unavailable: "ใช้ไม่ได้",
    updated: "อัปเดตแล้ว",
    updating: "กำลังอัปเดต",
    upgrading: "กำลังอัปเกรด",
    waiting: "กำลังรอ",
    warning: "คำเตือน",
  },
  tags: {
    less: "น้อยลง",
    more: "เพิ่มเติม ({count})",
  },
  validation: {
    addressInvalid:
      "แอดเดรสไม่ถูกต้อง: 'IP แอดเดรสหรือบล็อค CIDR มีรูปแบบไม่ถูกต้อง: {address}'",
    addressOverlap: "แอดเดรสไม่ถูกต้อง: '{address1} ทับซ้อน {address2}'",
    addressReserved: "แอดเดรสไม่ถูกต้อง: '{address} ถูกสำรองไว้'",
    addressReservedRange:
      "แอดเดรสไม่ถูกต้อง: '{address} อยู่ภายในช่วงที่สำรองไว้ {cidr} ({startingIp} - {endingIp})'",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "แอดเดรสไม่ถูกต้อง: '{cidr}' มีแอดเดรสที่สำรองไว้ {address}'",
    cidrHostBitsSet: "แอดเดรสไม่ถูกต้อง: 'CIDR ต้องไม่มีชุดบิตของโฮสต์'",
    cidrInvalid: "แอดเดรสไม่ถูกต้อง: 'บล็อค CIDR มีรูปแบบไม่ถูกต้อง: {cidr}'",
    cidrPrefixInvalid:
      "แอดเดรสไม่ถูกต้อง: 'คำนำหน้า CIDR ต้องอยู่ระหว่าง {min} ถึง {max} ตัว แบบรวม'",
    inputRange: "ค่าต้องมีความยาวระหว่าง {minValue} ถึง {maxValue} อักขระ",
    integerMax: "ค่าต้องไม่เกิน {maxValue}",
    integerMin: "ค่าต้องมีอย่างน้อย {minValue}",
    integerRange: "ค่าต้องอยู่ระหว่าง {minValue} ถึง {maxValue}",
    integerValid: "ค่าต้องเป็นจำนวนเต็มที่ถูกต้อง",
    ipAddressInvalid:
      "แอดเดรสไม่ถูกต้อง: 'IP แอดเดรสมีรูปแบบไม่ถูกต้อง: {address}'",
    maxRows: "ต้องมีรายการสูงสุดไม่เกิน {maxRows} รายการ",
    minRows: "ต้องมีรายการอย่างน้อย {minRows} รายการ",
    requiredValidation: "ค่าต้องไม่ว่างเปล่า",
    radioValidation: "ค่าที่ระบุไม่ใช่ตัวเลือกที่ถูกต้อง",
    timePickerValidation: "รูปแบบเวลาไม่ถูกต้อง",
    validationFailed:
      "การตรวจสอบล้มเหลว ไม่มีข้อมูลที่จำเป็น หรือมีแต่ไม่ถูกต้อง",
    validationFailedWithTabHeader:
      "การตรวจสอบล้มเหลวสำหรับแท็บต่อไปนี้: {tabHeader} ไม่มีข้อมูลที่จำเป็น หรือมีแต่ไม่ถูกต้อง",
    validationFailedWithTabHeaders:
      "การตรวจสอบล้มเหลวสำหรับแท็บต่อไปนี้: {tabHeaders} ไม่มีข้อมูลที่จำเป็น หรือมีแต่ไม่ถูกต้อง",
    validationInProgress: "กำลังดำเนินการตรวจสอบ",
    validationMaxDate: "ค่าควรเป็น {maxDate}, {maxTime} หรือก่อนหน้านี้",
    validationMinDate: "ค่าควรเป็น {minDate}, {minTime} หรือหลังจากนี้",
    validationMinMaxDateRange:
      "ค่าควรอยู่ระหว่าง {minDate}, {minTime} ถึง {maxDate}, {maxTime}",
    validationPassed: "ผ่านการตรวจสอบ",
    validationReservedWord: "ค่าต้องไม่มีคำสงวน '{reserved}'",
    validationReservedWords: "ค่าต้องไม่มีคำสงวนใดๆ ต่อไปนี้: {reserved}",
    valueMaxLen: "ค่าต้องมีความยาวอักขระมากที่สุด {maxLength} ตัว",
    valueMinLen: "ค่าต้องมีความยาวอย่างน้อย {minLength} อักขระ",
  },
};
bundled["tr"] = {
  actions: {
    add: "Ekle",
    addFilter: "Filtre ekle",
    addTags: "Etiket eklemek için buraya tıklayın",
    apply: "Uygula",
    clearFilters: "Filtreleri temizle",
    copiedToClipboard: "Kopyalandı",
    copyToClipboard: "Panoya kopyala",
    create: "Oluştur",
    delete: "Sil",
    edit: "Düzenle",
    editTag: "Etiketi düzenle",
    next: "Sonraki",
    previous: "Önceki",
    reviewCreate: "Gözden geçir + oluştur",
    selectAll: "Tümünü seç",
    selectFile: "Bir dosya seçin",
    view: "Görüntüle",
  },
  ariaLabel: {
    actionMenu: "Bağlam menüsünü açmak için tıklayın",
    actionMenuHeader: "Bağlam menüsü sütunu",
    close: "kapat",
    collapse: "Daralt",
    datePicker: "Tarih seçin",
    delete: "Sil",
    dismissNotification: "Bildirimi Yok Say",
    expand: "Genişlet",
    fileUpload: "Dosyayı karşıya yükle",
    groupBy: "Gruplama ölçütü",
    info: "Bilgi",
    more: "Diğer",
    nextMonth: "Sonraki ay",
    page: "Sayfa",
    previousMonth: "Önceki ay",
    required: "Gerekli",
    resourceList: "Kaynak listesi",
    selectItem: "Öğe seç",
    sliderInput: "Kaydırıcı girdisi",
    switchView: "Liste görünümü veya veri özet görünümü arasında geçiş yapın.",
    timePicker: "Zaman seç",
  },
  common: {
    addressesCount: "({count} adres)",
    all: "Tümü",
    cancel: "İptal",
    confirm: "Teyit et",
    contains: "İçerir",
    discard: "At",
    equals: "Eşittir",
    error: "Hata",
    errorLoadingData: "Veri yüklenirken hata oluştu",
    filter: "Filtre",
    lastViewed: "Son Görüntüleme",
    less: "Daha Az",
    loading: "Yükleniyor...",
    more: "Daha Fazla ({count})",
    name: "Ad",
    no: "Hayır",
    ok: "Tamam",
    operator: "İşleç",
    required: "Gerekli",
    success: "Başarılı",
    tag: "Etiket",
    tags: "Etiketler",
    type: "Tip",
    value: "Değer",
    yes: "Evet",
  },
  duration: {
    daysLong: "gün",
    daysShort: "g",
    daysSymbol: "g",
    hoursLong: "saat",
    hoursShort: "sa",
    hoursSymbol: "s",
    millisecondsLong: "milisaniye",
    millisecondsShort: "msn",
    millisecondsSymbol: "ms",
    minutesLong: "dakika",
    minutesShort: "min",
    minutesSymbol: "d",
    secondsLong: "saniye",
    secondsShort: "san",
    secondsSymbol: "sn",
  },
  hints: {
    filterAnyField: "Herhangi bir alanı filtreleyin...",
    filterPlaceholder: "Filtrele...",
    noResultsFound: "Sonuç bulunamadı.",
    requiredField: "Gerekli alan",
    searchForField: "{fieldName} ile ilgili arama",
    searchPlaceholder: "Ara",
    selectAnOption: "Bir seçenek belirleyin",
    selectExisting: "Var olanı seç...",
    showSelection: "{count} seçili",
  },
  labels: {
    addresses: "Adresler",
    essentials: "Temel Bileşenler",
    tags: "Etiketler",
  },
  listTemplate: {
    learnMore: "Daha fazla bilgi edinin",
    none: "Hiçbiri",
    showing: "{start} - {end}/{total} kayıt gösteriliyor.",
  },
  notifications: {
    apiErrorMsg:
      "Altta yatan uygulama programı arabiriminden faydalı olabilecek ek detaylar: {errMsg}",
    pollingErrorMessage: "İşlem statüsü kontrol edilemedi.",
    pollingErrorTitle: "Kontrol edilemedi",
  },
  pagination: {
    next: "Sonraki",
    of: "/{totalNumberOfPages}",
    page: "Sayfa",
    previous: "Önceki",
  },
  status: {
    accepted: "Kabul Edildi",
    actionRequired: "Eylem gerekli",
    active: "Etkin",
    applied: "Uygulandı",
    applying: "Uygulanıyor",
    assigned: "Atandı",
    assigning: "Atanıyor",
    attached: "Eklendi",
    attaching: "Ekleniyor",
    available: "Erişilebilir",
    availableNeedsAttention: "Erişilebilir müdahale gerekiyor",
    backupInProgress: "Yedekleme devam ediyor",
    blocked: "Engellendi",
    canceled: "İptal Edildi",
    canceling: "İptal Ediliyor",
    cancelled: "İptal edildi",
    cancelledReturned: "İptal edildi döndürüldü",
    checking: "Kontrol Ediliyor",
    closed: "Kapalı",
    complete: "Tamamlandı",
    completed: "Tamamlandı",
    converting: "Dönüştürülüyor",
    created: "Oluşturuldu",
    creating: "Oluşturuluyor",
    creatingImage: "İmaj oluşturuluyor",
    deleted: "Silindi",
    deleting: "Siliniyor",
    detaching: "Ayrılıyor",
    disabled: "Devre Dışı Bırakılmış",
    disabling: "Devre Dışı Bırakılıyor",
    down: "Kapalı",
    downForMaintenance: "Bakım için kapalı",
    enabled: "Etkin",
    enabling: "Etkinleştiriliyor",
    error: "Hata",
    expired: "Geçerliliği Sona Erdi",
    exporting: "Dışa Aktarılıyor",
    failed: "Başarısız",
    failedApply: "Uygulanamadı",
    failedRollback: "Geri alınamadı",
    faulty: "Arızalı",
    importing: "İçe Aktarılıyor",
    inactive: "Etkin Değil",
    initiated: "Başlatıldı",
    inProgress: "Devam Ediyor",
    internalError: "Dahili hata",
    loading: "Yükleniyor",
    maintenanceInProgress: "Bakım devam ediyor",
    missing: "Eksik",
    packaged: "Paketlendi",
    pendingCustomer: "Müşteri bekleniyor",
    pendingProvider: "Sağlayıcı bekleniyor",
    pendingProvisioning: "Yetki sağlama bekleniyor",
    preparing: "Hazırlanıyor",
    processed: "İşlendi",
    processing: "İşleniyor",
    provisioned: "Yetki Sağlandı",
    provisioning: "Yetki Sağlanıyor",
    queued: "Kuyruğa Alındı",
    ready: "Hazır",
    received: "Alındı",
    related: "İlgili",
    requestReceived: "İstek alındı",
    restoreFailed: "Geri yüklenemedi",
    restoreInProgress: "Geri yükleme devam ediyor",
    restoring: "Geri yükleniyor",
    returned: "Geri döndürüldü",
    rollingBack: "Geri alınıyor",
    running: "Çalışıyor",
    scaleInProgress: "Ölçeklendirme devam ediyor",
    shipping: "Sevk Ediliyor",
    starting: "Başlatılıyor",
    stopped: "Durduruldu",
    stopping: "Durduruluyor",
    succeeded: "Başarılı",
    terminated: "Sonlandırıldı",
    terminating: "Sonlandırılıyor",
    up: "Çalışıyor",
    unassigned: "Atanmadı",
    unassigning: "Atama kaldırılıyor",
    unavailable: "Erişilebilir",
    updated: "Güncellendi",
    updating: "Güncelleniyor",
    upgrading: "Yükseltiliyor",
    waiting: "Bekleniyor",
    warning: "Uyarı",
  },
  tags: {
    less: "Daha Az",
    more: "Daha Fazla ({count})",
  },
  validation: {
    addressInvalid:
      "Geçersiz adres: 'Yanlış biçimlendirilmiş IP adresi veya CIDR bloğu: {address}'.",
    addressOverlap: "Geçersiz adres: '{address1} {address2} ile çakışıyor'.",
    addressReserved: "Geçersiz adres: '{address} ayrılmış'.",
    addressReservedRange:
      "Geçersiz adres '{address}, ayrılmış {cidr} ({startingIp} - {endingIp}) aralığı içinde'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp:
      "Geçersiz adres: '{cidr}, ayrılmış {address} adresini içeriyor'.",
    cidrHostBitsSet:
      "Geçersiz adres: 'CIDR ana bilgisayar bitleri kümesine sahip olamaz'.",
    cidrInvalid:
      "Geçersiz adres: 'Yanlış biçimlendirilmiş CIDR bloğu: {cidr}'.",
    cidrPrefixInvalid:
      "Geçersiz adres: 'CIDR öneki {min} ve {max} (bu değerler dahil) arasında olmalıdır'.",
    inputRange:
      "Değerin uzunluğu {minValue} ile {maxValue} karakter arasında olmalıdır.",
    integerMax: "Değer en fazla {maxValue} olmalıdır.",
    integerMin: "Değer en az {minValue} olmalıdır.",
    integerRange: "Değer {minValue} ile {maxValue} arasında olmalıdır.",
    integerValid: "Değer geçerli bir tamsayı olmalıdır.",
    ipAddressInvalid:
      "Geçersiz adres: 'Yanlış biçimlendirilmiş IP adresi: {address}'",
    maxRows: "En fazla {maxRows} giriş olmalıdır.",
    minRows: "En az {minRows} giriş olmalıdır.",
    requiredValidation: "Değer boş olmamalıdır.",
    radioValidation: "Sağlanan değer geçerli bir seçenek değil",
    timePickerValidation: "Geçersiz saat formatı.",
    validationFailed:
      "Doğrulanamadı. Gerekli bilgiler eksik veya geçerli değil.",
    validationFailedWithTabHeader:
      "Şu sekme için doğrulama başarısız oldu: {tabHeader}. Gerekli bilgiler eksik veya geçerli değil.",
    validationFailedWithTabHeaders:
      "Şu sekmeler için doğrulama başarısız oldu: {tabHeaders}. Gerekli bilgiler eksik veya geçersiz.",
    validationInProgress: "Doğrulama devam ediyor",
    validationMaxDate: "Değer {maxDate}, {maxTime} veya öncesi olmalıdır.",
    validationMinDate: "Değer {minDate}, {minTime} veya sonrası olmalıdır.",
    validationMinMaxDateRange:
      "Değer {minDate}, {minTime} ve{maxDate}, {maxTime}. arasında olmalıdır.",
    validationPassed: "Doğrulama başarılı.",
    validationReservedWord:
      "Değer, '{reserved}' özel amaçlı sözcüğünü içeremez.",
    validationReservedWords:
      "Değer, şu özel amaçlı sözcüklerden birini içeremez: {reserved}.",
    valueMaxLen: "Değer en fazla {maxLength} karakter uzunluğunda olmalıdır.",
    valueMinLen: "Değer en az {minLength} karakter uzunluğunda olmalıdır.",
  },
};
bundled["zh-Hans"] = {
  actions: {
    add: "添加",
    addFilter: "添加筛选器",
    addTags: "单击此处以添加标记",
    apply: "应用",
    clearFilters: "清除筛选器",
    copiedToClipboard: "已复制",
    copyToClipboard: "复制到剪贴板",
    create: "创建",
    delete: "删除",
    edit: "编辑",
    editTag: "编辑标记",
    next: "下一步",
    previous: "上一步",
    reviewCreate: "复查 + 创建",
    selectAll: "全选",
    selectFile: "选择文件",
    view: "查看",
  },
  ariaLabel: {
    actionMenu: "单击以打开上下文菜单",
    actionMenuHeader: "上下文菜单列",
    close: "关闭",
    collapse: "折叠",
    datePicker: "选择日期",
    delete: "删除",
    dismissNotification: "关闭通知",
    expand: "展开",
    fileUpload: "上载文件",
    groupBy: "分组依据",
    info: "信息",
    more: "更多",
    nextMonth: "下个月",
    page: "页",
    previousMonth: "上个月",
    required: "必需",
    resourceList: "资源列表",
    selectItem: "选择项",
    sliderInput: "滑块输入",
    switchView: "在数据列表视图与数据概要视图之间切换",
    timePicker: "选择时间",
  },
  common: {
    addressesCount: "（{count} 个地址）",
    all: "全部",
    cancel: "取消",
    confirm: "确认",
    contains: "包含",
    discard: "放弃",
    equals: "等于",
    error: "错误",
    errorLoadingData: "加载数据时出错",
    filter: "筛选器",
    lastViewed: "上次查看时间",
    less: "更少",
    loading: "正在加载...",
    more: "更多 ({count})",
    name: "名称",
    no: "否",
    ok: "确定",
    operator: "运算符",
    required: "必需",
    success: "成功",
    tag: "标记",
    tags: "标记",
    type: "类型",
    value: "值",
    yes: "是",
  },
  duration: {
    daysLong: "天",
    daysShort: "天",
    daysSymbol: "d",
    hoursLong: "小时",
    hoursShort: "小时",
    hoursSymbol: "h",
    millisecondsLong: "毫秒",
    millisecondsShort: "毫秒",
    millisecondsSymbol: "ms",
    minutesLong: "分钟",
    minutesShort: "分钟",
    minutesSymbol: "m",
    secondsLong: "秒",
    secondsShort: "秒",
    secondsSymbol: "s",
  },
  hints: {
    filterAnyField: "筛选任何字段...",
    filterPlaceholder: "筛选...",
    noResultsFound: "未找到任何结果。",
    requiredField: "必填字段",
    searchForField: "搜索 {fieldName}",
    searchPlaceholder: "搜索",
    selectAnOption: "选择选项",
    selectExisting: "选择现有项...",
    showSelection: "已选择 {count} 个",
  },
  labels: {
    addresses: "地址",
    essentials: "基础信息",
    tags: "标记",
  },
  listTemplate: {
    learnMore: "了解更多信息",
    none: "无",
    showing: "显示 {start} 到 {end} 条记录（共 {total} 条记录）。",
  },
  notifications: {
    apiErrorMsg: "来自底层 API 的其他可能有用的详细信息：{errMsg}",
    pollingErrorMessage: "无法检查操作状态。",
    pollingErrorTitle: "无法检查",
  },
  pagination: {
    next: "下一页",
    of: "，共 {totalNumberOfPages} 页",
    page: "页",
    previous: "上一页",
  },
  status: {
    accepted: "已接受",
    actionRequired: "需要采取措施",
    active: "活动",
    applied: "已应用",
    applying: "正在应用",
    assigned: "已分配",
    assigning: "正在分配",
    attached: "已附加",
    attaching: "正在附加",
    available: "可用",
    availableNeedsAttention: "可用，但需要注意",
    backupInProgress: "正在备份",
    blocked: "已阻止",
    canceled: "已取消",
    canceling: "正在取消",
    cancelled: "已取消",
    cancelledReturned: "已返回状态“已取消”",
    checking: "正在检查",
    closed: "已关闭",
    complete: "完成",
    completed: "已完成",
    converting: "正在转换",
    created: "已创建",
    creating: "正在创建",
    creatingImage: "正在创建映像",
    deleted: "已删除",
    deleting: "正在删除",
    detaching: "正在分离",
    disabled: "已禁用",
    disabling: "正在禁用",
    down: "关闭",
    downForMaintenance: "因为维护而关闭",
    enabled: "已启用",
    enabling: "正在启用",
    error: "错误",
    expired: "已到期",
    exporting: "正在导出",
    failed: "失败",
    failedApply: "应用失败",
    failedRollback: "回退失败",
    faulty: "有故障",
    importing: "正在导入",
    inactive: "不活动",
    initiated: "已启动",
    inProgress: "正在进行",
    internalError: "内部错误",
    loading: "正在加载",
    maintenanceInProgress: "正在维护",
    missing: "缺少",
    packaged: "已打包",
    pendingCustomer: "等待客户处理",
    pendingProvider: "等待提供商处理",
    pendingProvisioning: "等待预配",
    preparing: "正在准备",
    processed: "已处理",
    processing: "正在处理",
    provisioned: "已预配",
    provisioning: "正在预配",
    queued: "已排队",
    ready: "就绪",
    received: "已收到",
    related: "相关",
    requestReceived: "已收到请求",
    restoreFailed: "还原失败",
    restoreInProgress: "正在进行还原",
    restoring: "正在还原",
    returned: "已返回",
    rollingBack: "正在回退",
    running: "正在运行",
    scaleInProgress: "正在扩展",
    shipping: "发运中",
    starting: "正在启动",
    stopped: "已停止",
    stopping: "正在停止",
    succeeded: "成功",
    terminated: "已终止",
    terminating: "正在终止",
    up: "正常运行",
    unassigned: "已取消分配",
    unassigning: "正在取消分配",
    unavailable: "不可用",
    updated: "已更新",
    updating: "正在更新",
    upgrading: "正在升级",
    waiting: "正在等待",
    warning: "警告",
  },
  tags: {
    less: "更少",
    more: "更多 ({count})",
  },
  validation: {
    addressInvalid: "地址无效：'IP 地址或 CIDR 块 {address} 格式错误'。",
    addressOverlap: "地址无效：'{address1} 与 {address2} 重叠'。",
    addressReserved: "地址无效：'{address} 是保留地址'。",
    addressReservedRange:
      "地址无效：'{address} 在保留范围内 {cidr} ({startingIp} - {endingIp})'。",
    asyncValidationFailed: "<strong>[{tabField}]</strong>：{error}",
    cidrHasReservedIp: "地址无效：'{cidr}' 包含保留地址 {address}'。",
    cidrHostBitsSet: "地址无效：'不能为 CIDR 设置主机位'。",
    cidrInvalid: "地址无效：'CIDR 块 {cidr} 格式错误'。",
    cidrPrefixInvalid:
      "地址无效：'CIDR 前缀必须介于 {min} 和 {max} 之间（包含这两个值）'",
    inputRange: "值长度必须介于 {minValue} 和 {maxValue} 个字符之间。",
    integerMax: "此值最多为 {maxValue}。",
    integerMin: "此值至少为 {minValue}。",
    integerRange: "值必须介于 {minValue} 和 {maxValue} 之间。",
    integerValid: "此值必须是有效的整数。",
    ipAddressInvalid: "地址无效：'IP 地址 {address} 格式错误'",
    maxRows: "不能超过 {maxRows} 个条目。",
    minRows: "必须至少有 {minRows} 个条目。",
    requiredValidation: "值不能为空。",
    radioValidation: "提供的值不是有效选项",
    timePickerValidation: "时间格式无效。",
    validationFailed: "验证失败。所需信息缺失或无效。",
    validationFailedWithTabHeader:
      "以下选项卡的验证失败：{tabHeader}。所需信息缺失或无效。",
    validationFailedWithTabHeaders:
      "以下选项卡的验证失败：{tabHeaders}。所需信息缺失或无效。",
    validationInProgress: "正在验证",
    validationMaxDate: "值应为 {maxDate} {maxTime} 或更早的日期。",
    validationMinDate: "值应为 {minDate} {minTime} 或更晚的日期。",
    validationMinMaxDateRange:
      "值应介于 {minDate} {minTime} 和 {maxDate} {maxTime} 之间。",
    validationPassed: "已通过验证。",
    validationReservedWord: "值不能包含保留字 '{reserved}'。",
    validationReservedWords: "值不能包含以下任何保留字：{reserved}。",
    valueMaxLen: "值的长度不得超过 {maxLength} 个字符。",
    valueMinLen: "值的长度必须至少为 {minLength} 个字符。",
  },
};
bundled["zh-Hant"] = {
  actions: {
    add: "新增",
    addFilter: "新增篩選條件",
    addTags: "按一下此處以新增標記",
    apply: "套用",
    clearFilters: "清除篩選條件",
    copiedToClipboard: "已複製",
    copyToClipboard: "複製到剪貼簿",
    create: "建立",
    delete: "刪除",
    edit: "編輯",
    editTag: "編輯標記",
    next: "下一步",
    previous: "上一步",
    reviewCreate: "複查 + 建立",
    selectAll: "全選",
    selectFile: "選取檔案",
    view: "檢視",
  },
  ariaLabel: {
    actionMenu: "按一下即可開啟彈出式功能表",
    actionMenuHeader: "彈出式功能表資料欄",
    close: "關閉",
    collapse: "收合",
    datePicker: "選取日期",
    delete: "刪除",
    dismissNotification: "關閉通知",
    expand: "展開",
    fileUpload: "上傳檔案",
    groupBy: "群組方式",
    info: "資訊",
    more: "更多",
    nextMonth: "下個月",
    page: "頁",
    previousMonth: "上個月",
    required: "必要",
    resourceList: "資源清單",
    selectItem: "選取項目",
    sliderInput: "滑桿輸入",
    switchView: "在資料的清單檢視或摘要檢視之間做切換",
    timePicker: "選取時間",
  },
  common: {
    addressesCount: "({count} 個位址)",
    all: "全部",
    cancel: "取消",
    confirm: "確認",
    contains: "包含",
    discard: "捨棄",
    equals: "等於",
    error: "錯誤",
    errorLoadingData: "載入資料時發生錯誤",
    filter: "篩選條件",
    lastViewed: "上次檢視時間",
    less: "較少",
    loading: "正在載入...",
    more: "更多 ({count})",
    name: "名稱",
    no: "否",
    ok: "確定",
    operator: "運算子",
    required: "必要",
    success: "成功",
    tag: "標記",
    tags: "標記",
    type: "類型",
    value: "值",
    yes: "是",
  },
  duration: {
    daysLong: "天",
    daysShort: "天",
    daysSymbol: "天",
    hoursLong: "小時",
    hoursShort: "小時",
    hoursSymbol: "小時",
    millisecondsLong: "毫秒",
    millisecondsShort: "毫秒",
    millisecondsSymbol: "毫秒",
    minutesLong: "分鐘",
    minutesShort: "分鐘",
    minutesSymbol: "分鐘",
    secondsLong: "秒",
    secondsShort: "秒",
    secondsSymbol: "秒",
  },
  hints: {
    filterAnyField: "任何欄位的篩選條件...",
    filterPlaceholder: "篩選...",
    noResultsFound: "找不到結果.",
    requiredField: "必要欄位",
    searchForField: "搜尋 {fieldName}",
    searchPlaceholder: "搜尋",
    selectAnOption: "選取選項",
    selectExisting: "選取現有項目...",
    showSelection: "已選取 {count} 個",
  },
  labels: {
    addresses: "位址",
    essentials: "要素",
    tags: "標記",
  },
  listTemplate: {
    learnMore: "進一步瞭解",
    none: "無",
    showing: "顯示 {start} 到 {end} 的 {total} 筆記錄.",
  },
  notifications: {
    apiErrorMsg: "可能有用的基礎 API 其他詳細資訊: {errMsg}",
    pollingErrorMessage: "無法檢查作業狀態.",
    pollingErrorTitle: "無法檢查",
  },
  pagination: {
    next: "下一頁",
    of: "/ {totalNumberOfPages}",
    page: "頁",
    previous: "上一頁",
  },
  status: {
    accepted: "已接受",
    actionRequired: "需要採取動作",
    active: "作用中",
    applied: "已套用",
    applying: "套用中",
    assigned: "已指定",
    assigning: "指定中",
    attached: "已連附",
    attaching: "連附中",
    available: "可用",
    availableNeedsAttention: "可用但需要注意",
    backupInProgress: "備份中",
    blocked: "已封鎖",
    canceled: "已取消",
    canceling: "取消中",
    cancelled: "已取消",
    cancelledReturned: "已取消並歸還",
    checking: "檢查中",
    closed: "已關閉",
    complete: "完成",
    completed: "已完成",
    converting: "轉換中",
    created: "已建立",
    creating: "建立中",
    creatingImage: "映像檔建立中",
    deleted: "已刪除",
    deleting: "刪除中",
    detaching: "取消連附中",
    disabled: "已停用",
    disabling: "停用中",
    down: "停止運作",
    downForMaintenance: "停止運作以進行維護",
    enabled: "已啟用",
    enabling: "啟用中",
    error: "錯誤",
    expired: "已到期",
    exporting: "匯出中",
    failed: "失敗",
    failedApply: "套用失敗",
    failedRollback: "倒回失敗",
    faulty: "故障",
    importing: "匯入中",
    inactive: "非作用中",
    initiated: "已起始",
    inProgress: "進行中",
    internalError: "內部錯誤",
    loading: "載入中",
    maintenanceInProgress: "維護中",
    missing: "遺漏",
    packaged: "已封裝",
    pendingCustomer: "等待客戶處理",
    pendingProvider: "等待提供者處理",
    pendingProvisioning: "等待佈建",
    preparing: "準備中",
    processed: "已處理",
    processing: "處理中",
    provisioned: "已佈建",
    provisioning: "佈建中",
    queued: "已排入佇列",
    ready: "就緒",
    received: "已收到",
    related: "相關",
    requestReceived: "已收到要求",
    restoreFailed: "回復失敗",
    restoreInProgress: "回復中",
    restoring: "回復中",
    returned: "已歸還",
    rollingBack: "倒回中",
    running: "執行中",
    scaleInProgress: "調整中",
    shipping: "寄送中",
    starting: "啟動中",
    stopped: "已停止",
    stopping: "停止中",
    succeeded: "成功",
    terminated: "已終止",
    terminating: "終止中",
    up: "正常運作",
    unassigned: "已取消指定",
    unassigning: "取消指定中",
    unavailable: "無法使用",
    updated: "已更新",
    updating: "更新中",
    upgrading: "升級中",
    waiting: "等待中",
    warning: "警告",
  },
  tags: {
    less: "較少",
    more: "較多 ({count})",
  },
  validation: {
    addressInvalid: "無效的位址: 'IP 位址或 CIDR 區塊格式錯誤: {address}'.",
    addressOverlap: "無效的位址: '{address1} 與 {address2} 重疊'.",
    addressReserved: "無效的位址: '{address} 是保留位址'.",
    addressReservedRange:
      "無效的位址: '{address} 位於保留範圍 {cidr} ({startingIp} - {endingIp})'.",
    asyncValidationFailed: "<strong>[{tabField}]</strong>: {error}",
    cidrHasReservedIp: "無效的位址: '{cidr}' 包含保留位址 {address}'.",
    cidrHostBitsSet: "無效的位址: 'CIDR 不可設定主機位元'.",
    cidrInvalid: "無效的位址: 'CIDR 區塊格式錯誤: {cidr}'.",
    cidrPrefixInvalid:
      "無效的位址: 'CIDR 前置碼必須在 {min} (含) 到 {max} (含) 之間'.",
    inputRange: "值的長度必須在 {minValue} 到 {maxValue} 個字元之間.",
    integerMax: "值最大為 {maxValue}.",
    integerMin: "值至少需為 {minValue}.",
    integerRange: "值必須在 {minValue} 到 {maxValue} 之間.",
    integerValid: "值必須是有效的整數.",
    ipAddressInvalid: "無效的位址: 'IP 位址格式錯誤: {address}'",
    maxRows: "最多只能有 {maxRows} 個項目.",
    minRows: "至少要有 {minRows} 個項目.",
    requiredValidation: "值不能是空的.",
    radioValidation: "提供的值不是有效的選項.",
    timePickerValidation: "時間格式無效.",
    validationFailed: "驗證失敗. 遺漏必要的資訊或資訊無效.",
    validationFailedWithTabHeader:
      "以下頁籤的驗證失敗: {tabHeader}. 遺漏必要的資訊或資訊無效.",
    validationFailedWithTabHeaders:
      "以下頁籤的驗證失敗: {tabHeaders}. 遺漏必要的資訊或資訊無效.",
    validationInProgress: "驗證中",
    validationMaxDate: "值應為 {maxDate}, {maxTime} 或更早的時間.",
    validationMinDate: "值應為 {minDate}, {minTime} 或更晚的時間.",
    validationMinMaxDateRange:
      "值應為 {minDate}, {minTime} 到 {maxDate}, {maxTime} 之間的時間.",
    validationPassed: "已通過驗證.",
    validationReservedWord: "值不可包含保留字 '{reserved}'.",
    validationReservedWords: "值不可包含以下任一保留字: {reserved}.",
    valueMaxLen: "值的長度最多只能有 {maxLength} 個字元.",
    valueMinLen: "值的長度至少必須要有 {minLength} 個字元.",
  },
};

export const i18n = new LoomI18n();

/**
 * Initialize the instance. If no {@code defaultLanguage} are provided, tries to automatically detect the language
 * @param defaultLanguage The language to use initially
 */
export function init(
  defaultLanguage?: string,
  localeOptions?: {
    defaultLocale?: string;
    defaultCurrency?: Currency.Code;
    defaultTimezone?: string;
  }
): void {
  i18n.initSync({
    defaultLanguage: defaultLanguage,
    resources: bundled,
    localesData: {
      en: enLocaleData,
      ar: arLocaleData,
      cs: csLocaleData,
      da: daLocaleData,
      de: deLocaleData,
      el: elLocaleData,
      es: esLocaleData,
      fi: fiLocaleData,
      fr: frLocaleData,
      hr: hrLocaleData,
      hu: huLocaleData,
      it: itLocaleData,
      ja: jaLocaleData,
      ko: koLocaleData,
      nl: nlLocaleData,
      no: noLocaleData,
      pl: plLocaleData,
      pt: ptLocaleData,
      ro: roLocaleData,
      ru: ruLocaleData,
      sk: skLocaleData,
      sl: slLocaleData,
      sr: srLocaleData,
      sv: svLocaleData,
      th: thLocaleData,
      tr: trLocaleData,
      zh: zhLocaleData,
    },
    defaultCurrency: localeOptions && localeOptions.defaultCurrency,
    defaultTimezone: localeOptions && localeOptions.defaultTimezone,
  });
}

/**
 * Add a notification callback on initialization time. Please set this before you call @method init
 * @param callback
 */
export function onInit(
  callback: (initLang: string, error?: any) => void
): void {
  i18n.onInit(callback);
}

/**
 * Helper functions for i18n.
 */
export const i18nUtils = {
  /**
   * Function to format currency.
   * @param value
   * @deprecated We will be removing "Code" and only have optional "Options" object where you can specify the currency
   * @param codeOrOptions Currency code to use, defaults to USD. Use getCurrencyCode to map string to Currency.Code enum
   * or Options object to set currencyCode(defaults to USD), displayFormat(Defaults to Symbol) and precision
   * @deprecated We will be removing "displayFormat" in the future. You can set this in the Options object
   * @param displayFormat Display as symbol or name. Eg. $5.00 or 5 US Dollars, Defaults to Symbol.
   * This is ignored if "Options" object is provided
   */
  formatCurrency: function formatCurrency(
    value: number,
    codeOrOptions?: Currency.Code | Currency.Options,
    displayFormat?: Currency.DisplayFormat
  ): string {
    return i18n.formatCurrency(value, codeOrOptions, displayFormat);
  },

  /**
   * Get Currency.Code enum from the specified string. If not found, returns the string value cast as enum.
   * @param code Currency code string to look up.
   */
  getCurrencyCode: function getCurrencyCode(code: string): Currency.Code {
    return (<any>Currency.Code).code || code;
  },
};

/**
 * Add a notification callback on missing translation key. Useful for applications that may need to do
 * work once the translation key is missing in translation files.
 * @param callback
 */
export function onMissingKey(callback: (key: string) => void): void {
  i18n.onMissingKey(callback);
}

/**
 * Function to check if the instance is initialized.
 */
export function isInit(): boolean {
  return i18n.isInitialized;
}

/**
 * Change the language of the current instance.
 * @param language New language to use. Can be either language code (e.g. "en"), or locale code (e.g. "en-US"))
 */
export function changeLanguage(language: string): Promise<void> {
  return i18n.changeLanguage(language);
}

/**
 * Add a notification callback on change of language. Useful for applications that may need
 * to do work once the language is changed (e.g. forceUpdate of the root component in react)
 * @param callback
 */
export function onLanguageChanged(
  callback: (language: string, error?: any) => void
): void {
  i18n.onLanguageChanged(callback);
}

/**
 * Main wrapper for calling the i18next t function
 * key is the unique path to the string in en.json
 * props is an object of typed replacement values for placeholders in the string
 * returnKey is a flag that indicates that this method should return the key itself instead of string
 */
function t(key: string, props: { [key: string]: any }, returnKey?: boolean) {
  if (returnKey) {
    return key;
  } else {
    return i18n.t(key, props);
  }
}

export const actions = {
  add: function actions_add(_key?: boolean): string {
    return t("actions.add", {}, _key);
  },
  addColumn: function actions_addColumn(_key?: boolean): string {
    return t("actions.addColumn", {}, _key);
  },
  addFilter: function actions_addFilter(_key?: boolean): string {
    return t("actions.addFilter", {}, _key);
  },
  addTags: function actions_addTags(_key?: boolean): string {
    return t("actions.addTags", {}, _key);
  },
  apply: function actions_apply(_key?: boolean): string {
    return t("actions.apply", {}, _key);
  },
  clearFilters: function actions_clearFilters(_key?: boolean): string {
    return t("actions.clearFilters", {}, _key);
  },
  copiedToClipboard: function actions_copiedToClipboard(
    _key?: boolean
  ): string {
    return t("actions.copiedToClipboard", {}, _key);
  },
  copyToClipboard: function actions_copyToClipboard(_key?: boolean): string {
    return t("actions.copyToClipboard", {}, _key);
  },
  create: function actions_create(_key?: boolean): string {
    return t("actions.create", {}, _key);
  },
  delete: function actions_delete(_key?: boolean): string {
    return t("actions.delete", {}, _key);
  },
  edit: function actions_edit(_key?: boolean): string {
    return t("actions.edit", {}, _key);
  },
  editTag: function actions_editTag(_key?: boolean): string {
    return t("actions.editTag", {}, _key);
  },
  moveDown: function actions_moveDown(_key?: boolean): string {
    return t("actions.moveDown", {}, _key);
  },
  moveUp: function actions_moveUp(_key?: boolean): string {
    return t("actions.moveUp", {}, _key);
  },
  next: function actions_next(_key?: boolean): string {
    return t("actions.next", {}, _key);
  },
  previous: function actions_previous(_key?: boolean): string {
    return t("actions.previous", {}, _key);
  },
  resetToDefaults: function actions_resetToDefaults(_key?: boolean): string {
    return t("actions.resetToDefaults", {}, _key);
  },
  reviewCreate: function actions_reviewCreate(_key?: boolean): string {
    return t("actions.reviewCreate", {}, _key);
  },
  selectAll: function actions_selectAll(_key?: boolean): string {
    return t("actions.selectAll", {}, _key);
  },
  selectFile: function actions_selectFile(_key?: boolean): string {
    return t("actions.selectFile", {}, _key);
  },
  view: function actions_view(_key?: boolean): string {
    return t("actions.view", {}, _key);
  },
};
export const ariaLabel = {
  actionMenu: function ariaLabel_actionMenu(_key?: boolean): string {
    return t("ariaLabel.actionMenu", {}, _key);
  },
  actionMenuHeader: function ariaLabel_actionMenuHeader(
    _key?: boolean
  ): string {
    return t("ariaLabel.actionMenuHeader", {}, _key);
  },
  close: function ariaLabel_close(_key?: boolean): string {
    return t("ariaLabel.close", {}, _key);
  },
  collapse: function ariaLabel_collapse(_key?: boolean): string {
    return t("ariaLabel.collapse", {}, _key);
  },
  datePicker: function ariaLabel_datePicker(_key?: boolean): string {
    return t("ariaLabel.datePicker", {}, _key);
  },
  delete: function ariaLabel_delete(_key?: boolean): string {
    return t("ariaLabel.delete", {}, _key);
  },
  dismissNotification: function ariaLabel_dismissNotification(
    _key?: boolean
  ): string {
    return t("ariaLabel.dismissNotification", {}, _key);
  },
  expand: function ariaLabel_expand(_key?: boolean): string {
    return t("ariaLabel.expand", {}, _key);
  },
  fileUpload: function ariaLabel_fileUpload(_key?: boolean): string {
    return t("ariaLabel.fileUpload", {}, _key);
  },
  groupBy: function ariaLabel_groupBy(_key?: boolean): string {
    return t("ariaLabel.groupBy", {}, _key);
  },
  info: function ariaLabel_info(_key?: boolean): string {
    return t("ariaLabel.info", {}, _key);
  },
  more: function ariaLabel_more(_key?: boolean): string {
    return t("ariaLabel.more", {}, _key);
  },
  nextMonth: function ariaLabel_nextMonth(_key?: boolean): string {
    return t("ariaLabel.nextMonth", {}, _key);
  },
  page: function ariaLabel_page(_key?: boolean): string {
    return t("ariaLabel.page", {}, _key);
  },
  previousMonth: function ariaLabel_previousMonth(_key?: boolean): string {
    return t("ariaLabel.previousMonth", {}, _key);
  },
  required: function ariaLabel_required(_key?: boolean): string {
    return t("ariaLabel.required", {}, _key);
  },
  resourceList: function ariaLabel_resourceList(_key?: boolean): string {
    return t("ariaLabel.resourceList", {}, _key);
  },
  selectItem: function ariaLabel_selectItem(_key?: boolean): string {
    return t("ariaLabel.selectItem", {}, _key);
  },
  sliderInput: function ariaLabel_sliderInput(_key?: boolean): string {
    return t("ariaLabel.sliderInput", {}, _key);
  },
  switchView: function ariaLabel_switchView(_key?: boolean): string {
    return t("ariaLabel.switchView", {}, _key);
  },
  timePicker: function ariaLabel_timePicker(_key?: boolean): string {
    return t("ariaLabel.timePicker", {}, _key);
  },
};
export const common = {
  addressesCount: function common_addressesCount(
    count: string,
    _key?: boolean
  ): string {
    return t("common.addressesCount", { count }, _key);
  },
  all: function common_all(_key?: boolean): string {
    return t("common.all", {}, _key);
  },
  cancel: function common_cancel(_key?: boolean): string {
    return t("common.cancel", {}, _key);
  },
  confirm: function common_confirm(_key?: boolean): string {
    return t("common.confirm", {}, _key);
  },
  contains: function common_contains(_key?: boolean): string {
    return t("common.contains", {}, _key);
  },
  discard: function common_discard(_key?: boolean): string {
    return t("common.discard", {}, _key);
  },
  equals: function common_equals(_key?: boolean): string {
    return t("common.equals", {}, _key);
  },
  error: function common_error(_key?: boolean): string {
    return t("common.error", {}, _key);
  },
  errorLoadingData: function common_errorLoadingData(_key?: boolean): string {
    return t("common.errorLoadingData", {}, _key);
  },
  filter: function common_filter(_key?: boolean): string {
    return t("common.filter", {}, _key);
  },
  lastViewed: function common_lastViewed(_key?: boolean): string {
    return t("common.lastViewed", {}, _key);
  },
  less: function common_less(_key?: boolean): string {
    return t("common.less", {}, _key);
  },
  loading: function common_loading(_key?: boolean): string {
    return t("common.loading", {}, _key);
  },
  more: function common_more(count: string, _key?: boolean): string {
    return t("common.more", { count }, _key);
  },
  name: function common_name(_key?: boolean): string {
    return t("common.name", {}, _key);
  },
  no: function common_no(_key?: boolean): string {
    return t("common.no", {}, _key);
  },
  ok: function common_ok(_key?: boolean): string {
    return t("common.ok", {}, _key);
  },
  operator: function common_operator(_key?: boolean): string {
    return t("common.operator", {}, _key);
  },
  required: function common_required(_key?: boolean): string {
    return t("common.required", {}, _key);
  },
  success: function common_success(_key?: boolean): string {
    return t("common.success", {}, _key);
  },
  tag: function common_tag(_key?: boolean): string {
    return t("common.tag", {}, _key);
  },
  tags: function common_tags(_key?: boolean): string {
    return t("common.tags", {}, _key);
  },
  type: function common_type(_key?: boolean): string {
    return t("common.type", {}, _key);
  },
  value: function common_value(_key?: boolean): string {
    return t("common.value", {}, _key);
  },
  yes: function common_yes(_key?: boolean): string {
    return t("common.yes", {}, _key);
  },
};
export const duration = {
  daysLong: function duration_daysLong(_key?: boolean): string {
    return t("duration.daysLong", {}, _key);
  },
  daysShort: function duration_daysShort(_key?: boolean): string {
    return t("duration.daysShort", {}, _key);
  },
  daysSymbol: function duration_daysSymbol(_key?: boolean): string {
    return t("duration.daysSymbol", {}, _key);
  },
  hoursLong: function duration_hoursLong(_key?: boolean): string {
    return t("duration.hoursLong", {}, _key);
  },
  hoursShort: function duration_hoursShort(_key?: boolean): string {
    return t("duration.hoursShort", {}, _key);
  },
  hoursSymbol: function duration_hoursSymbol(_key?: boolean): string {
    return t("duration.hoursSymbol", {}, _key);
  },
  millisecondsLong: function duration_millisecondsLong(_key?: boolean): string {
    return t("duration.millisecondsLong", {}, _key);
  },
  millisecondsShort: function duration_millisecondsShort(
    _key?: boolean
  ): string {
    return t("duration.millisecondsShort", {}, _key);
  },
  millisecondsSymbol: function duration_millisecondsSymbol(
    _key?: boolean
  ): string {
    return t("duration.millisecondsSymbol", {}, _key);
  },
  minutesLong: function duration_minutesLong(_key?: boolean): string {
    return t("duration.minutesLong", {}, _key);
  },
  minutesShort: function duration_minutesShort(_key?: boolean): string {
    return t("duration.minutesShort", {}, _key);
  },
  minutesSymbol: function duration_minutesSymbol(_key?: boolean): string {
    return t("duration.minutesSymbol", {}, _key);
  },
  secondsLong: function duration_secondsLong(_key?: boolean): string {
    return t("duration.secondsLong", {}, _key);
  },
  secondsShort: function duration_secondsShort(_key?: boolean): string {
    return t("duration.secondsShort", {}, _key);
  },
  secondsSymbol: function duration_secondsSymbol(_key?: boolean): string {
    return t("duration.secondsSymbol", {}, _key);
  },
};
export const hints = {
  addToFavorites: function hints_addToFavorites(_key?: boolean): string {
    return t("hints.addToFavorites", {}, _key);
  },
  filterAnyField: function hints_filterAnyField(_key?: boolean): string {
    return t("hints.filterAnyField", {}, _key);
  },
  filterPlaceholder: function hints_filterPlaceholder(_key?: boolean): string {
    return t("hints.filterPlaceholder", {}, _key);
  },
  noResultsFound: function hints_noResultsFound(_key?: boolean): string {
    return t("hints.noResultsFound", {}, _key);
  },
  removeFromFavorites: function hints_removeFromFavorites(
    _key?: boolean
  ): string {
    return t("hints.removeFromFavorites", {}, _key);
  },
  requiredField: function hints_requiredField(_key?: boolean): string {
    return t("hints.requiredField", {}, _key);
  },
  searchForField: function hints_searchForField(
    fieldName: string,
    _key?: boolean
  ): string {
    return t("hints.searchForField", { fieldName }, _key);
  },
  searchPlaceholder: function hints_searchPlaceholder(_key?: boolean): string {
    return t("hints.searchPlaceholder", {}, _key);
  },
  selectAnOption: function hints_selectAnOption(_key?: boolean): string {
    return t("hints.selectAnOption", {}, _key);
  },
  selectExisting: function hints_selectExisting(_key?: boolean): string {
    return t("hints.selectExisting", {}, _key);
  },
  showSelection: function hints_showSelection(
    count: string,
    _key?: boolean
  ): string {
    return t("hints.showSelection", { count }, _key);
  },
};
export const labels = {
  addresses: function labels_addresses(_key?: boolean): string {
    return t("labels.addresses", {}, _key);
  },
  essentials: function labels_essentials(_key?: boolean): string {
    return t("labels.essentials", {}, _key);
  },
  noGrouping: function labels_noGrouping(_key?: boolean): string {
    return t("labels.noGrouping", {}, _key);
  },
  tags: function labels_tags(_key?: boolean): string {
    return t("labels.tags", {}, _key);
  },
};
export const listTemplate = {
  learnMore: function listTemplate_learnMore(_key?: boolean): string {
    return t("listTemplate.learnMore", {}, _key);
  },
  none: function listTemplate_none(_key?: boolean): string {
    return t("listTemplate.none", {}, _key);
  },
  showing: function listTemplate_showing(
    start: string,
    end: string,
    total: string,
    _key?: boolean
  ): string {
    return t("listTemplate.showing", { start, end, total }, _key);
  },
  showingAll: function listTemplate_showingAll(
    total: string,
    _key?: boolean
  ): string {
    return t("listTemplate.showingAll", { total }, _key);
  },
};
export const notifications = {
  apiErrorMsg: function notifications_apiErrorMsg(
    errMsg: string,
    _key?: boolean
  ): string {
    return t("notifications.apiErrorMsg", { errMsg }, _key);
  },
  pollingErrorMessage: function notifications_pollingErrorMessage(
    _key?: boolean
  ): string {
    return t("notifications.pollingErrorMessage", {}, _key);
  },
  pollingErrorTitle: function notifications_pollingErrorTitle(
    _key?: boolean
  ): string {
    return t("notifications.pollingErrorTitle", {}, _key);
  },
};
export const pagination = {
  next: function pagination_next(_key?: boolean): string {
    return t("pagination.next", {}, _key);
  },
  of: function pagination_of(
    totalNumberOfPages: string,
    _key?: boolean
  ): string {
    return t("pagination.of", { totalNumberOfPages }, _key);
  },
  page: function pagination_page(_key?: boolean): string {
    return t("pagination.page", {}, _key);
  },
  previous: function pagination_previous(_key?: boolean): string {
    return t("pagination.previous", {}, _key);
  },
};
export const status = {
  accepted: function status_accepted(_key?: boolean): string {
    return t("status.accepted", {}, _key);
  },
  actionRequired: function status_actionRequired(_key?: boolean): string {
    return t("status.actionRequired", {}, _key);
  },
  active: function status_active(_key?: boolean): string {
    return t("status.active", {}, _key);
  },
  applied: function status_applied(_key?: boolean): string {
    return t("status.applied", {}, _key);
  },
  applying: function status_applying(_key?: boolean): string {
    return t("status.applying", {}, _key);
  },
  assigned: function status_assigned(_key?: boolean): string {
    return t("status.assigned", {}, _key);
  },
  assigning: function status_assigning(_key?: boolean): string {
    return t("status.assigning", {}, _key);
  },
  attached: function status_attached(_key?: boolean): string {
    return t("status.attached", {}, _key);
  },
  attaching: function status_attaching(_key?: boolean): string {
    return t("status.attaching", {}, _key);
  },
  available: function status_available(_key?: boolean): string {
    return t("status.available", {}, _key);
  },
  availableNeedsAttention: function status_availableNeedsAttention(
    _key?: boolean
  ): string {
    return t("status.availableNeedsAttention", {}, _key);
  },
  backupInProgress: function status_backupInProgress(_key?: boolean): string {
    return t("status.backupInProgress", {}, _key);
  },
  blocked: function status_blocked(_key?: boolean): string {
    return t("status.blocked", {}, _key);
  },
  canceled: function status_canceled(_key?: boolean): string {
    return t("status.canceled", {}, _key);
  },
  canceling: function status_canceling(_key?: boolean): string {
    return t("status.canceling", {}, _key);
  },
  cancelled: function status_cancelled(_key?: boolean): string {
    return t("status.cancelled", {}, _key);
  },
  cancelledReturned: function status_cancelledReturned(_key?: boolean): string {
    return t("status.cancelledReturned", {}, _key);
  },
  checking: function status_checking(_key?: boolean): string {
    return t("status.checking", {}, _key);
  },
  closed: function status_closed(_key?: boolean): string {
    return t("status.closed", {}, _key);
  },
  complete: function status_complete(_key?: boolean): string {
    return t("status.complete", {}, _key);
  },
  completed: function status_completed(_key?: boolean): string {
    return t("status.completed", {}, _key);
  },
  converting: function status_converting(_key?: boolean): string {
    return t("status.converting", {}, _key);
  },
  created: function status_created(_key?: boolean): string {
    return t("status.created", {}, _key);
  },
  creating: function status_creating(_key?: boolean): string {
    return t("status.creating", {}, _key);
  },
  creatingImage: function status_creatingImage(_key?: boolean): string {
    return t("status.creatingImage", {}, _key);
  },
  deleted: function status_deleted(_key?: boolean): string {
    return t("status.deleted", {}, _key);
  },
  deleting: function status_deleting(_key?: boolean): string {
    return t("status.deleting", {}, _key);
  },
  detaching: function status_detaching(_key?: boolean): string {
    return t("status.detaching", {}, _key);
  },
  disabled: function status_disabled(_key?: boolean): string {
    return t("status.disabled", {}, _key);
  },
  disabling: function status_disabling(_key?: boolean): string {
    return t("status.disabling", {}, _key);
  },
  down: function status_down(_key?: boolean): string {
    return t("status.down", {}, _key);
  },
  downForMaintenance: function status_downForMaintenance(
    _key?: boolean
  ): string {
    return t("status.downForMaintenance", {}, _key);
  },
  enabled: function status_enabled(_key?: boolean): string {
    return t("status.enabled", {}, _key);
  },
  enabling: function status_enabling(_key?: boolean): string {
    return t("status.enabling", {}, _key);
  },
  error: function status_error(_key?: boolean): string {
    return t("status.error", {}, _key);
  },
  expired: function status_expired(_key?: boolean): string {
    return t("status.expired", {}, _key);
  },
  exporting: function status_exporting(_key?: boolean): string {
    return t("status.exporting", {}, _key);
  },
  failed: function status_failed(_key?: boolean): string {
    return t("status.failed", {}, _key);
  },
  failedApply: function status_failedApply(_key?: boolean): string {
    return t("status.failedApply", {}, _key);
  },
  failedRollback: function status_failedRollback(_key?: boolean): string {
    return t("status.failedRollback", {}, _key);
  },
  faulty: function status_faulty(_key?: boolean): string {
    return t("status.faulty", {}, _key);
  },
  importing: function status_importing(_key?: boolean): string {
    return t("status.importing", {}, _key);
  },
  inactive: function status_inactive(_key?: boolean): string {
    return t("status.inactive", {}, _key);
  },
  initiated: function status_initiated(_key?: boolean): string {
    return t("status.initiated", {}, _key);
  },
  inProgress: function status_inProgress(_key?: boolean): string {
    return t("status.inProgress", {}, _key);
  },
  internalError: function status_internalError(_key?: boolean): string {
    return t("status.internalError", {}, _key);
  },
  loading: function status_loading(_key?: boolean): string {
    return t("status.loading", {}, _key);
  },
  maintenanceInProgress: function status_maintenanceInProgress(
    _key?: boolean
  ): string {
    return t("status.maintenanceInProgress", {}, _key);
  },
  missing: function status_missing(_key?: boolean): string {
    return t("status.missing", {}, _key);
  },
  packaged: function status_packaged(_key?: boolean): string {
    return t("status.packaged", {}, _key);
  },
  pendingCustomer: function status_pendingCustomer(_key?: boolean): string {
    return t("status.pendingCustomer", {}, _key);
  },
  pendingProvider: function status_pendingProvider(_key?: boolean): string {
    return t("status.pendingProvider", {}, _key);
  },
  pendingProvisioning: function status_pendingProvisioning(
    _key?: boolean
  ): string {
    return t("status.pendingProvisioning", {}, _key);
  },
  preparing: function status_preparing(_key?: boolean): string {
    return t("status.preparing", {}, _key);
  },
  processed: function status_processed(_key?: boolean): string {
    return t("status.processed", {}, _key);
  },
  processing: function status_processing(_key?: boolean): string {
    return t("status.processing", {}, _key);
  },
  provisioned: function status_provisioned(_key?: boolean): string {
    return t("status.provisioned", {}, _key);
  },
  provisioning: function status_provisioning(_key?: boolean): string {
    return t("status.provisioning", {}, _key);
  },
  queued: function status_queued(_key?: boolean): string {
    return t("status.queued", {}, _key);
  },
  ready: function status_ready(_key?: boolean): string {
    return t("status.ready", {}, _key);
  },
  received: function status_received(_key?: boolean): string {
    return t("status.received", {}, _key);
  },
  related: function status_related(_key?: boolean): string {
    return t("status.related", {}, _key);
  },
  requestReceived: function status_requestReceived(_key?: boolean): string {
    return t("status.requestReceived", {}, _key);
  },
  restoreFailed: function status_restoreFailed(_key?: boolean): string {
    return t("status.restoreFailed", {}, _key);
  },
  restoreInProgress: function status_restoreInProgress(_key?: boolean): string {
    return t("status.restoreInProgress", {}, _key);
  },
  restoring: function status_restoring(_key?: boolean): string {
    return t("status.restoring", {}, _key);
  },
  returned: function status_returned(_key?: boolean): string {
    return t("status.returned", {}, _key);
  },
  rollingBack: function status_rollingBack(_key?: boolean): string {
    return t("status.rollingBack", {}, _key);
  },
  running: function status_running(_key?: boolean): string {
    return t("status.running", {}, _key);
  },
  scaleInProgress: function status_scaleInProgress(_key?: boolean): string {
    return t("status.scaleInProgress", {}, _key);
  },
  shipping: function status_shipping(_key?: boolean): string {
    return t("status.shipping", {}, _key);
  },
  starting: function status_starting(_key?: boolean): string {
    return t("status.starting", {}, _key);
  },
  stopped: function status_stopped(_key?: boolean): string {
    return t("status.stopped", {}, _key);
  },
  stopping: function status_stopping(_key?: boolean): string {
    return t("status.stopping", {}, _key);
  },
  succeeded: function status_succeeded(_key?: boolean): string {
    return t("status.succeeded", {}, _key);
  },
  terminated: function status_terminated(_key?: boolean): string {
    return t("status.terminated", {}, _key);
  },
  terminating: function status_terminating(_key?: boolean): string {
    return t("status.terminating", {}, _key);
  },
  up: function status_up(_key?: boolean): string {
    return t("status.up", {}, _key);
  },
  unassigned: function status_unassigned(_key?: boolean): string {
    return t("status.unassigned", {}, _key);
  },
  unassigning: function status_unassigning(_key?: boolean): string {
    return t("status.unassigning", {}, _key);
  },
  unavailable: function status_unavailable(_key?: boolean): string {
    return t("status.unavailable", {}, _key);
  },
  updated: function status_updated(_key?: boolean): string {
    return t("status.updated", {}, _key);
  },
  updating: function status_updating(_key?: boolean): string {
    return t("status.updating", {}, _key);
  },
  upgrading: function status_upgrading(_key?: boolean): string {
    return t("status.upgrading", {}, _key);
  },
  waiting: function status_waiting(_key?: boolean): string {
    return t("status.waiting", {}, _key);
  },
  warning: function status_warning(_key?: boolean): string {
    return t("status.warning", {}, _key);
  },
};
export const tags = {
  less: function tags_less(_key?: boolean): string {
    return t("tags.less", {}, _key);
  },
  more: function tags_more(count: string, _key?: boolean): string {
    return t("tags.more", { count }, _key);
  },
};
export const validation = {
  addressInvalid: function validation_addressInvalid(
    address: string,
    _key?: boolean
  ): string {
    return t("validation.addressInvalid", { address }, _key);
  },
  addressOverlap: function validation_addressOverlap(
    address1: string,
    address2: string,
    _key?: boolean
  ): string {
    return t("validation.addressOverlap", { address1, address2 }, _key);
  },
  addressReserved: function validation_addressReserved(
    address: string,
    _key?: boolean
  ): string {
    return t("validation.addressReserved", { address }, _key);
  },
  addressReservedRange: function validation_addressReservedRange(
    address: string,
    cidr: string,
    startingIp: string,
    endingIp: string,
    _key?: boolean
  ): string {
    return t(
      "validation.addressReservedRange",
      { address, cidr, startingIp, endingIp },
      _key
    );
  },
  asyncValidationFailed: function validation_asyncValidationFailed(
    tabField: string,
    error: string,
    _key?: boolean
  ): string {
    return t("validation.asyncValidationFailed", { tabField, error }, _key);
  },
  cidrHasReservedIp: function validation_cidrHasReservedIp(
    cidr: string,
    address: string,
    _key?: boolean
  ): string {
    return t("validation.cidrHasReservedIp", { cidr, address }, _key);
  },
  cidrHostBitsSet: function validation_cidrHostBitsSet(_key?: boolean): string {
    return t("validation.cidrHostBitsSet", {}, _key);
  },
  cidrInvalid: function validation_cidrInvalid(
    cidr: string,
    _key?: boolean
  ): string {
    return t("validation.cidrInvalid", { cidr }, _key);
  },
  cidrPrefixInvalid: function validation_cidrPrefixInvalid(
    min: string,
    max: string,
    _key?: boolean
  ): string {
    return t("validation.cidrPrefixInvalid", { min, max }, _key);
  },
  inputRange: function validation_inputRange(
    minValue: string,
    maxValue: string,
    _key?: boolean
  ): string {
    return t("validation.inputRange", { minValue, maxValue }, _key);
  },
  integerMax: function validation_integerMax(
    maxValue: string,
    _key?: boolean
  ): string {
    return t("validation.integerMax", { maxValue }, _key);
  },
  integerMin: function validation_integerMin(
    minValue: string,
    _key?: boolean
  ): string {
    return t("validation.integerMin", { minValue }, _key);
  },
  integerRange: function validation_integerRange(
    minValue: string,
    maxValue: string,
    _key?: boolean
  ): string {
    return t("validation.integerRange", { minValue, maxValue }, _key);
  },
  integerValid: function validation_integerValid(_key?: boolean): string {
    return t("validation.integerValid", {}, _key);
  },
  ipAddressInvalid: function validation_ipAddressInvalid(
    address: string,
    _key?: boolean
  ): string {
    return t("validation.ipAddressInvalid", { address }, _key);
  },
  maxRows: function validation_maxRows(
    maxRows: string,
    _key?: boolean
  ): string {
    return t("validation.maxRows", { maxRows }, _key);
  },
  minRows: function validation_minRows(
    minRows: string,
    _key?: boolean
  ): string {
    return t("validation.minRows", { minRows }, _key);
  },
  requiredValidation: function validation_requiredValidation(
    _key?: boolean
  ): string {
    return t("validation.requiredValidation", {}, _key);
  },
  radioValidation: function validation_radioValidation(_key?: boolean): string {
    return t("validation.radioValidation", {}, _key);
  },
  timePickerValidation: function validation_timePickerValidation(
    _key?: boolean
  ): string {
    return t("validation.timePickerValidation", {}, _key);
  },
  validationFailed: function validation_validationFailed(
    _key?: boolean
  ): string {
    return t("validation.validationFailed", {}, _key);
  },
  validationFailedWithTabHeader:
    function validation_validationFailedWithTabHeader(
      tabHeader: string,
      _key?: boolean
    ): string {
      return t("validation.validationFailedWithTabHeader", { tabHeader }, _key);
    },
  validationFailedWithTabHeaders:
    function validation_validationFailedWithTabHeaders(
      tabHeaders: string,
      _key?: boolean
    ): string {
      return t(
        "validation.validationFailedWithTabHeaders",
        { tabHeaders },
        _key
      );
    },
  validationInProgress: function validation_validationInProgress(
    _key?: boolean
  ): string {
    return t("validation.validationInProgress", {}, _key);
  },
  validationMaxDate: function validation_validationMaxDate(
    maxDate: string,
    maxTime: string,
    _key?: boolean
  ): string {
    return t("validation.validationMaxDate", { maxDate, maxTime }, _key);
  },
  validationMinDate: function validation_validationMinDate(
    minDate: string,
    minTime: string,
    _key?: boolean
  ): string {
    return t("validation.validationMinDate", { minDate, minTime }, _key);
  },
  validationMinMaxDateRange: function validation_validationMinMaxDateRange(
    minDate: string,
    minTime: string,
    maxDate: string,
    maxTime: string,
    _key?: boolean
  ): string {
    return t(
      "validation.validationMinMaxDateRange",
      { minDate, minTime, maxDate, maxTime },
      _key
    );
  },
  validationPassed: function validation_validationPassed(
    _key?: boolean
  ): string {
    return t("validation.validationPassed", {}, _key);
  },
  validationReservedWord: function validation_validationReservedWord(
    reserved: string,
    _key?: boolean
  ): string {
    return t("validation.validationReservedWord", { reserved }, _key);
  },
  validationReservedWords: function validation_validationReservedWords(
    reserved: string,
    _key?: boolean
  ): string {
    return t("validation.validationReservedWords", { reserved }, _key);
  },
  valueMaxLen: function validation_valueMaxLen(
    maxLength: string,
    _key?: boolean
  ): string {
    return t("validation.valueMaxLen", { maxLength }, _key);
  },
  valueMinLen: function validation_valueMinLen(
    minLength: string,
    _key?: boolean
  ): string {
    return t("validation.valueMinLen", { minLength }, _key);
  },
};
