import * as React from "react";
import { MetaItemGroupLayoutType, MetaItemGroupsLayoutProps } from "./MetaItemTypes";

export enum LabelMetaItemLayoutType {
  WIDE = "WIDE",
  COMPACT = "COMPACT",
}

export interface MetaItemGroupsLayoutInternalProps extends MetaItemGroupsLayoutProps {
  layoutType?: MetaItemGroupLayoutType;
}

export interface MetaItemGroupsLayoutConfig {
  labelWidth: number;
  labelLayoutType: LabelMetaItemLayoutType;
}

export const MetaItemGroupsLayoutContext = React.createContext<MetaItemGroupsLayoutConfig>(
  {} as MetaItemGroupsLayoutConfig,
);
