import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { buildTestId } from "../../helpers/testIdHelper";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { inputWizardLayoutManager } from "../../models/LayoutManager";
import { FormInputGroupLayout } from "../Input/FormInputGroup";
import { FormStates } from "../Input/FormInternalTypes";
import { FormValues, ValidationState } from "../Input/FormTypes";
import {
  AnchoredPanelComponent,
  AnchoredPanelProps,
  AnchoredPanelType,
  ButtonType,
  PanelFooterButton,
} from "../Panel/AnchoredPanelTypes";
import { InternalAnchoredPanel } from "../Panel/InternalAnchoredPanel";
import { InputForm, InputFormComponent, InputFormInitialState, InputFormProps } from "./InputForm";
import { SubmitButtonMode } from "./InputFormTypes";

export interface InputFormSidePanelComponent extends AnchoredPanelComponent {
  /**
   * Method to retrieve the form values
   */
  getFormValues: () => FormValues | undefined;
  allowResubmit: () => void;
}

export interface InputFormSidePanelProps extends
  Pick<AnchoredPanelProps, "type" | "customWidth" | "title" | "onClose" | "message" >,
  Pick<InputFormProps, "layout" | "onSubmit" | "children" > {
  /**
   * Component reference for the InputWizardPanel
   */
  componentRef?: (ref: InputFormSidePanelComponent) => void;
  /**
   * Show only the submit button on the InputFormSidePanel
   */
  submitButtonOnly?: boolean;
  /**
   * Specify how will submit button initially show (enabled/disabled)
   * @default ENABLE_TILL_CLICKED
   */
  submitButtonMode?: SubmitButtonMode;
  /**
   * Override default submit button label
   */
  submitText?: string;
  /**
   * Override default discard button label
   */
  discardText?: string;
  /**
   * Id used to get sub elements of the InputFormSidePanel
   */
  testId?: string;
  /**
   * Initial field values. Used by the component to enable submit button if any has been modified by the user.
   * Setting this prop implicitly will set submitButtonMode to DISABLE_TILL_VALID regardless of what has been set
   * by the consumer.
   */
  initialValues?: FormValues;
}

const enum PrimaryActionState {
  WAIT_CLICK = "WAIT_CLICK",
  CLICKED = "CLICKED",
}

export interface InputFormSidePanelTestIds {
  primaryButton: string;
  discardButton: string;
}

export const buildInputFormSidePanelTestIds = (testId?: string): InputFormSidePanelTestIds => ({
  discardButton: buildTestId(testId, "-discard-button"),
  primaryButton: buildTestId(testId, "-primary-button"),
});

/**
 *The InputFormSidePanel renders the InputForm component within a
 panel
 */
export const InputFormSidePanel = ({
  componentRef,
  type = AnchoredPanelType.MEDIUM,
  customWidth,
  layout = FormInputGroupLayout.WIDE,
  title,
  message,
  initialValues,
  submitButtonOnly,
  submitButtonMode = initialValues ? SubmitButtonMode.DISABLE_TILL_VALID : SubmitButtonMode.ENABLE_TILL_CLICKED,
  onClose,
  onSubmit,
  submitText,
  discardText = Messages.common.discard(),
  children,
  testId,
}: InputFormSidePanelProps): JSX.Element => {
  const [formStates, setFormStates] = React.useState<FormStates>({} as FormStates);
  const [overallState, setOverallState] = React.useState<ValidationState>();
  const [primaryAction, setPrimaryAction] = React.useState<PrimaryActionState>(PrimaryActionState.WAIT_CLICK);
  const [initialValuesChanged, setInitialValuesChanged] = React.useState<boolean>(false);
  const formContainerRef = React.useRef(null);
  const { width: formContainerWidth } = useResizeObserver(formContainerRef);
  const [contentLayoutType, setContentLayoutType] = React.useState<FormInputGroupLayout>(layout);

  const inputFormSidePanelTestIds = buildInputFormSidePanelTestIds(testId);

  const thisRef = React.useRef<InputFormSidePanelComponent>();

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  React.useLayoutEffect(() => {
    const inputFormContentLayoutType = inputWizardLayoutManager.getContentLayoutType(formContainerWidth);
    setContentLayoutType(inputFormContentLayoutType);
  }, [formContainerWidth]);

  React.useEffect(() => {
    if (componentRef) {
      thisRef.current = {
        getFormValues: () => formComponentRef.current?.getFormValues(),
        allowResubmit: () => setPrimaryAction(PrimaryActionState.WAIT_CLICK),
        open: () => panelRef.current?.open(),
        close: () => panelRef.current?.close(),
      } as InputFormSidePanelComponent;
      componentRef(thisRef.current);
    }
  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setOverallState(formStates.overall);
  }, [formStates]);

  React.useEffect(() => {
    if (overallState === ValidationState.INVALID) {
      setPrimaryAction(PrimaryActionState.WAIT_CLICK);
    } else if ((!overallState || overallState === ValidationState.VALID)
      && primaryAction === PrimaryActionState.CLICKED) {
      formComponentRef.current?.submit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallState, primaryAction]);

  const formComponentRef = React.useRef<InputFormComponent>();
  const setFormComponentRef = (formComponent: InputFormComponent): void => {
    formComponentRef.current = formComponent;
  };

  const onClick = (): void => {
    if (!formComponentRef.current?.isValidationEnabled()) {
      formComponentRef.current?.enableValidation();
    }
    setPrimaryAction(PrimaryActionState.CLICKED);
  };

  const onFormStatesUpdate = (states: FormStates): void => {
    setFormStates(states);
  };

  const primaryButtonDisabled = (
    (submitButtonMode === SubmitButtonMode.DISABLE_TILL_VALID && (
      (formStates.overall === ValidationState.INVALID) || !initialValuesChanged
    ))
    // ENABLE_TILL_CLICKED will not work as intended!!
    // This is due to initial input state now potentially including INVALID, not just UNKNOWN.
    || (submitButtonMode === SubmitButtonMode.ENABLE_TILL_CLICKED && formStates.overall === ValidationState.INVALID)
  );

  const footerButtons: PanelFooterButton[] = [
    {
      type: ButtonType.PRIMARY,
      testId: inputFormSidePanelTestIds.primaryButton,
      label: submitText || Messages.common.ok(),
      disabled: primaryButtonDisabled,
      onClick,
      style: { margin: "10px 5px 0px 0px", height: "24px" },
    },
  ];
  if (!submitButtonOnly) {
    footerButtons.push({
      type: ButtonType.DEFAULT,
      testId: inputFormSidePanelTestIds.discardButton,
      label: discardText,
      onClick: () => { onClose?.(); },
      style: { margin: "10px 5px 0px 0px", height: "24px" },
    });
  }

  const internalInitialState: InputFormInitialState = {
    fieldValues: initialValues,
    haveChanged: setInitialValuesChanged,
  };

  return (
    <InternalAnchoredPanel
      componentRef={setPanelRef}
      title={title}
      type={type}
      customWidth={customWidth}
      onClose={onClose}
      message={message}
      isOpen
      footer={footerButtons}
    >
      <InputForm
        componentRef={setFormComponentRef}
        layout={contentLayoutType}
        onSubmit={onSubmit}
        onStatesUpdate={onFormStatesUpdate}
        initialState={internalInitialState}
      >
        <div ref={formContainerRef}>
          {children}
        </div>
      </InputForm>
    </InternalAnchoredPanel>
  );
};
