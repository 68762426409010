import * as React from "react";
import {
  Checkbox,
  CheckboxVisibility,
  CollapseAllVisibility,
  ConstrainMode,
  DetailsHeader,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  DetailsRowFields,
  Dropdown,
  IButtonStyles,
  ICheckboxStyleProps,
  ICheckboxStyles,
  IColumn,
  Icon,
  IconButton,
  IDetailsCheckboxProps,
  IDetailsColumnProps,
  IDetailsColumnStyleProps,
  IDetailsColumnStyles,
  IDetailsGroupDividerProps,
  IDetailsHeaderProps,
  IDetailsHeaderStyleProps,
  IDetailsHeaderStyles,
  IDetailsListStyles,
  IDetailsRowFieldsProps,
  IDetailsRowProps,
  IDetailsRowStyleProps,
  IDetailsRowStyles,
  IDropdownStyles,
  IGroup,
  IStackTokens,
  IStyleFunctionOrObject,
  Label,
  mergeStyles,
  Overlay,
  registerIcons,
  Selection,
  Stack,
  Target,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { uniqueGUID } from "../../helpers/util";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { anchoredPanelLayoutManager, listingLayoutManager } from "../../models/LayoutManager";
import { ActionBar } from "../Action/ActionBar";
import { ACTION_MENU_WIDTH, ActionMenu } from "../Action/ActionMenu";
import { ActionMenuProps } from "../Action/ActionTypes";
import { ScreenSizeContext } from "../Context/ScreenSizeContext";
import { Filters } from "../Filters/Filters";
import { NoValue } from "../NoValue/NoValue";
import { AnchoredPanelHeaderLayoutType } from "../Panel/AnchoredPanelTypes";
import { ProgressDotsIndicator } from "../Progress/ProgressDotsIndicator";
import { ChevronDownSvg, ChevronRightSvg, MoreDotsSvg, Sorted, SortedAscending, SortedDescending } from "../Svg/icons";
import { ListingEmptyWatermark } from "./ListingEmptyWatermark";
import { ListingPagination } from "./ListingPagination";
import {
  buildListingTestIds,
  CheckBoxVisibility,
  ListingColumn,
  ListingProps,
  ListingSelectOption,
  SelectionMode,
  SortDirections,
  ViewSelectOption,
} from "./ListingTypes";

enum ListingDisplayType {
  FILL_CONTAINER = "FILL_CONTAINER",
  FULL_SCREEN = "FULL_SCREEN",
}

interface Column<T> extends Omit<ListingColumn, "onRenderItems"> {
  key: string;
  minWidth: number;
  isColumnSorted?: boolean;
  isColumnSortedDescending?: boolean;
  styles?: IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles>;
  onRenderItems: (item: T) => JSX.Element;
}

interface ColumnMap<T> {
  [key: string]: Column<T>;
}

interface GroupOptionMap<T> {
  [key: string]: ListingSelectOption<T>
}

type ItemsWithKeys<K> = K & {
  key: string | number;
};

const groupDropdownStyles: Partial<IDropdownStyles> = {
  root: { width: "200px", height: "24px", marginLeft: "10px" },
  title: {
    height: "24px",
    lineHeight: "22px",
    fontSize: "13px",
  },
  caretDownWrapper: { top: "-3px" },
};
const viewDropdownStyles: Partial<IDropdownStyles> = {
  root: { width: "160px", height: "24px" },
  title: {
    height: "24px",
    lineHeight: "22px",
    fontSize: "13px",
  },
  caretDownWrapper: { top: "-3px" },
};

const cellStyles: IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles> = {
  root: {
    minWidth: "32px",
    height: "32px",
    ":hover": { backgroundColor: "white" },
  },
  cellName: { fontSize: "13px" },
  cellTitle: { paddingLeft: "4px" },
};

const tableRowStyles: IStyleFunctionOrObject<IDetailsRowStyleProps, IDetailsRowStyles> = {
  root: {
    minHeight: "32px",
    height: "32px",
    minWidth: "100% !important",
    ".ms-GroupSpacer": { display: "none !important" },
  },
  cellUnpadded: { padding: "6px 8px 0px 0px !important" },
  cell: {
    fontSize: "13px",
    padding: "6px 4px 0px 5px",
    minHeight: "32px",
    color: "rgb(50, 49, 48)",
    a: {
      textDecoration: "none",
      color: "rgb(0, 120, 212)",
      ":hover": { textDecoration: "underline" },
    },
    minWidth: "30px",
    span: {
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    height: "32px",
  },
  checkCell: { width: "26px", minHeight: "32px", height: "32px" },
  cellPadded: {
    marginLeft: "54px",
    width: "50px !important",
    paddingRight: "0px !important",
    paddingTop: "6px !important",
  },
};

const headerStyles: IStyleFunctionOrObject<IDetailsHeaderStyleProps, IDetailsHeaderStyles> = {
  root: {
    height: "36px",
    paddingTop: "0px",
    marginLeft: "0px",
    ":hover": {
      cursor: "pointer",
      ".ms-DetailsHeader-cellSizer": { "::after": { opacity: 1 } },
    },
    ".ms-DetailsHeader-check": { opacity: 1 },
  },
  cellSizer: { cursor: "col-resize" },
  cellIsCheck: { width: "26px", left: "-21px" },
  cellIsGroupExpander: { display: "none" },
  check: { opacity: 1 },
};

const getHeaderCheckboxStyle = (
  hideSelectAll: boolean | undefined,
  checkboxVisibility: CheckBoxVisibility,
  selectionCount: number,
): IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> => ({
  root: {
    width: "0px",
    ":hover": { opacity: checkboxVisibility === CheckBoxVisibility.Hover && !hideSelectAll ? 1 : undefined },
    opacity: isSelectAllVisible(hideSelectAll, checkboxVisibility, selectionCount) ? 1 : 0,
  },
});

const commonRowCbStyle = {
  width: "42px",
  height: "30px",
  margin: "6px",
};

const rowCheckboxStyle = {
  root: commonRowCbStyle,
  checked: commonRowCbStyle,
};

const rowCheckboxDisabledUncheckedStyle = {
  root: commonRowCbStyle,
  checked: commonRowCbStyle,
  checkbox: { background: "#f3f2f1" },
};

const groupClass = mergeStyles({
  position: "relative",
  left: "-2px",
  display: "flex",
  borderBottom: "1px solid rgb(243, 242, 241)",
  ":hover": { backgroundColor: "rgb(243, 242, 241)" },
});

const NO_GROUPING_KEY = "no-grouping";
const ACTION_MENU_KEY = "action-menu";
const LISTING_CLASS_NAME = "listing";
const CELL_PADDINGS = 20;

const renderRowFields = (props: IDetailsRowFieldsProps): JSX.Element => (
  <span data-selection-disabled>
    <DetailsRowFields {...props} />
  </span>
);

function getInitialSorting<T>(
  columnId: string,
  columns: Column<T>[],
): { columnKey: string, direction?: SortDirections } {
  const initialSorting: { columnKey: string, direction?: SortDirections } = { columnKey: "" };
  columns.forEach(column => {
    if (column.key === columnId) {
      if (!column.initialSortDirection) {
        throw new Error(`column ${column.key} is defined as initial sort column but is not sortable`);
      } else {
        initialSorting.columnKey = column.key;
        initialSorting.direction = column.initialSortDirection ? column.initialSortDirection : undefined;
      }
    }
  });
  return initialSorting;
}

const getFluentCheckboxVisiblity = (visibility: CheckBoxVisibility): CheckboxVisibility => {
  switch (visibility) {
    case CheckBoxVisibility.Hover:
      return CheckboxVisibility.onHover;
    case CheckBoxVisibility.Always:
    default:
      return CheckboxVisibility.always;
  }
};

const isSelectAllVisible = (
  hideSelectAll: boolean | undefined,
  checkboxVisibility: CheckBoxVisibility,
  selectionCount: number,
): boolean => {
  if (hideSelectAll) return false;
  if (checkboxVisibility === CheckBoxVisibility.Always) return true;
  return selectionCount > 0;
};

const renderItem = (value: string): JSX.Element => <span title={value}>{value}</span>;
const internalOnRenderItems = <T extends object>(column: ListingColumn, item: T): JSX.Element => {
  if (!column.onRenderItems) return renderItem(item[column.itemProp]);
  const value: string | JSX.Element = column.onRenderItems(item);
  if (typeof value === "string") return renderItem(value);
  return value;
};
const onRenderItems = <T extends object>(
  column: ListingColumn,
) => (item: T): JSX.Element => internalOnRenderItems(column, item);

/**
 * A listing that diplays data in a table that can be paginated.
 * It also allows manupulating the data using an optional action bar.
 */

export const Listing = <T extends object >({
  listColumns,
  items,
  itemsPerPage,
  selectionMode,
  selectedItems,
  actionBarItems,
  onActionClick,
  infoBlocks,
  disableAllActions,
  emptyList,
  sorting,
  isLoading,
  groupingSelect,
  viewSelect,
  actions,
  filtering,
  listingComponentRef,
  testId,
  isRowSelectionDisabled,
  defaultSelection,
  hideSelectAll,
  checkboxVisibility = CheckBoxVisibility.Always,
  showItemCount = false,
  onRenderFooter,
}: ListingProps<T>): JSX.Element => {
  const ref = React.useRef(null);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [itemsWithKeys, setItemsWithKeys] = React.useState<ItemsWithKeys<T>[]>([]);
  const [filteredItems, setFilteredItems] = React.useState<T []>(itemsWithKeys);
  const [sortedItems, setSortedItems] = React.useState<T []>(filteredItems);
  const [columns, setColumns] = React.useState<Column<T>[]>([]);
  const [groups, setGroups] = React.useState<IGroup []>([]);
  const [selectedGroup, setSelectedGroup] = React.useState(groupingSelect?.defaultSelectedKey || NO_GROUPING_KEY);
  const { width: listingPanelWidth } = useResizeObserver(ref);
  const screenSizeMonitor = React.useContext(ScreenSizeContext);

  const actionBarTargetId = `confirm-dialog-action-bar-${useId()}`;
  const [contextMenu, setContextMenu] = React.useState<{ data: T; target?: Target }>();

  const [displayType, setDisplayType] = React.useState<ListingDisplayType>(ListingDisplayType.FILL_CONTAINER);

  React.useLayoutEffect(() => {
    if (screenSizeMonitor?.panelHeight) {
      const anchoredPanelHeaderLayoutType = anchoredPanelLayoutManager.getHeaderLayoutType(
        screenSizeMonitor.panelHeight,
      );
      setDisplayType(
        anchoredPanelHeaderLayoutType === AnchoredPanelHeaderLayoutType.SCROLL_WITH_CONTENT
          ? ListingDisplayType.FULL_SCREEN
          : ListingDisplayType.FILL_CONTAINER,
      );
    }
  }, [screenSizeMonitor?.panelHeight]);

  const renderHeader = (headerProps?: IDetailsColumnProps): JSX.Element | null => {
    if (!headerProps) {
      return null;
    }
    const column = headerProps?.column as Column<T>;
    let sortIcon;

    if (column.key === ACTION_MENU_KEY) {
      return (
        <span aria-label={Messages.ariaLabel.actionMenuHeader()} />
      );
    }

    if ((column.isColumnSorted === false && column.initialSortDirection) && (column.key !== selectedGroup)) {
      return (
        <span title={column.name} data-test-id={column.testId}>
          {column.name}
          {Sorted}
        </span>
      );
    }
    if (column.isColumnSortedDescending === true) {
      sortIcon = SortedDescending;
    } else if ((column.isColumnSortedDescending === false) || (column.key === selectedGroup)) {
      sortIcon = SortedAscending;
    }
    return (
      <span title={column.name} data-test-id={column.testId}>
        {column.name}
        {sortIcon}
      </span>
    );
  };

  const onRenderActionMenu = (value: T, menuActions: ListingProps<T>["actions"]): JSX.Element => (
    <span style={{
      display: "block",
      textAlign: "right",
      minWidth: `${ACTION_MENU_WIDTH}px`,
      width: `${ACTION_MENU_WIDTH}px`,
    }}
    >
      <InternalActionMenu<T>
        actions={menuActions ?? []}
        item={value}
        onOpen={resetTableSelection}
        testId={buildListingTestIds(testId).rowActionMenuButton}
        confirmDialogProps={{
          anchorId: actionBarTargetId,
          width: `${listingPanelWidth}px`,
        }}
      />
    </span>
  );

  const actionsRef = React.useRef<typeof actions>(actions);

  const getColumnByKey = (columnKey: string): Column<T> => {
    const column = columns.find(col => columnKey === col.key);
    return column || columns[0];
  };

  React.useEffect(() => {
    actionsRef.current = actions;
    // Cannot rely on serializing object as functions are lost during serialization
  }, [actions]);

  const memoColumns = React.useMemo(() => {
    let listColumnsToColumn: Column<T>[] = [];
    // if the function returns an empty array, it will be treated as having an action menu
    const hasActions = typeof actions === "function" ? true : actions?.length;

    if (listColumns) {
      let totalColumnFlexGrow = 0;
      listColumns.forEach(listColumn => {
        totalColumnFlexGrow += listColumn.flexGrow || 1;
      });
      listColumnsToColumn = listColumns.map(listColumn => ({
        key: listColumn.itemProp,
        maxWidth: ((listingPanelWidth - (hasActions ? ACTION_MENU_WIDTH : 0))
          / totalColumnFlexGrow) * (listColumn?.flexGrow || 1) - CELL_PADDINGS,
        minWidth: listingLayoutManager.getColumnMinWidth(),
        isColumnSorted: getColumnByKey(listColumn.itemProp)?.isColumnSorted,
        isColumnSortedDescending: getColumnByKey(listColumn.itemProp)?.isColumnSortedDescending,
        initialSortDirection: getColumnByKey(listColumn.itemProp)?.initialSortDirection,
        onRenderHeader: renderHeader,
        ...listColumn,
        calculatedWidth: 0,
        onRenderItems: onRenderItems(listColumn),
        styles: cellStyles,
      } as Column<T>));

      if (actionsRef.current) {
        listColumnsToColumn.push({
          key: ACTION_MENU_KEY,
          itemProp: "",
          name: "",
          minWidth: ACTION_MENU_WIDTH,
          maxWidth: ACTION_MENU_WIDTH,
          onRenderHeader: renderHeader,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          onRenderItems: (values: T) => (onRenderActionMenu(values, actionsRef.current!)),
          styles: cellStyles,
          isPadded: selectedGroup !== NO_GROUPING_KEY,
        } as Column<T>);
      }
    }
    return listColumnsToColumn;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, listingPanelWidth, JSON.stringify(listColumns)]);

  React.useEffect(() => {
    setColumns(memoColumns);
  }, [memoColumns]);

  React.useEffect(() => {
    const newItemsWithKeys: ItemsWithKeys<T> [] = [];
    items.forEach((item: T, index: number) => {
      newItemsWithKeys.push({ ...item, key: index.toString() });
    });
    setFilteredItems(newItemsWithKeys);
    setItemsWithKeys(newItemsWithKeys);
  }, [items]);

  React.useEffect(() => {
    if (currentSort.current) {
      const { columnKey, direction } = currentSort.current;
      const column = getColumnByKey(columnKey);
      if (column) {
        sortByColumn(column, filteredItems, direction || SortDirections.ASC);
      }
    } else {
      setSortedItems(filteredItems);
    }
    setCurrentPage(1);
    if (groups.length) {
      buildGroups(selectedGroup);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filteredItems)]);

  const gridStyles: Partial<IDetailsListStyles> = {
    root: {
      height: "100%",
      ".ms-List-cell": { minHeight: "32px !important" },
      "& [role=grid]": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        height: sortedItems.length === 0 ? undefined : (displayType === ListingDisplayType.FILL_CONTAINER
          ? "100%"
          : `${screenSizeMonitor?.panelHeight}px`),
        width: "100%",
        position: "relative",
        overflow: "auto",
      },
    },
    headerWrapper: {
      flex: "0 0 auto",
      position: "sticky",
      top: 0,
      zIndex: 2,
      width: "100%",
    },
    contentWrapper: { flex: "1 1 auto", width: "100%" },
  };

  const [isHeaderCbChecked, setIsHeaderCbChecked] = React.useState<boolean>(false);
  const [isHeaderCbIndeterminate, setIsHeaderCbIndeterminate] = React.useState<boolean>(false);
  const [selectionCount, setSelectionCount] = React.useState<number>(0);
  const [showWatermark, setShowWatermark] = React.useState<boolean>(false);

  const [selectionState] = React.useState<Selection>(new Selection(
    {
      onSelectionChanged: (): void => {
        if (selectedItems) {
          setSelectionCount(selectionState.getSelectedCount());
          const newSelection: T [] = [];
          selectionState.getSelection().forEach(selectedItem => {
            const { key, ...newItem } = selectedItem;
            newSelection.push(newItem as T);
          });
          return selectedItems(newSelection as T []);
        }
        return undefined;
      },
    },
  ));
  const collapsedGroups = React.useRef<{ [key: string]: { isCollapsed: boolean } }>({});
  const groupsArray = React.useRef<T[][]>([]);
  const isSelectionTouched = React.useRef(false);
  const currentSort = React.useRef(
    sorting?.initialSortedColumn
      ? getInitialSorting(sorting.initialSortedColumn || "", memoColumns)
      : { columnKey: "", direction: undefined },
  );
  const groupOptionMap = groupingSelect?.groupingOptions.reduce((
    map: GroupOptionMap<T>,
    groupOption: ListingSelectOption<T>,
  ) => {
    map[groupOption.key] = groupOption;
    return map;
  }, {});
  const columnsMap = columns.reduce((map: ColumnMap<T>, column: Column<T>) => {
    map[column.key] = column;
    return map;
  }, {});
  const viewGroupingStackTokens: IStackTokens = { childrenGap: 10 };
  const pageCount: number = itemsPerPage ? Math.ceil(sortedItems.length / itemsPerPage) : 1;
  const fromItems: number = itemsPerPage ? (
    sortedItems.length ? (currentPage * itemsPerPage
    ) - itemsPerPage + 1 : 0) : sortedItems.length ? 1 : 0;
  const totalItems: number = sortedItems.length;
  let toItems: number = itemsPerPage ? currentPage * itemsPerPage : sortedItems.length;
  const itemsToShow: T [] = sortedItems.slice(fromItems - 1, toItems);
  const showPaginationControls = !!itemsToShow.length && itemsPerPage;
  if (itemsPerPage && itemsToShow.length < itemsPerPage) {
    toItems = ((currentPage - 1) * itemsPerPage) + itemsToShow.length;
  }

  const onPrevious = (): void => {
    setCurrentPage(currentPage - 1);
  };

  const onNext = (): void => {
    setCurrentPage(currentPage + 1);
  };

  const onPageSelect = (pageNumber: number): void => {
    setCurrentPage(pageNumber);
  };

  const getSelectionMode = (): SelectionMode | undefined => {
    let mode: SelectionMode | undefined = selectionMode;
    if (selectionMode === undefined) {
      if (!selectedItems) {
        mode = SelectionMode.none;
      } else {
        mode = SelectionMode.multiple;
      }
    }

    // This is a hack to force the DetailsList to not overflow at 100% zoom on a computer screen
    // for some reason rendering our checkboxes forces this to work. (requires checkboxVisibility to be always)
    // (if checkboxVisibility is hidden this hack does not work)
    // This also requires that renderCheckbox not render a checkbox if the selectionMode is none
    if (!mode) {
      mode = SelectionMode.single;
    }

    return mode;
  };

  const resetTableSelection = (): void => {
    selectionState.setAllSelected(false);
  };

  const getCurrentSelection = (): T [] => {
    const newSelection: T [] = [];
    selectionState.getSelection().forEach(selectedItem => {
      const { key, ...newItem } = selectedItem;
      newSelection.push(newItem as T);
    });
    return newSelection;
  };

  React.useEffect(() => {
    resetTableSelection();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, currentPage]);

  React.useEffect(() => {
    if (itemsToShow.length) {
      buildGroups(selectedGroup);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage]);

  React.useEffect(() => {
    if (selectionCount === 0) {
      setIsHeaderCbChecked(false);
      setIsHeaderCbIndeterminate(false);
    } else if (selectionCount === itemsToShow.length) {
      setIsHeaderCbChecked(true);
      setIsHeaderCbIndeterminate(false);
    } else {
      setIsHeaderCbChecked(false);
      setIsHeaderCbIndeterminate(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionCount]);

  React.useEffect(() => {
    if (itemsToShow.length === 0 && currentPage > 1) {
      onPrevious();
    }
    setShowWatermark(!isLoading && itemsToShow.length === 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, itemsToShow.length]);

  React.useEffect(() => {
    if (listingComponentRef) {
      listingComponentRef({ resetTableSelection, getCurrentSelection });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (defaultSelection && !isSelectionTouched.current) {
      const itemsMap = itemsToShow.reduce((map: { [key: string]: ItemsWithKeys<T> }, obj: T) => {
        map[obj[defaultSelection.uniqueItemProp]] = obj as ItemsWithKeys<T>;
        return map;
      }, {});
      defaultSelection.values.forEach(value => {
        if (itemsMap[value] && itemsMap[value].key) {
          selectionState.setKeySelected(`${itemsMap[value].key}`, true, false);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(itemsToShow)]);

  const renderCustomColumn = (item: T, _?: number, rendredColumn?: IColumn): JSX.Element => {
    const key: string = rendredColumn?.key || "";
    const fieldContent = item[key];
    const renderElement = columnsMap[key].onRenderItems;
    if (rendredColumn?.key !== ACTION_MENU_KEY) {
      if (fieldContent === undefined || fieldContent === "") {
        return <NoValue />;
      }
    }
    if (renderElement) {
      return renderElement(item);
    }
    return <span>{fieldContent}</span>;
  };

  const [filterKey, setFilterKey] = React.useState(uniqueGUID());

  const copyAndSort = (
    itemsToSort: T[],
    columnKey: string,
    isSortedDescending?: boolean,
    comparator?: (a: T, b: T) => 0 | -1 | 1,
  ): T[] => {
    const key = columnKey;
    return itemsToSort.slice(0).sort((a, b) => {
      if (comparator) {
        return isSortedDescending ? -1 * comparator(a, b) : comparator(a, b);
      }
      if (a[key] === undefined || a[key] === null) {
        return isSortedDescending ? 1 : -1;
      }
      if (b[key] === undefined || b[key] === null) {
        return isSortedDescending ? -1 : 1;
      }
      const aVal = typeof (a[key]) === "string" ? a[key].toLowerCase() : a[key];
      const bVal = typeof (b[key]) === "string" ? b[key].toLowerCase() : b[key];
      if (typeof (aVal) === "string" && typeof (bVal) === "string" && sorting?.locale) {
        const comparaison = isSortedDescending
          ? bVal.localeCompare(aVal, sorting.locale) : aVal.localeCompare(bVal, sorting.locale);
        return comparaison > 0 ? 1 : comparaison < 0 ? -1 : 0;
      }
      return ((isSortedDescending ? aVal > bVal : aVal < bVal) ? -1 : 1);
    });
  };

  const sortByColumn = (column: Column<T>, itemsToSort: T[] | T[][], direction?: SortDirections): T [] => {
    let { isColumnSortedDescending } = column;
    if (direction === SortDirections.ASC) {
      isColumnSortedDescending = false;
    } else if (direction === SortDirections.DESC) {
      isColumnSortedDescending = true;
    }
    if (column.isColumnSorted && !direction) {
      isColumnSortedDescending = !isColumnSortedDescending;
    }
    currentSort.current = {
      columnKey: column.key,
      direction: isColumnSortedDescending ? SortDirections.DESC : SortDirections.ASC,
    };
    let newSortedItems: T [] = [];
    itemsToSort.forEach(itemToSort => {
      if (Array.isArray(itemToSort)) {
        newSortedItems.push(
          ...copyAndSort(itemToSort, column.key || "", isColumnSortedDescending, column.comparator),
        );
      } else {
        newSortedItems = copyAndSort(itemsToSort as T[], column.key || "", isColumnSortedDescending, column.comparator);
      }
    });
    setSortedItems(newSortedItems);
    setColumns(columns.map(col => {
      col.isColumnSorted = col.key === column.key;
      if (col.isColumnSorted) {
        col.isColumnSortedDescending = isColumnSortedDescending;
      }
      return col;
    }));
    return newSortedItems;
  };

  const headerColumnClick = (_?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
    const listingColumn = column as Column<T>;
    if (listingColumn.key !== selectedGroup) {
      if (listingColumn && columnsMap[listingColumn.key].initialSortDirection) {
        if (selectedGroup && selectedGroup !== NO_GROUPING_KEY) {
          buildGroups(selectedGroup);
        }
        if (listingColumn.isColumnSorted) {
          sortByColumn(
            listingColumn,
            groupsArray.current.length ? groupsArray.current : filteredItems,
          );
        } else {
          sortByColumn(
            listingColumn,
            groupsArray.current.length ? groupsArray.current : filteredItems,
            columnsMap[listingColumn.key].initialSortDirection,
          );
        }
      }
    }
  };

  const onRowCheckboxClick = (): void => {
    isSelectionTouched.current = true;
  };

  const renderCheckbox = (props: IDetailsCheckboxProps | undefined, isDisabled: boolean): JSX.Element => {
    if (!selectionMode) return <div />;

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div onClick={onRowCheckboxClick} onKeyUp={onRowCheckboxClick}>
        <div style={{ pointerEvents: "none" }}>
          <Checkbox
            ariaLabel={Messages.ariaLabel.selectItem()}
            inputProps={
            { "data-test-id": buildListingTestIds(testId).rowCheckbox } as Record<string, string | undefined>
          }
            checked={props?.checked}
            styles={isDisabled && !props?.checked ? rowCheckboxDisabledUncheckedStyle : rowCheckboxStyle}
            disabled={isDisabled}
          />
        </div>
      </div>
    );
  };

  const renderEnabledCheckbox = (props: IDetailsCheckboxProps | undefined): JSX.Element => (
    renderCheckbox(props, false)
  );

  const renderDisabledCheckbox = (props: IDetailsCheckboxProps | undefined): JSX.Element => (
    renderCheckbox(props, true)
  );

  const renderDetailsHeader = (headerProps?: IDetailsHeaderProps): JSX.Element | null => {
    if (!headerProps) {
      return null;
    }

    return <DetailsHeader {...headerProps} onRenderDetailsCheckbox={renderHeaderCheckbox} styles={headerStyles} />;
  };

  const onHeaderCheckboxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newChecked?: boolean): void => {
    isSelectionTouched.current = true;
    if (isHeaderCbIndeterminate) {
      setIsHeaderCbIndeterminate(false);
    } else {
      setIsHeaderCbChecked(!newChecked);
      selectionState.setAllSelected(!newChecked);
    }
  };

  const renderHeaderCheckbox = (): JSX.Element => (
    !hideSelectAll
      ? (
        <Checkbox
          indeterminate={isHeaderCbIndeterminate}
          checked={isHeaderCbChecked}
          onChange={onHeaderCheckboxChange}
          styles={getHeaderCheckboxStyle(hideSelectAll, checkboxVisibility, selectionCount)}
        />
      ) : <div />
  );

  const renderRow = (props: IDetailsRowProps | undefined): JSX.Element | null => {
    if (props) {
      if (isRowSelectionDisabled && isRowSelectionDisabled(props.item)) {
        return (
          <span data-selection-disabled>
            <DetailsRow
              data-test-id={`${buildListingTestIds(testId).row}`}
              rowFieldsAs={renderRowFields}
              {...props}
              styles={tableRowStyles}
              onRenderDetailsCheckbox={renderDisabledCheckbox}
            />
          </span>
        );
      }
      return (
        <DetailsRow
          data-test-id={`${buildListingTestIds(testId).row}`}
          rowFieldsAs={renderRowFields}
          {...props}
          styles={tableRowStyles}
        />
      );
    }
    return null;
  };

  const renderViewTitle = (options?: ViewSelectOption<T> []): JSX.Element => {
    const option = options && options[0];
    return renderViewOption(option);
  };

  const renderViewOption = (option?: ViewSelectOption<T>): JSX.Element => (
    <div>
      <span style={{ position: "relative", top: "3px" }}>{option?.icon && <Icon iconName={option.icon} />}</span>
      <span style={{ paddingLeft: "5px" }}>{option?.text}</span>
    </div>
  );

  /**
   * As long as this callback is provided, the HTML context menu will not be displayed
   */
  const onItemContextMenu = (item: T, _?: number, ev?: Event): void => {
    // This does not cause a flicker because it is called as part
    // of the same render as the call that sets the table selection.
    // We need to reset the selection because providing this callback to
    // Fluent means that Fluent will attempt to select the row when it is
    // right-clicked
    resetTableSelection();

    const displayActionMenu = typeof actions === "function" || actions?.length;

    if (!displayActionMenu) return;

    setContextMenu({ data: item, target: ev ? ev as Target : "" });
  };

  const showCount = showPaginationControls ? false : showItemCount;

  const buildGroupFromDataSet = (groupKey: string, dataSet: T []): IGroup [] => {
    const groupMap: { [key: string]: IGroup } = {};
    let previousKey = "";
    let totalCount = 0;
    dataSet.forEach(item => {
      const itemKey = item[groupKey];
      if (itemKey !== previousKey) {
        let displayTitle;
        if (groupOptionMap && groupOptionMap[groupKey]?.onRenderGroupTitle) {
          displayTitle = groupOptionMap[groupKey].onRenderGroupTitle?.(item);
        }
        totalCount += groupMap[previousKey] ? groupMap[previousKey].count : 0;
        groupMap[itemKey] = {
          key: itemKey,
          name: displayTitle || itemKey,
          startIndex: totalCount,
          count: 1,
          isCollapsed: collapsedGroups.current[itemKey] && collapsedGroups.current[itemKey].isCollapsed,
          data: { displayTitle },
        };
        previousKey = itemKey;
      } else {
        groupMap[itemKey].count++;
      }
    });
    return (Object.values(groupMap));
  };

  const buildGroups = (groupKey: string): void => {
    const { columnKey, direction } = currentSort.current;
    if (groupKey && groupKey !== NO_GROUPING_KEY) {
      const newSortedItems = sortByColumn(getColumnByKey(groupKey), filteredItems, SortDirections.ASC);
      let newGroups: IGroup [] = buildGroupFromDataSet(groupKey, newSortedItems);
      const groupsPerPage: IGroup [] = [];
      const groupItemArray: T[][] = [];
      newGroups.forEach(newGroup => {
        const { startIndex, count } = newGroup;
        groupItemArray.push(newSortedItems.slice(startIndex, startIndex + count));
      });
      groupsArray.current = groupItemArray;
      sortByColumn(getColumnByKey(columnKey), groupsArray.current, direction);
      if (itemsPerPage) {
        newGroups = buildGroupFromDataSet(groupKey, newSortedItems.slice(fromItems - 1, toItems));
      }
      setGroups(groupsPerPage.length ? groupsPerPage : newGroups);
    } else if (groupKey === NO_GROUPING_KEY) {
      setGroups([]);
      groupsArray.current = [];
      setColumns(columns.map(col => {
        if (col.key === selectedGroup) {
          col.isColumnSortedDescending = undefined;
          col.isColumnSorted = undefined;
        }
        return col;
      }));
      sortByColumn(getColumnByKey(columnKey), filteredItems, direction);
    }
  };

  const onGroupingChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: ListingSelectOption<T>,
    index?: number,
  ): void => {
    setSelectedGroup(option?.key as string);
    buildGroups(option?.key as string);
    groupingSelect?.onGroupingChange?.(event, option, index);
  };

  const toggleCollapse = (groupProps: IDetailsGroupDividerProps): void => {
    if (groupProps && groupProps.group) {
      collapsedGroups.current[groupProps.group.key] = { isCollapsed: !groupProps.group.isCollapsed };
      groupProps.onToggleCollapse?.(groupProps.group as IGroup);
    }
  };

  const onRenderGroupHeader = (props?: IDetailsGroupDividerProps): JSX.Element | null => {
    if (props && props.group) {
      return (
        <div className={groupClass}>
          <IconButton
            iconProps={{ iconName: props.group.isCollapsed ? "chevron-right-svg" : "chevron-down-svg" }}
            onClick={() => toggleCollapse(props)}
            styles={{ rootPressed: { backgroundColor: "rgb(243, 242, 241)" } }}
          />
          <div style={{
            color: "rgb(41, 40, 39)",
            fontSize: "13px",
            fontWeight: 600,
            lineHeight: "normal",
            fill: "rgb(50, 49, 48)",
            outlineColor: "rgb(41, 40, 39)",
            marginTop: "6px",
          }}
          >
            {props.group?.name}
          </div>
        </div>
      );
    }
    return <div />;
  };

  return (
    <Stack
      data-test-id={buildListingTestIds(testId).component}
      className={LISTING_CLASS_NAME}
      verticalFill
      style={{ width: "100%" }}
    >
      <div style={{ height: "100%" }} ref={ref}>
        <Stack style={{ height: "100%" }}>
          {actionBarItems && (
            <Stack.Item>
              <ActionBar
                actions={actionBarItems}
                infoBlocks={infoBlocks}
                disableAll={disableAllActions}
                onActionClick={onActionClick}
                id={actionBarTargetId}
              />
            </Stack.Item>
          )}
          {filtering
            && (
              <Stack.Item
                style={{
                  paddingTop: "10px",
                  paddingBottom: "5px",
                }}
              >
                <Filters
                  items={itemsWithKeys as T[]}
                  columns={listColumns}
                  setFilteredItems={setFilteredItems}
                  allExtraFilters={filtering.allExtraFilters}
                  defaultExtraFilters={filtering.defaultExtraFilters}
                  key={filterKey}
                  hideTextFilter={filtering.hideTextFilter}
                  hideAddFilterPill={filtering.hideAddFilterPill}
                  disableCollapse={filtering.disableCollapse}
                  filterLayout={filtering.filterLayout}
                  textFilterCallBack={filtering.textFilterCallBack}
                  componentRef={filtering.componentRef}
                  defaultFilterText={filtering.defaultFilterText}
                />
              </Stack.Item>
            )}
          {(showPaginationControls || groupingSelect || viewSelect || showCount) && (
            <Stack.Item>
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ margin: "10px 0px", flexWrap: "wrap" }}
              >
                <Stack
                  style={{
                    lineHeight: "normal",
                    fontWeight: 400,
                    fontSize: "13px",
                    fill: "rgb(0, 0, 0)",
                    color: "rgb(50, 49, 48)",
                    height: "17px",
                    outlineColor: "rgb(50, 49, 48)",
                    outlineStyle: "none",
                    outlineWidth: "0px",
                    textSizeAdjust: "100%",
                    marginTop: "4px",
                  }}
                >
                  {showPaginationControls && Messages.listTemplate.showing(
                    fromItems.toString(),
                    toItems.toString(),
                    totalItems.toString(),
                  )}
                  {showCount && Messages.listTemplate.showingAll(totalItems.toString())}
                </Stack>
                <Stack>
                  {(groupingSelect || viewSelect)
                  && (
                  <Stack
                    horizontal
                    tokens={viewGroupingStackTokens}
                    style={{ flexWrap: "wrap" }}
                    grow
                  >
                    {groupingSelect && (
                    <Dropdown
                      styles={groupDropdownStyles}
                      ariaLabel={Messages.ariaLabel.groupBy()}
                      options={[
                        { key: NO_GROUPING_KEY, text: Messages.labels.noGrouping() }, ...groupingSelect.groupingOptions,
                      ]}
                      selectedKey={selectedGroup}
                      onChange={onGroupingChange}
                    />
                    )}
                    {viewSelect && (
                    <Dropdown
                      styles={viewDropdownStyles}
                      ariaLabel={Messages.ariaLabel.switchView()}
                      options={viewSelect.viewOptions}
                      defaultSelectedKey={viewSelect.defaultSelectedKey}
                      onChange={viewSelect.onViewChange}
                      onRenderOption={renderViewOption}
                      onRenderTitle={renderViewTitle}
                    />
                    )}
                  </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack.Item>
          )}
          <Stack.Item
            grow
            style={{
              position: "relative",
              display: "flex",
              flexFlow: "column nowrap",
              minHeight: displayType === ListingDisplayType.FILL_CONTAINER ? "180px" : undefined,
            }}
          >
            {isLoading && (
            <>
              <ProgressDotsIndicator testId={buildListingTestIds(testId).loadingDots} />
              <Overlay style={{ zIndex: 1 }} />
            </>
            )}
            <DetailsList
              ariaLabelForGrid={Messages.ariaLabel.resourceList()}
              items={itemsToShow}
              groups={groups.length ? groups : undefined}
              groupProps={{
                onRenderHeader: onRenderGroupHeader,
                collapseAllVisibility: CollapseAllVisibility.hidden,
              }}
              columns={columns}
              setKey="listing"
              layoutMode={DetailsListLayoutMode.justified}
              onShouldVirtualize={() => false}
              constrainMode={ConstrainMode.unconstrained}
              selection={selectionState}
              selectionPreservedOnEmptyClick
              selectionMode={getSelectionMode()}
              isSelectedOnFocus={false}
              styles={gridStyles}
              checkboxVisibility={getFluentCheckboxVisiblity(checkboxVisibility)}
              onRenderRow={renderRow}
              onItemContextMenu={onItemContextMenu}
              onRenderCheckbox={renderEnabledCheckbox}
              onRenderItemColumn={renderCustomColumn}
              onColumnHeaderClick={headerColumnClick}
              onRenderDetailsHeader={renderDetailsHeader}
              selectionZoneProps={{ selection: selectionState }}
              onRenderDetailsFooter={onRenderFooter}
            />
            {showWatermark && emptyList && !isLoading ? (
              emptyList.watermark
              || emptyList.description
              || emptyList.emptyButtons
              || emptyList.learnMoreLink
              || emptyList.clearFilters
            ) ? (
              <ListingEmptyWatermark
                testId={testId}
                watermark={emptyList.watermark}
                title={emptyList.title}
                description={emptyList.description}
                emptyButtons={emptyList.emptyButtons}
                learnMoreLink={emptyList.learnMoreLink}
                clearFilters={() => {
                  if (filtering?.onClearFilters) filtering.onClearFilters();
                  setFilterKey(uniqueGUID());
                }}
              />
              ) : <Label>{emptyList.title}</Label> : <div />}
          </Stack.Item>
          {showPaginationControls && (
            <Stack.Item style={{ position: "relative" }}>
              <ListingPagination
                currentPage={currentPage}
                pageCount={pageCount}
                onNext={onNext}
                onPrevious={onPrevious}
                onPageSelect={onPageSelect}
              />
            </Stack.Item>
          )}
        </Stack>
      </div>
      {contextMenu && (
        <ActionMenu
          onClose={() => setContextMenu(undefined)}
          anchor={contextMenu.target ?? ""}
          item={contextMenu.data}
          actions={actionsRef.current ?? []}
          confirmDialogProps={{
            anchorId: actionBarTargetId,
            width: `${listingPanelWidth}px`,
          }}
        />
      )}
    </Stack>
  );
};

const btnStyles: IButtonStyles = { root: { height: 20, width: ACTION_MENU_WIDTH } };

registerIcons({
  icons: {
    "more-dots-svg": MoreDotsSvg,
    "chevron-right-svg": ChevronRightSvg,
    "chevron-down-svg": ChevronDownSvg,
  },
});

interface InternalActionMenuProps<T> extends Omit<ActionMenuProps<T>, "onClose" | "anchor"> {
  onOpen?: () => void;
}

// The main purpose of this component is to encapsulate the targetId of the button
// If we were to mix the ActionMenu launched via the contextmenu and the button
// then we would have to manage 2 different systems of targeting, and targeting by ID is easier
// to manage when encapsulated within a component.
const InternalActionMenu = <T extends object>({
  testId,
  onOpen,
  ...props
}: InternalActionMenuProps<T>): JSX.Element => {
  const id = React.useMemo(() => `action-menu-${uniqueGUID()}`, []);
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const toggleMenu = (): void => setShowMenu(prev => !prev);
  const closeMenu = (): void => setShowMenu(false);

  const internalOnClick = (): void => {
    toggleMenu();
    onOpen?.();
  };

  return (
    <>
      <IconButton
        id={id}
        data-test-id={testId}
        iconProps={{ iconName: "more-dots-svg", styles: { root: { position: "absolute", top: "2px" } } }}
        onClick={internalOnClick}
        ariaLabel={Messages.ariaLabel.actionMenu()}
        styles={btnStyles}
      />
      {showMenu && <ActionMenu<T> anchor={id} onClose={closeMenu} {...props} />}
    </>
  );
};
