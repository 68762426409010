import * as React from "react";
import { Form } from "../Input/Form";
import { FormInputGroup, FormInputGroupLayout } from "../Input/FormInputGroup";
import { StatesUpdateCallback, ValueUpdateCallback } from "../Input/FormInternalTypes";
import { FormInterface, FormValues, ValidationState } from "../Input/FormTypes";
import { InputFormGroup } from "./InputFormTypes";

export interface InputFormComponent {
  /**
   * Method to retrieve the form values
   */
  getFormValues: () => FormValues | undefined;
  /**
   * Method when validation is enabled on the InputForm
   */
  enableValidation: () => void;
  /**
   * Method when validation is enabled or disabled
   */
  isValidationEnabled: () => boolean;
  /**
   * Method for the submit action on the InputForm
   */
  submit: () => void;
  /**
   * Method to get form state on the InputForm
   */
  getFormState: () => ValidationState;
  /**
   * Method to check if form has validation
   */
  hasValidation: () => boolean;
}

export interface InputFormInitialState {
  /**
   * Initial field values. Used by the component to determine if any of the provided fields have been
   * modified by the user. No groups should be used, it should be a direct record of fields -> values.
   */
  fieldValues: FormValues | undefined;
  /**
   * Callback to inform the consumer if initial field values have changed.
   */
  haveChanged?: (initValuesChanged: boolean) => void;
}

export interface InputFormProps {
  /**
   * Component reference for the InputForm
   */
  componentRef?: (formComponent: InputFormComponent) => void;
  /**
   * Layout for the InputForm component
   * @default WIDE
   */
  layout?: FormInputGroupLayout;
  /**
   * Callback when the state is updated on the InputForm component
   */
  onStatesUpdate?: StatesUpdateCallback;
  /**
   * Callback when the form values are submitted on the InputForm component
   */
  onSubmit: (formValues: FormValues) => void;
  /**
   * Components to be displayed on the InputForm
   */
  children: React.ReactNode;
  /**
   * Callback when form field value(s) changes
   */
  onFormChange?: ValueUpdateCallback;
  /**
   * Initial field values and callback to be notified if they are modified by the user.
   */
  initialState?: InputFormInitialState;
}

// Removes empty values.
// This is needed as we have no way to notify the consumer that they have provided
// a wrong value (empty string or array instead of undefined)
function extractInitialValue(initialValue: unknown): unknown | undefined {
  // Handles SimpleBuilder, Select and Combo
  if (Array.isArray(initialValue)) {
    const extractedArray = initialValue
      .map(value => extractInitialValue(value))
      .filter(value => value !== undefined);

    return extractedArray.length ? extractedArray : undefined;
  }

  // We convert any expected 'falsy' values to undefined as that is how they are represented in the Form
  // (our definition of falsy is different in the context of the Form)
  // eslint-disable-next-line eqeqeq
  if (initialValue === "" || initialValue == undefined || initialValue === false) return undefined;
  // At this point we check if we are dealing with a row in the SimpleBuilder.
  // If not, then the value has passed our tests and is valid
  if (typeof initialValue !== "object") return initialValue;

  // We are dealing with a row in the SimpleBuilder
  const valueEntries = Object.entries(initialValue as Record<string, unknown>);

  const extractedValues = valueEntries.reduce((values, [name, value]) => {
    const transformedValue = extractInitialValue(value);
    if (transformedValue) values[name] = value;
    return values;
  }, {} as FormValues);

  // We return the row if there are valid entries
  if (Object.keys(extractedValues).length) {
    return extractedValues;
  }

  return undefined;
}

/**
 * The InputForm component renders the components provided by the caller
 * within a form in the specified layout
 */
export const InputForm = ({
  componentRef,
  layout = FormInputGroupLayout.WIDE,
  onStatesUpdate,
  onSubmit,
  children,
  onFormChange,
  initialState,
}: InputFormProps): JSX.Element => {
  const formRef = React.useRef<FormInterface>();
  const setFormRef = (form: FormInterface): void => {
    formRef.current = form;
  };

  const enableValidation = (): void => {
    formRef.current?.enableValidation();
  };

  const isValidationEnabled = (): boolean => formRef.current?.isValidationEnabled() || false;

  const submit = (): void => {
    formRef.current?.submit();
  };

  const getFormState = (): ValidationState => formRef.current?.getFormState() as ValidationState;

  const hasValidation = (): boolean => formRef.current?.hasValidation() as boolean;

  const getFormValues = (): FormValues | undefined => formRef.current?.getFormValues();

  const internalOnFormChanged = (formValues: FormValues, fieldValue: unknown, field: string, group?: string): void => {
    if (initialState) {
      initialState.haveChanged?.(initValuesHaveChanged(initialState.fieldValues, formValues));
    }
    onFormChange?.(formValues, fieldValue, field, group);
  };

  const initValuesHaveChanged = (initialFormValues: FormValues | undefined, currentFormValues: FormValues): boolean => {
    const currentEntries = currentFormValues[InputFormGroup] ? Object.entries(currentFormValues[InputFormGroup]) : [];
    const initialEntries = initialFormValues ? Object.entries(initialFormValues) : [];

    const allFields = Array.from(
      new Set([
        ...initialEntries.map(([key]) => key),
        ...currentEntries.map(([key]) => key),
      ]),
    );

    const hasChanged = allFields.some(fieldName => {
      const currentValue = currentEntries.find(([field]) => field === fieldName)?.[1];
      const initialValue = initialEntries.find(([field]) => field === fieldName)?.[1];

      const extractedCurrentValue = formRef.current?.extractFieldInputValue(currentValue, fieldName);
      const extractedInitialValue = extractInitialValue(initialValue);

      return JSON.stringify(extractedCurrentValue) !== JSON.stringify(extractedInitialValue);
    });

    return hasChanged;
  };

  React.useEffect(() => {
    if (componentRef) {
      componentRef({
        enableValidation,
        isValidationEnabled,
        submit,
        getFormState,
        getFormValues,
        hasValidation,
      } as InputFormComponent);
    }

    if (initialState?.haveChanged) {
      const formValues = getFormValues() ?? {};
      const valuesHaveChanged = initValuesHaveChanged(initialState.fieldValues, formValues);
      initialState.haveChanged(valuesHaveChanged);
    }
  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      componentRef={setFormRef}
      onSubmit={onSubmit}
      onStatesUpdate={onStatesUpdate}
      onValueUpdate={internalOnFormChanged}
    >
      <FormInputGroup
        groupName={InputFormGroup}
        layout={layout}
        style={{ height: "100%" }}
      >
        {children}
      </FormInputGroup>
    </Form>
  );
};
