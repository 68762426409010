import * as React from "react";
import { favoriteAccessManager } from "../FavoriteAccess/FavoriteAccessManager";
import { FavoriteAccessRegistration } from "../FavoriteAccess/FavoriteAccessTypes";
import { AnchoredPanelProps } from "../Panel/AnchoredPanelTypes";
import { InternalAnchoredPanel, PanelFavorite } from "../Panel/InternalAnchoredPanel";
import { ErrorView, ErrorViewProps } from "./ErrorView";

export interface ErrorViewPanelProps extends ErrorViewProps,
  Pick<AnchoredPanelProps, "componentRef" | "title" | "icon" | "subTitle" | "onClose" | "isOpen"> {
  /**
   * Registration information for tracking favorite state
   */
  favoriteAccessRegistration?: FavoriteAccessRegistration;
}

/**
 * The ErrorViewPanel handles rendering of AnchoredPanel and ErrorView directly.
 */
export const ErrorViewPanel = ({
  componentRef,
  title,
  subTitle,
  isOpen,
  onClose,
  icon,
  errorTitle,
  resourceId,
  errorCode,
  details,
  favoriteAccessRegistration,
}: ErrorViewPanelProps): JSX.Element => {
  const panelFavorite: PanelFavorite | undefined = favoriteAccessRegistration
    ? {
      isFavorite: favoriteAccessManager.isFavorite(favoriteAccessRegistration?.id || ""),
      onChange: (isFavorite: boolean): void => {
        if (favoriteAccessRegistration) {
          if (isFavorite) {
            favoriteAccessManager.addItem(favoriteAccessRegistration);
          } else {
            favoriteAccessManager.clearItem(favoriteAccessRegistration.id);
          }
        }
      },
    } as PanelFavorite : undefined;

  return (
    <InternalAnchoredPanel
      componentRef={componentRef}
      title={title}
      subTitle={subTitle}
      onClose={onClose}
      icon={icon}
      isOpen={isOpen}
      favorite={panelFavorite}
    >
      <ErrorView
        errorTitle={errorTitle}
        resourceId={resourceId}
        errorCode={errorCode}
        details={details}
      />
    </InternalAnchoredPanel>
  );
};
