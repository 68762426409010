import * as React from "react";
import {
  DefaultButton,
  IButtonStyles,
  Icon,
  IContextualMenuProps,
  IStackStyles,
  IStackTokens,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { buildListingTestIds, EmptyListingButtonTypes, EmptyListProps } from "./ListingTypes";

const emtyListStackStyles: IStackStyles = {
  root: {
    top: "100px",
    alignItems: "center",
    fontSize: "13px",
    // position: "absolute",
    // transform: "translateX(50%)",
    marginTop: "60px",
    marginBottom: "25px",
    right: "50%",
  },
};

const buttonStyles: IButtonStyles = {
  root: {
    fontSize: "13px",
    margin: "0px 8px",
    height: "24px",
  },
  label: { lineHeight: "0%" },
};

const emptyListStackTokens: IStackTokens = { childrenGap: 10 };

export const ListingEmptyWatermark = ({
  testId,
  watermark,
  title,
  description,
  emptyButtons,
  learnMoreLink,
  clearFilters,
}: EmptyListProps): JSX.Element => {
  const getEmptyListButtons = (): JSX.Element[] => {
    const elements: JSX.Element[] = [];
    emptyButtons?.forEach((button, index) => {
      if (button.type === EmptyListingButtonTypes.PRIMARY) {
        const menuProps: IContextualMenuProps | undefined = button.menu ? {
          items: button.menu.items.map(item => ({
            ...item,
            "data-test-id": item.testId,
          })),
        } : undefined;

        elements.push(
          <PrimaryButton
            data-test-id={buildListingTestIds(testId).emptyListingWatermarkPrimaryButton}
            key={index.toString()}
            styles={buttonStyles}
            text={button.text}
            onClick={button.action}
            menuProps={menuProps}
          />,
        );
      } else {
        elements.push(
          <DefaultButton
            key={index.toString()}
            styles={buttonStyles}
            text={button.text}
            onClick={button.action}
            menuProps={button.menu}
          />,
        );
      }
    });
    if (clearFilters) {
      elements.push(
        <DefaultButton
          data-test-id={buildListingTestIds(testId).emptyListingWatermarkClearFilterButton}
          key="clear-filter"
          styles={buttonStyles}
          text={Messages.actions.clearFilters()}
          onClick={clearFilters}
        />,
      );
    }
    return elements;
  };

  return (
    <Stack
      data-test-id={buildListingTestIds(testId).emptyListingWatermark}
      tokens={emptyListStackTokens}
      styles={emtyListStackStyles}
    >
      {watermark && <Icon iconName={watermark} />}
      <div
        style={{
          fontWeight: 600,
          fontSize: "20px",
          lineHeight: 1.4,
        }}
      >
        {title}
      </div>
      <div>
        {description}
      </div>
      <div>
        {emptyButtons && getEmptyListButtons()}
      </div>
      {
        learnMoreLink && (
        <a
          target="_blank"
          rel="noreferrer"
          href={learnMoreLink}
        >
          {Messages.listTemplate.learnMore()}
        </a>
        )
      }
    </Stack>
  );
};
