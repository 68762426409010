import * as React from "react";
import {
  ICalloutProps,
  IconButton,
  IIconProps,
  ILinkProps,
  ITooltipHostStyles,
  Link,
  mergeStyleSets,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";

const classNames = mergeStyleSets({
  button: {
    display: "table",
    boxSizing: "border-box",
    minWidth: 0,
    height: "20px",
    width: "20px",
    margin: "2px",
    fontSize: "13px",
    lineHeight: 1.385,
    position: "absolute",
  },
  icon: {
    boxSizing: "border-box",
    textAlign: "center",
    verticalAlign: "middle",
    width: "12px !important",
    height: "12px !important",
    padding: "0 important",
    color: "rgb(0,0,0)",
    marginTop: "-5px !important",
    fontSize: "13px !important",
  },
});

export interface CustomLinkProps extends ILinkProps {
  children?: JSX.Element;
  linkText: string;
  testId?: string;
}

export const CustomLink = (
  { children, linkText, testId, ...rest }: CustomLinkProps,
): JSX.Element => {
  const [copied, setCopied] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const tooltipId = useId("tooltip");
  const buttonId = useId("targetButton");

  const showElement = isHovered ? 1 : 0;
  const calloutProps: ICalloutProps = { gapSpace: 0, target: `#${buttonId}` };
  const iconProps: IIconProps = { iconName: "Copy", "aria-describedby": tooltipId, className: classNames.icon };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: {
      display: "flex",
      flex: "auto",
      maxWidth: "24px",
      width: "20px",
      opacity: showElement,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const copyLabelMetaItemValue = (event): void => {
    if (!copied) setCopied(true);
    navigator.clipboard.writeText(linkText);
  };

  const showCopy = (): void => {
    setIsHovered(true);
  };

  const hideCopy = (): void => {
    setIsHovered(false);
    if (copied) setCopied(false);
  };

  const clipboardCopy = (): JSX.Element => (
    <TooltipHost
      content={copied ? Messages.actions.copiedToClipboard() : Messages.actions.copyToClipboard()}
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
    >
      <IconButton
        id={buttonId}
        onClick={copyLabelMetaItemValue}
        className={classNames.button}
        iconProps={iconProps}
        aria-describedby={tooltipId}
        styles={{ root: { opacity: showElement } }}
      />
    </TooltipHost>
  );

  return (
    <Stack
      horizontal
      style={{ display: "flex" }}
      onMouseEnter={showCopy}
      onMouseLeave={hideCopy}
      onFocus={showCopy}
      onBlur={hideCopy}
    >
      <Text
        title={linkText}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Link
          data-test-id={testId}
          {...rest}
        >
          {linkText}
        </Link>
      </Text>
      {children}
      {linkText !== "" && clipboardCopy()}
    </Stack>
  );
};
