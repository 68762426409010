import * as React from "react";
import {
  DirectionalHint,
  FocusZone,
  IButtonStyles,
  IconButton,
  ITooltipHostStyles,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";

export enum ItemStyle {
  /**
   * Vertical layout of content section item
   */
  VERTICAL = "VERTICAL",

  /**
   * Horizontal layout of content section item
   */
  HORIZONTAL = "HORIZONTAL",
}

export interface OverviewContentItem {
  /**
   * TestId of current content item
   *
   */
  testId?: string;

  /**
 * Content item iconName
 */
  iconName?: string;

  /**
   * Content item title
   */
  title: React.ReactNode;

  /**
   * Content item onClick action
   */
  onClick?: () => void;

  /**
   * Whether content item is disabled
   */
  disabled?: boolean;

}

export interface OverviewContentSection {

  /**
   * TestId of current content section
   */
  testId?: string;

  /**
   * Content section title
   */
  title?: string;

  /**
   * Content section items
   */
  items: OverviewContentItem[];

  /**
   * If set, will cycle to the first items once the user navigates to the next target while at the last item
   */
  circularNavigation?: boolean;

  /**
   * Content section item style
   */
  itemStyle?: ItemStyle;
}

export interface InternalOverviewContentSection extends OverviewContentSection {
  focusOnMount?: boolean;
}

const classNames = mergeStyleSets({
  overviewContent: { paddingBottom: "40px" },
  overviewContentSectionTitle: {
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "18px",
  },
  contentRow: {
    paddingLeft: 0,
    marginTop: "-7px",
    marginLeft: "-7px",
    display: "flex",
    flexWrap: "wrap",
  },
  overviewItemIcon: {
    height: "32px",
    width: "32px",
    marginBottom: "9px",
  },
  overviewItemVertical: {
    flexDirection: "column",
    alignItems: "center",
    height: "110px",
    width: "100px",
    flex: "0 0 auto",
    cursor: "pointer",
    textAlign: "center",
    border: "1px solid transparent",
    margin: "0 0 0 7px",
    boxSizing: "border-box",
    ":hover": {
      boxShadow: "0 3.2px 7.2px 0 rgba(0,0,0,.132),0 .6px 1.8px 0 rgba(0,0,0,.108)",
      backgroundColor: "#fff",
      borderColor: "rgba(204,204,204,.8)",
    },
  },
  overviewItemVerticalDisable: {
    flexDirection: "column",
    alignItems: "center",
    height: "110px",
    width: "100px",
    flex: "0 0 auto",
    cursor: "pointer",
    textAlign: "center",
    border: "1px solid transparent",
    margin: "0 0 0 7px",
    boxSizing: "border-box",
    ":hover": { backgroundColor: "#fff" },
  },
  overviewItemHorizontal: {
    display: "flex",
    alignItems: "center",
    height: "60px",
    width: "253px",
    boxSizing: "border-box",
    border: "1px solid transparent",
    marginLeft: "10px",
    cursor: "pointer",
    ":hover": {
      boxShadow: "0 3.2px 7.2px 0 rgba(0,0,0,.132),0 .6px 1.8px 0 rgba(0,0,0,.108)",
      backgroundColor: "#fff",
      borderColor: "rgba(204,204,204,.8)",
    },
  },
  overviewItemHorizontalDisable: {
    display: "flex",
    alignItems: "center",
    height: "60px",
    width: "253px",
    boxSizing: "border-box",
    border: "1px solid transparent",
    marginLeft: "10px",
    cursor: "pointer",
    ":hover": { backgroundColor: "#fff" },
  },
  overviewItemTitle: {
    fontSize: "12px",
    color: "#000000",
  },
  itemToolTipContainer: {
    width: "375px",
    minHeight: "100px",
    padding: "20px 20px 0 20px",
  },
  itemToolTipHeader: { display: "flex" },
  itemToolTipImage: { paddingRight: "15px" },
  itemToolTipTitle: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "20px",
    overflow: "hidden",
  },
  itemToolTipStar: {
    marginLeft: "auto",
    paddingRight: "20px",
  },
  itemToolTipStarIcon: {
    color: "#0078D4",
    font: "14px",
  },
});

const btnVerticalStyles: IButtonStyles = {
  root: {
    width: "100%",
    height: "100%",
  },
  rootHovered: { backgroundColor: "white" },
  rootPressed: { backgroundColor: "white" },
  flexContainer: { flexDirection: "column" },
  icon: {
    height: "auto",
    lineHeight: "normal",
  },
  rootDisabled: {
    backgroundColor: "white",
    filter: "grayscale(100%)",
    opacity: "0.4",
  },
};

const btnHorizontalStyles: IButtonStyles = {
  root: {
    width: "100%",
    height: "100%",
  },
  rootHovered: { backgroundColor: "white" },
  rootPressed: { backgroundColor: "white" },
  flexContainer: { justifyContent: "flex-start" },
  icon: {
    height: "auto",
    lineHeight: "normal",
  },
  rootDisabled: {
    backgroundColor: "white",
    filter: "grayscale(100%)",
    opacity: "0.4",
  },
};

/**
 *
 * OverviewContentSectionTemplate is a component to show the overview content section
 *
 */
export const OverviewContentSectionTemplate = ({
  testId,
  title,
  items,
  circularNavigation,
  focusOnMount,
  itemStyle,
}: InternalOverviewContentSection): JSX.Element => {
  const tooltipId = useId("tooltip");
  const calloutProps = { gapSpace: 15, isBeakVisible: false };
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

  const getItemHtml = (item: OverviewContentItem, index: number, style?: ItemStyle): JSX.Element => {
    const returnVal = (
      <TooltipHost
        calloutProps={calloutProps}
        styles={hostStyles}
        key={`${tooltipId}${item.testId}${index}`}
        delay={TooltipDelay.long}
        directionalHint={DirectionalHint.rightTopEdge}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          key={`overview-item-${item.testId}${index}`}
          role="presentation"
          className={
            ItemStyle.HORIZONTAL === style
              ? item.disabled
                ? classNames.overviewItemHorizontalDisable
                : classNames.overviewItemHorizontal
              : item.disabled
                ? classNames.overviewItemVerticalDisable
                : classNames.overviewItemVertical
          }
        >
          <IconButton
            iconProps={item.iconName
              ? { iconName: item.iconName, styles: { root: { width: "35px", height: "35px" } } }
              : undefined}
            styles={ItemStyle.HORIZONTAL !== style ? btnVerticalStyles : btnHorizontalStyles}
            onClick={item.onClick}
            disabled={item.disabled}
            data-test-id={item.testId}
          >
            <div className={classNames.overviewItemTitle} data-test-id="title">
              {item.title}
            </div>
          </IconButton>
        </div>
      </TooltipHost>
    );
    return returnVal;
  };
  return (
    <div
      className={classNames.overviewContent}
      data-test-id={testId}
    >
      {title && <div className={classNames.overviewContentSectionTitle}>{title}</div>}
      <FocusZone
        className={classNames.contentRow}
        isCircularNavigation={circularNavigation}
        shouldFocusOnMount={focusOnMount}
      >
        {items.map((item, i) => (getItemHtml(item, i, itemStyle)))}
      </FocusZone>
    </div>
  );
};
