import { sleep } from "../utils/internalUtils";
import { FetchApiSignature } from "../models/FetchApiSignature";

// TODO: add what return code we should retry
export default class ResilientFetch<T> {
  private tryNumber: number;
  private internalFetchApi: FetchApiSignature;

  /**
   * Constructor for ResilientFetch. This class helps with making POST requests to console telemetry service. It is
   * resilient because if the call fails it tries again in an exponential backoff manner. Example: initialInterval is 5
   * seconds and factor is 2. Then first call is made after 5 seconds, if it fails then 2nd is made after 5*2 = 10
   * seconds, if this also fails the third call is made after 5*4 = 20 seconds
   * @param initialInterval
   * @param factor
   * @param maxRetries
   * @param fetchApi
   */
  constructor(private initialInterval: number, private factor: number, private maxRetries: number) {
    this.tryNumber = 0;
    this.internalFetchApi = window.fetch;
  }

  /**
   * Function to make a post request to console telemetry service
   * @param records: Data (logs or metrics) we want to send to console telemetry service
   * @param telemetryUrl: Telemetry service URL
   */
  private async makeRequest(records: T[], telemetryUrl: string) {
    const requestPayload: RequestInit = {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(records),
    };

    try {
      const response = await this.internalFetchApi(telemetryUrl, requestPayload);
      if (!response.ok) {
        throw Error(`Response is not OK: ${response.status}`);
      }
    } catch (e) {
      console.debug(`Failed to send request to ${telemetryUrl}: `, e);
      throw e;
    }
  }

  private async processRequest(
    interval: number,
    triesLeft: number,
    request: Request,
  ): Promise<Response> {
    try {
      const response = await this.internalFetchApi(request);
      if (!response.ok && triesLeft > 0) {
        console.info(
          `request failed with status code ${response.status} retrying in ${interval} milliseconds.`,
        );
        await sleep(interval);
        const newInterval = interval * this.factor;
        return this.processRequest(newInterval, triesLeft--, request);
      }
      return response;
    } catch (exception) {
      console.error("Error processing request", exception);
      throw exception;
    }
  }

  /**
   * Function for exponential backoff
   * @param telemetryURL: Telemetry service URL
   * @param records: Data (logs or metrics) we want to send to console telemetry service
   */
  public async process(telemetryURL: string, records: T[]): Promise<boolean> {
    const interval = this.initialInterval * this.factor ** this.tryNumber;
    try {
      await this.makeRequest(records, telemetryURL);
      this.reset();
      return true;
    } catch (e) {
      console.error(e);
    }
    this.tryNumber += 1;
    if (this.tryNumber >= this.maxRetries) {
      this.reset();
      return false;
    }
    await sleep(interval);
    return this.process(telemetryURL, records);
  }

  public getResilientFetch(): FetchApiSignature {
    return (input: string | Request, init?: RequestInit): Promise<Response> => {
      const request = new Request(input, init);
      return this.processRequest(this.initialInterval, this.maxRetries, request);
    };
  }

  /**
   * Resets the tryNumber. This function is called when either the call is successful or fails three times. In case of
   * failure we try a different console telemetry URL hence need to reset tryNumber in that case also.
   */
  public reset() {
    this.tryNumber = 0;
  }

  public setInternalFetchApi(fetchApi: FetchApiSignature) {
    if (fetchApi) {
      this.internalFetchApi = fetchApi;
    }
  }
}
