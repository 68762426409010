import * as React from "react";
import { CommandBarButton, IIconProps, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { uniqueGUID } from "../../helpers/util";
import { ActionBar } from "../Action/ActionBar";
import { ActionType } from "../Action/ActionTypes";
import { CheckBoxVisibility } from "../Listing";
import { CheckBox } from "./CheckBox";
import { Form } from "./Form";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import { InternalFormContext, InternalFormState } from "./FormInternalTypes";
import { FormValues, ValidationState } from "./FormTypes";
import { buildOrderedSelectBuilderTestIds, OrderedSelectBuilderProps } from "./OrderedSelectBuilderTypes";
import { Select, SelectOption } from "./Select";
import { SimpleBuilder } from "./SimpleBuilder";
import { SimpleBuilderComponentRef } from "./SimpleBuilderTypes";

enum ActionBarIds {
  ResetToDefaults = "reset-to-defaults-action",
  MoveUp = "move-up-action",
  MoveDown = "move-down-action",
}

enum FieldIds {
  OrderedSelectBuilder = "osb",
  RowDropdown = "rowDropdown",
  RowCheckbox = "rowCheckbox",
}

enum Direction {
  Down = "down",
  Up = "up",
}

enum CurrentState {
  Idle = "Idle",
  RowAdded = "RowAdded",
  RowClicked = "RowClicked",
  RowDeleted = "RowDeleted",
  RowUpdated = "RowUpdated",
}

const addColumnIcon: IIconProps = { iconName: "Add" };
const actionBarStyles = { root: { ".ms-CommandBar": { borderBottom: 0, paddingBottom: 8 } } };
const addColumnStyles = { root: { display: "flex", padding: "8px auto", marginTop: "-10px !important" } };

export const OrderedSelectBuilder = ({
  fieldName,
  testId,
  validator,
  options,
  defaultValue,
  factoryValues,
  disableAutoRowAddition,
  groupName,
}: OrderedSelectBuilderProps): JSX.Element => {
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("OrderedSelectBuilder should be used within form");
  }

  const { groupName: layoutGroup } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const group = groupName || layoutGroup;
  const [selectList, setSelectList] = React.useState<SelectOption[]>();
  const [simpleBuilderDefaultValue, setSimpleBuilderDefaultValue] = React.useState<FormValues[]>();
  const [simpleBuilderKey, setSimpleBuilderKey] = React.useState(uniqueGUID());
  const [disableAddColumn, setDisableAddColumn] = React.useState(false);
  const [componentRef, setComponentRef] = React.useState<SimpleBuilderComponentRef>();
  const currentStateRef = React.useRef<CurrentState>(CurrentState.Idle);

  const orderedSelectBuilderTestIds = buildOrderedSelectBuilderTestIds(testId);

  const extractInputValue = (
    rawData: FormValues[] | undefined,
  ): FormValues[] | undefined => (rawData?.length ? rawData : undefined);

  const initializeAllValues = (valuesForReset?: string[]): void => {
    const initialDefaultValues = valuesForReset
      ? valuesForReset.map(value => ({
        [FieldIds.RowCheckbox]: false,
        [FieldIds.RowDropdown]: [value],
      }))
      : defaultValue?.map(value => ({
        [FieldIds.RowCheckbox]: false,
        [FieldIds.RowDropdown]: [value],
      }));
    const exposedFormValues = initialDefaultValues?.map(value => value[FieldIds.RowDropdown]);
    const initialList = (options as SelectOption[]).map(option => ({
      id: option.id,
      text: option.text,
      disabled: !!initialDefaultValues?.find(value => value[FieldIds.RowDropdown][0] === option.id) || false,
    })) as SelectOption[];
    performChangesToBuilder(initialDefaultValues, false, exposedFormValues, initialList);
  };

  React.useEffect(() => {
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);
    if (validator) {
      form.registerField(fieldName, group);
    }
    initializeAllValues();

    return function cleanup() {
      if (validator) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
    };
  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (simpleBuilderDefaultValue && simpleBuilderDefaultValue.length >= options.length) setDisableAddColumn(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(simpleBuilderDefaultValue)]);

  const onMoveUp = (): void => {
    if (simpleBuilderDefaultValue) moveRow(Direction.Up, simpleBuilderDefaultValue);
  };

  const onMoveDown = (): void => {
    if (simpleBuilderDefaultValue) moveRow(Direction.Down, simpleBuilderDefaultValue);
  };

  const moveRow = (direction: Direction, defaultValues: FormValues[]): void => {
    const indexOfRowToBeMoved = defaultValues.findIndex(value => value[FieldIds.RowCheckbox]);
    const indexOfAffectedRow = direction === Direction.Up ? indexOfRowToBeMoved - 1 : indexOfRowToBeMoved + 1;
    const newDefaultValues = defaultValues.slice();
    const rowToBeMoved = newDefaultValues[indexOfRowToBeMoved];
    newDefaultValues[indexOfRowToBeMoved] = newDefaultValues[indexOfAffectedRow];
    newDefaultValues[indexOfAffectedRow] = rowToBeMoved;
    const exposedFormValues = newDefaultValues?.map(value => value[FieldIds.RowDropdown]);
    performChangesToBuilder(newDefaultValues, undefined, exposedFormValues);
    currentStateRef.current = CurrentState.RowUpdated;
  };

  const handleResetToDefaults = (): void => {
    initializeAllValues(factoryValues);
    currentStateRef.current = CurrentState.Idle;
  };

  const handleAddColumn = (): void => {
    setDisableAddColumn(true);
    componentRef?.addRow();
    setSimpleBuilderDefaultValue(currentValues => {
      const newRow = { [FieldIds.RowCheckbox]: false, [FieldIds.RowDropdown]: undefined };
      if (currentValues) {
        const newDefaultValues = [...currentValues];
        newDefaultValues.push(newRow);
        return newDefaultValues;
      }
      const addedRow: FormValues[] = [];
      addedRow.push(newRow);
      return addedRow;
    });
    setSimpleBuilderKey(uniqueGUID());
    currentStateRef.current = CurrentState.RowAdded;
  };

  const shouldDisableMoveDown = (): boolean => {
    if (!simpleBuilderDefaultValue) return true;
    const currentChecked = simpleBuilderDefaultValue.filter(value => value[FieldIds.RowCheckbox])[0];
    if (!currentChecked) return true;
    const currentCheckedIndex = simpleBuilderDefaultValue.findIndex(
      value => JSON.stringify(value) === JSON.stringify(currentChecked),
    );
    const nextUncheckedIndex = currentCheckedIndex + 1;
    const nextUnchecked = simpleBuilderDefaultValue[nextUncheckedIndex];
    if (!nextUnchecked) return true;
    const booleanValue = !simpleBuilderDefaultValue[nextUncheckedIndex]?.[FieldIds.RowDropdown];
    return booleanValue;
  };

  const shouldNotAddNewColumn = (values: FormValues[] | undefined): boolean => {
    if (!values) return false;
    if (values.length >= options.length) return true;
    return values.some(value => (
      typeof value[FieldIds.RowCheckbox] === "boolean" && !value[FieldIds.RowDropdown]));
  };

  const performChangesToBuilder = (
    newValue: FormValues[] | undefined,
    disableButton?: boolean,
    exposedFormValues?: string[][],
    newList?: SelectOption[],
  ): void => {
    if (exposedFormValues) {
      if (typeof disableButton === "boolean") setDisableAddColumn(disableButton);
      if (newList) setSelectList(newList);
      setSimpleBuilderDefaultValue(newValue);
      const readableExposedFormValues = exposedFormValues.map(value => value[0]);
      form.setValue(readableExposedFormValues, fieldName, group);
      setSimpleBuilderKey(uniqueGUID());
      return;
    }
    if (typeof disableButton === "boolean") setDisableAddColumn(disableButton);
    setSimpleBuilderDefaultValue(newValue);
    const readableNewValue = newValue?.map(value => value[0]);
    form.setValue(readableNewValue, fieldName, group);
    setSelectList(newList || selectList?.map(item => ({ ...item, disabled: false })));
    setSimpleBuilderKey(uniqueGUID());
  };

  const actions : ActionType[] = [
    {
      testId: orderedSelectBuilderTestIds.resetToDefaults,
      key: ActionBarIds.ResetToDefaults,
      text: Messages.actions.resetToDefaults(),
      onClick: handleResetToDefaults,
    },
    {
      testId: orderedSelectBuilderTestIds.moveUp,
      key: ActionBarIds.MoveUp,
      text: Messages.actions.moveUp(),
      icon: Direction.Up,
      onClick: onMoveUp,
      disabled: !simpleBuilderDefaultValue?.find((value, index) => value[FieldIds.RowCheckbox]
      && value[FieldIds.RowDropdown] && index > 0),
    },
    {
      testId: orderedSelectBuilderTestIds.moveDown,
      key: ActionBarIds.MoveDown,
      text: Messages.actions.moveDown(),
      icon: Direction.Down,
      onClick: onMoveDown,
      disabled: shouldDisableMoveDown,
    },
  ];

  const determineStateFrom = (currentRowValues: FormValues[]): void => {
    if (currentStateRef.current === CurrentState.RowClicked) return;
    if (simpleBuilderDefaultValue && currentRowValues.length < simpleBuilderDefaultValue.length
      && !simpleBuilderDefaultValue.find(value => value[FieldIds.RowDropdown] === undefined)) {
      currentStateRef.current = CurrentState.RowDeleted;
      return;
    }
    if (JSON.stringify(currentRowValues) !== JSON.stringify(simpleBuilderDefaultValue)) {
      currentStateRef.current = CurrentState.RowUpdated;
    }
  };

  const updateSimpleBuilderDefaultValuesWith = (currentRowValues: FormValues[]): void => {
    if (JSON.stringify(simpleBuilderDefaultValue) !== JSON.stringify(currentRowValues)) {
      const checkboxIndeces: number[] = [];
      currentRowValues.forEach((value, index) => {
        if (value[FieldIds.RowCheckbox]) checkboxIndeces.push(index);
      });
      if (checkboxIndeces.length > 1) {
        updateSimpleBuilderUsingValuesFrom(checkboxIndeces);
        return;
      }
      const updatedSelectList = (selectList as SelectOption[]).map(item => ({
        ...item,
        disabled: item.id === currentRowValues.find(row => row[FieldIds.RowDropdown][0] === item.id)
          ?.[FieldIds.RowDropdown][0],
      }));
      if (currentStateRef.current === CurrentState.RowClicked
        && currentRowValues.length !== simpleBuilderDefaultValue?.length) {
        let updatedValues: FormValues[] | undefined;
        const referenceDefaultValue = simpleBuilderDefaultValue?.slice(0, currentRowValues.length);
        if (JSON.stringify(referenceDefaultValue) !== JSON.stringify(currentRowValues)) {
          updatedValues = simpleBuilderDefaultValue?.map((value, index) => {
            if (currentRowValues[index]) {
              return {
                [FieldIds.RowCheckbox]: currentRowValues[index][FieldIds.RowCheckbox],
                [FieldIds.RowDropdown]: currentRowValues[index][FieldIds.RowDropdown],
              };
            }
            return value;
          });
        } else {
          updatedValues = simpleBuilderDefaultValue?.map((value, index) => {
            if (currentRowValues[index]) {
              return value;
            }
            return {
              ...value,
              [FieldIds.RowCheckbox]: !value[FieldIds.RowCheckbox],
            };
          });
        }
        const exposedFormValues = currentRowValues.map(value => value[FieldIds.RowDropdown]);
        const disableButton = shouldNotAddNewColumn(updatedValues);
        performChangesToBuilder(updatedValues, disableButton, exposedFormValues, updatedSelectList);
        currentStateRef.current = CurrentState.RowUpdated;
        return;
      }
      const exposedFormValues = currentRowValues.map(value => value[FieldIds.RowDropdown]);
      const disableButton = shouldNotAddNewColumn(currentRowValues);
      performChangesToBuilder(currentRowValues, disableButton, exposedFormValues, updatedSelectList);
      currentStateRef.current = CurrentState.RowUpdated;
    }
  };

  const updateSimpleBuilderUsingValuesFrom = (checkboxIndeces: number[]): void => {
    const referenceIndex = simpleBuilderDefaultValue?.map((value, index) => value[FieldIds.RowCheckbox] && index)
      .filter(value => Number.isInteger(value))[0];
    const comparatorIndex = checkboxIndeces.find(value => value !== referenceIndex);
    const newDefaultValues = simpleBuilderDefaultValue?.map((value, index) => {
      if (index === comparatorIndex) {
        return { ...value, [FieldIds.RowCheckbox]: true };
      }
      if (index === referenceIndex) {
        return { ...value, [FieldIds.RowCheckbox]: false };
      }
      return value;
    });
    const exposedFormValues = newDefaultValues?.map(value => value[FieldIds.RowDropdown]);
    const disableButton = shouldNotAddNewColumn(newDefaultValues);
    performChangesToBuilder(newDefaultValues, disableButton, exposedFormValues);
    currentStateRef.current = CurrentState.RowUpdated;
  };

  const selectOptions = React.useMemo(() => {
    const optionValues = selectList?.map(select => ({
      id: select.id,
      text: select.text,
      disabled: select.disabled || false,
    })) as SelectOption[];
    return optionValues;
  }, [selectList]);

  const internalValidator = (value: FormValues[] | undefined): string[] | undefined => {
    const newValue: string[] | undefined = value?.map(val => val[FieldIds.RowDropdown]);
    const validation: string[] | undefined = validator?.(newValue);
    form.setFieldState(
      validation && validation?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );
    return validation;
  };

  return (
    <Form>
      <Stack styles={actionBarStyles}>
        <ActionBar actions={actions} />
      </Stack>
      <SimpleBuilder
        testId={testId}
        key={simpleBuilderKey}
        fieldName={FieldIds.OrderedSelectBuilder}
        defaultValue={simpleBuilderDefaultValue}
        validator={internalValidator}
        disableAutoRowAddition={disableAutoRowAddition}
        componentRef={setComponentRef}
        maxRows={options.length}
        onChange={(value: FormValues[] | undefined) => {
          if ((currentStateRef.current === CurrentState.RowAdded)
          || (currentStateRef.current === CurrentState.RowUpdated)) {
            currentStateRef.current = CurrentState.Idle;
            return;
          }
          if (!value) {
            if (currentStateRef.current === CurrentState.RowClicked) {
              setSimpleBuilderDefaultValue(() => {
                const newDefaultValue = [{ [FieldIds.RowCheckbox]: false, [FieldIds.RowDropdown]: undefined }];
                return newDefaultValue;
              });
              currentStateRef.current = CurrentState.Idle;
              return;
            }
            performChangesToBuilder(value, false);
            currentStateRef.current = CurrentState.Idle;
          }
          if (value) {
            const currentRowValues = value.map(row => ({
              [FieldIds.RowCheckbox]: row[FieldIds.RowCheckbox] || false,
              [FieldIds.RowDropdown]: row[FieldIds.RowDropdown]?.[0]?.id
                ? [row[FieldIds.RowDropdown]?.[0]?.id] : undefined,
            }));
            determineStateFrom(currentRowValues);
            updateSimpleBuilderDefaultValuesWith(currentRowValues);
          }
        }}
      >
        <CheckBox
          fieldName={FieldIds.RowCheckbox}
          checkboxVisibility={CheckBoxVisibility.Hover}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onChange={value => {
            currentStateRef.current = CurrentState.RowClicked;
          }}
          width={30}
          minWidth={30}
        />
        <Select
          fieldName={FieldIds.RowDropdown}
          options={selectOptions}
        />
      </SimpleBuilder>
      <CommandBarButton
        data-test-id={orderedSelectBuilderTestIds.addColumn}
        onClick={handleAddColumn}
        text={Messages.actions.addColumn()}
        iconProps={addColumnIcon}
        disabled={disableAddColumn}
        styles={addColumnStyles}
      />
    </Form>
  );
};
