/* eslint-disable object-shorthand */
import TelemetryProcessor from "../core/TelemetryProcessor";
import {
  getConsoleTelemetryAPIEndpoint,
  getConsoleTelemetryStoreName,
  logToBrowser,
  removeAuthInfo,
  validateDimensions,
} from "../utils/internalUtils";
import {
  Metric,
  Log,
  LogLevel,
  LogMetadata,
  Transport,
  TokenAuthenticator,
  TelemetryClientData,
  TelemetryType,
} from "../models";
import { TIMER_INTERVAL, SHORT_TIME_INTERVAL, DEFAULT_MESSAGE_TTL } from "../utils/constants";

export type ConsoleTelemetryConfig = {
  serviceLimit: number;
  maxRetries: number;
  realm: string;
  realmToTelemetryURLs: Record<string, string[]>;
  logToBrowsersConsole?: boolean;
  postToTelemetryService?: boolean;
};

export class ConsoleTelemetryTransport implements Transport {
  private region: string;
  private userSession: string;
  private config: ConsoleTelemetryConfig;
  private telemetryURLs?: string[];

  private metricTelemetryProcessor: TelemetryProcessor<Metric>;
  private logTelemetryProcessor: TelemetryProcessor<Log>;
  private logFastTelemetryProcessor: TelemetryProcessor<Log>;

  public isInitialized() {
    return !!this.config;
  }

  public setRegion(region: string) {
    this.region = region;
  }

  public setAuthInstance(authInstance: TokenAuthenticator) {
    this.userSession = authInstance.SessionId;

    // update processors
    this.logProcessor().setAuthInstance(authInstance);
    this.logFastProcessor().setAuthInstance(authInstance);
    this.metricProcessor().setAuthInstance(authInstance);
  }

  public constructor(config: ConsoleTelemetryConfig) {
    this.config = { ...config };
    if (this.config.postToTelemetryService === undefined) {
      this.config.postToTelemetryService = true;
    }
    const telemetryURLs = this.config.realmToTelemetryURLs[this.config.realm];
    if (telemetryURLs) {
      this.telemetryURLs = telemetryURLs;
    }
  }

  private getTelemetryProcessor<T>(
    timeInterval: number = TIMER_INTERVAL,
    telemetryType: TelemetryType = "Logs",
  ): TelemetryProcessor<T> {
    if (!this.isInitialized) {
      throw new Error("Initialize TelemetryClient before using the logger");
    }
    return new TelemetryProcessor<T>({
      storeName: getConsoleTelemetryStoreName(telemetryType),
      timeInterval: timeInterval,
      recordProcessor: this.getRecordProcessor(telemetryType),
      APIEndpoint: getConsoleTelemetryAPIEndpoint(telemetryType),
      maxRetries: this.config.maxRetries,
      serviceLimit: this.config.serviceLimit,
      telemetryURLs: this.telemetryURLs,
    });
  }

  private logProcessor(): TelemetryProcessor<Log> {
    if (!this.logTelemetryProcessor) {
      this.logTelemetryProcessor = this.getTelemetryProcessor<Log>();
    }
    return this.logTelemetryProcessor;
  }

  private logFastProcessor(): TelemetryProcessor<Log> {
    if (!this.logFastTelemetryProcessor) {
      this.logFastTelemetryProcessor = this.getTelemetryProcessor<Log>(SHORT_TIME_INTERVAL);
    }
    return this.logFastTelemetryProcessor;
  }

  private metricProcessor(): TelemetryProcessor<Metric> {
    if (!this.metricTelemetryProcessor) {
      this.metricTelemetryProcessor = this.getTelemetryProcessor<Metric>(
        SHORT_TIME_INTERVAL,
        "Metrics",
      );
    }
    return this.metricTelemetryProcessor;
  }

  private getRecordProcessor(telemetryType: TelemetryType) {
    switch (telemetryType) {
      case "Metrics":
        return this.metricRecordProcessor.bind(this);
      case "Logs":
      default:
        return this.addTenantInfoOnceAuthenticated.bind(this);
    }
  }

  /**
   * Information to add to the log once the user authenticates
   * @param payload: log
   */
  private addTenantInfoOnceAuthenticated(payload: Log): Log {
    payload = {
      currentPage: removeAuthInfo(window.location.href),
      event: "LogEvent",
      sessionId: this.userSession,
      ts: new Date().getTime(),
      telemetryRegion: this.region,
      ...payload,
    };
    return payload;
  }

  /**
   * We don't need to add any information post authentication, hence just return. Might need it in future
   * @param metric
   */
  private metricRecordProcessor(metric: Metric): Metric {
    metric = {
      telemetryRegion: this.region,
      ...metric,
    };
    return metric;
  }

  processLog(logLevel: LogLevel, message: string | Error, logMetadata?: LogMetadata): void {
    if (!this.isInitialized) {
      return;
    }
    if (this.config.logToBrowsersConsole) {
      logToBrowser(logLevel, message);
    }
    if (!this.config.postToTelemetryService) {
      return;
    }
    const log: Log = {
      logLevel: logLevel,
      msg: message,
      ...logMetadata,
    };
    const data: TelemetryClientData<Log> = {
      TTL: new Date().getTime() + DEFAULT_MESSAGE_TTL,
      payload: log,
    };
    if (logLevel === LogLevel.Error) {
      this.logFastProcessor().add(data);
    } else {
      this.logProcessor().add(data);
    }
  }

  processMetric(name: string, value: number, dimensions?: Record<string, string>): void {
    validateDimensions(dimensions);
    const metric: Metric = {
      name,
      value,
      dimensions,
      timestamp: Date.now(),
    };
    if (!this.config || !this.config.postToTelemetryService) {
      console.debug(`metrics - ${name}:`, metric);
      return;
    }
    const data: TelemetryClientData<Metric> = {
      TTL: new Date().getTime() + DEFAULT_MESSAGE_TTL,
      payload: metric,
    };
    this.metricProcessor().add(data);
  }
}
