import * as React from "react";
import { mergeStyleSets } from "@fluentui/react";
import { OverviewContentSection, OverviewContentSectionTemplate } from "./OverviewContentSectionTemplate";
import {
  OverviewGettingStartedSection,
  OverviewGettingStartedSectionTemplate,
} from "./OverviewGettingStartedSectionTemplate";
import { OverviewInfoBlockSection, OverviewInfoBlockSectionTemplate } from "./OverviewInfoBlockSectionTemplate";
import { OverviewLinkSection, OverviewLinkSectionTemplate } from "./OverviewLinkSectionTemplate";
import {
  OverviewRecentViewResourceSectionTemplate,
  OverviewRecentViewSection,
} from "./OverviewRecentViewResourceSectionTemplate";

export interface OverviewInitialFocus {
  /**
   * Index of the section to get the initail focus.
   * The first focusable item in that section will get the initial focus.
   * Currently only content sections are supported.
   */
  sectionNdx: number;
}
export interface OverviewTemplateProps<T> {

  /**
   * TestId of current template
   */
  testId?: string;

  /**
   * Overview info block Section component
   */
  infoBlock?: OverviewInfoBlockSection;

  /**
   * Getting started sections component
   */
  gettingStartedSections?: OverviewGettingStartedSection[];

  /**
   * Overview content sections component
   */
  contentSections?: Array<OverviewContentSection | OverviewRecentViewSection<T>>;

  /**
   * Overview link sections component
   */
  linkSections?: OverviewLinkSection[];

  /**
   * Specify which section has initial focus
   */
  initialFocus?: OverviewInitialFocus;
}

const classNames = mergeStyleSets({
  overviewTemplateContainer: {
    padding: "24px 24px",
    fontSize: "13px",
    maxWidth: "1063px",
    margin: "0 auto",
  },
});

/**
 * OverviewTemplate is a component to build the overview page
 *
 */
export const OverviewTemplate = <T extends object > ({
  testId,
  linkSections,
  contentSections,
  infoBlock,
  gettingStartedSections,
  initialFocus,
}: OverviewTemplateProps<T>): JSX.Element => (
  <div
    className={classNames.overviewTemplateContainer}
    data-test-id={testId}
  >
    <main>
      {infoBlock && (
        <OverviewInfoBlockSectionTemplate
          testId={infoBlock.testId}
          message={infoBlock.message}
          messageType={infoBlock.messageType}
          actionButtons={infoBlock.actionButtons}
          isMultiline={infoBlock.isMultiline}
        />
      )}
      {(gettingStartedSections && gettingStartedSections.length > 0) && (
        <section>
          {gettingStartedSections.map(
            (section, i) => (
              <OverviewGettingStartedSectionTemplate
                title={section.title}
                key={`getting-started-section-${i.toString()}`}
                testId={section.testId}
                items={section.items}
              />
            ),
          )}
        </section>
      )}
      {(contentSections && contentSections.length > 0) && (
        <section
          id="overview-main-content-wrapper"
        >
          {contentSections.map(
            (section, i) => {
              if ((section as OverviewContentSection).items) {
                return (
                  <OverviewContentSectionTemplate
                    title={section.title}
                    key={`overview-content-section-${i.toString()}`}
                    testId={section.testId}
                    items={(section as OverviewContentSection).items}
                    itemStyle={(section as OverviewContentSection).itemStyle}
                    circularNavigation={(section as OverviewContentSection).circularNavigation}
                    focusOnMount={initialFocus?.sectionNdx === i}
                  />
                );
              }
              return (
                <OverviewRecentViewResourceSectionTemplate
                  key={`overview-content-section-${i.toString()}`}
                  title={section.title}
                  content={(section as OverviewRecentViewSection<T>).content}
                />
              );
            },
          )}
        </section>
      )}
      {(linkSections && linkSections.length > 0) && (
        <section>
          {linkSections.map(
            (item, i) => (
              <OverviewLinkSectionTemplate
                title={item.title}
                key={`overview-link-section-${i.toString()}`}
                testId={item.testId}
                items={item.items}
              />
            ),
          )}
        </section>
      )}
    </main>
  </div>
  );

export {
  OverviewInfoBlockStatus, OverviewInfoBlockSection, OverviewInfoBlockButton,
} from "./OverviewInfoBlockSectionTemplate";
export {
  ItemStyle, OverviewContentItem, OverviewContentSection,
} from "./OverviewContentSectionTemplate";
export {
  buildOverviewGettingStartedItemTestIds,
  OverviewGettingStartedItem,
  OverviewGettingStartedSection,
  OverviewGettingStartedItemTestIds,
} from "./OverviewGettingStartedSectionTemplate";
export { OverviewLinkItem, OverviewLinkSection } from "./OverviewLinkSectionTemplate";
export {
  OverviewRecentViewSection,
  RecentViewContent,
} from "./OverviewRecentViewResourceSectionTemplate";
