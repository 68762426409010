/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { ApiClientMethod, SerializableResponseWithData, TimeInterval, useQuery } from "savant-connector";
import { IconButton, IIconProps, mergeStyleSets, registerIcons } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { optimizedRetryOption, uniqueGUID } from "../../helpers/util";
import { InProgress } from "../Svg/icons";

/* eslint-disable no-underscore-dangle */
export enum NotificationType {
  IN_PROGRESS = "IN-PROGRESS",
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export enum NotificationMode {
  NO_TOAST = "NO_TOAST",
  TOAST_ONLY = "TOAST_ONLY",
  WITH_TOAST = "WITH_TOAST",
}

export interface NotificationRequest {
  unitId?: string;
  type: NotificationType;
  mode?: NotificationMode;
  title: string;
  message: string;
  apiError?: string;
}

export interface Notification extends NotificationRequest {
  id: string;
  timestamp: number;
  onlyToast?: boolean;
  toastShown: boolean;
  read: boolean;
}

export enum PollingAction {
  CONTINUE = "CONTINUE",
  STOP_SUCCESS = "STOP-SUCCESS",
  STOP_FAILURE = "STOP-FAILURE",
}

export interface PolledResponseResult {
  pollingAction: PollingAction;
  apiError?: string;
}

export type PolledResponseCallback = (resp: any, status?: number) => PolledResponseResult;

export type AsyncNotificationMethodProvider = (key: string, ociRegion: string) => ApiClientMethod<any, any> | undefined;
export type AsyncNotificationPolledResponseProvider = (key: string) => PolledResponseCallback | undefined;

export interface AsyncNotificationRequest {
  /**
   * An id to identify notifications that are part of the same unit of work
   * (e.g. in-progress followed by either success or failure for the same operation)
   */
  unitId?: string;
  /**
   * The key identifying the method to query.
   * The response of that query is passed to the polled response callback for deciding to stop or to continue polling.
   */
  methodKey: string;
  /**
   * Arguments to be passed in the HTTP request
   */
  args: any;
  /**
   * The region to target via the URL
   */
  ociRegion: string;
  /**
   * Delay in ms before initial request is made
   */
  delay?: TimeInterval;
  /**
   * If the PollingAction is to continue,
   * this is the interval between submitting a request
   */
  pollingInterval?: TimeInterval;
  /**
   * The key identifying the PolledResponseCallback
   */
  polledResponseKey: string;
  /**
   * Array of HTTP statuses that bypass default error flow
   * and ensure the status gets passed to the PolledResponseCallback.
   * This allows the consumer to deal with handling the status.
   */
  errorStatusOverrides?: number[];
  /**
   * The message to display to the user when the polled response is marked as CONTINUE
   */
  onProgress?: {
    title: string;
    message: string;
  },
  /**
   * The message to display to the user when the polled response is marked as STOP_SUCCESS
   */
  onSuccess: {
    title: string;
    message: string;
  },
  /**
   * The message to display to the user when the polled response is marked as STOP_FAILURE
   */
  onFailure: {
    title: string;
    message: string;
  },
}

export interface AsyncNotification extends AsyncNotificationRequest {
  id: string;
  isOld?: boolean;
}

const StorageNotifKey = "O4A-NOTIF";

/**
 * Notification manager
 */
class NotificationManager {
  private _notifications: Notification[] = [];

  private _notificationListener!: (notifications: Notification[]) => void;

  private _asyncNotificationMethodProvider: AsyncNotificationMethodProvider;

  private _asyncNotificationPolledResponseProvider: AsyncNotificationPolledResponseProvider;

  private _asyncNotifications: AsyncNotification[] = [];

  private _asyncNotificationListener!: (asyncNotifications: AsyncNotification[]) => void;

  constructor() {
    this._asyncNotificationMethodProvider = () => undefined;
    this._asyncNotificationPolledResponseProvider = () => undefined;
  }

  public init = (
    asyncMethodProvider: AsyncNotificationMethodProvider,
    asyncResponseProvider: AsyncNotificationPolledResponseProvider,
  ): void => {
    this._asyncNotificationMethodProvider = asyncMethodProvider;
    this._asyncNotificationPolledResponseProvider = asyncResponseProvider;
    const notifStored = window.localStorage.getItem(StorageNotifKey);
    if (notifStored) {
      this._asyncNotifications = JSON.parse(notifStored);
      this._asyncNotifications.forEach(entry => { entry.isOld = true; });
      this._asyncNotificationListener?.([...this._asyncNotifications]);
    }
  };

  public submitAsync = (asyncNotificationRequest: AsyncNotificationRequest): string => {
    const id = uniqueGUID();
    const asyncNotification = {
      ...asyncNotificationRequest,
      ...{ id },
    } as AsyncNotification;
    this._asyncNotifications.push(asyncNotification);
    this._asyncNotificationListener?.([...this._asyncNotifications]);
    return id;
  };

  public submit = ({ mode = NotificationMode.WITH_TOAST, ...rest }: NotificationRequest): string => {
    const id = `notif-${uniqueGUID()}`;
    const notification = {
      ...rest,
      ...{
        id,
        mode,
        timestamp: Date.now(),
        toastShown: mode === NotificationMode.NO_TOAST, // By setting this flag no toast will be shown
        read: false,
      },
    } as Notification;
    if (notification.unitId) {
      const ndx = this._notifications.findIndex(entry => entry.unitId === notification.unitId);
      if (ndx >= 0) {
        this._notifications.splice(ndx, 1);
      }
    }
    this._notifications.push(notification);
    this._notificationListener?.([...this._notifications]);
    return id;
  };

  public dismissAsync = (id: string): void => {
    const ndx = this._asyncNotifications.findIndex(entry => entry.id === id);
    if (ndx >= 0) {
      this._asyncNotifications.splice(ndx, 1);
    }
  };

  public dismissAll = (): void => {
    this._notifications = [];
    this._notificationListener?.([...this._notifications]);
  };

  public dismissByType = (type: NotificationType): number => {
    this._notifications = this._notifications.filter(entry => entry.type !== type);
    this._notificationListener?.([...this._notifications]);
    return this._notifications.length;
  };

  public dismiss = (id: string): number => {
    const notificationNdx = this._notifications.findIndex(entry => entry.id === id);
    if (notificationNdx >= 0) {
      this._notifications.splice(notificationNdx, 1);
      this._notificationListener?.([...this._notifications]);
    }
    return this._notifications.length;
  };

  public markAllRead = (): void => {
    let hadUnread = false;
    this._notifications.forEach(entry => {
      if (!entry.read) {
        entry.read = true;
        hadUnread = true;
      }
    });
    if (hadUnread) {
      this._notificationListener?.([...this._notifications]);
    }
  };

  public markRead = (id: string): void => {
    const notification = this._notifications.find(entry => entry.id === id);
    if (notification && !notification.read) {
      notification.read = true;
      this._notificationListener?.([...this._notifications]);
    }
  };

  public markToastShown = (id: string): void => {
    const notification = this._notifications.find(entry => entry.id === id);
    if (notification && !notification.toastShown) {
      notification.toastShown = true;
      this._notificationListener?.([...this._notifications]);
    }
  };

  public get notifications(): Notification[] { return this._notifications; }

  public get asyncNotifications(): AsyncNotification[] { return this._asyncNotifications; }

  public get asyncNotificationMethodProvider(): AsyncNotificationMethodProvider {
    return this._asyncNotificationMethodProvider;
  }

  public get asyncNotificationPolledResponseProvider(): AsyncNotificationPolledResponseProvider {
    return this._asyncNotificationPolledResponseProvider;
  }

  public addNotificationListener = (listener: (notifications: Notification[]) => void): void => {
    this._notificationListener = listener;
  };

  public addAsyncNotificationListener = (
    listener: (asyncNotifications: AsyncNotification[]) => void,
  ): void => {
    this._asyncNotificationListener = listener;
  };
}

const notificationManager = new Proxy(new NotificationManager(), {
  get: (target, prop) => {
    const targetProp = prop in target ? target[prop as keyof typeof target] : undefined;
    if (typeof targetProp === "function"
      && (prop === "submitAsync" || prop === "dismissAsync" || prop === "asyncNotifications")
    ) {
      return new Proxy(targetProp, {
        apply: (funcTarget, thisArg, argumentsList) => {
          const result = Reflect.apply(funcTarget, thisArg, argumentsList);
          window.localStorage.setItem(StorageNotifKey, JSON.stringify(Reflect.get(target, "_asyncNotifications")));
          return result;
        },
      });
    }
    return Reflect.get(target, prop);
  },
});

export const initNotificationManager = (
  asyncMethodProvider: AsyncNotificationMethodProvider,
  asyncResponseProvider: AsyncNotificationPolledResponseProvider,
): void => {
  notificationManager.init(asyncMethodProvider, asyncResponseProvider);
};

/**
 * Notification Provider
 */

export interface NotificationsState {
  notifications: Notification[];
  unreadCount: number;
  inProgressCount: number;
}

export interface NotificationProviderProps {
  children: React.ReactNode;
}

const NotificationContext = React.createContext<NotificationsState>({} as NotificationsState);
export const NotificationProvider = ({ children }: NotificationProviderProps): JSX.Element => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  React.useMemo(() => {
    notificationManager.addNotificationListener((notifs: Notification[]): void => {
      setNotifications(notifs);
    });
  }, []);

  const notificationsState = React.useMemo(() => (
    { notifications, unreadCount: getUnreadCount(notifications), inProgressCount: getInProgressCount(notifications) }
  ), [notifications]);

  return (
    <NotificationContext.Provider value={notificationsState}>
      {children}
    </NotificationContext.Provider>
  );
};

const getUnreadCount = (notifications: Notification[]): number => notifications.reduce((acc, notification) => (
  notification.read || notification.onlyToast ? acc : ++acc
), 0);

const getInProgressCount = (notifications: Notification[]): number => notifications.reduce((acc, notification) => (
  notification.type !== NotificationType.IN_PROGRESS || notification.onlyToast ? acc : ++acc
), 0);

/**
 * useNotificationRequest hook
 */

export type Submit = (notificationRequest: NotificationRequest) => string;
export type SubmitAsync = (asyncNotificationRequest: AsyncNotificationRequest) => string;

export type NotificationRequestHandlers = {
  submit: Submit;
  submitAsync: SubmitAsync;
};

export const useNotificationRequest = (): NotificationRequestHandlers => {
  const submit = (
    notificationRequest: NotificationRequest,
  ): string => notificationManager.submit(notificationRequest);

  const submitAsync = (
    asyncNotificationRequest: AsyncNotificationRequest,
  ): string => notificationManager.submitAsync(asyncNotificationRequest);

  return { submit, submitAsync };
};

/**
 * useNotification hook
 */

export type MarkAllRead = () => void;
export type MarkRead = (id: string) => void;
export type MarkToastShown = (id: string) => void;
export type DismissAll = () => void;
export type DismissByType = (type: NotificationType) => number;
export type Dismiss = (id: string) => number;

export interface NotificationHandlers extends NotificationsState {
  markAllRead: MarkAllRead;
  markRead: MarkRead;
  markToastShown: MarkToastShown;
  dismissAll: DismissAll;
  dismissByType: DismissByType;
  dismiss: Dismiss;
}

export const useNotification = (): NotificationHandlers => {
  const notificationsState = React.useContext(NotificationContext);
  const markAllRead = (): void => notificationManager.markAllRead();
  const markRead = (id: string): void => notificationManager.markRead(id);
  const markToastShown = (id: string): void => notificationManager.markToastShown(id);
  const dismissAll = (): void => notificationManager.dismissAll();
  const dismissByType = (type: NotificationType): number => notificationManager.dismissByType(type);
  const dismiss = (id: string): number => notificationManager.dismiss(id);

  return {
    notifications: notificationsState.notifications,
    unreadCount: notificationsState.unreadCount,
    inProgressCount: notificationsState.inProgressCount,
    markAllRead,
    markRead,
    markToastShown,
    dismissAll,
    dismissByType,
    dismiss,
  };
};

/**
 * Notification Card
 */

registerIcons({ icons: { "inProgress-svg": InProgress } });

const getNotificationIconProps = (type: NotificationType): IIconProps => {
  switch (type) {
    case NotificationType.FAILURE:
      return { iconName: "AlertSolid", styles: { root: { color: "#a4262c" } } };
    case NotificationType.SUCCESS:
      return { iconName: "CompletedSolid", styles: { root: { color: "#57a300" } } };
    case NotificationType.INFO:
      return { iconName: "AlertSolid", styles: { root: { color: "#015cda", transform: "rotate(180deg)" } } };
    case NotificationType.IN_PROGRESS:
    default:
      return { iconName: "inProgress-svg" };
  }
};
export interface NotificationCardProps extends NotificationRequest {
  onClose?: () => void;
}

const classNames = mergeStyleSets({ apiErrorMsg: { wordBreak: "break-word" } });

export const NotificationCard = ({
  type,
  title,
  message,
  apiError,
  onClose,
}: NotificationCardProps): JSX.Element => (
  <div style={{ padding: "10px 5px 10px 10px" }}>
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <IconButton
        iconProps={getNotificationIconProps(type)}
        role="presentation"
        styles={{ root: { height: "16px", width: "16px", marginRight: "4px" } }}
      />
      <span
        style={{
          flex: "1",
          margin: "0 4px",
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "19px",
        }}
      >
        {title}
      </span>
      <IconButton
        iconProps={{ iconName: "Cancel", styles: { root: { color: "rgb(96, 94, 92)" } } }}
        aria-label={Messages.ariaLabel.dismissNotification()}
        styles={{ root: { height: "14px", width: "10px", paddingRight: "10px" } }}
        onClick={() => onClose?.()}
      />
    </div>
    <div style={{ fontSize: "13px", lineHeight: "18px", paddingTop: "5px" }}>{message}</div>
    {apiError && <div className={classNames.apiErrorMsg}>{Messages.notifications.apiErrorMsg(apiError)}</div>}
  </div>
);

/**
 * Async Notification Monitor
 */

interface AsyncNotificationJobProps extends AsyncNotification {
  onDismiss: (id: string) => void;
}

const AsyncNotifcationJob = ({
  isOld,
  unitId,
  id,
  methodKey,
  args,
  ociRegion,
  delay = TimeInterval.sm,
  pollingInterval = TimeInterval.md,
  polledResponseKey,
  errorStatusOverrides,
  onProgress,
  onSuccess,
  onFailure,
  onDismiss,
}: AsyncNotificationJobProps): JSX.Element => {
  const [refreshed, setRefreshed] = React.useState<boolean>(false);
  // start polling right away if loaded from local storage
  const [startPolling, setStartPolling] = React.useState<boolean>(isOld || false);

  const [old, setOld] = React.useState<boolean | undefined>(isOld);

  const asyncMethod = notificationManager.asyncNotificationMethodProvider(methodKey, ociRegion);
  const onPolledResponse = notificationManager.asyncNotificationPolledResponseProvider(polledResponseKey);
  const { loading, error, response, refresh } = useQuery({
    wait: !startPolling,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    method: asyncMethod!,
    options: {
      args,
      caching: { type: "polling", pollingInterval, refreshOnMount: true }, // refreshOnMount not working
      retry: optimizedRetryOption,
    },
  });

  const { submit } = useNotificationRequest();
  const { markRead } = useNotification();

  const executePollingAction = (polledResponseResult: PolledResponseResult | undefined): void => {
    switch (polledResponseResult?.pollingAction) {
      case PollingAction.STOP_SUCCESS:
        onDismiss(id);
        if (!old) {
          submit({
            unitId,
            type: NotificationType.SUCCESS,
            title: onSuccess.title,
            message: onSuccess.message,
          } as NotificationRequest);
        }
        break;
      case PollingAction.STOP_FAILURE:
        onDismiss(id);
        if (!old) {
          submit({
            unitId,
            type: NotificationType.FAILURE,
            title: onFailure.title,
            message: onFailure.message,
            apiError: polledResponseResult.apiError,
          } as NotificationRequest);
        }
        break;
      case PollingAction.CONTINUE:
      default:
        if (old && unitId) {
          setOld(false);
          const inProgressId = submit({
            unitId,
            type: NotificationType.IN_PROGRESS,
            mode: NotificationMode.NO_TOAST,
            title: onProgress?.title,
            message: onProgress?.message,
          } as NotificationRequest);
          markRead(inProgressId);
        }
        break;
    }
  };

  React.useEffect(() => {
    if (error) {
      if (errorStatusOverrides?.includes(error.status)) {
        const polledResponseResult = onPolledResponse?.(undefined, error.status);
        executePollingAction(polledResponseResult);
      } else {
        onDismiss(id);
        // report failure to check
        submit({
          unitId,
          type: NotificationType.FAILURE,
          title: Messages.notifications.pollingErrorTitle(),
          message: Messages.notifications.pollingErrorMessage(),
          apiError: error.body.message,
        } as NotificationRequest);
      }
    }
  // Workaround for shallow comparison
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(error)]);

  React.useEffect(() => {
    if (!startPolling) {
      setTimeout(() => {
        setStartPolling(true);
      }, delay);
    } else if (!refreshed) {
      // *** Workaround for refreshOnMount not working ***
      // Force a refresh to avoid using cached data
      // only check the response after the manual refresh
      refresh();
      setRefreshed(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPolling, refreshed]);

  React.useEffect(() => {
    const resp = response as SerializableResponseWithData<any>;
    if (refreshed && !loading && !error && resp) {
      const polledResponseResult = onPolledResponse?.(resp.data, resp.response.status);
      executePollingAction(polledResponseResult);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, loading, error, refreshed]);

  return <div />;
};

export const AsyncNotificationMonitor = (): JSX.Element => {
  const [asyncNotifications, setAsyncNotifications] = React.useState<AsyncNotification[]>([]);

  React.useEffect(() => {
    notificationManager.addAsyncNotificationListener((asyncNotifs: AsyncNotification[]): void => {
      setAsyncNotifications(asyncNotifs);
    });
    setAsyncNotifications(notificationManager.asyncNotifications);
  }, []);

  const onDismiss = (id: string): void => {
    notificationManager.dismissAsync(id);
    setAsyncNotifications([...notificationManager.asyncNotifications]);
  };

  const asyncNotificationJobs = React.useMemo(
    () => asyncNotifications
      .filter(
        entry => notificationManager.asyncNotificationMethodProvider(entry.methodKey, entry.ociRegion)
            && notificationManager.asyncNotificationPolledResponseProvider(entry.polledResponseKey),
      )
      .map(entry => (
        <AsyncNotifcationJob
          key={entry.id}
          isOld={entry.isOld}
          unitId={entry.unitId}
          id={entry.id}
          methodKey={entry.methodKey}
          args={entry.args}
          errorStatusOverrides={entry.errorStatusOverrides}
          ociRegion={entry.ociRegion}
          delay={entry.delay}
          pollingInterval={entry.pollingInterval}
          polledResponseKey={entry.polledResponseKey}
          onProgress={entry.onProgress}
          onSuccess={entry.onSuccess}
          onFailure={entry.onFailure}
          onDismiss={onDismiss}
        />
      )),
    [asyncNotifications],
  );

  return (
    <div>
      {asyncNotificationJobs}
    </div>
  );
};
