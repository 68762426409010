import * as React from "react";
import { Icon, IIconStyles, IStackStyles, registerIcons, Stack } from "@fluentui/react";
import { uniqueGUID } from "../../helpers/util";
import { FormattedString } from "../FormattedString";
import { ErrorResourceSvg } from "../Svg/icons";

export interface ErrorViewProps {
  /**
   * Title of the error to be shown
   */
  errorTitle: string;
  /**
   * ID of the resource that showed an error
   */
  resourceId: string;
  /**
   * Http status code of the error
   */
  errorCode: number;
  /**
   * Error details and/or description
   */
  details: string[];
}

registerIcons({ icons: { "error-res-svg": ErrorResourceSvg } });
const errorViewStackStyles: IStackStyles = {
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    height: "100%",
    boxSizing: "border-box",
    maxWidth: 715,
    fontSize: 13,
    margin: "0 auto",
  },
};
const summaryStackStyles: IStackStyles = {
  root: {
    color: "#323130",
    borderColor: "#d6d6d6",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderStyle: "solid",
    overflowX: "hidden",
    overflowY: "auto",
    flex: "0 1 auto",
    margin: "10px 0",
    width: 715,
  },
};

const iconStyles: IIconStyles = {
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    justifyContent: "center",
    flex: "0 1 auto",
    padding: 10,
  },
};
const summaryListItemStyle = {
  margin: "0 10px 10px 0",
  width: "100%",
};

/**
 * The ErrorView component handles rendering a summary of the current error
 */
export const ErrorView = ({
  errorTitle,
  resourceId,
  errorCode,
  details,
}: ErrorViewProps): JSX.Element => {
  const summaryListItems = [
    {
      key: "resId",
      label: "Resource ID",
      value: resourceId,
    },
    {
      key: "errorCode",
      label: "Error code",
      value: errorCode,
    },
  ];
  return (
    <Stack style={{ padding: 20, boxSizing: "border-box", cursor: "default" }}>
      <Stack styles={errorViewStackStyles}>
        <Icon iconName="error-res-svg" styles={iconStyles} />
        <div style={{ fontSize: 18, textAlign: "center", flex: "0 0 auto", marginBottom: 14 }}>{errorTitle}</div>
        <Stack styles={summaryStackStyles}>
          <h4 style={{ margin: 15, display: "flex", alignItems: "center", fontWeight: 600 }}>Summary</h4>
          <ul style={{
            listStyleType: "none",
            padding: 0,
            margin: "0 15px",
            display: "flex",
            flexFlow: "column wrap",
            alignContent: "flex-start",
          }}
          >
            {summaryListItems.map(item => (
              <li key={item.key} style={summaryListItemStyle}>
                <label style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: "#646464",
                  whiteSpace: "nowrap",
                }}
                >
                  {item.label}
                </label>
                <div style={{
                  overflow: "hidden",
                  whiteSpace: "pre-wrap",
                  color: "#323130",
                }}
                >
                  {item.value}
                </div>
              </li>
            ))}
          </ul>
          <div style={{ margin: 15 }}>
            <label style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#646464",
              whiteSpace: "nowrap",
            }}
            >
              Details
            </label>
            {details.map(detail => (
              <div key={uniqueGUID()}>
                <div style={{ color: "#323130" }}>{FormattedString({ inputText: detail }) as unknown as string}</div>
                <br />
              </div>
            ))}
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};
