import * as React from "react";
import { CommandBarButton, IOverflowSetItemProps, OverflowSet, ResizeGroup, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { TagItem, TagProps } from "./TagItem";

export interface TagsDisplayProps {
  /**
   * Tags to be displayed
   */
  tags: TagProps[];
}

interface OverflowData {
  primary: IOverflowSetItemProps[];
  overflow: IOverflowSetItemProps[];
}

const expandButtonColor = "#0078d4";
const tagsGap = "8px";

const getTagKey = (ndx: number): string => `tag${ndx}`;

const onReduceData = (currentData: OverflowData): OverflowData | undefined => {
  if (currentData.primary.length === 0) {
    return undefined;
  }
  const overflow = [...currentData.primary.slice(-1), ...currentData.overflow];
  const primary = currentData.primary.slice(0, -1);
  return { primary, overflow };
};

const onGrowData = (currentData: OverflowData): OverflowData | undefined => {
  if (currentData.overflow.length === 0) {
    return undefined;
  }
  const overflow = currentData.overflow.slice(1);
  const primary = [...currentData.primary, ...currentData.overflow.slice(0, 1)];
  return { primary, overflow };
};

interface ExpandCollapseButtonProps {
  label: string;
  iconName: string;
  onClick: () => void;
}

const ExpandCollapseButton = ({ label, iconName, onClick }: ExpandCollapseButtonProps): JSX.Element => (
  <CommandBarButton
    iconProps={{ iconName }}
    onClick={onClick}
    styles={{
      root: { color: expandButtonColor, backgroundColor: "inherit" },
      rootHovered: { color: expandButtonColor, backgroundColor: "inherit" },
      rootPressed: { color: expandButtonColor, backgroundColor: "inherit" },
      icon: { fontSize: "12px", color: expandButtonColor },
      iconHovered: { color: expandButtonColor },
      iconPressed: { color: expandButtonColor },
      label: { fontSize: "14px" },
    }}
  >
    {label}
  </CommandBarButton>
);

const ExpandedStyles: React.CSSProperties = { visibility: "hidden", position: "absolute", maxWidth: "100%" };
const CollapsedStyles: React.CSSProperties = { maxWidth: "100%" };

export const TagsDisplay = ({ tags }: TagsDisplayProps): JSX.Element => {
  const [expand, setExpand] = React.useState<boolean>(false);
  const [moreCount, setMoreCount] = React.useState<number>(0);

  const primaryData = tags.map((tag, ndx) => ({ ...tag, key: getTagKey(ndx) }));
  const dataToRender = { primary: primaryData, overflow: [] } as OverflowData;

  const onRenderData = (data: OverflowData): JSX.Element => (
    <OverflowSet
      items={data.primary}
      overflowItems={data.overflow.length ? data.overflow : undefined}
      onRenderItem={onRenderItem}
      onRenderOverflowButton={onRenderOverflowButton}
      styles={{ overflowButton: { marginLeft: "auto" } }}
    />
  );

  const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => (
    <div style={{ marginRight: tagsGap }}>
      <TagItem
        key={item.key}
        name={item.name}
        value={item.value}
      />
    </div>
  );

  const onRenderOverflowButton = (): JSX.Element => (
    <ExpandCollapseButton
      label={Messages.tags.more(moreCount.toString())}
      iconName="ChevronDownMed"
      onClick={() => setExpand(true)}
    />
  );

  const dataDidRender = (renderedData: OverflowData): void => {
    setMoreCount(renderedData.overflow.length);
    if (renderedData.overflow.length === 0) {
      // Reset the expansion flag whenever the zooming out causes the items
      // to no longer overflow so that the next zooming in will show the items
      // in the collapsed state with the MORE link instead of showing them
      // in the expanded state with the LESS link.
      setExpand(false);
    }
  };

  return (
    <div>
      {/*
        Always render ResizeGroup to take advantage of its overflow calculation.
        When the tags are expanded:
        - The visibilty of the ResizeGroup should be
        set to hidden so that it continues rendering and as a result continue
        recalculating the overflow.
        - The position is set to absolute so that it does not push down the expanded
        tags
        - Only display LESS link if there are overflow items
       */}
      <ResizeGroup
        data={dataToRender}
        onReduceData={onReduceData}
        onGrowData={onGrowData}
        onRenderData={onRenderData}
        dataDidRender={dataDidRender}
        style={expand ? ExpandedStyles : CollapsedStyles}
      />
      {expand && (
        <Stack horizontal wrap tokens={{ childrenGap: tagsGap }}>
          {tags.map((item, ndx) => (
            <TagItem
              key={getTagKey(ndx)}
              name={item?.name}
              value={item?.value}
            />
          ))}
          {moreCount > 0 && (
            <div style={{ marginLeft: "auto" }}>
              <ExpandCollapseButton
                label={Messages.tags.less()}
                iconName="ChevronUpMed"
                onClick={() => setExpand(false)}
              />
            </div>
          )}
        </Stack>
      )}
    </div>
  );
};
