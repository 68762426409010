import * as React from "react";

export enum ValidationState {
  VALID = "VALID",
  INVALID = "INVALID",
  UNKNOWN = "UNKNOWN",
}

export interface FormState {
  setValue: (value: unknown, field: string, group?: string) => void;
  getValue: <ValueType>(field: string, group?: string) => ValueType | undefined;
  getValuesByFieldPrefix: (fieldPrefix: string, group?: string) => FormValues;
  getValues: () => FormValues;
  getGroupValues: (group: string) => FormValues;
}

// Used by consumers of the Form
export interface FormInterface {
  submit: () => void;
  enableValidation: (group?: string) => void;
  isValidationEnabled: (group?: string) => boolean;
  getFormState: () => ValidationState;
  hasValidation: (group?: string) => boolean;
  getFormValues: () => FormValues;
  getFormFieldLabelMap: () => Map<string, string>;
  getFormFieldGroupMap: () => Map<string, string>;
  extractFieldInputValue: (formValue: unknown | undefined, field: string) => unknown | undefined;
}

export const FormContext = React.createContext<FormState>({} as FormState);

// We cannot completely get rid of any without a full rearchitecture
// as the SimpleBuilder relies heavily on this type as well. I do not see a great need
// to remove this usage of any as long as the provided utilities (getValue) are used.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormValues = Record<string, any>;
export type FormErrors = Record<string, string[] | undefined>;

export interface FormFieldErrors {
  errors: string[] | undefined;
  field: string;
  group?: string;
}

export const getValue = <ValueType>(formValues: FormValues, field: string, group?: string): ValueType | undefined => {
  if (group) {
    return formValues[group]?.[field];
  }
  return formValues[field];
};

export const getValuesByFieldPrefix = (
  formValues: FormValues,
  fieldPrefix: string,
  group?: string,
): FormValues => {
  const result: FormValues = {};
  let formValuesSubset: FormValues;
  if (group) {
    formValuesSubset = formValues[group];
  } else {
    formValuesSubset = formValues;
  }
  if (formValuesSubset) {
    Object.keys(formValuesSubset).forEach(key => {
      if (key.startsWith(fieldPrefix)) {
        result[key] = formValuesSubset[key];
      }
    });
  }
  return result;
};
