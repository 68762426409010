import * as Messages from "../codegen/Messages";
import {
  cidrHasHostBitsSet,
  cidrPrefixWithinRange,
  cidrRange,
  cidrValid,
  getOverlapCidrs,
  isValidAddress,
  isValidIpAddress,
  maxIpv4CidrPrefix,
  minIpv4CidrPrefix,
} from "./networkHelper";

export interface LengthRange {
  /**
   * The minimum allowed length
   */
  min?: number;
  /**
   * The maximum allowed length
   */
  max?: number;
}

export interface ReservedWordValidation {
  /**
   * List of words that the value is not allowed to contain
   */
  words: string[];
  /**
   * Whether to match the whole value against the reserved word(s)
   * @default false
   */
  wholeWordMatch?: boolean;
  /**
   * Whether the words should be searched for case sensitive
   * @default false
   */
  caseSensitiveMatch?: boolean;
  /**
   * Custom error message
   * @default "The value cannot contain the reserved word '{reserved}'."
   * @default "The value cannot contain any of the following reserved words: {reserved}."
   */
  errorMessage?: string;
}

export interface RegExpValidation {
  /**
   * The regex to test the value against
   */
  regExp: RegExp;
  /**
   * The custom error message to be returned if the string does not match the regex
   */
  errorMessage: string;
}

export interface ValidateStringOptions {
  /**
   * When true it will not display validation errors if the value is empty/undefined
   * When false it will display validation errors if the value is empty/undefined
   * @default false
   */
  isOptional?: boolean;
  /**
   * The allowed range that the length of the value can belong to
   */
  lengthRange?: LengthRange;
  /**
   * Configuration for validating against a list of reserved words
   */
  reservedWordValidation?: ReservedWordValidation;
  /**
   * Configuration for validating against regular expressions
   */
  regExpValidation?: RegExpValidation | RegExpValidation[];
}

const validateOptions = (options: ValidateStringOptions): void => {
  const {
    lengthRange,
    regExpValidation,
    reservedWordValidation,
  } = options;

  if (!regExpValidation && !lengthRange && !reservedWordValidation) {
    throw new Error("An option must be provided to validateString.");
  }

  if (lengthRange) {
    const { min, max } = lengthRange;
    if (!min && !max) throw new Error("Min and/or max must be provided.");
  }

  if (reservedWordValidation) {
    const { words } = reservedWordValidation;
    if (words.length === 0) throw new Error("Reserved words cannot be empty");
  }
};

const validateReservedWords = (value: string, reservedWordValidation: ReservedWordValidation): boolean => {
  const {
    words: reservedWords,
    caseSensitiveMatch,
    wholeWordMatch,
  } = reservedWordValidation;

  const searchCaseSensitive = caseSensitiveMatch ?? false;
  const matchWholeWord = wholeWordMatch ?? false;

  const getNormalizedString = (val: string): string => {
    if (!searchCaseSensitive) return val.toLocaleLowerCase();
    return val;
  };

  const normalizedValue = getNormalizedString(value);

  let hasReservedWord: boolean;

  if (matchWholeWord) {
    hasReservedWord = reservedWords.some(word => normalizedValue === getNormalizedString(word));
  } else {
    hasReservedWord = reservedWords.some(word => normalizedValue.includes(getNormalizedString(word)));
  }

  return !hasReservedWord; // Negate as successful validation is no words being matched
};

const validateLengthRange = (value: string, lengthRange: LengthRange): boolean => {
  const {
    min,
    max,
  } = lengthRange;

  if (max && value.length > max) return false;
  if (min && value.length < min) return false;
  return true;
};

const getLengthRangeError = (lengthRange: LengthRange): string => {
  // eslint-disable-next-line eqeqeq
  if (!lengthRange.min && lengthRange.max != undefined) {
    return Messages.validation.valueMaxLen(lengthRange.max.toString());
  }
  // eslint-disable-next-line eqeqeq
  if (!lengthRange.max && lengthRange.min != undefined) {
    return Messages.validation.valueMinLen(lengthRange.min.toString());
  }

  return Messages.validation.inputRange(
    lengthRange.min?.toString() || "",
    lengthRange.max?.toString() || "",
  );
};

const getReservedWordsValidationError = (reservedWordValidation: ReservedWordValidation): string => {
  if (reservedWordValidation.errorMessage) return reservedWordValidation.errorMessage;
  if (reservedWordValidation.words.length === 1) {
    return Messages.validation.validationReservedWord(reservedWordValidation.words[0]);
  }
  return Messages.validation.validationReservedWords(reservedWordValidation.words.join(", "));
};

const getAllErrors = (options: ValidateStringOptions): string[] => {
  const errors: string[] = [];
  const { lengthRange, regExpValidation, reservedWordValidation } = options;

  if (lengthRange) {
    errors.push(getLengthRangeError(lengthRange));
  }

  if (regExpValidation) {
    if (Array.isArray(regExpValidation)) {
      regExpValidation.forEach(({ errorMessage }) => errors.push(errorMessage));
    } else {
      errors.push(regExpValidation.errorMessage);
    }
  }

  if (reservedWordValidation) {
    errors.push(getReservedWordsValidationError(reservedWordValidation));
  }

  return errors;
};

export const validateString = (value: string | undefined, options: ValidateStringOptions): string[] | undefined => {
  validateOptions(options);

  const { regExpValidation, lengthRange, reservedWordValidation, isOptional = false } = options;

  if (!value) {
    if (!isOptional) return getAllErrors(options);
    return undefined;
  }

  const errors: string[] = [];

  if (regExpValidation) {
    if (Array.isArray(regExpValidation)) {
      regExpValidation.forEach(({ errorMessage, regExp }) => {
        if (!regExp.test(value)) errors.push(errorMessage);
      });
    } else if (!regExpValidation.regExp.test(value)) {
      errors.push(regExpValidation.errorMessage);
    }
  }

  if (reservedWordValidation && !validateReservedWords(value, reservedWordValidation)) {
    errors.push(getReservedWordsValidationError(reservedWordValidation));
  }

  if (lengthRange && !validateLengthRange(value, lengthRange)) {
    errors.push(getLengthRangeError(lengthRange));
  }

  return errors.length > 0 ? errors : undefined;
};

export interface PrefixRange {
  /**
   * The numerical max value of the CIDR prefix (ex. /23)
   */
  max: number;
  /**
   * The numerical min value of the CIDR prefix (ex. /16)
   */
  min?: number;
  /**
   * The validation message if the range provided is invalid
   */
  validationMessage?: string;
}

export interface NetworkAddressValidationConfig {
  /**
   * Flag that determines whether setting the bits of
   * the host address is treated as an invalid CIDR
   * Only has an effect if containsCidrBlock is set
   * @default false
   */
  allowHostBits?: boolean;
  /**
   * Controls whether validation allows CIDR blocks to entered
   * Works inclusivley with containsIpAddress
   * One of containsCidrBlock or containsIpAddress must be true
   */
  containsCidrBlock: boolean;
  /**
   * Controls whether validation allows single IP addresses to be entered
   * Works inclusivley with containsCidrBlock
   * One of containsCidrBlock or containsIpAddress must be true
   */
  containsIpAddress: boolean;
  /**
   * Accepts CIDRs and IP addresses
   * We assume that the list of CIDRs/IP addresses provided do not overlap with themselves
   * The only difference between this and reservedAddresses is the error message displayed
   */
  excludeAddresses?: string[];
  /**
   * The numberical range of the CIDR prefix
   * Only validates if containsCidrBlock is set
   */
  prefixRange?: PrefixRange;
  /**
   * Accepts CIDRs and IP addresses
   * We assume that the list of CIDRs/IP addresses provided do not overlap with themselves
   * The only difference between this and excludeAddresses is the error message displayed
   */
  reservedAddresses?: string[];
}

export const validateNetworkAddress = (
  value: string | undefined,
  config?: NetworkAddressValidationConfig,
): string[] | undefined => {
  const {
    allowHostBits = false,
    containsCidrBlock,
    containsIpAddress,
    excludeAddresses,
    prefixRange,
    reservedAddresses,
  } = config ?? {};

  if (!containsCidrBlock && !containsIpAddress) {
    throw new Error("Either 'containsCidrBlock' or 'containsIpAddress' must be set");
  }

  const maxCidrPrefix = prefixRange?.max ?? maxIpv4CidrPrefix;
  const minCidrPrefix = prefixRange?.min ?? minIpv4CidrPrefix;

  const validateReservedAddresses = (addresses: string[]): string[] | undefined => {
    const overlappingAddresses = getOverlapCidrs(addresses);

    if (overlappingAddresses.length > 0) {
      return overlappingAddresses.map(overlap => {
        // Runs if user enters IP/CIDR and the reserved address is a CIDR
        if (cidrValid(overlap.cidr2)) {
          const [startingIp, endingIp] = cidrRange(overlap.cidr2);
          return Messages.validation.addressReservedRange(overlap.cidr1, overlap.cidr2, startingIp, endingIp);
        }

        // Runs if user enters a CIDR and reserved address is an IP address
        if (cidrValid(overlap.cidr1)) {
          return Messages.validation.cidrHasReservedIp(overlap.cidr1, overlap.cidr2);
        }

        // Runs if the reserved address and the user input are IP addresses
        return Messages.validation.addressReserved(overlap.cidr1);
      });
    }

    return undefined;
  };

  const validateExcludedAddresses = (addresses: string[]): string[] | undefined => {
    const overlappingAddresses = getOverlapCidrs(addresses);

    if (overlappingAddresses.length > 0) {
      return overlappingAddresses.map(overlap => Messages.validation.addressOverlap(
        overlap.cidr1,
        overlap.cidr2,
      ));
    }

    return undefined;
  };

  const validateAddress = (address: string): string[] | undefined => {
    if (!isValidAddress(address)) return [Messages.validation.addressInvalid(address)];
    return undefined;
  };

  const validateCidr = (cidr: string): string[] | undefined => {
    if (!cidrValid(cidr)) return [Messages.validation.cidrInvalid(cidr)];
    return undefined;
  };

  const validateIpAddress = (ipAddress: string): string[] | undefined => {
    if (!isValidIpAddress(ipAddress)) return [Messages.validation.ipAddressInvalid(ipAddress)];
    return undefined;
  };

  const validateCidrPrefix = (cidr: string): string[] | undefined => {
    if (!cidrPrefixWithinRange(cidr, minCidrPrefix, maxCidrPrefix)) {
      return [prefixRange?.validationMessage || Messages.validation.cidrPrefixInvalid(
        minCidrPrefix.toString(),
        maxCidrPrefix.toString(),
      )];
    }
    return undefined;
  };

  const validateHostBits = (cidr: string): string[] | undefined => {
    if (cidrHasHostBitsSet(cidr)) return [Messages.validation.cidrHostBitsSet()];
    return undefined;
  };

  const errors: string[] = [];

  // Leave required validation up to the TextInput
  if (value) {
    if (containsCidrBlock && containsIpAddress) {
      errors.push(...(validateAddress(value) || []));
    } else if (containsCidrBlock) {
      errors.push(...(validateCidr(value) || []));
    } else if (containsIpAddress) {
      errors.push(...(validateIpAddress(value) || []));
    }

    // ensure it is a valid address (based on previous validation results)
    if (errors.length === 0) {
      // only run if it is a CIDR and is enabled
      if (containsCidrBlock && cidrValid(value)) {
        errors.push(...(validateCidrPrefix(value) || []));
      }
    }

    if (errors.length === 0) {
      if (containsCidrBlock && cidrValid(value) && !allowHostBits) {
        errors.push(...(validateHostBits(value) || []));
      }
    }

    if (errors.length === 0) {
      if (excludeAddresses) {
        errors.push(...(validateExcludedAddresses([value, ...excludeAddresses])) || []);
      }

      if (reservedAddresses) {
        errors.push(...(validateReservedAddresses([value, ...reservedAddresses]) || []));
      }
    }
  }

  return errors.length > 0 ? errors : undefined;
};
