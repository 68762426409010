import { createBackOffDelay, defaultBackOffConfig, defaultRetryableResponses, retryableError } from "savant-connector";

/**
 * https://stackoverflow.com/a/105074/96898
 * Do not use this if you need compliant GUIDs.
 */
export function uniqueGUID(): string {
  function s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (`${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`);
}

const optimizedRetryableResponses = defaultRetryableResponses.filter(resp => resp.status !== 500);

export const optimizedRetryOption = {
  nextRetryDelay: createBackOffDelay(
    defaultBackOffConfig,
    retryableError(optimizedRetryableResponses),
  ),
};
