/* eslint-disable no-underscore-dangle */
import * as React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { uniqueGUID } from "../../helpers/util";
import { AppContext, AppState } from "../Context/AppContext";
import { screenSizeManager } from "../Context/InternalScreenSizeContext";
import { MenuState } from "../Menu/Menu";

export enum NavigationEntryType {
  ROOT_PAGE = "root-page",
  REGULAR_PAGE = "regular-page",
  DETAILS_ROOT_PANEL = "details-root-panel",
  DETAILS_PANEL = "details-panel",
}

export interface NavigationEntry {
  type: NavigationEntryType;
  key: string;
  pageId?: string;
  pageLabel?: string;
  panelTitle?:string;
  panelPath?: string; // Used for details page panels
  path?: string; // used as fallback when the index of hisotry is -ve
}

type MetaData = {
  searchText: string,
  menuState: MenuState,
};

interface NavigationHistoryEntry extends NavigationEntry {
  stamp: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customData?: { [key:string]: any },
  metaData?: MetaData,
}

type HistoryPath = { path: string, entry: NavigationHistoryEntry };

interface State {
  history: NavigationHistoryEntry[];
  registry: { [key:string]: NavigationEntry };
  breadcrumbLabelMap: { [key:string]: string };
  rootPageKey: string;
  currentEntryNdx: number;
}

const SessionStorageNavKey = "O4A-NAV";

class NavigationHistory {
  private _state: State = {
    history: [],
    registry: {},
    breadcrumbLabelMap: {},
    rootPageKey: "",
    currentEntryNdx: -1,
  };

  private _currentNdxListener!: (ndx: number) => void;

  constructor() {
    const navStored = window.sessionStorage.getItem(SessionStorageNavKey);
    if (navStored) {
      this._state = JSON.parse(navStored);
    }
  }

  public register = (
    key: string,
    type: NavigationEntryType,
    pageId: string,
    panelPath?: string,
    path?: string,
  ): void => {
    this._state.registry[key] = { key, type, pageId, panelPath, path } as NavigationEntry;
    if (type === NavigationEntryType.ROOT_PAGE) {
      this._state.rootPageKey = key;
    }
  };

  public regsiterBreadcrumbLabel = (key: string, label: string): void => {
    this._state.breadcrumbLabelMap[key] = label;
  };

  /**
   * This method allows the new navigation entry to be pushed into history.
   * resetPath allows to clear the browser history before proceeding. Each
   * entry pushed on history set the menu state & search text to default value.
   * @param navigationEntry
   * @param resetPath
   */
  public push = (navigationEntry: NavigationHistoryEntry, resetPath = false): void => {
    if (resetPath || navigationEntry.type === NavigationEntryType.ROOT_PAGE) {
      if (!this._state.history.length) {
        this._state.history.push(navigationEntry);
      }
      // reset the index when user is coming from resetPath or home is clicked
      this.currentEntryNdx = 0;
      this.setContextData({ searchText: "", menuState: MenuState.EXPAND });
    }

    if (this.currentEntryNdx >= 0
      && navigationEntry.pageId
      !== this._state.history[this.currentEntryNdx]?.pageId) {
      this.setHistoryMetaData();
      this.setContextData({ searchText: "", menuState: MenuState.EXPAND });
    }

    this._state.history.splice(this.currentEntryNdx + 1);
    if (navigationEntry.type !== NavigationEntryType.ROOT_PAGE) {
      this._state.history.push(navigationEntry);
      this.currentEntryNdx++;
    }
  };

  /**
   * This method gets called whenever we go back & forth in navigation.
   * It make sure that current index is in boundaries,
   * @param key
   * @param stamp
   */
  public moveTo(key: string, stamp: string): void {
    const destNdx = this._state.history.findIndex(entry => entry.key === key && entry.stamp === stamp);

    if (destNdx !== this.currentEntryNdx) {
      this.setContextData({ searchText: "", menuState: MenuState.EXPAND });
      this.setHistoryMetaData();
    }

    if (destNdx >= 0) this.currentEntryNdx = destNdx;

    /*
    *  if key is root_page, reset the index. There might be some scenarios in which
    *  crossing boundaries will leave the index in wrong state. it's important.
    */
    const rootIndex = this._state.history.findIndex(
      entry => entry.key === key && entry.type === NavigationEntryType.ROOT_PAGE,
    );
    if (rootIndex >= 0) {
      this.currentEntryNdx = 0;
    }
  }

  public moveToRoot = (): void => {
    if (this.currentEntryNdx >= 0) this.currentEntryNdx = 0;
  };

  /**
   * This method gets called when close button on page is clicked. It calculates the
   * total number of hops by traversing the history until we find different pageId.
   * Substract the total hops from the current index and update it as well. Menu state
   * & search text will be updated for the current index as well by getting values from
   * the history.
   * @returns number
   */
  public back = (): number => {
    const currentEntry = this._state.history[this.currentEntryNdx];
    let nextPositionForCurrent = 0;
    for (let i = this.currentEntryNdx; i >= 0; i--) {
      if (this._state.history[i].pageId !== currentEntry.pageId) {
        nextPositionForCurrent = i;
        break;
      }
    }
    const noOfHopsToGoBack = this.currentEntryNdx - nextPositionForCurrent;
    this.currentEntryNdx = nextPositionForCurrent < 0 ? 0 : nextPositionForCurrent;

    this.setContextDataFromHistory();

    return noOfHopsToGoBack < 0 ? 0 : noOfHopsToGoBack;
  };

  /**
   * This method gets called when breadcrumb is clicked. It can be the breadcrumb of
   * same page as well. It calculates the total number of hops first by checking if
   * the current index is on the root of the details page or not. If not traversing
   * the history until we find the root. Substract the total hops from the current index
   * and update it as well. Meta data for each page is stored in history while navigating
   * between panels. Menu state & search text will be updated for the current index as well
   * by getting values from the history.
   * @param index
   * @returns number
   */
  public backTo = (index: number): number => {
    const isTabNav = index === this.currentEntryNdx;
    let tempIndex = index;
    const currentEntry = this._state.history[index];
    if (currentEntry?.type === NavigationEntryType.DETAILS_PANEL) {
      for (let i = index; i >= 0; i--) {
        if (this._state.history[i]?.pageId !== currentEntry?.pageId) {
          tempIndex = i + 1;
          break;
        }
      }
    }
    const backCount = this.currentEntryNdx - tempIndex;
    this.currentEntryNdx = tempIndex;

    if (isTabNav) {
      this.setHistoryMetaData();
    }

    this.setContextDataFromHistory();

    return backCount;
  };

  /**
   * This method gets called when breadcrumb with pipe is clicked.
   * It calculates the total number of hops & update the current index.
   * Once we know the current index, call the method to get info for
   * that index from the history, if search text & menu state exists, set them.
   * @param index
   * @returns number
   */
  public backToPipe = (index: number): number => {
    const backCount = this.currentEntryNdx - index;
    this.currentEntryNdx = index;

    this.setContextDataFromHistory();

    return backCount;
  };

  public get rootPageKey(): string { return this._state.rootPageKey; }

  public get history(): NavigationHistoryEntry[] { return this._state.history; }

  public get registry(): { [key:string]: NavigationEntry } { return this._state.registry; }

  public get breadcrumbLabelMap(): { [key:string]: string } { return this._state.breadcrumbLabelMap; }

  public get currentEntryNdx(): number { return this._state.currentEntryNdx; }

  public set currentEntryNdx(ndx: number) {
    this._state.currentEntryNdx = ndx;
    this._currentNdxListener?.(this._state.currentEntryNdx);
  }

  public addCurrentNdxListener = (listener: (ndx: number) => void): void => {
    this._currentNdxListener = listener;
  };

  /**
   * If current navigation index is > 0 get the menu state & search text
   * from the history and set the context. This is required when user is going
   * back using close button or breadcrumbs.
   */
  private setContextDataFromHistory():void {
    if (this.currentEntryNdx > 0) {
      const { metaData } = this._state.history[this.currentEntryNdx];
      if (metaData?.menuState) {
        screenSizeManager.setMenuState(metaData?.menuState);
      }
      screenSizeManager.setSearchText(metaData?.searchText || "");
    }
  }

  /**
   * If current navigation index is > 0 get the menu state & search text
   * from context and store it inside meta data for the current index.
   * This is required when user navigates from the current page to another one.
   */
  private setHistoryMetaData():void {
    if (this.currentEntryNdx > 0) {
      this._state.history[this.currentEntryNdx] = {
        ...this._state.history[this.currentEntryNdx],
        metaData: {
          searchText: screenSizeManager.getSearchText(),
          menuState: screenSizeManager.getMenuState(),
        },
      };
    }
  }

  /**
   * Used for setting context i.e (search text, menu state)
   * @param data
   */
  private setContextData = (data: MetaData):void => {
    const { menuState, searchText } = data;
    if (menuState) {
      screenSizeManager.setMenuState(menuState);
    }
    screenSizeManager.setSearchText(searchText);
  };

  public getFallBackHistoryEntry = (): HistoryPath => {
    const currentIndex = navigationHistory.currentEntryNdx;
    const historyEntry = navigationHistory.history[currentIndex <= 0 ? 0 : currentIndex];
    const path = navigationHistory.registry[historyEntry.key]?.path || "/";
    return { path, entry: historyEntry };
  };
}

/**
 * Navigation history instance is wrapped in proxy to intercept the getter, setter before storing state.
 */
const navigationHistory = new Proxy(new NavigationHistory(), {
  get: (target, prop) => {
    const targetProp = prop in target ? target[prop as keyof typeof target] : undefined;
    if (typeof targetProp === "function" && prop !== "addCurrentNdxListener") {
      return new Proxy(targetProp, {
        apply: (funcTarget, thisArg, argumentsList) => {
          const result = Reflect.apply(funcTarget, thisArg, argumentsList);
          window.sessionStorage.setItem(SessionStorageNavKey, JSON.stringify(Reflect.get(target, "_state")));
          return result;
        },
      });
    }
    return Reflect.get(target, prop);
  },
  set: (obj, prop, value): boolean => {
    const success = Reflect.set(obj, prop, value);
    window.sessionStorage.setItem(SessionStorageNavKey, JSON.stringify(Reflect.get(obj, "_state")));
    return success;
  },
});

/**
 * clear the storage for navigation history.
 */
export const clearNavHistoryStorage = (): void => {
  window.sessionStorage.removeItem(SessionStorageNavKey);
};

/**
 * It register's all the pages, usually this method is called on initial app load.
 * @param key
 * @param type
 * @param pageId
 * @param panelPath
 * @param path
 */
export const registerNavigationEntry = (
  key: string,
  type: NavigationEntryType,
  pageId: string,
  panelPath?: string,
  path?: string,
): void => {
  navigationHistory.register(key, type, pageId, panelPath, path);
};

/**
 * It register's meaningful label names used for displaying breadcrumbs.
 * @param key
 * @param label
 */
export const registerBreadcrumbLabel = (key: string, label: string): void => {
  navigationHistory.regsiterBreadcrumbLabel(key, label);
};

export type Back = () => void;
export type NavigateToSelf = (path: string, key: string) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NavigateTo = (path: string, key: string, customData?: any, resetPath?: boolean) => void;
export type NavigateToPanel = (key: string) => void;

export type NavigationHandlers = {
  navigateToSelf: NavigateToSelf;
  navigateTo: NavigateTo;
  navigateToPanel: NavigateToPanel;
  back: Back;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customData: any;
  setPageDetails: (title:string, suffix:string) => void;
};

export function useNavigation<T extends AppState>(appContext: AppContext<T>): NavigationHandlers {
  const appState = React.useContext<T>(appContext);
  const navigate = useNavigate();
  const location = useLocation();

  const navigationState = location.state as NavigationHistoryEntry;
  if (!navigationState) {
    navigationHistory.moveToRoot();
  } else {
    navigationHistory.moveTo(navigationState.key, navigationState.stamp);
  }

  /**
   * This method is called from each page to handle same page reload.
   * Also if url is bookmarked, it will check for root page, if not
   * create the entry and proceed with path that is passed as param.
   * @param path
   * @param key
   */
  const navigateToSelf: NavigateToSelf = (path: string, key: string): void => {
    const selfNdx = navigationHistory.history.findIndex(entry => entry.key === key);
    if (selfNdx < 0) {
      let replace = true;
      const navigationEntry = navigationHistory.registry[key];
      if (navigationEntry) {
        if (navigationEntry.type !== NavigationEntryType.ROOT_PAGE) {
          const rootNavigationEntry = {
            type: NavigationEntryType.ROOT_PAGE,
            key: appState.homeKey,
            stamp: uniqueGUID(),
          } as NavigationHistoryEntry;
          navigationHistory.push(rootNavigationEntry);
          navigate(appState.homePath || "/", { state: rootNavigationEntry, replace });
          replace = false;
        }

        const navigationHistoryEntry = { ...navigationEntry, ...{ stamp: uniqueGUID() } };
        navigationHistory.push(navigationHistoryEntry);
        navigate(path, { state: navigationHistoryEntry, replace });
      }
    }
  };

  /**
   * This method is used to navigate between different pages. It allow the customData
   * to be stored in history associated with entry that is pushed. if the resetPath is
   * true, history will be cleared before pushing new entry pushing into history.
   * @param path
   * @param key
   * @param customData
   * @param resetPath
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigateTo: NavigateTo = (path: string, key: string, customData?: any, resetPath = false): void => {
    const navigationEntry = navigationHistory.registry[key];
    if (navigationEntry) {
      const navigationHistoryEntry = { ...navigationEntry, stamp: uniqueGUID(), customData };
      /**
       * If the resetPath is true, check if index >= 0, it means we have to go back in history and clear it
       * before navigating to destination. if the index is equal to zero or destination is root, do nothing.
       * It means we are already at root.
       */
      if (resetPath && navigationHistory.currentEntryNdx >= 0) {
        if (navigationEntry.type === NavigationEntryType.ROOT_PAGE && navigationHistory.currentEntryNdx === 0) {
          // do nothing ...
        } else {
          if (navigationHistory.currentEntryNdx > 0) {
            navigate(-navigationHistory.currentEntryNdx);
          }
          setTimeout(() => {
            if (navigationEntry.type !== NavigationEntryType.ROOT_PAGE) {
              navigationHistory.push(navigationHistoryEntry, resetPath);
              navigate(path, { state: navigationHistoryEntry });
            }
          }, 1);
        }
      } else {
        navigationHistory.push(navigationHistoryEntry, resetPath);
        navigate(path, { state: navigationHistoryEntry });
      }
    }
  };

  /**
   * This method is used to navigate between panels on detail page.
   * @param key
   */
  const navigateToPanel: NavigateToPanel = (key: string): void => {
    const navigationEntry = navigationHistory.registry[key];
    const pathTokens = location.pathname.split("/");

    const panelName = pathTokens.pop();

    if (panelName === navigationEntry?.panelPath) return;

    const panelUrl = `${pathTokens.join("/")}/${navigationEntry?.panelPath}${location.search || ""}`;
    navigateTo(panelUrl, key);
  };

  /**
   * This method handles going back in history.
   */
  const back: Back = (): void => {
    const viewIndex = navigationHistory.back();
    if (viewIndex >= 0) {
      navigate(viewIndex === 0 ? -1 : -viewIndex);
    } else {
      const { path, entry } = navigationHistory.getFallBackHistoryEntry();
      navigate(path, { state: entry });
    }
  };

  /**
   * If the current index has a customData associated with it, useNavigation hook allows access to it.
   */
  const customData = navigationHistory.history[navigationHistory.currentEntryNdx]?.customData;

  /**
   * This method is used to set the page label, title in history when it is not home page.
   * @param title
   * @param suffix
   */
  const setPageDetails = (title:string, suffix:string): void => {
    if (navigationHistory.currentEntryNdx >= 0) {
      if (navigationHistory.currentEntryNdx === 0) {
        navigationHistory.history[navigationHistory.currentEntryNdx].pageLabel = undefined;
      } else {
        navigationHistory.history[navigationHistory.currentEntryNdx].pageLabel = title;
        navigationHistory.history[navigationHistory.currentEntryNdx].panelTitle = suffix;
      }
    }
  };

  return { navigateToSelf, navigateTo, navigateToPanel, back, customData, setPageDetails };
}

type BreadcrumbItem = {
  key: string;
  label: string;
};

const BreadCrumbKey = "O4A-BC-END";

export interface BreadcrumbsHandlers {
  breadcrumbs: BreadcrumbItem[];
  backTo: (index: number) => void;
  backToPipe: (index: number) => void;
}

export const useBreadcrumbs = (): BreadcrumbsHandlers => {
  const navigate = useNavigate();

  /**
   * Each time when history entry is pushed/pop, breadcrumbs logic is executed and breadcrumbs
   * array is filled with all the valid entries that needs to be rendered.
   */
  const activeHistory = navigationHistory.history.slice(0, navigationHistory.currentEntryNdx + 1);
  let lastEntry = activeHistory[activeHistory.length - 1];
  const breadcrumbs: BreadcrumbItem[] = [];
  for (let i = activeHistory.length - 1; i >= 0; i--) {
    const label = activeHistory[i]?.pageLabel || navigationHistory.breadcrumbLabelMap[activeHistory[i].key];
    if (activeHistory[i]?.pageId !== lastEntry.pageId) {
      if (activeHistory[i]?.type === NavigationEntryType.DETAILS_PANEL) {
        const panelForPipe = activeHistory[i]?.panelTitle || activeHistory[i]?.pageLabel;
        const labelWithPipe = `${label} | ${panelForPipe}`;
        breadcrumbs.push({ key: `${activeHistory[i].key}$${i}#${i}`, label: labelWithPipe });
      } else {
        breadcrumbs.push({ key: `${activeHistory[i].key}$${i}`, label });
      }
    }
    lastEntry = activeHistory[i];
  }

  if (activeHistory[activeHistory.length - 1]?.type === NavigationEntryType.DETAILS_PANEL) {
    const entry = activeHistory[activeHistory.length - 1];
    const label = entry?.pageLabel || navigationHistory.breadcrumbLabelMap[entry.key];
    breadcrumbs.unshift({ key: `${entry.key}$${activeHistory.length - 1}`, label });
  }
  breadcrumbs.reverse();
  breadcrumbs.push({ key: BreadCrumbKey, label: "" });

  /**
   * This method gets called when breadcrumn is clicked.
   * @param index
   */
  const backTo = (index: number): void => {
    const viewIndex = navigationHistory.backTo(index);
    if (viewIndex >= 0) {
      navigate(viewIndex === 0 ? -1 : -viewIndex);
    } else {
      const { path, entry } = navigationHistory.getFallBackHistoryEntry();
      navigate(path, { state: entry });
    }
  };

  /**
   * This method gets called when breadcrumb with pipe is clicked.
   * @param index
   */
  const backToPipe = (index: number): void => {
    const viewIndex = navigationHistory.backToPipe(index);
    if (viewIndex >= 0) {
      navigate(viewIndex === 0 ? -1 : -viewIndex);
    } else {
      const { path, entry } = navigationHistory.getFallBackHistoryEntry();
      navigate(path, { state: entry });
    }
  };

  return { breadcrumbs, backTo, backToPipe };
};

export interface NavigationLinkProps {
  to: string;
  title?: string;
  pageKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customData?: { [key:string]: any },
  children: React.ReactNode
}

export const NavigationLink = ({ to, pageKey, customData, children, title }: NavigationLinkProps): JSX.Element => {
  const navigationEntry = React.useMemo(() => {
    const entry = navigationHistory.registry[pageKey];
    return entry ? { ...entry, stamp: uniqueGUID(), customData }
      : {} as NavigationHistoryEntry;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to, pageKey, customData]);

  const onClick = (): void => {
    navigationHistory.push(navigationEntry);
  };

  return (
    <Link
      to={to}
      title={title}
      state={navigationEntry}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
