import * as Messages from "../codegen/Messages";

export enum StatusType {
  CANCELLED,
  DISABLED,
  ERROR,
  INFO,
  NONE,
  PENDING,
  STOPPED,
  SUCCESS,
  UNKNOWN,
  WARNING,
}

export const stateT = (stateValue: string): string => {
  switch ((stateValue || "").toLowerCase()) {
    case "applied":
      return Messages.status.applied();
    case "active":
      return Messages.status.active();
    case "action_required":
      return Messages.status.actionRequired();
    case "assigned":
      return Messages.status.assigned();
    case "attached":
      return Messages.status.attached();
    case "available":
      return Messages.status.available();
    case "accepted":
      return Messages.status.accepted();
    case "applying":
      return Messages.status.applying();
    case "assigning":
      return Messages.status.assigning();
    case "attaching":
      return Messages.status.attaching();
    case "available_needs_attention":
      return Messages.status.availableNeedsAttention();
    case "blocked":
      return Messages.status.blocked();
    case "backup_in_progress":
    case "backupinprogress":
      return Messages.status.backupInProgress();
    case "cancelled":
      return Messages.status.cancelled();
    case "canceled":
      return Messages.status.canceled();
    case "cancelled_returned":
      return Messages.status.cancelledReturned();
    case "closed":
      return Messages.status.closed();
    case "canceling":
      return Messages.status.canceling();
    case "checking":
      return Messages.status.checking();
    case "created":
      return Messages.status.created();
    case "creating":
      return Messages.status.creating();
    case "creating_image":
      return Messages.status.creatingImage();
    case "complete":
      return Messages.status.complete();
    case "completed":
      return Messages.status.completed();
    case "deleting":
      return Messages.status.deleting();
    case "detaching":
      return Messages.status.detaching();
    case "disabled":
      return Messages.status.disabled();
    case "disabling":
      return Messages.status.disabling();
    case "down":
      return Messages.status.down();
    case "down_for_maintenance":
      return Messages.status.downForMaintenance();
    case "enabling":
      return Messages.status.enabling();
    case "deleted":
      return Messages.status.deleted();
    case "expired":
      return Messages.status.expired();
    case "exporting":
      return Messages.status.exporting();
    case "enabled":
      return Messages.status.enabled();
    case "error":
      return Messages.status.error();
    case "failed":
      return Messages.status.failed();
    case "failed-apply":
      return Messages.status.failedApply();
    case "failed-rollback":
      return Messages.status.failedRollback();
    case "faulty":
      return Messages.status.faulty();
    case "importing":
      return Messages.status.importing();
    case "initiated":
      return Messages.status.initiated();
    case "in_progress":
    case "inprogress":
      return Messages.status.inProgress();
    case "internal_error":
      return Messages.status.internalError();
    case "inactive":
      return Messages.status.inactive();
    case "loading":
      return Messages.status.loading();
    case "maintenanceinprogress":
      return Messages.status.maintenanceInProgress();
    case "missing":
      return Messages.status.missing();
    case "packaged":
      return Messages.status.packaged();
    case "provisioning":
      return Messages.status.provisioning();
    case "pending_provider":
      return Messages.status.pendingProvider();
    case "pending_customer":
      return Messages.status.pendingCustomer();
    case "pending_provisioning":
      return Messages.status.pendingProvisioning();
    case "preparing":
      return Messages.status.preparing();
    case "processed":
      return Messages.status.processed();
    case "processing":
      return Messages.status.processing();
    case "provisioned":
      return Messages.status.provisioned();
    case "queued":
      return Messages.status.queued();
    case "received":
      return Messages.status.received();
    case "request_received":
      return Messages.status.requestReceived();
    case "restore_failed":
      return Messages.status.restoreFailed();
    case "restore_in_progress":
      return Messages.status.restoreInProgress();
    case "restoring":
      return Messages.status.restoring();
    case "rolling-back":
      return Messages.status.rollingBack();
    case "ready":
      return Messages.status.ready();
    case "related":
      return Messages.status.related();
    case "returned":
      return Messages.status.returned();
    case "running":
      return Messages.status.running();
    case "scale_in_progress":
      return Messages.status.scaleInProgress();
    case "shipping":
      return Messages.status.shipping();
    case "starting":
      return Messages.status.starting();
    case "stopped":
      return Messages.status.stopped();
    case "stopping":
      return Messages.status.stopping();
    case "succeeded":
      return Messages.status.succeeded();
    case "terminating":
      return Messages.status.terminating();
    case "terminated":
      return Messages.status.terminated();
    case "unavailable":
      return Messages.status.unavailable();
    case "unassigned":
      return Messages.status.unassigned();
    case "unassigning":
      return Messages.status.unassigning();
    case "updated":
      return Messages.status.updated();
    case "updating":
      return Messages.status.updating();
    case "up":
      return Messages.status.up();
    case "waiting":
      return Messages.status.waiting();
    case "warning":
      return Messages.status.warning();
    case "upgrading":
      return Messages.status.upgrading();
    case "converting":
      return Messages.status.converting();
    default:
      return stateValue;
  }
};

export const getResourceLifecycleStatus = (lifecycleState: string): StatusType => {
  switch (lifecycleState.toLowerCase()) {
    case "active":
    case "assigned":
    case "attached":
    case "available":
    case "complete":
    case "completed":
    case "created":
    case "enabled":
    case "provisioned":
    case "ready":
    case "related":
    case "returned":
    case "running":
    case "scale_in_progress":
    case "succeeded":
    case "up":
    case "updated":
      return StatusType.SUCCESS;
    case "accepted":
    case "action_required":
    case "applying":
    case "assigning":
    case "attaching":
    case "available_needs_attention":
    case "backup_in_progress":
    case "backupinprogress":
    case "canceling":
    case "checking":
    case "creating":
    case "creating_image":
    case "deleting":
    case "detaching":
    case "disabled":
    case "disabling":
    case "down_for_maintenance":
    case "enabling":
    case "exporting":
    case "importing":
    case "initiated":
    case "in_progress":
    case "inprogress":
    case "maintenanceinprogress":
    case "packaged":
    case "provisioning":
    case "pending_provider":
    case "pending_customer":
    case "pending_provisioning":
    case "preparing":
    case "processed":
    case "processing":
    case "received":
    case "request_received":
    case "restore_failed":
    case "restore_in_progress":
    case "restoring":
    case "rolling-back":
    case "shipping":
    case "starting":
    case "stopped":
    case "stopping":
    case "terminating":
    case "unassigned":
    case "unassigning":
    case "updating":
    case "waiting":
    case "warning":
    case "upgrading":
    case "converting":
      return StatusType.WARNING;
    case "down":
    case "error":
    case "failed":
    case "failed-apply":
    case "failed-rollback":
    case "faulty":
    case "internal_error":
    case "missing":
      return StatusType.ERROR;
    case "applied":
    case "blocked":
    case "cancelled":
    case "canceled":
    case "cancelled_returned":
    case "closed":
    case "deleted":
    case "expired":
    case "inactive":
    case "loading":
    case "queued":
    case "terminated":
    case "unavailable":
    default:
      return StatusType.NONE;
  }
};
