import * as React from "react";
import { IStackTokens, Stack } from "@fluentui/react";
import { DeleteButton } from "./SimpleBuilderDeleteButton";
import { TemplateInput } from "./SimpleBuilderInternalTypes";

const containerStyle: React.CSSProperties = { padding: "0 5px 5px 5px", borderBottom: "1px solid #f3f2f1" };

const getLabelFlexBasis = (
  totalFixedWidth: number,
  numberOfFlexColumns: number,
  // 32px is the width of the delete button element
): string => `calc((100% - ${totalFixedWidth}px - 32px) / ${numberOfFlexColumns})`;

const getLabelStyle = (
  flexBasis: React.CSSProperties["flexBasis"],
  width?: number,
  minWidth?: number,
): React.CSSProperties => ({
  flexBasis: width === undefined ? flexBasis : undefined,
  width: width ?? flexBasis,
  minWidth,
  textOverflow: "ellipsis",
  overflow: "hidden",
});

/**
 * @returns an array of tuples containing the label text and its styles
 */
const getLabels = (templateRow: TemplateInput[]): [string | undefined, React.CSSProperties][] => {
  const numberOfFlexColumns = templateRow.filter(child => !(child.props.width || child.props.minWidth)).length;
  const totalFixedWidth = templateRow.reduce((sum, child) => sum + (child.props.width || child.props.minWidth || 0), 0);

  const labels: [string | undefined, React.CSSProperties][] = [];

  templateRow.forEach(child => {
    const flexBasis = getLabelFlexBasis(totalFixedWidth, numberOfFlexColumns);
    const { label, width, minWidth } = child.props;

    labels.push([label, getLabelStyle(flexBasis, width, minWidth)]);
  });

  return labels;
};

export interface HeaderProps {
  /**
   * The template row to base the dimensions off of
   */
  templateRow: TemplateInput[];
  /**
   * Provide styling to the columns container
   */
  tokens: IStackTokens;
}

/**
 * Displays the labels separate from the components.
 * Creates an entry for any components without a label so consistent styling can be applied.
 * It is assumed that inputs will have a label prop, for the purposes of displaying the labels correctly.
 * The widths can be overriden by ensuring the templates expose width and maxWidth props.
 * If no width provided then the labels will share equal space.
 */
export const Header = ({ templateRow, tokens }: HeaderProps):JSX.Element => {
  const elements = React.useMemo(() => getLabels(templateRow).map(
    ([label, style], index) => (
      <span
        key={label || `label-${index}`}
        title={label}
        style={style}
      >
        {label}
      </span>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return (
    <Stack
      horizontal
      horizontalAlign="space-evenly"
      tokens={tokens}
      style={containerStyle}
    >
      {elements}
      <DeleteButton />
    </Stack>
  );
};
