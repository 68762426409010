import * as React from "react";
import { BaseInputProps } from "./BaseInput";
import { FormValues, ValidationState } from "./FormTypes";
import { LabelWrapperProps } from "./LabelWrapperTypes";

export const emptyDivStyle: React.CSSProperties = { paddingLeft: "16px", paddingRight: "16px" };

// Inputs, if rendered with the same props as the template input, will have identical field names
// As the field name must be unique for each element, we need to adjust these field names to be
// unique, but follow a pattern, in order to parse out the relevant information later
export const buildFieldName = (fieldName: string, rowId: number): string => `${fieldName}-${rowId}`;

export const parseFieldName = (fieldName: string): [string, number] => {
  const [field, rowId] = fieldName.split("-");
  return [field, Number.parseInt(rowId, 10)];
};

export const buildRowHtmlIdAttr = (id: number): string => `row-${id}`;

export type TemplateInputProps = Pick<LabelWrapperProps, "label" | "required" | "tooltip" >
& Partial<Pick<BaseInputProps<unknown>, "defaultValue" | "fieldName" | "onChange" | "readOnly" | "width" | "minWidth">>;

export type TemplateInput = React.ReactElement<TemplateInputProps>;

export type StatesUpdateCallback = (
  overall: ValidationState,
  updatedValue: ValidationState, // When UNKNOWN, the field was registered or unregistered
  fieldName: string | undefined, // When undefined, then the row was the resource affected
  rowId: number,
) => void;

export type ValueUpdateCallback = (
  values: FormValues[],
  fieldValue: unknown | undefined, // When undefined, then the resource was deleted
  fieldName: string | undefined, // When undefined, then the row was the resource affected
  rowId: number
) => void;

export interface RowConfig {
  numberOfRows: number;
  hasTemplateRow: boolean;
}

export const getInitialRowConfig = (
  defaultValues: FormValues[] | undefined,
  disableAutoRowAddition: boolean,
  maxRows?: number,
): RowConfig => {
  if (!defaultValues?.length) {
    // As there are no default values, only display a 'template row'.
    return { numberOfRows: !disableAutoRowAddition ? 1 : 0, hasTemplateRow: !disableAutoRowAddition };
  }

  const hasMoreValuesThanMaximum = maxRows && defaultValues.length >= maxRows;

  if (hasMoreValuesThanMaximum) {
    return {
      // we always display the default values even though there may be more values
      // than allowed rows, this way we do not mask any inconsistencies or errors
      numberOfRows: defaultValues.length,
      hasTemplateRow: false,
    };
  }

  return {
    // Otherwise we want to display one more row than there are values to display
    numberOfRows: disableAutoRowAddition ? defaultValues.length : defaultValues.length + 1,
    hasTemplateRow: !disableAutoRowAddition,
  };
};

export const getFocusableElementsWithin = (element: HTMLElement | Element): HTMLElement[] => {
  const focusableElements: NodeListOf<HTMLElement> = element.querySelectorAll(
    // The expected elements to be within the SimpleBuilder that would be focusable are any form inputs,
    // or anything with a positive tab index, and is not hidden
    "input, select, textarea, [tabindex]:not([tabindex='-1']):not([aria-hidden='true'])",
  );

  return Array.from(focusableElements);
};

// This function is for accessibility purposes (keyboard navigation).
// Issue: When the user deletes a row, keyboard focus is lost
// In order to move the focus to the next row we must:
//    - provide each row with a unique ID
//    - call this function with the ID of the current row to be deleted
//    - call this function before the current row is removed from the DOM
export const focusNextInputFrom = (id: string): void => {
  const currentRow = document.getElementById(id);
  const nextRow = currentRow?.nextElementSibling;

  // FIXME: If the maximum rows have been reached and filled out (there is no template row displayed).
  //        And the user deletes the last row. Then there is no row currently in the DOM to be able to focus.
  if (!nextRow) return;

  const nextRowInputs = getFocusableElementsWithin(nextRow);

  if (nextRowInputs.length) {
    nextRowInputs[0].focus();
  }
};
