import * as React from "react";
import { DefaultButton, IButtonStyles, PrimaryButton, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { anchoredPanelLayoutManager, inputWizardLayoutManager } from "../../models/LayoutManager";
import { screenSizeManager } from "../Context/InternalScreenSizeContext";
import { ScreenSizeContext } from "../Context/ScreenSizeContext";
import { MenuLayoutType, ScreenSizeMonitor } from "../Context/ScreenSizeContextTypes";
import { FormInputGroupLayout } from "../Input/FormInputGroup";
import { FormStates } from "../Input/FormInternalTypes";
import { FormValues, ValidationState } from "../Input/FormTypes";
import { AnchoredPanelFooterType } from "../Panel/AnchoredPanelTypes";
import { InputForm, InputFormComponent, InputFormInitialState, InputFormProps } from "./InputForm";
import { SubmitButtonMode } from "./InputFormTypes";

export interface InputFormInlineComponent {
  /**
   * Method to retrieve the form values
   */
  getFormValues: () => FormValues | undefined;
  /**
   * Method to allow resubmitting form values
   */
  allowResubmit: () => void;
}

export interface InputFormInlineProps extends
  Pick<InputFormProps, "onSubmit" | "children"> {
  /**
   * Specify how will submit button initially show (enabled/disabled)
   * @default ENABLE_TILL_CLICKED
   */
  submitButtonMode?: SubmitButtonMode;
  /**
   * Text for submit button. Default: Ok
   */
  submitButtonText?: string;
  /**
   * Text for discard button. Default: Discard
   */
  discardButtonText?: string;
  /**
   * Callback when the form values are discarded from the InputFormInline component
   */
  onDiscard?: () => void;
  /**
   * Component reference for the form
   */
  componentRef?: (ref: InputFormInlineComponent) => void;
  /**
   * Id used to get sub elements of the InputFormInline
   */
  testId?: string;
  /**
   * Initial field values. Used by the component to enable submit button if any has been modified by the user.
   * Setting this prop implicitly will set submitButtonMode to DISABLE_TILL_VALID regardless of what has been set
   * by the consumer.
   */
  initialValues?: FormValues;
  /**
   * Layout for the InputFormInline component
   * @default COMPACT
   */
  layout?: FormInputGroupLayout;
  /**
   * To display the top border for the footer buttons
   * @default false
   */
  footerHasBorder?: boolean;
}

enum PrimaryActionState {
  WAIT_CLICK = "WAIT_CLICK",
  CLICKED = "CLICKED",
}

const btnStyles: IButtonStyles = { root: { height: 25 } };

export interface InputFormInlineTestIds extends ComponentTestIds {
  submitButton: string;
  discardButton: string;
}

export const buildInputFormInlineTestIds = (testId?: string): InputFormInlineTestIds => ({
  component: buildTestId(testId),
  submitButton: buildTestId(testId, "-submit-button"),
  discardButton: buildTestId(testId, "-discard-button"),
});

const InputFormContentId = "input-form-content";

/**
 *The InputFormInline renders the InputForm component that validates
 the form content internally
 */
export const InputFormInline = ({
  layout = FormInputGroupLayout.COMPACT,
  onDiscard,
  onSubmit,
  componentRef,
  initialValues,
  submitButtonMode = initialValues ? SubmitButtonMode.DISABLE_TILL_VALID : SubmitButtonMode.ENABLE_TILL_CLICKED,
  submitButtonText,
  discardButtonText,
  children,
  testId,
  footerHasBorder = false,
}: InputFormInlineProps): JSX.Element => {
  const [formStates, setFormStates] = React.useState<FormStates>({} as FormStates);
  const [overallState, setOverallState] = React.useState<ValidationState>();
  const [primaryAction, setPrimaryAction] = React.useState<PrimaryActionState>(PrimaryActionState.WAIT_CLICK);
  const [initialValuesChanged, setInitialValuesChanged] = React.useState<boolean>(false);
  const screenSizeMonitor = React.useContext<ScreenSizeMonitor | undefined>(ScreenSizeContext);
  const formBodyRef = React.useRef(null);
  const { height: formBodyHeight } = useResizeObserver(formBodyRef);
  const formContentRef = React.useRef<HTMLElement | undefined>();
  const { width: formContainerWidth } = useResizeObserver(formContentRef);
  const [contentLayoutType, setContentLayoutType] = React.useState<FormInputGroupLayout>(layout);

  const formComponentRef = React.useRef<InputFormComponent>();
  const setFormComponentRef = (formComponent: InputFormComponent): void => {
    formComponentRef.current = formComponent;
  };
  const inputFormInlineTestIds = buildInputFormInlineTestIds(testId);
  const overflowType = screenSizeMonitor?.menuLayoutType === MenuLayoutType.TOP_SIDE_DECOUPLED ? "auto" : "inherit";

  const thisRef = React.useRef<InputFormInlineComponent>();

  const footerType = React.useRef<AnchoredPanelFooterType>(
    anchoredPanelLayoutManager.getFooterType(screenSizeManager.getLastPanelHeight()),
  );

  React.useLayoutEffect(() => {
    const inputFormContentLayoutType = inputWizardLayoutManager.getContentLayoutType(formContainerWidth);
    setContentLayoutType(inputFormContentLayoutType);
  }, [formContainerWidth]);

  React.useEffect(() => {
    if (componentRef) {
      thisRef.current = {
        getFormValues: () => formComponentRef.current?.getFormValues(),
        allowResubmit: () => setPrimaryAction(PrimaryActionState.WAIT_CLICK),
      } as InputFormInlineComponent;
      componentRef(thisRef.current);
    }
    formContentRef.current = document.getElementById(InputFormContentId) ?? undefined;
    // Only at mount time. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useLayoutEffect(() => {
    if (formBodyHeight > 0) {
      const anchoredPanelFooterType = anchoredPanelLayoutManager.getFooterType(screenSizeManager.getLastPanelHeight());
      footerType.current = anchoredPanelFooterType;

      // console.log(`>>> ${panelHeight} -> ${anchoredPanelFooterType} -> ${screenSizeManager.menuLayoutType}`);
    }
  }, [formBodyHeight]);

  React.useEffect(() => {
    setOverallState(formStates.overall);
  }, [formStates]);

  React.useEffect(() => {
    if (overallState === ValidationState.INVALID) {
      setPrimaryAction(PrimaryActionState.WAIT_CLICK);
    } else if ((!overallState || overallState === ValidationState.VALID)
      && primaryAction === PrimaryActionState.CLICKED) {
      formComponentRef.current?.submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallState, primaryAction]);

  const onClick = (): void => {
    if (!formComponentRef.current?.isValidationEnabled()) {
      formComponentRef.current?.enableValidation();
    }
    setPrimaryAction(PrimaryActionState.CLICKED);
  };

  const onFormStatesUpdate = (states: FormStates): void => setFormStates(states);

  const submitButtonDisabled = (
    (submitButtonMode === SubmitButtonMode.DISABLE_TILL_VALID && (
      (formStates.overall === ValidationState.INVALID) || !initialValuesChanged
    ))
    // ENABLE_TILL_CLICKED will not work as intended!!
    // This is due to initial input state now potentially including INVALID, not just UNKNOWN.
    || (submitButtonMode === SubmitButtonMode.ENABLE_TILL_CLICKED && formStates.overall === ValidationState.INVALID)
  );
  const internalInitialState: InputFormInitialState = {
    fieldValues: initialValues,
    haveChanged: setInitialValuesChanged,
  };

  const footerStyles: React.CSSProperties = {
    position: footerType.current === AnchoredPanelFooterType.FIXED_AT_BOTTOM ? "sticky" : undefined,
    bottom: 0,
    flexBasis: 45,
    zIndex: "1",
    backgroundColor: "white",
    paddingBottom: "20px",
    paddingTop: "20px",
    borderTop: footerHasBorder ? "1px solid rgb(214, 214, 214)" : undefined,
  };

  const contentSytles: React.CSSProperties = {
    flexGrow: 1,
    flexBasis: 90,
    overflow: overflowType,
  };

  const bodyStyles: React.CSSProperties = {
    display: "flex",
    flexFlow: "column",
    height: "100%",
  };

  return (
    <InputForm
      onSubmit={onSubmit}
      componentRef={setFormComponentRef}
      layout={contentLayoutType === FormInputGroupLayout.WIDE
        ? FormInputGroupLayout.WIDE
        : FormInputGroupLayout.COMPACT}
      onStatesUpdate={onFormStatesUpdate}
      initialState={internalInitialState}
    >
      <div ref={formBodyRef} style={bodyStyles}>
        <Stack style={contentSytles} id={InputFormContentId}>
          {children}
        </Stack>
        <Stack
          style={footerStyles}
          tokens={{ childrenGap: 8 }}
          horizontal
        >
          <PrimaryButton
            styles={btnStyles}
            onClick={onClick}
            disabled={submitButtonDisabled}
            data-test-id={inputFormInlineTestIds.submitButton}
          >
            {submitButtonText || Messages.common.ok()}
          </PrimaryButton>
          <DefaultButton
            data-test-id={inputFormInlineTestIds.discardButton}
            styles={btnStyles}
            onClick={onDiscard}
            disabled={!initialValuesChanged}
          >
            {discardButtonText || Messages.common.discard()}
          </DefaultButton>
        </Stack>
      </div>
    </InputForm>
  );
};
