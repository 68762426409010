import * as React from "react";
import { Pivot, PivotItem, Stack } from "@fluentui/react";
import { Listing, ListingProps } from "../Listing";

export type ListContentProps<T> = Pick<ListingProps<T>,
"items" | "listColumns" | "onRenderFooter" | "emptyList" | "sorting">;

export interface RecentViewContent<T> {
  /**
   * Title for the listing section
   */
  title: string;
  /**
   * Item & column details for the list
   */
  listContent: ListContentProps<T>;
  /**
   * Test id for the section
   */
  testId?: string;
}

export interface OverviewRecentViewSection<T> {
  /**
   * Title of the section
   */
  title?: string;
  /**
   * Content contains the title for the section and list items
   */
  content?: RecentViewContent<T>[];
  /**
   * Test id for the title
   */
  testId?: string;
}

export const OverviewRecentViewResourceSectionTemplate = <T extends object > (
  { title, content, testId }: OverviewRecentViewSection<T>): JSX.Element => (
    <Stack style={{ height: "100%" }}>
      {title && (
      <span
        style={{
          fontWeight: 600,
          fontSize: "18px",
          marginBottom: "18px",
        }}
        data-test-id={testId}
      >
        {title}
      </span>
      )}
      <Pivot>
        {content?.map((o, index) => (
          <PivotItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-pivot-item-overview-section`}
            headerButtonProps={{ "data-test-id": o.testId || "" }}
            headerText={o.title}
          >
            <Listing {...o.listContent} />
          </PivotItem>
        ))}
      </Pivot>
    </Stack>
  );
