import * as React from "react";
import { InternalConfirmDialog, InternalConfirmDialogProps } from "./InternalConfirmDialog";

export type ConfirmDialogProps = Omit<InternalConfirmDialogProps, "styles">;

export const ConfirmDialog = ({ ...props } : ConfirmDialogProps): JSX.Element => (
  <InternalConfirmDialog
    {...props}
  />
);
