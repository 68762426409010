import * as React from "react";
import {
  Callout,
  DefaultButton,
  FocusZone,
  FocusZoneTabbableElements,
  FontWeights,
  IButtonStyles,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { InfoBlock } from "../InfoBlock/InfoBlock";
import { buildConfirmDialogTestIds, ConfirmDialogType, InfoBlockType } from "./ConfirmDialogTypes";

export interface InternalConfirmDialogProps {
  /**
   * Hide cancel button and only show the confirm button
   * @default false
   */
  useConfirmBtnOnly?: boolean;
  /**
  * Cancel button text
  * @default No
  */
  cancelBtnText?: string;
  /**
  * Primary button text
  * @default Yes
  */
  confirmBtnText?: string;
  /**
  * Description of the dialog
  */
  description: string | JSX.Element;
  /**
   * An infoblock to display inside the dialog
   */
  infoBlock?: InfoBlockType;
  /**
  * Confirm callback
  */
  onConfirm?: () => void;
  /**
  * Cancel callback
  */
  onCancel?: () => void;
  /**
  * Dismiss dialog
  */
  onDismiss?: () => void;
  /**
   * Dialog, title, description & button styles
  */
  styles?: {
    /**
    * Width of outer container
    * @default 320px
    */
    dialogWidth?: string | number;
    /**
    * Dialog padding
    * @default 12px 18px 14px
    */
    dialogPadding?: string;
    /**
    * Bottom marging for title
    * @default 5
    */
    titleBottomMargin?: number;
    /**
    * Title text variant
    * @default 18px
    */
    titleVariant?: string;
    /**
    * Description text variant
    * @default 14px
    */
    descVariant?: string;
    /**
    * Button\'s top margin
    * @default 15
    */
    buttonsTopMargin?: number;
  };
  /**
  * The target with which confirm dialog will be connected.
  */
  targetId: string;
  /**
  * Title of the dialog
  */
  title?: string;
  /**
  * Confirm dialog use type
  */
  type : ConfirmDialogType.COMMAND_BAR | ConfirmDialogType.ACTION_MENU;
  /**
   * Id used to get the confirmation callout elements for unit tests. Fluent will add a test id to
   * the wrapper and the callout so querying for it will return an array. The callout test id will be at index 1.
   */
  testId?: string;
}

const btnStyles: IButtonStyles = {
  root: {
    height: 25,
    "&:focus": {
      outline: "1px solid white",
      outlineOffset: -3,
    },
  },
};

/**
 *  Confirm dialog can be used to require secondary confirmation
 *  from a user before executing the action.
 */
export const InternalConfirmDialog = ({
  useConfirmBtnOnly = false,
  cancelBtnText = Messages.common.no(),
  confirmBtnText = Messages.common.yes(),
  description,
  infoBlock,
  onConfirm,
  onCancel,
  onDismiss,
  styles,
  targetId,
  title,
  type,
  testId,
}: InternalConfirmDialogProps): JSX.Element => {
  const isBeakVisible = (type === ConfirmDialogType.ACTION_MENU);

  return (
    <Callout
      data-test-id={buildConfirmDialogTestIds(testId).component}
      role="alertdialog"
      target={`#${targetId}`}
      isBeakVisible={isBeakVisible}
      setInitialFocus
      onDismiss={onDismiss}
      styles={{
        root: {
          width: styles?.dialogWidth || 320,
          padding: styles?.dialogPadding || "12px 18px 14px",
          backgroundColor: "#FFFFFF",
        },
      }}
    >
      <Text
        block
        styles={{
          root: {
            marginBottom: styles?.titleBottomMargin || 5,
            fontWeight: FontWeights.semibold,
            fontSize: styles?.titleVariant || "18px",
            fontStyle: "normal",
          },
        }}
      >
        {title}
      </Text>
      {infoBlock && (
      <div style={{ marginBottom: "10px" }}>
        <InfoBlock messageType={infoBlock.messageType} message={infoBlock.message} />
      </div>
      )}
      {typeof description === "string"
        ? (
          <Text
            block
            styles={{
              root: {
                fontSize: styles?.descVariant || "14px",
                fontWeight: FontWeights.regular,
                fontStyle: "normal",
              },
            }}
          >
            {description}
          </Text>
        ) : (description)}
      <FocusZone
        handleTabKey={FocusZoneTabbableElements.all}
        isCircularNavigation
      >
        <Stack
          styles={{
            root: {
              justifyContent: "flex-start",
              marginTop: styles?.buttonsTopMargin || 15,
            },
          }}
          tokens={{ childrenGap: 8 }}
          horizontal
        >
          <PrimaryButton
            data-test-id={buildConfirmDialogTestIds(testId).confirmButton}
            onClick={onConfirm}
            styles={btnStyles}
          >
            {confirmBtnText}
          </PrimaryButton>
          {!useConfirmBtnOnly && (
            <DefaultButton
              data-test-id={buildConfirmDialogTestIds(testId).cancelButton}
              onClick={onCancel}
              styles={btnStyles}
            >
              {cancelBtnText}
            </DefaultButton>
          )}
        </Stack>
      </FocusZone>
    </Callout>
  );
};
