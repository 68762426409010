import { StatesUpdateCallback, ValueUpdateCallback } from "../Input/FormInternalTypes";
import { FormFieldErrors, FormValues } from "../Input/FormTypes";

export enum InputWizardTabNavigation {
  /**
   * User allowed to navigate between tabs in any order
   */
  FREE = "FREE",
  /**
   * User is only allowed to navigate between the tabs sequentially going forward but can jump to any tab going backward
   */
  SEQUENTIAL = "SEQUENTIAL",
}

export interface InputWizardComponent {
  /**
   * Method to retrieve the map for the field and it corresponding label
   */
  getFormFieldLabelMap: () => Map<string, string>;
  /**
   * Method to retrieve the map for the field and it corresponding group name
   */
  getFormFieldGroupMap: () => Map<string, string>;
  /**
   * Method to retrieve the form values
   */
  getFormValues: () => FormValues | undefined;
  /**
   * Method when validation is enabled on the InputWizardComponent
   */
  enableValidation: (group?: string, noNavigation?: boolean) => void;
  /**
   * Method to check if validation is enabled on the InputWizardComponent
   */
  isValidationEnabled: (group?: string) => boolean | undefined;
  /**
   * Method to check if there are fields with validation
   */
  hasValidation: (group?: string) => boolean | undefined;
  /**
   * Method for submit action on the InputWizardComponent
   */
  submit: () => void;
  /*
  * Method for next action on the InputWizardComponent
  */
  next: () => void;
  /**
   * Method for the previous action on the InputWizardComponent
   */
  previous: () => void;
}

export interface InputWizardItem {
  /**
   * Header for the InputWizardItem
   */
  header: string;
  /**
   * Group name for the InputWizardItem
   */
  groupName: string;
  /**
   * Content to be displayed for the InputWizardItem
   */
  content: React.ReactNode;
  /**
   * Description for the InputWizardItem
   */
  description?: string;
  /**
   * Callback when the InputWizardItem is selected
   */
  onSelected?: (ndx: number) => void;
  /**
   * Callback when the InputWizardItem is unselected
   */
  onUnselecting?: (ndx: number) => void;
  /**
   * data-test-id HTML atrribute value for the tab
   */
  testId?: string;
}

export interface InputWizardProps {
  /**
   * Component reference for the InputWizard
   */
  componentRef?: (wizard: InputWizardComponent) => void;
  /**
   * Items to be displayed on the InputWizard
   */
  items: InputWizardItem[];
  /**
   * Determines how the user can navigate between the different tabs.
   */
  tabNavigation?: InputWizardTabNavigation;
  /**
   * Callback for the submit action on the InputWizard
   */
  onSubmit: (formValues: FormValues) => void;
  /**
   * Callback for the form state update on the InputWizard
   */
  onStatesUpdate?: StatesUpdateCallback;
  /**
   * Callback for the field value update on the InputWizard
   */
  onValueUpdate?: ValueUpdateCallback;
  /**
   * Callback to validate values on the InputWizard
   */
  validator?: (formValues: FormValues) => FormFieldErrors[] | undefined;
}
