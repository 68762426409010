/* eslint-disable no-underscore-dangle */
import { uniqueGUID } from "../../helpers/util";
import { FavoriteAccessItem } from "./FavoriteAccessTypes";

const LocalStorageFavoriteAccessKey = "O4A-FAV";

type FavoriteAccessListener = (favoriteItems: FavoriteAccessItem[]) => void;

class FavoriteAccessManager {
  private _favoriteItems: Map<string, FavoriteAccessItem> = new Map<string, FavoriteAccessItem>();

  private _listeners: Map<string, FavoriteAccessListener> = new Map<string, FavoriteAccessListener>();

  constructor() {
    // Fetch the list from local storage and populate the in-memory map
    const favoriteAccessStored = window.localStorage.getItem(LocalStorageFavoriteAccessKey);
    if (favoriteAccessStored) {
      const favoriteAccessItems = JSON.parse(favoriteAccessStored) as FavoriteAccessItem[];
      if (Array.isArray(favoriteAccessItems)) {
        favoriteAccessItems.forEach(item => this._favoriteItems.set(item.id, item));
      }
    }
  }

  public addListener = (listener: FavoriteAccessListener): string => {
    const listenerId = uniqueGUID();
    this._listeners.set(listenerId, listener);
    listener(this.items); // invoke the listener upon registration to pass the most recent list
    return listenerId;
  };

  public removeListener = (listenerId: string): void => {
    this._listeners.delete(listenerId);
  };

  public get items(): FavoriteAccessItem[] { return [...this._favoriteItems.values()]; }

  public addItem = (item : FavoriteAccessItem): void => { this._favoriteItems.set(item.id, item); };

  public isFavorite = (itemId: string): boolean => this._favoriteItems.has(itemId);

  public clearItem = (itemId: string): void => { this._favoriteItems.delete(itemId); };
}

export const favoriteAccessManager = new Proxy(new FavoriteAccessManager(), {
  get: (target, prop) => {
    const targetProp = prop in target ? target[prop as keyof typeof target] : undefined;
    // Intercept all methods that manipulate the items list to keep the local storage in sync
    // and to call the listeners with the modified list
    if (typeof targetProp === "function"
      && (prop === "addItem" || prop === "clearItem")
    ) {
      return new Proxy(targetProp, {
        apply: (funcTarget, thisArg, argumentsList) => {
          const result = Reflect.apply(funcTarget, thisArg, argumentsList);
          const items: FavoriteAccessItem[] = Array.from(Reflect.get(target, "_favoriteItems").values());
          window.localStorage.setItem(LocalStorageFavoriteAccessKey, JSON.stringify(items));
          const listeners: FavoriteAccessListener[] = Array.from(Reflect.get(target, "_listeners").values());
          listeners.forEach(listener => listener(items));
          return result;
        },
      });
    }
    return Reflect.get(target, prop);
  },
});
