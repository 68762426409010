/* eslint-disable no-useless-constructor */
/* eslint-disable no-empty-function */
import { TelemetryClient } from "./TelemetryClient";

export class MetricsClient {
  private constructor() {}

  /**
   * Function to get an instance of MetricsClient
   */
  public static getMetricsClient() {
    return new MetricsClient();
  }

  /**
   * Function used by the user to send a metric
   * @param name
   * @param value
   */
  public sendMetric(name: string, value: number, dimensions?: Record<string, string>) {
    TelemetryClient.processMetric(name, value, dimensions);
  }
}
