import * as React from "react";

export interface AppState {
  basePath: string;
  regionName: string;
  homeKey?: string;
  homeLabel?: string;
  homePath?: string;
  headerHeight: string | number;
  footerHeight?: string | number;
}

export type AppContext<T extends AppState> = React.Context<T>;

export function createAppContext<T extends AppState>(initValue: T): AppContext<T> {
  return React.createContext<T>(initValue);
}
