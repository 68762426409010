/* eslint-disable no-useless-constructor */
/* eslint-disable no-empty-function */
export default class Timer {
  private timerID: number | undefined;

  /**
   *
   * @param initialInterval time to execute next iteration
   * @param fn function to execute when the interval completes
   */
  constructor(private initialInterval: number, private fn: () => void | Promise<void>) {}

  /**
   * start the timer if the timer is already running will be ignore
   */
  public start() {
    this.timerID = window.setInterval(async () => {
      try {
        await this.fn();
      } catch (e) {
        console.debug(e);
      }
    }, this.initialInterval);
  }

  /**
   * stop the interval and reset the timer
   */
  public stop() {
    if (this.timerID) {
      clearInterval(this.timerID);
    }
    this.timerID = undefined;
  }
}
