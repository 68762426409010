import { buildTestId } from "../../helpers/testIdHelper";
import { SelectProps } from "./Select";
import { SimpleBuilderProps } from "./SimpleBuilder";
import { buildSimpleBuilderTestIds } from "./SimpleBuilderTypes";

export interface OrderedSelectBuilderProps
  extends Pick<
  SimpleBuilderProps, "fieldName" | "testId" | "disableAutoRowAddition" | "groupName"
  >, Pick<SelectProps, "defaultValue" | "options">{
  /**
   * Values to be provided when resetting
   */
  factoryValues?: string[]
  /**
   * Callback to validate the field value
   */
  validator?: (value: string[] | undefined) => string[] | undefined;
}

export interface OrderedSelectBuilderTestIds {
  resetToDefaults: string;
  moveUp: string;
  moveDown: string;
  addColumn: string;
}

export const buildOrderedSelectBuilderTestIds = (testId?: string): OrderedSelectBuilderTestIds => ({
  ...buildSimpleBuilderTestIds(testId),
  resetToDefaults: buildTestId(testId, "-reset-to-defaults"),
  moveUp: buildTestId(testId, "-move-up"),
  moveDown: buildTestId(testId, "-move-down"),
  addColumn: buildTestId(testId, "-add-column"),
});
