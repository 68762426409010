import * as React from "react";
import {
  ICalloutProps,
  IconButton,
  IIconProps,
  ITooltipHostStyles,
  mergeStyleSets,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { getFormattedDateTime } from "../../helpers/dateTimeUtils";
import { NoValue } from "../NoValue/NoValue";

const styles = mergeStyleSets({
  callout: {
    width: 360,
    padding: "18px",
  },
  button: {
    display: "table",
    boxSizing: "border-box",
    minWidth: 0,
    height: "20px",
    width: "20px",
    margin: "2px",
    fontSize: "13px",
    lineHeight: 1.385,
    position: "absolute",
  },
  icon: {
    boxSizing: "border-box",
    textAlign: "center",
    verticalAlign: "middle",
    width: "12px !important",
    height: "12px !important",
    padding: "0 important",
    color: "rgb(0,0,0)",
    marginTop: "-5px !important",
    fontSize: "13px !important",
  },
});

export interface DateTimeDisplayProps {
  date: Date | undefined;
  /**
 * Clipboard button will be shown by default
 *  @default: false
 */
  hideClipboardCopy?: boolean;
}

/**
 * The DateTimeDisplay component can be used to display the datetime
 * in MM/DD/YYYY HH:MM:SS AM/PM format
 */
export const DateTimeDisplay = ({ date, hideClipboardCopy = false }: DateTimeDisplayProps): JSX.Element => {
  const [copied, setCopied] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const tooltipId = useId("tooltip");
  const buttonId = useId("targetButton");

  if (date) {
    const formattedDate = getFormattedDateTime(date);

    const showElement = isHovered ? 1 : 0;
    const calloutProps: ICalloutProps = { gapSpace: 0, target: `#${buttonId}` };
    const iconProps: IIconProps = { iconName: "Copy", "aria-describedby": tooltipId, className: styles.icon };
    const hostStyles: Partial<ITooltipHostStyles> = {
      root: {
        display: "flex",
        flex: "auto",
        maxWidth: "24px",
        width: "20px",
        opacity: showElement,
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const copyLabelMetaItemValue = (event): void => {
      if (!copied) setCopied(true);
      navigator.clipboard.writeText(formattedDate);
    };

    const showCopy = (): void => {
      if (!hideClipboardCopy) setIsHovered(true);
    };

    const hideCopy = (): void => {
      if (!hideClipboardCopy) {
        setIsHovered(false);
        if (copied) setCopied(false);
      }
    };

    const clipboardCopy = (): JSX.Element => (
      <TooltipHost
        content={copied ? Messages.actions.copiedToClipboard() : Messages.actions.copyToClipboard()}
        id={tooltipId}
        calloutProps={calloutProps}
        styles={hostStyles}
      >
        <IconButton
          id={buttonId}
          onClick={copyLabelMetaItemValue}
          className={styles.button}
          iconProps={iconProps}
          aria-describedby={tooltipId}
          styles={{ root: { opacity: showElement } }}
        />
      </TooltipHost>
    );
    return (
      <Stack
        horizontal
        style={{ display: "flex", width: "100%" }}
        onMouseEnter={showCopy}
        onMouseLeave={hideCopy}
        onFocus={showCopy}
        onBlur={hideCopy}
      >
        <Text
          title={formattedDate}
          aria-label={formattedDate}
          style={{ width: hideClipboardCopy ? "100%" : "calc(100% - 24px)", maxWidth: "max-content" }}
        >
          {formattedDate}
        </Text>
        {!hideClipboardCopy && clipboardCopy()}
      </Stack>
    );
  }
  return <NoValue />;
};
