import * as React from "react";
import {
  IButtonStyles,
  IIconProps,
  IStyleFunctionOrObject,
  ITextFieldStyleProps,
  ITextFieldStyles,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { useErrors } from "../../hooks/useErrors";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  InternalFormContext,
  InternalFormState,
} from "./FormInternalTypes";
import { ValidationState } from "./FormTypes";
import { LabelWrapper } from "./LabelWrapper";
import { buildLabelWrapperTestIds, LabelWrapperProps, LabelWrapperTestIds } from "./LabelWrapperTypes";

const folderIcon: IIconProps = { iconName: "FolderHorizontal" };
const buttonDimension = 24;
const btnStyles: IButtonStyles = {
  root: {
    height: buttonDimension,
    width: buttonDimension,
    padding: 0,
    minWidth: buttonDimension,
  },
};
const inputStyle: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = {
  root: { width: `calc(100% - ${buttonDimension}px)` },
  fieldGroup: {
    height: buttonDimension,
    minHeight: buttonDimension,
  },
  field: { cursor: "pointer" },
};

const fileInputStyle = { marginTop: "5px", width: "100%", minWidth: `${107 + buttonDimension}px` };

export interface FileUploadTestIds extends ComponentTestIds, LabelWrapperTestIds {
  button: string;
  display: string;
}

export const buildFileUploadTestIds = (testId?: string): FileUploadTestIds => ({
  ...buildLabelWrapperTestIds(testId),
  button: buildTestId(testId, "-button"),
  display: buildTestId(testId, "-display"),
  component: buildTestId(testId),
});

// Does not have a default value as there is no browser API to force retrieval of a file from a user's machine
export type FileUploadProps = Omit<BaseInputProps<never, string | undefined>, "defaultValue">
& Pick<LabelWrapperProps, "label">;

/**
 * In order to retrieve the file contents the consumer must use the componentRef.
 * The form values will only contain the file name.
 * Supports uploading one file at a time
 */
export const FileUpload = ({
  testId,
  fieldName,
  groupName,
  label,
  onChange,
  required,
  statusInfo,
  subField,
  tooltip,
  validator,
}: FileUploadProps): JSX.Element => {
  // TextField must be kept controlled (cannot have undefined as a value)
  const [fileName, setFileName] = React.useState<string>("");
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>();
  const selectedFile = React.useRef<File | undefined>();

  const { groupName: layoutGroup, layout } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const group = groupName || layoutGroup;
  const form: InternalFormState = React.useContext(InternalFormContext);

  if (!Object.keys(form).length) {
    throw new Error("FileUpload should be used within form");
  }

  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);

  const [allErrors, setFieldErrors] = useErrors(fieldName, group);

  const fileUploadTestIds = buildFileUploadTestIds(testId);

  const fieldValidation = (): void => {
    const name = form.getValue<File>(fieldName, group)?.name;
    const errorMsg = validator ? validator(name) : undefined;
    const requiredMessage = required
      ? (!name ? [Messages.validation.requiredValidation()] : undefined)
      : undefined;
    const errors = [...requiredMessage || [], ...errorMsg || []];
    form.setFieldState(
      errors && errors?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );
    setFieldErrors(errors);
  };

  const internalOnChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    evt.preventDefault();

    selectedFile.current = evt.target.files?.length ? evt.target.files[0] : undefined;
    form.setValue(selectedFile.current, fieldName, group);

    const uploadedFileName = selectedFile.current?.name;
    setFileName(uploadedFileName ?? "");
    if (validator || required) {
      fieldValidation();
    }

    // Should I pass the file name, or the 'File' (Not the file contents, but the File implementation)
    onChange?.(uploadedFileName);
  };

  const extractInputValue = (formValue: File | undefined): File | undefined => formValue;

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    if (validator || required) {
      form.registerField(fieldName, group);
    }

    // There is never a default value, so will always be invalid if required
    if (required) {
      form.setFieldState(ValidationState.INVALID, fieldName, group);
    }

    return function cleanup() {
      if (validator || required) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
    };
  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[group] === FormValidationMode.ON) {
      if (validator || required) {
        fieldValidation();
      }
    }
    // Only if validation mode changes. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  return (
    <LabelWrapper
      errors={allErrors}
      fieldName={fieldName}
      layout={layout}
      label={label}
      required={required}
      statusInfo={statusInfo}
      subField={subField}
      tooltip={tooltip}
      testId={testId}
    >
      <div style={fileInputStyle}>
        <Stack
          tokens={{ childrenGap: 8 }}
          horizontal
        >
          <TextField
            data-test-id={fileUploadTestIds.display}
            onClick={() => inputRef && inputRef.click()}
            styles={inputStyle}
            placeholder={Messages.actions.selectFile()}
            value={fileName}
            readOnly
          />
          <PrimaryButton
            iconProps={folderIcon}
            onClick={() => inputRef && inputRef.click()}
            styles={btnStyles}
            data-test-id={fileUploadTestIds.button}
            ariaLabel={Messages.ariaLabel.fileUpload()}
          />
        </Stack>
        <input
          ref={input => setInputRef(input)}
          type="file"
          id="File"
          name="File"
          style={{ display: "none" }}
          onChange={internalOnChange}
          data-test-id={fileUploadTestIds.component}
        />
      </div>
    </LabelWrapper>
  );
};
