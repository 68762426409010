/* eslint-disable no-console */
import { getWindow } from "../utils/internalUtils";
import { EventReport } from "models/InfinityOptions";
import { Status, InfinityData, InfinityTransmitConfigs } from "../models";
import { addInfinityScript, addPrefixKey, infinityFailCallback } from "../utils/infinityUtils";
import {
  INFINITY_ALREADY_INITIALIZED,
  INFINITY_INIT_TAG_ID,
  INFINITY_INVALID_PARAMS,
  INFINITY_NOT_INITIALIZED,
  INFINITY_REMOVED_INFO,
  INFINITY_TAG_TIMEOUT,
} from "../utils/constants";

export class AnalyticsClient {
  private static messageQueue: EventReport[] = [];
  private static processingQueue = false;
  private static status = Status.NotInitialized;
  protected static initializationTimeWaited = 0;
  private static window = getWindow();

  /**
   * Initialize the Infinity Tag.
   */
  public static initialize(scriptUrl: string, shouldTrack: boolean): void {
    if (!shouldTrack) {
      this.status = Status.Disabled;
      return;
    }

    // TODO: MCUX-254 - Add generic exceptions and avoid logging to browser dev console
    if (this.status !== Status.NotInitialized) {
      throw new Error(INFINITY_ALREADY_INITIALIZED);
    }

    if (!scriptUrl || typeof scriptUrl !== "string") {
      throw new Error(INFINITY_INVALID_PARAMS);
    }

    addInfinityScript(INFINITY_INIT_TAG_ID, scriptUrl, this.infinityScriptErrorCallback);
    this.status = Status.Initialized;

    AnalyticsClient.configurator();
  }

  public static terminate(): void {
    try {
      this.status = Status.NotInitialized;
      const tag = document.getElementById(INFINITY_INIT_TAG_ID);
      tag && tag.remove();
      window.ORA && delete window.ORA;
      if (tag || window.ORA) {
        console.debug(INFINITY_REMOVED_INFO);
      }
    } catch (e) {
      console.error("error unloading the ORA object");
    }
  }

  /**
   * Track an event with infinity analytics
   * @param origin the origin of the tracking event
   * @param data the list of key-value pairs that needs to be sent to infinity
   * @param shouldTrack checks if the Infinity Tag should track a user
   * @param prefixKey value representing is prefix string (e.g. "oci.*") should be added before the key
   */
  public static track = (
    origin: string,
    data: InfinityData,
    prefixKey: string = "",
    shouldTrack: boolean = true,
  ): void => {
    if (!shouldTrack) {
      this.status === Status.Disabled;
      console.debug(`Analytics > event-> ${JSON.stringify(data)}`);
      return;
    }

    if (this.status === Status.NotInitialized) {
      throw new Error(INFINITY_NOT_INITIALIZED);
    }
    if (this.status === Status.Error) {
      return;
    }

    data["page-uri"] = this.window.location.pathname;
    data.querystringparams = this.window.location.search;

    if (!this.window.ORA || !this.window.ORA.click) {
      this.messageQueue.push({ origin, data, prefixKey });
      return;
    }

    if (this.messageQueue.length > 0 && !this.processingQueue && this.status === Status.Ready) {
      this.flushQueue();
    }
    this.collect(origin, data, prefixKey);
  };

  // loops @ 100ms for the OAC tag, failing at 60 seconds. If its loaded, it configures it.
  // this catches failures in initializeTag
  private static configurator(): void {
    setTimeout(() => {
      if (this.initializationTimeWaited > INFINITY_TAG_TIMEOUT) {
        console.error(`Failed to load the infinity tag in ${INFINITY_TAG_TIMEOUT / 1000} seconds`);
        return;
      }
      if (typeof this.window.ORA !== "object") {
        this.initializationTimeWaited += 100;
        return this.configurator();
      }
      this.status = Status.Ready;

      // clean the queue if there is anything there
      if (this.messageQueue.length > 0 && !this.processingQueue) {
        this.flushQueue();
      }
      console.debug("Infinity Analytics > Configured");
    }, 100);
  }

  private static flushQueue = (): void => {
    this.processingQueue = true;
    this.messageQueue.forEach(message => {
      this.collect(message.origin, message.data, message.prefixKey);
    });
    this.messageQueue = [];
    this.processingQueue = false;
  };

  private static collect = (origin: string, data: InfinityData, prefixKey: string): void => {
    try {
      data.origin = origin;
      this.window.ORA[data.eventType === "route-hit" ? "view" : "click"]({
        config: this.transmitConfiguration(),
        data: addPrefixKey(data, prefixKey),
      });
    } catch (error) {
      console.error("Unable to send data to infinity", error);
    }
  };

  private static transmitConfiguration = (): InfinityTransmitConfigs => ({
    callbacks: {
      fail: infinityFailCallback,
    },
  });

  private static infinityScriptErrorCallback = function () {
    this.status = Status.Error;
    console.error("Error Loading the script tag. Unable to send data to Infinity");
  };
}
