import * as React from "react";
import {
  DefaultButton,
  Dropdown,
  IButtonStyles,
  IDropdownOption,
  IDropdownStyles,
  IStackTokens,
  mergeStyleSets,
  ResponsiveMode,
  Stack,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { listingLayoutManager } from "../../models/LayoutManager";
import { ListingPaginationLayoutType } from "./ListingTypes";

interface ListingPaginationProps {
  currentPage: number;
  pageCount: number;
  onPrevious?: (currentPage: number) => void;
  onNext?: (currentPage: number) => void;
  onPageSelect?: (currentPage: number) => void;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  root: { height: "24px", width: "52px" },
  title: {
    height: "24px",
    width: "52px",
    lineHeight: "23px",
  },
  caretDownWrapper: { top: "-3px" },
  caretDown: { fontWeight: 900 },
};

const classNames = mergeStyleSets({
  paginationLabel: {
    lineHeight: "normal",
    fontWeight: 400,
    fontSize: "13px",
    fill: "rgb(0, 0, 0)",
    color: "rgb(50, 49, 48)",
    height: "17px",
    outlineColor: "rgb(50, 49, 48)",
    outlineStyle: "none",
    outlineWidth: "0px",
    textSizeAdjust: "100%",
    marginTop: "4px",
  },
});

export const ListingPagination = ({
  currentPage,
  pageCount,
  onPrevious,
  onNext,
  onPageSelect,
}: ListingPaginationProps): JSX.Element => {
  const stackTokens: IStackTokens = { childrenGap: 10 };
  const ref = React.useRef(null);
  const { width } = useResizeObserver(ref);
  const layoutType = listingLayoutManager.getPaginationType(width);

  const getPagesOptions = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [];
    for (let i = 1; i <= pageCount; i++) {
      options.push({
        key: i,
        text: i.toString(),
      });
    }
    return options;
  };

  const onPreviousClick = (): void => {
    onPrevious?.(currentPage);
  };

  const onNextClick = (): void => {
    onNext?.(currentPage);
  };

  const onPageChange = (_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    onPageSelect?.(item?.key as number);
  };

  const buttonStyles: IButtonStyles = {
    root: {
      padding: "0px, 5px",
      height: "24px",
      minWidth: "31px",
      width: layoutType === ListingPaginationLayoutType.WIDE ? "unset" : "31px",
      borderColor: "#323130",
    },
    rootDisabled: { borderColor: "#c8c6c4" },
    label: {
      paddingBottom: "2px",
      fontSize: 13,
      fontWeight: 700,
    },
  };

  return (
    <div ref={ref}>
      <Stack
        className="listing-pagination"
        style={{
          bottom: "0px",
          padding: "20px 15px",
          width: "auto",
          backgroundColor: "rgb(255, 255, 255)",
        }}
        horizontal
        tokens={stackTokens}
      >
        <DefaultButton
          styles={buttonStyles}
          text={`< ${layoutType === ListingPaginationLayoutType.WIDE ? Messages.pagination.previous() : ""}`}
          disabled={currentPage === 1}
          onClick={onPreviousClick}
        />
        <div className={classNames.paginationLabel}>{Messages.pagination.page()}</div>
        <Dropdown
          styles={dropdownStyles}
          ariaLabel={Messages.ariaLabel.page()}
          options={getPagesOptions()}
          defaultSelectedKey={currentPage}
          onChange={onPageChange}
          responsiveMode={ResponsiveMode.large}
        />
        <div className={classNames.paginationLabel}>{Messages.pagination.of(pageCount.toString())}</div>
        <DefaultButton
          styles={buttonStyles}
          text={`${layoutType === ListingPaginationLayoutType.WIDE ? Messages.pagination.next() : ""} >`}
          onClick={onNextClick}
          disabled={currentPage === pageCount}
        />
      </Stack>
    </div>
  );
};
