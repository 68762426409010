import * as React from "react";
import { screenSizeManager } from "./InternalScreenSizeContext";
import { DetailViewsMenuZoomLayout, MenuLayoutType, ScreenSizeMonitor } from "./ScreenSizeContextTypes";

export const ScreenSizeContext = React.createContext<ScreenSizeMonitor | undefined>(undefined);

export const ScreenSizeContextProvider: React.FC = ({ children }): JSX.Element => {
  const getDetailViewsMenu = (): React.ReactNode => screenSizeManager.getDetailViewsMenu();
  // eslint-disable-next-line max-len
  const getDetailViewsMenuZoomLayout = (): DetailViewsMenuZoomLayout | undefined => screenSizeManager.getDetailViewsMenuZoomLayout();
  const getSearchText = (): string => screenSizeManager.getSearchText();
  const setSearchText = (searchText:string): void => screenSizeManager.setSearchText(searchText);

  const [screenSizeMonitor, setScreenSizeMonitor] = React.useState<ScreenSizeMonitor>({
    panelHeight: screenSizeManager.panelHeight,
    menuLayoutType: screenSizeManager.menuLayoutType,
    getSearchText,
    setSearchText,
    getDetailViewsMenu,
    getDetailViewsMenuZoomLayout,
  } as ScreenSizeMonitor);

  const [menuLayoutType, setMenuLayoutType] = React.useState<MenuLayoutType>(screenSizeManager.menuLayoutType);
  const [panelHeight, setPanelHeight] = React.useState<number>(screenSizeManager.panelHeight);

  React.useEffect(() => {
    screenSizeManager.setNotifyMenuLayoutChange(setMenuLayoutType);
    screenSizeManager.setNotifyPanelHeightChange(setPanelHeight);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setScreenSizeMonitor({
      panelHeight,
      menuLayoutType,
      getSearchText,
      setSearchText,
      getDetailViewsMenu,
      getDetailViewsMenuZoomLayout,
    } as ScreenSizeMonitor);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuLayoutType, panelHeight]);

  return (
    <ScreenSizeContext.Provider value={screenSizeMonitor}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
