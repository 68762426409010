import * as React from "react";
import { screenSizeManager } from "../Context/InternalScreenSizeContext";
import { MenuLayoutType } from "../Context/ScreenSizeContextTypes";
import { favoriteAccessManager } from "../FavoriteAccess/FavoriteAccessManager";
import { FavoriteAccessRegistration } from "../FavoriteAccess/FavoriteAccessTypes";
import { MenuState } from "../Menu/Menu";
import { AnchoredPanelHeaderLayoutType, AnchoredPanelProps } from "../Panel/AnchoredPanelTypes";
import { InternalAnchoredPanel, PanelFavorite } from "../Panel/InternalAnchoredPanel";
import { DetailViews, DetailViewsLayoutType, DetailViewsProps } from "./DetailViews";

export interface DetailViewsPanelProps extends DetailViewsProps,
  Pick<AnchoredPanelProps,
  "componentRef" | "title" | "status" | "icon" | "subTitle" | "onClose" | "isOpen" | "message"> {
  hideSearchBox?: boolean;
  searchPlaceholder?: string;
  /**
   * Registration information for tracking favorite state
   */
  favoriteAccessRegistration?: FavoriteAccessRegistration;
}

/**
 * The DetailsViewsPanel handle rendering of AnchoredPanel and DetailViews directly.
 */
export const DetailViewsPanel = ({
  componentRef,
  title,
  status,
  subTitle,
  message,
  isOpen,
  onClose,
  menu,
  menuFixed,
  menuZoomLayout,
  views,
  onMenuItemSelect,
  activeViewId,
  icon,
  hideSearchBox = false,
  searchPlaceholder,
  favoriteAccessRegistration,
}: DetailViewsPanelProps): JSX.Element => {
  const [panelHeaderLayoutType, setPanelHeaderLayoutType] = React.useState<AnchoredPanelHeaderLayoutType>();

  const onSearch = (searchText: string): void => screenSizeManager?.setSearchText(searchText);
  const searchText = screenSizeManager?.getSearchText();

  const onMenuStateChange = (state: MenuState): void => screenSizeManager?.setMenuState(state);
  const menuState = screenSizeManager?.getMenuState();

  const panelFavorite: PanelFavorite | undefined = favoriteAccessRegistration
    ? {
      isFavorite: favoriteAccessManager.isFavorite(favoriteAccessRegistration?.id || ""),
      onChange: (isFavorite: boolean): void => {
        if (favoriteAccessRegistration) {
          if (isFavorite) {
            favoriteAccessManager.addItem(favoriteAccessRegistration);
          } else {
            favoriteAccessManager.clearItem(favoriteAccessRegistration.id);
          }
        }
      },
    } as PanelFavorite : undefined;

  return (
    <InternalAnchoredPanel
      componentRef={componentRef}
      title={title}
      status={status}
      subTitle={subTitle}
      preventCloseOnEsc
      onClose={onClose}
      icon={icon}
      isOpen={isOpen}
      message={message}
      favorite={panelFavorite}
      onHeaderLayoutChange={(
        headerLayoutType: AnchoredPanelHeaderLayoutType | undefined,
      ) => {
        setPanelHeaderLayoutType(headerLayoutType);
        switch (headerLayoutType) {
          case AnchoredPanelHeaderLayoutType.SCROLL_WITH_CONTENT:
            screenSizeManager.setMenuLayoutType(MenuLayoutType.TOP_SIDE_COUPLED);
            break;
          case AnchoredPanelHeaderLayoutType.FIXED_AT_TOP:
          default:
            screenSizeManager.setMenuLayoutType(MenuLayoutType.TOP_SIDE_DECOUPLED);
            break;
        }
      }}
    >
      <DetailViews
        layoutType={panelHeaderLayoutType === AnchoredPanelHeaderLayoutType.FIXED_AT_TOP
          ? DetailViewsLayoutType.SCROLL_MENU_CONTENT_SEPARATELY
          : DetailViewsLayoutType.SCROLL_MENU_CONTENT_TOGETHER}
        menu={menu}
        menuFixed={menuFixed}
        hideMenu={panelHeaderLayoutType === AnchoredPanelHeaderLayoutType.SCROLL_WITH_CONTENT}
        menuZoomLayout={menuZoomLayout}
        views={views}
        activeViewId={activeViewId}
        onMenuItemSelect={onMenuItemSelect}
        hideSearchBox={hideSearchBox}
        searchPlaceholder={searchPlaceholder}
        searchText={searchText}
        onSearch={onSearch}
        menuState={menuState}
        onMenuStateChange={onMenuStateChange}
      />
    </InternalAnchoredPanel>
  );
};
