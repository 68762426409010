import * as React from "react";
import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  IComboBoxStyles,
  Label,
  Link,
  mergeStyleSets,
  SelectableOptionMenuItemType,
  Stack,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { useErrors } from "../../hooks/useErrors";
import { LabelInfoTooltip, RequiredTooltip } from "../Tooltip/Tooltip";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayout, FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  InternalFormContext,
  InternalFormState,
} from "./FormInternalTypes";
import { ValidationState } from "./FormTypes";
import {
  compactSubFieldBorder,
  labelStyles,
  labelStylesSubField,
  labelStylesSubFieldWithTooltip,
  labelStylesWithToolTip,
  linkStylesCompact,
  linkStylesWide,
  renderErrorMessages,
  renderStatusBar,
  stackItemStyles,
  stackItemStylesWithToolTip,
  wideSubFieldBorder,
} from "./InputCommon";

// tslint:disable-next-line: interface-name
export interface ComboOption {
  /**
  * Whether or not the option is selected
  */
  selected?: boolean;
  /**
   * Id attribute associated with this option
   */
  id: string;
  /**
   * Text to render for this option
   */
  text: string;
}

export enum AutoComplete {
  ON = "on",
  OFF = "off",
}

// FIXME: Combo should provide list of selected options, instead of most recently selected option
export interface ComboProps extends BaseInputProps<string[], string, ComboOption[] | undefined> {
  /**
   * Label for the Combo Field
   */
  label?: string;
  /**
   * Options to be listed for selection
   */
  options: ComboOption[];
  /**
   * Option to select multiple values
   */
  multiSelect?: boolean;
  /**
   * Option to auto complete the text search
   */
  autoComplete?: AutoComplete;
  /**
   * Select all the combo options listed
   */
  selectAllOption?: boolean;
  /**
   * Show the number of options selected
   */
  showCount?: boolean;
}

const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    width: "100%",
    height: "24px",
    minWidth: "20rem",
    marginTop: "5px",
  },
};

const comboBoxWideStyles = mergeStyleSets(comboBoxStyles, { root: { minWidth: "445px" } });
/**
 * The Combo component provides an option to  select a single or multiple options
 * from the list of options and provide the ability to search for a listed option
 */
export const Combo = ({
  testId,
  autoComplete,
  fieldName,
  groupName,
  label,
  options,
  disabled,
  required,
  placeholder,
  defaultValue,
  multiSelect,
  tooltip,
  inputLink,
  statusInfo,
  subField,
  selectAllOption,
  showCount,
  validator,
  onChange,
}: ComboProps): JSX.Element => {
  const { groupName: layoutGroup, layout } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const isWideLayout = layout === FormInputGroupLayout.WIDE;

  const comboOptions = options.map(option => ({
    key: option.id,
    text: option.text,
  } as IComboBoxOption));

  if (selectAllOption) {
    comboOptions.unshift(
      {
        key: "selectAll",
        text: Messages.actions.selectAll(),
        itemType: SelectableOptionMenuItemType.SelectAll,
      },
    );
  }
  const group = groupName || layoutGroup;
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("Combo should be used within form");
  }
  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>();

  const [allErrors, setFieldErrors] = useErrors(fieldName, group);

  const fieldValidation = (): void => {
    const value = form.getValue<ComboOption[]>(fieldName, group);
    const errorMsg = validator ? validator(value) : undefined;
    const requiredMessage = required ? value?.[0]?.id === undefined
      ? [Messages.validation.requiredValidation()] : undefined : undefined;
    const errors = [...requiredMessage || [], ...errorMsg || []];
    form.setFieldState(
      errors && errors?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );
    setFieldErrors(errors);
  };

  const extractInputValue = (formValue: ComboOption[] | undefined): string[] | undefined => {
    if (!formValue?.length) return undefined;
    return formValue.map(selectedOption => selectedOption.id);
  };

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    if (validator || required) {
      form.registerField(fieldName, group);
    }

    if (defaultValue?.length) {
      const selectedOptions = options?.filter(option => defaultValue.includes(option.id as string));
      setSelectedKeys(defaultValue);
      form.setValue(selectedOptions?.length ? selectedOptions : undefined, fieldName, group);
      fieldValidation();
    } else if (required) {
      form.setFieldState(ValidationState.INVALID, fieldName, group);
    }

    return function cleanup() {
      if (validator || required) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
    };
  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let newSelection: ComboOption[] = form?.getValue<ComboOption[]>(fieldName, group) ?? [];

  const internalOnChange = (
    _: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
  ): void => {
    if (multiSelect && option !== undefined) {
      if (option.selected) {
        if (selectAllOption && option?.itemType && option?.itemType === SelectableOptionMenuItemType.SelectAll) {
          newSelection = (comboOptions.filter(o => o.key !== option.key)
            .map(o => ({ id: o.key as string, text: o.text as string })));
        } else {
          newSelection.push({ id: option.key as string, text: option.text });
        }
      } else if (!option.selected) {
        if (selectAllOption && option?.itemType && option?.itemType === SelectableOptionMenuItemType.SelectAll) {
          newSelection = [];
        } else {
          newSelection = newSelection.filter(item => item.id !== option.key);
        }
      }
      setSelectedKeys(newSelection.map(key => key.id));
      form.setValue(newSelection.length ? newSelection : undefined, fieldName, group);
    } else if (option !== undefined) {
      setSelectedKeys([option?.key as string]);
      newSelection = [];
      newSelection.push({ id: option?.key as string, text: option?.text as string });
      form.setValue(newSelection, fieldName, group);
    }
    if (validator || required) {
      fieldValidation();
    }
    onChange?.(option?.key.toString() || "");
  };

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[group] === FormValidationMode.ON) {
      if (validator || required) {
        fieldValidation();
      }
    }
  // Only if validation mode changes. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  const comboResourcelink = (
    <Link
      id={inputLink?.id}
      styles={!isWideLayout ? linkStylesCompact : linkStylesWide}
      onClick={() => { inputLink?.onLinkClick?.(); }}
    >
      {inputLink?.text}
    </Link>
  );

  const getShowCount = selectedKeys?.length === undefined || selectedKeys?.length === 0
    ? placeholder === undefined ? Messages.hints.showSelection("0") : placeholder
    : Messages.hints.showSelection(`${selectedKeys?.length}`);

  const comboBoxSelect = (
    <ComboBox
      id={fieldName}
      data-test-id={testId}
      options={comboOptions}
      onChange={internalOnChange}
      text={showCount ? getShowCount : undefined}
      disabled={disabled}
      placeholder={placeholder}
      multiSelect={multiSelect}
      allowFreeform
      autoComplete={autoComplete}
      defaultSelectedKey={defaultValue}
      styles={!isWideLayout ? comboBoxStyles : comboBoxWideStyles}
    />
  );

  const comboBoxFieldSeparateLabel = (
    <>
      <Stack
        horizontal={isWideLayout}
        styles={inputLink === undefined ? stackItemStyles : undefined}
      >
        <Stack
          horizontal
          styles={tooltip !== undefined ? stackItemStylesWithToolTip : undefined}
        >
          {label && (
            <Stack horizontal style={{ minWidth: "250px" }}>
              {subField && isWideLayout && (
                <span style={isWideLayout ? wideSubFieldBorder : compactSubFieldBorder}>{}</span>
              )}
              <Label
                htmlFor={fieldName}
                styles={
                  tooltip !== undefined
                    ? subField
                      ? labelStylesSubFieldWithTooltip
                      : labelStylesWithToolTip
                    : subField
                      ? labelStylesSubField
                      : labelStyles
                }
              >
                {label}
                {required && <RequiredTooltip />}
                {tooltip !== undefined && (
                  <LabelInfoTooltip
                    tooltip={tooltip}
                  />
                )}
              </Label>
            </Stack>
          )}
        </Stack>
        {comboBoxSelect}
      </Stack>
      {inputLink !== undefined && comboResourcelink}
      {allErrors.length > 0 && renderErrorMessages(allErrors, layout)}
      {statusInfo ? renderStatusBar(statusInfo, layout) : null}
    </>
  );

  return (comboBoxFieldSeparateLabel);
};
