import * as React from "react";
import { ISpinButtonStyles, SpinButton } from "@fluentui/react";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { InfoBlockProps } from "../InfoBlock/InfoBlock";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import { InternalFormContext, InternalFormState } from "./FormInternalTypes";
import { LabelWrapper } from "./LabelWrapper";
import { buildLabelWrapperTestIds, LabelWrapperProps, LabelWrapperTestIds } from "./LabelWrapperTypes";

export interface NumberInputProps extends BaseInputProps<number>, Pick<LabelWrapperProps, "label"> {
  /**
   * Min value for the NumberInput
   * @default 0
   */
  min?: number;
  /**
   * Max value for the NumberInput
   */
  max?: number;
  /**
   * Incremental value for the NumberInput
   */
  step?: number;
  /**
   * Accessible label text for the increment button
   */
  incrementButtonAriaLabel?: string;
  /**
   * Accessible label text for the decrement button
   */
  decrementButtonAriaLabel?: string;
  /**
   * Option to display the message bar for the NumberInput
   */
  statusInfo?: InfoBlockProps;
}

const spinButtonStyles: Partial<ISpinButtonStyles> = {
  root: {
    width: "100%",
    height: "24px",
    marginTop: "5px",
  },
  spinButtonWrapper: { height: 24 },
};

export type NumberInputTestIds = ComponentTestIds & LabelWrapperTestIds;

export const buildNumberInputTestIds = (testId?: string): NumberInputTestIds => ({
  ...buildLabelWrapperTestIds(testId),
  component: buildTestId(testId),
});

/**
 * NumberInput component allows the caller to incrementally adjust a value in small steps.
 * It’s used for numeric values.
 */
export const NumberInput = ({
  testId,
  defaultValue,
  fieldName,
  groupName,
  label,
  disabled,
  tooltip,
  min = 0,
  max,
  step,
  incrementButtonAriaLabel,
  decrementButtonAriaLabel,
  statusInfo,
  subField,
  required,
  validator,
  onChange,
  ariaLabel,
}: NumberInputProps): JSX.Element => {
  const { groupName: layoutGroup, layout } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const group = groupName || layoutGroup;
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("NumberInput should be used within form");
  }

  const extractInputValue = (formValue: number): number => formValue;

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    if (validator) {
      form.registerField(fieldName, group);
    }

    if (typeof defaultValue === "number") {
      form.setValue(defaultValue, fieldName, group);
    } else if (typeof min === "number") {
      form.setValue(min, fieldName, group);
    }

    return function cleanup() {
      if (validator) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
    };
  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const internalOnValidate = (val: string): string | void | undefined => {
    const numValue = Number(val);
    const initial = defaultValue || min;
    if (Number.isNaN(numValue) || undefined) return (initial ? String(initial) : undefined);
    const integerValue = Math.floor(numValue);
    const belowMin = integerValue <= Number(min);
    const aboveMax = integerValue >= Number(max);
    if (belowMin) return String(min);
    if (aboveMax) return String(max);
    return String(integerValue);
  };

  const internalOnChange = (
    _: React.SyntheticEvent<HTMLElement>,
    newValue?: string,
  ): void => {
    const numValue = Number(newValue);
    form.setValue(numValue, fieldName, group);
    onChange?.(numValue);
  };

  const numberInputTestIds = buildNumberInputTestIds(testId);

  return (
    <LabelWrapper
      errors={[]}
      fieldName={fieldName}
      layout={layout}
      label={label}
      required={required}
      statusInfo={statusInfo}
      subField={subField}
      tooltip={tooltip}
      testId={testId}
    >
      <SpinButton
        id={fieldName}
        data-test-id={numberInputTestIds.component}
        min={min}
        max={max}
        step={step}
        ariaLabel={ariaLabel || label}
        incrementButtonAriaLabel={incrementButtonAriaLabel}
        decrementButtonAriaLabel={decrementButtonAriaLabel}
        onChange={internalOnChange}
        onValidate={internalOnValidate}
        disabled={disabled}
        defaultValue={defaultValue ? `${defaultValue}` : undefined}
        styles={spinButtonStyles}
      />
    </LabelWrapper>
  );
};
