/* eslint-disable object-shorthand */
import TelemetryProcessor from "../core/TelemetryProcessor";
import {
  getConsoleTelemetryStoreName,
  getMultiCloudTelemetryAPIEndpoint,
  preprocessMetadata,
  validateDimensions,
} from "../utils/internalUtils";
import {
  Metric,
  Log,
  LogLevel,
  LogMetadata,
  Transport,
  TokenAuthenticator,
  TelemetryClientData,
  TelemetryType,
} from "../models";
import { TIMER_INTERVAL, SHORT_TIME_INTERVAL, DEFAULT_MESSAGE_TTL } from "../utils/constants";
import { MultiCloudTelemetryConfig } from "../models/MultiCloudTelemetryConfig";

export class MultiCloudTelemetryTransport implements Transport {
  private region: string;
  private config: MultiCloudTelemetryConfig;

  private metricTelemetryProcessor: TelemetryProcessor<Metric>;
  private logTelemetryProcessor: TelemetryProcessor<Log>;
  private logFastTelemetryProcessor: TelemetryProcessor<Log>;

  public isInitialized() {
    return !!this.config;
  }

  public setRegion(region: string) {
    this.region = region;
  }

  public setAuthInstance(authInstance: TokenAuthenticator) {
    this.config.userSession = authInstance.SessionId;

    // update processors
    this.logProcessor().setAuthInstance(authInstance);
    this.logFastProcessor().setAuthInstance(authInstance);
    this.metricProcessor().setAuthInstance(authInstance);
  }

  public constructor(config: MultiCloudTelemetryConfig) {
    this.config = { ...config };
    if (this.config.postToTelemetryService === undefined) {
      this.config.postToTelemetryService = true;
    }
  }

  private getTelemetryProcessor<T>(
    timeInterval: number = TIMER_INTERVAL,
    telemetryType: TelemetryType = "Logs",
  ): TelemetryProcessor<T> {
    if (!this.isInitialized) {
      throw new Error("Initialize TelemetryClient before using the logger");
    }
    return new TelemetryProcessor<T>({
      storeName: getConsoleTelemetryStoreName(telemetryType),
      timeInterval: timeInterval,
      recordProcessor: this.appendRecordProcessor.bind(this),
      APIEndpoint: getMultiCloudTelemetryAPIEndpoint(telemetryType),
      maxRetries: this.config.maxRetries,
      serviceLimit: this.config.serviceLimit,
      telemetryURLs: this.config.mcTelemetryAdapterURLs,
      fetchApi: this.config.fetchApi,
    });
  }

  private logProcessor(): TelemetryProcessor<Log> {
    if (!this.logTelemetryProcessor) {
      this.logTelemetryProcessor = this.getTelemetryProcessor<Log>();
    }
    return this.logTelemetryProcessor;
  }

  private logFastProcessor(): TelemetryProcessor<Log> {
    if (!this.logFastTelemetryProcessor) {
      this.logFastTelemetryProcessor = this.getTelemetryProcessor<Log>(SHORT_TIME_INTERVAL);
    }
    return this.logFastTelemetryProcessor;
  }

  private metricProcessor(): TelemetryProcessor<Metric> {
    if (!this.metricTelemetryProcessor) {
      this.metricTelemetryProcessor = this.getTelemetryProcessor<Metric>(
        SHORT_TIME_INTERVAL,
        "Metrics",
      );
    }
    return this.metricTelemetryProcessor;
  }

  /**
   * Information to add to the telemetry once the user authenticates
   * @param payload: log | metric
   */
  private appendRecordProcessor<T>(payload: T): T {
    payload = {
      userSession: this.config.userSession,
      timestamp: new Date().getTime(),
      telemetryRegion: this.region,
      ...payload,
    };
    return payload;
  }

  processLog(logLevel: LogLevel, message: string | Error, logMetadata?: LogMetadata): void {
    if (!this.isInitialized) {
      return;
    }
    if (!this.config.postToTelemetryService) {
      return;
    }
    const log: Log = {
      type: "logging",
      severity: logLevel,
      source: logMetadata?.origin ?? this.config.source,
      namespace: this.config.namespace,
      message: typeof message === "string" ? message : (message as Error)?.message,
      metadata: preprocessMetadata(logMetadata),
    };
    const data: TelemetryClientData<Log> = {
      TTL: new Date().getTime() + DEFAULT_MESSAGE_TTL,
      payload: log,
    };
    if (logLevel === LogLevel.Error) {
      this.logFastProcessor().add(data);
    } else {
      this.logProcessor().add(data);
    }
  }

  processMetric(name: string, value: number, dimensions?: Record<string, string>): void {
    validateDimensions(dimensions);

    const metric: Metric = {
      type: "metrics",
      name: `${this.config.namespace}_${name}`,
      value,
      source: this.config.source,
      sampling: 1,
      metadata: preprocessMetadata(dimensions),
    };
    if (!this.config || !this.config.postToTelemetryService) {
      console.debug(`metrics - ${name}:`, metric);
      return;
    }
    const data: TelemetryClientData<Metric> = {
      TTL: new Date().getTime() + DEFAULT_MESSAGE_TTL,
      payload: metric,
    };
    this.metricProcessor().add(data);
  }
}
