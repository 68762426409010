import * as React from "react";
import {
  Checkbox,
  CheckboxVisibility,
  DetailsHeader,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  DetailsRowFields,
  IColumn,
  IDetailsCheckboxProps,
  IDetailsHeaderProps,
  IDetailsListStyles,
  IDetailsRowFieldsProps,
  IDetailsRowProps,
  IDetailsRowStyles,
  IDropdownOption,
  IObjectWithKey,
  ITextFieldStyles,
  mergeStyleSets,
  Selection,
  SelectionMode,
  TextField,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { FilterAll } from "../../constants/uiConstants";
import { ListingColumn } from "../Listing/ListingTypes";
import { ExtraFilter } from "./FilterTypes";
import { deepCopy, emptyKeyStr, getEditModeOptionCount } from "./FilterUtils";

export interface FilterPillValueSelectorProps {

  /**
   * Label of the filter pill value selector field
   */
  label?: string;

  /**
   * Placeholder of the filter pill value selector field
   */
  placeholder?: string;

  /**
   * Options of the pill value selection list
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: IDropdownOption<any>[];

  /**
   * Default pill value selection keys
   */
  defaultSelectedKeys: string[];

  /**
   * CallBack function of selecting pill list value
   */
  setSelectedKeysCallBack: (keys: string[]) => void

  /**
   * Current extra filler fieldKey identifier
   */
  currentExtraFilterKey: string;

  /**
   * Items full data input list.
   * This can be used to track the label of the filter field when there is no current items in list.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataItems: any[];

  /**
   * Columns of data input list
   */
  dataColumns: ListingColumn[];

  /**
   * Text filter callback function
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  textFilterCallBack?: (item: any) => any;

  /**
   * All the current extra fillers that applied to the list items
   */
  currentExtraFilters?: ExtraFilter[];

  /**
   *Current text filter text
   */
  currentFilterText?: string;
}

export const enum Fields {
  Search = "search",
}

const listStyles: Partial<IDetailsRowStyles | IDetailsListStyles> = {
  cell: { fontSize: "13px" },
  root: {
    ".ms-List-cell": { minHeight: "31px" },
    ".ms-DetailsHeader": { height: "34px" },
    ".ms-DetailsHeader-cellTitle": { padding: 0 },
  },
};

const textStyle: Partial<ITextFieldStyles> = {
  root: { ".ms-Label": { fontWeight: 400 } },
  fieldGroup: { height: "24px" },
};

const classNames = mergeStyleSets({ detailsListContainer: { maxHeight: "196px", overflowY: "auto" } });

const tableRowStyles = {
  root: {
    minHeight: "31px",
    ".ms-DetailsRow-check": { height: "31px" },
  },
  cell: {
    fontSize: "13px",
    padding: "7px 0 2px 0",
    minHeight: "31px",
    color: "rgb(50, 49, 48)",
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
  checkCell: { minHeight: "15px" },
};

/**
 * FilterPillValueSelector is a component to select pill filter values.
 *
 */
export const FilterPillValueSelector = (
  {
    label,
    placeholder,
    options,
    defaultSelectedKeys,
    setSelectedKeysCallBack,
    currentExtraFilterKey,
    currentExtraFilters,
    currentFilterText,
    dataItems,
    textFilterCallBack,
    dataColumns,
  }: FilterPillValueSelectorProps,
): JSX.Element => {
  const optionsUpdated = deepCopy(options).map(
    o => {
      if (o.key === "") {
        o.key = emptyKeyStr;
        o.text = emptyKeyStr;
      }
      return o;
    },
  );
  const [selectionObjects, setSelectionObjects] = React.useState<IObjectWithKey[]>();
  const [optionItems, setOptionItems] = React.useState(optionsUpdated.filter(o => o.key !== FilterAll));
  const [isHeaderCbIndeterminate, setIsHeaderCbIndeterminate] = React.useState<boolean>(false);
  const [isHeaderCbChecked, setIsHeaderCbChecked] = React.useState<boolean>(false);

  const [selectionState] = React.useState<Selection>(new Selection(
    {
      onSelectionChanged: (): void => {
        setSelectionObjects(selectionState.getSelection());
      },
    },
  ));

  React.useEffect(() => {
    setSelectedKeysCallBack(selectionObjects?.map(i => {
      if (i.key?.toString() === emptyKeyStr) {
        return "";
      }
      return i.key?.toString() || "";
    }) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectionObjects)]);

  React.useEffect(() => {
    const defaultKeysUpdated = defaultSelectedKeys?.map(v => (
      v === "" ? emptyKeyStr : v
    )) || [];
    defaultKeysUpdated.forEach(i => {
      selectionState.setKeySelected(i, true, false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedKeys]);

  const onValueChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    searchText: string | undefined,
  ): void => {
    if (searchText) {
      setOptionItems(optionsUpdated.filter(i => i.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1));
    } else {
      setOptionItems(optionsUpdated);
    }
  };

  const renderCheckbox = (props: IDetailsCheckboxProps | undefined): JSX.Element => (
    <div style={{ pointerEvents: "none" }}>
      <Checkbox checked={props?.checked} />
    </div>
  );

  const selectorColumns: IColumn[] = [
    {
      key: "text",
      name: Messages.common.all(),
      fieldName: "text",
      minWidth: 200,
      onRender: (
        selectorItem: IDropdownOption,
      ) => `${selectorItem.text} (${getEditModeOptionCount(
        selectorItem,
        dataItems,
        currentExtraFilters || [],
        currentExtraFilterKey,
        currentFilterText || "",
        dataColumns,
        textFilterCallBack,
      )})`,
    },
  ];

  const renderRowFields = (props: IDetailsRowFieldsProps): JSX.Element => (
    <span data-selection-disabled>
      <DetailsRowFields {...props} />
    </span>
  );

  const renderRow = (props: IDetailsRowProps | undefined): JSX.Element | null => {
    if (props) {
      return <DetailsRow rowFieldsAs={renderRowFields} {...props} styles={tableRowStyles} />;
    }
    return null;
  };

  const onHeaderCheckboxChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    newChecked?: boolean,
  ): void => {
    if (isHeaderCbIndeterminate) {
      setIsHeaderCbIndeterminate(false);
    } else {
      selectionState.setAllSelected(!newChecked);
    }
  };

  const renderHeaderCheckbox = (): JSX.Element => (
    <Checkbox
      checked={isHeaderCbChecked}
      indeterminate={isHeaderCbIndeterminate}
      onChange={onHeaderCheckboxChange}
    />
  );

  React.useEffect(() => {
    setIsHeaderCbChecked(false);
    if (selectionObjects && optionItems) {
      if (selectionObjects.length === 0) {
        setIsHeaderCbIndeterminate(false);
      } else if (selectionObjects.length < optionItems.length) {
        setIsHeaderCbIndeterminate(true);
      } else if (selectionObjects.length === optionItems.length) {
        setIsHeaderCbIndeterminate(false);
        setIsHeaderCbChecked(true);
      }
    } else {
      setIsHeaderCbIndeterminate(false);
    }
  }, [selectionObjects, optionItems]);

  const renderDetailsHeader = (headerProps?: IDetailsHeaderProps): JSX.Element | null => {
    if (!headerProps) {
      return null;
    }
    return <DetailsHeader {...headerProps} onRenderDetailsCheckbox={renderHeaderCheckbox} />;
  };

  return (
    <>
      <TextField
        label={label}
        onChange={onValueChange}
        styles={textStyle}
        placeholder={placeholder}
      />
      <div className={classNames.detailsListContainer}>
        <DetailsList
          items={optionItems}
          columns={selectorColumns}
          selection={selectionState}
          selectionPreservedOnEmptyClick
          selectionMode={SelectionMode.multiple}
          checkboxVisibility={CheckboxVisibility.always}
          onRenderCheckbox={renderCheckbox}
          styles={listStyles}
          layoutMode={DetailsListLayoutMode.justified}
          onRenderRow={renderRow}
          onRenderDetailsHeader={renderDetailsHeader}
          isSelectedOnFocus={false}
        />
      </div>
    </>
  );
};
