import { stringifyReact } from "../../helpers/reactHelper";
import { MenuState } from "../Menu/Menu";
import { DetailViewsMenuZoomLayout, MenuLayoutType, ScreenSizeMonitor } from "./ScreenSizeContextTypes";

export interface ScreenSizeManager {
  registerDetailViewsMenu: (detailViewsMenu: React.ReactNode, zoomLayout?: DetailViewsMenuZoomLayout) => void;
  unregisterDetailViewsMenu: (detailViewsMenu: React.ReactNode) => void;
  setMenuLayoutType: (menuLayoutType: MenuLayoutType) => void;
  setLastPanelHeight: (height: number) => void;
  getLastPanelHeight: () => number;
  setSearchText: (searchText:string) => void;
  getSearchText: () => string;
  setMenuState: (state: MenuState) => void;
  getMenuState: () => MenuState;
}

type NotifyMenuLayoutChange = (menuLayoutType: MenuLayoutType) => void;
type NotifyPanelHeightChange = (panelHeight: number) => void;

export class ScreenSizeManagerClass implements ScreenSizeManager, ScreenSizeMonitor {
  private detailViewsMenu: React.ReactNode;

  private detailViewsMenuZoomLayout: DetailViewsMenuZoomLayout | undefined;

  private notifyMenuLayoutChange: NotifyMenuLayoutChange | undefined;

  private notifyPanelHeightChange: NotifyPanelHeightChange | undefined;

  public panelHeight = 0;

  public setNotifyMenuLayoutChange = (notifyMenuLayoutChange: NotifyMenuLayoutChange): void => {
    this.notifyMenuLayoutChange = notifyMenuLayoutChange;
  };

  public setNotifyPanelHeightChange = (notifyPanelHeightChange: NotifyPanelHeightChange): void => {
    this.notifyPanelHeightChange = notifyPanelHeightChange;
  };

  public menuLayoutType: MenuLayoutType = MenuLayoutType.TOP_SIDE_DECOUPLED;

  private searchText = "";

  private menuState: MenuState = MenuState.EXPAND;

  public registerDetailViewsMenu = (
    detailViewsMenu: React.ReactNode,
    zoomLayout: DetailViewsMenuZoomLayout = DetailViewsMenuZoomLayout.COUPLED_PORTAL,
  ): void => {
    this.detailViewsMenu = detailViewsMenu;
    this.detailViewsMenuZoomLayout = zoomLayout;
  };

  public unregisterDetailViewsMenu = (detailViewsMenu: React.ReactNode): void => {
    if (this.detailViewsMenu && stringifyReact(this.detailViewsMenu) === stringifyReact(detailViewsMenu)) {
      this.detailViewsMenu = undefined;
      this.detailViewsMenuZoomLayout = undefined;
    }
  };

  public getDetailViewsMenu = (): React.ReactNode => this.detailViewsMenu;

  public getDetailViewsMenuZoomLayout = (): DetailViewsMenuZoomLayout | undefined => this.detailViewsMenuZoomLayout;

  public setMenuLayoutType = (menuLayoutType: MenuLayoutType): void => {
    this.menuLayoutType = menuLayoutType;
    this.notifyMenuLayoutChange?.(menuLayoutType);
  };

  public setLastPanelHeight = (height: number): void => {
    this.panelHeight = height;
    this.notifyPanelHeightChange?.(height);
  };

  public getLastPanelHeight = (): number => this.panelHeight;

  public setSearchText = (searchText: string): void => {
    this.searchText = searchText;
  };

  public getSearchText = (): string => this.searchText;

  public setMenuState = (state: MenuState): void => {
    this.menuState = state;
  };

  public getMenuState = (): MenuState => this.menuState;
}

export const screenSizeManager = new ScreenSizeManagerClass();
