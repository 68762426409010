import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { StatusProps } from "../Status/Status";

export enum AnchoredPanelType {
  SMALL,
  MEDIUM,
  LARGE,
  FULL_WIDTH,
  CUSTOM_WIDTH,
}

export enum AnchoredPanelHeaderLayoutType {
  FIXED_AT_TOP = "FIXED_AT_TOP",
  SCROLL_WITH_CONTENT = "SCROLL_WITH_CONTENT",
}

export enum AnchoredPanelFooterType {
  FIXED_AT_BOTTOM = "FIXED_AT_BOTTOM",
  BELOW_CONTENT = "BELOW_CONTENT",
}

export enum ButtonType {
  PRIMARY,
  DEFAULT,
}

export enum MessageType {
  IN_PROGRESS,
  INFO,
  SUCCESS,
  WARNING,
  ERROR,
}

export enum FocusableArea {
  CONTENT,
  FOOTER,
}

export interface AnchoredPanelInternalIds {
  panelId: string;
  contentSiblingId: string;
  panelNavigationId: string;
}

export interface AnchoredPanelComponent {
  /**
   * Method when  the AnchoredPanel is set to be open
   */
  open: () => void;
  /**
   * Method when the AnchoredPanel is set to be closed
   */
  close: () => void;
  /**
   * Method to show/hide in-progress indicator above the panel's header
   * The in-progress indicator is off (hidden) at mount time
   */
  toggleInProgress: (on: boolean) => void;

  /**
   * Method to return the internal IDs used by the panel
   *
   * @returns internal IDs
   */
  getInternalIds: () => AnchoredPanelInternalIds;
}

export interface PanelFooterButton {
  /**
   * Label for the PanelFooter
   */
  label: string;
  /**
   * Button type for the PanelFooter
   */
  type?: ButtonType;
  /**
   * Callback when the PanelFooter button is clicked
   */
  onClick: () => void;
  /**
   * Disable the PanelFooter
   */
  disabled?: boolean;
  /**
   * Style for the PanelFooter
   */
  style?: React.CSSProperties;
  /**
   * data-test-id HTML attribute value
   */
  testId?: string;
}

export interface PanelMessage {
  /**
   * Message type to decide which icon to use
   */
  type: MessageType,
  /**
   * Text to render as message content
   */
  text: string | JSX.Element;
  /**
   * React component to render as message details
   */
  details?: JSX.Element;
  /**
   * If provided a dismiss button will be shown and the callback will be invoked if clicked on
   */
  onDismiss?: () => void;
}

export interface TitleDetails {
  primary: string;
  secondary: string;
}

export interface AnchoredPanelProps {
  /**
   * Component reference for the AnchoredPanel
   */
  componentRef?: (ref: AnchoredPanelComponent) => void;
  /**
   * Panel type for the AnchoredPanel
   */
  type?: AnchoredPanelType;
  /**
   * Custom width for the AnchoredPanel
   */
  customWidth?: number;
  /**
   * Title for the AnchoredPanel
   */
  title: string | TitleDetails;
  /**
   * Status icon with tooltip to render to the right of the title
   */
  status?: Pick<StatusProps, "status" | "tooltip">;
  /**
   * Subtitle for the AnchoredPanel
   */
  subTitle?: string;
  /**
   * Icon name for the AnchoredPanel
   */
  icon?: string;
  /**
   * Option to open the AnchoredPanel
   */
  isOpen?: boolean;
  /**
   * Specify if the AnchoredPanel is blocked
   */
  isBlocking?: boolean;
  /**
   * If set will prevent closing the panel by pressing Esc
   * @default false
   */
  preventCloseOnEsc?: boolean;
  /**
   * Callback when the AnchoredPanel is closed
   */
  onClose?: () => void;
  /**
   * Footer button on the AnchoredPanel
   */
  footer?: PanelFooterButton[];
  /**
   * Message to display on the AnchoredPanel
   */
  message?: PanelMessage;
  /**
   * Components displayed as part of the AnchoredPanel
   */
  children: React.ReactNode;
  /**
   * A test id for the anchored panel
   */
  testId?: string;
  /**
   * The area to look for the first focusable element
   * @default CONTENT
   */
  initialFocusableArea?: FocusableArea;
  /**
   * Callback invoked when the header layout type changes
   * @param headerLayoutType The enum value the header layout type changed to
   */
  onHeaderLayoutChange?: (headerLayoutType: AnchoredPanelHeaderLayoutType | undefined) => void;
}

export interface AnchoredPanelTestIds extends ComponentTestIds{
  /**
   * The header containing the title, the vaidation message and the close button
   */
  header: string;
}

export const buildAnchoredPanelTestIds = (baseTestId?: string): AnchoredPanelTestIds => ({
  component: buildTestId(baseTestId),
  header: buildTestId(baseTestId, "-header"),
});
