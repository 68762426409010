// eslint-disable-next-line max-classes-per-file
import {
  anchoredPanelLayoutManager,
  appLayoutManager,
  inputWizardLayoutManager,
  listingLayoutManager,
  metaItemSectionLayoutManager,
} from "./LayoutManager";
import {
  AnchoredPanelFooterTypeConfig,
  AnchoredPanelHeaderLayoutTypeConfig,
  AnchoredPanelLayoutConfig,
  AppLayoutConfig,
  InputWizardContentLayoutTypeConfig,
  InputWizardLayoutConfig,
  ListingLayoutConfig,
  ListingPaginationLayoutTypeConfig,
  MetaItemSectionLayoutConfig,
  MetaItemSectionLayoutTypeConfig,
} from "./LayoutManagerTypes";

/**
 * App layout manager
 */
class AppLayoutConfigClass implements AppLayoutConfig {
  public setMainAnchorId = (mainAnchorId: string): void => {
    appLayoutManager.setMainAnchorId(mainAnchorId);
  };
}

export const appLayoutConfig = new AppLayoutConfigClass();

/**
 * MetaItemSection layout manager
 */
class MetaItemSectionLayoutConfigClass implements MetaItemSectionLayoutConfig {
  public setLayoutTypeMaxWidth = (layoutType: MetaItemSectionLayoutTypeConfig, maxWidth: number): void => {
    metaItemSectionLayoutManager.setLayoutTypeMaxWidth(layoutType, maxWidth);
  };
}

export const metaItemSectionLayoutConfig = new MetaItemSectionLayoutConfigClass();

/**
 * AnchoredPanel layout manager
 */
class AnchoredPanelLayoutConfigClass implements AnchoredPanelLayoutConfig {
  public setHeaderLayoutTypeMinHeight = (
    headerLayoutType: AnchoredPanelHeaderLayoutTypeConfig,
    minHeight: number,
  ): void => {
    anchoredPanelLayoutManager.setHeaderLayoutTypeMinHeight(headerLayoutType, minHeight);
  };

  public setFooterTypeMinHeight = (
    footerType: AnchoredPanelFooterTypeConfig,
    minHeight: number,
  ): void => {
    anchoredPanelLayoutManager.setFooterTypeMinHeight(footerType, minHeight);
  };
}

export const anchoredPanelLayoutConfig = new AnchoredPanelLayoutConfigClass();

/**
 * InputWizard layout manager
 */
class InputWizardLayoutConfigClass implements InputWizardLayoutConfig {
  public setContentLayoutTypeMinWidth = (
    contentLayoutType: InputWizardContentLayoutTypeConfig,
    minWidth: number,
  ): void => {
    inputWizardLayoutManager.setContentLayoutTypeMinWidth(contentLayoutType, minWidth);
  };
}

export const inputWizardLayoutConfig = new InputWizardLayoutConfigClass();

/**
 * Listing layout manager
 */

class ListingLayoutConfigClass implements ListingLayoutConfig {
  public setColumnMinWidth = (minWidth: number): void => {
    listingLayoutManager.setColumnMinWidth(minWidth);
  };

  public setPaginationTypeMinWidth = (
    paginationType: ListingPaginationLayoutTypeConfig,
    minWidth: number,
  ): void => {
    listingLayoutManager.setPaginationTypeMinWidth(paginationType, minWidth);
  };
}

export const listingLayoutConfig = new ListingLayoutConfigClass();
