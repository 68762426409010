import { ExtractInputValueType, getConsolidatedState, InternalFormState } from "./FormInternalTypes";
import { FormValues, ValidationState } from "./FormTypes";
import { parseFieldName, StatesUpdateCallback, ValueUpdateCallback } from "./SimpleBuilderInternalTypes";

export class SimpleBuilderState implements InternalFormState {
  // Maps the row ID to the field validation states. The keys in the record correspond to the names in the fieldNameSet
  private states: Map<number, Record<string, ValidationState>> = new Map();

  // Maps the row ID to its validation state. The validation state is representative of the row validator state, not of
  // consolidated field states
  private rowStates: Map<number, ValidationState> = new Map();

  // Maps the row ID to the field values. The keys in the record correspond to the names in the fieldNameSet
  private values: Map<number, Record<string, unknown>> = new Map();

  private fieldExtractInputValueMap: Map<string, ExtractInputValueType> = new Map<string, ExtractInputValueType>();

  private fieldLabelMap: Map<string, string> = new Map<string, string>();

  private onStatesUpdate?: StatesUpdateCallback;

  private onValueUpdate?: ValueUpdateCallback;

  public hasTemplateRow: boolean;

  constructor(
    hasTemplateRow: boolean,
    onStatesUpdate?: StatesUpdateCallback,
    onValueUpdate?: ValueUpdateCallback,
  ) {
    this.hasTemplateRow = hasTemplateRow;
    this.onStatesUpdate = onStatesUpdate;
    this.onValueUpdate = onValueUpdate;
  }

  private getConsolidatedFieldStates(): ValidationState {
    const rowIds: number[] = Array.from(this.states.keys());

    if (this.hasTemplateRow) {
      rowIds.pop();
    }

    const consolidatedRowFieldStates: ValidationState[] = rowIds.map(rowId => {
      const rowFieldStates: Record<string, ValidationState> = this.states.get(rowId) ?? {};

      const validationStates: ValidationState[] = Object.values(rowFieldStates);

      return getConsolidatedState(validationStates);
    });

    return getConsolidatedState(consolidatedRowFieldStates);
  }

  private getConsolidatedRowStates(): ValidationState {
    const rowIds: number[] = Array.from(this.rowStates.keys());

    if (this.hasTemplateRow) {
      rowIds.pop();
    }

    const rowStates: ValidationState[] = rowIds.map(rowId => this.rowStates.get(rowId) as ValidationState);
    return getConsolidatedState(rowStates);
  }

  private getOverallState = (): ValidationState => getConsolidatedState([
    this.getConsolidatedFieldStates(),
    this.getConsolidatedRowStates(),
  ]);

  public getValue = <ValueType>(fieldName: string): ValueType | undefined => {
    const [field, rowId] = parseFieldName(fieldName);

    return this.values.get(rowId)?.[field] as ValueType | undefined;
  };

  /**
   * @returns keys ordered same as the order the inputs are passed
   */
  public getRowValues = (rowId: number): FormValues => {
    const rowValues = this.values.get(rowId) ?? {};
    return rowValues;
  };

  /**
   * @returns the index in the data array of the given row
   */
  public getRowIndex = (rowId: number): number => {
    const rowIds: number[] = Array.from(this.values.keys());
    return rowIds.findIndex(entry => entry === rowId);
  };

  /**
   * @returns values in order of row and in order of the field names passed to the SimpleBuilder
   */
  public getValues = (): FormValues[] => {
    const rowIds: number[] = Array.from(this.values.keys());
    const values: FormValues[] = rowIds.map(rowId => this.getRowValues(rowId));
    return values;
  };

  public setValue = (value: unknown, fieldName: string): void => {
    const [field, rowId] = parseFieldName(fieldName);

    this.values.set(rowId, { ...this.values.get(rowId), [field]: value });

    this.onValueUpdate?.(this.getValues(), value, field, rowId);
  };

  public deleteField = (fieldName: string): void => {
    const [field, rowId] = parseFieldName(fieldName);

    delete this.values.get(rowId)?.[field];

    this.onValueUpdate?.(this.getValues(), undefined, field, rowId);
  };

  public deleteRow = (rowId: number): void => {
    this.values.delete(rowId);

    this.onValueUpdate?.(this.getValues(), undefined, undefined, rowId);
  };

  public registerRow = (rowId: number): void => {
    this.rowStates.set(rowId, ValidationState.UNKNOWN);

    this.onStatesUpdate?.(
      this.getOverallState(),
      ValidationState.UNKNOWN,
      undefined,
      rowId,
    );
  };

  public unRegisterRow = (rowId: number): void => {
    this.rowStates.delete(rowId);

    this.onStatesUpdate?.(
      this.getOverallState(),
      ValidationState.UNKNOWN,
      undefined,
      rowId,
    );
  };

  /**
   * @param rowId the unique ID of the row
   * @param state validation state of the row as a unit, not the consolidated field states of the row
   */
  public setRowState = (rowId: number, state: ValidationState): void => {
    this.rowStates.set(rowId, state);

    this.onStatesUpdate?.(this.getOverallState(), state, undefined, rowId);
  };

  public registerField = (fieldName: string): void => {
    const [field, rowId] = parseFieldName(fieldName);

    this.states.set(rowId, { ...this.states.get(rowId), [field]: ValidationState.UNKNOWN });

    this.onStatesUpdate?.(
      this.getOverallState(),
      ValidationState.UNKNOWN,
      fieldName,
      rowId,
    );
  };

  public unRegisterField = (fieldName: string): void => {
    const [field, rowId] = parseFieldName(fieldName);

    delete this.states.get(rowId)?.[field];

    this.onStatesUpdate?.(
      this.getOverallState(),
      ValidationState.UNKNOWN,
      fieldName,
      rowId,
    );
  };

  public setFieldState = (state: ValidationState, fieldName: string): void => {
    const [field, rowId] = parseFieldName(fieldName);

    this.states.set(rowId, { ...this.states.get(rowId), [field]: state });

    this.onStatesUpdate?.(
      this.getOverallState(),
      state,
      fieldName,
      rowId,
    );
  };

  public getFieldState = (fieldName: string): ValidationState => {
    const [field, rowId] = parseFieldName(fieldName);

    return this.states.get(rowId)?.[field] as ValidationState;
  };

  public getFormState = (): ValidationState => this.getOverallState();

  public getFieldExtractInputValueMap = (): Map<string, ExtractInputValueType> => this.fieldExtractInputValueMap;

  public extractFieldInputValue = (formValue: unknown, field: string): unknown => {
    const extractInputValue = this.fieldExtractInputValueMap.get(field);

    return extractInputValue?.(formValue);
  };

  public registerFieldExtractInputValue = <ValueType, ReturnType = ValueType>(
    field: string,
    extractInputValue: ExtractInputValueType<ValueType, ReturnType>,
  ): void => {
    this.fieldExtractInputValueMap.set(field, extractInputValue as ExtractInputValueType);
  };

  // has validation if a field or a row has been registered
  public hasValidation = (): boolean => this.states.size > 0 || this.rowStates.size > 0;

  public registerFieldLabel = (fieldName: string, label?: string | undefined): void => {
    if (label) {
      this.fieldLabelMap.set(fieldName, label);
    }
  };

  public getFieldLabel = (fieldName: string): string | undefined => this.fieldLabelMap.get(fieldName);

  public getFieldLabelMap = (): Map<string, string> => this.fieldLabelMap;

  /*
   * Below are the methods that are barely implemented, as they are of no current relevance within the SimpleBuilder
   *
   * (ex. groups are irrelevant because within the SimpleBuilder there are no groups within)
   */

  public getGroupState = (): ValidationState => undefined as unknown as ValidationState;

  public getFieldErrors = (): string[] | undefined => undefined;

  public getFieldGroup = (): string | undefined => undefined;

  public getFieldGroupMap = (): Map<string, string> => new Map();

  public getGroupValues = (): FormValues => ({});

  public registerFieldGroup = (): void => { };

  public getValuesByFieldPrefix = (): FormValues => ({});
}
