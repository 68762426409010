import * as React from "react";
import {
  FormContext as PublicFormContext,
  FormErrors,
  FormState as PublicFormState,
  FormValues,
  ValidationState,
} from "./FormTypes";

export interface InternalFormState extends PublicFormState {
  registerFieldLabel: (field: string, label?: string) => void;
  registerFieldGroup: (field: string, group?: string) => void;
  registerFieldExtractInputValue: <ValueType, ReturnValueType = ValueType>(
    field: string,
    extractInputValue: ExtractInputValueType<ValueType, ReturnValueType>,
  ) => void;
  registerField: (field: string, group?: string) => void;
  unRegisterField: (field: string, group?: string) => void;
  getFieldLabel: (field: string) => string | undefined;
  getFieldLabelMap: () => Map<string, string>;
  getFieldGroup: (field: string) => string | undefined;
  getFieldGroupMap: () => Map<string, string>;
  getFieldExtractInputValueMap: () => Map<string, ExtractInputValueType<unknown>>;
  setFieldState: (state: ValidationState, field: string, group?: string) => void;
  getFieldState: (field: string, group?: string) => ValidationState;
  getFormState: () => ValidationState;
  getGroupState: (group: string) => ValidationState;
  deleteField: (field: string, group?: string) => void;
  getFieldErrors: (field: string, group?: string) => string[] | undefined;
}

export const InternalFormContext = PublicFormContext as unknown as React.Context<InternalFormState>;
export const InternalFormContextProvider = InternalFormContext.Provider;

export type FieldsStates = Record<string, ValidationState | Record<string, ValidationState>>;

export interface FormStates {
  overall: ValidationState;
  groups: Record<string, ValidationState>;
}

export type StatesUpdateCallback = (
  formStates: FormStates, fieldState: ValidationState, field: string, group?: string,
) => void;

export type ValueUpdateCallback = (formValues: FormValues, fieldValue: unknown, field: string, group?: string) => void;

export type ExtractInputValueType<ValueType = unknown, ReturnValueType = ValueType> = (
  formValue: ValueType
) => ReturnValueType | undefined;

export const getConsolidatedState = (states: ValidationState[]): ValidationState => (
  states.some(state => state === ValidationState.INVALID)
    ? ValidationState.INVALID
    : states.every(state => state === ValidationState.VALID) // also if empty, then the overall state is VALID
      ? ValidationState.VALID
      : ValidationState.UNKNOWN);

export enum FormValidationMode {
  ON = "ON",
  OFF = "OFF",
}

export type FormGroupsValidationModes = Record<string, FormValidationMode>;

export const FormValidationModeContext = React.createContext<FormValidationMode>(FormValidationMode.OFF);
export const FormValidationModeContextProvider = FormValidationModeContext.Provider;

export const FormGroupValidationModeContext = React.createContext<FormGroupsValidationModes>(
  {} as FormGroupsValidationModes,
);
export const FormGroupValidationModeContextProvider = FormGroupValidationModeContext.Provider;

export const FormErrorsContext = React.createContext<FormErrors>({});
export const FormErrorsContextProvider = FormErrorsContext.Provider;

export const getFieldErrors = (
  formErrors: FormErrors,
  field: string,
  group?: string,
): string[] | undefined => formErrors[String([field, group])];
