import * as React from "react";
import { mergeStyleSets, MessageBar, MessageBarButton, MessageBarType } from "@fluentui/react";

export enum OverviewInfoBlockStatus {
  "INFO",
  "WARNING",
  "ERROR",
}

export interface OverviewInfoBlockButton {
  /**
   * TestId of info block button
   */
  testId?: string;

  /**
   * onClick of info block button
   */
  onClick: () => void;

  /**
   * text of info block button
   */
  text: string;

}

export interface OverviewInfoBlockSection {
  /**
   * TestId of info block section
   */
  testId?: string;

  /**
   * Title of info block section
   */
  message: string | JSX.Element;

  /**
   * Message type for info block section
   */
  messageType: OverviewInfoBlockStatus;

  /**
   * actionButtons for the info block section
   */
  actionButtons?: OverviewInfoBlockButton[];

  /**
   * Determines if the info block is multi lined
   * When false, text will be clipped if it overflows
   * @default false
   */
  isMultiline?: boolean;
}

const classNames = mergeStyleSets({
  infoButtons: {
    listStyle: "none",
    paddingLeft: "0",
    display: "flex",
    margin: "0",
  },
  infoButton: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  blockContainer: { marginBottom: "15px" },
});

/**
 *
 * OverviewInfoBlockSectionTemplate is a component to show the overview info block section
 *
 */
export const OverviewInfoBlockSectionTemplate = (
  { message, messageType, testId, actionButtons, isMultiline = false }: OverviewInfoBlockSection,
): JSX.Element => {
  let actions: JSX.Element | undefined;
  if (actionButtons && actionButtons.length > 0) {
    actions = (
      <ul className={classNames.infoButtons}>
        {actionButtons?.map((button, i) => (
          <li key={i.toString()} className={classNames.infoButton}>
            <MessageBarButton data-test-id={button.testId} onClick={button.onClick}>{button.text}</MessageBarButton>
          </li>
        ))}
      </ul>
    );
  }

  let messageBarType: MessageBarType = MessageBarType.info;

  if (messageType === OverviewInfoBlockStatus.WARNING) {
    messageBarType = MessageBarType.warning;
  } else if (messageType === OverviewInfoBlockStatus.ERROR) {
    messageBarType = MessageBarType.error;
  }

  return (
    <div data-test-id={testId} className={classNames.blockContainer}>
      <MessageBar
        messageBarType={messageBarType}
        isMultiline={isMultiline}
        actions={actions}
      >
        {message}
      </MessageBar>
    </div>
  );
};
