import * as React from "react";
import { PrimaryButton } from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";

export interface OverviewGettingStartedItem {
  /**
   * TestId of current getting started item
   *
   */
  testId?: string;

  /**
    * Getting started item image
    */
  image?: React.ReactNode;

  /**
   * Getting started item title
   */
  title?: React.ReactNode;

  /**
   * Getting started item description
   */
  description?: React.ReactNode;

  /**
   * Getting started item button label
   */
  buttonLabel: React.ReactNode;

  /**
   * Getting started item button onClick action
   */
  onClick: () => void;

  /**
   * Whether getting started item button is disabled
   */
  disabled?: boolean;

}

export interface OverviewGettingStartedSection {

  /**
   * TestId of current getting started section
   */
  testId?: string;

  /**
   * Getting started section title
   */
  title?: string;

  /**
   * Getting started section items
   */
  items: OverviewGettingStartedItem[];
}

const classNames = mergeStyleSets({
  overviewGettingStarted: { paddingBottom: "40px" },
  overviewGettingStartedSectionTitle: {
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "18px",
  },
  gettingStartedRow: {
    paddingLeft: 0,
    marginTop: "-7px",
    marginLeft: "-7px",
    display: "flex",
    flexWrap: "wrap",
  },
  overviewGettingStartedItemImage: {
    height: "157px",
    width: "263px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  overviewGettingStartedItem: {
    flexDirection: "column",
    alignItems: "center",
    width: "400px",
    flex: "0 0 auto",
    cursor: "default",
    margin: "0 0 0 7px",
  },
  overviewGettingStartedItemTitle: {
    fontSize: "16px",
    fontWeight: "600",
    marginTop: "5px",
    marginBottom: "5px",
  },
  overviewGettingStartedItemDescription: {
    fontSize: "13px",
    fontWeight: "400",
    marginTop: "5px",
    marginBottom: "5px",
  },
});

export interface OverviewGettingStartedItemTestIds extends ComponentTestIds {
  image: string;
  title: string;
  description: string;
  button: string;
}

export const buildOverviewGettingStartedItemTestIds = (testId?: string): OverviewGettingStartedItemTestIds => ({
  component: buildTestId(testId),
  image: buildTestId(testId, "-image"),
  title: buildTestId(testId, "-title"),
  description: buildTestId(testId, "-description"),
  button: buildTestId(testId, "-button"),
});

/**
 *
 * OverviewGettingStartedSectionTemplate is a component to show the overview getting started section
 *
 */
export const OverviewGettingStartedSectionTemplate = ({
  testId,
  title,
  items,
}: OverviewGettingStartedSection): JSX.Element => {
  const getItemHtml = (item: OverviewGettingStartedItem, index: number): JSX.Element => {
    const overviewGettingStarterItemTestIds = buildOverviewGettingStartedItemTestIds(item.testId);
    const returnVal = (
      <div
        key={`overview-getting-started-item-${item.buttonLabel}${index}`}
        className={classNames.overviewGettingStartedItem}
        data-test-id={overviewGettingStarterItemTestIds.component}
      >
        {item.image && (
          <div
            className={classNames.overviewGettingStartedItemImage}
            data-test-id={overviewGettingStarterItemTestIds.image}
          >
            {item.image}
          </div>
        )}
        {item.title && (
          <div
            className={classNames.overviewGettingStartedItemTitle}
            data-test-id={overviewGettingStarterItemTestIds.title}
          >
            {item.title}
          </div>
        )}
        {item.description && (
          <div
            className={classNames.overviewGettingStartedItemDescription}
            data-test-id={overviewGettingStarterItemTestIds.description}
          >
            {item.description}
          </div>
        )}
        <PrimaryButton
          onClick={item.onClick}
          disabled={item.disabled}
          data-test-id={overviewGettingStarterItemTestIds.button}
        >
          {item.buttonLabel}
        </PrimaryButton>
      </div>
    );
    return returnVal;
  };
  return (
    <div
      className={classNames.overviewGettingStarted}
      data-test-id={testId}
    >
      {title && <div className={classNames.overviewGettingStartedSectionTitle}>{title}</div>}
      <div className={classNames.gettingStartedRow}>
        {items.map((item, i) => (getItemHtml(item, i)))}
      </div>
    </div>
  );
};
