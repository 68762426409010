import * as React from "react";
import {
  Checkbox,
  Dropdown,
  DropdownMenuItemType,
  ICheckboxStyles,
  IDropdown,
  IDropdownOption,
  IDropdownStyles,
  ISelectableDroppableTextProps,
  IStackStyles,
  IStackTokens,
  ResponsiveMode,
  Spinner,
  SpinnerSize,
  Stack,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { useErrors } from "../../hooks/useErrors";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  InternalFormContext,
  InternalFormState,
} from "./FormInternalTypes";
import { ValidationState } from "./FormTypes";
import { InternalLabelWrapper } from "./InternalLabelWrapper";
import { buildLabelWrapperTestIds, LabelWrapperProps, LabelWrapperTestIds } from "./LabelWrapperTypes";

export const OptionNewId = "__new__";
export interface SelectComponent {
  /**
   * Function to see text and data for the new option
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOptionNew: (text: string, data: any) => void;
  /**
   * Function to get the new option
   */
  getOptionNew: () => string | undefined;
}

export interface GroupOption {
  /**
   * If passed, it will show the options under this heading.
   */
  heading: string;
  /**
   * Array of options, each option is individual select row.
   */
  options: SelectOption[];
  /**
   * ID used to get the element during testing
   */
  testId?: string;
}

export interface SelectOption {
  /**
   * Whether or not the option is selected
   * @default false
   */
  selected?: boolean;
  /**
   * Unique identifier for the select option
   */
  id: string;
  /**
   * Text to render for this option
   */
  text: string;
  /**
   * Data to render for this option
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  /**
   * Makes option not selectable
   * @default false
   */
  disabled?: boolean;
  /**
   * Explanation of option
   */
  tooltip?: string;
  /**
   * Description for the option
   */
  description?: string;
  /**
   * ID used to get the element during testing
   */
  testId?: string;
}

export interface SelectProps extends
  // FIXME: Select should provide a list of elements in the onChange, instead of just the most recent one
  //        Also should not use magic string 'selectAll' to signify that all were selected
  BaseInputProps<string[], string, SelectOption[] | undefined>,
  Pick<LabelWrapperProps, "label"> {
  /**
   * Function to get the component reference for te option
   */
  componentRef?: (component: SelectComponent) => void;
  /**
   *Options to be listed for selection
   */
  options: SelectOption[] | GroupOption[];
  /**
   * Option to select multiple values
   */
  multiSelect?: boolean;
  /**
   * Select all the options when the multiSelect mode is turned on
   */
  selectAllOption?: boolean;
  /**
   * Hide search box
   * @default false
  */
  hideSearchBox?: boolean;
  /**
   * Show the number of options selected
   */
  showCount?: boolean;
  /**
   * Display the spinner when options are loading
   */
  loading?: boolean;
  /**
   * Callback invoked when the dropdown is clicked on and before it opens
   */
  onClick?: () => void;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    width: "100%",
    marginTop: "5px !important",
  },
  dropdownOptionText: { overflow: "visible", whiteSpace: "normal" },
  title: { height: "24px", lineHeight: "20px" },
  caretDownWrapper: { lineHeight: "25px" },
  dropdownItem: {
    height: "auto",
    "&:hover": { color: "#000000" },
  },
  dropdownItemSelected: { height: "auto" },
  dropdownItemHeader: {
    color: "#000000",
    "&:hover": { color: "#000000" },
  },
};

const dropdownLoadingStyles: Partial<IDropdownStyles> = {
  root: {
    width: "100%",
    minWidth: "100px",
    marginTop: "5px !important",
  },
  title: { height: "24px", lineHeight: "20px", border: "1px solid #605e5c", borderRadius: "2px" },
};

const checkBoxStyles: Partial<ICheckboxStyles> = { root: { paddingTop: "10px" } };

interface IDropdownOptionExt extends IDropdownOption {
  testId?: string;
  tooltip?: string;
}

export interface SelectTestIds extends ComponentTestIds, LabelWrapperTestIds {
  searchBox: string;
  selectAllOption: string;
}

export const buildSelectTestIds = (testId?: string): SelectTestIds => ({
  ...buildLabelWrapperTestIds(testId),
  searchBox: buildTestId(testId, "-search-box"),
  selectAllOption: buildTestId(testId, "-select-all-option"),
  component: buildTestId(testId),
});

/**
 * The Select component renders  a single select or a multi select options where the user can either select a
 * single or multiple options from the list.
 */
export const Select = ({
  testId,
  fieldName,
  groupName,
  componentRef,
  label,
  options,
  disabled,
  required,
  placeholder,
  // If the default value cannot be found in the options then validation will be triggered
  // but no value will be set within the form (or set visually)
  defaultValue,
  multiSelect,
  tooltip,
  inputLink,
  subField,
  statusInfo,
  selectAllOption,
  hideSearchBox = false,
  showCount,
  validator,
  onChange,
  onClick,
  loading,
  width,
  minWidth,
  ariaLabel,
}: SelectProps): JSX.Element => {
  const { groupName: layoutGroup, layout } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const group = groupName || layoutGroup;
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("Select should be used within form");
  }
  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);
  const [selectedKey, setSelectedKeys] = React.useState<string[]>();
  const [searchText, setSearchText] = React.useState<string>();

  const newOptionRef = React.useRef<SelectOption>();
  const dropDownRef = React.useRef<IDropdown>(null);

  const [allErrors, setFieldErrors] = useErrors(fieldName, group);

  const selectTestIds = buildSelectTestIds(testId);

  const selectOptions: IDropdownOptionExt[] = [];
  options.forEach((option, i: number) => {
    if ((option as GroupOption).heading) {
      const groupOption = option as GroupOption;
      selectOptions.push({
        key: `${i}-header`,
        text: groupOption.heading,
        itemType: DropdownMenuItemType.Header,
        testId: groupOption.testId,
      });
      groupOption.options.forEach(groupedItem => {
        selectOptions.push({
          key: groupedItem.id,
          text: groupedItem.text,
          data: groupedItem.data,
          disabled: groupedItem.disabled,
          tooltip: groupedItem.tooltip,
          ariaLabel: groupedItem.description,
          testId: groupedItem.testId,
        });
      });
      selectOptions.push({
        key: `${i}-divider`,
        text: "-",
        itemType: DropdownMenuItemType.Divider,
      });
    } else {
      const o = option as SelectOption;
      selectOptions.push({
        key: o.id,
        text: o.text,
        data: o.data,
        disabled: o.disabled,
        tooltip: o.tooltip,
        ariaLabel: o.description,
        testId: o.testId,
      });
    }
  });

  const isDescriptiveText = selectOptions.find(option => option?.ariaLabel !== undefined);
  if (newOptionRef.current) {
    selectOptions.unshift({ key: newOptionRef.current?.id, text: `(New) ${newOptionRef.current?.text}` });
  }

  if (selectAllOption && selectOptions.length) {
    selectOptions.unshift(
      {
        key: "selectAll",
        text: " ",
        // Keep as Header to allow for overriding the checkbox onChange event handler
        itemType: DropdownMenuItemType.Header,
      },
    );
  }

  const fieldValidation = (): void => {
    const value = form.getValue<SelectOption[]>(fieldName, group);
    const errorMsg = validator ? validator(value) : undefined;
    const requiredMessage = required ? value?.[0]?.id === undefined
      ? [Messages.validation.requiredValidation()] : undefined : undefined;
    const errors = [...requiredMessage || [], ...errorMsg || []];
    form.setFieldState(
      errors && errors?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );
    setFieldErrors(errors);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setOptionNew = (text: string, data: any): void => {
    const optionNew = { id: OptionNewId, text, data };
    newOptionRef.current = optionNew;
    setSelectedKeys([OptionNewId]);
    form.setValue([optionNew], fieldName, group);
    if (validator || required) {
      fieldValidation();
    }
    onChange?.(OptionNewId);
  };

  const getOptionNew = (): string | undefined => newOptionRef?.current?.text;

  let newSelection: SelectOption[] = form?.getValue<SelectOption[]>(fieldName, group) ?? [];

  const setSelectedOptions = (): void => {
    if (selectedKey?.length !== selectOptions.filter(opt => !opt.disabled).length - 1) {
      // eslint-disable-next-line max-len
      const selectedOptions = (options as SelectOption[])?.filter(option => option.id !== "selectAll" && !option.disabled);
      setSelectedKeys(selectedOptions.map(opt => opt.id));
      newSelection = selectedOptions;
    } else {
      setSelectedKeys([]);
      newSelection = [];
    }
    form.setValue(newSelection.length ? newSelection : undefined, fieldName, group);
    if (validator || required) {
      fieldValidation();
    }
    onChange?.("selectAll");
  };

  const extractInputValue = (formValue: SelectOption[] | undefined): string[] | undefined => {
    if (!formValue?.length) return undefined;
    const ids = formValue.map(selectedOption => selectedOption.id);
    return ids;
  };

  React.useEffect(() => {
    if (componentRef) {
      componentRef({ setOptionNew, getOptionNew } as SelectComponent);
    }

    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    if (validator || required) {
      form.registerField(fieldName, group);
    }

    if (defaultValue?.length) {
      let selectedOptions:SelectOption[] = [];
      if ((options as GroupOption[])?.[0]?.heading) {
        (options as GroupOption[])?.forEach(optionGroup => {
          optionGroup.options.forEach(o => {
            if (defaultValue.includes(o.id)) {
              selectedOptions.push(o);
            }
          });
        });
      } else {
        selectedOptions = (options as SelectOption[])?.filter(option => defaultValue.includes(option.id as string));
      }
      setSelectedKeys(defaultValue);
      form.setValue(selectedOptions.length ? selectedOptions : undefined, fieldName, group);
      fieldValidation();
    } else if (required) {
      form.setFieldState(ValidationState.INVALID, fieldName, group);
    }

    return function cleanup() {
      if (validator || required) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
      newOptionRef.current = undefined;
    };
    // Only at mount time. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const internalOnChange = (
    _: React.FormEvent<HTMLDivElement>,
    dropdownOption?: IDropdownOption,
  ): void => {
    if (multiSelect && dropdownOption !== undefined) {
      if (dropdownOption.selected) {
        newSelection.push(dropdownOption2SelectOption(dropdownOption));
      } else {
        newSelection = newSelection.filter(item => item.id !== dropdownOption.key);
      }
      setSelectedKeys(newSelection.map(key => key.id));
      form.setValue(newSelection.length ? newSelection : undefined, fieldName, group);
    } else {
      setSelectedKeys([dropdownOption?.key as string]);
      newSelection = [];
      newSelection.push(dropdownOption2SelectOption(dropdownOption));
      form.setValue(newSelection, fieldName, group);
    }

    if (validator || required) {
      fieldValidation();
    }
    onChange?.(dropdownOption?.key.toString() || "");
  };

  const dropdownOption2SelectOption = (dropdownOption: IDropdownOption | undefined): SelectOption => {
    if (dropdownOption?.key === OptionNewId && newOptionRef.current) {
      return { id: newOptionRef.current?.id, text: newOptionRef.current?.text, data: newOptionRef.current?.data };
    }
    return { id: dropdownOption?.key as string, text: dropdownOption?.text as string, data: dropdownOption?.data };
  };

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[group] === FormValidationMode.ON) {
      if (validator || required) {
        fieldValidation();
      }
    }
    // Only if validation mode changes. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  const showCountPlaceHolderText = placeholder === undefined ? Messages.hints.showSelection("0") : placeholder;

  const onRenderTitle = (
    props?: IDropdownOption[],
    defaultRender?: (props?: IDropdownOption[],
    ) => JSX.Element | null,
  ): JSX.Element => {
    if (props && defaultRender) {
      const defaultTitle = defaultRender(props);
      return (
        <span title={props[0].text}>{defaultTitle}</span>
      );
    }
    return <div />;
  };

  const onRenderCount = (): JSX.Element => {
    const count = form?.getValue<SelectOption[]>(fieldName, group)?.length ?? 0;
    return (
      <span>
        {Messages.hints.showSelection(count.toString())}
      </span>
    );
  };

  const onRenderCaretDown = (): JSX.Element => (
    <Spinner
      styles={{ root: { marginTop: 4 } }}
      size={SpinnerSize.xSmall}
    />
  );

  const stackTokens: IStackTokens = { childrenGap: 5 };
  const stackStyles: IStackStyles = isDescriptiveText ? { root: { marginTop: "10px" } } : {};

  const onRenderOption = (option?: IDropdownOptionExt): JSX.Element => (
    <TooltipHost content={option?.tooltip}>
      {option?.key === "selectAll" && (
        <Checkbox
          styles={checkBoxStyles}
          indeterminate={selectedKey?.length !== selectOptions.filter(
            opt => !opt.disabled,
          ).length - 1 && (selectedKey?.length ?? 0) !== 0}
          checked={selectedKey?.length === selectOptions.filter(opt => !opt.disabled).length - 1}
          label={Messages.actions.selectAll()}
          onChange={setSelectedOptions}
          inputProps={{ "data-test-id": selectTestIds.selectAllOption } as Record<string, string | undefined>}
        />
      )}
      <Stack
        tokens={stackTokens}
        styles={stackStyles}
        data-test-id={option?.testId}
      >
        <span
          style={isDescriptiveText
            ? { fontWeight: "600", whiteSpace: "nowrap" }
            : { whiteSpace: "nowrap" }}
        >
          {option?.text}
        </span>
        {option?.ariaLabel !== undefined && (
          <span style={{ fontWeight: "300" }}>{option?.ariaLabel}</span>
        )}
      </Stack>
    </TooltipHost>
  );

  const displayPlaceholder = loading
    ? Messages.common.loading()
    : showCount
      ? showCountPlaceHolderText
      : placeholder;

  let styles: Partial<IDropdownStyles> = dropdownStyles;
  if (loading) styles = dropdownLoadingStyles;

  const normalizeItems = (items: IDropdownOptionExt[]): IDropdownOptionExt[] => {
    const itemsToShow = items.map(option => {
      if (searchText?.trim() !== ""
        && option.itemType !== DropdownMenuItemType.Header
        && option.text.toLowerCase().indexOf(searchText?.trim().toLowerCase() || "") > -1) {
        return option;
      }
      if (searchText?.trim() !== ""
        && option.text.toLowerCase().indexOf(searchText?.trim().toLowerCase() || "") < 0) {
        return { ...option, hidden: true };
      }
      // when searchbox is empty
      return option;
    });

    return itemsToShow.filter(item => item.hidden !== true);
  };

  const onRenderList = (
    props?: ISelectableDroppableTextProps<IDropdown, HTMLDivElement>,
    defaultRender?: (props?: ISelectableDroppableTextProps<IDropdown, HTMLDivElement>) => JSX.Element | null,
  ): JSX.Element => {
    if (props && defaultRender && !hideSearchBox) {
      const wrapper = (
        <>
          <TextField
            styles={{
              root: { margin: "5px" },
              fieldGroup: { height: "25px" },
            }}
            placeholder={Messages.hints.selectExisting()}
            onChange={(_, val) => setSearchText(val)}
            data-test-id={selectTestIds.searchBox}
          />
          {props.options.length > 0 && (
            <Stack
              id="o4a-dropdown-items-container"
              style={{ maxHeight: 300, overflowY: "auto" }}
            >
              {defaultRender?.(props)}
            </Stack>
          )}
          {props.options.length === 0 && (
            <div style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 2,
              opacity: "0.7",
              fontStyle: "italic",
            }}
            >
              {Messages.hints.noResultsFound()}
            </div>
          )}
        </>
      );
      return wrapper;
    }
    return defaultRender?.(props) || <div />;
  };

  return (
    <InternalLabelWrapper
      // The Fluent Dropdown component does not use an HTML select control
      // Therefore we cannot use an HTML label component according to semantic
      // HTML standards.
      noHtmlFor
      errors={allErrors}
      fieldName={fieldName}
      layout={layout}
      width={width}
      minWidth={minWidth}
      inputLink={inputLink}
      label={label}
      required={required}
      statusInfo={statusInfo}
      subField={subField}
      tooltip={tooltip}
      testId={testId}
    >
      <Dropdown
        id={fieldName}
        ariaLabel={ariaLabel || label}
        data-test-id={selectTestIds.component}
        options={normalizeItems(selectOptions)}
        responsiveMode={ResponsiveMode.large}
        onChange={internalOnChange}
        onClick={onClick}
        onRenderTitle={showCount ? onRenderCount : onRenderTitle}
        disabled={loading || disabled}
        placeholder={displayPlaceholder}
        multiSelect={multiSelect}
        defaultSelectedKeys={selectedKey}
        defaultSelectedKey={selectedKey}
        onRenderCaretDown={loading ? onRenderCaretDown : undefined}
        onRenderOption={onRenderOption}
        onRenderList={onRenderList}
        calloutProps={hideSearchBox
          ? {
            calloutMaxHeight: 170,
            styles: { root: { minWidth: "fit-content" } },
          } : {
            styles: {
              root: { minWidth: "fit-content" },
              calloutMain: {
                overflowY: "clip !important",
                overflowX: "hidden",
              },
            },
          }}
        componentRef={dropDownRef}
        onDismiss={() => {
          dropDownRef.current?.focus();
          setSearchText("");
        }}
        styles={styles}
      />
    </InternalLabelWrapper>
  );
};
