import * as React from "react";
import { Stack } from "@fluentui/react";

/**
 * Available layouts typically used by the Inputs to determine which styles to apply
 */
export enum FormInputGroupLayout {
  /**
   * Typically will display the label and the input portion of the Inputs horizontally
   */
  WIDE = "WIDE",
  /**
   * Typically will display the label and the input portion of the Inputs vertically
   */
  COMPACT = "COMPACT",
}

export interface FormInputGroupProps {
  /**
   * The name of the group within the Form that the children metadata will be stored in
   */
  groupName: string;
  /**
   * The layout to be provided to the children
   * @default WIDE
   */
  layout?: FormInputGroupLayout;
  /**
   * Mainly consists of Inputs
   */
  children: React.ReactNode;
  /**
   * Custom styles to apply to the containing Stack
   */
  style?: React.CSSProperties;
}

type FormInputGroupLayoutContextValue = Required<Pick<FormInputGroupProps, "groupName" | "layout">>;

export const FormInputGroupLayoutContext = React.createContext<FormInputGroupLayoutContextValue>({
  groupName: "",
  layout: FormInputGroupLayout.WIDE,
});

export const FormInputGroupLayoutContextProvider = FormInputGroupLayoutContext.Provider;

export const FormInputGroup = ({
  groupName,
  children,
  layout = FormInputGroupLayout.WIDE,
  style,
}: FormInputGroupProps): JSX.Element => (
  <FormInputGroupLayoutContextProvider value={{ groupName, layout }}>
    <Stack style={style}>
      {children}
    </Stack>
  </FormInputGroupLayoutContextProvider>
);
