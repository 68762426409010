import * as React from "react";
import { CommandBar, ICommandBarStyles } from "@fluentui/react";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { InfoBlock } from "../InfoBlock/InfoBlock";
import { CustomOverflowButton } from "../InternalComponents/CustomOverflowButton";
import { ActionBarProps, ActionType, ConfirmAction, CustomAction } from "./ActionTypes";
import { actionBarDefaultId, ConfirmActionDialog, getActionItems, isConfirmAction } from "./InternalActionTypes";

const actionBarStyles: ICommandBarStyles = {
  root: {
    height: "36px",
    padding: "0px",
    borderBottom: "1px solid rgb(214, 214, 214)",
    ".ms-Button-label": {
      fontSize: "13px",
      fontWeight: 400,
    },
    ".ms-Button-menuIcon": { lineHeight: "20px" },
  },
};

export const ActionBar = ({
  actions,
  infoBlocks,
  disableAll,
  onActionClick,
  testId,
  id = actionBarDefaultId,
}: ActionBarProps): JSX.Element => {
  const ref = React.useRef(null);

  const [confirmAction, setConfirmAction] = React.useState<ConfirmAction>();
  const hideConfirmAction = (): void => {
    setConfirmAction(undefined);
  };

  React.useEffect(() => {
    if (confirmAction) {
      confirmAction.onCancel?.();
      hideConfirmAction();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  const onActionItemClick = (action: ActionType) => (): void => {
    confirmAction?.onCancel?.();
    setConfirmAction(undefined);

    (action as ConfirmAction | CustomAction).onClick?.();
    onActionClick?.(action.key);

    if (isConfirmAction(action)) {
      setConfirmAction(action as ConfirmAction);
    }
  };

  const { width: dialogWidth } = useResizeObserver(ref);

  return (
    <div ref={ref}>
      <CommandBar
        data-test-id={testId}
        id={id}
        styles={actionBarStyles}
        items={getActionItems(actions, undefined, onActionItemClick, disableAll)}
        overflowButtonAs={CustomOverflowButton}
      />
      {infoBlocks && (
        infoBlocks?.map((statusInfo, index) => (
          <InfoBlock
            {...statusInfo}
              // eslint-disable-next-line react/no-array-index-key
            key={`infoBlock${index}`}
          />
        ))
      )}
      {confirmAction && (
        <ConfirmActionDialog
          targetId={id}
          onConfirm={hideConfirmAction}
          onCancel={hideConfirmAction}
          confirmAction={confirmAction}
          width={dialogWidth}
        />
      )}
    </div>
  );
};
