/**
 * All components that expose builder functions for their test ids should extend this interface.
 */
export interface ComponentTestIds {
  /**
   * The main 'component' that the user interacts with.
   * The pattern is that this should be the unmodified testId representing the component as a whole.
   */
  component: string;
}

export const buildTestId = (baseTestId?: string, suffix = ""): string => (baseTestId ? `${baseTestId}${suffix}` : "");
