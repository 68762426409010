import * as React from "react";
import {
  DefaultButton,
  getId,
  IButtonStyles,
  Icon,
  IconButton,
  IMessageBarStyles,
  IPanel,
  IPanelProps,
  IPanelStyles,
  IProcessedStyleSet,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  registerIcons,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { uniqueGUID } from "../../helpers/util";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { anchoredPanelLayoutManager, appLayoutManager } from "../../models/LayoutManager";
import { screenSizeManager } from "../Context/InternalScreenSizeContext";
import { DetailViewsMenuZoomLayout } from "../Context/ScreenSizeContextTypes";
import { Breadcrumbs } from "../Navigation/Breadcrumbs";
import { BookmarkablePageContext, BookmarkablePageContextValue } from "../Page/InternalBookmarkablePage";
import { Status } from "../Status/Status";
import { CircleCheckmarkSolid, CircleMultiplySolid, Warning } from "../Svg/icons";
import {
  AnchoredPanelComponent,
  AnchoredPanelFooterType,
  AnchoredPanelHeaderLayoutType,
  AnchoredPanelInternalIds,
  AnchoredPanelProps,
  AnchoredPanelType,
  buildAnchoredPanelTestIds,
  ButtonType,
  FocusableArea,
  MessageType,
} from "./AnchoredPanelTypes";
import { DotsJustification, InProgressIndicator } from "./InProgressIndicator";

export interface PanelFavorite {
  /**
   * Specify if the favorite star icon should be selected or not
   */
  isFavorite: boolean;
  /**
   * Callback triggered when the user toggle the favorite star icon.
   */
  onChange?: (isFavorite: boolean) => void;
}
export interface InternalAnchoredPanelProps extends AnchoredPanelProps {
  firstFocusableTarget?: (element: HTMLElement) => HTMLElement | null;
  hideCloseButton?: boolean;
  favorite?: PanelFavorite;
}

const basePanelStyles: Partial<IPanelStyles> = {
  main: {
    animationDuration: "0",
    background: "#FFFFFF",
  },
  navigation: {
    flexDirection: "column",
    justifyContent: "unset",
    height: "unset",
    background: "inherit",
  },
  headerText: {
    fontSize: "24px",
    lineHeight: "28px",
    height: "32px",
  },
  commands: {
    "&": {
      position: "sticky !important",
      top: "0px",
      zIndex: "1",
    },
  },
  content: {
    overflowX: "hidden",
    overflowY: "auto",
    background: "inherit",
  },
  contentInner: { background: "inherit" },
  scrollableContent: {
    height: "100%",
    flexDirection: "inherit",
    background: "inherit",
  },
  footer: {
    borderTop: "1px solid",
    borderColor: "#bbb",
    background: "inherit",
    "&": { position: "static !important" },
  },
  footerInner: { padding: 20 },
  subComponentStyles: {
    closeButton: {
      root: {
        height: "32px",
        width: "32px",
      },
      rootHovered: {
        backgroundColor: "rgb(186, 20, 26)",
        color: "rgb(255, 255, 255)",
      },
      rootPressed: {
        backgroundColor: "rgb(186, 20, 26)",
        color: "rgb(255, 255, 255)",
      },
    },
  },
};

const NonStickyHeaderStyles: Partial<IPanelStyles> = {
  commands: { "&": { position: "unset !important" } },
  content: { overflowY: "hidden" },
  contentInner: { overflowY: "unset" },
  scrollableContent: { display: "block", overflowY: "unset" },
};

const fullWidthPanelStyles: Partial<IPanelStyles> = { main: { boxShadow: "unset" } };

const footerPanelStickyStyles: Partial<IPanelStyles> = { footer: { position: "sticky" } };

const messageBarStyles: Partial<IMessageBarStyles> = {
  root: {
    margin: "0px 20px",
    width: "unset",
  },
  content: {
    height: "50px",
    alignItems: "center",
  },
};

const messageDetailsBarStyles: Partial<IMessageBarStyles> = { actions: { flexDirection: "row" } };

const barBackgroundError: IMessageBarStyles = {
  root: {
    backgroundColor: "#fef0f1",
    borderColor: "#fef0f1",
  },
};
const barBackgroundWarning: IMessageBarStyles = {
  root: {
    backgroundColor: "#fff8f0",
    borderColor: "#fff8f0",
  },
};
const barBackgroundSuccess: IMessageBarStyles = {
  root: {
    backgroundColor: "#f8fff0",
    borderColor: "#f8fff0",
  },
};

const favoriteStyles: IButtonStyles = {
  root: { backgroundColor: "inherit", color: "inherit" },
  rootChecked: { backgroundColor: "inherit", color: "inherit" },
  rootHovered: { backgroundColor: "#f3f2f1", color: "inherit" },
  rootCheckedHovered: { backgroundColor: "#f3f2f1", color: "inherit" },
  rootPressed: { backgroundColor: "#edebe9" },
  icon: { fontSize: "14px" },
};

const getPanelType = (type?: AnchoredPanelType): PanelType => {
  switch (type) {
    case AnchoredPanelType.CUSTOM_WIDTH:
      return PanelType.custom;
    case AnchoredPanelType.SMALL:
      return PanelType.smallFixedFar;
    case AnchoredPanelType.MEDIUM:
      return PanelType.medium;
    case AnchoredPanelType.LARGE:
      return PanelType.large;
    case AnchoredPanelType.FULL_WIDTH:
    default:
      return PanelType.smallFluid;
  }
};

registerIcons({
  icons: {
    "error-svg": CircleMultiplySolid,
    "success-svg": CircleCheckmarkSolid,
    "warning-svg": Warning,
  },
});

/**
 * The AnchoredPanel renders the components provided by the caller within the panel
 * based on the specified width with a header and a footer.
 */

export const InternalAnchoredPanel = (
  {
    componentRef,
    title,
    status,
    subTitle,
    icon,
    type = AnchoredPanelType.FULL_WIDTH,
    customWidth,
    isOpen,
    isBlocking = false,
    onClose,
    preventCloseOnEsc,
    footer,
    message,
    favorite,
    children,
    testId,
    firstFocusableTarget,
    initialFocusableArea = FocusableArea.CONTENT,
    onHeaderLayoutChange,
    hideCloseButton = false,
  }: InternalAnchoredPanelProps,
): JSX.Element => {
  const ref = React.useRef<HTMLElement | undefined>();
  const { height: panelHeight } = useResizeObserver(ref);

  const navRef = React.useRef<HTMLElement | undefined>();
  const { height: navHeight } = useResizeObserver(navRef);

  const headerLayoutType = React.useRef<AnchoredPanelHeaderLayoutType>(
    anchoredPanelLayoutManager.getHeaderLayoutType(panelHeight || screenSizeManager.getLastPanelHeight()),
  );
  const footerType = React.useRef<AnchoredPanelFooterType>(
    anchoredPanelLayoutManager.getFooterType(panelHeight || screenSizeManager.getLastPanelHeight()),
  );

  const { withBreadcrumbs } = React.useContext<BookmarkablePageContextValue>(BookmarkablePageContext);

  React.useLayoutEffect(() => {
    if (panelHeight > 0) {
      screenSizeManager.setLastPanelHeight(panelHeight);

      const anchoredPanelHeaderLayoutType = anchoredPanelLayoutManager.getHeaderLayoutType(panelHeight);
      headerLayoutType.current = anchoredPanelHeaderLayoutType;

      const anchoredPanelFooterType = anchoredPanelLayoutManager.getFooterType(panelHeight);
      footerType.current = anchoredPanelFooterType;

      // console.log(`>>> ${panelHeight} -> ${anchoredPanelFooterType} -> ${screenSizeManager.menuLayoutType}`);
    }
  }, [panelHeight]);

  React.useEffect(() => {
    onHeaderLayoutChange?.(headerLayoutType.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerLayoutType.current]);

  const panelRef = React.useRef<IPanel>(null);
  const thisRef = React.useRef<AnchoredPanelComponent>();

  const [initialFocus, setInitialFocus] = React.useState<HTMLElement | null>(null);
  const [showInProgress, setShowInProgress] = React.useState<boolean>(false);
  const [isFavorite, setIsFavorite] = React.useState<boolean>(favorite?.isFavorite || false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const panelId = React.useMemo(() => uniqueGUID(), [JSON.stringify(title)]);
  const contentSiblingId = React.useMemo(() => `panel-content-sibling-${panelId}`, [panelId]);
  const panelNavigationId = React.useMemo(() => `panel-navigation-${panelId}`, [panelId]);
  const footerId = React.useMemo(() => `panel-footer-${panelId}`, [panelId]);

  const internalFirstFocusableTarget = (element: HTMLElement): HTMLElement | null => {
    let firstFocusable = firstFocusableTarget?.(element);
    if (!firstFocusable) {
      const focusable: NodeListOf<HTMLElement> = element?.querySelectorAll(
        // eslint-disable-next-line max-len
        "button, div:not([hidden]) > [href], input, select, textarea, [tabindex]:not([tabindex='-1']):not([aria-hidden='true'])",
      );
      firstFocusable = focusable?.[0] || document.querySelector(`#${panelNavigationId} > button`) as HTMLElement;
    }
    return firstFocusable;
  };

  React.useEffect(() => {
    if (componentRef) {
      thisRef.current = {
        open: () => panelRef.current?.open(),
        close: () => panelRef.current?.dismiss(),
        toggleInProgress: (on: boolean) => setShowInProgress(on),
        getInternalIds: () => ({
          panelId,
          contentSiblingId,
          panelNavigationId,
        } as AnchoredPanelInternalIds),
      } as AnchoredPanelComponent;
      componentRef(thisRef.current);
    }
    setTimeout(() => {
      ref.current = document.getElementById(panelId) ?? undefined;
      navRef.current = document.getElementById(panelNavigationId)?.parentElement ?? undefined;

      let focusWithin: Element | null | undefined;

      if (initialFocusableArea === FocusableArea.CONTENT) {
        focusWithin = document.getElementById(contentSiblingId)?.nextElementSibling;
      } else if (initialFocusableArea === FocusableArea.FOOTER) {
        focusWithin = document.getElementById(footerId);
      }

      const firstFocusable = internalFirstFocusableTarget(focusWithin as HTMLElement);
      setInitialFocus(firstFocusable);
    }, 100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(title)]);

  React.useEffect(() => {
    if (initialFocus) {
      setTimeout(() => {
        initialFocus.focus();
      }, 100);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFocus]);

  React.useEffect(() => {
    favorite?.onChange?.(isFavorite);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFavorite]);

  const onDismiss = (ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent): void => {
    if (preventCloseOnEsc && (ev as KeyboardEvent)?.key === "Escape") {
      ev?.preventDefault();
    } else {
      onClose?.();
    }
  };

  const onRenderDummyHeader = (): JSX.Element => (
    <div id={contentSiblingId} />
  );

  const renderHeader = (
    props: IPanelProps,
  ): JSX.Element | null => {
    const { headerTextProps = {} } = props;
    const headerTextId = title && `${getId("Panel")}-headerText`;
    let primaryHeaderText;
    let secondaryHeaderText;
    let tooltipText;
    if (title) {
      if (typeof title === "string") {
        primaryHeaderText = title;
        tooltipText = title;
      } else {
        primaryHeaderText = title.primary;
        secondaryHeaderText = title.secondary;
        if (!(headerLayoutType.current === AnchoredPanelHeaderLayoutType.SCROLL_WITH_CONTENT
          && screenSizeManager.getDetailViewsMenuZoomLayout() === DetailViewsMenuZoomLayout.DROPDOWN)) {
          tooltipText = `${primaryHeaderText} | ${secondaryHeaderText}`;
        } else {
          tooltipText = `${primaryHeaderText}`;
        }
      }

      return (
        <div style={{ flexGrow: 1, alignSelf: "flex-start", minWidth: "0px" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {icon && <Icon iconName={icon} styles={{ root: { width: "35px", height: "35px" } }} />}
            <div style={{ display: "flex", flexDirection: "column", minWidth: "0px" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  id={headerTextId}
                  role="heading"
                  aria-level={1}
                  {...headerTextProps}
                  style={{
                    fontSize: "24px",
                    fontWeight: 600,
                    color: "rgb(50, 49, 48)",
                    lineHeight: "28px",
                    height: "32px",
                    marginRight: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: "1",
                    flexShrink: "1",
                  }}
                  title={tooltipText}
                >
                  {primaryHeaderText}
                  {secondaryHeaderText
                  && !(headerLayoutType.current === AnchoredPanelHeaderLayoutType.SCROLL_WITH_CONTENT
                  && screenSizeManager.getDetailViewsMenuZoomLayout() === DetailViewsMenuZoomLayout.DROPDOWN)
                  && (
                    <span
                      style={{ fontWeight: 400 }}
                    >
                      {` | ${secondaryHeaderText}`}
                    </span>
                  )}
                </div>
                {favorite && (
                  <IconButton
                    toggle
                    iconProps={{ iconName: isFavorite ? "FavoriteStarFill" : "FavoriteStar" }}
                    checked={isFavorite}
                    title={isFavorite ? Messages.hints.removeFromFavorites() : Messages.hints.addToFavorites()}
                    aria-label={isFavorite ? Messages.hints.removeFromFavorites() : Messages.hints.addToFavorites()}
                    styles={favoriteStyles}
                    onClick={() => setIsFavorite(isChecked => !isChecked)}
                  />
                )}
                {status && (
                  <span style={{ alignSelf: "center" }}>
                    <Status
                      status={status.status}
                      tooltip={status.tooltip}
                      hideClipboardCopy
                    />
                  </span>
                )}
                {(headerLayoutType.current === AnchoredPanelHeaderLayoutType.SCROLL_WITH_CONTENT
                  && screenSizeManager.getDetailViewsMenuZoomLayout() === DetailViewsMenuZoomLayout.DROPDOWN)
                  && (
                    <div
                      style={{
                        flexGrow: "1",
                        flexShrink: "1",
                        minWidth: "120px",
                      }}
                    >
                      {screenSizeManager.getDetailViewsMenu()}
                    </div>
                  )}
              </div>
              {subTitle && (
                <span
                  key="subtitle"
                  style={{ fontSize: "12px", lineHeight: "14px", color: "#646464" }}
                >
                  {subTitle}
                </span>
              )}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const getMessageBarData = (messageType: MessageType):
  { type: MessageBarType, iconName: string, styles: IMessageBarStyles } => {
    switch (messageType) {
      case MessageType.IN_PROGRESS:
        return {
          type: MessageBarType.info,
          iconName: "info",
          styles: mergeStyleSets(messageBarStyles),
        };
      case MessageType.ERROR:
        return {
          type: MessageBarType.error,
          iconName: "error-svg",
          styles: mergeStyleSets(
            messageBarStyles,
            barBackgroundError,
          ),
        };
      case MessageType.WARNING:
        return {
          type: MessageBarType.warning,
          iconName: "warning-svg",
          styles: mergeStyleSets(
            messageBarStyles,
            barBackgroundWarning,
          ),
        };
      case MessageType.SUCCESS:
        return {
          type: MessageBarType.success,
          iconName: "success-svg",
          styles: mergeStyleSets(
            messageBarStyles,
            barBackgroundSuccess,
          ),
        };
      case MessageType.INFO:
      default:
        return { type: MessageBarType.info, iconName: "info", styles: messageBarStyles };
    }
  };

  const onRenderNavigation = (
    props?: IPanelProps,
    defaultRender?: (props?: IPanelProps) => JSX.Element | null,
  ): JSX.Element => {
    const navigationNode = hideCloseButton ? undefined : defaultRender?.(props);
    if (navigationNode) {
      const messageBarNode = message && (
        <MessageBar
          delayedRender={false}
          styles={
            message.details
              ? mergeStyleSets(getMessageBarData(message.type).styles, messageDetailsBarStyles)
              : getMessageBarData(message.type).styles
          }
          messageBarIconProps={{ iconName: getMessageBarData(message.type).iconName }}
          onDismiss={message.onDismiss ? () => message.onDismiss?.() : undefined}
          actions={message.details}
        >
          {
            message.type === MessageType.IN_PROGRESS
              ? (
                <div style={{ minWidth: "500px" }}>
                  <div style={{ display: "inline-flex" }}>{message.text}</div>
                  <InProgressIndicator justification={DotsJustification.LEFT} />
                </div>
              )
              : message.text
            }
        </MessageBar>
      );
      const navigationNodeChildren = (
        <>
          {withBreadcrumbs && <Breadcrumbs />}
          {showInProgress && <InProgressIndicator justification={DotsJustification.CENTER} />}
          <div
            data-test-id={buildAnchoredPanelTestIds(testId).header}
            id={panelNavigationId}
            style={{ display: "flex", justifyContent: "flex-end", padding: "8px 20px" }}
          >
            {renderHeader(props || {})}
            {React.Children.toArray(navigationNode?.props.children)}
          </div>
          {messageBarNode}
        </>
      );
      return React.cloneElement(navigationNode, { ...navigationNode.props, children: navigationNodeChildren });
    }
    return (<div />);
  };

  const onRenderFooterContent = (): JSX.Element | null => {
    if (footer && footer.length > 0) {
      return (
        <div id={footerId}>
          {footer?.map(button => {
            if (button.type === ButtonType.PRIMARY) {
              return (
                <PrimaryButton
                  key={button.label}
                  onClick={button.onClick}
                  disabled={button.disabled}
                  style={button.style}
                  data-test-id={button.testId}
                >
                  {button.label}
                </PrimaryButton>
              );
            }
            return (
              <DefaultButton
                key={button.label}
                onClick={button.onClick}
                disabled={button.disabled}
                style={button.style}
                data-test-id={button.testId}
              >
                {button.label}
              </DefaultButton>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const getPanelStyles = (): IProcessedStyleSet<Partial<IPanelStyles>> => {
    const topPadding = (headerLayoutType.current === AnchoredPanelHeaderLayoutType.SCROLL_WITH_CONTENT
      && screenSizeManager.getDetailViewsMenuZoomLayout() === DetailViewsMenuZoomLayout.DROPDOWN)
      ? 0 : 20;

    let styles;
    switch (type) {
      case AnchoredPanelType.FULL_WIDTH:
        styles = mergeStyleSets(basePanelStyles, fullWidthPanelStyles);
        break;
      default:
        styles = mergeStyleSets(basePanelStyles);
        break;
    }
    if (!hideCloseButton) {
      styles = mergeStyleSets(styles, { content: { padding: `${topPadding}px 20px 0px 20px` } });
    }
    if (footerType.current === AnchoredPanelFooterType.FIXED_AT_BOTTOM) {
      styles = mergeStyleSets(styles, footerPanelStickyStyles);
    }
    if (headerLayoutType.current === AnchoredPanelHeaderLayoutType.SCROLL_WITH_CONTENT
        && screenSizeManager.getDetailViewsMenuZoomLayout() !== DetailViewsMenuZoomLayout.DROPDOWN) {
      styles = mergeStyleSets(styles, NonStickyHeaderStyles);
    }
    styles = mergeStyleSets(styles, { content: { height: `calc(100% - ${navHeight}px - ${topPadding}px)` } });
    return styles;
  };

  return (
    <Panel
      key={panelId}
      id={panelId}
      componentRef={panelRef}
      hasCloseButton={!hideCloseButton}
      isOpen={isOpen}
      isBlocking={isBlocking}
      focusTrapZoneProps={{ disabled: !isBlocking }}
      type={getPanelType(type)}
      customWidth={type === AnchoredPanelType.CUSTOM_WIDTH && customWidth ? `${customWidth}px` : undefined}
      closeButtonAriaLabel={Messages.ariaLabel.close()}
      onRenderHeader={onRenderDummyHeader}
      onRenderNavigation={onRenderNavigation}
      onRenderFooterContent={footer && footer.length > 0 ? onRenderFooterContent : undefined}
      isFooterAtBottom={footerType.current === AnchoredPanelFooterType.FIXED_AT_BOTTOM && footer && footer.length > 0}
      onDismiss={onDismiss}
      styles={getPanelStyles()}
      layerProps={{ hostId: appLayoutManager.mainAnchorId }}
    >
      {children}
    </Panel>
  );
};
