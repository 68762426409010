import * as React from "react";
import {
  IButtonStyles,
  ICalloutProps,
  IconButton,
  IIconProps,
  ITooltipHostStyles,
  mergeStyleSets,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";

const azBlueBorder = "#0078d4";

const codeSnippetVerticalPadding = 6;
const codeSnippetLineHeight = 18;
const codeSnippetMinHeight = codeSnippetLineHeight + codeSnippetVerticalPadding;

// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline" } };

const iconStyles: IButtonStyles = { iconHovered: { color: "#323130" } };

const classNames = mergeStyleSets({
  wrapper: {
    position: "relative",
    boxSizing: "border-box",
    minHeight: `${codeSnippetMinHeight}px`,
    height: "min-content",
  },
  button: {
    display: "table",
    boxSizing: "border-box",
    minWidth: 0,
    height: "20px",
    width: "20px",
    padding: "0 4px",
    margin: "2px 2px 2px 0",
    fontSize: "13px",
    lineHeight: 1.385,
    position: "absolute",
    top: 0,
    right: 0,
    "&:focus": { border: `1px solid ${azBlueBorder}` },
  },
  icon: {
    boxSizing: "border-box",
    textAlign: "center",
    verticalAlign: "middle",
    width: "12px !important",
    height: "12px !important",
    padding: "0 important",
    color: "#605E5C",
    marginTop: "-5px !important",
    fontSize: "13px !important",
  },
  componentWrapper: {
    minHeight: `${codeSnippetMinHeight}px`,
    height: "min-content",
    lineHeight: `${codeSnippetLineHeight}px`,
    backgroundColor: "#E6E6E6",
    width: "100%",
    boxSizing: "border-box",
    padding: "3px 25px 3px 8px",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: "2px",
    border: "1px solid transparent",
    display: "inline-block",
    "&:focus-within": { borderColor: azBlueBorder, boxShadow: `inset 0 0 0 1px ${azBlueBorder}` },
  },
  component: {
    fontSize: "13px",
    fontFamily: "'Segoe UI Web (West European)'",
    lineHeight: `${codeSnippetLineHeight}px`,
    backgroundColor: "transparent",
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    border: "none",
    outline: "none",
    padding: 0,
  },
});

export interface CodeSnippetProps {
  /**
   * The text to display
   */
  text: string;
  /**
   * Determines the number of rows to display
   * @defaults 1
   */
  numberOfRows?: number
  /**
   * Id used to get the code snippet element for unit tests
   */
  testId?: string;

  /**
   * Aria-label to be displayed on the textarea and textinput
   */
  ariaLabel?: string;
}

export const CodeSnippet = ({ numberOfRows = 1, text, testId, ariaLabel }: CodeSnippetProps): JSX.Element => {
  const [copied, setCopied] = React.useState(false);

  const tooltipId = useId("tooltip");
  const buttonId = useId("targetButton");

  const calloutProps: ICalloutProps = { gapSpace: 0, target: `#${buttonId}` };
  const iconProps: IIconProps = { iconName: "Copy", "aria-describedby": tooltipId, className: classNames.icon };

  const copyCodeSnippet = (): void => {
    if (!copied) setCopied(true);
    navigator.clipboard.writeText(text);
  };

  // Prevent consumer from setting numberOfRows to a value less than 1
  const internalNumberOfRows = numberOfRows < 1 ? 1 : numberOfRows;

  const codeSnippetHeight = internalNumberOfRows > 1
    ? `${codeSnippetVerticalPadding + codeSnippetLineHeight * internalNumberOfRows}px`
    : `${codeSnippetMinHeight}px`;

  const onMouseLeave = (): void => {
    if (copied) setCopied(false);
  };

  const textareaStyle: React.CSSProperties = {
    height: internalNumberOfRows * codeSnippetLineHeight,
    overflowY: text.split("\n").length > internalNumberOfRows ? "auto" : "hidden",
    resize: "none",
  };

  const textInputStyle: React.CSSProperties = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    position: "relative",
    top: "-2px",
  };

  const wrapperStyle: React.CSSProperties = { height: codeSnippetHeight };

  const textArea = (
    <textarea
      key={text}
      data-test-id={testId}
      aria-label={ariaLabel}
      className={classNames.component}
      style={textareaStyle}
      readOnly
      wrap="off"
      rows={internalNumberOfRows}
    >
      {text}
    </textarea>
  );

  const textInput = (
    <input
      key={text}
      aria-label={ariaLabel}
      data-test-id={testId}
      className={classNames.component}
      style={textInputStyle}
      readOnly
      value={text}
    />
  );

  const component = internalNumberOfRows > 1 ? textArea : textInput;

  return (
    <div style={wrapperStyle} className={classNames.wrapper}>
      <div style={wrapperStyle} className={classNames.componentWrapper}>
        {component}
      </div>
      <TooltipHost
        content={copied ? Messages.actions.copiedToClipboard() : Messages.actions.copyToClipboard()}
        id={tooltipId}
        calloutProps={calloutProps}
        styles={hostStyles}
      >
        <IconButton
          id={buttonId}
          onClick={copyCodeSnippet}
          className={classNames.button}
          iconProps={iconProps}
          ariaLabel={copied ? Messages.actions.copiedToClipboard() : Messages.actions.copyToClipboard()}
          aria-describedby={tooltipId}
          styles={iconStyles}
          onMouseLeave={onMouseLeave}
        />
      </TooltipHost>
    </div>
  );
};
