/* eslint-disable no-underscore-dangle */
import { uniqueGUID } from "../../helpers/util";
import { RecentAccessItem } from "./RecentAccessTypes";

const LocalStorageRecentAccessKey = "O4A-RECENT";

type RecentAccessListener = (recentItems: RecentAccessItem[]) => void;

class RecentAccessManager {
  private _recentItems: Map<string, RecentAccessItem> = new Map<string, RecentAccessItem>();

  private _listeners: Map<string, RecentAccessListener> = new Map<string, RecentAccessListener>();

  constructor() {
    // Fetch the list from local storage and populate the in-memory map
    const recentAccessStored = window.localStorage.getItem(LocalStorageRecentAccessKey);
    if (recentAccessStored) {
      const recentAccessItems = JSON.parse(recentAccessStored) as RecentAccessItem[];
      if (Array.isArray(recentAccessItems)) {
        recentAccessItems.forEach(item => this._recentItems.set(item.id, item));
      }
    }
  }

  public addListener = (listener: RecentAccessListener): string => {
    const listenerId = uniqueGUID();
    this._listeners.set(listenerId, listener);
    listener(this.items); // invoke the listener upon registration to pass the most recent list
    return listenerId;
  };

  public removeListener = (listenerId: string): void => {
    this._listeners.delete(listenerId);
  };

  public get items(): RecentAccessItem[] { return [...this._recentItems.values()]; }

  public addItem = (item : RecentAccessItem): void => { this._recentItems.set(item.id, item); };

  public clearItem = (itemId: string): void => { this._recentItems.delete(itemId); };

  public clearAll = (): void => { this._recentItems.clear(); };
}

export const recentAccessManager = new Proxy(new RecentAccessManager(), {
  get: (target, prop) => {
    const targetProp = prop in target ? target[prop as keyof typeof target] : undefined;
    // Intercept all methods that manipulate the items list to keep the local storage in sync
    // and to call the listeners with the modified list
    if (typeof targetProp === "function"
      && (prop === "addItem" || prop === "clearItem" || prop === "clearAll")
    ) {
      return new Proxy(targetProp, {
        apply: (funcTarget, thisArg, argumentsList) => {
          const result = Reflect.apply(funcTarget, thisArg, argumentsList);
          const items: RecentAccessItem[] = Array.from(Reflect.get(target, "_recentItems").values());
          window.localStorage.setItem(LocalStorageRecentAccessKey, JSON.stringify(items));
          const listeners: RecentAccessListener[] = Array.from(Reflect.get(target, "_listeners").values());
          listeners.forEach(listener => listener(items));
          return result;
        },
      });
    }
    return Reflect.get(target, prop);
  },
});
