import * as React from "react";

/**
 * Function to stringify data that may contain React/JSX element
 * @param data the data to be serialized to a string
 * @returns string representation of the data
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stringifyReact = (data: any): string => JSON.stringify(data, (_key, value) => {
  if (React.isValidElement(value)) {
    return reactNodeToString(value);
  }
  return value;
});

const reactNodeToString = (reactNode: React.ReactNode): string => {
  let string = "";
  if (typeof reactNode === "string") {
    string = reactNode;
  } else if (typeof reactNode === "number") {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach(child => {
      string += reactNodeToString(child);
    });
  } else if (React.isValidElement(reactNode)) {
    string += reactNodeToString(reactNode.props);
  } else if (typeof reactNode === "object") {
    string += JSON.stringify(reactNode);
  }
  return string;
};
