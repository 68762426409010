import * as React from "react";
import { ContextualMenu } from "@fluentui/react";
import { ActionMenuProps, ActionType, ConfirmAction, CustomAction } from "./ActionTypes";
import { ConfirmActionDialog, getActionItems, isConfirmAction } from "./InternalActionTypes";

export const ACTION_MENU_WIDTH = 20;

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const ActionMenu = <T,>({
  anchor,
  actions,
  item,
  confirmDialogProps,
  testId,
  onClose,
}: ActionMenuProps<T>): JSX.Element => {
  const [showMenu, setShowMenu] = React.useState<boolean>(true);
  const closeMenu = (): void => setShowMenu(false);

  const [confirmAction, setConfirmAction] = React.useState<ConfirmAction<T>>();

  const hideConfirmAction = (): void => {
    setConfirmAction(undefined);
    onClose?.();
  };

  const dismissMenu = (): void => {
    closeMenu();

    if (!confirmAction) {
      onClose?.();
    }
  };

  React.useEffect(() => {
    if (confirmAction) {
      confirmAction.onCancel?.();
      hideConfirmAction();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  React.useEffect(() => {
    const scrollListener = (): void => onClose?.();

    document.addEventListener(
      "scroll",
      scrollListener,
      // tells the browser to capture the event on dispatch,
      // even if that event does not normally bubble.
      // https://stackoverflow.com/questions/24270036/listening-to-all-scroll-events-on-a-page
      true,
    );

    return function cleanup() {
      document.removeEventListener("scroll", scrollListener, true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onActionClick = (action: ActionType<T>) => (): void => {
    confirmAction?.onCancel?.();
    setConfirmAction(undefined);

    (action as ConfirmAction<T> | CustomAction<T>).onClick?.(item);

    closeMenu();

    if (isConfirmAction(action)) {
      const newConfirmAction = (action as ConfirmAction<T>);
      setConfirmAction(newConfirmAction);
    } else {
      onClose?.();
    }
  };

  const onMenuDismiss = (ev?: Event | React.MouseEvent<Element> | React.KeyboardEvent<Element>): void => {
    const isPointerEvent = !!(ev as React.PointerEvent<HTMLElement>).pointerType;
    const eventTargetId = (ev?.target as HTMLElement).id;

    const isKeyboardEvent = !!(ev as React.KeyboardEvent).getModifierState;
    const isFocusEvent = ev?.type === "focus";

    const shouldIgnorePointerEvent = typeof anchor === "string" ? eventTargetId === anchor : ev === anchor;
    if (isPointerEvent && !shouldIgnorePointerEvent) {
      dismissMenu();
    } else if (isKeyboardEvent && (ev as React.KeyboardEvent<HTMLElement>).key === "Escape") {
      dismissMenu();
    } else if (isFocusEvent) {
      dismissMenu();
    }
  };

  return (
    <>
      {showMenu && (
        <ContextualMenu
          {...{ "data-test-id": testId }}
          target={typeof anchor === "string" ? `#${anchor}` : anchor}
          items={getActionItems(actions, item, onActionClick)}
          onDismiss={onMenuDismiss}
        />
      )}
      {confirmAction && (
        <ConfirmActionDialog
          item={item}
          targetId={confirmDialogProps?.anchorId}
          width={confirmDialogProps?.width}
          onConfirm={hideConfirmAction}
          onCancel={hideConfirmAction}
          confirmAction={confirmAction}
        />
      )}
    </>
  );
};
