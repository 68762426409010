/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import {
  CommandBarButton,
  IOverflowSetItemProps,
  ITextFieldStyles,
  mergeStyleSets,
  OverflowSet,
  ResizeGroup,
  TextField,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { FilterAll } from "../../constants/uiConstants";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { ListingColumn } from "../Listing/ListingTypes";
import { FilterOperationModal, IDropdownOptionType, IDropdownOptionWithType } from "./FilterOperationModal";
import { ExtraFilter, FilterComponent, FilterLayout, FilterOptions, Operator, searchFieldTestId } from "./FilterTypes";
import { filterDataByExtraFilter, filterDataByTextFilter } from "./FilterUtils";

export interface FiltersProps<T> extends FilterOptions {
  /**
   * Items data input list
   */
  items: T[];
  /**
   * Columns of data input list
   */
  columns: ListingColumn[];
  /**
   * CallBack function which is used to return the filtered data items
   */
  setFilteredItems: React.Dispatch<React.SetStateAction<T[]>>;
}

const classNames = mergeStyleSets({
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  dropDownLayoutFilterContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  filterInputContainer: { paddingRight: "10px" },
  dropdownLayoutFilterInputContainer: {
    paddingRight: "10px",
    paddingTop: "2px",
  },
  filterItemsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
  },
  dropdownLayoutFilterItemsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  filterItems: {
    borderColor: "#e6f2fb",
    backgroundColor: "#e6f2fb",
    margin: "5px",
    fontSize: "13px",
    borderRadius: "16px",
    boxSizing: "border-box",
    border: "1px solid",
    cursor: "pointer",
  },
  lessButtonContainer: {
    display: "flex",
    alignSelf: "end",
    marginLeft: "auto",
  },
});

const textFieldStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    width: 200,
    height: 24,
    color: "#323130",
    borderColor: "#8a8886",
  },
};

interface OverflowData {
  primary: IOverflowSetItemProps[];
  overflow: IOverflowSetItemProps[];
}

const addFilterActionButtonKey = "addFilterActionButton";

const onGrowData = (currentData: OverflowData): OverflowData | undefined => {
  if (currentData.overflow.length === 0) {
    return undefined;
  }

  let overflow: IOverflowSetItemProps[] = [];
  let primary: IOverflowSetItemProps[] = [];

  const addFilterPillIndexInPrimary = currentData.primary.findIndex(f => f.fieldKey === addFilterActionButtonKey);
  const addFilterPillIndexInOverflow = currentData.overflow.findIndex(f => f.fieldKey === addFilterActionButtonKey);

  if (addFilterPillIndexInPrimary > -1) {
    overflow = currentData.overflow.slice(1);
    primary = currentData.primary.splice(addFilterPillIndexInPrimary, 0, currentData.overflow[0]);
  } else if (addFilterPillIndexInOverflow > -1) {
    overflow = currentData.overflow.slice(addFilterPillIndexInOverflow, 1);
    primary = [...currentData.primary, currentData.overflow[addFilterPillIndexInOverflow]];
  } else {
    overflow = currentData.overflow.slice(1);
    primary = [...currentData.primary, ...currentData.overflow.slice(0, 1)];
  }
  return { primary, overflow };
};

const onReduceData = (currentData: OverflowData): OverflowData | undefined => {
  if (currentData.primary.length === 0) {
    return undefined;
  }

  let overflow: IOverflowSetItemProps[] = [];
  let primary: IOverflowSetItemProps[] = [];

  const addFilterPillIndexInPrimary = currentData.primary.findIndex(f => f.fieldKey === addFilterActionButtonKey);

  if (addFilterPillIndexInPrimary === 0) {
    overflow = [...currentData.overflow];
    primary = [...currentData.primary];
  } else if (addFilterPillIndexInPrimary > 0) {
    overflow = [
      ...currentData.primary.slice(addFilterPillIndexInPrimary - 1, addFilterPillIndexInPrimary),
      ...currentData.overflow,
    ];
    primary = [
      ...currentData.primary.slice(0, addFilterPillIndexInPrimary - 1),
      currentData.primary[addFilterPillIndexInPrimary],
    ];
  } else {
    overflow = [...currentData.primary.slice(-1), ...currentData.overflow];
    primary = currentData.primary.slice(0, -1);
  }
  return { primary, overflow };
};

interface ExpandCollapseButtonProps {
  label: string;
  iconName: string;
  onClick: () => void;
}

const expandButtonColor = "#0078d4";
const pillsGap = "8px";

const ExpandCollapseButton = ({ label, iconName, onClick }: ExpandCollapseButtonProps): JSX.Element => (
  <CommandBarButton
    iconProps={{ iconName }}
    onClick={onClick}
    styles={{
      root: { color: expandButtonColor },
      rootHovered: { color: expandButtonColor, backgroundColor: "inherit" },
      rootPressed: { color: expandButtonColor, backgroundColor: "inherit" },
      icon: { fontSize: "12px", color: expandButtonColor },
      iconHovered: { color: expandButtonColor },
      iconPressed: { color: expandButtonColor },
      label: { fontSize: "14px" },
    }}
  >
    {label}
  </CommandBarButton>
);

/**
 *
 * Filters is a component to show filter section above the list.
 * It provides a textfield search and select box searches based on a selected field
 */
// eslint-disable-next-line react/function-component-definition
export function Filters<T>({
  items,
  columns,
  setFilteredItems,
  defaultExtraFilters,
  allExtraFilters,
  hideTextFilter = false,
  hideAddFilterPill = false,
  filterLayout = FilterLayout.Pill,
  textFilterCallBack,
  componentRef,
  defaultFilterText,
  disableCollapse = false,
}: FiltersProps<T>): JSX.Element {
  const [filterText, setFilterText] = React.useState<string | undefined>(defaultFilterText || "");
  const [extraFilters, setExtraFilters] = React.useState<ExtraFilter[]>(defaultExtraFilters || []);
  const [itemsInList, setItemsInList] = React.useState(items);
  const thisRef = React.useRef<FilterComponent>();

  const [expand, setExpand] = React.useState<boolean>(!!disableCollapse);
  const [moreCount, setMoreCount] = React.useState<number>(0);
  const ref = React.useRef(null);
  const { width: containerWidth } = useResizeObserver(ref);

  const getPillKey = (ndx: number): string => `pill${ndx}`;
  const primaryDataConditionPills = extraFilters.map((extraFilter, ndx) => ({ ...extraFilter, key: getPillKey(ndx) }));

  const primaryDataAddFilterPill = {
    fieldKey: addFilterActionButtonKey,
    key: addFilterActionButtonKey,
  };

  const primaryData = hideAddFilterPill
    ? [...primaryDataConditionPills] : [...primaryDataConditionPills, primaryDataAddFilterPill];

  const dataToRender = { primary: primaryData, overflow: [] } as OverflowData;
  const ExpandedStyles: React.CSSProperties = {
    visibility: "hidden",
    position: "absolute",
    width: (hideTextFilter || containerWidth < 450)
      ? containerWidth : (containerWidth - 235),
  };
  const CollapsedStyles: React.CSSProperties = {
    width: (hideTextFilter || containerWidth < 450)
      ? containerWidth : (containerWidth - 235),
  };

  const onRenderData = (data: OverflowData): JSX.Element => (
    <OverflowSet
      items={data.primary}
      overflowItems={data.overflow.length ? data.overflow : undefined}
      onRenderItem={onRenderItem}
      onRenderOverflowButton={onRenderOverflowButton}
      styles={{ overflowButton: { marginLeft: "auto" } }}
    />
  );

  const onRenderItem = (overflowSetItem: IOverflowSetItemProps): JSX.Element => {
    if (overflowSetItem.fieldKey === addFilterActionButtonKey) {
      return getAddPillElement();
    }
    const extraFilterItem = extraFilters.find(f => f.fieldKey === overflowSetItem.fieldKey);
    const itemIndex = extraFilters.findIndex(f => f.fieldKey === overflowSetItem.fieldKey);

    return (
      <div style={{ marginRight: pillsGap }}>
        {getConditionPillElement(extraFilterItem, itemIndex)}
      </div>
    );
  };

  const onRenderOverflowButton = (): JSX.Element => (
    <ExpandCollapseButton
      label={Messages.common.more(moreCount.toString())}
      iconName="ChevronDownMed"
      onClick={() => setExpand(true)}
    />
  );

  const dataDidRender = (renderedData: OverflowData): void => {
    setMoreCount(renderedData.overflow.length);
    // Reset the expansion flag whenever the zooming out causes the items
    // to no longer overflow so that the next zooming in will show the items
    // in the collapsed state with the MORE link instead of showing them
    // in the expanded state with the LESS link.
    if (renderedData.overflow.length === 0) {
      setExpand(false);
    }
  };

  const getAddPillElement = (): JSX.Element => (
    <FilterOperationModal
      key="add-extra-filter"
      itemsInList={itemsInList}
      items={items}
      columns={columns}
      textFilterCallBack={textFilterCallBack}
      currentExtraFilters={extraFilters}
      currentFilterText={filterText}
      allExtraFilters={allExtraFilters}
      extraFiltersIgnored={extraFilters}
      filterLayout={filterLayout}
      onApply={(extraFilter: ExtraFilter) => {
        setExtraFilters([...extraFilters, ...[extraFilter]]);
      }}
    />
  );

  const getConditionPillElement = (extraFilterItem: ExtraFilter | undefined, itemIndex: number): JSX.Element => (
    <FilterOperationModal
      key={`extra-filter-${itemIndex}`}
      title={extraFilterItem?.name}
      itemsInList={itemsInList}
      items={items}
      defaultExtraFilter={extraFilterItem}
      allExtraFilters={allExtraFilters}
      columns={columns}
      textFilterCallBack={textFilterCallBack}
      currentExtraFilters={extraFilters}
      currentFilterText={filterText}
      extraFiltersIgnored={extraFilters}
      filterLayout={filterLayout}
      onApply={(extraFilter: ExtraFilter): void => {
        if (extraFilter) {
          const newExtraFilters = [...extraFilters];
          newExtraFilters[itemIndex] = extraFilter;
          setExtraFilters(newExtraFilters);
        }
      }}
      onDelete={(extraFilter: ExtraFilter) => {
        setExtraFilters(extraFilters.filter(item => !(
          item.name === extraFilter.name
          && item.operator === extraFilter.operator
          && item.value === extraFilter.value
          && item.fieldKey === extraFilter.fieldKey
        )));
      }}
    />
  );

  React.useEffect(() => {
    setItemsInList(items);
  }, [items]);

  React.useEffect(() => {
    if (defaultExtraFilters) {
      const tempArr = [...extraFilters];
      tempArr.forEach(t => {
        const item = defaultExtraFilters.find(f => f.fieldKey === t.fieldKey);
        if (item && item.customOptions) {
          t.customOptions = item?.customOptions;
          t.value = item.value;
          if (item.callBack) {
            t.callBack = item.callBack;
          }
        }
      });
      setExtraFilters(tempArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultExtraFilters]);

  React.useEffect(() => {
    if (allExtraFilters) {
      const tempArr = [...extraFilters];
      tempArr.forEach(t => {
        const item = allExtraFilters.find(f => f.fieldKey === t.fieldKey);
        if (item && item.customOptions) {
          t.customOptions = item?.customOptions;
        }
      });
      setExtraFilters(tempArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExtraFilters]);

  const itemsTagsOptions: IDropdownOptionWithType[] = [];
  items.forEach(item => {
    if ((item as any)?.freeformTags) {
      const tagKeys = Object.keys((item as any).freeformTags);
      tagKeys.forEach(tagKey => {
        const findKeyExist = itemsTagsOptions?.find(f => f.key.toString().toLowerCase() === tagKey.toLowerCase());
        if (!findKeyExist) {
          itemsTagsOptions.push({
            key: tagKey.toLowerCase(),
            text: tagKey,
            type: IDropdownOptionType.Tag,
          });
        }
      });
    }
  });
  itemsTagsOptions.forEach(o => {
    const tempFilter: ExtraFilter = {
      name: o.text.toString(),
      operator: Operator.Equal,
      value: [FilterAll],
      fieldKey: o.key.toString(),
      isTagFilter: true,
    };
    const findExist = allExtraFilters?.find(f => f.fieldKey === o.key);
    if (!findExist) {
      allExtraFilters?.push(tempFilter);
    }
  });

  React.useEffect(() => {
    if (componentRef) {
      thisRef.current = {
        getFilters: () => extraFilters.map(
          tempFilter => ({ fieldKey: tempFilter.fieldKey, value: tempFilter.value }),
        ),
        getFilterText: () => filterText,
      } as FilterComponent;
      componentRef(thisRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(extraFilters), filterText]);

  React.useEffect(() => {
    let tempItemsInList = items;
    if (filterText) {
      if (textFilterCallBack) {
        tempItemsInList = items.filter(item => (filterDataByTextFilter(textFilterCallBack(item), filterText, columns)));
      } else {
        tempItemsInList = items.filter(item => (filterDataByTextFilter(item, filterText, columns)));
      }
    }
    if (extraFilters) {
      extraFilters.forEach((extraFilter: ExtraFilter) => {
        if (!extraFilter?.callBack) {
          tempItemsInList = filterDataByExtraFilter(tempItemsInList, extraFilter);
        }
      });
    }
    setItemsInList(tempItemsInList);
    setFilteredItems(tempItemsInList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, extraFilters]);

  const onTextFilter = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    text?: string | undefined,
  ): void => {
    setFilterText(text);
  };

  return (
    <div
      className={filterLayout === FilterLayout.Pill
        ? classNames.filterContainer
        : classNames.dropDownLayoutFilterContainer}
      ref={ref}
    >
      {!hideTextFilter && (
        <div
          className={
            filterLayout === FilterLayout.Pill
              ? classNames.filterInputContainer
              : classNames.dropdownLayoutFilterInputContainer
          }
        >
          <TextField
            data-test-id={searchFieldTestId}
            onChange={onTextFilter}
            styles={textFieldStyles}
            placeholder={Messages.hints.filterAnyField()}
            value={filterText}
          />
        </div>
      )}
      {/*
        Always render ResizeGroup to take advantage of its overflow calculation.
        When the tags are expanded:
        - The visibilty of the ResizeGroup should be
        set to hidden so that it continues rendering and as a result continue
        recalculating the overflow.
        - The position is set to absolute so that it does not push down the expanded
        tags
      - Only display LESS link if there are overflow items */
      }
      {!disableCollapse && (
        <ResizeGroup
          data={dataToRender}
          onReduceData={onReduceData}
          onGrowData={onGrowData}
          onRenderData={onRenderData}
          dataDidRender={dataDidRender}
          style={expand ? ExpandedStyles : CollapsedStyles}
        />
      )}
      {expand && (
        <>
          <div
            className={
              filterLayout === FilterLayout.Pill
                ? classNames.filterItemsContainer
                : classNames.dropdownLayoutFilterItemsContainer
            }
          >
            {extraFilters.map((extraFilterItem, itemIndex) => getConditionPillElement(extraFilterItem, itemIndex))}
            {!hideAddFilterPill && getAddPillElement()}
          </div>
          {moreCount > 0 && !disableCollapse && (
            <div className={classNames.lessButtonContainer}>
              <ExpandCollapseButton
                label={Messages.common.less()}
                iconName="ChevronUpMed"
                onClick={() => setExpand(false)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
