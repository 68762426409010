import * as React from "react";
import { mergeStyleSets } from "@fluentui/react";

export interface OverviewLinkItem {
  /**
   * TestId of link item
   */
  testId?: string;

  /**
   * Text of link item
   */
  text: string;

  /**
   * URL of link item
   */
  url: string;
}

export interface OverviewLinkSection {
  /**
   * TestId of link section
   */
  testId?: string;

  /**
   * Title of link section
   */
  title?: string;

  /**
   * Link section items
   */
  items: OverviewLinkItem[];
}

const classNames = mergeStyleSets({
  overviewLinkSectionTitle: {
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "18px",
  },
  overviewLinkSectionItems: {
    listStyle: "none",
    display: "flex",
    paddingLeft: 0,
    paddingTop: 0,
    flexWrap: "wrap",
  },
  overviewLinkSectionItem: {
    marginLeft: "-12px",
    flex: "0 0 251px",
    paddingLeft: "12px",
    paddingTop: "1px",
    paddingBottom: "1px",
    a: {
      fontSize: "12px",
      color: "#0078D4",
      textDecoration: "none",
      ":hover": {
        color: "#004578",
        textDecoration: "underline",
      },
    },
  },
});

/**
 *
 * OverviewLinkSectionTemplate is a component to show the overview link section
 *
 */
export const OverviewLinkSectionTemplate = ({ title, items, testId }: OverviewLinkSection): JSX.Element => {
  const linkItems = items.map((item, i) => (
    <li className={classNames.overviewLinkSectionItem} key={i.toString()}>
      <a rel="noreferrer" target="_blank" href={item.url}>
        {item.text}
      </a>
    </li>
  ));
  return (
    <div data-test-id={testId}>
      {title && <div className={classNames.overviewLinkSectionTitle}>{title}</div>}
      <ul className={classNames.overviewLinkSectionItems}>{linkItems}</ul>
    </div>
  );
};
