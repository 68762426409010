export interface FileDetails {
  fileContent: string;
  fileName: string;
}

export enum ContentReaderDataType {
  /**
   * Plain text
   */
  Text = 0,
  /**
   * URL encoded data
   */
  EncodedData = 1,
}

export const readFile = (file: File, dataType: ContentReaderDataType): Promise<FileDetails> => {
  const reads: Promise<FileDetails>[] = [];
  const promise = new Promise<FileDetails>((resolve, reject) => {
    const fileReader = new FileReader();
    const onload = (e: Event): void => {
      resolve({
        fileName: file.name,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fileContent: (e.target as any).result,
      });
    };
    const onerror = (e: Event): void => {
      reject(e);
    };
    fileReader.addEventListener("load", onload);
    fileReader.addEventListener("error", onerror);
    switch (dataType) {
      case ContentReaderDataType.EncodedData:
        fileReader.readAsDataURL(file as Blob);
        break;

      case ContentReaderDataType.Text:
      default:
        fileReader.readAsText(file as Blob, "utf-8");
        break;
    }
  });
  reads.push(promise);
  return Promise.resolve(promise);
};
