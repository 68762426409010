import * as React from "react";
import {
  IIconProps,
  IIconStyles,
  IMessageBarStyles,
  IStackStyles,
  mergeStyleSets,
  MessageBar,
  registerIcons,
  Stack,
} from "@fluentui/react";
import { CircleMultiplySolid, Info, Warning } from "../Svg/icons";

export enum InfoBlockStatus {
  "INFO",
  "WARNING",
  "ERROR",
}
export enum InfoBlockLayout {
  "Stretched",
  "Compact",
}
export interface InfoBlockProps {
  /**
   * Message type for the Info block
   */
  messageType: InfoBlockStatus;
  /**
   * Message to be displayed on the Info Block
   */
  message: string | JSX.Element;
  /**
   * Layout type for the Info Block
   */
  infoLayout?: InfoBlockLayout;
  /**
   * Custom style option for the Info Block
   */
  customInfoStyle?: IStackStyles;

  /**
   * actions of infoBlock
   */
  actions?: JSX.Element | undefined;

  /**
   * isMultiline of infoBlock
   */
  isMultiline?: boolean | undefined
  /**
   * id used to get the InfoBlock component
   */
  testId?: string;
}

const stackBarStretched: IStackStyles = { root: { width: "100%" } };

const barBackgroundError: IMessageBarStyles = {
  root: {
    backgroundColor: "#fef0f1",
    borderColor: "#fef0f1",
  },
};
const barBackgroundWarning: IMessageBarStyles = {
  root: {
    backgroundColor: "#fff8f0",
    borderColor: "#fff8f0",
  },
};
const barBackgroundInfo: IMessageBarStyles = {
  root: {
    backgroundColor: "#f0f6ff",
    borderColor: "#f0f6ff",
  },
};
const iconStylesInfo: IIconStyles = { root: { color: "#015cda" } };

const iconStylesWarning: IIconStyles = { root: { color: "#db7500" } };

const iconStylesError: IIconStyles = { root: { color: "#a4262c" } };

registerIcons({
  icons: {
    "error-svg": CircleMultiplySolid,
    "warning-svg": Warning,
    "info-svg": Info,
  },
});

/**
 * The InfoBlock component render the message bar for the specified message type
 */

export const InfoBlock = ({
  message,
  messageType,
  customInfoStyle,
  actions,
  isMultiline,
  testId,
}: InfoBlockProps): JSX.Element => {
  let iconStyle;
  let iconBackground;
  let icon;

  if (messageType === InfoBlockStatus.INFO) {
    icon = "info-svg";
    iconBackground = barBackgroundInfo;
    iconStyle = iconStylesInfo;
  } else if (messageType === InfoBlockStatus.WARNING) {
    icon = "warning-svg";
    iconBackground = barBackgroundWarning;
    iconStyle = iconStylesWarning;
  } else {
    icon = "error-svg";
    iconBackground = barBackgroundError;
    iconStyle = iconStylesError;
  }

  const icons: IIconProps = {
    iconName: icon,
    styles: iconStyle,
    style: { width: "16px", height: "16px" },
  };

  const customBarStyle: IStackStyles = mergeStyleSets(stackBarStretched, customInfoStyle);

  return (
    <Stack
      horizontal
      styles={customInfoStyle ? customBarStyle : stackBarStretched}
    >
      <MessageBar
        data-test-id={testId}
        styles={iconBackground}
        messageBarIconProps={icons}
        actions={actions}
        isMultiline={isMultiline}
      >
        {message}
      </MessageBar>
    </Stack>
  );
};
