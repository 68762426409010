import * as React from "react";
import {
  ChoiceGroup,
  IChoiceGroupOption,
  IChoiceGroupOptionProps,
  IChoiceGroupOptionStyles,
  IChoiceGroupStyles,
  TooltipHost,
} from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import * as Messages from "../../codegen/Messages";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { useErrors } from "../../hooks/useErrors";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  InternalFormContext,
  InternalFormState,
} from "./FormInternalTypes";
import { ValidationState } from "./FormTypes";
import { InternalLabelWrapper } from "./InternalLabelWrapper";
import { buildLabelWrapperTestIds, LabelWrapperProps, LabelWrapperTestIds } from "./LabelWrapperTypes";

const AzureBlueIcon = "#0058ad";

export enum OptionOrientation {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}

export enum IconType {
  FONT = "font-icon",
  SVG = "svg",
}

export type IconStyles = {
  selectedBackgroundColor?: string;
  borderOnHover?: boolean;
};

export interface IconGroupOption {
  /**
   * Disables the given option from being selected
   * @default false
   */
  disabled?: boolean;
  /**
   * The name of the icon to be displayed
   * Icon must be registered
   */
  iconName: string;
  /**
   * Unique identifier for the radio group option
   */
  id: string;
  /**
   * Whether or not the option is selected
   */
  selected?: boolean;
  /**
   * The label for this option
   * The label will not be be visually displayed to the user
   * This is left for accessibility
   */
  text: string;
  /**
   * Text to be rendered in a tooltip for the radio group option
   */
  tooltip?: string;
  /**
   * ID used to get the element during testing
   */
  testId?: string;
}

export interface IconGroupProps extends Omit<BaseInputProps<string>, "validator">, Pick<LabelWrapperProps, "label"> {
  /**
   * Options to be listed for selection
   */
  options: IconGroupOption[];
  /**
   * The layout of the options (vertically or horizontally)
   */
  optionOrientation: OptionOrientation;
  /**
   * Specific styles that apply to each IconGroupOption
   */
  iconStyles?: IconStyles;
  /**
   * Type of icon being displayed
   */
  iconType?: IconType;
}

const baseIconStyles: IChoiceGroupOptionStyles = {
  root: { background: "none", margin: 0 },
  choiceFieldWrapper: { "is-inFocus": { "&:after": { border: "none !important" } } },
  innerField: { padding: 0 },
  field: {
    boxSizing: "border-box",
    borderColor: "transparent",
    borderRadius: "4px",
    borderWidth: "2px",
    "&:hover": { borderColor: "transparent" },
    // Hides the border of the radio button
    "&:before": { display: "none" },
    // Hides the filled in portion of the radio button when selected
    "&:after": { display: "none" },
  },
};

const fontIconStyles = mergeStyleSets(
  baseIconStyles,
  {
    root: { background: "none" },
    field: {
      ...(baseIconStyles.field as Record<string, unknown>),
      width: 33,
      height: 33,
      padding: 3,
    },
    // Hides the label text
    labelWrapper: { width: 0, height: 0, margin: 0, padding: 0 },
  },
);

const selectedFontIconStyles = mergeStyleSets(
  fontIconStyles,
  {
    field: {
      ...(fontIconStyles.field as unknown as Record<string, unknown>),
      backgroundColor: "#d7ecf9",
      borderColor: `${AzureBlueIcon} !important`,
      "&:hover": { borderColor: `${AzureBlueIcon} !important` },
    },
  },
);

const svgStyles = mergeStyleSets(
  baseIconStyles,
  {
    field: {
      ...(baseIconStyles.field as Record<string, unknown>),
      padding: "20px 8px 0",
      borderRadius: "4px",
      "&:hover": { borderColor: "#323130" },
    },
    labelWrapper: {
      maxWidth: "100%",
      width: "100%",
      margin: "0",
      marginTop: "56px",
      // Aligns label to the svg from the left
      "span.ms-ChoiceFieldLabel": { position: "absolute", left: "0" },
    },
  },
);

const selectedSvgStyles = mergeStyleSets(
  svgStyles,
  {
    field: {
      ...(svgStyles.field as unknown as Record<string, unknown>),
      borderColor: `${AzureBlueIcon} !important`,
      "&:hover": { borderColor: `${AzureBlueIcon} !important` },
    },
    labelWrapper: {
      ...(svgStyles.labelWrapper as unknown as Record<string, unknown>),
      fontWeight: 600,
    },
  },
);

const horizontalGroupStyles: IChoiceGroupStyles = mergeStyleSets(
  { width: "100%" },
  { flexContainer: { flexDirection: "row", flexWrap: "nowrap", gap: "13px" } },
);

const verticalGroupStyles: IChoiceGroupStyles = mergeStyleSets(
  { root: { width: "min-content" } },
  { flexContainer: { flexDirection: "column", gap: "13px" } },
);

const iconGroupStyles: { [key in OptionOrientation]: IChoiceGroupStyles } = {
  [OptionOrientation.HORIZONTAL]: horizontalGroupStyles,
  [OptionOrientation.VERTICAL]: verticalGroupStyles,
};

const iconStyle: React.CSSProperties = { fontSize: 26 };
const selectedIconStyle: React.CSSProperties = { ...iconStyle, color: AzureBlueIcon };

/**
 * A closure which allows the onRenderField function that is returned
 * now has access to the tooltip's option
 * @returns the on render function
 */
const onRenderFieldWithTooltip = (tooltip: string) => (
  props?: IChoiceGroupOptionProps,
  render?: (props?: IChoiceGroupOptionProps) => JSX.Element | null,
): JSX.Element | null => (
  <TooltipHost content={tooltip}>
    {render?.(props)}
  </TooltipHost>
);

export type IconGroupTestIds = ComponentTestIds & LabelWrapperTestIds;

export const buildIconGroupTestIds = (testId?: string): IconGroupTestIds => ({
  ...buildLabelWrapperTestIds(testId),
  component: buildTestId(testId),
});

/**
 * We do not expose a validator because the user may only select the given options
 */
export const IconGroup = ({
  testId,
  defaultValue,
  disabled,
  fieldName,
  groupName,
  label,
  onChange,
  optionOrientation,
  options,
  required,
  statusInfo,
  subField,
  tooltip,
  readOnly,
  iconStyles = { selectedBackgroundColor: "#d7ecf9", borderOnHover: false },
  iconType = IconType.FONT,
}: IconGroupProps): JSX.Element => {
  const { groupName: layoutGroup, layout } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const group = groupName || layoutGroup;
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("IconGroup should be used within form");
  }
  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);

  const [allErrors, setFieldErrors] = useErrors(fieldName, group);

  const [selectedId, setSelectedId] = React.useState(defaultValue);

  const iconGroupTestIds = buildIconGroupTestIds(testId);

  const fieldValidation = (): void => {
    const selectedValue = form.getValue<IconGroupOption>(fieldName, group);
    // Check that the value set in the form
    // Is one of the values provided in the options
    const value = options.find(option => option.id === selectedValue?.id && !option.disabled);

    // If a selection is required but a value has not been set
    const requiredMessage = (required && !selectedValue) && [Messages.validation.requiredValidation()];
    // If the selected value exists and does not match a given option
    const invalidMessage = (selectedValue && !value) && [Messages.validation.radioValidation()];

    const errors = [...requiredMessage || [], ...invalidMessage || []];

    form.setFieldState(
      errors && errors?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );

    setFieldErrors(errors);
  };

  const iconGroupOptions = options.map(option => ({
    key: option.id,
    text: option.text,
    iconProps: { iconName: option.iconName },
    disabled: disabled || option.disabled,
    onRenderField: option.tooltip
      ? onRenderFieldWithTooltip(option.tooltip)
      : undefined,
    "data-test-id": option.testId,
  } as IChoiceGroupOption));

  const extractInputValue = (formValue: IconGroupOption | undefined): string | undefined => formValue?.id;

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    if (required || defaultValue) {
      form.registerField(fieldName, group);
    }

    if (defaultValue) {
      form.setValue({ id: defaultValue }, fieldName, group);
      fieldValidation();
    } else if (required) {
      form.setFieldState(ValidationState.INVALID, fieldName, group);
    }

    return () => {
      if (required || defaultValue) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
    };
  // Only at mount time. Other props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[group] === FormValidationMode.ON) {
      if (required) {
        fieldValidation();
      }
    }
    // Only if validation mode changes. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  const internalOnChange = (
    _?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption,
  ): void => {
    const newSelection = {
      id: option?.key as string,
      text: option?.text as string,
    };

    setSelectedId(newSelection.id);
    form.setValue(newSelection, fieldName, group);

    if (required) {
      fieldValidation();
    }

    onChange?.(newSelection.id || "");
  };

  // Handles changing how the selected option is displayed visually
  const styledOptions = iconGroupOptions.map(option => {
    const iconBackgroundColor = iconStyles?.selectedBackgroundColor;
    const iconBorderOnHover = iconStyles?.borderOnHover
      ? { "&:hover": { borderColor: "#323130" } }
      : { "&:hover": { borderColor: "transparent" } };

    const stylesType = {
      regular: iconType === IconType.SVG ? svgStyles : fontIconStyles,
      selected: iconType === IconType.SVG ? selectedSvgStyles : selectedFontIconStyles,
    };

    const regularStyles = mergeStyleSets(
      stylesType.regular,
      {
        field: {
          ...(stylesType.regular.field as unknown as Record<string, unknown>),
          "&:hover": iconBorderOnHover["&:hover"],
        },
      },
    );

    const selectedStyles = mergeStyleSets(
      stylesType.selected,
      {
        field: {
          ...(stylesType.selected.field as unknown as Record<string, unknown>),
          backgroundColor: iconBackgroundColor,
        },
      },
    );

    if (option.key !== selectedId) {
      option.styles = regularStyles;
      option.iconProps = { ...option.iconProps, style: iconStyle };
    } else {
      option.styles = selectedStyles;
      option.iconProps = { ...option.iconProps, style: selectedIconStyle };
    }

    return option;
  });

  return (
    <InternalLabelWrapper
      // The Fluent ChoiceGroup component does not use an HTML select control
      // Therefore we cannot use an HTML label component according to semantic
      // HTML standards.
      noHtmlFor
      errors={allErrors}
      fieldName={fieldName}
      layout={layout}
      statusInfo={statusInfo}
      label={label}
      subField={subField}
      required={required}
      tooltip={tooltip}
      testId={testId}
    >
      <ChoiceGroup
        id={fieldName}
        readOnly={readOnly}
        tabIndex={readOnly ? -1 : undefined}
        data-test-id={iconGroupTestIds.component}
        options={styledOptions}
        defaultSelectedKey={defaultValue}
        onChange={internalOnChange}
        styles={iconGroupStyles[optionOrientation]}
      />
    </InternalLabelWrapper>
  );
};
