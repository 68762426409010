import * as React from "react";
import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupStyles } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { useErrors } from "../../hooks/useErrors";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  InternalFormContext,
  InternalFormState,
} from "./FormInternalTypes";
import { ValidationState } from "./FormTypes";
import { InternalLabelWrapper } from "./InternalLabelWrapper";
import { buildLabelWrapperTestIds, LabelWrapperProps, LabelWrapperTestIds } from "./LabelWrapperTypes";

// tslint:disable-next-line: interface-name
export interface RadioGroupOption {
  /**
   * Whether or not the option is selected
   */
  selected?: boolean;
  /**
   * Unique identifier for the radio group option
   */
  id: string;
  /**
   * Text to render for this option
   */
  text: string;
  /**
   * Disables the given option
   * @default false
   */
  disabled?: boolean
  /**
   * ID used to get the element during testing
   */
  testId?: string;
}

export interface RadioGroupProps extends Omit<BaseInputProps<string>, "validator">, Pick<LabelWrapperProps, "label"> {
  /**
   *Options to be listed for selection
   */
  options: RadioGroupOption[];
}

const radioGroupStyles: Partial<IChoiceGroupStyles> = {
  root: { width: "100%" },
  flexContainer: "horizontal",
};

export type RadioGroupTestIds = ComponentTestIds & LabelWrapperTestIds;

export const buildRadioGroupTestIds = (testId?: string): RadioGroupTestIds => ({
  ...buildLabelWrapperTestIds(testId),
  component: buildTestId(testId),
});

/**
 * RadioGroup component  lets the user select a single option from two or more choices.
 */
export const RadioGroup = ({
  testId,
  fieldName,
  groupName,
  label,
  defaultValue,
  disabled,
  options,
  tooltip,
  required,
  statusInfo,
  subField,
  onChange,
  ariaLabel,
  readOnly,
}: RadioGroupProps): JSX.Element => {
  const { groupName: layoutGroup, layout } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const group = groupName || layoutGroup;
  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("RadioGroup should be used within form");
  }

  const [allErrors, setFieldErrors] = useErrors(fieldName, group);

  const radioGroupTestIds = buildRadioGroupTestIds(testId);

  const radioGroupOptions = options.map(option => ({
    key: option.id,
    text: option.text,
    disabled: disabled || option.disabled,
    "data-test-id": option.testId,
  } as IChoiceGroupOption));

  const extractInputValue = (formValue: RadioGroupOption | undefined): string | undefined => formValue?.id;

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    if (required) {
      form.registerField(fieldName, group);
    }

    if (defaultValue) {
      const selectedOption = options?.find(option => option.id === defaultValue);
      form.setValue(selectedOption, fieldName, group);
      fieldValidation();
    } else if (required) {
      form.setFieldState(ValidationState.INVALID, fieldName, group);
    }

    return function cleanup() {
      if (required) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
    };
  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const internalOnChange = (
    _?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption,
  ): void => {
    const newSelection: RadioGroupOption = { id: option?.key as string, text: option?.text as string };
    form.setValue(newSelection, fieldName, group);

    if (required) {
      fieldValidation();
    }

    onChange?.(option?.key || "");
  };

  const fieldValidation = (): void => {
    const errors: string[] = [];

    if (required && !form.getValue<RadioGroupOption>(fieldName, group)) {
      errors.push(Messages.validation.requiredValidation());
    }

    form.setFieldState(
      errors && errors?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );

    setFieldErrors(errors);
  };

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[group] === FormValidationMode.ON) {
      if (required) {
        fieldValidation();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  return (
    <InternalLabelWrapper
      // The Fluent ChoiceGroup component does not use an HTML select control
      // Therefore we cannot use an HTML label component according to semantic
      // HTML standards.
      noHtmlFor
      errors={allErrors}
      fieldName={fieldName}
      layout={layout}
      label={label}
      required={required}
      tooltip={tooltip}
      subField={subField}
      statusInfo={statusInfo}
      testId={testId}
    >
      <ChoiceGroup
        readOnly={readOnly}
        id={fieldName}
        data-test-id={radioGroupTestIds.component}
        ariaLabelledBy={ariaLabel || label}
        options={radioGroupOptions}
        defaultSelectedKey={defaultValue}
        onChange={internalOnChange}
        tabIndex={readOnly ? -1 : undefined}
        styles={radioGroupStyles}
      />
    </InternalLabelWrapper>
  );
};
