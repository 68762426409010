import * as React from "react";
import { uniqueGUID } from "../../helpers/util";
import {
  FormErrorsContextProvider,
  FormGroupsValidationModes,
  FormGroupValidationModeContextProvider,
  FormStates,
  FormValidationMode,
  FormValidationModeContextProvider,
  InternalFormContextProvider,
  StatesUpdateCallback,
  ValueUpdateCallback,
} from "./FormInternalTypes";
import { FormStateInstance } from "./FormState";
import { FormErrors, FormFieldErrors, FormInterface, FormValues, ValidationState } from "./FormTypes";

interface ConsolidatedValidationModes {
  overall: FormValidationMode;
  groups: FormGroupsValidationModes;
}

export interface FormProps {
  componentRef?: (form: FormInterface) => void;
  children: React.ReactNode;
  groups?: string[];
  onStatesUpdate?: StatesUpdateCallback;
  onValueUpdate?: ValueUpdateCallback;
  onSubmit?: (formValues: FormValues) => void;
  validator?: (formValues: FormValues) => FormFieldErrors[] | undefined;
}

export const Form = (
  { componentRef, children, groups: registeredGroups, onStatesUpdate, onValueUpdate, onSubmit, validator }: FormProps,
): JSX.Element => {
  const internalOnStatesUpdate = (
    formStates: FormStates,
    fieldState: ValidationState,
    field: string,
    group?: string,
  ): void => {
    setOverallFormState(formStates.overall);
    if (onStatesUpdate) {
      onStatesUpdate(formStates, fieldState, field, group);
    }
  };

  const internalOnValueUpdate = (formValues: FormValues, fieldValue: unknown, field: string, group?: string): void => {
    setFormValuesChanged(uniqueGUID());
    if (onValueUpdate) {
      onValueUpdate(formValues, fieldValue, field, group);
    }
  };

  const processValidatorResult = (formErrors: FormFieldErrors[] | undefined): FormErrors => {
    formErrors?.forEach(formError => {
      formStateInstanceRef.current.setFieldErrors(formError.errors, formError.field, formError.group);
    });
    return formStateInstanceRef.current.getFormErrors();
  };

  const formStateInstanceRef = React.useRef<FormStateInstance>(
    new FormStateInstance(internalOnStatesUpdate, internalOnValueUpdate),
  );
  const [validationMode, setValidationMode] = React.useState<FormValidationMode>(FormValidationMode.OFF);
  const [groupsValidationModes, setGroupsValidationModes] = React.useState<FormGroupsValidationModes>(() => {
    const validationModes = {} as FormGroupsValidationModes;
    registeredGroups?.forEach(registeredGroup => { validationModes[registeredGroup] = FormValidationMode.OFF; });
    return validationModes;
  });

  // Sync the validation mode between the states and this ref.
  // The ref is needed for access by the component ref methods to avoid the JS closure accessing stalled state values
  const validationModesRef = React.useRef<ConsolidatedValidationModes>({} as ConsolidatedValidationModes);

  const [overallFormState, setOverallFormState] = React.useState<ValidationState>(ValidationState.UNKNOWN);
  const [formValuesChanged, setFormValuesChanged] = React.useState<string>();
  const [serializedFormErrors, setSerializedFormErrors] = React.useState<string>(JSON.stringify({}));

  const submit = (): void => {
    if (onSubmit) {
      const values = formStateInstanceRef.current.getValues();
      onSubmit(values);
    }
  };

  const enableValidation = (group?: string): void => {
    if (group) {
      setGroupsValidationModes(prevGroupsValidationModes => {
        const validationModes = { ...prevGroupsValidationModes };
        validationModes[group] = FormValidationMode.ON;
        validationModesRef.current.groups = validationModes;
        return validationModes;
      });
    } else {
      setValidationMode(FormValidationMode.ON);
      validationModesRef.current.overall = FormValidationMode.ON;
    }
  };

  const isValidationEnabled = (group?: string): boolean => (
    group
      ? validationModesRef.current.groups?.[group] === FormValidationMode.ON
      : validationModesRef.current.overall === FormValidationMode.ON
  );

  const formRef = React.useRef<FormInterface>({
    submit,
    enableValidation,
    isValidationEnabled,
    getFormState: () => formStateInstanceRef.current.getFormState(),
    hasValidation: (group?: string) => formStateInstanceRef.current.hasValidation(group),
    getFormValues: () => formStateInstanceRef.current.getValues(),
    getFormFieldLabelMap: () => formStateInstanceRef.current.getFieldLabelMap(),
    getFormFieldGroupMap: () => formStateInstanceRef.current.getFieldGroupMap(),
    extractFieldInputValue: (
      formValue: unknown,
      field: string,
    ) => formStateInstanceRef.current.extractFieldInputValue(formValue, field),
  });

  React.useEffect(() => {
    if (componentRef) {
      componentRef(formRef.current);
    }
  }, [componentRef]);

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON && validator && overallFormState !== ValidationState.UNKNOWN) {
      const values = formStateInstanceRef.current.getValues();
      const formErrors = processValidatorResult(validator(values));
      setSerializedFormErrors(JSON.stringify(formErrors));
    }
  // Only if validation mode and overall form state/values change.
  // Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, overallFormState, formValuesChanged]);

  return (
    <InternalFormContextProvider value={formStateInstanceRef.current}>
      <FormValidationModeContextProvider value={validationMode}>
        <FormGroupValidationModeContextProvider value={groupsValidationModes}>
          <FormErrorsContextProvider value={JSON.parse(serializedFormErrors)}>
            {children}
          </FormErrorsContextProvider>
        </FormGroupValidationModeContextProvider>
      </FormValidationModeContextProvider>
    </InternalFormContextProvider>
  );
};
