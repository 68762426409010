import * as React from "react";
import { AppContext, AppState } from "../Context/AppContext";
import { registerBreadcrumbLabel, useNavigation } from "../Navigation/Navigation";
import { recentAccessManager } from "../RecentAccess/RecentAccessManager";
import { RecentAccessItem } from "../RecentAccess/RecentAccessTypes";
import { BookmarkablePageContext, BookmarkablePageContextValue } from "./InternalBookmarkablePage";

export type RecentAccessRegistration = Omit<RecentAccessItem, "lastAccessed">;
export interface BookmarkablePageProps<T extends AppState> {
  appContext: AppContext<T>;
  /**
   * Registration ids for the BookmarkablePage
   */
  registrationIds?: string[];
  /**
   * Title for the BookmarkablePage
   */
  title: string;
  /**
   * Title suffix for the BookmarkablePage
   */
  titleSuffix?: string;
  /**
   * Registration information for tracking last access
   */
  recentAccessRegistration?: RecentAccessRegistration;
  /**
   * Components to be rendered as part of the BookmarkablePage
   */
  children: React.ReactNode;
}

const bookmarkablePageContextValue: BookmarkablePageContextValue = { withBreadcrumbs: true };
/**
 * The BookmarkablePage component renders the components specified by the caller along
 * with the breadcrumbs
 */
// eslint-disable-next-line react/function-component-definition
export function BookmarkablePage<T extends AppState>(
  { appContext, registrationIds, title, titleSuffix, recentAccessRegistration, children }: BookmarkablePageProps<T>,
): JSX.Element {
  const { setPageDetails } = useNavigation(appContext);
  React.useEffect(() => {
    document.title = titleSuffix ? `${title} - ${titleSuffix}` : title;
    setPageDetails(title, titleSuffix || "");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, titleSuffix]);

  React.useEffect(() => {
    if (recentAccessRegistration && recentAccessRegistration.id) {
      recentAccessManager.addItem({ ...recentAccessRegistration, lastAccessed: Date.now() });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentAccessRegistration]);

  registrationIds?.forEach(id => {
    registerBreadcrumbLabel(id, title);
  });

  return (
    <BookmarkablePageContext.Provider value={bookmarkablePageContextValue}>
      {children}
    </BookmarkablePageContext.Provider>
  );
}
