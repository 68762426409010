import { IDropdownOption } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { FilterAll } from "../../constants/uiConstants";
import { ListingColumn } from "../Listing/ListingTypes";
import { ExtraFilter, Operator } from "./FilterTypes";

export const emptyKeyStr = "-";

export const filterDataByTextFilter = (item: any, text: string, listColumns: ListingColumn[]): boolean => {
  let criteriaResult = false;
  for (let i = 0; i < listColumns.length; i++) {
    const objValue = item[listColumns[i].itemProp];
    if (objValue?.toString().toLowerCase().indexOf(text?.toLowerCase().trim()) > -1) {
      criteriaResult = true;
      break;
    }
  }
  return criteriaResult;
};

export const filterDataByExtraFilter = (itemInList: any[], extraFilter: ExtraFilter): any[] => {
  let returnItems = itemInList;

  const getFieldOptionsByName = (fieldName: string): IDropdownOption<any>[] => ([
    ...new Map(returnItems.map(v => [JSON.stringify(v[fieldName]), v])).values(),
  ].map(item => ({
    key: item[fieldName]?.toString(),
    text: item[fieldName]?.toString(),
  } as IDropdownOption)));

  const tempOptions: IDropdownOption<any>[] = getFieldOptionsByName(extraFilter.fieldKey);

  if (Array.isArray(extraFilter.value)) {
    let extraFilterSearchValueArr: string[] = [];
    extraFilterSearchValueArr = extraFilter.value?.map(ef => (
      tempOptions?.find(
        v => (v.key === ef),
      )?.text?.toString().toLowerCase() || ""
    ));

    if (extraFilter.customOptions) {
      // search key for customOptions
      extraFilterSearchValueArr = extraFilter.value?.map(ef => (
        extraFilter.customOptions?.find(
          v => (v.key === ef),
        )?.key?.toString().toLowerCase() || ""
      ));
    }

    if (extraFilter.isTagFilter) {
      const tempTagOptions = getTagFieldValueOptionsByKey(itemInList, extraFilter.fieldKey);
      extraFilterSearchValueArr = extraFilter.value?.map(ef => (
        tempTagOptions?.find(
          v => (v.key === ef),
        )?.key?.toString() || ""
      ));
    }

    if (Operator.Equal === extraFilter.operator) {
      if (!(extraFilter.value.includes(FilterAll))) {
        returnItems = itemInList.filter(
          tempItem => {
            let compareResult = false;
            extraFilterSearchValueArr.forEach(efr => {
              let tempCompareResult = false;
              if (!extraFilter.isTagFilter) {
                tempCompareResult = (tempItem[extraFilter.fieldKey]?.toString().toLowerCase() || "")
                  .localeCompare(efr, undefined, { sensitivity: "base" }) === 0;
              } else if (extraFilter.isTagFilter && tempItem && tempItem.freeformTags) {
                const caseSensitiveKey = Object.keys(tempItem.freeformTags).find(
                  t => t.toLowerCase() === extraFilter.fieldKey.toLowerCase(),
                ) || extraFilter.fieldKey;
                if (tempItem.freeformTags[caseSensitiveKey]) {
                  tempCompareResult = (tempItem.freeformTags[caseSensitiveKey].toString() || "")
                    .localeCompare(efr, undefined, { sensitivity: "case" }) === 0;
                } else if (tempItem.freeformTags[caseSensitiveKey]?.trim() === "" && efr.toString().trim() === "") {
                  tempCompareResult = true;
                }
              }
              if (tempCompareResult) {
                compareResult = true;
              }
            });
            return compareResult;
          },
        );
      } else if (extraFilter.value.includes(FilterAll) && extraFilter.isTagFilter) {
        returnItems = itemInList.filter(
          tempItem => {
            let compareResult = false;
            const caseSensitiveKey = Object.keys(tempItem?.freeformTags || {}).find(
              t => t.toLowerCase() === extraFilter.fieldKey.toLowerCase(),
            ) || extraFilter.fieldKey;
            const tempTagsKeys = Object.keys(tempItem?.freeformTags || {});
            if (tempTagsKeys && tempTagsKeys.includes(caseSensitiveKey)) {
              compareResult = true;
            }
            return compareResult;
          },
        );
      }
    } else if (Operator.Contains === extraFilter.operator) {
      if (!(extraFilter.value.includes(FilterAll))) {
        returnItems = itemInList.filter(
          tempItem => {
            let compareResult = false;
            extraFilterSearchValueArr.forEach(efr => {
              let tempCompareResult = false;
              if (!extraFilter.isTagFilter) {
                tempCompareResult = (
                  tempItem[extraFilter.fieldKey]?.toString().toLowerCase() || ""
                ).indexOf(efr) > -1;
              } else if (extraFilter.isTagFilter && tempItem && tempItem.freeformTags) {
                const caseSensitiveKey = Object.keys(tempItem.freeformTags).find(
                  t => t.toLowerCase() === extraFilter.fieldKey.toLowerCase(),
                ) || extraFilter.fieldKey;
                if (tempItem.freeformTags[caseSensitiveKey]) {
                  tempCompareResult = (tempItem.freeformTags[caseSensitiveKey].toString() || "")
                    .indexOf(efr) > -1;
                } else if (tempItem.freeformTags[caseSensitiveKey]?.trim() === "" && efr.toString().trim() === "") {
                  tempCompareResult = true;
                }
              }
              if (tempCompareResult) {
                compareResult = true;
              }
            });
            return compareResult;
          },
        );
      } else if (extraFilter.value.includes(FilterAll) && extraFilter.isTagFilter) {
        returnItems = itemInList.filter(
          tempItem => {
            let compareResult = false;
            const caseSensitiveKey = Object.keys(tempItem?.freeformTags || {}).find(
              t => t.toLowerCase() === extraFilter.fieldKey.toLowerCase(),
            ) || extraFilter.fieldKey;
            const tempTagsKeys = Object.keys(tempItem?.freeformTags || {});
            if (tempTagsKeys && tempTagsKeys.includes(caseSensitiveKey)) {
              compareResult = true;
            }
            return compareResult;
          },
        );
      }
    } else if (Operator.Custom === extraFilter.operator) {
      returnItems = itemInList.filter(
        tempItem => {
          let compareResult = false;
          extraFilterSearchValueArr.forEach(efr => {
            if (extraFilter.customFilterOperation) {
              const tempCompareResult = extraFilter.customFilterOperation(tempItem, efr);
              if (tempCompareResult) {
                compareResult = true;
              }
            }
          });
          return compareResult;
        },
      );
    }
  } else {
    let extraFilterSearchValue = tempOptions?.find(
      v => (v.key === extraFilter.value),
    )?.text?.toString().toLowerCase();

    if (extraFilter.customOptions) {
      // search key for customOptions
      extraFilterSearchValue = extraFilter.customOptions.find(
        c => c.key === extraFilter.value,
      )?.key?.toString().toLowerCase();
    }

    if (Operator.Equal === extraFilter.operator && extraFilter.value !== FilterAll) {
      returnItems = itemInList.filter(
        tempItem => {
          const compareResult = (tempItem[extraFilter.fieldKey]?.toString().toLowerCase() || "")
            .localeCompare(extraFilterSearchValue, undefined, { sensitivity: "base" }) === 0;
          return compareResult;
        },
      );
    } else if (Operator.Contains === extraFilter.operator && extraFilter.value !== FilterAll) {
      returnItems = itemInList.filter(
        tempItem => (tempItem[extraFilter.fieldKey]?.toString().toLowerCase() || "")
          .indexOf(extraFilterSearchValue) > -1,
      );
    } else if (Operator.Custom === extraFilter.operator) {
      returnItems = itemInList.filter(
        (element: any) => {
          if (extraFilter.customFilterOperation) {
            const customFilterResult = extraFilter.customFilterOperation(element, extraFilter.value);
            return customFilterResult;
          }
          return true;
        },
      );
    }
  }
  return returnItems;
};

export const deepCopy = (source: any): any => (
  Array.isArray(source)
    ? source.map(item => deepCopy(item))
    : source instanceof Date
      ? new Date(source.getTime())
      : source && typeof source === "object"
        ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
          Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
          o[prop] = deepCopy((source as { [key: string]: any })[prop]);
          return o;
        }, Object.create(Object.getPrototypeOf(source)))
        : source as any);

export const getFieldOptionsByName = (
  listItems: any[],
  fieldName: string,
  messageCallBack: ((value: string) => string) | undefined,
): IDropdownOption<any>[] => ([
  ...new Map(listItems.map(v => [JSON.stringify(v[fieldName]), v])).values(),
].map(item => ({
  key: (item[fieldName] || "").toString(),
  text: messageCallBack ? messageCallBack((item[fieldName] || "").toString()) : (item[fieldName] || "").toString(),
} as IDropdownOption)));

export const getTagFieldValueOptionsByKey = (
  listItems: any[],
  fieldKey: string,
): IDropdownOption<any>[] => {
  const valueOptions: IDropdownOption<any>[] = [];
  listItems.forEach(i => {
    let isEmptyValue = false;
    if (i && i.freeformTags && i.freeformTags[
      Object.keys(i.freeformTags).find(k => k.toLowerCase() === fieldKey.toLowerCase()) || fieldKey
    ]?.trim() === "") {
      isEmptyValue = true;
    }
    const tempTag = i && i.freeformTags ? i.freeformTags[
      Object.keys(i.freeformTags).find(k => k.toLowerCase() === fieldKey.toLowerCase()) || fieldKey
    ] : undefined;
    if (isEmptyValue) {
      if (!valueOptions.find(o => o.key === "")) {
        valueOptions.push({
          key: "",
          text: emptyKeyStr,
        } as IDropdownOption);
      }
    } else if (tempTag && !valueOptions.find(o => o.key === tempTag)) {
      valueOptions.push({
        key: tempTag.toString(),
        text: tempTag.toString(),
      } as IDropdownOption);
    }
  });
  return valueOptions;
};

export const getEditModeSequenceValueOptions = (
  options: IDropdownOption<any>[],
  currentExtraFilter: ExtraFilter | undefined,
  items: any[],
  currentExtraFilters: ExtraFilter[] | undefined,
  currentFilterText: string,
  textFilterCallBack: ((item: any) => any) | undefined,
  columns: ListingColumn[],
): IDropdownOption<any>[] => {
  let sequenceValueOptions = options;
  const previousExtraFilters: ExtraFilter[] = [];
  let tempItemsInList = items;

  if (currentExtraFilters) {
    for (let i = 0; i < currentExtraFilters.length; i++) {
      const ef = currentExtraFilters[i];
      if (ef.fieldKey === currentExtraFilter?.fieldKey) {
        break;
      } else {
        previousExtraFilters.push(ef);
      }
    }
  }
  if (currentExtraFilter) {
    if (currentFilterText) {
      if (textFilterCallBack) {
        tempItemsInList = items.filter(
          item => (filterDataByTextFilter(textFilterCallBack(item), currentFilterText, columns || [])),
        );
      } else {
        tempItemsInList = items.filter(item => (filterDataByTextFilter(item, currentFilterText, columns || [])));
      }
    }
    if (previousExtraFilters && currentExtraFilter?.fieldKey) {
      previousExtraFilters.forEach((ef: ExtraFilter) => {
        if (!ef.callBack) {
          tempItemsInList = filterDataByExtraFilter(tempItemsInList, ef);
        }
      });
    }
    let tempFieldOptions = getFieldOptionsByName(
      tempItemsInList,
      currentExtraFilter.fieldKey,
      currentExtraFilter.messageCallBack,
    );
    if (currentExtraFilter.isTagFilter) {
      tempFieldOptions = getTagFieldValueOptionsByKey(
        tempItemsInList,
        currentExtraFilter.fieldKey,
      );
    }
    sequenceValueOptions = tempItemsInList && tempItemsInList.length > 0
      ? tempFieldOptions && tempFieldOptions.length > 0
        ? tempFieldOptions
        : options
      : options;
  }
  return sequenceValueOptions;
};

export const getAddModeSequenceValueOptions = (
  filterName: IDropdownOption<any> | undefined,
  options: IDropdownOption<any>[],
  items: any[],
  currentExtraFilters: ExtraFilter[] | undefined,
  currentFilterText: string,
  textFilterCallBack: ((item: any) => any) | undefined,
  columns: ListingColumn[],
  allExtraFilters: ExtraFilter[] | undefined,
): IDropdownOption<any>[] => {
  let sequenceValueOptions = options;
  let tempItemsInList = items;
  const currentExtraFilter = allExtraFilters?.find(f => f.fieldKey === filterName?.key);

  if (currentExtraFilter) {
    if (currentFilterText) {
      if (textFilterCallBack) {
        tempItemsInList = items.filter(
          item => (filterDataByTextFilter(textFilterCallBack(item), currentFilterText, columns || [])),
        );
      } else {
        tempItemsInList = items.filter(item => (filterDataByTextFilter(item, currentFilterText, columns || [])));
      }
    }
    if (currentExtraFilters) {
      currentExtraFilters.forEach((ef: ExtraFilter) => {
        if (!ef.callBack) {
          tempItemsInList = filterDataByExtraFilter(tempItemsInList, ef);
        }
      });
    }
    let tempFieldOptions = getFieldOptionsByName(
      tempItemsInList,
      currentExtraFilter.fieldKey,
      currentExtraFilter.messageCallBack,
    );
    if (currentExtraFilter.isTagFilter) {
      tempFieldOptions = getTagFieldValueOptionsByKey(
        tempItemsInList,
        currentExtraFilter.fieldKey,
      );
    }
    const tempFieldOptionsUpdated = [{ key: FilterAll, text: Messages.common.all() } as IDropdownOption]
      .concat(tempFieldOptions)
      .map(o => {
        if (o.key === FilterAll) {
          return o;
        }
        if (o.key === "") {
          o.text = `${emptyKeyStr} (${getAddModeOptionCount(
            o,
            items,
            currentExtraFilters || [],
            currentExtraFilter.fieldKey,
            currentFilterText || "",
            columns,
            textFilterCallBack,
            allExtraFilters,
          )})`;
        } else {
          o.text = `${o.text} (${getAddModeOptionCount(
            o,
            items,
            currentExtraFilters || [],
            currentExtraFilter.fieldKey,
            currentFilterText || "",
            columns,
            textFilterCallBack,
            allExtraFilters,
          )})`;
        }
        return o;
      });
    sequenceValueOptions = tempItemsInList && tempItemsInList.length > 0
      ? tempFieldOptionsUpdated
      : options;
  }
  return sequenceValueOptions;
};

export const getAddModeOptionCount = (
  selectorItem: IDropdownOption | undefined,
  dataItems: any[],
  currentExtraFilters: ExtraFilter[],
  currentExtraFilterKey: string,
  currentFilterText: string,
  dataColumns: ListingColumn[],
  textFilterCallBack: ((item: any) => any) | undefined,
  allExtraFilters: ExtraFilter[] | undefined,
): number => {
  let count = 0;
  let tempItemsInList = dataItems;
  let tempCurrentExtraFilters: ExtraFilter[] = deepCopy(currentExtraFilters) || [];
  tempCurrentExtraFilters = tempCurrentExtraFilters.concat(
    allExtraFilters?.find(f => f.fieldKey === currentExtraFilterKey) || [],
  );
  if (tempCurrentExtraFilters && currentExtraFilterKey) {
    const currentPill = tempCurrentExtraFilters.find(f => f.fieldKey === currentExtraFilterKey);
    if (currentPill) {
      currentPill.value = [selectorItem && selectorItem.key ? selectorItem.key.toString() : ""];
    }
  }

  if (currentFilterText) {
    if (textFilterCallBack) {
      tempItemsInList = dataItems.filter(
        item => (filterDataByTextFilter(textFilterCallBack(item), currentFilterText, dataColumns)),
      );
    } else {
      tempItemsInList = dataItems.filter(item => (filterDataByTextFilter(item, currentFilterText, dataColumns)));
    }
  }
  if (tempCurrentExtraFilters && currentExtraFilterKey) {
    tempCurrentExtraFilters.forEach((ef: ExtraFilter) => {
      if (!ef.callBack) {
        tempItemsInList = filterDataByExtraFilter(tempItemsInList, ef);
      }
    });
  }
  if (tempItemsInList) {
    count = tempItemsInList.length;
  }
  return count;
};

export const getEditModeOptionCount = (
  selectorItem: IDropdownOption | undefined,
  dataItems: any[],
  currentExtraFilters: ExtraFilter[],
  currentExtraFilterKey: string,
  currentFilterText: string,
  dataColumns: ListingColumn[],
  textFilterCallBack: ((item: any) => any) | undefined,
): number => {
  let count = 0;
  let tempItemsInList = dataItems;
  const tempCurrentExtraFilters = deepCopy(currentExtraFilters);
  if (tempCurrentExtraFilters && currentExtraFilterKey) {
    const currentPill = tempCurrentExtraFilters.find(f => f.fieldKey === currentExtraFilterKey);
    if (currentPill) {
      currentPill.value = [selectorItem && selectorItem.key ? selectorItem.key.toString() : ""];
    }
  }

  if (currentFilterText) {
    if (textFilterCallBack) {
      tempItemsInList = dataItems.filter(
        item => (filterDataByTextFilter(textFilterCallBack(item), currentFilterText, dataColumns)),
      );
    } else {
      tempItemsInList = dataItems.filter(item => (filterDataByTextFilter(item, currentFilterText, dataColumns)));
    }
  }
  if (tempCurrentExtraFilters && currentExtraFilterKey) {
    tempCurrentExtraFilters.forEach((ef: ExtraFilter) => {
      if (!ef.callBack) {
        tempItemsInList = filterDataByExtraFilter(tempItemsInList, ef);
      }
    });
  }
  if (tempItemsInList) {
    count = tempItemsInList.length;
  }
  return count;
};
