import * as React from "react";
import { toDurationStr } from "../../helpers/dateTimeUtils";
import { NoValue } from "../NoValue/NoValue";

export interface DurationDisplayProps {
  durationMs: number;
}

/**
 * The DurationDisplay component can be used to display the time
 * duration in DD:HHH:MM:SS:MS format
 */
export const DurationDisplay = ({ durationMs }: DurationDisplayProps): JSX.Element => {
  if (durationMs === 0) return <NoValue />;

  const value = toDurationStr(durationMs);
  return <span title={value}>{value}</span>;
};
