import * as React from "react";
import { Breadcrumb, IBreadcrumbItem, IBreadcrumbStyles } from "@fluentui/react";
import { BookmarkStyle } from "../../constants/uiConstants";
import { CustomOverflowButton } from "../InternalComponents/CustomOverflowButton";
import { useBreadcrumbs } from "./Navigation";

/**
 * This component is used for rendering breadcrumbs.
 * It uses the data returned by useBreadcrumbs.
 * @returns JSX
 */
export const Breadcrumbs = (): JSX.Element => {
  const { backTo, breadcrumbs, backToPipe } = useBreadcrumbs();

  let lastKey = "";

  const items: IBreadcrumbItem[] = breadcrumbs.map(entry => {
    lastKey = entry.key;
    const bItem = entry.label !== undefined
      ? {
        text: entry.label,
        key: entry.key,
        onClick,
      } : undefined;
    return bItem as IBreadcrumbItem;
  }).filter(item => item !== undefined);

  const styles: Partial<IBreadcrumbStyles> = {
    root: {
      margin: 0,
      fontSize: "14px",
    },
    list: {
      height: BookmarkStyle.height,
      paddingTop: BookmarkStyle.paddingTop,
      paddingLeft: 17,
    },
    listItem: [{
      lineHeight: "normal",
      fontSize: "14px",
      height: BookmarkStyle.height,
    },
    ],
    itemLink: {
      lineHeight: "normal",
      fontSize: "14px",
      height: BookmarkStyle.height,
      color: "#0078d4",
      fontWeight: "inherit",
      "&:focus": { color: "#0078d4" },
      "&:hover": {
        color: "#004578 !important",
        textDecoration: "underline",
        backgroundColor: "unset",
      },
    },
  };

  function onClick(ev?: React.MouseEvent<HTMLSpanElement>, item?: IBreadcrumbItem): void {
    if (item && item.key) {
      let index = parseInt(item.key.split("$")[1], 10);
      if (item.key.split("#")[1]) {
        index = parseInt(item.key.split("#")[1], 10);
        backToPipe(index);
      } else {
        backTo(index);
      }
    }
  }

  function onRenderItem(
    props?: IBreadcrumbItem,
    defaultRender?: (props?: IBreadcrumbItem) => JSX.Element | null,
  ): JSX.Element | null {
    if (lastKey === props?.key) {
      return <div />;
    }
    return defaultRender ? defaultRender(props) : null;
  }

  return (
    <Breadcrumb
      items={items}
      focusZoneProps={{ isCircularNavigation: true }}
      styles={styles}
      // eslint-disable-next-line react/jsx-no-bind
      onRenderItem={onRenderItem}
      overflowButtonAs={CustomOverflowButton}
    />
  );
};
