import * as React from "react";
import { IconButton, IStackStyles, Stack, Text } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { ColorTextPrimary } from "../../constants/uiConstants";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { metaItemSectionLayoutManager } from "../../models/LayoutManager";
import { MetaItemGroupsLayout } from "./MetaItemGroupsLayout";
import { MetaItemGroupLayoutType, MetaItemGroupsLayoutProps } from "./MetaItemTypes";

const OPEN_ICON = "ChevronDown";
const CLOSE_ICON = "ChevronUp";

export interface MetaItemSectionProps extends MetaItemGroupsLayoutProps {
  /**
   * Title for the MetaItemGroup
   * @default Essentials
   */
  title?: string;
}

const metaInfoStackStyle: IStackStyles = {
  root: {
    gridGap: "10px",
    margin: "10px 0px",
    borderBottom: "1px solid rgb(214, 214, 214)",
    paddingBottom: "10px",
  },
};

/**
 * MetaItemSection renders the component(s) group by item sections
 */

export const MetaItemSection = ({
  title = Messages.labels.essentials(),
  ...rest
}: MetaItemSectionProps): JSX.Element => {
  const ref = React.useRef(null);
  const { width: metaItemSectionWidth } = useResizeObserver(ref);
  const [layoutType, setLayoutType] = React.useState<MetaItemGroupLayoutType>();
  const [hideSection, { toggle: toggleSection }] = useBoolean(false);

  React.useLayoutEffect(() => {
    const metaItemSectionlayoutType = metaItemSectionLayoutManager.getLayoutType(metaItemSectionWidth);
    setLayoutType(metaItemSectionlayoutType);
    // console.log(`>>> ${metaItemSectionWidth} -> ${metaItemSectionlayoutType}`);
  }, [metaItemSectionWidth]);

  return (
    <div ref={ref}>
      <Stack styles={metaInfoStackStyle}>
        <Stack horizontal horizontalAlign="space-between">
          <IconButton
            styles={{
              root: { width: "100%" },
              flexContainer: { justifyContent: "inherit" },
            }}
            iconProps={{
              iconName: hideSection ? OPEN_ICON : CLOSE_ICON,
              styles: { root: { color: "#000000", fontSize: 12, fontWeight: 600 } },
            }}
            onClick={toggleSection}
          >
            <Text variant="medium" style={{ color: ColorTextPrimary, fontWeight: 600 }}>{title}</Text>
          </IconButton>
        </Stack>
        {!hideSection && <MetaItemGroupsLayout layoutType={layoutType} {...rest} />}
      </Stack>
    </div>
  );
};
