export const infinityFailCallback = (error: any): void =>
  console.debug(`Failed to track analytics with Infinity ${error}`);

/**
 * Adds prefix string (e.g. "oci.") to the front of the object keys,
 * resulting in ["{prefixKey}.key"] = value to improve filtering in Infinity console
 * @param data the data that should have its keys prepended
 */
export const addPrefixKey = (data: Record<string, any>, prefixKey: string): Record<string, any> => {
  if (prefixKey === undefined || prefixKey.length === 0) {
    return data;
  }

  const prefixedData: any = {};
  Object.keys(data).forEach(key => {
    prefixedData[`${prefixKey}${key}`] = data[key];
  });
  return prefixedData;
};

export const addInfinityScript = (
  id: string,
  scriptUrl: string,
  errorCallback?: () => void,
): void => {
  if (!document.getElementById(id)) {
    const scriptTag = document.createElement("script");
    scriptTag.async = true;
    scriptTag.type = "text/javascript";
    scriptTag.src = scriptUrl;
    scriptTag.id = id;
    if (errorCallback) {
      scriptTag.onerror = errorCallback;
    }
    document.head.appendChild(scriptTag);
  }
};
