import * as React from "react";
import { Icon, ILabelStyles, ILinkStyles, IStackStyles, mergeStyleSets, registerIcons, Stack } from "@fluentui/react";
import { InfoBlock, InfoBlockLayout, InfoBlockProps } from "../InfoBlock/InfoBlock";
import { CircleMultiplySolid } from "../Svg/icons";
import { FormInputGroupLayout } from "./FormInputGroup";

export const wideLayoutLabelWidth = 245;

export const stackItemStyles: IStackStyles = { root: { paddingBottom: "15px" } };

export const baseLabelStyles: ILabelStyles = {
  root: {
    display: "initial",
    minHeight: "17px",
    marginTop: "3px",
    marginBottom: "4px",
    padding: 0,
    fontWeight: "400",
    fontSize: "13px",
  },
};

export const labelStyles: ILabelStyles = mergeStyleSets(
  baseLabelStyles,
  {
    maxWidth: "200px",
    width: "100%",
    minWidth: "200px",
  },
);

export const labelStylesSubField = mergeStyleSets(
  baseLabelStyles,
  { root: { minWidth: "202px", paddingLeft: "5px" } },
);

const baseSubFieldBorder = {
  display: "block",
  borderLeft: "1px solid #a9a9a9",
  borderBottom: "1px solid #a9a9a9",
  marginLeft: "4px",
};

export const wideSubFieldBorder = mergeStyleSets(
  baseSubFieldBorder,
  {
    width: "45px",
    height: "25px",
    marginTop: "-15px",
  },
);

export const compactSubFieldBorder = mergeStyleSets(
  baseSubFieldBorder,
  {
    width: "40px",
    height: "45px",
    marginTop: "-3px",
  },
);

export const labelStylesWithToolTip: ILabelStyles = baseLabelStyles;

export const labelStylesSubFieldWithTooltip = mergeStyleSets(
  labelStylesWithToolTip,
  labelStylesSubField,
);

export const stackItemStylesWithToolTip: IStackStyles = { root: { minWidth: "250px" } };

export const linkStylesWide: ILinkStyles = {
  root: {
    paddingBottom: "10px",
    paddingLeft: "250px",
  },
};

export const linkStylesCompact: ILinkStyles = {
  root: {
    paddingBottom: "10px",
    maxWidth: "fit-content",
  },
};

const errorMessageStylesWide = {
  maxHeight: "30px",
  paddingLeft: "250px",
};

const errorMessageStylesCompact = { maxHeight: "30px" };

const infoBlockCompactStyles: IStackStyles = {
  root: {
    maxWidth: "calc(100% - 250px)",
    marginLeft: "250px",
    marginBottom: "15px",
  },
};

const infoBlockStretchedStyles: IStackStyles = { root: { marginBottom: "15px" } };

registerIcons({ icons: { "error-svg": CircleMultiplySolid } });

export const renderStatusBar = (
  statusInfo: InfoBlockProps,
  layout: FormInputGroupLayout,
): JSX.Element => (
  <InfoBlock
    {...statusInfo}
    customInfoStyle={
      layout === FormInputGroupLayout.WIDE
        ? statusInfo.infoLayout === InfoBlockLayout.Compact
          ? infoBlockCompactStyles
          : infoBlockStretchedStyles
        : infoBlockStretchedStyles
    }
  />
);

export const renderErrorMessages = (
  errorMessages: string[],
  layout: FormInputGroupLayout,
  testId?: string,
): JSX.Element => {
  const errorStyles = layout === FormInputGroupLayout.COMPACT
    ? errorMessageStylesCompact
    : errorMessageStylesWide;

  const errors = errorMessages.map((error, index) => (
    // error may not be unique, so combine with its position to ensure uniqueness
    // eslint-disable-next-line react/no-array-index-key
    <Stack horizontal key={`${error}-${index}`} data-test-id={testId}>
      <span style={errorStyles}>
        <Icon iconName="error-svg" />
      </span>
      <span
        style={{
          display: "block",
          marginLeft: "10px",
          fontSize: "13px",
          color: "#a4262c",
        }}
      >
        {error}
      </span>
    </Stack>
  ));
  return (
    <Stack style={{ paddingBottom: "15px", marginTop: "-2px" }}>{errors}</Stack>
  );
};
