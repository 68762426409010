import * as React from "react";
import {
  DatePicker,
  IDatePicker,
  IDatePickerStrings,
  IDatePickerStyles,
  IStackTokens,
  ITextFieldStyles,
  mergeStyleSets,
  Stack,
  TextField,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { formatLocalizedTime } from "../../helpers/dateTimeUtils";
import { buildTestId } from "../../helpers/testIdHelper";
import { uniqueGUID } from "../../helpers/util";
import { useErrors } from "../../hooks/useErrors";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayout, FormInputGroupLayoutContext } from "./FormInputGroup";
import { FormValidationModeContext, InternalFormContext, InternalFormState } from "./FormInternalTypes";
import { ValidationState } from "./FormTypes";
import { LabelWrapper } from "./LabelWrapper";
import { buildLabelWrapperTestIds, LabelWrapperProps, LabelWrapperTestIds } from "./LabelWrapperTypes";

export interface DateTimePickerProps extends
  Omit<BaseInputProps<Date, never, number>, "onChange">,
  Pick<LabelWrapperProps, "label"> {
  /**
  * Locale for the Date and time picker Field
  */
  locale: string;
  /**
   * The minimum allowable date.
   */
  minDate?: Date;
  /**
   * The maximum allowable date.
   */
  maxDate?: Date;
}

const set24HrsTimeFormat = (str: string): Date => {
  str = String(str).toLowerCase().replace(/\s/g, "");
  const hasAM = str.indexOf("am") >= 0;
  const hasPM = str.indexOf("pm") >= 0;
  // first strip off the am/pm, leave it either hour or hour:minute
  str = str.replace("am", "").replace("pm", "");
  // if hour, convert to hour:00
  if (str.indexOf(":") < 0) str += ":00";
  // now it's hour:minute
  // we add am/pm back if striped out before
  if (hasAM) str += " am";
  if (hasPM) str += " pm";
  // now its either hour:minute, or hour:minute am/pm
  // put it in a date object, it will convert to 24 hours format for us
  const d = new Date(`1/1/2011 ${str}`);
  return d;
};

const enum DateFormatOptions {
  Long = "long",
  Short = "short",
  Narrow = "narrow",
}

const generateDefaultTime = (): Date => {
  const date = new Date();
  date.setHours(24, 0, 0, 0);
  return date;
};

const getMonthNames = (locale: string, format: DateFormatOptions): string[] => {
  const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: "UTC" });
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => {
    const mm = month < 10 ? `0${month}` : `${month}`;
    return new Date(`2017-${mm}-01T00:00:00+00:00`);
  });
  return months.map(date => formatter.format(date));
};
const getDayNames = (locale: string, format: DateFormatOptions): string[] => {
  const curr = new Date();
  curr.setHours(24, 0, 0, 0);
  const days:Date[] = [];
  for (let i = 0; i < 7; i++) {
    const first = curr.getDate() - curr.getDay() + i;
    const day = new Date(curr.setDate(first));
    days.push(day);
  }
  return days.map(day => day.toLocaleDateString(locale, { weekday: format }));
};

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    width: "100%",
    height: "32px",
    marginTop: "5px",
  },
};
const calendarStyles: Partial<IDatePickerStyles> = {

  root: {
    width: "100%",
    height: "24px",
    marginTop: "5px",
  },
  wrapper: { height: "24px" },
  // textField: { height: "24px !important", fieldGroup: { height: "24px !important" } },
  readOnlyTextField: { maxHeight: "24px !important" },
  // callout: { minWidth: "218px", height: "260px", left: "500px" },

};
const calenderWideStyles = mergeStyleSets(calendarStyles, { root: { minWidth: "218px", height: "24px" } });
const textFieldWideStyles = mergeStyleSets(textFieldStyles, { root: { minWidth: "218px" } });
const stackTokens: Partial<IStackTokens> = { childrenGap: 5 };
const timeFormatOptions: Intl.DateTimeFormatOptions = { hour: "2-digit", minute: "2-digit", second: "2-digit" };

/**
 * This does not extend ComponentTestIds as there is not one 'core' component but multiple
 */
export type DateTimePickerTestIds = { date: string; time: string; } & LabelWrapperTestIds;

export const buildDateTimePickerTestIds = (testId?: string): DateTimePickerTestIds => ({
  ...buildLabelWrapperTestIds(testId),
  date: buildTestId(testId, "-date"),
  time: buildTestId(testId, "-time"),
});
/**
 * The Date and Time picker component provides an option to  select a date and time
 */
export const DateTimePicker = ({
  testId,
  fieldName,
  groupName,
  label,
  locale,
  disabled,
  defaultValue,
  required,
  placeholder,
  tooltip,
  statusInfo,
  subField,
  validator,
  minDate,
  maxDate,
}: DateTimePickerProps): JSX.Element => {
  const { groupName: layoutGroup, layout } = React.useContext(
    FormInputGroupLayoutContext,
  );

  const group = groupName || layoutGroup;
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("Date and time picker should be used within form");
  }

  const datePickerRef = React.useRef<IDatePicker>(null);
  const [dateValue, setDateValue] = React.useState<Date>(defaultValue || new Date());
  const [selectedTime, setSelectedTime] = React.useState<string>();
  const [timeValue, setTimeValue] = React.useState<Date>(generateDefaultTime());
  const validationMode = React.useContext(FormValidationModeContext);
  const [timeFormatMessage, setTimeFormatMessage] = React.useState<string[]>();
  const [key, setKey] = React.useState<string>(uniqueGUID());

  const localizedDatePickerStrings: IDatePickerStrings = {
    days: getDayNames(locale, DateFormatOptions.Long),
    goToToday: "",
    months: getMonthNames(locale, DateFormatOptions.Long),
    shortDays: getDayNames(locale, DateFormatOptions.Narrow),
    shortMonths: getMonthNames(locale, DateFormatOptions.Short),
    prevMonthAriaLabel: Messages.ariaLabel.previousMonth(),
    nextMonthAriaLabel: Messages.ariaLabel.nextMonth(),
  };

  const onFormatDate = (date? : Date): string => (
    !date ? "" : date.toLocaleDateString(locale)
  );

  const [allErrors, setFieldErrors] = useErrors(fieldName, group);

  const dateTimePickerTestIds = buildDateTimePickerTestIds(testId);

  const extractInputValue = (formValue: number): number => formValue;

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    // always register the field since the time picker has an internal time validation
    form.registerField(fieldName, group);

    return function cleanup() {
      form.unRegisterField(fieldName, group);
      form.deleteField(fieldName, group);
    };
    // Only at mount time. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldValidation = () : void => {
    const value = form.getValue<number>(fieldName, group) as number;
    const errorMsg = validator ? validator(value) : undefined;
    const invalidTime = timeFormatMessage || undefined;
    const minMaxValidation = validateWithMinMaxDate(timeValue) || undefined;
    const errors = [...invalidTime || [], ...errorMsg || [], ...minMaxValidation || []];
    form.setFieldState(
      errors && errors?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );
    setFieldErrors(errors);
  };

  React.useEffect(() => {
    form.setValue(new Date(
      dateValue.getFullYear(),
      dateValue.getMonth(),
      dateValue.getDate(),
      timeValue.getHours(),
      timeValue.getMinutes(),
      timeValue.getSeconds(),
    // Do not change to Date as this takes into account any changes to timezone
    ).getTime(), fieldName, group);

    fieldValidation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateValue, timeValue]);

  const internalDateOnChange = (
    date: Date | null | undefined,
  ): void => {
    if (date !== undefined && date !== null) {
      setDateValue(date);
    }
  };

  const internalTimeOnChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ): void => {
    if (newValue !== undefined) {
      if (newValue !== "") {
        setSelectedTime(newValue);
      } else {
        setSelectedTime(`${generateDefaultTime().getHours()}`);
      }
    }
  };

  const validateWithMinMaxDate = (time: Date): string[] | undefined => {
    const errors: string[] = [];
    const evaluatedDateValue = new Date(
      dateValue.getFullYear(),
      dateValue.getMonth(),
      dateValue.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds(),
    ).getTime();

    // Eliminate msecs from min and max dates
    const minDateValue = minDate?.getTime() ? Math.floor(minDate.getTime() / 1000) * 1000 : undefined;
    const maxDateValue = maxDate?.getTime() ? Math.floor(maxDate.getTime() / 1000) * 1000 : undefined;

    if (minDate && !maxDate) {
      if (minDateValue && evaluatedDateValue < minDateValue) {
        errors.push(
          Messages.validation.validationMinDate(
            minDate.toLocaleDateString(locale),
            formatLocalizedTime(locale, minDate, timeFormatOptions),
          ),
        );
      }
    }
    if (!minDate && maxDate) {
      if (maxDateValue && evaluatedDateValue > maxDateValue) {
        errors.push(
          Messages.validation.validationMaxDate(
            maxDate.toLocaleDateString(locale),
            formatLocalizedTime(locale, maxDate, timeFormatOptions),
          ),
        );
      }
    }
    if (minDate && maxDate) {
      if (minDateValue && maxDateValue && (evaluatedDateValue < minDateValue || evaluatedDateValue > maxDateValue)) {
        errors.push(
          Messages.validation.validationMinMaxDateRange(
            minDate.toLocaleDateString(locale),
            formatLocalizedTime(locale, minDate, timeFormatOptions),
            maxDate.toLocaleDateString(locale),
            formatLocalizedTime(locale, maxDate, timeFormatOptions),
          ),
        );
      }
    }
    return errors.length > 0 ? errors : undefined;
  };

  const validateSelectedTime = (): void => {
    if (selectedTime !== undefined) {
      const formattedSelectedTime = set24HrsTimeFormat(selectedTime);
      if ((selectedTime !== "" && Number.isNaN(formattedSelectedTime.getHours()))) {
        setTimeFormatMessage(
          Number.isNaN(formattedSelectedTime.getHours()) ? [Messages.validation.timePickerValidation()] : [],
        );
        setTimeValue(
          Number.isNaN(formattedSelectedTime.getHours()) ? generateDefaultTime()
            : formattedSelectedTime,
        );
      } else if (!Number.isNaN(formattedSelectedTime.getHours())) {
        setTimeFormatMessage(undefined);
        setTimeValue(formattedSelectedTime);
        setKey(uniqueGUID());
      }
    }
  };

  React.useEffect(() => {
    fieldValidation();
    // Only if validation mode changes. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode]);

  return (
    <LabelWrapper
      errors={allErrors}
      fieldName={fieldName}
      layout={layout}
      label={label}
      required={required}
      statusInfo={statusInfo}
      subField={subField}
      tooltip={tooltip}
      testId={testId}
    >
      <Stack horizontal tokens={stackTokens}>
        <DatePicker
          data-test-id={dateTimePickerTestIds.date}
          componentRef={datePickerRef}
          ariaLabel={Messages.ariaLabel.datePicker()}
          disabled={disabled}
          placeholder={placeholder}
          value={new Date(dateValue)}
          formatDate={onFormatDate}
          onSelectDate={internalDateOnChange}
          isMonthPickerVisible={false}
          showGoToToday={false}
          strings={localizedDatePickerStrings}
          minDate={minDate}
          maxDate={maxDate}
          styles={
          layout === FormInputGroupLayout.COMPACT
            ? calendarStyles
            : calenderWideStyles
        }
        />
        <TextField
          data-test-id={dateTimePickerTestIds.time}
          onBlur={validateSelectedTime}
          ariaLabel={Messages.ariaLabel.timePicker()}
          key={key}
          disabled={disabled}
          placeholder={placeholder}
          defaultValue={formatLocalizedTime(locale, timeValue, timeFormatOptions)}
          validateOnFocusOut
          onChange={internalTimeOnChange}
          styles={
          layout === FormInputGroupLayout.COMPACT
            ? textFieldStyles
            : textFieldWideStyles
        }
        />
      </Stack>
    </LabelWrapper>
  );
};
