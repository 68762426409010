import * as React from "react";
import { IStackTokens, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { uniqueGUID } from "../../helpers/util";
import { useErrors } from "../../hooks/useErrors";
import { BaseInputProps } from "./BaseInput";
import { FieldWrapperProps } from "./FieldWrapper";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  ExtractInputValueType,
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  getConsolidatedState,
  InternalFormContext,
  InternalFormContextProvider,
} from "./FormInternalTypes";
import { FormErrors, FormValues, ValidationState } from "./FormTypes";
import { Errors } from "./SimpleBuilderError";
import { Header } from "./SimpleBuilderHeader";
import { getInitialRowConfig, parseFieldName, TemplateInput, ValueUpdateCallback } from "./SimpleBuilderInternalTypes";
import { Label } from "./SimpleBuilderLabel";
import { Row } from "./SimpleBuilderRow";
import { SimpleBuilderState } from "./SimpleBuilderState";
import { buildSimpleBuilderTestIds, SimpleBuilderComponentRef } from "./SimpleBuilderTypes";

// **********************************************************************************************
// Preface
// **********************************************************************************************

// This is the SimpleBuilder, its purpose is to dynamically render multiple rows of given inputs.
// It should behave like one input within the Form, but behave like a Form to the inputs it renders.
//
// About row rendering.
// All default values will be rendered as a row. If there is space an empty row will be added. This
// empty row will persist until the maximum rows have been reached.
//
// About the children.
// The children that are passed may contain other elements aside from inputs. The inputs are expected
// to conform to a specific interface (See TemplateInputProps interface for more details)

// **********************************************************************************************
// Definitions
// **********************************************************************************************

// Within this file's comments there are certain terms that need context.
//
// Template row refers to a rendered copy of the children passed to the SimpleBuilder, whose
// footprint is ignored in the SimpleBuilder state, it is the end row that is in an untouched state.
// Template input will refer to the individual component(s) passed as children to the SimpleBuilder.

const columnStackTokens: IStackTokens = { childrenGap: 8 };

export interface SimpleBuilderProps
  extends Pick<
  BaseInputProps<FormValues[] | undefined>,
  "defaultValue" | "fieldName" | "onChange" | "testId" | "validator" | "groupName"
  >,
  Pick<FieldWrapperProps, "label" | "required" | "tooltip"> {
  /**
   * A template array used to generate a dynamic number of rows. Typically a mix of inputs.
   */
  children: TemplateInput | TemplateInput[];
  /**
   * The maximum number of rows. Row addition will be disabled unless you have less rows than this.
   * Must be a positive integer.Must be greater than or equal to minRows.
   */
  maxRows?: number;
  /**
   * The minimum number of rows to be required. Must be a positive integer. Must be less than or equal to maxRows.
   */
  minRows?: number;
  /**
   * Callback to validate a row
   * @param allRowValues an array of objects with the field names as the keys, and their value as the values
   * @param rowNdx is the index of the array entry representing the row data
   * @returns the errors to be displayed for a given field
   */
  rowValidator?: (allRowValues: FormValues[], rowNdx: number) => FormErrors | undefined;
  /**
   * Callback when a value was changed within a row.
   * @param rowValues The current values of the row
   * @returns The values to be replaced. If no changes are desired for an input, do not include its field name
   */
  rowModifier?: (rowValues: FormValues) => FormValues | undefined;
  /**
   * Controls whether a new row will be added when the last empty field is touched
   * @default false
   */
  disableAutoRowAddition?: boolean;
  /**
   * Method to obtain the reference to methods exposing imperative functionality of the SimpleBuilder
   */
  componentRef?: (ref?: SimpleBuilderComponentRef) => void;
}

export const SimpleBuilder = ({
  children,
  fieldName,
  defaultValue,
  label,
  maxRows,
  minRows,
  required,
  testId,
  tooltip,
  onChange,
  rowModifier,
  rowValidator,
  validator,
  disableAutoRowAddition = false,
  componentRef,
  groupName: group,
}: SimpleBuilderProps): JSX.Element => {
  if (maxRows !== undefined && maxRows < 1) {
    throw new Error("maxRows must be a positive number");
  }
  if (minRows !== undefined && minRows < 1) {
    throw new Error("minRows must be a positive number");
  }
  if (maxRows && minRows && maxRows < minRows) {
    throw new Error("maxRows should be greater than or equal to minRows.");
  }

  const form = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("SimpleBuilder must be used within a Form");
  }

  const internalOnStatesUpdate = (overallState: ValidationState): void => {
    // If the SimpleBuilder is unmounted then we do not want to notify form/consumer
    // of any effects caused by the children unmounting
    if (!isMounted.current) return;

    form.setFieldState(
      getConsolidatedState([overallState, validationState.current]),
      fieldName,
      groupName,
    );
  };

  const internalOnValueUpdate: ValueUpdateCallback = (
    values: FormValues[],
    changedFieldValue: unknown | undefined,
    changedFieldName: string | undefined,
    rowId: number,
  ): void => {
    const watchedFieldNames = Array.from(fieldNameSet.values());

    // If the SimpleBuilder is unmounted then we do not want to notify form/consumer
    // of any effects caused by the internal children unmounting
    if (!isMounted.current) return;

    const changedFieldIsReadOnly = Boolean(changedFieldName && !watchedFieldNames.includes(changedFieldName));
    // No need to notify form if computed/readonly field is changed
    if (changedFieldIsReadOnly) return;

    const currentRowValues = simpleBuilder.getRowValues(rowId);
    const newRowValues = rowModifier?.(currentRowValues);
    processNewRowValues(rowId, newRowValues);

    // We need to defer the notification of the values changing as we want all synchronous changes
    // to have completed before letting the consumer know (also don't put intermediary values in the Form)
    setTriggerDeferredOnChange(true);

    // Do not validate a deleted row. Otherwise the row states will be polluted.
    const rowWasDeleted = changedFieldValue === undefined && changedFieldName === undefined;
    if (rowWasDeleted) return;

    // If we defer validation by 1 render then the validation states will be in sync with the existence
    // of the rowIds. By validating asynchronously we can handle multiple rows/inputs being affected
    // synchronously or asynchronously. If we need to validate synchronously we should ensure that the rowIds
    // is a consistent reference as this function's definition is only valid on first render.
    // Therefore all rows can be validated without worrying that the validation state will be polluted
    setTriggerDeferredValidation(true);
  };

  const transformData = (values: FormValues[]): FormValues[] | undefined => {
    const transformedValues: FormValues[] = [];
    const orderedFieldNames: string[] = Array.from(fieldNameSet.values());

    values.forEach(rowValues => {
      // Ensure the transformed FormValues are in the order that the inputs were passed in
      const orderedRowEntries: [string, unknown][] = orderedFieldNames.map(field => ([field, rowValues[field]]));

      const orderedRowValues: FormValues = orderedRowEntries.reduce((acc, [field, value]) => {
        // eslint-disable-next-line eqeqeq
        if (value != undefined && value !== "" && value !== false) {
          acc[field] = value;
        }
        return acc;
      }, {} as FormValues);

      // If the row has values
      if (Object.keys(orderedRowValues).length) {
        transformedValues.push(orderedRowValues);
      }
    });

    return transformedValues.length ? transformedValues : undefined;
  };

  const {
    numberOfRows: initialNumberOfRows,
    // This should only be used to remove the last row validation state entry, NOT the values.
    // The values should be transformed as there are more conditions in deducing if a value should be passed to the Form
    hasTemplateRow: initialHasTemplateRow,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = React.useMemo(() => getInitialRowConfig(defaultValue, disableAutoRowAddition, maxRows), []);

  const templateRow = React.useMemo(
    () => (Array.isArray(children) ? children : [children]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Used to set the order of the row's FormValues in the same order that the inputs are passed to the SimpleBuilder
  const fieldNameSet: Set<string> = React.useMemo(() => (
    new Set<string>(
      templateRow
        // filter out non-input elements
        .filter(child => !!child.props.fieldName)
        // read-only elements should not be passed onto the Form
        .filter(child => !child.props.readOnly)
        .map(child => child.props.fieldName) as string[],
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const simpleBuilder = React.useMemo(() => (
    new SimpleBuilderState(
      initialHasTemplateRow,
      internalOnStatesUpdate,
      internalOnValueUpdate,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const [triggerDeferredOnChange, setTriggerDeferredOnChange] = React.useState<boolean>(false);
  const [triggerDeferredValidation, setTriggerDeferredValidation] = React.useState<boolean>(false);

  const validationState = React.useRef<ValidationState>(ValidationState.UNKNOWN);

  // Methods to extract the values from the given field
  const fieldExtractInputValueMap = React.useMemo<Map<string, ExtractInputValueType | undefined>>(
    () => {
      const fieldNames = Array.from(fieldNameSet.values());
      const extractInputValueEntries: [string, ExtractInputValueType | undefined][] = fieldNames.map(
        name => ([name, undefined] as [string, undefined]),
      );
      return new Map<string, ExtractInputValueType | undefined>(extractInputValueEntries);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);
  const { groupName: layoutGroup } = React.useContext(FormInputGroupLayoutContext);
  const groupName = group || layoutGroup;

  const simpleBuilderTestIds = buildSimpleBuilderTestIds(testId);

  const [simpleBuilderErrors, setSimpleBuilderErrors] = useErrors(fieldName, groupName);
  const [rowErrors, setRowErrors] = React.useState<Record<number, string[] | undefined>>({});
  // In React 17, the cleanup of effects runs top to bottom (parents first, children last) the opposite of
  // of the order the effects themselves are run.
  // In React 18, the cleanup effects runs bottom to top (children first, parents last) in the same order
  // the effects themselves are run
  // The reason we need this is to stop any data from dirtying the Form after the SimpleBuilder has unmounted,
  // since the SimpleBuilder's childrens' cleanup(s) will run after the SimpleBuilder has cleaned up. (see usage)
  // If we upgrade to 18+ we will no longer need this flag.
  const isMounted = React.useRef<boolean>(true);

  // Unique ID represents each row
  const [rowIds, setRowIds] = React.useState<number[]>([...Array(initialNumberOfRows).keys()]);

  // Holds the ID for the next row to be rendered. To add a new row, append the current nextRowId
  // to the rowIds and then setNextRowId to a new value for later use. Always increment to generate unique IDs
  const [nextRowId, setNextRowId] = React.useState<number>(initialNumberOfRows);

  // Mechanism to control when inputs within the rows should be remounted.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const managedKeys: Map<number, Record<string, string>> = React.useMemo(() => new Map(), []);

  const setManagedKey = (rowId: number, field: string): void => {
    const currentKeys = managedKeys.get(rowId) ?? {};
    const updatedKeys = { ...currentKeys, [field]: uniqueGUID() };
    managedKeys.set(rowId, updatedKeys);
  };

  // Mechanism to manage the values of 'controlled' components. Since our inputs are actually uncontrolled
  // we need to make use of the defaultValue prop and the key prop to force the inputs to remount.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const managedValues: Map<number, FormValues> = React.useMemo(() => new Map(), []);

  const setManagedValue = (rowId: number, field: string, value: unknown): void => {
    const currentValues = managedValues.get(rowId) ?? {};
    const updatedValues = { ...currentValues, [field]: value };
    managedValues.set(rowId, updatedValues);
  };

  /**
   * imperative call to add a row
   * the auto row addition is located in the onRowChange
   */
  const addRow = (): void => {
    // In order to avoid stale state we must use the callback method of setting state
    // otherwise we would have to maintain a ref in sync with the state
    setNextRowId(currentNextRowId => {
      setRowIds(prevRowIds => {
        const numberOfRows = prevRowIds.length;
        const hasMoreThanMaxRows = maxRows && numberOfRows >= maxRows;

        if (hasMoreThanMaxRows) return prevRowIds;

        // Otherwise we need to replace the template row
        return [...prevRowIds, currentNextRowId];
      });
      return currentNextRowId + 1;
    });

    if (rowValidator) {
      simpleBuilder.registerRow(nextRowId);
    }
  };

  const processNewRowValues = (currentRowId: number, newRowValues: FormValues | undefined): void => {
    if (!newRowValues) return;

    const managedRowValues: [string, unknown][] = Object.entries(newRowValues);

    managedRowValues.forEach(([field, value]) => {
      setManagedKey(currentRowId, field);
      setManagedValue(currentRowId, field, value);
    });
  };

  const onRowChange = (currentRowIndex: number): void => {
    const numberOfRows = rowIds.length;
    const lastRowIndex = numberOfRows - 1;
    const isLastRow = currentRowIndex === lastRowIndex;

    if (disableAutoRowAddition || !isLastRow) return;

    const hasMoreThanMaxRows = maxRows && numberOfRows >= maxRows;

    if (hasMoreThanMaxRows) {
      // We do not want to display a template row when the maximum rows have been reached
      simpleBuilder.hasTemplateRow = false;
    } else {
      // Otherwise we need to replace the template row
      simpleBuilder.hasTemplateRow = true;

      setRowIds([...rowIds, nextRowId]);
      setNextRowId(nextRowId + 1);

      if (rowValidator) {
        simpleBuilder.registerRow(nextRowId);
      }
    }
  };

  // A part of the mechanism to determine if the initial values of the entire Form have changed
  // Uses the corresponding mechanism for each Input to obtain the respective values
  const extractInputValue = (rawData: FormValues[] | undefined): FormValues[] | undefined => {
    const extractedData: FormValues[] = [];

    rawData?.forEach(row => {
      const rawRowData = Object.entries(row);

      const extractedRowData = rawRowData.reduce((rowData, [inputFieldName, rawInputValue]) => {
        const extractInternalInputValue = fieldExtractInputValueMap.get(inputFieldName);

        const extractedInputValue = extractInternalInputValue?.(rawInputValue);

        if (extractedInputValue !== undefined) {
          rowData[inputFieldName] = extractedInputValue;
        }

        return rowData;
      }, {} as FormValues);

      // A row may be empty but not deleted, therefore the row data should not be added
      if (Object.keys(extractedRowData).length) {
        extractedData.push(extractedRowData);
      }
    });

    return extractedData.length ? extractedData : undefined;
  };

  React.useEffect(() => {
    if (rowValidator) {
      rowIds.forEach(id => simpleBuilder.registerRow(id));
    }

    // Check if any inputs have registered themselves within the SimpleBuilder
    const inputsHaveValidation = simpleBuilder.getFormState() !== ValidationState.VALID;

    const hasValidation = Boolean(
      inputsHaveValidation
      || rowValidator
      || validator
      || required
      || maxRows
      || minRows,
    );

    validationState.current = hasValidation ? ValidationState.UNKNOWN : ValidationState.VALID;

    if (hasValidation) {
      form.registerField(fieldName, groupName);
    }

    if (label) {
      form.registerFieldLabel(fieldName, label);
      form.registerFieldGroup(fieldName, groupName);
    }

    const extractInputValueMap = simpleBuilder.getFieldExtractInputValueMap();

    extractInputValueMap.forEach((value, key) => {
      const [inputFieldName] = parseFieldName(key);

      if (fieldExtractInputValueMap.has(inputFieldName)) {
        fieldExtractInputValueMap.set(inputFieldName, value);
      }
    });

    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    const numberOfDefaultRows = defaultValue?.length ?? 0;

    const hasNoRows = required && numberOfDefaultRows === 0;
    const hasLessRowsThanMinimum = minRows && numberOfDefaultRows < minRows;
    const isInvalid = hasLessRowsThanMinimum || hasNoRows;

    if (!defaultValue?.length && isInvalid) {
      fieldValidation();
      form.setFieldState(ValidationState.INVALID, fieldName, groupName);
    }

    return function cleanup() {
      isMounted.current = false;
      if (hasValidation) {
        form.unRegisterField(fieldName, groupName);
      }
      form.deleteField(fieldName, groupName);
    };
    // Only at mount time. Other props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[groupName] === FormValidationMode.ON) {
      rowValidation();
      fieldValidation();
    }
    // Only if validation mode changes. Other props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  React.useEffect(() => {
    // At this point all rowId modifications should have taken place (batched).
    if (triggerDeferredOnChange) {
      const values = simpleBuilder.getValues();
      const newValues = transformData(values);

      onChange?.(newValues);
      form.setValue(newValues, fieldName, groupName);
      setTriggerDeferredOnChange(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDeferredOnChange]);

  React.useEffect(() => {
    // At this point all rowId modifications should have taken place (batched).
    // Also the onChange should have happened (see above useEffect)
    if (triggerDeferredValidation) {
      fieldValidation();
      rowValidation();
      setTriggerDeferredValidation(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDeferredValidation]);

  React.useEffect(() => {
    if (componentRef) {
      componentRef({ addRow });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef]);

  // Responsible for ordering the error messages in the order that the children are passed to the SimpleBuilder
  const processRowValidatorResult = (rowFieldErrors: FormErrors | undefined): string[] => {
    const errorMessages: string[] = [];

    const orderedFields = Array.from(fieldNameSet.values());

    orderedFields.forEach(name => {
      errorMessages.push(...rowFieldErrors?.[name] ?? []);
    });

    return errorMessages;
  };

  const validateRow = (rowId: number): void => {
    if (rowValidator) {
      const allRowValues: FormValues[] = simpleBuilder.getValues();
      const rowNdx = simpleBuilder.getRowIndex(rowId);

      const rowFieldErrors = rowValidator(allRowValues, rowNdx);
      const errorMessages = processRowValidatorResult(rowFieldErrors);
      const rowValidationState = errorMessages.length ? ValidationState.INVALID : ValidationState.VALID;

      setRowErrors(previousErrors => ({
        ...previousErrors,
        [rowId]: errorMessages,
      }));

      simpleBuilder.setRowState(rowId, rowValidationState);
    }
  };

  const rowValidation = (): void => {
    const rows = Array.from(rowIds);

    if (!disableAutoRowAddition && simpleBuilder.hasTemplateRow) {
      rows.pop();
    }

    rows.forEach(validateRow);
  };

  // Validates the whole SimpleBuilder as one unit
  const fieldValidation = (): void => {
    const errorMessages: string[] = [];

    // SimpleBuilderState will have already removed the template row from the values
    const currentRows = form.getValue<FormValues[]>(fieldName, groupName);

    // Remove fields that do not have a value so proper validation may be performed
    const nonEmptyRows = currentRows?.filter(
      rowValues => {
        const values = Object.values(rowValues);
        const rowHasValues = values.some(value => value !== undefined && value !== "");
        return rowHasValues;
      },
    );

    const numberOfRows = nonEmptyRows?.length ?? 0;

    if (required && numberOfRows === 0) {
      errorMessages.push(Messages.validation.requiredValidation());
    }

    if (minRows && numberOfRows < minRows) {
      errorMessages.push(Messages.validation.minRows(minRows.toString()));
    }

    if (maxRows && numberOfRows > maxRows) {
      errorMessages.push(Messages.validation.maxRows(maxRows.toString()));
    }

    if (validator) {
      errorMessages.push(...validator(nonEmptyRows) ?? []);
    }

    validationState.current = errorMessages.length ? ValidationState.INVALID : ValidationState.VALID;

    form.setFieldState(
      getConsolidatedState([
        validationState.current,
        simpleBuilder.getFormState(),
      ]),
      fieldName,
      groupName,
    );

    setSimpleBuilderErrors(errorMessages);
  };

  const onRowRemove = (removedRowId: number): void => {
    const updatedRows = rowIds.filter(id => removedRowId !== id);
    const numberOfRows = updatedRows.length;

    // We need to account for the case when the default values are more than 1 over the maximum rows
    // as that means that if the user only deletes one row, we still do not have room for a template row
    const hasRoomForTemplateRow = maxRows ? numberOfRows < maxRows : true;
    // If there is currently a template row, then we do not need to replace it
    const addTemplateRow = !disableAutoRowAddition && !simpleBuilder.hasTemplateRow && hasRoomForTemplateRow;

    if (addTemplateRow) {
      simpleBuilder.hasTemplateRow = true;
      updatedRows.push(nextRowId);
      setNextRowId(nextRowId + 1);
    }

    setRowIds(updatedRows);

    if (rowValidator) {
      simpleBuilder.unRegisterRow(removedRowId);
    }

    simpleBuilder.deleteRow(removedRowId);
  };

  const rows = rowIds.map((currentRowId, currentRowIndex) => {
    const currentRowErrors = rowErrors[currentRowId];
    const lastRowIndex = rowIds.length - 1;

    let showDeleteButton: boolean;

    const hasMoreThanOneRow = rowIds.length > 1;

    if ((!simpleBuilder.hasTemplateRow && hasMoreThanOneRow) || disableAutoRowAddition) {
      showDeleteButton = true;
    } else {
      // Do not delete a template row (always the last row)
      const currentRowIsLastRow = currentRowIndex === lastRowIndex;

      showDeleteButton = !currentRowIsLastRow;
    }

    // We will only pass the SimpleBuilder's default values if the row's position has not changed
    const isInitialRowPosition = currentRowId === currentRowIndex;

    // Our inputs are considered a mix of controlled/uncontrolled, in that they appear managed
    // via the Form, but themselves are in fact not managed. So we must use the defaultValue prop
    // along with a remount key in order to force control of the inputs.
    const defaultRowValues = isInitialRowPosition ? defaultValue?.[currentRowIndex] ?? {} : {};
    const managedRowValues = managedValues.get(currentRowId) ?? {};
    // managed row values should take precendence
    const mergedRowValues = { ...defaultRowValues, ...managedRowValues };

    // Azure displays row errors on one line, so we concatenate the applicable errors
    const combinedCurrentRowErrors = currentRowErrors?.length ? [currentRowErrors.join(" ")] : undefined;

    return (
      <Row
        // This key is required, otherwise the inputs will all remount every time their row index changes.
        // Otherwise if a row is deleted, any rows beneath it that had a value set by the user, will appear
        // to lose the value, while behind the scenes the value and state are still stored in the Form.
        // The only other way around this is to keep track of the current values and pass them to the inputs as
        // a default value.
        //
        // We explicitly use the row ID as the key, as we expect that to be the same across all renders until
        // the row is deleted.
        //
        // If we apply the key within the Row component itself, it does not work as intended,
        // it must be placed at the top most abstraction that represents a row.
        key={currentRowId}
        keys={managedKeys.get(currentRowId)}
        id={currentRowId}
        index={currentRowIndex}
        template={templateRow}
        onChange={() => onRowChange(currentRowIndex)}
        onDelete={() => onRowRemove(currentRowId)}
        defaultValues={mergedRowValues}
        errorProps={{
          errors: combinedCurrentRowErrors,
          testId: simpleBuilderTestIds.rowError,
        }}
        deleteProps={{
          show: showDeleteButton,
          testId: simpleBuilderTestIds.deleteRow,
        }}
        tokens={columnStackTokens}
      />
    );
  });

  return (
    <Label fieldName={fieldName} label={label} required={required} tooltip={tooltip}>
      <InternalFormContextProvider value={simpleBuilder}>
        <div style={{ overflowX: "auto", width: "100%", overflowY: "hidden" }}>
          <Stack
            style={{ maxWidth: "100%", minWidth: "100%", width: "max-content" }}
          >
            <Header templateRow={templateRow} tokens={columnStackTokens} />
            {rows}
            <div style={{ paddingLeft: "5px", paddingTop: "10px" }}>
              <Errors errors={simpleBuilderErrors} testId={simpleBuilderTestIds.error} />
            </div>
          </Stack>
        </div>
      </InternalFormContextProvider>
    </Label>
  );
};
