import { buildTestId } from "../../helpers/testIdHelper";

/**
 * This does not extend ComponentTestIds because there is no 'root' component
 */
export interface SimpleBuilderTestIds {
  error: string;
  rowError: string;
  deleteRow: string;
}

export interface SimpleBuilderComponentRef {
  addRow: () => void;
}

// Creates test IDs used to access certain elements within the SimpleBuilder during testing
export const buildSimpleBuilderTestIds = (testId?: string): SimpleBuilderTestIds => ({
  error: buildTestId(testId, "-error"),
  rowError: buildTestId(testId, "-row-error"),
  deleteRow: buildTestId(testId, "-delete-row"),
});
