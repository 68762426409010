import * as React from "react";
import * as Messages from "../../codegen/Messages";
import { BaseInputProps } from "./BaseInput";
import { InternalFormContext, InternalFormState } from "./FormInternalTypes";
import { buildTextInputTestIds, TextInput, TextInputProps, TextInputTestIds } from "./TextInput";

const integerRegex = /^\d+$/;

export const buildIntegerInputTestIds = buildTextInputTestIds;
export type IntegerInputTestIds = TextInputTestIds;

type BaseTextInputProps = Omit<
TextInputProps,
"defaultValue"
| "multiline"
| "onChange"
| "revealPassword"
| "revealPasswordAriaLabel"
| "textType"
| "validator"
>;

export interface IntegerInputProps
  extends Pick<BaseInputProps<number | undefined>, "defaultValue" | "onChange" | "validator">,
  BaseTextInputProps {
  /**
   * The min value to validate against
   */
  min?: number;
  /**
   * The max value to validate against
   */
  max?: number;
}

export const IntegerInput = ({
  min,
  max,
  onChange,
  validator,
  defaultValue,
  required,
  ...textInputProps
}: IntegerInputProps): JSX.Element => {
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("IntegerInput should be used within form");
  }

  const internalValidation = (value: string | undefined): string[] | undefined => {
    const errors: string[] = [];
    if (
      (!value && required)
      || (value && !integerRegex.test(value))
    ) {
      errors.push(Messages.validation.integerValid());
    }

    const numValue = Number(value);
    const minValue = Number(min);
    const maxValue = Number(max);

    if (Number.isInteger(minValue) && !Number.isInteger(maxValue)) {
      if (!Number.isInteger(numValue) || numValue < minValue) {
        errors.push(Messages.validation.integerMin(minValue.toString()));
      }
    }
    if (!Number.isInteger(minValue) && Number.isInteger(maxValue)) {
      if (!Number.isInteger(numValue) || numValue > maxValue) {
        errors.push(Messages.validation.integerMax(maxValue.toString()));
      }
    }
    if (Number.isInteger(minValue) && Number.isInteger(maxValue)) {
      if (!Number.isInteger(numValue) || numValue < minValue || numValue > maxValue) {
        errors.push(Messages.validation.integerRange(
          minValue.toString(),
          maxValue.toString(),
        ));
      }
    }

    errors.push(...validator?.(value ? numValue : undefined) ?? []);

    return errors;
  };

  const internalOnChange = (newValue: string | undefined): void => {
    const numValue = Number(newValue);
    onChange?.(newValue ? numValue : undefined);
  };

  return (
    <TextInput
      {...textInputProps}
      required={required}
      onChange={internalOnChange}
      validator={internalValidation}
      defaultValue={defaultValue?.toString()}
    />
  );
};
