import * as React from "react";
import { IIconProps, IShimmerStyles, ITextFieldStyles, Shimmer, TextField } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { useErrors } from "../../hooks/useErrors";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  InternalFormContext,
  InternalFormState,
} from "./FormInternalTypes";
import { ValidationState } from "./FormTypes";
import { LabelWrapper } from "./LabelWrapper";
import { buildLabelWrapperTestIds, LabelWrapperProps, LabelWrapperTestIds } from "./LabelWrapperTypes";

export enum TextInputTypes {
  PASSWORD = "password",
  TEXT = "text",
}

export interface TextInputMultilineConfig {
  /**
   * Number of rows for the multiline
   */
  rows: number;

  /**
   * For multiline text fields, whether or not the field is resizable.
   * @default false
   */
  resizable?: boolean;
}

export interface TextInputProps
  extends Omit<BaseInputProps<string | undefined>, "readOnly">,
  Pick<LabelWrapperProps, "label"> {
  /**
   * Option to specify the TextInput type
   */
  textType?: TextInputTypes;
  /**
   * Option to display the password
   */
  revealPassword?: boolean;
  /**
   * If revealPassword is true, aria label for the reveal password button
   */
  revealPasswordAriaLabel?: string;
  /**
   * multiline config for the text field.
   */
  multiline?: TextInputMultilineConfig,
  /**
   * Display Shimmer when value is loading
   */
  loading?: boolean;
}

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: { width: "100%" },
  fieldGroup: { height: "24px" },
};

const textAreaStyles: Partial<ITextFieldStyles> = { root: { width: "100%" } };

const shimmerStyles: Partial<IShimmerStyles> = {
  root: {
    width: "100%",
    // This rule needs to be applied to the component that is the direct child of the LabelWrapper.
    // It works because the LabelWrapper applies a 'child gap', but the implementation is a margin
    // applied to the direct child
    marginTop: "5px !important",
  },
  dataWrapper: { position: "relative" },
};

export type TextInputTestIds = ComponentTestIds & LabelWrapperTestIds;

export const buildTextInputTestIds = (testId?: string): TextInputTestIds => ({
  ...buildLabelWrapperTestIds(testId),
  component: buildTestId(testId),
});

/**
 * TextInput component render an input field where the user can supply the input for the field
 */
export const TextInput = ({
  testId,
  fieldName,
  groupName,
  label,
  required,
  tooltip,
  disabled,
  textType,
  defaultValue,
  subField,
  revealPassword,
  revealPasswordAriaLabel,
  statusInfo,
  placeholder,
  validator,
  onChange,
  multiline,
  loading,
  ariaLabel,
}: TextInputProps): JSX.Element => {
  const { groupName: layoutGroup, layout } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const group = groupName || layoutGroup;
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("TextInput should be used within form");
  }

  const [allErrors, setFieldErrors] = useErrors(fieldName, group);
  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);
  const [checkedIcon, setCheckedIcon] = React.useState<boolean>(false);

  const textInputTestIds = buildTextInputTestIds(testId);

  const extractInputValue = (formValue: string | undefined): string | undefined => formValue || undefined;

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    if (validator || required) {
      form.registerField(fieldName, group);
    }

    if (defaultValue) {
      form.setValue(defaultValue, fieldName, group);
      fieldValidation();
    } else if (required) {
      form.setFieldState(ValidationState.INVALID, fieldName, group);
    }

    return function cleanup() {
      if (validator || required) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
    };
    // Only at mount time. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldValidation = () : void => {
    const value = form.getValue<string>(fieldName, group);
    const errorMsg = validator ? validator(value) : undefined;
    const requiredMessage = required
      ? (!value ? [Messages.validation.requiredValidation()] : undefined)
      : undefined;
    const errors = [...requiredMessage || [], ...errorMsg || []];
    form.setFieldState(
      errors && errors?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );
    setFieldErrors(errors);
  };

  const internalOnChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string,
  ): void => {
    const newValue = !value ? undefined : value;

    form.setValue(newValue, fieldName, group);
    if (validator || required) {
      fieldValidation();
      setCheckedIcon(form.getFieldState(fieldName, group) === ValidationState.VALID && newValue !== "");
    }
    onChange?.(newValue);
  };

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[group] === FormValidationMode.ON) {
      if (validator || required) {
        fieldValidation();
      }
    }
    // Only if validation mode changes. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  const iconProps: IIconProps = {
    iconName: "CheckMark",
    style: { lineHeight: "10px", color: "#57a300" },
  };

  const selectedStyles = multiline
    ? textAreaStyles
    : textFieldStyles;

  return (
    <LabelWrapper
      errors={allErrors}
      fieldName={fieldName}
      layout={layout}
      label={label}
      subField={subField}
      required={required}
      tooltip={tooltip}
      statusInfo={statusInfo}
      testId={testId}
    >
      <Shimmer isDataLoaded={!loading} styles={shimmerStyles}>
        <TextField
          id={fieldName}
          data-test-id={textInputTestIds.component}
          disabled={disabled}
          onChange={internalOnChange}
          ariaLabel={ariaLabel || label}
          defaultValue={defaultValue}
          type={textType || TextInputTypes.TEXT}
          placeholder={placeholder}
          iconProps={checkedIcon ? iconProps : undefined}
          canRevealPassword={revealPassword}
          revealPasswordAriaLabel={revealPasswordAriaLabel}
          multiline={!!multiline}
          rows={multiline?.rows}
          resizable={!!((multiline && multiline.resizable === true))}
          styles={selectedStyles}
        />
      </Shimmer>
    </LabelWrapper>
  );
};
