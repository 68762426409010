import * as React from "react";

/* eslint-disable max-len */

export const injectKeyFramesForAnimation = (style: string):void => {
  const styleElement = document.createElement("style");

  document.head.appendChild(styleElement);

  const styleSheet = styleElement.sheet;

  styleSheet?.insertRule(style, styleSheet.cssRules.length);
};

injectKeyFramesForAnimation(`@keyframes Pulse {
  0% {
    transform: scale(1);
    opacity: .3;
  }
  36.1% {
    transform: scale(1.06);
    opacity: 1;
  }
  50% {
    transform: scale(1.06);
    opacity: 1;
  }
  86.1% {
    transform: scale(1);
    opacity: .3;
  }
}`);

injectKeyFramesForAnimation(`@keyframes loading {
  0% {
    transform:translateX(-500px)
  }
  10% {
    transform:translateX(-500px)
  }
  90% {
    transform:translateX(500px)
  }
  100% {
    transform:translateX(500px)
  }
}`);

injectKeyFramesForAnimation(`@keyframes loading-opacity {
  0% {
    opacity:0
  }
  10% {
    opacity:0
  }
  50% {
    opacity:1
  }
  100% {
    opacity:1
  }
}`);

export const CircleMultiplySolid = (
  <svg
    viewBox="0 0 9 9"
    max-width="16"
    max-height="18"
    width="16"
    role="presentation"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
  >
    <g>
      <circle cx="4.5" cy="4.5" r="4.5" fill="#a4262c" />
      <path
        d="M7 2.8 6.2 2 4.5 3.7 2.8 2l-.8.8 1.7 1.7L2 6.2l.8.8 1.7-1.7L6.2 7l.8-.8-1.7-1.7z"
        fill="#fff"
      />
    </g>
  </svg>
);
export const CircleCheckmarkSolid = (
  <svg
    viewBox="0 0 16 16"
    max-width="16"
    max-height="18"
    width="16"
    role="presentation"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
  >
    <g>
      <circle cx="8" cy="8" r="8" fill="#57a300" />
      <path
        d="M3.553 8.291a.406.406 0 0 1 .022-.575l.835-.772a.407.407 0 0 1 .57.022l2.136 2.292 3.807-4.875a.402.402 0 0 1 .566-.073l.906.699c.173.128.21.377.08.554l-4.868 6.233a.5.5 0 0 1-.76.033L3.553 8.291z"
        fill="#fff"
      />
    </g>
  </svg>
);
export const Required = (
  <svg
    viewBox="0 0 6 6"
    role="presentation"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="6px"
    height="6px"
  >
    <g>
      <path
        d="m3.543 2.352 2.08-.716L6 2.687l-2.076.675L5.21 5.158l-.942.676-1.242-1.867-1.264 1.867-.97-.676 1.305-1.796L0 2.687.38 1.63l2.058.743V.233h1.105v2.119z"
        fill="#a4262c"
      />
    </g>
  </svg>
);
export const List = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.38 11.575" width="16px" height="16px">
    <defs>
      <linearGradient id="a" x1="-1.672" y1="9.26" x2="4.676" y2="9.197" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#5e9624" />
        <stop offset="1" stopColor="#76bc2d" />
      </linearGradient>
    </defs>
    <path d="M4.14.6h12.51a.728.728 0 01.73.725.728.728 0 01-.73.725H4.14a.728.728 0 01-.73-.725A.728.728 0 014.14.6zm0 4.38h12.51a.728.728 0 01.73.725.728.728 0 01-.73.725H4.14a.728.728 0 01-.73-.725.728.728 0 01.73-.725zm0 4.55h12.51a.728.728 0 01.73.725.728.728 0 01-.73.725H4.14a.728.728 0 01-.73-.725.728.728 0 01.73-.725z" fill="#8a8a8a" />
    <path d="M1.64 3.44a1.32 1.32 0 011.32 1.32 1.32 1.32 0 01-1.32 1.32A1.32 1.32 0 01.32 4.76a1.32 1.32 0 011.32-1.32zm0 4.39a1.32 1.32 0 011.32 1.32 1.32 1.32 0 01-1.32 1.32A1.32 1.32 0 01.32 9.15a1.32 1.32 0 011.32-1.32zm0 4.54a1.32 1.32 0 011.32 1.32 1.32 1.32 0 01-1.32 1.32 1.32 1.32 0 01-1.32-1.32 1.32 1.32 0 011.32-1.32zm0 .005A1.32 1.32 0 012.96 13.7a1.32 1.32 0 01-1.32 1.32A1.32 1.32 0 01.32 13.7a1.32 1.32 0 011.32-1.325z" transform="translate(-.32 -3.44)" fill="url(#a)" />
  </svg>
);
export const Warning = (
  <svg
    viewBox="0 0 9 9"
    role="presentation"
    focusable="false"
    max-width="16"
    max-height="18"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
  >
    <g>
      <path fill="#db7500" d="M8.267 8H.733c-.6 0-.916-.623-.62-1.129L2.014 3.53 3.896.384c.302-.507.903-.514 1.197-.008L7.001 3.65l1.882 3.229C9.183 7.383 8.881 8 8.267 8z" />
      <circle cx="4.5" cy="6.178" r=".615" fill="#fff" />
      <path
        d="M4.623 2.428H3.98l.164 2.85h.774l.165-2.85z"
        fill="#fff"
      />
    </g>
  </svg>
);

export const InProgress = (
  <svg
    viewBox="0 0 16 16"
    height="15"
    width="15"
    role="presentation"
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
  >
    <g>
      <g transform-origin="50%">
        <path
          d="M0 6h4v4H0zm6 0h4v4H6zm6 0h4v4h-4z"
          fill="#0f0f0f"
          style={{ animation: "Pulse 1.45s infinite ease-in-out" }}
        />
      </g>
    </g>
  </svg>
);

export const Sorted = (
  <svg viewBox="0 0 34.761 26.892" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" style={{ width: "16px", margin: "2px 2px -2px 8px" }}>
    <g>
      <path d="m15.359 9.478-6.226-6.21v17.557H7.426V3.268L1.2 9.478 0 8.281 8.279 0l8.279 8.281z" />
      <path d="m34.761 18.612-8.279 8.281-8.282-8.281 1.2-1.2 6.226 6.21V6.068h1.707v17.557l6.226-6.21z" />
    </g>
  </svg>
);

export const SortedAscending = (
  <svg viewBox="0 0 34.761 26.892" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" style={{ width: "16px", margin: "2px 2px -2px 8px" }}>
    <g>
      <path d="m15.359 9.478-6.226-6.21v17.557H7.426V3.268L1.2 9.478 0 8.281 8.279 0l8.279 8.281z" />
      <path d="m34.761 18.612-8.279 8.281-8.282-8.281 1.2-1.2 6.226 6.21V6.068h1.707v17.557l6.226-6.21z" opacity="0.25" />
    </g>
  </svg>
);

export const SortedDescending = (
  <svg viewBox="0 0 34.761 26.892" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" style={{ width: "16px", margin: "2px 2px -2px 8px" }}>
    <g>
      <path d="m15.359 9.478-6.226-6.21v17.557H7.426V3.268L1.2 9.478 0 8.281 8.279 0l8.279 8.281z" opacity="0.25" />
      <path d="m34.761 18.612-8.279 8.281-8.282-8.281 1.2-1.2 6.226 6.21V6.068h1.707v17.557l6.226-6.21z" />
    </g>
  </svg>
);

export const Info = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g>
      <circle cx="8" cy="8" r="8" fill="#015cda" />
      <circle cx="8" cy="4.116" r="1.449" fill="#fff" />
      <path d="M9.1 13.987v-7H6.911l-.011 7h2.2z" fill="#fff" />
    </g>
  </svg>
);

export const FilterSvg = (
  <svg viewBox="0 0 18 17" width="18" height="17" fill="#015cda" xmlns="http://www.w3.org/2000/svg" role="presentation" focusable="false" aria-hidden="true">
    <g>
      <path d="M5 4V0H4v4H0v1h4v4h1V5h4V4z" />
      <path d="M7 7v1.5l4 3.8V17h3v-4.7l4-3.8V7H7zm6 5v4h-1v-4L8 8.3V8h9v.3L13 12z" />
    </g>
  </svg>
);

export const FilterPillTagSvg = (
  <svg viewBox="0 0 13 17" width="13" height="17" fill="#015cda" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true">
    <g>
      <path d="M2.7 7.3 1.4 6l5-5H10v2h1V0H6L0 6l2 2 .7-.7zM8 6l-6 6 5 5 6-6V6zm4 4.6-5 5L3.4 12l5-5H12z" />
      <path d="M3.7 10.3 2.4 9l5-5H11v2h1V3H7L1 9l2 2 .7-.7z" />
      <circle cx="10.5" cy="8.5" r=".5" />
    </g>
  </svg>
);

export const MoreDotsSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#605e5c" focusable="false" height="16" width="16" viewBox="0 0 16 16">
    <circle cx="14.4" cy="7.6" r="1.6" />
    <circle cx="8" cy="7.6" r="1.6" />
    <circle cx="1.6" cy="7.6" r="1.6" />
  </svg>
);

export const ErrorResourceSvg = (
  <svg viewBox="0 0 113.386 113.773" width="125px" height="125px" xmlns="http://www.w3.org/2000/svg" role="presentation" focusable="false" aria-hidden="true">
    <g>
      <path fill="#aab3bd" d="M113 57.63a13.117 13.117 0 0 0-13.108-13.108H98.31a31.58 31.58 0 0 0 1.13-9.266 34.763 34.763 0 0 0-67.8-11.3 22.014 22.014 0 0 0-7.684-1.356A24.016 24.016 0 0 0 0 46.677v.1a23.777 23.777 0 0 0 23.6 23.96h77.2A13.254 13.254 0 0 0 113 57.63" />
      <path fill="#fff" d="M36.612 70.738a24.168 24.168 0 0 1-6.554-11.752 23.792 23.792 0 0 1 25.764-29.154 35.3 35.3 0 0 1 19.436-28.25A40.2 40.2 0 0 0 64.636 0a34.609 34.609 0 0 0-33 23.956 22 22 0 0 0-7.68-1.356A24.016 24.016 0 0 0 0 46.677v.1a23.777 23.777 0 0 0 23.6 23.96h13.012z" opacity=".2" />
      <path fill="#d0d5da" d="M86.1 101.246a11.752 11.752 0 1 1-23.5.434v-.434c0-6.554 11.752-28.7 11.752-28.7S86.1 94.694 86.1 101.246z" />
      <path fill="#fff" d="M68.93 104.864C67.8 99.214 71.868 82.716 74.58 73a.7.7 0 0 1-.226-.452S62.6 94.694 62.6 101.246A11.812 11.812 0 0 0 73.45 113a15.308 15.308 0 0 1-4.52-8.136z" opacity=".3" />
    </g>
  </svg>
);

export const ChevronRightSvg = (
  <svg viewBox="0 0 12 12" width="12px" height="12px" className="" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" data-type="158">
    <g>
      <path d="m8.2 6-5.5 5.5.6.5 6-6-6-6-.6.5z" />
    </g>
  </svg>
);

export const ChevronDownSvg = (
  <svg viewBox="0 0 12 12" width="12px" height="12px" className="" role="presentation" focusable="false" xmlns="http://www.w3.org/2000/svg" data-type="158" transform="rotate(90)">
    <g>
      <path d="m8.2 6-5.5 5.5.6.5 6-6-6-6-.6.5z" />
    </g>
  </svg>
);
