import * as React from "react";
import { recentAccessManager } from "./RecentAccessManager";
import { RecentAccessItem } from "./RecentAccessTypes";

export interface RecentAccessHandlers {
  recentItems: RecentAccessItem[];
  clearItem: (itemId: string) => void;
  clearAll: () => void;
}

const clearItem = (itemId: string): void => recentAccessManager.clearItem(itemId);
const clearAll = (): void => recentAccessManager.clearAll();

export const useRecentAccess = (): RecentAccessHandlers => {
  // Fetch the list of recent items upon initialization
  const [recentItems, setRecentItems] = React.useState<RecentAccessItem[]>(recentAccessManager.items);

  React.useEffect(() => {
    // Register a listener callback upon mounting
    const listenerId = recentAccessManager.addListener((items: RecentAccessItem[]) => {
      // Update the list of items everytime the listener is invoked with the modified list
      setRecentItems(items);
    });

    // Unregister the listener callback upon unmounting
    return () => recentAccessManager.removeListener(listenerId);
  }, []);

  return {
    recentItems,
    clearItem,
    clearAll,
  };
};
