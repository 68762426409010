import * as React from "react";
import { Checkbox, ICheckboxStyles } from "@fluentui/react";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { useErrors } from "../../hooks/useErrors";
import { CheckBoxVisibility } from "../Listing/ListingTypes";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayout, FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  InternalFormContext,
  InternalFormState,
} from "./FormInternalTypes";
import { ValidationState } from "./FormTypes";
import { LabelWrapper } from "./LabelWrapper";
import { buildLabelWrapperTestIds, LabelWrapperProps, LabelWrapperTestIds } from "./LabelWrapperTypes";

export interface CheckBoxProps extends BaseInputProps<boolean>, Pick<LabelWrapperProps, "label"> {
  /**
   * Option to display checkbox before or after the label
  */
  switchCheckBoxSide?: boolean;
  /**
   * Determines the visibilty of the checkbox
   * @default Always
   */
  checkboxVisibility?: CheckBoxVisibility;
}

export type CheckBoxTestIds = ComponentTestIds & LabelWrapperTestIds;
export const buildCheckBoxTestIds = (testId?: string): CheckBoxTestIds => ({
  ...buildLabelWrapperTestIds(testId),
  component: buildTestId(testId),
});

/**
 *The Checkbox component provides a way to select one or more items from a group,
 *or switch between two mutually exclusive options (checked or unchecked, on or off)
 *for the specified field.
 */

export const CheckBox = ({
  testId,
  fieldName,
  groupName,
  label,
  defaultValue,
  tooltip,
  required,
  statusInfo,
  subField,
  disabled,
  switchCheckBoxSide,
  onChange,
  validator,
  ariaLabel,
  width,
  minWidth,
  checkboxVisibility = CheckBoxVisibility.Always,
}: CheckBoxProps): JSX.Element => {
  const { groupName: layoutGroup, layout } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const group = groupName || layoutGroup;
  const form: InternalFormState = React.useContext(InternalFormContext);
  const [allErrors, setFieldErrors] = useErrors(fieldName, group);
  if (!Object.keys(form).length) {
    throw new Error("CheckBox should be used within form");
  }
  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const checkboxTestIds = buildCheckBoxTestIds(testId);

  // Normalized to undefined as we want to support the consumer passing both undefined
  // and false as an initial value for the field.
  const extractInputValue = (formValue: boolean): boolean | undefined => (formValue || undefined);

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue<boolean>(fieldName, extractInputValue);

    if (validator) {
      form.registerField(fieldName, group);
    }

    if (defaultValue) {
      setIsChecked(defaultValue);
      form.setValue(defaultValue, fieldName, group);
      fieldValidation();
    } else form.setValue(isChecked, fieldName, group);

    return function cleanup() {
      if (validator) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
    };
  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldValidation = () : void => {
    const value = form.getValue<boolean>(fieldName, group) as boolean;
    const errorMsg = validator?.(value);
    const errors = [...errorMsg || []];
    form.setFieldState(
      errors.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );
    setFieldErrors(errors);
  };

  const internalOnChange = (
    _?: React.FormEvent<HTMLElement | HTMLInputElement>,
    checked?: boolean,
  ): void => {
    if (checked !== undefined) { setIsChecked(checked); }
    form.setValue(checked, fieldName, group);
    if (validator) {
      fieldValidation();
    }
    onChange?.(checked || false);
  };

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[group] === FormValidationMode.ON) {
      if (validator) {
        fieldValidation();
      }
    }
    // Only if validation mode changes. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  const styles: ICheckboxStyles = {
    root: {
      marginTop: switchCheckBoxSide ? "2px" : "5px !important",
      width: switchCheckBoxSide ? "100%" : undefined,
      opacity: checkboxVisibility === CheckBoxVisibility.Hover && !isChecked ? 0 : 1,
      ":hover": { opacity: checkboxVisibility === CheckBoxVisibility.Hover ? 1 : undefined },
    },
    checkbox: { background: disabled && !isChecked ? "#f3f2f1" : undefined },
  };

  return (
    <LabelWrapper
      width={width}
      minWidth={minWidth}
      horizontal={layout === FormInputGroupLayout.WIDE || switchCheckBoxSide}
      reversed={switchCheckBoxSide}
      errors={allErrors}
      fieldName={fieldName}
      layout={layout}
      label={label}
      required={required}
      statusInfo={statusInfo}
      subField={subField}
      tooltip={tooltip}
      testId={testId}
    >
      <Checkbox
        id={fieldName}
        inputProps={{ "data-test-id": checkboxTestIds.component } as Record<string, string | undefined>}
        onChange={internalOnChange}
        disabled={disabled}
        checked={isChecked}
        ariaLabel={ariaLabel || label}
        styles={styles}
      />
    </LabelWrapper>
  );
};
