import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { ActionType } from "../Action/ActionTypes";
import { FilterOptions } from "../Filters/FilterTypes";
import { InfoBlockProps } from "../InfoBlock/InfoBlock";
import { AnchoredPanelProps } from "../Panel/AnchoredPanelTypes";

export enum ListingPaginationLayoutType {
  WIDE = "WIDE",
  COMPACT = "COMPACT",
}

export enum SortDirections {
  ASC = "ASCENDING",
  DESC = "DESCENDING",
}

export enum SelectionMode {
  none = 0,
  single = 1,
  multiple = 2,
}

export enum EmptyListingButtonTypes {
  PRIMARY = "PRIMARY",
  DEFAULT = "DEFAULT",
}

export interface ListingProps <T> {
  /**
   * List of columns to display in the table
   */
  listColumns: ListingColumn [];
  /**
   * List of items to display in the table
   */
  items: T [];
  /**
   * Number of rows to show per page. If not specified pagination will not be shown
   */
  itemsPerPage?: number;
  /**
   * Specifies if the table shoud support single/ multiple selection or none
   * @default none
   */
  selectionMode?: SelectionMode;
  /**
   * Returns the new list of selected items each time the selection changes
   */
  selectedItems?: (selectedItems: T[]) => void;
  /**
   * Actions supported by the action bar
   */
  actionBarItems?: ActionType [];
  /**
   * Triggered when the user clicks on any action.
   * Allows executing logic prior to invoking the action.
   */
  onActionClick?: (actionKey: string) => void;
  /**
   * Info block to show under action bar items
   */
  infoBlocks?: InfoBlockProps [];
  /**
   * Allows to disable all actions
   */
  disableAllActions?: boolean;
  /**
   * A placeholder to display when the table is empty
   */
  emptyList?: EmptyListProps;
  /**
   * Sorting proprties
   */
  sorting? : SortOptions;
  /**
   * Indicates if the list should show the loading indicator.
   * @default false
   */
  isLoading?: boolean;
  /**
   * Select items grouping criteria
   */
  groupingSelect?: GroupingSelect<T>;
  /**
   * Select the view type
   */
  viewSelect?: ViewSelect<T>;
  /**
   * Options to filter items in the list
   */
  filtering?: ListingFilterOptions;
  /**
   * Action menu to manipulate individual rows
   */
  actions?: ActionType<T> [] | ((item?: T) => ActionType <T> []);
  /**
   * A reference to the listing component
   */
  listingComponentRef?: (listing: ListingComponent<T>) => void;
  /**
   * Id used to get the listing elements for unit tests
   */
  testId?: string;
  /**
   * Callback to return whether the row checkbox should be disabled for the item
   */
  isRowSelectionDisabled?: (item: T) => boolean;
  /**
   * Default selection options to preselect element in the List.
   */
  defaultSelection?: DefaultSelection;
  /**
   * Option to hide the select / deselect all checkbox in the header.
   * @default false
   */
  hideSelectAll?: boolean;
  /**
   * Determines the visibilty of the row checkboxes
   * Only takes effect when selection is enabled
   * @default Always
   */
  checkboxVisibility?: CheckBoxVisibility;
  /**
   * Controls whether to show a count of the items displayed
   * Only takes effect when pagination is NOT enabled
   * @default false
   */
  showItemCount?: boolean;
  /**
   * Custom footer for the list
   */
  onRenderFooter?: () => JSX.Element;
}

export enum CheckBoxVisibility {
  Always = "Always",
  Hover = "Hover",
}

export interface EmptyListProps {
  testId?: string;
  watermark?: string;
  title: string;
  description?: string;
  emptyButtons?: EmptyButton [];
  learnMoreLink?: string;
  clearFilters?: () => void;
}

export interface ListingComponent<T> {
  /**
   * A function that removes all selection from the table
   */
  resetTableSelection: () => void;
  /**
   * A function that returns the selected items
   */
  getCurrentSelection: () => T [];
}

export interface ListingColumn {
  /**
   * Unique key that identifies the column
   */
  itemProp: string;
  /**
   * id used to get the ListingColumn header element
   */
  testId?: string;
  /**
   * Column display name
   */
  name: string;
  /**
   * The width of the column is a portion of the available space equal to this value divided by the sum
   * of all proportional column widths in the list.
   */
  flexGrow?: number;
  /**
   * Specifies if the column can be resized
   */
  isResizable?: boolean;
  /**
   * Function to render a custom component
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRenderItems?: (value: any) => JSX.Element | string;
  /**
   * Initial sort direction of the column
   */
  initialSortDirection?: SortDirections;
  /**
   * Custom comparator to use when sorting
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  comparator?: (a: any, b: any) => 0 | -1 | 1
}

export interface DefaultSelection {
  /**
   * The property that holds the primary key of the item.
   */
  uniqueItemProp: string;
  /**
   * List of the primary keys identifying the items to be selected.
   */
  values: string [];
}

export interface ListingPanelProps<T> extends ListingProps<T>,
  Pick <AnchoredPanelProps, "componentRef" | "title" | "subTitle" | "icon" | "onClose" | "isOpen" > {}

export interface ListingSelectOption <T>{
  /**
   *  Key of the select option
   */
  key: string | number;
  /**
   *  Display name of the option
   */
  text: string;
  /**
   *  Optional callback to display a custom group title
   */
  onRenderGroupTitle?: (item: T) => string;
}

export interface ViewSelectOption<T> extends ListingSelectOption<T> {
  /**
   * Add an icon to the option display name
   */
  icon?: string;
}

export interface GroupingSelect<T> {
  /**
   * List of grouping options
   */
  groupingOptions: ListingSelectOption<T>[],
  /**
   * Default selected grouping option
   */
  defaultSelectedKey?: string,
  /**
   * Handler that triggers when the selected grouping option changes
   */
  onGroupingChange?: (event: React.FormEvent<HTMLDivElement>, option?: ListingSelectOption<T>, index?: number) => void
}

export interface ViewSelect<T> {
  /**
   * List of view options
   */
  viewOptions: ListingSelectOption<T>[],
  /**
   * Default selected view option
   */
  defaultSelectedKey?: string,
  /**
   * Handler that triggers when the selected view option changes
   */
  onViewChange?: (event: React.FormEvent<HTMLDivElement>, option?: ListingSelectOption<T>, index?: number) => void;
}

export interface PageNavigation <T> {
  /**
   * A relative path to access when cicking on the link
   */
  to: string;
  /**
   * The key of the page that will display when the link is clicked
   */
  pageKey: string;
  /**
   * To store additional information with navigation
   */
  customData?: { [key:string]: T },
}

export interface CustomNavigation {
  /**
   * Callback to provide an action to execute when the link is clicked
   */
  onClick: () => void;
}

export type NavigationType<T> = PageNavigation<T> | CustomNavigation;

export interface ListingDisplayNameLinkProps <T>{
  /**
   * The link text to be displayed
   */
  displayName: string;
  /**
   * An optional icon to be displayed before the link text
   */
  iconName?: string;
  /**
   * Specify the navigation behaviour of the linl
   */
  navigation: NavigationType<T>;
}

export interface EmptyButtonMenu {
  /**
   * Key of the menu button
   */
  key: string;
  /**
   * Text to display on the menu button
   */
  text: string;
  /**
   * id used to get the EmptyButtonMenu
   */
  testId?: string;
  /**
   * A handler that triggers once the button is clicked
   */
  onClick: () => void;
}

export interface EmptyButtonMenuItems {
  /**
   * List of menu items under a menu button
   */
  items: EmptyButtonMenu [];
}

export interface EmptyButton {
  /**
   * Specifies if the button is primary or default
   */
  type: EmptyListingButtonTypes;
  /**
   * Text to display on the button
   */
  text: string;
  /**
   * Action to perform when the button is clicked
   */
  action?: () => void;
  /**
   * Specifies if the button is a menu button
   */
  menu?: EmptyButtonMenuItems;
}

export interface SortOptions {
  /**
   * The locale used for sorting
   */
  locale: string;
  /**
   * Initially sorted column
   */
  initialSortedColumn?: string;
}

export interface ListingFilterOptions extends FilterOptions {
  /**
   * Clear filter callback function
   */
  onClearFilters?: () => void;
}

export interface ListingTestIds extends ComponentTestIds{
  /**
   * The loading dots that are displayed when the listing is loading
   */
  loadingDots: string,
  /**
   * A row inside the listing.
   * To access a particular row use its corresponding index in the array of rows returned by getAllByTestId
   */
  row: string,
  /**
   * The checkbox to select a row
   * To access a particular checkbox use its corresponding index in the array of rows returned by getAllByTestId
   */
  rowCheckbox: string,
  /**
   * The action menu button displayed on the far right of the row
   */
  rowActionMenuButton: string,
  /**
   * The watermark that is displayed when the listing has no items to show
   */
  emptyListingWatermark: string,
  /**
   * The clear filter button in the watermark
   */
  emptyListingWatermarkClearFilterButton: string,
  /**
   * The primary button in the watermark
   */
  emptyListingWatermarkPrimaryButton: string,
}

export const buildListingTestIds = (baseTestId?: string): ListingTestIds => ({
  component: buildTestId(baseTestId),
  loadingDots: buildTestId(baseTestId, "-loading-dots"),
  row: buildTestId(baseTestId, "-row"),
  rowCheckbox: buildTestId(baseTestId, "-row-checkbox"),
  rowActionMenuButton: buildTestId(baseTestId, "-row-action-menu-button"),
  emptyListingWatermark: buildTestId(baseTestId, "-watermark"),
  emptyListingWatermarkClearFilterButton: buildTestId(baseTestId, "-watermark-clear-filters-button"),
  emptyListingWatermarkPrimaryButton: buildTestId(baseTestId, "-watermark-primary-button"),
});
