/* eslint-disable no-promise-executor-return */
/* eslint-disable promise/param-names */

import { BaseTelemetry, LogLevel, MultiCloudMetadata, TelemetryType } from "../models";
import {
  CONSOLE_LOGS_STORE_NAME,
  CONSOLE_METRIC_STORE_NAME,
  CONSOLE_METRICS_API_ENDPOINT,
  CONSOLE_LOGS_API_ENDPOINT,
  MC_TELEMETRY_LOGS_API_ENDPOINT,
  MC_TELEMETRY_METRICS_API_ENDPOINT,
} from "./constants";
import { v4 as uuid } from "uuid";

export const logToBrowser = (
  logLevel: LogLevel,
  message: string | Error,
  ...optionalParams: any[]
): void => {
  switch (logLevel) {
    case LogLevel.Debug:
      console.debug(message, optionalParams);
      break;
    case LogLevel.Error:
      console.error(message, optionalParams);
      break;
    case LogLevel.Info:
      console.info(message, optionalParams);
      break;
    case LogLevel.Warning:
      console.warn(message, optionalParams);
      break;
    default:
      console.log(logLevel, message, optionalParams);
  }
};

export const validateDimensions = (dimensions?: Record<string, string>): void => {
  if (dimensions && Object.keys(dimensions).length !== 0) {
    Object.keys(dimensions).forEach((key: string) => {
      const value: string = dimensions[key];
      if (!key || !value || typeof key !== "string" || typeof value !== "string") {
        throw new Error("Dimension key and value must be a non empty string");
      }
    });
  }
};

export const preprocessMetadata = (metadata?: Record<string, any>): MultiCloudMetadata[] => {
  const data: MultiCloudMetadata[] = [];
  if (metadata && Object.keys(metadata).length !== 0) {
    Object.keys(metadata).forEach((key: string) => {
      const value: string = metadata[key];
      if (key && value) {
        data.push({
          key,
          value,
          type: typeof value,
        });
      }
    });
  }
  return data;
};

/**
 * Replaces urls such as
 * "https://domain.com/#id_token..., https://domain.com/?id_token..., https://domain.com/id_token...
 * with "https://domain.com/"
 * @param input: url or message
 * @returns string: sanitized url or message
 */
export const removeAuthInfo = (input: string): string => {
  if (!input.includes("id_token")) {
    return input;
  }
  return input.replace(/\/(#|\?|i)(.*)/, "/");
};

export const sleep = (time: number): Promise<void> =>
  new Promise(resolve => {
    setTimeout(resolve, time);
  });

export const getConsoleTelemetryStoreName = (telemetryType: TelemetryType): string => {
  switch (telemetryType) {
    case "Metrics":
      return CONSOLE_METRIC_STORE_NAME;
    case "Logs":
    default:
      return CONSOLE_LOGS_STORE_NAME;
  }
};

export const getConsoleTelemetryAPIEndpoint = (telemetryType: TelemetryType): string => {
  switch (telemetryType) {
    case "Metrics":
      return CONSOLE_METRICS_API_ENDPOINT;
    case "Logs":
    default:
      return CONSOLE_LOGS_API_ENDPOINT;
  }
};

export const getMultiCloudTelemetryAPIEndpoint = (telemetryType: TelemetryType): string => {
  switch (telemetryType) {
    case "Metrics":
      return MC_TELEMETRY_METRICS_API_ENDPOINT;
    case "Logs":
    default:
      return MC_TELEMETRY_LOGS_API_ENDPOINT;
  }
};

/**
 * A function which wraps the provided promise into a promise which will be
 * rejected automatically when timeout is reached.
 * @param promise The promise to wrap
 * @param timeout The timeout (in ms) to wait until the promise is rejected
 */
export const timeoutPromise = <T>(promise: Promise<T>, error?: Error, timeout = 5000): Promise<T> =>
  Promise.race([
    promise,
    new Promise((_, reject) =>
      setTimeout(() => reject(error || new Error("Timeout error")), timeout),
    ),
  ]) as Promise<T>;

export const filterTelemetryURLsByRegion = <T>(telemetryURLs: string[], record: T) => {
  const region = (record as BaseTelemetry)?.telemetryRegion;
  const filterByRegion = region ? telemetryURLs.filter(url => url.includes(region)) : [];
  return filterByRegion.length > 0 ? filterByRegion : telemetryURLs;
};

export function generateUserSessionId(): string {
  return uuid();
}

/**
 * Get window through a function so we can mock this function in unit test and return a stub window
 */
export function getWindow(): Window {
  return window;
}
