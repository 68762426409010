import * as React from "react";
import { AnchoredPanel } from "../Panel/AnchoredPanel";
import { Listing } from "./Listing";
import { ListingPanelProps } from "./ListingTypes";

/**
 * A container anchored panel for the listing component
 */

export const ListingPanel = <T extends object >({
  listColumns,
  items,
  itemsPerPage,
  selectionMode,
  selectedItems,
  actionBarItems,
  emptyList,
  sorting,
  isLoading,
  groupingSelect,
  viewSelect,
  filtering,
  listingComponentRef,
  componentRef,
  title,
  subTitle,
  icon,
  onClose,
  isOpen,
}: ListingPanelProps <T>): JSX.Element => (
  <AnchoredPanel
    componentRef={componentRef}
    title={title}
    subTitle={subTitle}
    icon={icon}
    onClose={onClose}
    isOpen={isOpen}
  >
    <Listing
      listColumns={listColumns}
      items={items}
      itemsPerPage={itemsPerPage}
      selectionMode={selectionMode}
      selectedItems={selectedItems}
      actionBarItems={actionBarItems}
      emptyList={emptyList}
      sorting={sorting}
      isLoading={isLoading}
      groupingSelect={groupingSelect}
      viewSelect={viewSelect}
      filtering={filtering}
      listingComponentRef={listingComponentRef}
    />
  </AnchoredPanel>
  );
