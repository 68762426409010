import * as React from "react";

export interface Dimensions {
  top: number;
  left: number;
  right: number;
  width: number;
  height: number;
  bottom: number;
}

export const useResizeObserver = (ref: React.RefObject<HTMLElement | undefined>): Dimensions => {
  const [dimensions, setDimensions] = React.useState<Dimensions>({
    top: 0,
    left: 0,
    right: 0,
    width: 0,
    height: 0,
    bottom: 0,
  });

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const RO = new ResizeObserver(values => {
      if (values && Array.isArray(values)) {
        setDimensions(values?.[0]?.contentRect);
      }
    });

    RO.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => RO.disconnect();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return dimensions;
};
