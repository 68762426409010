/**
 * Supported formats
 */
export enum Format {
  Emphasized = "Emphasized",
  Link = "Link",
  Strong = "Strong",
}

/**
 * Interface representing formatted text
 */
export interface FormattedText {
  content?: FormattedTextResult;
  format: Format;
  properties?: Properties;
}

/**
 * Properties associated with a @see FormattedText
 */
interface Properties {
  [key: string]: string | number | boolean;
}

/**
 * Recursive structure representing formatted text
 */
export type FormattedTextResult = (string | FormattedText)[];

/**
 * Interface definition for use in the formatToRegExp object
 */
interface FormatParser {
  formatFn: (regexpResult: RegExpExecArray, format: Format) => FormattedText;
  format: Format;
  regexFn: (text: string) => RegExpExecArray | null;
}

/**
 * Formatting function for non-Link formats. First looks through parts for any nested formatting.
 * If any are found, it sends the required parameters to checkNestedTextAndFormat to be formatted.
 * @param parts FormattedTextResult object to search for nested text and to build up the content
 * property in the returned FormattedText object
 * @param format Format used to grab the appropriate regular expression function and to add to the
 * returned FormattedText object
 * @param index Number used to look at that index in parts for nested formatting and to build up
 * the content in the returned FormattedText object
 * @returns FormattedText
 */
function genericFormatFn(regexpResult: RegExpExecArray, format: Format): FormattedText {
  return {
    format,
    content: textFormatter(regexpResult[1]),
  };
}

/**
 * Link format parsing function
 * @param regexpResult RegExpExecArray results of an executed RegularExpression
 * @returns FormattedText
 */
function linkFormatFn(regexpResult: RegExpExecArray): FormattedText {
  // use the browser to help us parse the link
  const tempDiv = document.createElement("div");
  [tempDiv.innerHTML] = regexpResult;
  const a = tempDiv.firstElementChild;
  const properties: Properties = { target: "_blank" };
  if (a) {
    SUPPORTED_LINK_ATTRIBUTES.forEach(attr => {
      const elementAttr = a.attributes.getNamedItem(attr);
      if (elementAttr) {
        properties[attr] = elementAttr.value;
      }
    });

    return {
      properties,
      format: Format.Link,
      content: textFormatter(a.innerHTML),
    };
  }
  return {} as FormattedText;
}

const SUPPORTED_LINK_ATTRIBUTES: string[] = ["href", "title", "target"];

/**
 * Object used to store utilities and functions for formatting.
 * Each key is a Format and each property is a FormatParser which contains
 * functions for formatting text based on its format, testing if the given text
 * matches a particular regular expression, or splitting text based
 */
const formatToFormatParser: { [index in keyof typeof Format]: FormatParser } = {
  [Format.Emphasized]: {
    formatFn: genericFormatFn,
    format: Format.Emphasized,
    regexFn: (text: string) => /<em><\/em>|<em>(.+?)<\/em>/.exec(text),
  },
  [Format.Link]: {
    formatFn: linkFormatFn,
    format: Format.Link,
    regexFn: (text: string) => /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/.exec(text),
  },
  [Format.Strong]: {
    formatFn: genericFormatFn,
    format: Format.Strong,
    regexFn: (text: string) => /<strong><\/strong>|<strong>(.+?)<\/strong>/.exec(text),
  },
};

/**
 * Returns the Format which first appears in the given text string.
 * If no formatting is found in the text, nothing is returned.
 *
 * @param text string text search for formatting
 * @returns { format: Format, result: RegExpExecArray } | undefined
 */
function findFirstFormatting(text: string): { format: Format; result: RegExpExecArray } | undefined {
  // For each format, test the text against its regex and save to an object that
  // maps formats to indices
  let firstIndex = Infinity;
  let firstFormat: { format: Format; result: RegExpExecArray } | undefined;
  Object.keys(Format).forEach(format => {
    const regExpObj = formatToFormatParser[format as Format];
    const result = regExpObj.regexFn(text);
    if (result && result.index >= 0) {
      if (result.index < firstIndex) {
        firstIndex = result.index;
        firstFormat = { result, format: format as Format };
      }
    }
  });

  return firstFormat;
}

function sanitizeIncoming(incoming: string): string {
  const tempDiv = document.createElement("div");
  tempDiv.textContent = incoming;
  return tempDiv.innerHTML;
}

/**
 * Function to be called by framework-specific formatting packages like loom-formatted-string-react
 * Takes in a string and returns a FormattedTextResult.
 * If any supported formatting is, the text will be recursively parsed to a FormattedText.
 * If no supported formatting is detected, then this simply returns the given text as an array.
 * If no string is being passed in, it will return an empty array.
 * @param text String which need to be formatted
 * @returns FormattedTextResult
 */
export function textFormatter(text: string): FormattedTextResult {
  const firstFormat = findFirstFormatting(text);
  const parseTextResult: FormattedTextResult = [];

  if (typeof text === "string") {
    if (firstFormat) {
      const { format, result } = firstFormat;
      const pre = text.substr(0, result.index);
      const postTextResult = textFormatter(text.substr(result.index + result[0].length));
      if (pre.length > 0) {
        parseTextResult.push(pre);
      }
      parseTextResult.push(formatToFormatParser[format].formatFn(result, format));

      if (postTextResult.length > 0) {
        parseTextResult.push(...postTextResult);
      }
    } else if (text.length > 0) {
      parseTextResult.push(sanitizeIncoming(text));
    }
  }

  return parseTextResult;
}
