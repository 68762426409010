import * as React from "react";
import { concatStyleSets, ILinkStyles, IStyle, Link, mergeStyleSets, Stack, Text } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { buildTestId } from "../../helpers/testIdHelper";
import { useErrors } from "../../hooks/useErrors";
import { LabelInfoTooltip, RequiredTooltip } from "../Tooltip/Tooltip";
import { BaseInputProps, InputLink } from "./BaseInput";
import { FormInputGroupLayout, FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  InternalFormContext,
  InternalFormState,
} from "./FormInternalTypes";
import { ValidationState } from "./FormTypes";
import {
  compactSubFieldBorder,
  labelStyles,
  labelStylesSubField,
  labelStylesSubFieldWithTooltip,
  labelStylesWithToolTip,
  renderErrorMessages,
  renderStatusBar,
  stackItemStyles,
  stackItemStylesWithToolTip,
  wideSubFieldBorder,
} from "./InputCommon";

export interface ConfigInputValues {
  /**
   * Text option for the ConfigInput
   */
  title?: string | JSX.Element;
  /**
   * Description for the ConfigInput field
  */
  summary?: string | JSX.Element;
  /**
   * Value option for the ConfigInput
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

// FIXME: Should not have inputLink AND inputLinks, adjust BaseInputProps to use array instead
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ConfigInputProps extends Omit<BaseInputProps<never, never, any>, "defaultValue" | "onChange"> {
  /**
   * Label for the ConfigInput field
   */
  label?: string;
  /**
   * Title , summary and value option for the ConfigInput field
   */
  configInputValue: ConfigInputValues;
  /**
   * Link to display below the ConfigInput field
   */
  inputLinks?: InputLink[];
}

const classNames = mergeStyleSets({
  inputLinks: { display: "flex" },
  inputLink: { paddingRight: "10px" },
});

const configFieldStyles: IStyle = {
  padding: "3px 0px 6px 7px",
  width: "100%",
  borderLeftStyle: "solid",
  borderLeftWidth: "2px",
};

const linkStyles: ILinkStyles = { root: { fontSize: "13px" } };
const configStyles = { width: "100%", minWidth: "20rem" };

const configWideStyles = mergeStyleSets(configStyles, {
  maxWidth: "calc(100% - 250px)",
  paddingBottom: "10px",
});

const invalidConfigFieldStyles = concatStyleSets(configFieldStyles, { borderLeftColor: "rgb(255, 0, 0)" });
const validConfigFieldStyles = concatStyleSets(configFieldStyles, { borderLeftColor: "rgb(127, 186, 0)" });

/**
 * This does not extend ComponentTestIds as this does not ahve a 'core' component
 */
export interface ConfigInputTestIds {
  error: string;
  label: string;
  summary: string;
  title: string;
}

export const buildConfigInputTestIds = (testId?: string): ConfigInputTestIds => ({
  error: buildTestId(testId, "-error"),
  label: buildTestId(testId, "-label"),
  summary: buildTestId(testId, "-summary"),
  title: buildTestId(testId, "-title"),
});

/**
 * The ConfigInput component provides the caller with the ability to select the default configuration or
 * create a new configuration for the field.
*/

export const ConfigInput = (
  {
    testId, fieldName, groupName, label, validator, configInputValue,
    inputLink, statusInfo, required, tooltip, subField, inputLinks,
  }: ConfigInputProps,
): JSX.Element => {
  const configInputTestIds = buildConfigInputTestIds(testId);

  const { groupName: layoutGroup, layout } = React.useContext(FormInputGroupLayoutContext);
  const isWideLayout = layout === FormInputGroupLayout.WIDE;
  const group = groupName || layoutGroup;
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("ConfigInput should be used within form");
  }
  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);

  const [allErrors, setFieldErrors] = useErrors(fieldName, group);

  const finalStyle = !configInputValue.value || allErrors.length > 0
    ? invalidConfigFieldStyles : validConfigFieldStyles;

  const fieldValidation = (): void => {
    const { value } = form.getValue<ConfigInputValues>(fieldName, group) as ConfigInputValues;
    const errorMsg = validator ? validator(value) : undefined;
    const requiredMessage = required
      ? (!value ? [Messages.validation.requiredValidation()] : undefined)
      : undefined;
    const errors = [...requiredMessage || [], ...errorMsg || []];
    form.setFieldState(
      errors && errors?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );
    setFieldErrors(errors);
  };

  const extractInputValue = (
    formValue: ConfigInputValues,
  ): ConfigInputValues => formValue;

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    if (validator || required) {
      form.registerField(fieldName, group);
    }

    const hasDefaultValue = Boolean(configInputValue && configInputValue.value);

    if (required && !hasDefaultValue) {
      form.setFieldState(ValidationState.INVALID, fieldName, group);
    }

    return function cleanup() {
      if (validator || required) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
    };
    // Only at mount time. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[group] === FormValidationMode.ON) {
      if (validator || required) {
        fieldValidation();
      }
    }
    // Only if validation mode changes. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  React.useEffect(() => {
    form.setValue(configInputValue, fieldName, group);
    if (validationMode === FormValidationMode.ON || groupValidationMode[group] === FormValidationMode.ON) {
      if (validator || required) {
        fieldValidation();
      }
    }
    // Only if value changes. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(configInputValue.value)]);

  const configLink = (
    <Link
      id={inputLink?.id}
      styles={
        linkStyles
      }
      onClick={() => { inputLink?.onLinkClick?.(); }}
      data-test-id={inputLink?.testId}
    >
      {inputLink?.text}
    </Link>
  );

  const configLinks = inputLinks?.map(
    link => (
      <div
        key={link?.id}
        className={classNames.inputLink}
      >
        <Link
          data-test-id={link.testId}
          id={link?.id}
          styles={
            linkStyles
          }
          onClick={() => { link?.onLinkClick?.(); }}
        >
          {link?.text}
        </Link>
      </div>
    ),
  );

  const configField = (
    <div
      style={!isWideLayout ? configStyles : configWideStyles}
    >
      <div id={fieldName} style={finalStyle as React.CSSProperties}>
        <div style={{ marginBottom: "3px" }} data-test-id={configInputTestIds.title}>
          <strong>{configInputValue.title}</strong>
        </div>
        <div style={{ marginBottom: "3px" }} data-test-id={configInputTestIds.summary}>
          <span>{configInputValue.summary}</span>
        </div>
        {inputLink !== undefined && configLink}
        {inputLinks && inputLinks.length > 0 && <div className={classNames.inputLinks}>{configLinks}</div>}
      </div>
    </div>
  );

  const configInputSeparateLabel = (
    <>
      <Stack
        horizontal={isWideLayout}
        styles={inputLink === undefined ? stackItemStyles : undefined}
      >
        {label && (
          <Stack
            horizontal
            styles={tooltip !== undefined ? stackItemStylesWithToolTip : undefined}
            data-test-id={configInputTestIds.label}
          >
            <Stack horizontal style={{ minWidth: "250px" }}>
              {subField && isWideLayout && (
                <span style={isWideLayout ? wideSubFieldBorder : compactSubFieldBorder}>{ }</span>
              )}
              <Text
                styles={
                  tooltip !== undefined
                    ? subField
                      ? labelStylesSubFieldWithTooltip
                      : labelStylesWithToolTip
                    : subField
                      ? labelStylesSubField
                      : labelStyles
                }

              >
                {label}
                {required && <RequiredTooltip />}
                {tooltip !== undefined && (
                  <LabelInfoTooltip
                    tooltip={tooltip}
                  />
                )}

              </Text>

            </Stack>

          </Stack>
        )}
        {configField}
      </Stack>

      {allErrors.length > 0 && renderErrorMessages(allErrors, layout, configInputTestIds.error)}
      {statusInfo ? renderStatusBar(statusInfo, layout) : null}
    </>
  );

  return (configInputSeparateLabel);
};
