export const SERVICE_LIMIT_MULTIPLIER = 50;
export const TIMER_INTERVAL = 5 * 1000;
export const SHORT_TIME_INTERVAL = 2 * 1000;
export const DEFAULT_MESSAGE_TTL = 5 * 60 * 1000;

export const INDEX_DB_TEST_NAME = "console_feature_test";
export const INDEX_DB_VERSION = 2;
export const INDEX_DB_TEST_STORE_NAME = "test";

export const CONSOLE_LOGS_STORE_NAME = "logs";
export const CONSOLE_METRIC_STORE_NAME = "metrics";

export const CONSOLE_LOGS_API_ENDPOINT = "/v1/tracking-events";
export const CONSOLE_METRICS_API_ENDPOINT = "/v1/metrics";

export const MC_TELEMETRY_LOGS_API_ENDPOINT = "/sendLogging";
export const MC_TELEMETRY_METRICS_API_ENDPOINT = "/sendMetrics";

export const INFINITY_TAG_TIMEOUT = 10000;
export const INFINITY_INIT_TAG_ID = "InfinityStartScript";
export const INFINITY_INVALID_PARAMS =
  "Initialize Infinity with the right params such as your script tag and your app name";
export const INFINITY_ALREADY_INITIALIZED =
  "Infinity is already initialized. Use track() to send data to infinity";
export const INFINITY_NOT_INITIALIZED =
  "Initialize Infinity first by calling AnalyticsClient.initialize() method with the right params";
export const INFINITY_REMOVED_INFO = "Infinity Analytics > removed from window";
