import {
  ExtractInputValueType,
  FieldsStates,
  FormStates,
  getConsolidatedState,
  getFieldErrors,
  InternalFormState,
  StatesUpdateCallback,
  ValueUpdateCallback,
} from "./FormInternalTypes";
import { FormErrors, FormValues, getValue, getValuesByFieldPrefix, ValidationState } from "./FormTypes";

export class FormStateInstance implements InternalFormState {
  private groups: Set<string> = new Set<string>();

  private fieldLabelMap: Map<string, string> = new Map<string, string>();

  private fieldGroupMap: Map<string, string> = new Map<string, string>();

  private fieldExtractInputValueMap: Map<string, ExtractInputValueType> = new Map<string, ExtractInputValueType>();

  private onStatesUpdate?: StatesUpdateCallback;

  private onValueUpdate?: ValueUpdateCallback;

  private fieldsStates: FieldsStates = {};

  private formValues: FormValues = {};

  private formErrors: FormErrors = {};

  constructor(onStatesUpdate?: StatesUpdateCallback, onValueUpdate?: ValueUpdateCallback) {
    if (onStatesUpdate) {
      this.onStatesUpdate = onStatesUpdate;
    }
    if (onValueUpdate) {
      this.onValueUpdate = onValueUpdate;
    }
  }

  public registerFieldLabel = (field: string, label?: string): void => {
    if (label) {
      this.fieldLabelMap.set(field, label);
    }
  };

  public registerFieldGroup = (field: string, group?: string): void => {
    if (group) {
      this.fieldGroupMap.set(field, group);
    }
  };

  public registerFieldExtractInputValue = <ValueType, ReturnType = ValueType>(
    field: string,
    extractInputValue: ExtractInputValueType<ValueType, ReturnType>,
  ): void => {
    this.fieldExtractInputValueMap.set(field, extractInputValue as ExtractInputValueType);
  };

  public registerField = (field: string, group?: string): void => {
    if (group) {
      if (!this.groups.has(group)) {
        this.groups.add(group);
        this.fieldsStates[group] = {};
      }
      const groupStates = (this.fieldsStates[group] as Record<string, ValidationState>);
      if (groupStates) {
        groupStates[field] = ValidationState.UNKNOWN;
      }
    } else {
      this.fieldsStates[field] = ValidationState.UNKNOWN;
    }
    this.onStatesUpdate?.(this.getStates(), ValidationState.UNKNOWN, field, group);
  };

  public unRegisterField = (field: string, group?: string): void => {
    if (group) {
      const groupStates = (this.fieldsStates[group] as Record<string, ValidationState>);
      if (groupStates) {
        delete groupStates[field];
      }
    } else {
      delete this.fieldsStates[field];
    }
    this.onStatesUpdate?.(this.getStates(), ValidationState.UNKNOWN, field, group);
  };

  public getFieldLabel = (field: string): string | undefined => this.fieldLabelMap.get(field);

  public getFieldLabelMap = (): Map<string, string> => this.fieldLabelMap;

  public getFieldGroup = (field: string): string | undefined => this.fieldGroupMap.get(field);

  public getFieldGroupMap = (): Map<string, string> => this.fieldGroupMap;

  public getFieldExtractInputValueMap = (): Map<string, ExtractInputValueType> => this.fieldExtractInputValueMap;

  public extractFieldInputValue = (formValue: unknown, field: string): unknown | undefined => {
    const extractInputValue = this.fieldExtractInputValueMap.get(field);
    return extractInputValue?.(formValue);
  };

  public setFieldState = (state: ValidationState, field: string, group?: string): void => {
    if (group) {
      const groupStates = (this.fieldsStates[group] as Record<string, ValidationState>);
      if (groupStates) {
        groupStates[field] = state;
      }
    } else {
      this.fieldsStates[field] = state;
    }
    this.onStatesUpdate?.(this.getStates(), state, field, group);
  };

  private getStates = (): FormStates => {
    const states = {
      overall: this.getFormState(),
      groups: {},
    } as FormStates;
    this.groups.forEach(group => {
      states.groups[group] = this.getGroupState(group);
    });
    return states;
  };

  public getFieldState = (field: string, group?: string): ValidationState => {
    if (group) {
      return (this.fieldsStates[group] as Record<string, ValidationState>)[field] || ValidationState.UNKNOWN;
    }
    return this.fieldsStates[field] as ValidationState || ValidationState.UNKNOWN;
  };

  public getFormState = (): ValidationState => {
    const names = Object.keys(this.fieldsStates);
    const states = names.map(name => {
      if (!this.groups.has(name)) {
        return this.fieldsStates[name] as ValidationState;
      }
      const groupStates = this.fieldsStates[name] as Record<string, ValidationState>;
      return getConsolidatedState(Object.values(groupStates));
    });
    return getConsolidatedState(states);
  };

  public getGroupState = (group: string): ValidationState => {
    const groupStates = Object.values(this.fieldsStates[group] as Record<string, ValidationState>);
    return getConsolidatedState(groupStates);
  };

  public setValue = (value: unknown, field: string, group?: string): void => {
    if (group) {
      if (!this.formValues[group]) {
        this.formValues[group] = {};
      }
      this.formValues[group][field] = value;
    } else {
      this.formValues[field] = value;
    }
    this.onValueUpdate?.(this.formValues, value, field, group);
  };

  public getValue = <ValueType>(
    field: string,
    group?: string,
  ): ValueType | undefined => getValue<ValueType>(this.formValues, field, group);

  public getValuesByFieldPrefix = (
    fieldPrefix: string,
    group?: string,
  ): FormValues => getValuesByFieldPrefix(this.formValues, fieldPrefix, group);

  public getValues = (): FormValues => this.formValues;

  public hasValidation = (group?: string): boolean => {
    if (group) {
      return !!this.fieldsStates[group];
    }
    const names = Object.keys(this.fieldsStates);
    return names.length > 0;
  };

  public getGroupValues = (group: string): FormValues => this.formValues[group];

  public deleteField = (field: string, group?: string): void => {
    if (group) {
      delete this.formValues[group]?.[field];
    } else {
      delete this.formValues[field];
    }
    this.onValueUpdate?.(this.formValues, undefined, field, group);
  };

  public getFieldErrors = (
    field: string,
    group?: string,
  ): string[] | undefined => getFieldErrors(this.formErrors, field, group);

  public setFieldErrors = (errors: string[] | undefined, field: string, group?: string): void => {
    this.formErrors[String([field, group])] = errors;
    this.setFieldState(errors ? ValidationState.INVALID : ValidationState.VALID, field, group);
  };

  public getFormErrors = (): FormErrors => this.formErrors;
}
