import { logToBrowser } from "../utils/internalUtils";
import { LogLevel, Transport, LogMetadata } from "../models";

export class BrowserLogTransport implements Transport {
  processLog(logLevel: LogLevel, message: string, logMetadata?: LogMetadata): void {
    console.group(`Log: ${message}`);
    logToBrowser(logLevel, "logLevel", logLevel);
    logToBrowser(logLevel, "message/error", message);
    logToBrowser(logLevel, "logMetadata", logMetadata);
    console.groupEnd();
  }

  processMetric(name: string, value: number, dimensions?: Record<string, string>): void {
    console.group(`Metric: ${name}`);
    console.info("name", name);
    console.info("value", value);
    console.info("dimensions", dimensions);
    console.groupEnd();
  }
}
