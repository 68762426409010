import * as React from "react";
import {
  Callout,
  FontIcon,
  ICalloutProps,
  IconButton,
  IIconProps,
  ITooltipHostStyles,
  Link,
  mergeStyles,
  mergeStyleSets,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { StatusType } from "../../helpers/statusHelper";

interface StatusInfo {
  title?: string;
  description: string
}
export interface StatusProps {
  /**
   *Status type to be used for the Status
   */
  status: StatusType;
  /**
   * Label for the Status
   */
  label?: string;
  /**
   * Tooltip will be shown when hover over an icon
   */
  tooltip?: string;
  /**
   * Icon position.
   * @default: left
   */
  iconPosition?: "left" | "right"
  /**
   * Providing status info will show the @field label (required if status info is passed) as hyperlink.
   * @field title or @field description will be shown on the callout.
   */
  statusInfo?: StatusInfo;
  /**
   * Clipboard button will be shown by default
   *  @default: false
   */
  hideClipboardCopy?: boolean;
}

const iconClass = mergeStyles({
  height: 18,
  width: 18,
  cursor: "default",
});

const styles = mergeStyleSets({
  callout: {
    width: 360,
    padding: "18px",
  },
  button: {
    display: "table",
    boxSizing: "border-box",
    minWidth: 0,
    height: "20px",
    width: "20px",
    margin: "2px",
    fontSize: "13px",
    lineHeight: 1.385,
    position: "absolute",
  },
  icon: {
    boxSizing: "border-box",
    textAlign: "center",
    verticalAlign: "middle",
    width: "12px !important",
    height: "12px !important",
    padding: "0 important",
    color: "rgb(0,0,0)",
    marginTop: "-5px !important",
    fontSize: "13px !important",
  },
});

function getStatusColorAndIcon(type: StatusType): { [key: string]: string } {
  switch (type) {
    case StatusType.INFO:
      return { color: "#0451d5", name: "InfoSolid" };
    case StatusType.ERROR:
      return { color: "#db0e1c", name: "StatusErrorFull" };
    case StatusType.SUCCESS:
      return { color: "#53aa00", name: "CompletedSolid" };
    case StatusType.NONE:
      return { color: "#6f6f6f", name: "CircleFill" };
    case StatusType.PENDING:
      return { color: "#0451d5", name: "SyncStatusSolid" };
    case StatusType.STOPPED:
      return { color: "#6f6f6f", name: "CircleStopSolid" };
    case StatusType.CANCELLED:
      return { color: "#6f6f6f", name: "StatusErrorFull" };
    case StatusType.DISABLED:
      return { color: "#db0e1c", name: "DRM" };
    case StatusType.UNKNOWN:
      return { color: "#6f6f6f", name: "UnknownSolid" };
    case StatusType.WARNING:
      return { color: "#d66b00", name: "WarningSolid" };
    default: return {};
  }
}

function getIcon(status: StatusType, iconTooltip: string): JSX.Element {
  const { color, name } = getStatusColorAndIcon(status);

  const icon = (
    <FontIcon
      iconName={name}
      className={mergeStyles(iconClass, { color })}
    />
  );

  return (
    <div style={{ marginTop: "2px" }}>
      {iconTooltip ? (
        <TooltipHost
          content={iconTooltip}
        >
          {icon}
        </TooltipHost>
      ) : icon }
    </div>
  );
}

/**
 * The Status component render the status icon for the provided status type with
 * a tooltip on hovering over it
 */
export const Status = ({
  status,
  label,
  tooltip = "",
  statusInfo,
  iconPosition = "left",
  hideClipboardCopy = false,
}: StatusProps): JSX.Element => {
  const [openInfoCallout, setOpenInfoCallout] = React.useState<boolean>(false);
  const targetId = useId("status-info-callout");

  const [copied, setCopied] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const tooltipId = useId("tooltip");
  const buttonId = useId("targetButton");

  const showElement = isHovered ? 1 : 0;
  const calloutProps: ICalloutProps = { gapSpace: 0, target: `#${buttonId}` };
  const iconProps: IIconProps = { iconName: "Copy", "aria-describedby": tooltipId, className: styles.icon };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: {
      display: "flex",
      flex: "auto",
      maxWidth: "24px",
      width: "20px",
      opacity: showElement,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const copyLabelMetaItemValue = (event): void => {
    if (!copied) setCopied(true);
    if (label) navigator.clipboard.writeText(label);
  };

  const showCopy = (): void => {
    if (!hideClipboardCopy) setIsHovered(true);
  };

  const hideCopy = (): void => {
    if (!hideClipboardCopy) {
      setIsHovered(false);
      if (copied) setCopied(false);
    }
  };

  const clipboardCopy = (): JSX.Element => (
    <TooltipHost
      content={copied ? Messages.actions.copiedToClipboard() : Messages.actions.copyToClipboard()}
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
    >
      <IconButton
        id={buttonId}
        onClick={copyLabelMetaItemValue}
        className={styles.button}
        iconProps={iconProps}
        aria-describedby={tooltipId}
        styles={{ root: { opacity: showElement } }}
      />
    </TooltipHost>
  );

  return (
    <>
      <Stack
        styles={{ root: { maxWidth: 200 } }}
      >
        <Stack
          tokens={{ childrenGap: 4 }}
          horizontal
          style={{ display: "flex", width: "100%" }}
          onMouseEnter={showCopy}
          onMouseLeave={hideCopy}
          onFocus={showCopy}
          onBlur={hideCopy}
        >
          {iconPosition === "left" && getIcon(status, tooltip)}
          {!statusInfo && (
          <Text title={label} aria-label={label}>
            {label}
          </Text>
          )}
          {statusInfo && label && (
            <Text title={label}>
              <Link
                id={targetId}
                onClick={() => setOpenInfoCallout(true)}
                aria-label={label}
                underline
              >
                {label}
              </Link>
            </Text>
          )}
          {iconPosition === "right" && getIcon(status, tooltip)}
          {!hideClipboardCopy && clipboardCopy()}
        </Stack>
      </Stack>
      {openInfoCallout && (
      <Callout
        isBeakVisible
        setInitialFocus
        target={`#${targetId}`}
        onDismiss={() => setOpenInfoCallout(false)}
        className={styles.callout}
      >
        {statusInfo?.title && (
        <Text
          block
          styles={{
            root: {
              fontSize: 24,
              fontWeight: 600,
              marginBottom: 10,
            },
          }}
        >
          {statusInfo?.title}
        </Text>
        )}
        <Text
          block
          styles={{
            root: {
              marginBottom: 18,
              fontStyle: "normal",
              overflowWrap: "break-word",
            },
          }}
        >
          {statusInfo?.description}
        </Text>
      </Callout>
      )}
    </>
  );
};
