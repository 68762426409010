import { buildTestId } from "../../helpers/testIdHelper";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayout } from "./FormInputGroup";

/**
 * It does not extend the ComponentTestIds interface as this is a 'wrapper' not a 'core' component
 */
export interface LabelWrapperTestIds {
  error: string;
  label: string;
}

export const buildLabelWrapperTestIds = (testId?: string): LabelWrapperTestIds => ({
  error: buildTestId(testId, "-error"),
  label: buildTestId(testId, "-label"),
});

type BaseLabelProps = Pick<BaseInputProps<never>,
"fieldName"
| "inputLink"
| "required"
| "statusInfo"
| "subField"
| "tooltip"
| "width"
| "minWidth"
>;

export interface LabelWrapperProps extends BaseLabelProps {
  /**
   * The input component to be displayed
   */
  children: JSX.Element;
  /**
   * The layout of the component
   */
  layout: FormInputGroupLayout;
  /**
   * The list of errors determined by the validators
   */
  errors: string[];
  /**
   * The text to display in the label
   */
  label?: string;
  /**
   * Allows override of whether the label and the input are displayed on the same row
   * @default true when the FormInputGroupLayout is set to WIDE
   * @default false when the FormInputGroupLayout is set to COMPACT
   */
  horizontal?: boolean;
  /**
   * Reverse the order of the label and the input
   * @default false
   */
  reversed?: boolean;
  /**
   * derives multiple test ids for nested elements
   */
  testId?: string;
}
