import * as IPCIDR from "ip-cidr";

// eslint-disable-next-line max-len
// https://docs.oracle.com/cd/E19109-01/tsolaris8/816-1048/networkconcepts-2/index.html#:~:text=IPv4%20network%20addresses%20can%20have,length%20between%201%20and%20128.
// IPv4 network addresses can have a prefix length between 0 and 32.
// IPv6 network addresses can have a prefix length between 0 and 128
export const maxIpv4CidrPrefix = 32;
export const minIpv4CidrPrefix = 0;

/**
 * https://bitbucket.oci.oraclecorp.com/projects/VN-CORE-UI/repos/networking-ui/browse/src/helpers/cidrHelper.ts
 * Get a 8-bit binary string for each int in each segment
 * of the IP address and combine them, resulting in one
 * 32-bit representation of the address
 */
export function convertIpToNumber(ip: string): number {
  const d = ip.split(".");
  return ((((((+d[0]) * 256) + (+d[1])) * 256) + (+d[2])) * 256) + (+d[3]);
}

/**
 * https://bitbucket.oci.oraclecorp.com/projects/VN-CORE-UI/repos/networking-ui/browse/src/helpers/cidrHelper.ts
 * Must not have bits signed outside network prefix. e.g. 10.0.0.1/8 is invalid
 * because you can only have signed bits in the first octet in that case
 */
export const cidrHasHostBitsSet = (cidr: string): boolean => {
  if (cidrValid(cidr)) {
    const ip = cidr.split("/")[0];
    // We force this not to be undefined as we perform the same check that
    // would cause this to return undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const prefix = getCidrPrefix(cidr)!;

    const ipNumber = convertIpToNumber(ip);

    /**
     * Bitwise operator magic incoming
     * see https://bitbucket.oci.oraclecorp.com/projects/VN-CORE-UI/repos/networking-ui/browse/src/helpers/cidrHelper.ts
     */

    // eslint-disable-next-line no-bitwise
    const mask = ~(2 ** (32 - prefix) - 1);
    // eslint-disable-next-line no-bitwise
    return !(prefix === 32 || (ipNumber | mask) === mask);
  }

  return false;
};

export const getCidrPrefix = (cidr: string): number | undefined => {
  if (cidrValid(cidr)) {
    const prefixStr = cidr.split("/")[1];
    return parseInt(prefixStr, 10);
  }
  return undefined;
};

export const cidrRange = (cidr: string): [string, string, number] => {
  if (cidrValid(cidr)) {
    const mask = parseInt(cidr.split("/")[1], 10);
    const ipCount = 2 ** (32 - mask);

    const cidrBlock = new IPCIDR(cidr);
    const startIp = cidrBlock.start<string>();
    const endIp = cidrBlock.end<string>();

    return [startIp, endIp, ipCount];
  }
  return ["", "", 0];
};

export const cidrValid = (cidr: string): boolean => {
  try {
    const cidrBlock = new IPCIDR(cidr);
    return !!cidrBlock;
  } catch (e) {
    return false;
  }
};

/**
 *
 * @param min the minimum prefix value includsive
 * @param max the maximum prefix value inclusive
 * @returns true when the prefix is within the given range
 */
export const cidrPrefixWithinRange = (cidr: string, min = minIpv4CidrPrefix, max = maxIpv4CidrPrefix): boolean => {
  if (max < min) throw new Error("max cannot be less than min");
  const prefix = getCidrPrefix(cidr);

  // eslint-disable-next-line eqeqeq
  if (prefix != undefined) {
    return prefix >= min && prefix <= max;
  }

  return false;
};

/* export const cidrOverlap = (cidr1: string, cidr2: string): boolean => {
  if (cidr1.trim() === cidr2.trim()) {
    return true;
  } if (isValidCidr(cidr1) && isValidCidr(cidr2)) {
    if (cidrValid(cidr1) && cidrValid(cidr2)) {
      const cidrBlock1 = new IPCIDR(cidr1);
      const cidrBlock2 = new IPCIDR(cidr2);
      const cidrBlock2Range = cidrBlock2.toRange<string>();
      return cidrBlock1.contains(cidrBlock2Range[0]) || cidrBlock1.contains(cidrBlock2Range[1]);
    }
  } if (isValidCidr(cidr1) || isValidCidr(cidr2)) {
    return checkCidrOverlapsIP(cidr1, cidr2);
  }
  return false;
}; */

export interface OverlapCIDR {
  cidr1: string;
  cidr2: string;
}
/**
 * Works with IP addresses and CIDRs
 * @param cidrs
 * @returns list of object contains cidr1, cidr2 overlapping
 */
export const getOverlapCidrs = (cidrs: string[]): OverlapCIDR[] => {
  if (!cidrs.length) return [];

  const overlapCidrs: OverlapCIDR[] = [];

  for (let index = 0; index < cidrs.length; index++) {
    for (let innerIndex = index + 1; innerIndex < cidrs.length; innerIndex++) {
      if (
        isValidAddress(cidrs[index])
        && isValidAddress(cidrs[innerIndex])
        && cidrOverlap(cidrs[index], cidrs[innerIndex])
      ) {
        overlapCidrs.push({ cidr1: cidrs[index], cidr2: cidrs[innerIndex] });
      }
    }
  }
  return overlapCidrs;
};

export const isValidIpAddress = (address: string): boolean => !isCidr(address) && IPCIDR.isValidAddress(address);

/**
 * This validates if it is either a CIDR or IP address
 */
export const isValidAddress = (address: string): boolean => IPCIDR.isValidAddress(address);

const isCidr = (cidr: string): boolean => cidr.indexOf("/") > 0;

const isIpOverlapsCidr = (cidr:string, ip:string): boolean => {
  if (cidr && ip) {
    const cidrBlock = new IPCIDR(cidr);
    return cidrBlock?.contains(ip);
  }
  return false;
};

export const cidrOverlap = (cidr1: string, cidr2: string): boolean => {
  if (cidr1 && cidr2) {
    if (cidr1.trim() === cidr2.trim()) {
      return true;
    }
    if (isCidr(cidr1) && cidrValid(cidr1) && isCidr(cidr2) && cidrValid(cidr2)) {
      const cidrBlock1 = new IPCIDR(cidr1);
      const cidrBlock2 = new IPCIDR(cidr2);
      const cidrBlock2Range = cidrBlock2.toRange<string>();
      return cidrBlock1.contains(cidrBlock2Range[0]) || cidrBlock1.contains(cidrBlock2Range[1]);
    }
    let cidr;
    let ip;
    if (isCidr(cidr1) && cidrValid(cidr1) && !isCidr(cidr2)) {
      cidr = cidr1;
      ip = cidr2;
    } else if (!isCidr(cidr1) && isCidr(cidr2) && cidrValid(cidr2)) {
      cidr = cidr2;
      ip = cidr1;
    }
    return isIpOverlapsCidr(cidr, ip);
  }
  return false;
};
