import * as React from "react";
import { Stack } from "@fluentui/react";
import { screenSizeManager } from "../Context/InternalScreenSizeContext";
import { DetailViewsMenuZoomLayout } from "../Context/ScreenSizeContextTypes";
import { GroupItem, Menu, MenuProps, MenuState, MenuType } from "../Menu/Menu";

export enum DetailViewsLayoutType {
  SCROLL_MENU_CONTENT_TOGETHER = "SCROLL_MENU_CONTENT_TOGETHER",
  SCROLL_MENU_CONTENT_SEPARATELY = "SCROLL_MENU_CONTENT_SEPARATELY",
}

export interface DetailView {
  /**
   * Id attribute associated to DetailView
   */
  id: string;
  /**
   * Content or an array of content to be displayed
   */
  content: React.ReactChild | React.ReactChild[];
}

export interface DetailViewsProps {
  /**
   * Layout type
   * @default SCROLL_MENU_CONTENT_SEPARATELY
   */
  layoutType?: DetailViewsLayoutType;
  /**
   * Array of DetailViews
   */
  views: DetailView[];
  /**
   * List of menu GroupItems
   */
  menu: GroupItem[];
  /**
   * Menu is fixed and cannot be collapsed
   * @default false
   */
  menuFixed?: boolean;
  /**
   * Hide the menu
   * @default false
   */
  hideMenu?: boolean;
  /**
   * How will the menu be rendered upon zooming.
   * Coupled with the portal menu (COUPLED_PORTAL) or as a dropdown (DROPDOWN)
   * @default COUPLED_PORTAL
   */
  menuZoomLayout?: DetailViewsMenuZoomLayout;
  /**
   * Active view id. If no value is passed, uses first view id
   */
  activeViewId?: string;
  /**
   * Callback for menu item selection
   */
  onMenuItemSelect?: (id: string) => void;
  /**
   * Searchbox for side menu
   */
  hideSearchBox?: boolean;
  /**
   * Placeholder for searchbox
   */
  searchPlaceholder?: string;
  /**
   * Default value of Search Field
   */
  searchText?: string;
  /**
   * Callback executes when search field changed.
   */
  onSearch?: (searchText: string) => void;
  /**
   * Menu state (COLLAPSED, EXPAND)
   */
  menuState?: MenuState;
  /**
   * Callback executes when menu collapse or expand.
   */
  onMenuStateChange?: (state: MenuState) => void;
}

/**
 * The DetailsView component handles rendering left menu and content view
 */
export const DetailViews = ({
  layoutType = DetailViewsLayoutType.SCROLL_MENU_CONTENT_SEPARATELY,
  views,
  activeViewId,
  onMenuItemSelect,
  menu,
  menuFixed = false,
  hideMenu = false,
  menuZoomLayout = DetailViewsMenuZoomLayout.COUPLED_PORTAL,
  hideSearchBox = false,
  searchPlaceholder,
  searchText,
  menuState,
  onSearch,
  onMenuStateChange,
}: DetailViewsProps): JSX.Element => {
  const menuStyles: React.CSSProperties = {
    overflowY: layoutType === DetailViewsLayoutType.SCROLL_MENU_CONTENT_SEPARATELY
      ? "clip" : "hidden",
  };

  const contentStyles: React.CSSProperties = {
    flex: 1,
    paddingRight: (layoutType === DetailViewsLayoutType.SCROLL_MENU_CONTENT_SEPARATELY
      || menuZoomLayout === DetailViewsMenuZoomLayout.DROPDOWN)
      ? 25 : undefined,
    height: "100%",
    minWidth: 0,
    overflowY: (layoutType === DetailViewsLayoutType.SCROLL_MENU_CONTENT_SEPARATELY
      || menuZoomLayout === DetailViewsMenuZoomLayout.DROPDOWN)
      ? "auto" : "hidden",
  };

  const detailViewsStyles: React.CSSProperties = { height: "100%" };

  const selectedItem = views.find(item => item.id === (activeViewId || views?.[0]?.id));
  const selectedContent = selectedItem?.content;

  const internalOnMenuItemSelect = (id: string): void => {
    onMenuItemSelect?.(id);
  };

  const groups = menu.map(groupItem => ({
    heading: groupItem.heading,
    items: groupItem.items.map(menuItem => ({
      ...menuItem,
      onClick: internalOnMenuItemSelect,
    })),
  }));

  React.useLayoutEffect(() => {
    const menuComponent = MenuComponent({
      groups,
      onSearch,
      searchText,
      type: menuZoomLayout === DetailViewsMenuZoomLayout.DROPDOWN ? MenuType.DROPDOWN_MENU : MenuType.TOP_MENU,
      selectedKey: selectedItem?.id,
      rememberSelection: true,
      width: menuZoomLayout === DetailViewsMenuZoomLayout.DROPDOWN ? "100%" : undefined,
      hideSearchBox,
    });

    screenSizeManager.registerDetailViewsMenu(menuComponent, menuZoomLayout);

    return () => screenSizeManager.unregisterDetailViewsMenu(menuComponent);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeViewId]);

  return (
    <Stack horizontal tokens={hideMenu ? undefined : { childrenGap: 25 }} style={detailViewsStyles}>
      {!hideMenu && (
        <Stack.Item style={menuStyles}>
          <MenuComponent
            groups={groups}
            isCollapsible={!menuFixed}
            type={MenuType.SIDE_MENU}
            selectedKey={selectedItem?.id}
            hideSearchBox={hideSearchBox}
            placeholder={searchPlaceholder}
            searchText={searchText}
            onSearch={onSearch}
            menuState={menuState}
            onMenuStateChange={onMenuStateChange}
            rememberSelection
          />
        </Stack.Item>
      )}
      {/*
        Need to set min-width to 0 to work around an issue with fluent CommandBar component
        as per this bug report https://github.com/microsoft/fluentui/issues/12540
        The issue is present when selectedContent contains an ActionBar (which uses fluent CommandBar)
        and the viewport is resized at runtime to reduce its width.
        Even when the available width is not enough to render all action items in the bar, they end up being cut off
        instead of being rendered as overflow. This issue is the result of the container being a flex child.
        Setting the min-width to zero fixes the issue.
      */}
      <Stack.Item style={contentStyles}>
        {selectedContent}
      </Stack.Item>
    </Stack>
  );
};

type MenuComponentProps = Omit<MenuProps, "insetShadow" | "testId" | "backgroundColor">;

const MenuComponent = (
  {
    width, isCollapsible, type, groups, selectedKey, placeholder, menuState,
    searchText, hideSearchBox, rememberSelection, onSearch, onMenuStateChange,
  }: MenuComponentProps,
): JSX.Element => (
  <Menu
    width={width}
    isCollapsible={isCollapsible}
    type={type}
    groups={groups}
    selectedKey={selectedKey}
    hideSearchBox={hideSearchBox}
    placeholder={placeholder}
    menuState={menuState}
    searchText={searchText}
    onSearch={onSearch}
    onMenuStateChange={onMenuStateChange}
    rememberSelection={rememberSelection}
  />
);
