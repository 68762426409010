import * as React from "react";
import { useBoolean } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { stringifyReact } from "../../helpers/reactHelper";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { uniqueGUID } from "../../helpers/util";
import { ConfirmDialogType } from "../ConfirmDialog/ConfirmDialogTypes";
import { InternalConfirmDialog } from "../ConfirmDialog/InternalConfirmDialog";
import { FormattedString } from "../FormattedString/FormattedString";
import { FormStates, InternalFormContext, InternalFormState, ValueUpdateCallback } from "../Input/FormInternalTypes";
import { FormFieldErrors, FormValues, ValidationState } from "../Input/FormTypes";
import { AnchoredPanel } from "../Panel/AnchoredPanel";
import {
  AnchoredPanelComponent,
  AnchoredPanelProps,
  AnchoredPanelType,
  buildAnchoredPanelTestIds,
  ButtonType,
  MessageType,
  PanelFooterButton,
  PanelMessage,
} from "../Panel/AnchoredPanelTypes";
import { InputWizard } from "./InputWizard";
import { InputWizardComponent, InputWizardItem, InputWizardTabNavigation } from "./InputWizardTypes";

export interface AsyncFieldError {
  error: string;
  field: string;
}
export type AsyncGenericError = string;

export type AsyncValidationError = AsyncFieldError | AsyncGenericError;

interface RenderedAsyncFieldError extends AsyncFieldError {
  tabHeader: string;
  fieldLabel?: string;
}
type RenderedAsyncValidationError = RenderedAsyncFieldError | AsyncGenericError;

interface ValidationErrorsProps {
  renderedErrors: RenderedAsyncValidationError[];
}

// Component to render the async errors by identifying which field (by label) under which tab (by header)
// the error message references. If tab/field cannot be identified
// or the error is generic (i.e. not associated with any payload prop, then the error message is shown as is
// without any refrence to tab/field
const ValidationErrors = ({ renderedErrors }: ValidationErrorsProps): JSX.Element => {
  const items = renderedErrors.map((renderedError, ndx) => {
    const { tabHeader, fieldLabel, error } = renderedError as RenderedAsyncFieldError;
    let lineItemContent;
    if (error) {
      if (tabHeader && fieldLabel) {
        const tabField = `${tabHeader} | ${fieldLabel}`;
        lineItemContent = Messages.validation.asyncValidationFailed(tabField, error);
      } else {
        lineItemContent = error as AsyncGenericError;
      }
    } else {
      lineItemContent = renderedError as AsyncGenericError;
    }

    return (<li key={ndx.toString()} style={{ fontSize: "12px" }}><FormattedString inputText={lineItemContent} /></li>);
  });
  return (
    <ul>
      {items}
    </ul>
  );
};

interface DialogError {
  message: string;
  title?: string;
}

interface MessageBarError {
  message: string | JSX.Element;
  details?: JSX.Element;
  dismissBtn?: boolean;
}

export interface InputWizardPanelComponent extends AnchoredPanelComponent {
  /**
   * Method to retrieve the form values
   */
  getFormValues: () => FormValues | undefined;
  /**
   * Method to show an error as a confirmation dialog
   */
  showErrorDialog: (message: string, title?: string) => void;
  /**
   * Method to show an error as a message bar
   */
  showError: (message: string | JSX.Element, details?: JSX.Element, dismissBtn?: boolean) => void;
  /**
   * Method to clear the message bar error
   */
  clearError: () => void;
  /**
   * Method to allow clicking on Create button to use to resubmit after a previous API call failure
   */
  allowResubmit: () => void;
}

export interface InputWizardItemMessage {
  /**
   * Index of the item tab for which the message applies
   */
  itemNdx: number;
  /**
   * Type of the message
   */
  type: MessageType;
  /**
   * Message text to render
   */
  message: string;
  /**
   * Hide the message when item tab is first time selected then show afterwards
   */
  hideOnFirstSelect?: boolean;
}
export interface InputWizardPanelProps extends
  Pick<AnchoredPanelProps, "title" | "subTitle" | "onClose" | "message" > {
  /**
   * Component reference for the InputWizardPanel
   */
  componentRef?: (ref: InputWizardPanelComponent) => void;
  /**
   * Determines how the user can navigate between the different tabs.
   */
  tabNavigation?: InputWizardTabNavigation;
  /**
   * Input wizard items to be displayed on the InputWizardPanel
   */
  items: InputWizardItem[];
  /**
   * Message to show when item tab is selected
   */
  itemsMessages?: InputWizardItemMessage[];
  /**
   * Callback when the final review page is rendered
   */
  onRenderReview: (formValues: FormValues) => JSX.Element;
  /**
   * Callback when the review tab is unselected
   */
  onUnselectingReview?: (ndx: number) => void;
  /**
   * Callback when the form values are submitted
   * In case of failure then the call to reject should be made to
   * pass the errors (including validation errors if any) to the component
   * for rendering in the panel's message bar.
   */
  onSubmit: (
    formValues: FormValues,
    reject: (errorMessage: string, validationErrors?: AsyncValidationError[]) => void,
  ) => void;
  /**
   * Error option for failed validations
   */
  validationError?: string;
  /**
   * Callback to validate the form values
   */
  validator?: (formValues: FormValues) => FormFieldErrors[] | undefined;
  /**
   * Callback to allow the consumer to perform async validation
   * then to call the resolve callback while passing validation erros (if any)
   * In case the validation call fails then call the reject callback
   */
  onAsyncValidate?: (
    formValues: FormValues,
    resolve: (validationErrors?: AsyncValidationError[]) => void,
    reject: (errorMessage: string) => void,
  ) => void;
  /**
   * Callback when form field value(s) changes
   */
  onFormChange?: ValueUpdateCallback;
  /**
   * Will derive the data-test-id HTML atrribute value(s)
   * for internal elements from this value
   */
  testId?: string;
}

export const GROUP_REVIEW = "review";

const enum PrimaryAction {
  REVIEW = "REVIEW",
  CREATE = "CREATE",
}

const ReviewTab = ({ onRenderReview }: { onRenderReview: (formValues: FormValues) => JSX.Element }): JSX.Element => {
  const form = React.useContext<InternalFormState>(InternalFormContext);
  return onRenderReview(form.getValues());
};

const initTabNameMap = (items: InputWizardItem[]): Map<string, string> => {
  const map = new Map<string, string>();
  items.forEach(item => map.set(item.groupName, item.header));
  return map;
};

interface InputWizardTestIds extends ComponentTestIds{
  header: string;
  buttonNext: string;
  buttonPrevious: string;
  buttonPrimary: string;
  reviewPanel: string;
  reviewTab: string;
}

export const buildInputWizardTestIds = (baseTestId?: string): InputWizardTestIds => ({
  ...buildAnchoredPanelTestIds(baseTestId),
  buttonNext: buildTestId(baseTestId, "-button-next"),
  buttonPrevious: buildTestId(baseTestId, "-button-previous"),
  buttonPrimary: buildTestId(baseTestId, "-button-primary"),
  reviewPanel: buildTestId(baseTestId, "-panel-review"),
  reviewTab: buildTestId(baseTestId, "-tab-review"),
});

// Add the tab header and field label to the async errors
// to help the user identify which input needs correction
// Logic uses maps for fields and their corresponding label/group id (provided by the form)
// as well as the map for tabs IDs (i.e. group ID) and their corresponding headers (maintained by the wizard panel)
const toRenderedErrors = (
  errors: AsyncValidationError[],
  tabNameMap: Map<string, string>,
  fieldLabelMap: Map<string, string> | undefined,
  fieldGroupMap: Map<string, string> | undefined,
): RenderedAsyncValidationError[] => {
  const convertedErrors: RenderedAsyncValidationError[] = [];
  errors.forEach(errorEntry => {
    const { field, error } = errorEntry as AsyncFieldError;
    if (field) {
      const fieldLabel = fieldLabelMap?.get(field);
      const group = fieldGroupMap?.get(field);
      const tabHeader = tabNameMap.get(group || "");
      convertedErrors.push({
        field,
        error,
        tabHeader,
        fieldLabel,
      } as RenderedAsyncFieldError);
    } else {
      convertedErrors.push(errorEntry as AsyncGenericError);
    }
  });
  return convertedErrors;
};

/**
 * The InputWizardPanel component renders the Input wizard items within the
 * panel along with the footer options.
 *
 * The InputWizardPanel MUST be used inside either a LayerHost with id equal to "page" OR
 * within the BookmarkablePage component
 */

export const InputWizardPanel = (
  {
    componentRef,
    tabNavigation = InputWizardTabNavigation.FREE,
    title,
    subTitle,
    message: mainMessage,
    items,
    itemsMessages,
    onClose,
    onFormChange,
    onRenderReview,
    onUnselectingReview,
    onSubmit,
    validationError,
    validator,
    onAsyncValidate,
    testId,
  }: InputWizardPanelProps,
): JSX.Element => {
  const inputWizardTestIds = buildInputWizardTestIds(testId);

  const validationErrorRef = React.useRef<string | undefined>(validationError);

  const [primaryAction, setPrimaryAction] = React.useState<PrimaryAction>(PrimaryAction.REVIEW);
  const [currentTab, setCurrentTab] = React.useState<number>(0);

  // Tracking fields/groups states based on local validation (not async)
  const [formStates, setFormStates] = React.useState<FormStates>({} as FormStates);

  // **** [BEGIN] Tracking async validation ****

  // Flag for tracking the validation state after async validation
  const [asyncValidationState, setAsyncValidationState] = React.useState<ValidationState>(
    onAsyncValidate ? ValidationState.UNKNOWN : ValidationState.VALID,
  );
  // Flag to determine if async validation should be run upon clicking on Review
  const [doAsnycValidation, setDoAsyncValidation] = React.useState<boolean>(!!onAsyncValidate);
  // Flag set while async validation is running
  // (use ref instead of state to avoid onAsyncValidate callback closure to lock stalled value)
  const asyncValidatingRef = React.useRef<boolean>(false);
  // State to force rerender cycle since change of ref will not provoke it
  const [, forceRender] = React.useState<string>(uniqueGUID());
  // Methods for accessing the async validating flag
  const isAsyncValidating = (): boolean => asyncValidatingRef.current;
  const setAsyncValidating = (enable: boolean): void => {
    asyncValidatingRef.current = enable;
    forceRender(uniqueGUID()); // make sure to force a rerender cycle
  };
  // Errors retunred from async validtaion
  const [asyncErrors, setAsyncErrors] = React.useState<AsyncValidationError[] | undefined>(undefined);

  // **** [END] Tracking async validation ****

  const [dialogError, setDialogError] = React.useState<DialogError | undefined>(undefined);
  const [messageBarError, setMessageBarError] = React.useState<MessageBarError | undefined>(undefined);
  const [tabMessage, setTabMessage] = React.useState<InputWizardItemMessage | undefined>(undefined);
  const tabVisited = React.useRef<boolean[]>(new Array(items.length).fill(false));
  const [isCreateClicked, { setTrue: setCreateClicked, setFalse: resetCreateClicked }] = useBoolean(false);
  const [nextClicked, setNextClicked] = React.useState<string | undefined>(undefined);

  // Map for holding the tab's ID and its corresponding header
  // for use in identifying which async error corresponds to which tab
  const tabNameMap = React.useRef<Map<string, string>>(initTabNameMap(items));

  const thisRef = React.useRef<InputWizardPanelComponent>();

  const panelRef = React.useRef<AnchoredPanelComponent>();
  const setPanelRef = (panel: AnchoredPanelComponent): void => {
    panelRef.current = panel;
  };

  const wizardRef = React.useRef<InputWizardComponent>();
  const setWizardRef = (wizard: InputWizardComponent): void => {
    wizardRef.current = wizard;
  };

  React.useEffect(() => {
    if (componentRef) {
      thisRef.current = {
        showErrorDialog: (message: string, dialogTitle?: string) => {
          setDialogError({ message, title: dialogTitle });
        },
        showError: (message: string | JSX.Element, details?: JSX.Element, dismissBtn?: boolean) => {
          setMessageBarError({ message, details, dismissBtn });
        },
        clearError: () => {
          setMessageBarError(undefined);
        },
        allowResubmit: () => resetCreateClicked(),
        toggleInProgress: (on: boolean) => panelRef.current?.toggleInProgress(on),
        open: () => panelRef.current?.open(),
        close: () => panelRef.current?.close(),
        getFormValues: () => wizardRef.current?.getFormValues(),
      } as InputWizardPanelComponent;
      componentRef(thisRef.current);
    }
  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (currentTab === wizardItems.length - 1) {
      setPrimaryAction(PrimaryAction.CREATE);
    } else { setPrimaryAction(PrimaryAction.REVIEW); }

    const itemMessage = itemsMessages?.find(entry => entry.itemNdx === currentTab);
    if (itemMessage && itemMessage.hideOnFirstSelect) {
      if (currentTab < items.length && tabVisited.current[currentTab]) {
        setTabMessage(itemMessage);
      } else {
        setTabMessage(undefined);
        tabVisited.current[currentTab] = true;
      }
    } else {
      setTabMessage(itemMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const onReview = (): void => {
    wizardRef.current?.enableValidation();
  };

  const onCreate = (): void => {
    if (!isCreateClicked) {
      setCreateClicked();
      wizardRef.current?.submit();
    }
  };

  const onFormStatesUpdate = (states: FormStates): void => {
    setFormStates(states);
  };

  const onValueUpdate = (formValues: FormValues, fieldValue: unknown, field: string, group?: string): void => {
    // If any field is touched then set the async valdaition to be run upon next review
    if (onAsyncValidate) {
      setDoAsyncValidation(true);
    }
    onFormChange?.(formValues, fieldValue, field, group);
  };

  const getInvalidTabHeaders = () : string[] => Object.entries(formStates.groups)
    .filter(group => group[1] === "INVALID")
    .map(group => tabNameMap.current.get(group[0]) || "");

  const getValidationMessage = (): string => {
    const invalidTabHeaders = getInvalidTabHeaders();
    return invalidTabHeaders.length === 1
      ? Messages.validation.validationFailedWithTabHeader(invalidTabHeaders.toString())
      : Messages.validation.validationFailedWithTabHeaders(invalidTabHeaders.join(", "));
  };

  React.useEffect(() => {
    if (wizardRef.current?.isValidationEnabled() && onAsyncValidate) {
      switch (primaryAction) {
        case PrimaryAction.CREATE:
          // Run async validation after local validation is valid
          if (formStates.overall !== ValidationState.INVALID && doAsnycValidation) {
            // Mark async validation running to show the IN PROGRESS message
            setAsyncValidating(true);
            // Reset async validation state to UNKNOWN to hide any errors/success from previous valdiation
            setAsyncValidationState(ValidationState.UNKNOWN);
            // Call the asyn validation callback with the current form values and a resolve callback for the consumer
            // to invoke with the results once the async validation call is completed
            onAsyncValidate(
              wizardRef.current?.getFormValues() || {},
              validationErrors => {
                // Check that the user did not leave the review tab by checking that async validating flag is still true
                // otherwise ignore any results coming from a previous call (short circuit the async validation call)
                if (isAsyncValidating()) {
                  // Set up the aync state to INVALID if errors are returned in the resolve callback and VALID otherwise
                  setAsyncValidationState(validationErrors ? ValidationState.INVALID : ValidationState.VALID);
                  // Set the returned errors for displaying in the panel's message bar
                  setAsyncErrors(validationErrors);
                  // Reset any error displayed in the panel's message bar from a previous failed call
                  validationErrorRef.current = undefined;
                  // Turn off the async validating flag to hide the IN PROGRESS message
                  setAsyncValidating(false);
                  // Turn off the flag for running async validation on upon next review
                  setDoAsyncValidation(false);
                }
              },
              errorMessage => {
                // Check that the user did not leave the review tab by checking that async validating flag is still true
                // otherwise ignore any results coming from a previous call (short circuit the async validation call)
                if (isAsyncValidating()) {
                  // Set up the aync state to INVALID
                  setAsyncValidationState(ValidationState.INVALID);
                  // Set the returned error for displaying in the panel's message bar
                  validationErrorRef.current = errorMessage;
                  // Turn off the async validating flag to hide the IN PROGRESS message
                  setAsyncValidating(false);
                  // Turn off the flag for running async validation on upon next review
                  setDoAsyncValidation(false);
                }
              },
            );
          }
          break;
        case PrimaryAction.REVIEW:
        default:
          // If user moves away from review tab, turn off the async validating flag to hide the IN PROGRESS message
          // as well as to ignore any results from any async validation call that is still in progress
          setAsyncValidating(false);
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStates.overall, primaryAction]);

  React.useEffect(() => {
    if (nextClicked) {
      const group = wizardItems[currentTab].groupName;
      if (!wizardRef.current?.isValidationEnabled(group)) {
        wizardRef.current?.enableValidation(group, true);
        if (!wizardRef.current?.hasValidation(group)) {
          wizardRef.current?.next();
          setNextClicked(undefined);
        }
      } else if (wizardRef.current?.isValidationEnabled(group)) {
        if ((formStates.groups?.[group] === ValidationState.VALID || !formStates.groups?.[group])) {
          wizardRef.current?.next();
          setNextClicked(undefined);
        } else if (formStates.groups?.[group] === ValidationState.INVALID) {
          setNextClicked(undefined);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStates, nextClicked]);

  const onPrimaryButtonClicked = (): void => {
    if (primaryAction === PrimaryAction.REVIEW) {
      onReview();
    }
    if (primaryAction === PrimaryAction.CREATE) {
      onCreate();
    }
  };

  const onPreviousButtonClicked = (): void => {
    switch (tabNavigation) {
      case InputWizardTabNavigation.SEQUENTIAL:
      case InputWizardTabNavigation.FREE:
      default:
        wizardRef.current?.previous();
        break;
    }
  };

  const onNextButtonClicked = (): void => {
    switch (tabNavigation) {
      case InputWizardTabNavigation.SEQUENTIAL:
        setNextClicked(uniqueGUID());
        break;
      case InputWizardTabNavigation.FREE:
      default:
        wizardRef.current?.next();
        break;
    }
  };

  const wizardItems = React.useMemo(
    () => {
      const wItems = items.map(item => {
        const wizardItem: InputWizardItem = {
          ...item,
          ...{ onSelected: (ndx:number) => { setCurrentTab(ndx); item.onSelected?.(ndx); } },
        };
        return wizardItem;
      });
      // Add Review and create tab
      const reviewItem = {
        header: Messages.actions.reviewCreate(),
        groupName: GROUP_REVIEW,
        content: (
          // Only show review info if local and async validations are VALID
          (formStates.overall === ValidationState.VALID
            && asyncValidationState === ValidationState.VALID
            && onRenderReview)
            ? <ReviewTab onRenderReview={onRenderReview} />
            : <div />
        ),
        testId: inputWizardTestIds.reviewTab,
        onSelected: (ndx: number) => { setCurrentTab(ndx); onReview(); },
        onUnselecting: (ndx: number) => { onUnselectingReview?.(ndx); },
      } as InputWizardItem;
      wItems.push(reviewItem);

      return wItems;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifyReact(items), panelRef.current, formStates.overall, asyncValidationState],
  );

  const getNextTabHeader = (): string => (
    currentTab + 1 < wizardItems.length ? `: ${wizardItems[currentTab + 1].header}` : ""
  );

  const getMessage = (): PanelMessage | undefined => {
    // If REVIEW tab is selected...
    // Display IN PROGESS message if aync validating flag is on (i.e. Async validation is on going)
    if (currentTab === wizardItems.length - 1 && isAsyncValidating()) {
      return {
        type: MessageType.IN_PROGRESS,
        text: Messages.validation.validationInProgress(),
      };
    }

    // If CREATE has been clicked and the call fails
    // then show the error in the message panel
    // and if there are validation errors show them as details
    if (tabNavigation === InputWizardTabNavigation.FREE
      && validationErrorRef.current
    ) {
      const panelMessage = {
        type: MessageType.ERROR,
        text: validationErrorRef.current,
      } as PanelMessage;
      if (asyncErrors) {
        panelMessage.details = (
          <ValidationErrors
            renderedErrors={toRenderedErrors(
              asyncErrors || [],
              tabNameMap.current,
              wizardRef.current?.getFormFieldLabelMap(),
              wizardRef.current?.getFormFieldGroupMap(),
            )}
          />
        );
      }
      return panelMessage;
    }

    // For async validation errors, they should always be shown unless
    // the review tab is selected and the async validation is running then
    // show the IN PROGRESS message instead (logic will fall through to the IN PROGRESS case)
    if (asyncValidationState === ValidationState.INVALID
        && !(doAsnycValidation && currentTab === wizardItems.length - 1)) {
      // Show validation errors returned from async validation resolve callback
      if (asyncErrors) {
        return {
          type: MessageType.ERROR,
          text: Messages.validation.validationFailed(),
          details: (
            <ValidationErrors
              renderedErrors={toRenderedErrors(
                asyncErrors || [],
                tabNameMap.current,
                wizardRef.current?.getFormFieldLabelMap(),
                wizardRef.current?.getFormFieldGroupMap(),
              )}
            />
          ),
        };
      }
      // Otherwise show error message returned from async reject callback
      return {
        type: MessageType.ERROR,
        text: validationErrorRef.current || Messages.validation.validationFailed(),
      };
    }
    // If REVIEW tab is selected...
    if (currentTab === wizardItems.length - 1) {
      // Otherwise...
      switch (formStates.overall) {
        // Show local validation error message in the panel's message bar
        case ValidationState.INVALID:
          return {
            type: MessageType.ERROR,
            text: validationErrorRef.current || getValidationMessage(),
          };
        // Only if local and async validations are both VALID then
        // show the "validation has passed" message in the panel's message bar
        case ValidationState.VALID:
          switch (asyncValidationState) {
            case ValidationState.VALID:
              return {
                type: MessageType.SUCCESS,
                text: Messages.validation.validationPassed(),
              };
            default:
              return undefined;
          }
        default:
          return undefined;
      }
    }

    return undefined;
  };

  const getMessageBarError = (): PanelMessage | undefined => {
    if (messageBarError) {
      return {
        type: MessageType.ERROR,
        text: messageBarError.message,
        details: (<div style={{ fontSize: "12px" }}>{messageBarError.details}</div>),
        onDismiss: messageBarError.dismissBtn ? () => { setMessageBarError(undefined); } : undefined,
      };
    }
    return undefined;
  };

  const getTabMessageBar = (): PanelMessage | undefined => {
    if (tabMessage) {
      return {
        type: tabMessage.type,
        text: tabMessage.message,
      };
    }
    return undefined;
  };

  const getPrimaryButton = (): PanelFooterButton => {
    let label;
    let disabled;
    switch (tabNavigation) {
      case InputWizardTabNavigation.SEQUENTIAL:
        label = Messages.actions.create();
        disabled = currentTab < wizardItems.length - 1;
        break;
      case InputWizardTabNavigation.FREE:
      default:
        label = primaryAction === PrimaryAction.REVIEW ? Messages.actions.reviewCreate() : Messages.actions.create();
        // Disable if either local or async validation fails
        disabled = primaryAction === PrimaryAction.CREATE
          && (formStates.overall !== ValidationState.VALID || asyncValidationState !== ValidationState.VALID);
        break;
    }
    return {
      type: ButtonType.PRIMARY,
      label,
      disabled,
      onClick: onPrimaryButtonClicked,
      style: { margin: "0px 55px 5px 0px", height: "24px" },
      testId: inputWizardTestIds.buttonPrimary,
    };
  };

  const getPreviousButton = (): PanelFooterButton => {
    let label;
    let disabled;
    switch (tabNavigation) {
      case InputWizardTabNavigation.SEQUENTIAL:
        label = Messages.actions.previous();
        disabled = currentTab === 0;
        break;
      case InputWizardTabNavigation.FREE:
      default:
        label = `< ${Messages.actions.previous()}`;
        disabled = currentTab === 0;
        break;
    }
    return {
      type: ButtonType.DEFAULT,
      label,
      disabled,
      onClick: onPreviousButtonClicked,
      style: { margin: "0px 10px 5px 0px", height: "24px" },
      testId: inputWizardTestIds.buttonPrevious,
    };
  };

  const getNextButton = (): PanelFooterButton => {
    let label;
    let disabled;
    switch (tabNavigation) {
      case InputWizardTabNavigation.SEQUENTIAL:
        label = Messages.actions.next();
        disabled = currentTab === wizardItems.length - 1;
        break;
      case InputWizardTabNavigation.FREE:
      default:
        label = `${Messages.actions.next()}${getNextTabHeader()} >`;
        disabled = currentTab === wizardItems.length - 1;
        break;
    }
    return {
      type: ButtonType.DEFAULT,
      label,
      disabled,
      onClick: onNextButtonClicked,
      style: { margin: "0px 20px 5px 0px", height: "24px" },
      testId: inputWizardTestIds.buttonNext,
    };
  };

  const internalOnSubmit = (formValues: FormValues): void => {
    onSubmit(
      formValues,
      (errorMessage, validationErrors) => {
        // Set the returned error for displaying in the panel's message bar
        validationErrorRef.current = errorMessage;
        // Set the returned validation errors (if any) for displaying in the panel's message bar
        setAsyncErrors(validationErrors);
      },
    );
  };

  const getConfirmDialogTargetId = (): string => panelRef.current?.getInternalIds().panelNavigationId || "";

  return (
    <AnchoredPanel
      componentRef={setPanelRef}
      title={title}
      subTitle={subTitle}
      type={AnchoredPanelType.FULL_WIDTH}
      preventCloseOnEsc
      onClose={onClose}
      message={mainMessage || getMessage() || getMessageBarError() || getTabMessageBar()}
      testId={inputWizardTestIds.component}
      isOpen
      footer={tabNavigation === InputWizardTabNavigation.SEQUENTIAL
        ? [getPreviousButton(), getNextButton(), getPrimaryButton()]
        : [getPrimaryButton(), getPreviousButton(), getNextButton()]}
    >
      {dialogError && (
        <InternalConfirmDialog
          type={ConfirmDialogType.COMMAND_BAR}
          targetId={getConfirmDialogTargetId()}
          useConfirmBtnOnly
          confirmBtnText={Messages.common.ok()}
          title={dialogError.title}
          description={dialogError.message}
          styles={{ dialogWidth: "calc(100vw - 27px)" }}
          onConfirm={() => setDialogError(undefined)}
        />
      )}
      <InputWizard
        key="wizard"
        componentRef={setWizardRef}
        items={wizardItems}
        tabNavigation={tabNavigation}
        onSubmit={internalOnSubmit}
        onStatesUpdate={onFormStatesUpdate}
        onValueUpdate={onValueUpdate}
        validator={validator}
      />
    </AnchoredPanel>
  );
};
