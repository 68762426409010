import * as React from "react";
import { FormInputGroupLayout } from "./FormInputGroup";
import { renderErrorMessages } from "./InputCommon";

export interface ErrorProps {
  /**
   * The errors to display
   */
  errors: string[] | undefined;
  /**
   * ID used to retrieve the element during testing
   */
  testId?: string;
}

/**
 * Display validation errors
 */
export const Errors = ({ errors, testId }: ErrorProps): JSX.Element => {
  if (!errors?.length) return <div />;
  return renderErrorMessages(errors, FormInputGroupLayout.COMPACT, testId);
};
