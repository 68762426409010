import * as Messages from "../codegen/Messages";

export enum DurationFormat { SYMBOL, SHORT, LONG }

const SEC_IN_MSECS = 1000;
const MIN_IN_MSECS = 60 * SEC_IN_MSECS;
const HOUR_IN_MSECS = 60 * MIN_IN_MSECS;
const DAY_IN_MSECS = 24 * HOUR_IN_MSECS;

export class DurationParts {
  public days = 0;

  public hours = 0;

  public mins = 0;

  public secs = 0;

  public msecs = 0;

  constructor(durationMs: number) {
    this.setDurationParts(durationMs);
  }

  private setDurationParts(durationMs: number): void {
    this.days = Math.floor(
      durationMs / DAY_IN_MSECS,
    );
    this.hours = Math.floor((
      durationMs - this.days * DAY_IN_MSECS
    ) / HOUR_IN_MSECS);
    this.mins = Math.floor((
      durationMs - this.days * DAY_IN_MSECS - this.hours * HOUR_IN_MSECS
    ) / MIN_IN_MSECS);
    this.secs = Math.floor((
      durationMs - this.days * DAY_IN_MSECS - this.hours * HOUR_IN_MSECS - this.mins * MIN_IN_MSECS
    ) / SEC_IN_MSECS);
    this.msecs = Math.floor(
      durationMs - this.days * DAY_IN_MSECS - this.hours * HOUR_IN_MSECS - this.mins * MIN_IN_MSECS
      - this.secs * SEC_IN_MSECS,
    );

    if (this.msecs === 1000) {
      this.secs++;
      this.msecs = 0;
    }
    if (this.secs === 60) {
      this.mins++;
      this.secs = 0;
    }
    if (this.mins === 60) {
      this.hours++;
      this.mins = 0;
    }
    if (this.hours === 24) {
      this.days++;
      this.hours = 0;
    }
  }
}

export function toDurationStr(durationMs: number, format?: DurationFormat): string {
  const durationParts = new DurationParts(durationMs);

  let daysSuffix = Messages.duration.daysSymbol();
  let hoursSuffix = Messages.duration.hoursSymbol();
  let minutesSuffix = Messages.duration.minutesSymbol();
  let secondsSuffix = Messages.duration.secondsSymbol();
  let millisecondsSuffix = Messages.duration.millisecondsSymbol();

  switch (format) {
    case DurationFormat.SHORT:
      daysSuffix = Messages.duration.daysShort();
      hoursSuffix = Messages.duration.hoursShort();
      minutesSuffix = Messages.duration.minutesShort();
      secondsSuffix = Messages.duration.secondsShort();
      millisecondsSuffix = Messages.duration.millisecondsShort();
      break;

    case DurationFormat.LONG:
      daysSuffix = Messages.duration.daysLong();
      hoursSuffix = Messages.duration.hoursLong();
      minutesSuffix = Messages.duration.minutesLong();
      secondsSuffix = Messages.duration.secondsLong();
      millisecondsSuffix = Messages.duration.millisecondsLong();
      break;

    // no default
  }

  if (durationParts.days > 0) {
    return (durationParts.days > 0 ? `${durationParts.days} ${daysSuffix} ` : "")
      + (durationParts.hours > 0 ? `${durationParts.hours} ${hoursSuffix} ` : "")
      + (durationParts.mins > 0 ? `${durationParts.mins} ${minutesSuffix}` : "");
  // eslint-disable-next-line no-else-return
  } else if (durationParts.hours > 0) {
    return (durationParts.hours > 0 ? `${durationParts.hours} ${hoursSuffix} ` : "")
      + (durationParts.mins > 0 ? `${durationParts.mins} ${minutesSuffix} ` : "")
      + (durationParts.secs > 0 ? `${durationParts.secs} ${secondsSuffix}` : "");
  } else {
    return (durationParts.mins > 0 ? `${durationParts.mins} ${minutesSuffix} ` : "")
      + (durationParts.secs > 0 ? `${durationParts.secs} ${secondsSuffix} ` : "")
      + (durationParts.msecs > 0 ? `${durationParts.msecs} ${millisecondsSuffix}` : "");
  }
}

export const getFormattedDateTime = (value: Date): string => (new Date(value)).toLocaleString();

export const formatLocalizedTime = (
  locale: string,
  date?: Date,
  options?: Intl.DateTimeFormatOptions,
): string => (!date ? "" : new Date(date)
  .toLocaleTimeString(
    locale,
    options,
  ));

export const formatLocalizedDate = (
  locale: string,
  date?: Date,
  options?: Intl.DateTimeFormatOptions,
): string => (!date ? "" : new Date(date)
  .toLocaleDateString(
    locale,
    options,
  ));
