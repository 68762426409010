import { init as messagesInit } from "./codegen/Messages";

export function o4aMessagesInit(defaultLanguage?: string): void {
  setDocumentLanguage(defaultLanguage || "en");
  messagesInit(defaultLanguage);
}
function setDocumentLanguage(language: string): void {
  if (!!document && document.documentElement && document.documentElement.lang !== language) {
    document.documentElement.lang = language;
  }
}
