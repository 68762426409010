// eslint-disable-next-line max-classes-per-file
import { FormInputGroupLayout } from "../components/Input/FormInputGroup";
import { ListingPaginationLayoutType } from "../components/Listing/ListingTypes";
import { MetaItemGroupLayoutType } from "../components/MetaItems/MetaItemTypes";
import { AnchoredPanelFooterType, AnchoredPanelHeaderLayoutType } from "../components/Panel/AnchoredPanelTypes";
import { MainAnchorId } from "../constants/uiConstants";
import {
  AnchoredPanelFooterTypeConfig,
  AnchoredPanelHeaderLayoutTypeConfig,
  AppLayoutConfig,
  InputWizardContentLayoutTypeConfig,
  MetaItemSectionLayoutConfig,
  MetaItemSectionLayoutTypeConfig,
} from "./LayoutManagerTypes";

/**
 * App layout manager
 */
class AppLayoutManagerClass implements AppLayoutConfig {
  public mainAnchorId = MainAnchorId;

  public setMainAnchorId = (mainAnchorId: string): void => {
    this.mainAnchorId = mainAnchorId;
  };
}

export const appLayoutManager = new AppLayoutManagerClass();

/**
 * MetaItemSection layout manager
 */
export interface MetaItemSectionLayoutTypeInternalConfig {
  layoutType: MetaItemGroupLayoutType;
  minWidth?: number;
  maxWidth?: number;
}

export interface MetaItemSectionLayoutManager {
  getLayoutType: (currentWidth: number) => MetaItemGroupLayoutType;
}

class MetaItemSectionLayoutManagerClass implements MetaItemSectionLayoutManager, MetaItemSectionLayoutConfig {
  private static SINGLE_COLUMN_COMPACT_DEFAULT_MAX_WIDTH = 496;

  private static SINGLE_COLUMN_DEFAULT_MAX_WIDTH = 656;

  private static MULTI_COLUMNS_COMPACT_DEFAULT_MAX_WIDTH = 1040;

  private layoutTypeConfigs: MetaItemSectionLayoutTypeInternalConfig[] = [];

  constructor() {
    this.layoutTypeConfigs.push({
      layoutType: MetaItemGroupLayoutType.MULTI_COLUMNS,
      minWidth: MetaItemSectionLayoutManagerClass.MULTI_COLUMNS_COMPACT_DEFAULT_MAX_WIDTH,
      maxWidth: Number.MAX_SAFE_INTEGER,
    });
    this.layoutTypeConfigs.push({
      layoutType: MetaItemGroupLayoutType.MULTI_COLUMNS_COMPACT,
      minWidth: MetaItemSectionLayoutManagerClass.SINGLE_COLUMN_DEFAULT_MAX_WIDTH,
      maxWidth: MetaItemSectionLayoutManagerClass.MULTI_COLUMNS_COMPACT_DEFAULT_MAX_WIDTH,
    });
    this.layoutTypeConfigs.push({
      layoutType: MetaItemGroupLayoutType.SINGLE_COLUMN,
      minWidth: MetaItemSectionLayoutManagerClass.SINGLE_COLUMN_COMPACT_DEFAULT_MAX_WIDTH,
      maxWidth: MetaItemSectionLayoutManagerClass.SINGLE_COLUMN_DEFAULT_MAX_WIDTH,
    });
    this.layoutTypeConfigs.push({
      layoutType: MetaItemGroupLayoutType.SINGLE_COLUMN_COMPACT,
      minWidth: 0,
      maxWidth: MetaItemSectionLayoutManagerClass.SINGLE_COLUMN_COMPACT_DEFAULT_MAX_WIDTH,
    });
  }

  public getLayoutType = (currentWidth: number): MetaItemGroupLayoutType => {
    const layoutConfig = this.layoutTypeConfigs.find(config => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const found = currentWidth > config.minWidth! && currentWidth <= config.maxWidth!;
      return found ? config : undefined;
    });
    return layoutConfig?.layoutType || MetaItemGroupLayoutType.MULTI_COLUMNS;
  };

  public setLayoutTypeMaxWidth = (layoutType: MetaItemSectionLayoutTypeConfig, maxWidth: number): void => {
    const configNdx = this.layoutTypeConfigs.findIndex(config => config.layoutType === layoutType);
    const config = this.layoutTypeConfigs[configNdx];
    config.maxWidth = maxWidth;
    const prevConfig = this.layoutTypeConfigs[configNdx - 1];
    prevConfig.minWidth = maxWidth;
  };
}

export const metaItemSectionLayoutManager = new MetaItemSectionLayoutManagerClass();

/**
 * AnchoredPanel layout manager
 */

export interface AnchoredPanelHeaderLayoutTypeInternalConfig {
  headerLayoutType: AnchoredPanelHeaderLayoutType;
  minHeight?: number;
  maxHeight?: number;
}

export interface AnchoredPanelFooterTypeInternalConfig {
  footerType: AnchoredPanelFooterType;
  minHeight?: number;
  maxHeight?: number;
}

export interface AnchoredPanelLayoutManager {
  getHeaderLayoutType: (currentHeight: number) => AnchoredPanelHeaderLayoutType;
  getFooterType: (currentHeight: number) => AnchoredPanelFooterType;
}

class AnchoredPanelLayoutManagerClass implements AnchoredPanelLayoutManager {
  private static HEADER_FIXED_AT_TOP_MIN_HEIGHT = 490;

  private static FOOTER_FIXED_AT_BOTTOM_MIN_HEIGHT = 490;

  private headerLayoutTypeConfigs: AnchoredPanelHeaderLayoutTypeInternalConfig[] = [];

  private footerTypeConfigs: AnchoredPanelFooterTypeInternalConfig[] = [];

  constructor() {
    this.headerLayoutTypeConfigs.push({
      headerLayoutType: AnchoredPanelHeaderLayoutType.FIXED_AT_TOP,
      minHeight: AnchoredPanelLayoutManagerClass.HEADER_FIXED_AT_TOP_MIN_HEIGHT,
      maxHeight: Number.MAX_SAFE_INTEGER,
    });
    this.headerLayoutTypeConfigs.push({
      headerLayoutType: AnchoredPanelHeaderLayoutType.SCROLL_WITH_CONTENT,
      minHeight: 0,
      maxHeight: AnchoredPanelLayoutManagerClass.HEADER_FIXED_AT_TOP_MIN_HEIGHT,
    });

    this.footerTypeConfigs.push({
      footerType: AnchoredPanelFooterType.FIXED_AT_BOTTOM,
      minHeight: AnchoredPanelLayoutManagerClass.FOOTER_FIXED_AT_BOTTOM_MIN_HEIGHT,
      maxHeight: Number.MAX_SAFE_INTEGER,
    });
    this.footerTypeConfigs.push({
      footerType: AnchoredPanelFooterType.BELOW_CONTENT,
      minHeight: 0,
      maxHeight: AnchoredPanelLayoutManagerClass.FOOTER_FIXED_AT_BOTTOM_MIN_HEIGHT,
    });
  }

  public getHeaderLayoutType = (currentHeight: number): AnchoredPanelHeaderLayoutType => {
    const headerLayoutTypeConfig = this.headerLayoutTypeConfigs.find(config => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const found = currentHeight > config.minHeight! && currentHeight <= config.maxHeight!;
      return found ? config : undefined;
    });
    return headerLayoutTypeConfig?.headerLayoutType || AnchoredPanelHeaderLayoutType.FIXED_AT_TOP;
  };

  public setHeaderLayoutTypeMinHeight = (
    headerLayoutType: AnchoredPanelHeaderLayoutTypeConfig,
    minHeight: number,
  ): void => {
    const configNdx = this.headerLayoutTypeConfigs.findIndex(config => config.headerLayoutType === headerLayoutType);
    const config = this.headerLayoutTypeConfigs[configNdx];
    config.minHeight = minHeight;
    const nextConfig = this.headerLayoutTypeConfigs[configNdx + 1];
    nextConfig.maxHeight = minHeight;
  };

  public getFooterType = (currentHeight: number): AnchoredPanelFooterType => {
    const footerTypeConfig = this.footerTypeConfigs.find(config => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const found = currentHeight > config.minHeight! && currentHeight <= config.maxHeight!;
      return found ? config : undefined;
    });
    return footerTypeConfig?.footerType || AnchoredPanelFooterType.FIXED_AT_BOTTOM;
  };

  public setFooterTypeMinHeight = (footerType: AnchoredPanelFooterTypeConfig, minHeight: number): void => {
    const configNdx = this.footerTypeConfigs.findIndex(config => config.footerType === footerType);
    const config = this.footerTypeConfigs[configNdx];
    config.minHeight = minHeight;
    const nextConfig = this.footerTypeConfigs[configNdx + 1];
    nextConfig.maxHeight = minHeight;
  };
}

export const anchoredPanelLayoutManager = new AnchoredPanelLayoutManagerClass();

/**
 * InputWizard layout manager
 */
export interface InputWizardContentLayoutTypeInternalConfig {
  contentLayoutType: FormInputGroupLayout;
  minWidth?: number;
  maxWidth?: number;
}

export interface InputWizardLayoutManager {
  getContentLayoutType: (currentWidth: number) => FormInputGroupLayout;
}

class InputWizardLayoutManagerClass implements InputWizardLayoutManager {
  private static CONTENT_WIDE_MIN_WIDTH = 480;

  private contentLayoutTypeConfigs: InputWizardContentLayoutTypeInternalConfig[] = [];

  constructor() {
    this.contentLayoutTypeConfigs.push({
      contentLayoutType: FormInputGroupLayout.WIDE,
      minWidth: InputWizardLayoutManagerClass.CONTENT_WIDE_MIN_WIDTH,
      maxWidth: Number.MAX_SAFE_INTEGER,
    });
    this.contentLayoutTypeConfigs.push({
      contentLayoutType: FormInputGroupLayout.COMPACT,
      minWidth: 0,
      maxWidth: InputWizardLayoutManagerClass.CONTENT_WIDE_MIN_WIDTH,
    });
  }

  public getContentLayoutType = (currentWidth: number): FormInputGroupLayout => {
    const contentLayoutTypeConfig = this.contentLayoutTypeConfigs.find(config => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const found = currentWidth > config.minWidth! && currentWidth <= config.maxWidth!;
      return found ? config : undefined;
    });
    return contentLayoutTypeConfig?.contentLayoutType || FormInputGroupLayout.WIDE;
  };

  public setContentLayoutTypeMinWidth = (
    contentLayoutType: InputWizardContentLayoutTypeConfig,
    minWidth: number,
  ): void => {
    const configNdx = this.contentLayoutTypeConfigs.findIndex(config => config.contentLayoutType === contentLayoutType);
    const config = this.contentLayoutTypeConfigs[configNdx];
    config.minWidth = minWidth;
    const nextConfig = this.contentLayoutTypeConfigs[configNdx + 1];
    nextConfig.maxWidth = minWidth;
  };
}

export const inputWizardLayoutManager = new InputWizardLayoutManagerClass();

/**
 *  Listing layout manager
 */

export interface ListingPaginationLayoutTypeInternalConfig {
  paginationType: ListingPaginationLayoutType;
  minWidth: number;
  maxWidth: number;
}

export interface ListingLayoutManager {
  getColumnMinWidth: () => number;
  getPaginationType: (currentWidth: number) => ListingPaginationLayoutType;
}

class ListingLayoutManagerClass implements ListingLayoutManager {
  private static COLUMN_MIN_WIDTH = 100;

  private static PAGINATION_WIDE_MIN_WIDTH = 880;

  private paginationTypeConfigs: ListingPaginationLayoutTypeInternalConfig[] = [];

  constructor() {
    this.paginationTypeConfigs.push({
      paginationType: ListingPaginationLayoutType.WIDE,
      minWidth: ListingLayoutManagerClass.PAGINATION_WIDE_MIN_WIDTH,
      maxWidth: Number.MAX_SAFE_INTEGER,
    });
    this.paginationTypeConfigs.push({
      paginationType: ListingPaginationLayoutType.COMPACT,
      minWidth: 0,
      maxWidth: ListingLayoutManagerClass.PAGINATION_WIDE_MIN_WIDTH,
    });
  }

  public getColumnMinWidth = (): number => ListingLayoutManagerClass.COLUMN_MIN_WIDTH;

  public setColumnMinWidth = (minWidth: number): void => {
    ListingLayoutManagerClass.COLUMN_MIN_WIDTH = minWidth;
  };

  public getPaginationType = (currentWidth: number): ListingPaginationLayoutType => {
    const paginationTypeConfig = this.paginationTypeConfigs.find(config => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const found = currentWidth > config.minWidth! && currentWidth <= config.maxWidth!;
      return found ? config : undefined;
    });
    return paginationTypeConfig?.paginationType || ListingPaginationLayoutType.WIDE;
  };

  public setPaginationTypeMinWidth = (paginationType: ListingPaginationLayoutType, minWidth: number): void => {
    const configNdx = this.paginationTypeConfigs.findIndex(config => config.paginationType === paginationType);
    const config = this.paginationTypeConfigs[configNdx];
    config.minWidth = minWidth;
    const nextConfig = this.paginationTypeConfigs[configNdx + 1];
    nextConfig.minWidth = minWidth;
  };
}

export const listingLayoutManager = new ListingLayoutManagerClass();
