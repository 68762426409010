import * as React from "react";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { cidrRange, cidrValid, isValidAddress } from "../../helpers/networkHelper";
import {
  FormInputGroupLayout,
  FormInputGroupLayoutContext,
  FormInputGroupLayoutContextProvider,
} from "./FormInputGroup";
import { InternalFormContext } from "./FormInternalTypes";
import { ReadOnlyText } from "./ReadOnlyText";
import { buildTextInputTestIds, TextInput, TextInputProps, TextInputTestIds } from "./TextInput";

const getAddressRange = (value?: string): string => {
  let addressRange = Messages.common.addressesCount("0");

  if (value) {
    if (cidrValid(value)) {
      const [startingIp, endingIp] = cidrRange(value);
      addressRange = `${startingIp} - ${endingIp}`;
    } else if (isValidAddress(value)) {
      addressRange = value;
    }
  }

  return addressRange;
};

enum Fields {
  Addresses = "addresses",
}

export enum FieldTestIds {
  Addresses = "addresses",
}

export interface NetworkAddressInputTestIds {
  address: TextInputTestIds;
  addresses: TextInputTestIds;
}

export const buildNetworkAddressInputTestIds = (testId?: string): NetworkAddressInputTestIds => ({
  address: buildTextInputTestIds(testId),
  addresses: buildTextInputTestIds(`${testId}-addresses`),
});

export interface NetworkAddressInputProps extends TextInputProps {
  /**
   * The label to display for the address range label
   * Will only be displayed if the label prop is provided as well
   * @default "Addresses"
   */
  addressRangeLabel?: string;
}

/**
 * This requires that the validateNetworkAddress helper be passed by the
 * consumer with the appropriate validation options
 */
export const NetworkAddressInput = ({
  label,
  addressRangeLabel,
  onChange,
  testId,
  fieldName,
  defaultValue,
  ...textInputProps
}: NetworkAddressInputProps): JSX.Element => {
  const form = React.useContext(InternalFormContext);

  if (!Object.keys(form).length) throw new Error("NetworkAddressInput must be used within a Form");

  const { layout, groupName } = React.useContext(FormInputGroupLayoutContext);
  const [addresses, setAddresses] = React.useState(getAddressRange(defaultValue));

  const networkAddressInputTestIds = buildNetworkAddressInputTestIds(testId);

  const getAddressRangeLabel = (): string | undefined => {
    if (!label) return undefined;
    if (addressRangeLabel) return addressRangeLabel;
    return Messages.labels.addresses();
  };

  const makeCompact = (component: JSX.Element): JSX.Element => {
    if (layout === FormInputGroupLayout.COMPACT) {
      return component;
    }

    return (
      <FormInputGroupLayoutContextProvider
        value={{ groupName, layout: FormInputGroupLayout.COMPACT }}
      >
        {component}
      </FormInputGroupLayoutContextProvider>
    );
  };

  const onAddressChange = (value: string | undefined): void => {
    setAddresses(getAddressRange(value));
    onChange?.(value);
  };

  return makeCompact(
    <Stack horizontal tokens={{ childrenGap: "10px" }}>
      <TextInput
        {...textInputProps}
        defaultValue={defaultValue}
        fieldName={fieldName}
        testId={networkAddressInputTestIds.address.component}
        label={label}
        onChange={onAddressChange}
      />
      <ReadOnlyText
        defaultValue={addresses}
        fieldName={`${fieldName}-${Fields.Addresses}`}
        testId={networkAddressInputTestIds.addresses.component}
        label={getAddressRangeLabel()}
      />
    </Stack>,
  );
};
