import * as React from "react";
import { Text, TooltipHost } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

export interface TagProps {
  /**
   * Name of the tag
   */
  name: string,
  /**
   * Value of the tag
   */
  value?: string;
}

export const TagItem = ({ name, value }: TagProps): JSX.Element => {
  const [isMouseOver, { toggle: toggleIsMouseOver }] = useBoolean(
    false,
  );

  const content = `${name} : ${value}`;
  const backgroundColor = isMouseOver ? "#cce4f6" : "#e6f2fb";

  return (
    <TooltipHost content={content}>
      <div
        style={{
          backgroundColor,
          borderRadius: "2px",
          padding: "3px 8px 4px",
          width: "fit-content",
        }}
        onMouseOut={toggleIsMouseOver}
        onMouseOver={toggleIsMouseOver}
        onBlur={toggleIsMouseOver}
        onFocus={toggleIsMouseOver}
      >
        <Text>{name}</Text>
        <Text style={{ paddingRight: "5px", paddingLeft: "5px" }}>:</Text>
        <Text>{value}</Text>
      </div>
    </TooltipHost>
  );
};
