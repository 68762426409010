import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";

export interface FilterOptions {
  /**
   * All the available selected extra filters
   */
  allExtraFilters?: ExtraFilter[];
  /**
   * Initial default selected extra filters
   */
  defaultExtraFilters?: ExtraFilter[];
  /**
   * Hide text input select filter
   */
  hideTextFilter?: boolean;
  /**
   * Hide add filter button
   */
  hideAddFilterPill?: boolean;
  /**
   * Layout type for the Filter
   */
  filterLayout?: FilterLayout;
  /**
   * Text filter callback function
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  textFilterCallBack?: (item: any) => any;
  /**
   * Component reference for the FilterComponent
   */
  componentRef?: (ref: FilterComponent) => void;
  /**
   * Default filter text value
   */
  defaultFilterText?: string;
  /**
   * Disable the expand/collapse style of filter pills when zoom in and zoom out
   */
  disableCollapse?: boolean;
}

export interface PillFilterValue {
  /**
   * Key identifier of the pill filter
   */
  fieldKey: string;

  /**
   * Value of the pill filter
   */
  value: string;
}

export interface FilterComponent {
  /**
   * Method to get all the current filters
   */
  getFilters: () => PillFilterValue[];

  /**
   * Method to get current filter text
   */
  getFilterText: () => string;

}

export enum Operator {
  /**
   * Filter equal operator
   */
  Equal = "==",

  /**
   * Filter contains operator
   */
  Contains = "contains",

  /**
   * Custom filter function
   */
  Custom = "custom",
}

export enum FilterLayout {
  Pill = "PILL",
  DropDown = "DROPDOWN",
}

export interface FilterDropdownOption {
  /**
   * Arbitrary string associated with this option.
   */
  key: string | number;

  /**
   * Text to render for this option
   */
  text: string;
}

export interface ExtraFilter {
  /**
   * Name of the extra filter
   */
  name: string;

  /**
   * Key identifier of the extra filter
   */
  fieldKey: string;

  /**
   * Value of the extra filter,
   * value type will be string[] when we support multi select dropdown values
   * otherwise value type will string
   */
  value: string | string[];

  /**
   * Operator of the extra filter
   */
  operator: Operator;

  /**
   * Custom options, it is used to override the drop down options of the filter.
   */
  customOptions?: FilterDropdownOption[];

  /**
   * Hide All option in the options selector
   */
  hideAllOption?: boolean;

  /**
   * CallBack function.
   * Default filter action is filtering the current data set in the view by the pill value
   * Instead of the default filter action,
   * caller can define the search action associated with pill value change in the caller.
   * If multi select options are enabled, value type will be string[], otherwise, value type is string
   */
  callBack?: (value: string | string[]) => void;

  /**
   * Whether the delete icon of the filter item is shown or not
   */
  hideDelete?: boolean;

  /**
   * Layout type for the Filter
   */
  filterLayout?: FilterLayout;

  /**
   * Custom filter operator function, used when the operator value is custom
   * like ===, it is to find some value inside the currentItem based on this custom operator
   * If multi select options are enabled, searchKey type will be string[], otherwise, searchKey type is string
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customFilterOperation?: (currentItem: any, searchKey: string | string[]) => boolean;

  /**
   * Message callBack function, filter pill will use the function to get the message catalog value
   */
  messageCallBack?: (value: string) => string;

  /**
   * is this filter a tag filter
   */
  isTagFilter?: boolean;

  /**
   * Id used to get the filter pill for unit tests
   */
  testId?: string;

  /**
   * Show the pill modal in radio buttons style, default value is false.
   */
  isRadioGroupStyle?: boolean;

  /**
   * Operator message callBack function, filter pill operator will use the function to get the message catalog value
   */
  operatorMessageCallBack?: (value: Operator) => string;
}

export interface FilterTestIds extends ComponentTestIds {
  /**
   * A single filter pill that we click on to show the filter callout
   */
  pill: string;
  /**
   * the dropdown that displays the possible choices for that particular filter pill
   */
  valueDropdown: string,
  /**
   * The callout that gets displayed when clicking on a filter pill
   */
  callout: string,
  /**
   * The Apply button inside the callout
   */
  calloutApplyButton: string,
}

export const buildFilterTestIds = (baseTestId?: string): FilterTestIds => ({
  component: buildTestId(baseTestId),
  pill: buildTestId(baseTestId, "-pill"),
  valueDropdown: buildTestId(baseTestId, "-value-dropdown"),
  callout: buildTestId(baseTestId, "-callout"),
  calloutApplyButton: buildTestId(baseTestId, "-callout-apply-button"),
});

export const searchFieldTestId = "filter-search-field";
