import * as React from "react";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import { InternalLabelWrapper, InternalLabelWrapperProps } from "./InternalLabelWrapper";

export type LabelProps = Pick<InternalLabelWrapperProps, "required" | "tooltip" | "label" | "fieldName" | "children">;

/**
 * Controls positioning and styling when a label is applied
 */
export const Label = ({
  children,
  label,
  required,
  tooltip,
  fieldName,
}: LabelProps): JSX.Element => {
  const { layout } = React.useContext(FormInputGroupLayoutContext);

  if (!label) return children;

  return (
    <InternalLabelWrapper
      label={label}
      noHtmlFor
      errors={[]}
      fieldName={fieldName}
      layout={layout}
      required={required}
      tooltip={tooltip}
    >
      {children}
    </InternalLabelWrapper>
  );
};
