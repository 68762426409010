import * as React from "react";
import { injectKeyFramesForAnimation } from "../Svg/icons";

export interface ProgressDotsIndicatorProps {
  /**
   * Id used to get the action bar elements for unit tests
   */
  testId?: string;
}

/**
 *The Progress component renders a centered, 3-dot Spinner
 */

export const ProgressDotsIndicator = ({ testId }: ProgressDotsIndicatorProps): JSX.Element => {
  injectKeyFramesForAnimation(`@keyframes progressShadow {
    0%{transform:scale(1);opacity:0}
    36.1%{transform:scale(1.06);opacity:0.4}
    50%{transform:scale(1.06);opacity:0.4}
    86.1%{transform:scale(1);opacity:0}
  }`);
  injectKeyFramesForAnimation(`@keyframes progressing{
    0%{transform:scale(1);opacity:0.3}
    36.1%{transform:scale(1.06);opacity:1}
    50%{transform:scale(1.06);opacity:1}
    86.1%{transform:scale(1);opacity:0.3}
  }`);

  const getLoadingDotStyle = (columnStart: number, isShadow: boolean):
  { [key: string]: string | number | undefined } => ({
    gridColumnStart: columnStart,
    animationDuration: "1.8s",
    animationName: "progress",
    backgroundColor: isShadow ? "transparent" : "#3471ff",
    opacity: isShadow ? 0 : 0.3,
    height: "10px",
    width: "10px",
    margin: "0px 5px",
    borderRadius: "5px",
    gridRowStart: 1,
    boxShadow: isShadow ? "0 3px 3px 0 #0078d4" : undefined,
    WebkitAnimation: isShadow ? "progressShadow 1.8s infinite" : "progressing 1.8s infinite",
  });

  return (
    <div
      data-test-id={testId}
      style={{
        position: "absolute",
        top: "calc(50% - 10px/2)",
        width: "100%",
        lineHeight: 0,
        justifyContent: "center",
        display: "grid",
        zIndex: 2,
      }}
    >
      <div style={{ ...getLoadingDotStyle(1, false), animationDelay: "0s" }} />
      <div style={{ ...getLoadingDotStyle(2, false), animationDelay: "0.125s" }} />
      <div style={{ ...getLoadingDotStyle(3, false), animationDelay: "0.25s" }} />
      <div style={{ ...getLoadingDotStyle(1, true), animationDelay: "0s" }} />
      <div style={{ ...getLoadingDotStyle(2, true), animationDelay: "0.125s" }} />
      <div style={{ ...getLoadingDotStyle(3, true), animationDelay: "0.25s" }} />
    </div>
  );
};
