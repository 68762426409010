import * as React from "react";
import { IContextualMenuItem } from "@fluentui/react";
import { ConfirmDialogType } from "../ConfirmDialog/ConfirmDialogTypes";
import { InternalConfirmDialog } from "../ConfirmDialog/InternalConfirmDialog";
import {
  ActionMenuProps,
  ActionType,
  buildConfirmActionTestIds,
  ConfirmAction,
  CustomAction,
  MenuAction,
} from "./ActionTypes";

export const actionBarDefaultId = "action-bar-id";

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const isConfirmAction = <T,>(action: ActionType<T>): boolean => "title" in action;
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const isMenuAction = <T,>(action: ActionType<T>): boolean => "subMenu" in action;
// eslint-disable-next-line @typescript-eslint/comma-dangle
const isActionDisabled = <T,>(action: ActionType<T>, item: T, disableAll?: boolean): boolean => {
  if (disableAll) return true;
  if (typeof action.disabled === "function") return action.disabled(item);
  return action.disabled ?? false;
};
// eslint-disable-next-line @typescript-eslint/comma-dangle
const getActions = <T,>(actions: ActionMenuProps<T>["actions"], item: ActionMenuProps<T>["item"]): ActionType<T>[] => (
  typeof actions === "function" ? actions(item) : actions
);

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const getActionItems = <T,>(
  actions: ActionMenuProps<T>["actions"],
  item: T,
  onClick?: (action: ActionType<T>) => () => void,
  disableAll?: boolean,
): IContextualMenuItem[] => {
  const items: IContextualMenuItem[] = [];

  getActions<T>(actions, item)
    .forEach((action: ActionType<T>) => {
      const subMenu = isMenuAction(action) ? getSubItems(action as MenuAction<T>, item) : undefined;

      const disabled = isActionDisabled(action, item, disableAll);

      const newAction: IContextualMenuItem = {
        ...action,
        onClick: onClick?.(action),
        id: action.key,
        "data-test-id": action.testId,
        disabled,
        iconProps: action.icon ? { iconName: action.icon } : undefined,
        subMenuProps: subMenu?.length ? { items: subMenu } : undefined,
      };

      delete newAction.description;
      items.push(newAction);
    });

  return items;
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const getSubItems = <T,>(
  action: MenuAction<T>,
  rowValue?: T,
): IContextualMenuItem[] => action.subMenu.items.map((item: ConfirmAction<T> | CustomAction<T>) => ({
    ...item,
    disabled: typeof item.disabled === "function" ? item.disabled(rowValue) : item.disabled,
    onClick: () => item.onClick?.(rowValue),
    "data-test-id": item.testId,
    id: item.key,
    iconProps: item.icon ? { iconName: item.icon } : undefined,
  }));

interface ConfirmActionDialogProps<T> {
  item?: T;
  confirmAction: ConfirmAction<T>;
  targetId?: string;
  width?: string | number;
  onConfirm?: () => void;
  onCancel?: () => void;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const ConfirmActionDialog = <T,>({
  confirmAction,
  item,
  targetId,
  width,
  onCancel,
  onConfirm,
}: ConfirmActionDialogProps<T>): JSX.Element => {
  const confirmActionTestIds = buildConfirmActionTestIds(confirmAction.testId);

  const internalOnConfirm = (): void => {
    confirmAction.onConfirm(item);
    onConfirm?.();
  };

  const internalOnCancel = (): void => {
    confirmAction.onCancel?.();
    onCancel?.();
  };

  const description = typeof confirmAction.description === "function"
    ? confirmAction.description(item)
    : confirmAction.description;

  return (
    <InternalConfirmDialog
      testId={confirmActionTestIds.confirmDialog.component}
      targetId={targetId || actionBarDefaultId}
      title={confirmAction.title}
      description={description}
      onConfirm={internalOnConfirm}
      onCancel={internalOnCancel}
      onDismiss={internalOnCancel}
      type={ConfirmDialogType.COMMAND_BAR}
      styles={{ dialogWidth: width || "300px" }}
    />
  );
};
