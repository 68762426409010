import { SERVICE_LIMIT_MULTIPLIER } from "../utils/constants";

export default class Producer<T> {
  private recordBuffer: T[];
  private readonly maxBufferSize: number;

  /**
   * Constructor for Producer class
   * @param notifyConsumer function to be executed when the batch is full
   * @param batchSize size of the batch
   */
  constructor(private notifyConsumer: () => void, private batchSize: number) {
    this.recordBuffer = [];
    this.maxBufferSize = SERVICE_LIMIT_MULTIPLIER * this.batchSize;
  }

  /**
   * Add items to the buffer
   * @param item
   */
  add(item: T) {
    const numberOfRecords = this.recordBuffer.length;
    if (numberOfRecords < this.maxBufferSize) {
      this.recordBuffer.push(item);
      if (numberOfRecords + 1 >= this.batchSize) {
        this.notifyConsumer();
      }
    } else {
      /**
       * This is to protect the telemetry service when it's trying to get back up after an outage.
       * Since we have resilient fetch, all those failed calls will retry, and so many calls so quickly
       * can overwhelm the service, therefore we need limit on the client level also.
       */
      console.debug("Limit Exceeded.");
    }
  }

  /**
   * Returns the current bufferSize
   */
  public bufferSize() {
    return this.recordBuffer.length;
  }

  /**
   * Get a batch of items to be process by the consumer
   */
  public getBatch() {
    return this.recordBuffer.splice(0, this.batchSize);
  }
}
