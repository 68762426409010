/* eslint-disable no-useless-constructor */
/* eslint-disable no-empty-function */
/* eslint-disable no-use-before-define */
import IndexedDBHandler from "../core/IndexedDBHandler";
import { LogMetadata, Transport, LogLevel } from "../models";
import { INDEX_DB_TEST_STORE_NAME, INDEX_DB_TEST_NAME, INDEX_DB_VERSION } from "../utils/constants";

export class TelemetryClient {
  private static instance: TelemetryClient;
  private constructor(private transports: Transport[] = []) {}

  public static isIndexedDBAvailable: boolean;

  public static async initialize(transports?: Transport[]) {
    if (!TelemetryClient.instance) {
      TelemetryClient.instance = new TelemetryClient(transports || []);
    }
    TelemetryClient.isIndexedDBAvailable = await IndexedDBHandler.testIndexedDB(
      INDEX_DB_TEST_STORE_NAME,
      INDEX_DB_TEST_NAME,
      INDEX_DB_VERSION,
    );
  }

  public static addTransport(transport: Transport) {
    if (TelemetryClient.instance) {
      TelemetryClient.instance.transports.push(transport);
    }
  }

  public static removeTransport(transport: Transport) {
    if (TelemetryClient.instance) {
      const index = TelemetryClient.instance.transports.findIndex(t => t === transport);
      if (index !== -1) {
        TelemetryClient.instance.transports.splice(index, 1);
      }
    }
  }

  public static processLog(logLevel: LogLevel, message: string | Error, logMetadata?: LogMetadata) {
    TelemetryClient.instance.transports.forEach(transport => {
      transport.processLog(logLevel, message, logMetadata);
    });
  }

  public static processMetric(name: string, value: number, dimensions?: Record<string, string>) {
    TelemetryClient.instance.transports.forEach(transport => {
      transport.processMetric(name, value, dimensions);
    });
  }
}
