/* eslint-disable object-shorthand */
import { generateUserSessionId } from "../utils/internalUtils";
import ResilientFetch from "../core/ResilientFetch";
import { FetchApiSignature, LogLevel, LogMetadata, Transport } from "../models";

export const COURIER_TIMER_INTERVAL = 500;
export const COURIER_FACTOR = 2;
export const COURIER_MAX_RETRIES = 5;
export const USER_SESSION_ID = "userSessionId";

export type CourierTransportConfig = {
  fetchApi?: FetchApiSignature;
  namespace: string;
  courierUrl: string;
  prefix: string;
  userSession?: string;
};

export class CourierTransport implements Transport {
  private config: CourierTransportConfig;
  private readonly fetchApi: FetchApiSignature;
  private readonly metricsUrl: string;
  private readonly logsUrl: string;
  private readonly levelMapping = new Map([
    [LogLevel.Error, 40000],
    [LogLevel.Warning, 40000],
    [LogLevel.Info, 20000],
    [LogLevel.Debug, 20000],
  ]);

  constructor(config: CourierTransportConfig) {
    this.config = { ...config };
    if (this.config.userSession === undefined) {
      this.config.userSession = generateUserSessionId();
      sessionStorage.setItem(USER_SESSION_ID, this.config.userSession);
    }
    const baseUrl = config.courierUrl;
    const resilientFetch = new ResilientFetch(
      COURIER_TIMER_INTERVAL,
      COURIER_FACTOR,
      COURIER_MAX_RETRIES,
    );
    resilientFetch.setInternalFetchApi(config.fetchApi);

    this.logsUrl = `${baseUrl}/sendLogging`;
    this.metricsUrl = `${baseUrl}/sendMetrics`;
    this.fetchApi = resilientFetch.getResilientFetch();
  }

  private reportError(errorMessage: string, error?: unknown) {
    console.group("Courier error");
    console.error(errorMessage);
    if (error) {
      console.error(error);
    }
    console.groupEnd();
  }

  public setUserSession(userSession: string): void {
    this.config.userSession = userSession;
    sessionStorage.setItem(USER_SESSION_ID, userSession);
  }

  processTelemetry(url: string, body: BodyInit, type: string): void {
    this.fetchApi(url, {
      body,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (!response.ok) {
          this.reportError(`Error sending ${type}`, `response code ${response.status}`);
        }
      })
      .catch(exception => {
        this.reportError(`Error sending ${type}`, exception);
      });
  }

  processLog(logLevel: LogLevel, message: string | Error, logMetadata?: LogMetadata): void {
    const messageBody = {
      message: message.toString(),
      metadata: logMetadata || {},
    };
    const type = "logging";
    const severity = this.levelMapping.get(logLevel);
    const body = JSON.stringify({
      source: this.config.namespace,
      severity,
      type,
      timestamp: Date.now(),
      origin: logMetadata?.origin ?? "",
      message: JSON.stringify(messageBody),
      metadata: [],
      userSession: this.config.userSession,
    });
    this.processTelemetry(this.logsUrl, body, type);
  }

  processMetric(name: string, value: number): void {
    const type = "metrics";
    const body = JSON.stringify({
      source: this.config.namespace,
      type,
      timestamp: Date.now(),
      metadata: [],
      sampling: 1,
      name: [this.config.prefix, name].join("_"),
      value: value,
      userSession: this.config.userSession,
    });
    this.processTelemetry(this.metricsUrl, body, type);
  }
}
