/* eslint-disable no-useless-constructor */
/* eslint-disable no-empty-function */
import { LogLevel, LogMetadata } from "../models";
import { TelemetryClient } from "./TelemetryClient";

export class LoggerClient {
  private constructor(private origin: string) {}

  /**
   * Function to get an instance of Logger
   * @param origin: where is the log request coming from. Eg. "Compute Plugin"
   */
  public static getLogger(origin: string) {
    return new LoggerClient(origin);
  }

  /**
   * Function used by the user to send a log
   * @param logLevel info, warn, error or debug
   * @param message
   * @param logMetadata any metadata such as region, environment or any other data
   */
  public log(logLevel: LogLevel, message: string | Error, logMetadata?: LogMetadata) {
    TelemetryClient.processLog(logLevel, message, { origin: this.origin, ...logMetadata });
  }

  public info(message: string | Error, logMetaData?: LogMetadata) {
    this.log(LogLevel.Info, message, logMetaData);
  }

  public error(message: string | Error, logMetaData?: LogMetadata) {
    this.log(LogLevel.Error, message, logMetaData);
  }

  public debug(message: string | Error, logMetaData?: LogMetadata) {
    this.log(LogLevel.Debug, message, logMetaData);
  }

  public warn(message: string | Error, logMetaData?: LogMetadata) {
    this.log(LogLevel.Warning, message, logMetaData);
  }
}
