import * as React from "react";
import { ILabelStyles, IStackStyles, Label, mergeStyleSets, Stack } from "@fluentui/react";
import { uniqueGUID } from "../../helpers/util";
import { FieldSet } from "../Input/FieldSet";
import { labelStylesWithToolTip, stackItemStylesWithToolTip } from "../Input/InputCommon";
import { LabelInfoTooltip } from "../Tooltip/Tooltip";

const stackItemStyles: IStackStyles = { root: { marginBottom: "-10px" } };

const stackSectionStyles: IStackStyles = { root: { paddingBottom: "15px" } };
const labelStackStyle: IStackStyles = { root: { minWidth: "250px" } };
const fieldNameStyles: ILabelStyles = {
  root: {
    maxWidth: "220px",
    width: "100%",
    height: "100%",
    marginTop: "3px",
    marginBottom: "4px",
    minWidth: "220px",
    fontWeight: "400",
    color: "#605e5c",
    fontSize: "13px",
  },
};
const valueStyles = mergeStyleSets(
  fieldNameStyles,
  { root: { maxWidth: "450px", color: "#323130" } },
);

const fieldNameToolTipStyles = mergeStyleSets(
  labelStylesWithToolTip,
  { root: { color: "#605e5c" } },
);
const computeLabelStyles = mergeStyleSets(
  fieldNameStyles,
  { root: { color: "#A19F9D" } },
);

export interface ReviewItem {
  /**
   * Label option for the Review Item
   */
  label: string;
  /**
   * Value(s) for the Review Item
   */
  value?: string | string[] | JSX.Element;
  /**
   * Tooltip to be displayed on the Review Item Label
   */
  tooltip?: string;
  /**
   * Display a computed text for the Review Item
   */
  computed?: boolean;
}
export interface ReviewSection {
  /**
   * Title for the ReviewSection
   */
  title: string;
  /**
   * Items to be displayed on the ReviewSection
   */
  items: ReviewItem[]
}
export interface ReviewInfoProps {
  /**
   * Sections to be displayed on the ReviewInfo
   */
  reviewSections: ReviewSection[];
}

const reviewItem = (item: ReviewItem) : JSX.Element => (
  <Stack
    key={uniqueGUID()}
    horizontal
    styles={stackItemStyles}
  >
    <Stack horizontal styles={item?.tooltip !== undefined ? stackItemStylesWithToolTip : labelStackStyle}>
      <Label styles={item?.tooltip !== undefined ? fieldNameToolTipStyles : fieldNameStyles}>
        {item?.label}
      </Label>
      {item?.tooltip !== undefined && <LabelInfoTooltip tooltip={item?.tooltip} />}
    </Stack>
    <Label styles={item?.computed ? computeLabelStyles : valueStyles}>
      {Array.isArray(item?.value) ? item.value.join(", ") : item?.value}
    </Label>
  </Stack>
);

/**
 * The ReviewInfo component renders the final review info with the review items
 * grouped into their respective review sections
 */

export const ReviewInfo = ({ reviewSections }: ReviewInfoProps): JSX.Element => {
  const reviewSection = reviewSections?.map(section => (

    <Stack
      key={uniqueGUID()}
      styles={stackSectionStyles}
    >
      <FieldSet header={section?.title} key={section.title}>
        {section?.items.map(item => (
          reviewItem(item)
        ))}
      </FieldSet>
    </Stack>

  ));
  return (
    <div>
      {reviewSection}
    </div>
  );
};
