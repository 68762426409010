import * as React from "react";
import { IStackStyles, IStackTokens, Stack } from "@fluentui/react";
import {
  FormInputGroupLayout,
  FormInputGroupLayoutContext,
  FormInputGroupLayoutContextProvider,
} from "./FormInputGroup";
import { FormValues } from "./FormTypes";
import { DeleteButton, DeleteButtonProps } from "./SimpleBuilderDeleteButton";
import { ErrorProps, Errors } from "./SimpleBuilderError";
import {
  buildFieldName,
  emptyDivStyle,
  focusNextInputFrom,
  TemplateInput,
  TemplateInputProps,
} from "./SimpleBuilderInternalTypes";

const fullWidth: React.CSSProperties = { maxWidth: "100%" };

const getErrorContainerStyles = (errors?: string[]): React.CSSProperties => ({
  width: "calc(100% - 32px)",
  paddingTop: errors?.length ? "5px" : undefined,
});

const styles: IStackStyles = {
  root: {
    padding: "0 5px -5px 5px",
    borderBottom: "1px solid #f3f2f1",
    ":hover": { backgroundColor: "#f3f2f1", borderBottom: "1px solid #f3f2f1" },
    ":focus-within": { backgroundColor: "#edebe9", borderBottom: "1px solid #edebe9" },
    ":hover:focus-within": { backgroundColor: "#e1dfdd", borderBottom: "1px solid #e1dfdd" },
  },
};

export interface RowProps {
  /**
   * Current row ID
   */
  id: number;
  /**
   * Current row index
   */
  index: number;
  /**
   * Callback when the row has changed
   */
  onChange: () => void;
  /**
   * Callback when the row is to be deleted
   */
  onDelete: () => void;
  /**
   * The default values for the row
   */
  defaultValues: FormValues | undefined;
  /**
   * The template to render
   */
  template: TemplateInput[];
  /**
   * Props for the delete button
   */
  deleteProps: Omit<DeleteButtonProps, "onClick">;
  /**
   * Props for the row errors
   */
  errorProps: ErrorProps;
  /**
   * Provide styling to the columns container
   */
  tokens: IStackTokens;
  /**
   * Controls when each field will remount.
   * The keys of the Record are the field names, the values are the 'keys' that will control the component's remount.
   */
  keys?: Record<string, string>;
}

/**
 * Renders a row of components from a template, typically inputs.
 */
export const Row = ({
  keys: currentRowKeys,
  defaultValues: defaultRowValues,
  deleteProps,
  errorProps,
  id: currentRowId,
  index: currentRowIndex,
  onChange: onRowChange,
  onDelete: onRowRemove,
  template: templateRow,
  tokens,
}: RowProps): JSX.Element => {
  const { groupName } = React.useContext(FormInputGroupLayoutContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const htmlIdAttribute = React.useMemo(() => `row-${currentRowId}`, []);

  const onDeleteClick = (): void => {
    focusNextInputFrom(htmlIdAttribute);
    onRowRemove();
  };

  return (
    <Stack
      id={htmlIdAttribute}
      style={fullWidth}
      styles={styles}
    >
      <Stack
        horizontal
        tokens={tokens}
        verticalAlign="start"
      >
        { // Always use React.Children.map instead of Array.map when used in combination with React.cloneElement
          // as otherwise child useEffects will be executed out of sync with the parent.
          React.Children.map(templateRow, (templateInput, templateInputIndex) => {
            // Whether or not the child exposes the field name property is how we determine if it is an input
            // Depending on whether or not it is an input we will override/manage some of its props
            const managedProps: TemplateInputProps = { ...templateInput.props };

            // This is required, otherwise the inputs will all rerender every time an input has changed
            // Without it they end up losing their values while behind the scenes the state still holds them
            // The only other alternative is to have a 3rd option passed to the managedProps.defaultValue by
            // retrieving the value from the subForm. This would be inefficient if we were to rerender
            // everything every time the user types.
            //
            // In the case that it is a non-input componenet, we don't have a use case for limiting the
            // remounting as we are expecting it to be a non-stateful element, but we do so anyway.
            let key = `row${currentRowIndex}-child${templateInputIndex}`;

            if (templateInput.props.fieldName) {
              const onChange = (value?: unknown): void => {
                templateInput.props.onChange?.(value);
                onRowChange();
              };

              const templateDefaultValue = templateInput.props.defaultValue;
              const defaultValue = defaultRowValues?.[templateInput.props.fieldName];

              managedProps.fieldName = buildFieldName(templateInput.props.fieldName, currentRowId);
              managedProps.defaultValue = defaultValue ?? templateDefaultValue;
              managedProps.onChange = onChange;
              managedProps.label = undefined;
              managedProps.tooltip = undefined;

              const remountKey = currentRowKeys?.[templateInput.props.fieldName];

              key = remountKey ? `${managedProps.fieldName}-${remountKey}` : managedProps.fieldName;
            }

            const element = React.cloneElement(templateInput, managedProps);

            return (
              <FormInputGroupLayoutContextProvider
                value={{ groupName, layout: FormInputGroupLayout.COMPACT }}
                key={key}
              >
                {element}
              </FormInputGroupLayoutContextProvider>
            );
          })
        }
        <DeleteButton {...deleteProps} onClick={onDeleteClick} />
      </Stack>
      <Stack
        horizontal
        tokens={tokens}
        style={fullWidth}
      >
        <div style={getErrorContainerStyles(errorProps.errors)}>
          <Errors {...errorProps} />
        </div>
        {/* Ensures that the errors will not display underneath the delete button */}
        <div style={emptyDivStyle} />
      </Stack>
    </Stack>
  );
};
