import * as React from "react";
import { Stack } from "@fluentui/react";
import { FormattedString } from "../FormattedString/FormattedString";

export interface FieldSetProps {
  /**
   * Option to provide a header for the FieldSet
   */
  header: string;
  /**
   * Header's font size in pixels
   * @default 14
   */
  headerSize?: number;
  /**
   * Description for the FieldSet
   */
  description?: string;
  /**
   * Option to specify the components as part of the FieldSet
   */
  children: React.ReactNode;
}

/**
 * The FieldSet renders the components grouped under the specified header
 */
export const FieldSet = ({ header, headerSize = 14, description, children }: FieldSetProps): JSX.Element => (
  <Stack>
    <div
      style={{
        margin: "10px 0px",
        fontSize: `${headerSize}px`,
        fontWeight: 600,
        lineHeight: "normal",
      }}
    >
      {header}
    </div>
    {description && (
      <>
        <FormattedString inputText={description} />
        <br />
      </>
    )}
    {children}
  </Stack>
);
