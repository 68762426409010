import { Target } from "@fluentui/react";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { buildConfirmDialogTestIds, ConfirmationDialogTestIds } from "../ConfirmDialog/ConfirmDialogTypes";
import { InfoBlockProps } from "../InfoBlock/InfoBlock";

export interface ActionBarProps {
  /**
   * List of actions to display in the action bar
   */
  actions: ActionType [] ;
  /**
   * Status info to show under the action bar
   */
  infoBlocks?: InfoBlockProps [];
  /**
   * If all the actions should be disabled simultaneously
   */
  disableAll?: boolean;
  /**
   * Triggered when the user clicks on any action.
   * Allows executing logic prior to invoking the action.
   */
  onActionClick?: (actionKey: string) => void;
  /**
   * Id used to get the action bar elements for unit tests
   */
  testId?: string;
  /**
   * Id of the action bar element
   * @default "action-bar-id"
   */
  id?: string;
}

export interface ConfirmAction <T = undefined> extends Action<T> {
  /**
   * Title of the confirmation dialog
   */
  title: string;
  /**
   * Description of the confirmation dialog
   */
  description: string | ((item?: T) => string);
  /**
   * Action to perform when clicking on YES in the confirmation
   */
  onConfirm: (item?: T) => void;
  /**
   * Action to perform when clicking on NO in the confirmation
   */
  onCancel?: () => void;
  /**
   * Toggles confirmation bar's visibility and an action to perform when clicking on action
   */
  onClick?: (item?: T) => void;
}

export interface CustomAction <T = undefined> extends Action<T> {
  /**
   * Action to perform when clicking on the action
   */
  onClick: (item?: T) => void;
}

export interface MenuAction <T = undefined> extends Action<T> {
  /**
   * Specifies if the action is a submenu
   */
  subMenu: { items: (ConfirmAction<T> | CustomAction<T>)[] };
}

/**
* An Action bar that supports custom actions, action menues and confirmation actions
* A confirmation action will display a confimration dialog that asks the user to proceed or cancel the action
*/

export type ActionType<T = undefined> = ConfirmAction <T> | CustomAction<T> | MenuAction<T>;

export interface Action <T = undefined>{
  /**
   * A unique key to identify the action
   */
  key: string;
  /**
   * Title of the action
   */
  text: string;
  /**
   * A custom icon to display on the action item along with the text
   */
  icon?: string;
  /**
   * If the action should be disabled
   */
  disabled?: boolean | ((item?: T) => boolean);
  /**
   * A function to edit how the icon is being rendered
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRenderIcon?: (iconProps?: any) => JSX.Element;
  /**
   * Id used to build test ids for sub components of the Action
   */
  testId?: string;
}

export interface ConfirmActionDialogProps {
  /**
   * The id of an element to which the dialog will be anchored, if not provided all dialogs will be anchored
   * to the action bar if any. Otherwise the dialog will be displayed in the upper left corner
   * @default "action-bar-id"
   */
  anchorId?: string;
  /**
   * An optional property to set the confirmation dialog width
   * @default "300px"
   */
  width?: string;
}

export interface ActionMenuProps<T> {
  /**
   * The event or ID of the element to attach to
   */
  anchor: string | Target;
  /**
   * The item in the row where the action menu is displayed
   */
  item: T;
  /**
   * The list of actions that can be performed
   */
  actions: ActionType <T> [] | ((item?: T) => ActionType <T> []);
  /**
   * An optional test id to identify the action menu element
   */
  testId?: string;
  /**
   * Props for the confirmation dialog if there is confirm actions
   */
  confirmDialogProps?: ConfirmActionDialogProps;
  /**
   * Callback triggered when the ActionMenu is to be closed
   * If the action clicked is a
   */
  onClose?: () => void;
}

export interface ConfirmActionTestIds extends ComponentTestIds {
  /**
   * Test Ids for the confirmation dialog
   */
  confirmDialog: ConfirmationDialogTestIds;
}

export const buildConfirmActionTestIds = (testId?: string): ConfirmActionTestIds => ({
  component: buildTestId(testId),
  confirmDialog: buildConfirmDialogTestIds(buildTestId(testId, "-confirm-dialog")),
});
