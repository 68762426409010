import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { InfoBlockProps } from "../InfoBlock/InfoBlock";

/**
 * Confirm Dialog has two types COMMAND_BAR, ACTION_MENU
 */
export enum ConfirmDialogType {
  /**
   * COMMAND_BAR: confirm dialog will be shown under action bar without beak.
   */
  COMMAND_BAR,
  /**
   * ACTION_MENU: confirm dialog will be shown as per the screen resolution and space around the target with beak.
   */
  ACTION_MENU,
}

export type InfoBlockType = Pick<InfoBlockProps, "messageType" | "message" | "isMultiline">;

export interface ConfirmationDialogTestIds extends ComponentTestIds {
  /**
   * the Confirm button inside the dialog
   */
  confirmButton: string,
  /**
   * the Cancel button inside the callout
   */
  cancelButton: string
}

export const buildConfirmDialogTestIds = (baseTestId?: string): ConfirmationDialogTestIds => ({
  component: buildTestId(baseTestId),
  confirmButton: buildTestId(baseTestId, "-confirm-button"),
  cancelButton: buildTestId(baseTestId, "-cancel-button"),
});
