import * as React from "react";
import { Link } from "@fluentui/react";
import { Format, FormattedText, FormattedTextResult, textFormatter } from "../../helpers/textFormatUtils";

/**
 * Takes in a FormattedTextResult and returns an array of JSX.Elements containing tags to be
 * rendered
 *
 * @param nestedResultText FormattedTextResult used to parse through and convert each element to an
 * array of JSX.Elements
 * @returns React.ReactNode to be rendered
 */
function convertToJSX(resultText: FormattedTextResult): JSX.Element {
  const elements: React.ReactNode[] = [];
  resultText.forEach((element: string | FormattedText, index: number) => {
    if (typeof element === "string") {
      elements.push(element);
    } else if (element.content) {
      const content: React.ReactNode = convertToJSX(element.content);
      switch (element.format) {
        case Format.Emphasized:
          elements.push(<em key={index.toString()}>{content}</em>);
          break;
        case Format.Link:
          elements.push(
            <Link
              key={index.toString()}
              {...element.properties}
            >
              {content}
            </Link>,
          );
          break;
        case Format.Strong:
          elements.push(<strong key={index.toString()}>{content}</strong>);
          break;
        default:
          break;
      }
    }
  });
  return <span>{elements}</span>;
}

export interface FormattedStringProps {
  /**
  * Text option for FormattedString
  */
  inputText: string
}

/**
 *The FormattedString component renders the formatted string
 as JSX element
 */
export const FormattedString = (props: FormattedStringProps): JSX.Element => {
  const text: string = props.inputText;
  // Get the formatted text from the textFormatter and then convert to JSX.
  // Memoized to improve performance.
  const renderResult = React.useMemo(() => convertToJSX(textFormatter(text)), [
    text,
  ]);

  // Convert the FormattedTextResult react elements
  return renderResult;
};
