export enum MenuLayoutType {
  TOP_SIDE_DECOUPLED = "TOP_SIDE_DECOUPLED",
  TOP_SIDE_COUPLED = "TOP_SIDE_COUPLED",
}

export enum DetailViewsMenuZoomLayout {
  DROPDOWN = "DROPDOWN",
  COUPLED_PORTAL = "COUPLED_PORTAL",
}

export interface ScreenSizeMonitor {
  panelHeight: number;
  menuLayoutType: MenuLayoutType;
  getDetailViewsMenu: () => React.ReactNode;
  getDetailViewsMenuZoomLayout: () => DetailViewsMenuZoomLayout | undefined;
  setSearchText: (searchText:string) => void;
  getSearchText: () => string;
}
