import * as React from "react";
import { FormErrorsContext, getFieldErrors } from "../components/Input/FormInternalTypes";

/**
 * Combined errors from different sources
 */
type AllErrors = string[];
type SetFieldErrors = (errors: string[] | undefined) => void;

export type UseErrorsResult = [AllErrors, SetFieldErrors];

/**
 * Provides mechanism to manage field errors while giving access to different error sources
 */
export const useErrors = (fieldName: string, groupName?: string): UseErrorsResult => {
  const formErrors = React.useContext(FormErrorsContext);
  const crossFieldErrors = getFieldErrors(formErrors, fieldName, groupName);

  const [fieldErrors, setFieldErrors] = React.useState<string[]>();

  // ensure that all errors are unique
  const allErrors = new Set([
    ...fieldErrors || [],
    ...crossFieldErrors || [],
  ]);

  return [Array.from(allErrors), setFieldErrors];
};
