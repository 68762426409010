import * as React from "react";
import {
  ActionButton,
  Dropdown,
  FontWeights,
  Icon,
  IconButton,
  IDropdownOption,
  INavButtonProps,
  INavLink,
  INavLinkGroup,
  INavStyles,
  mergeStyleSets,
  Nav,
  ResponsiveMode,
  SearchBox,
  Stack,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";

export interface ToolTipOptions {
  /**
   * tooltip text
   */
  toolTipText: string;
  /**
   * label for create option
   */
  createLabel?: string;
  /**
   * label for view option
   */
  viewLabel?: string;
  /**
   * when create label is clicked, onCreateClick will be called
   */
  onCreateClick?: () => void;
  /**
    * when view label is clicked, onViewClick will be called
    */
  onViewClick?: () => void;
}

/**
 * Menu state
 */
export enum MenuState {
  COLLAPSED = "none",
  EXPAND = "block",
}

/**
 * Menu has two types: SIDE_MENU, TOP_MENU
 */
export enum MenuType {
  /**
   * SIDE_MENU: Should be used when menu needs to be shown in application i.e inside details page
   */
  SIDE_MENU,
  /**
   * TOP_MENU: Should be used when menu needs to be shown as floating i.e menu on header
   */
  TOP_MENU,
  /**
   * DROPDOWN_MENU: Should be used when menu needs to be shown as dropdown with textual data only.
   */
  DROPDOWN_MENU,
}

/**
 * Each row in menu component is defined by MenuItem
 */
export interface MenuItem {
  /**
   * name of the menu item
   */
  name: string;
  /**
   * when menu item is clicked, onClick will be called
   */
  onClick?: (id: string, ev?: React.MouseEvent<HTMLElement>) => void;
  /**
   * icon name
   */
  icon?: string;
  /**
   * unique id for menu item. It has to be unique in a collection of menu items.
   */
  id: string;
  /**
   * tooltip options i.e create click, view click.
   */
  toolTipOptions?: ToolTipOptions;
  /**
   * if true, menu item will be disabled
   */
  disabled?: boolean;
  /**
   * id to get MenuItem for unit tests
   */
  testId?: string;
}

/**
 * Group Item is defined as combination of heading and list of menu items.
 */
export interface GroupItem {
  /**
   * heading. if passed, it will show the menu items under this name.
   */
  heading?: string;
  /**
   * Array of menu items, each item is individual menu row.
   */
  items: MenuItem[];
}

/**
 * Menu component props
 */
export interface MenuProps {
  /**
   * Menu is collapsible
   */
  isCollapsible?: boolean;
  /**
   * Array of menu items (data)
   */
  groups: GroupItem[];
  /**
   * Searchbox placeholder
   */
  placeholder?: string;
  /**
   * Menu type (TOP_MENU, SIDE_MENU)
   */
  type: MenuType;
  /**
   * Key of the menu item selected
   */
  selectedKey?: string;
  /**
   * Hide Searchbox
   * @default false
   */
  hideSearchBox?: boolean;
  /**
   * Allows to interact with menu from tests
   */
  testId?: string;
  /**
   * Width of the menu. px will be appended if width is number.
   * For string provide unit of width as well i.e. 225px or 20rem.
   * @default 225
   */
  width?: string | number;
  /**
   * Background color
   * @default white
   */
  backgroundColor?: string;
  /**
   * Show inset shadow
   * @defaut false
   */
  insetShadow?: boolean;
  /**
   * Default value of Search Field
   */
  searchText?: string;
  /**
   * Menu state (COLLAPSED, EXPAND)
   * @default EXPAND
   */
  menuState?: MenuState;
  /**
   * Remember the selection of menu item
   * @default false
   */
  rememberSelection?:boolean;
  /**
   * Callback executes when search field changed.
   */
  onSearch?: (searchText: string) => void;
  /**
   * Callback executes when menu collapse or expand.
   */
  onMenuStateChange?: (state: MenuState) => void;
}

const classNames = mergeStyleSets({
  svgIconSizeNavMenu: {
    svg: {
      height: 18,
      width: 18,
    },
  },
});

const getUrlTemplate = (props: INavButtonProps): JSX.Element => {
  const { className, onClick, children, disabled, link } = props;
  const { props: cProps } = children as JSX.Element;
  const inlineStyles = disabled ? { color: "gray", cursor: "default" } : undefined;
  const iconInlineStyles = disabled ? { filter: "grayscale(100%)", opacity: 0.4 } : undefined;
  return (
    <ActionButton
      onClick={disabled ? undefined : onClick}
      style={{ ...inlineStyles, color: "#0078d4", cursor: "pointer" }}
      className={className}
      data-test-id={link?.testId}
    >
      <span style={{ display: "flex" }}>
        <Icon
          style={{ margin: "1px 4px", ...iconInlineStyles }}
          {...props?.iconProps}
          className={classNames.svgIconSizeNavMenu}
        />
        <div
          style={inlineStyles}
          className={cProps?.className}
        >
          {cProps?.children}
        </div>
      </span>
    </ActionButton>
  );
};

const enum MenuColors {
  BLACK = "#000000",
  BLUE = "#E0E9F1",
  DARK_GRAY = "gray",
  GRAY = "#F1F0EF",
  WHITE = "#FFFFFF",
  LIGHT_BLUE = "#55b3ff1a",
  LIGHT_GRAY = "#f3f2f1",
}

const enum MenuIcons {
  COLLAPSE = "DoubleChevronLeft8",
  EXPAND = "DoubleChevronRight8",
}

const getHoverColor = (type: MenuType):string => (type === MenuType.TOP_MENU
  ? MenuColors.LIGHT_BLUE : MenuColors.LIGHT_GRAY);
const getSelectedColor = (type: MenuType, rememberSelection = false): string | undefined => {
  if ((type === MenuType.TOP_MENU || type === MenuType.SIDE_MENU) && !rememberSelection) {
    return "transparent";
  } if (rememberSelection) {
    return type === MenuType.TOP_MENU ? "rgba(85,179,255,.2)" : "rgb(237, 235, 233)";
  }
  return undefined;
};

/**
 * Menu can be used to navigate to other components.
 * Check @MenuProps
 */
export const Menu = ({
  type,
  testId,
  groups,
  onSearch,
  searchText,
  placeholder,
  selectedKey,
  isCollapsible,
  onMenuStateChange,
  width = 225,
  insetShadow = false,
  hideSearchBox = false,
  rememberSelection = false,
  menuState = MenuState.EXPAND,
  backgroundColor = MenuColors.WHITE,
}: MenuProps): JSX.Element => {
  const [internalMenuState, setInternalMenuState] = React.useState<MenuState>(menuState);
  const [searchPhrase, setSearchPhrase] = React.useState<string | undefined>(searchText);
  const [selected, setSelected] = React.useState<string | undefined>(selectedKey);
  const [navLinks, setNavLinks] = React.useState<INavLinkGroup[]>([]);
  const [menuWdith, setMenuWidth] = React.useState<number | string>(width);
  const [chevronIcon, setChevronIcon] = React.useState<MenuIcons>(MenuIcons.COLLAPSE);
  const [dropDownOptions, setDropDownOptions] = React.useState<IDropdownOption[]>([]);

  React.useEffect(() => setSelected(selectedKey), [selectedKey]);

  React.useEffect(() => {
    filterOptions(searchPhrase || "");
    onSearch?.(searchPhrase || "");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase, groups]);

  /**
   * useEffect is required only when the initial menuState is
   * collapsed & width has to be set explicitly based on menu type.
   * collapseMenu function does opposite work, if collapsed, expand it & vice-versa.
   */
  React.useEffect(() => {
    if (internalMenuState === MenuState.COLLAPSED) {
      setMenuWidth(type === MenuType.SIDE_MENU ? 32 : 40);
      setChevronIcon(MenuIcons.EXPAND);
    }
    if (type === MenuType.DROPDOWN_MENU) {
      const options = (groups.map(group => group.items.map(item => ({
        id: item.id,
        key: item.id,
        text: item.name,
      })))).reduce((acc, val) => acc.concat(val));
      setDropDownOptions(options);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const collapseMenu = (): void => {
    if (internalMenuState === MenuState.COLLAPSED) {
      setInternalMenuState(MenuState.EXPAND);
      setMenuWidth(width);
      setChevronIcon(MenuIcons.COLLAPSE);
      onMenuStateChange?.(MenuState.EXPAND);
    } else {
      setInternalMenuState(MenuState.COLLAPSED);
      setMenuWidth(type === MenuType.SIDE_MENU ? 32 : 40);
      setChevronIcon(MenuIcons.EXPAND);
      onMenuStateChange?.(MenuState.COLLAPSED);
    }
  };

  const onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink): void => {
    ev?.preventDefault?.();
    if (item && item.onItemClick) item?.onItemClick(item, ev);
  };

  const filterOptions = (searchValue: string): void => {
    const lowerCaseVal = searchValue.toLowerCase();
    const filteredArray = groups
      .filter(ele => ele.items.some(subEle => subEle.name.toLowerCase().indexOf(lowerCaseVal) > -1))
      .map(ele => Object.assign(
        {},
        ele,
        { items: ele.items.filter(subEle => subEle.name.toLowerCase().indexOf(lowerCaseVal) > -1) },
      ));
    const mItems = mapMenuItems(filteredArray || groups);
    setNavLinks(mItems);
  };

  const mapMenuItems = (menuItems: GroupItem[]): INavLinkGroup[] => {
    const mItems = menuItems?.map(ele => (
      {
        name: ele?.heading,
        links: ele?.items.map(subItem => {
          const toolTipOptions = subItem?.toolTipOptions;
          const title = toolTipOptions?.toolTipText ? toolTipOptions?.toolTipText : "";
          return {
            title: !(toolTipOptions?.onCreateClick || toolTipOptions?.onViewClick) || subItem.disabled ? title : "",
            name: subItem?.name,
            key: subItem?.id,
            icon: subItem?.icon,
            disabled: subItem.disabled,
            url: "",
            target: "_self",
            onItemClick: () => subItem?.onClick?.(subItem.id),
            toolTipOptions: subItem?.toolTipOptions,
            testId: subItem?.testId,
          };
        }),
      } as INavLinkGroup
    ));
    return mItems;
  };

  const navStyles: Partial<INavStyles> = {
    root: { height: "auto", width: menuWdith, overflowY: "auto", overflowX: "hidden" },
    navItems: { display: internalMenuState },
    groupContent: { marginBottom: 0 },
    group: { display: internalMenuState, fontWeight: FontWeights.semibold },
    linkText: {
      display: internalMenuState,
      color: MenuColors.BLACK,
      fontWeight: 400,
      fontSize: 13,
      fontStyle: "normal",
    },
    link: {
      height: 32,
      lineHeight: 32,
      paddingLeft: 7,
      "&::after": { borderLeft: 0 },
      ".is-selected &": { backgroundColor: getSelectedColor(type, rememberSelection) },
      ".is-selected:hover &": { backgroundColor: getHoverColor(type) },
      ".ms-Nav-compositeLink:hover &": { background: getHoverColor(type) },
      ".is-disabled:hover &": { background: type === MenuType.SIDE_MENU ? MenuColors.WHITE : MenuColors.GRAY },
      ":hover": { background: internalMenuState === MenuState.COLLAPSED ? getHoverColor(type) : "" },
    },
  };

  const renderGroupHeader = (group?: INavLinkGroup): JSX.Element => (
    <span id={`o4a-menu-heading-wrapper-${group?.name}`}>
      <p
        id={`o4a-menu-heading-${group?.name}`}
        style={{ margin: "2px 0px 2px 14px" }}
      >
        {group?.name}
      </p>
      <hr
        id={`o4a-menu-heading-line-${group?.name}`}
        style={{ width: "200px", border: "none", height: "1px", background: "darkgray" }}
      />
    </span>
  );

  const onRenderOption = (
    option?: IDropdownOption,
    defaultRender?: (option?: IDropdownOption,
    ) => JSX.Element | null,
  ): JSX.Element => {
    if (option && defaultRender) {
      const defaultOption = defaultRender(option);
      return (
        <span
          style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", padding: 2 }}
          title={option?.text}
        >
          {defaultOption}
        </span>
      );
    }
    return <div />;
  };

  const onRenderTitle = (
    options?: IDropdownOption[],
    defaultRender?: (options?: IDropdownOption[],
    ) => JSX.Element | null,
  ): JSX.Element => {
    if (options && defaultRender) {
      const defaultTitle = defaultRender(options);
      return (
        <span title={options[0].text}>{defaultTitle}</span>
      );
    }
    return <div />;
  };

  if (type === MenuType.TOP_MENU) {
    delete navStyles.group;
    delete navStyles.navItems;
  }

  return (
    <>
      {type === MenuType.DROPDOWN_MENU && (
      <Dropdown
        responsiveMode={ResponsiveMode.large}
        calloutProps={{ calloutMaxHeight: 170 }}
        data-test-id={testId}
        selectedKey={selectedKey || undefined}
        options={dropDownOptions}
        onRenderTitle={onRenderTitle}
        onRenderOption={onRenderOption}
        onChange={(_, ele) => {
          groups.forEach(group => {
            group.items.forEach(item => {
              if (item.id === ele?.id) {
                item.onClick?.(ele.id);
              }
            });
          });
        }}
        styles={{ dropdown: { width } }}
      />
      )}
      {type !== MenuType.DROPDOWN_MENU && (
      <Stack
        data-test-id={testId}
        styles={{
          root: {
            display: "grid",
            gridColumn: "1/3",
            gridTemplateRows: "max-content",
            cursor: internalMenuState === MenuState.COLLAPSED && type === MenuType.SIDE_MENU ? "pointer" : "default",
            height: "100%",
            ":hover": {
              background: (internalMenuState === MenuState.COLLAPSED
              && type === MenuType.SIDE_MENU ? "#f3f2f1" : ""),
            },
            backgroundColor,
            boxShadow: insetShadow ? "0 1px 4px rgba(0,0,0,.3),0 0 40px rgba(0,0,0,.1) inset" : "",
          },
        }}
        onClick={(type === MenuType.SIDE_MENU && internalMenuState === MenuState.COLLAPSED) ? collapseMenu : undefined}
      >
        <Stack
          horizontal
          styles={{
            root: {
              alignItems: "center",
              width: menuWdith,
              justifyContent: isCollapsible ? "start" : "space-around",
              "&:hover": { backgroundColor: type === MenuType.TOP_MENU && isCollapsible ? getHoverColor(type) : "" },
            },
          }}
          onClick={(ev: React.MouseEvent<HTMLElement>) => {
            if ((type === MenuType.TOP_MENU && hideSearchBox)
            || (type === MenuType.SIDE_MENU && internalMenuState === MenuState.COLLAPSED)) collapseMenu();
            else ev.stopPropagation();
          }}
        >
          {!hideSearchBox && (
          <Stack styles={{ root: { display: internalMenuState, paddingBottom: 8, marginTop: 3 } }}>
            <SearchBox
              styles={{
                root: {
                  width: (parseInt(width.toString(), 10) - 40),
                  height: 25,
                  marginLeft: isCollapsible ? 3 : 0,
                  padding: 0,
                  ".is-active &": { marginLeft: isCollapsible ? 3 : 0 },
                },
                iconContainer: { width: undefined },
                clearButton: { ".ms-Icon": { fontSize: 8 }, padding: 1 },
              }}
              showIcon
              value={searchPhrase}
              placeholder={placeholder || Messages.hints.searchPlaceholder()}
              onClear={() => setSearchPhrase("")}
              onChange={(_, searchValue) => setSearchPhrase(searchValue)}
              onSearch={searchValue => setSearchPhrase(searchValue)}
              onClick={(ev: React.MouseEvent<HTMLInputElement>) => ev?.stopPropagation()}
            />
          </Stack>
          )}
          {type === MenuType.SIDE_MENU && isCollapsible && (
          <Stack styles={{ root: { paddingBottom: 6 } }}>
            <div
              style={{
                height: internalMenuState !== MenuState.COLLAPSED ? 25 : undefined,
                width: (parseInt(width.toString(), 10) - 40),
              }}
            />
          </Stack>
          )}
          {type === MenuType.TOP_MENU && isCollapsible && (
          <IconButton
            iconProps={{
              iconName: chevronIcon,
              styles: {
                root: {
                  fontSize: 10,
                  "button:hover &": { color: MenuColors.DARK_GRAY },
                  ":hover": { background: getHoverColor(type) },
                },
              },
            }}
            styles={{
              root: {
                width: menuWdith,
                color: MenuColors.DARK_GRAY,
                ":hover": {
                  backgroundColor: getHoverColor(type),
                  background: getHoverColor(type),
                },
              },
              flexContainer: internalMenuState !== MenuState.COLLAPSED ? { justifyContent: "end" } : { paddingLeft: 8 },
            }}
            title={internalMenuState === MenuState.COLLAPSED
              ? Messages.ariaLabel.expand() : Messages.ariaLabel.collapse()}
            ariaLabel={internalMenuState === MenuState.COLLAPSED
              ? Messages.ariaLabel.expand() : Messages.ariaLabel.collapse()}
            onClick={collapseMenu}
          />
          )}
        </Stack>
        <Stack
          id="o4a-menu-container"
          onClick={(ev: React.MouseEvent<HTMLElement>) => {
            const { className } = ev.target as HTMLElement;
            if ((type !== MenuType.SIDE_MENU && internalMenuState !== MenuState.COLLAPSED)
          && ((ev.target as HTMLElement).id?.indexOf("o4a-menu-") > -1
          || (typeof className === "string" && className.indexOf("ms-Nav-group") > -1))) {
              ev.stopPropagation();
            }
          }}
        >
          <Nav
            styles={navStyles}
            focusZoneProps={{ isCircularNavigation: true }}
            onRenderGroupHeader={renderGroupHeader}
            groups={navLinks}
            onLinkClick={onLinkClick}
            initialSelectedKey={selected}
            selectedKey={selected}
            linkAs={props => getUrlTemplate(props)}
          />
        </Stack>
        {isCollapsible && type === MenuType.SIDE_MENU && (
        <Stack
          styles={{
            root: {
              display: "grid",
              gridRow: "1/2",
              gridColumn: internalMenuState !== MenuState.COLLAPSED ? "2/3" : "1/2",
              height: 27,
              marginLeft: internalMenuState !== MenuState.COLLAPSED ? -32 : 0,
            },
          }}
        >
          <IconButton
            iconProps={{
              iconName: chevronIcon,
              styles: {
                root: {
                  fontSize: 10,
                  marginBottom: 1,
                  "button:hover &": { color: MenuColors.DARK_GRAY },
                  ":hover": { background: getHoverColor(type) },
                },
              },
            }}
            styles={{
              root: {
                paddingBottom: 6,
                color: MenuColors.DARK_GRAY,
                ":hover": {
                  backgroundColor: getHoverColor(type),
                  background: getHoverColor(type),
                },
              },
            }}
            onClick={collapseMenu}
            title={internalMenuState === MenuState.COLLAPSED
              ? Messages.ariaLabel.expand() : Messages.ariaLabel.collapse()}
            ariaLabel={internalMenuState === MenuState.COLLAPSED
              ? Messages.ariaLabel.expand() : Messages.ariaLabel.collapse()}
          />
        </Stack>
        )}
      </Stack>
      )}
    </>
  );
};
