import * as React from "react";
import { IButtonStyles, IconButton, IIconProps } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { emptyDivStyle } from "./SimpleBuilderInternalTypes";

const deleteButtonStyles: IButtonStyles = {
  flexContainer: { display: "inline-block" },
  root: {
    boxSizing: "content-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: "#0078d4",
    width: "16px",
    height: "16px",
    padding: "4px 8px 6px 8px",
    marginTop: "4px",
  },
};

const iconProps: IIconProps = { iconName: "Delete" };

export interface DeleteButtonProps {
  /**
   * Controls whether to display the button
   */
  show?: boolean;
  /**
   * Callback used to perform an action when the button is clicked
   */
  onClick?: () => void;
  /**
   * The ID to obtain the element during testing
   */
  testId?: string;
}

/**
 * Ensures consistent sizing if shown or not
 */
export const DeleteButton = ({ show, onClick, testId }: DeleteButtonProps): JSX.Element => {
  if (!show) {
    // display an empty space for the template row to keep alignment
    return (
      <div style={emptyDivStyle} />
    );
  }

  return (
    <IconButton
      styles={deleteButtonStyles}
      onClick={onClick}
      data-test-id={testId}
      ariaLabel={Messages.ariaLabel.delete()}
      iconProps={iconProps}
    />
  );
};
