import * as React from "react";
import { IProcessedStyleSet, IStackStyles, Label, Link, mergeStyleSets, Stack, Text } from "@fluentui/react";
import { LabelInfoTooltip, RequiredTooltip } from "../Tooltip";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayout } from "./FormInputGroup";
import {
  baseLabelStyles,
  compactSubFieldBorder,
  linkStylesCompact,
  linkStylesWide,
  renderErrorMessages,
  renderStatusBar,
  wideLayoutLabelWidth,
  wideSubFieldBorder,
} from "./InputCommon";
import { buildLabelWrapperTestIds, LabelWrapperProps } from "./LabelWrapperTypes";

interface InternalLabelProps extends Pick<BaseInputProps<never>, "fieldName"> {
  /**
   * The input component to be displayed
   */
  children: JSX.Element | string;
  /**
   * Flag that tells whether or not an HTML form control is to be associated with the label.
   * If true will render an HTML label element.
   * If false will render the text styled the same as the label.
   */
  noHtmlFor: boolean;
}

// The HTML label element should not be used without an HTML form control
//  (according to https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label).
// If a custom control requires a label we should make sure to set the aria-label of the custom control
// to the same value that is to be displayed here.
//
// The preferred method is to associate a label with a custom control using aria-labelledby,
// but that method requires that the label have an ID and the custom control know it.
//
// The downside of this is that the user is not able to focus the input by clicking on the label.
// We could implement this, but Azure does not have this functionality for the same use case that
// we are using this for - the select component, which is a custom implementation on both ends and
// does not have an HTML input associated with it.
const InternalLabel = ({ children, fieldName, noHtmlFor }: InternalLabelProps): JSX.Element => {
  if (noHtmlFor) {
    return <Text styles={baseLabelStyles}>{children}</Text>;
  }

  return (
    <Label
      htmlFor={fieldName}
      styles={baseLabelStyles}
    >
      {children}
    </Label>
  );
};

const stackItemStylesWide = { root: { minWidth: `${wideLayoutLabelWidth}px` } };

const subFieldStyles = { root: { paddingLeft: "5px" } };
const labelSubFieldStyles = mergeStyleSets(baseLabelStyles, subFieldStyles);

export interface InternalLabelWrapperProps extends LabelWrapperProps {
  /**
   * Flag that tells whether or not an HTML form control is to be associated with the label.
   * If true will render an HTML label element.
   * If false will render the text styled the same as the label.
   * @default false
   */
  noHtmlFor?: boolean;
}

export const InternalLabelWrapper = ({
  errors,
  fieldName,
  layout,
  width,
  minWidth,
  required,
  statusInfo,
  subField,
  tooltip,
  children,
  label,
  inputLink,
  horizontal,
  reversed,
  testId,
  noHtmlFor = false,
}: InternalLabelWrapperProps): JSX.Element => {
  const isWideLayout = layout === FormInputGroupLayout.WIDE;
  const hasWideSubField = subField && isWideLayout;

  const labelWrapperTestIds = buildLabelWrapperTestIds(testId);

  const labelWrapperStyle = {
    width: isWideLayout ? `${wideLayoutLabelWidth}px` : "100%",
    maxWidth: isWideLayout ? `${wideLayoutLabelWidth}px` : "100%",
  };

  const elementWrapperStyle = {
    width: isWideLayout ? `calc(100% - ${wideLayoutLabelWidth}px - 5px)` : undefined,
    maxWidth: isWideLayout ? `calc(100% - ${wideLayoutLabelWidth}px - 5px)` : undefined,
  };

  // 53px is the width of the subField
  // plus its left margin
  // plus the padding between the label and the subField indicator
  // -> 45 + 3 + 5
  const labelFixedWidth = hasWideSubField ? "calc(100% - 53px)" : "100%";

  const labelStyle = {
    minWidth: labelFixedWidth,
    width: labelFixedWidth,
    maxWidth: labelFixedWidth,
  };

  const getWrappedInputStyles = (): IProcessedStyleSet<IStackStyles> => {
    let paddingBottom: string | undefined;
    // Inputs within our forms all want extra padding (those that have a label)
    // If it is not within the form we need consistent spacing between the input and the
    // errors or infoblock (input link provides this spacing by default)
    if (!inputLink && (label || errors.length || statusInfo)) paddingBottom = "10px";
    // Inputs within the SimpleBuilder do not want extra padding
    else if (!inputLink && !label) paddingBottom = "5px";
    // By default the inputLink will provide extra padding

    const wrappedInputStyles = mergeStyleSets(
      { root: { paddingBottom } },
      { root: { justifyContent: reversed ? "flex-end" : "space-between" } },
      { root: { width: (subField && (!isWideLayout || reversed)) ? "calc(100% - 53px)" : "100%" } },
    );

    return wrappedInputStyles;
  };

  const link = (
    <Link
      data-test-id={inputLink?.testId}
      styles={
        layout === FormInputGroupLayout.COMPACT
          ? linkStylesCompact
          : linkStylesWide
      }
      onClick={() => { inputLink?.onLinkClick?.(); }}
    >
      <span id={inputLink?.id}>{inputLink?.text}</span>
    </Link>
  );

  const subFieldSeperator = <span style={isWideLayout ? wideSubFieldBorder : compactSubFieldBorder}>{ }</span>;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const containerStyle = React.useMemo(() => ({ width: width ?? "100%", minWidth: minWidth ?? "107px" }), []);

  return (
    <Stack style={containerStyle}>
      <Stack horizontal tokens={{ childrenGap: "5px" }}>
        {subField && (!isWideLayout || reversed) && subFieldSeperator}
        <Stack
          tokens={{ childrenGap: "5px" }}
          reversed={reversed}
          horizontal={horizontal !== undefined ? horizontal : isWideLayout}
          styles={getWrappedInputStyles()}
        >
          {label && (
          <Stack
            horizontal
            styles={isWideLayout && !reversed ? stackItemStylesWide : undefined}
            data-test-id={labelWrapperTestIds.label}
          >
            <Stack
              horizontal
              style={labelWrapperStyle}
            >
              {hasWideSubField && !reversed && subFieldSeperator}
              <Stack
                horizontal
                style={labelStyle}
                styles={hasWideSubField && !reversed ? labelSubFieldStyles : baseLabelStyles}
              >
                <InternalLabel
                  fieldName={fieldName}
                  noHtmlFor={noHtmlFor}
                >
                  {label}
                </InternalLabel>
                {required && <RequiredTooltip />}
                {tooltip !== undefined && (
                  <LabelInfoTooltip
                    tooltip={tooltip}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
          )}
          <div style={elementWrapperStyle}>
            {children}
          </div>
        </Stack>
      </Stack>
      <Stack>
        {inputLink !== undefined && link}
        {errors.length > 0 && renderErrorMessages(errors, layout, labelWrapperTestIds.error)}
        {statusInfo ? renderStatusBar(statusInfo, layout) : null}
      </Stack>
    </Stack>
  );
};
