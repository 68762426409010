import * as React from "react";
import { Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { useErrors } from "../../hooks/useErrors";
import { FilterOptions } from "../Filters/FilterTypes";
import { Listing } from "../Listing/Listing";
import { EmptyListProps, SelectionMode, SortDirections } from "../Listing/ListingTypes";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  InternalFormContext,
  InternalFormState,
} from "./FormInternalTypes";
import { FormValues, ValidationState } from "./FormTypes";
import { LabelWrapper } from "./LabelWrapper";
import { LabelWrapperProps } from "./LabelWrapperTypes";

export type SelectTableSortDirections = SortDirections;

export interface SelectTableComponent <T> {
  /**
   * A function that removes all selection from the table.
   */
  resetTableSelection: () => void;
  /**
   * A function that returns the selected items.
   */
  getCurrentSelection: () => SelectTableItem <T> [];
}

export interface SelectTableSortOptions {
  /**
   * The locale used for sorting.
   */
  locale: string;
  /**
   * Initially sorted column.
   */
  initialSortedColumn?: string;
}

export interface SelectTableDefaultSelection {
  /**
   * The property that holds the primary key of the item.
   */
  uniqueItemProp: string;
  /**
   * List of the primary keys identifying the items to be selected.
   */
  values: string [];
}

export interface SelectTableColumn {
  /**
   * Unique key that identifies the column.
   */
  itemProp: string;
  /**
   * id used to get the SelectTableColumns header element.
   */
  testId?: string;
  /**
   * Column display name.
   */
  name: string;
  /**
   * The width of the column is a portion of the available space equal to this value divided by the sum
   * of all proportional column widths in the list.
   */
  flexGrow?: number;
  /**
   * Specifies if the column can be resized
   */
  isResizable?: boolean;
  /**
   * Function to render a custom component.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRenderItems?: (value: any) => JSX.Element;
  /**
   * Initial sort direction of the column.
   */
  initialSortDirection?: SelectTableSortDirections;
  /**
   * Custom comparator to use when sorting.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  comparator?: (a: any, b: any) => 0 | -1 | 1
}

export interface SelectTableFilterOptions extends FilterOptions {
  /**
   * Clear filter callback function
   */
  onClearFilters?: () => void;
}

export interface SelectTableGroupingOption {
  /**
   *  Key of the select option
   */
  key: string | number;
  /**
   *  Display name of the option
   */
  text: string;
}

export interface SelectTableGroupingSelect {
  /**
   * List of grouping options
   */
  groupingOptions: SelectTableGroupingOption[],
  /**
   * Default selected grouping option
   */
  defaultSelectedKey?: string,
  /**
   * Handler that triggers when the selected grouping option changes
   */
  // eslint-disable-next-line max-len
  onGroupingChange?: (event: React.FormEvent<HTMLDivElement>, option?: SelectTableGroupingOption, index?: number) => void
}

interface SelectTableFooter {
  /**
   * Label for the footer, this will be displayed in bold.
   */
  label?: string;
  /**
   * Text for the footer to display extra instructions to the user.
   */
  text: string;
}

export type SelectTableItem <K> = K & {
  /**
   * The unique ID of the option
   */
  id: string;
  /**
   * Should the row selection be disabled.
   * @default false
   */
  disabled?: boolean;
};

export type SelectTableEmptyProps = Pick<EmptyListProps, "testId" | "title" | "description" | "watermark">;

export interface SelectTableProps<T> extends
  Pick<LabelWrapperProps, "label" | "testId" | "required">,
  Pick<BaseInputProps<FormValues[] | undefined>, "onChange" | "validator" | "fieldName">{
  /**
   * List of items to display in the table.
   */
  items: SelectTableItem<T> [];
  /**
   * List of columns to display in the Select Table.
   */
  columns: SelectTableColumn [];
  /**
   * A reference to the Select Table.
   */
  componentRef?: (listing: SelectTableComponent <T>) => void;
  /**
   * Sorting proprties.
   */
  sortOptions? : SelectTableSortOptions;
  /**
   * Shows the loading indicator when set to true.
   * @default false
   */
  isLoading?: boolean;
  /**
   * Select items grouping criteria
   */
  groupingSelect?: SelectTableGroupingSelect;
  /**
   * Options to filter items in the list
   */
  filtering?: SelectTableFilterOptions;
  /**
   * A footer text to display under the table.
   */
  footer?: SelectTableFooter;
  /**
   * Default selection options to preselect elements in the Select Table.
   */
  defaultSelection?: SelectTableDefaultSelection;
  /**
   * Option to select multiple values.
   * @default false
   */
  multiSelect?: boolean;
  /**
   * Show select all checkbox
   * @default false
   */
  showSelectAll?: boolean;
  /**
   * A placeholder to display when the table is empty
   */
  emptyList?: SelectTableEmptyProps;
}

/**
 * SelectTable allows user to display selectable data in a table format.
 */

export const SelectTable = <T extends object>({
  columns,
  items,
  sortOptions,
  isLoading,
  componentRef,
  fieldName,
  label,
  required,
  testId,
  groupingSelect,
  filtering,
  footer,
  defaultSelection,
  multiSelect,
  showSelectAll = false,
  validator,
  onChange,
  emptyList,
}: SelectTableProps<T>): JSX.Element => {
  const { groupName, layout } = React.useContext(FormInputGroupLayoutContext);
  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);
  const form: InternalFormState = React.useContext(InternalFormContext);
  const [allErrors, setFieldErrors] = useErrors(fieldName, groupName);
  if (!Object.keys(form).length) {
    throw new Error("SelectTable should be used within form");
  }
  const onItemSelected = (selectedItems: SelectTableItem<T>[]): void => {
    const newItems = selectedItems.length ? selectedItems : undefined;
    onChange?.(newItems);
    form.setValue(newItems, fieldName, groupName);
    if (validator || required) {
      fieldValidation();
    }
  };

  const extractInputValue = (formValue: SelectTableItem<T>[] | undefined): SelectTableItem<T>[] | undefined => {
    if (!formValue?.length) return undefined;
    return formValue;
  };

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, groupName);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    if (validator || required) {
      form.registerField(fieldName, groupName);
    }

    if (defaultSelection?.values.length) {
      const selectedValues = items.filter(item => defaultSelection.values.includes(item.id));
      form.setValue(selectedValues.length ? selectedValues : undefined, fieldName, groupName);
      fieldValidation();
    } else if (required) {
      form.setFieldState(ValidationState.INVALID, fieldName, groupName);
    }

    return function cleanup() {
      if (validator || required) {
        form.unRegisterField(fieldName, groupName);
      }
      form.deleteField(fieldName, groupName);
    };

  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldValidation = (): void => {
    const value = form.getValue<SelectTableItem<T>[]>(fieldName, groupName);
    const errorMsg = validator ? validator(value) : undefined;
    const requiredMessage = required ? !value
      ? [Messages.validation.requiredValidation()] : undefined : undefined;
    const errors = [...requiredMessage || [], ...errorMsg || []];
    form.setFieldState(
      errors && errors?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      groupName,
    );
    setFieldErrors(errors);
  };

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[groupName] === FormValidationMode.ON) {
      if (validator || required) {
        fieldValidation();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  const isRowSelectionDisabled = (item: SelectTableItem<T>): boolean => !!item.disabled;

  return (
    <LabelWrapper
      errors={allErrors}
      fieldName={fieldName}
      layout={layout}
      label={label}
      required={required}
      testId={testId}
    >
      <Stack>
        <Stack.Item>
          <Listing
            listColumns={columns}
            items={items}
            selectionMode={multiSelect ? SelectionMode.multiple : SelectionMode.single}
            sorting={sortOptions}
            filtering={filtering}
            groupingSelect={groupingSelect}
            isLoading={isLoading}
            selectedItems={onItemSelected}
            isRowSelectionDisabled={isRowSelectionDisabled}
            listingComponentRef={componentRef}
            defaultSelection={defaultSelection}
            hideSelectAll={!showSelectAll}
            emptyList={emptyList}
          />
        </Stack.Item>
        <Stack.Item>
          <p>
            <b>{footer?.label}</b>
            {footer?.text}
          </p>
        </Stack.Item>
      </Stack>
    </LabelWrapper>
  );
};
