import * as React from "react";
import { TemplateInputProps } from "./SimpleBuilderInternalTypes";

/**
 * TemplateInputProps provides the information the SimpleBuilder requires to acheive certain functionality
 * Your custom component may not provide the same information exactly matching the desired keys.
 * This provides the interface a wrapper would need to potentially expose.
 *
 * If you want to dynamically pass the values down to the children you need to follow this components pattern
 * and implement your own wrapper extending the TemplateInputProps.
 */
export interface SimpleBuilderCellProps extends TemplateInputProps {
  children: JSX.Element;
}

export const SimpleBuilderCell = ({ minWidth, width, children }: SimpleBuilderCellProps): JSX.Element => (
  <span style={{ width, minWidth }}>
    {children}
  </span>
);
