import * as React from "react";
import {
  DefaultButton,
  FocusTrapCallout,
  IButtonStyles,
  mergeStyleSets,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { FormInputGroupLayout } from "../Input/FormInputGroup";
import { FormStates } from "../Input/FormInternalTypes";
import { FormValues, ValidationState } from "../Input/FormTypes";
import { InputForm, InputFormComponent, InputFormInitialState, InputFormProps } from "./InputForm";
import { SubmitButtonMode } from "./InputFormTypes";

export interface CalloutComponent {
  allowResubmit: () => void;
}
export interface InputFormCalloutProps extends
  Pick<InputFormProps, "onSubmit" | "children"> {
  /**
   * title of the callout
   */
  title: string;
  /**
   * id of the element to with which callout will be attached
   */
  targetId: string;
  /**
   * Specify how will submit button initially show (enabled/disabled)
   * @default ENABLE_TILL_CLICKED
   */
  submitButtonMode?: SubmitButtonMode;
  /**
   * text for primary button. Default: Ok
   */
  primaryButtonText?: string;
  /**
   * text for default button. Default: Discard
   */
  defaultButtonText?: string;
  /**
   * close or dismiss the callout
   */
  onClose?: () => void;
  /**
   * Component reference for the callout
   */
  componentRef?: (ref: CalloutComponent) => void;

  /**
   * Callout container width
   */
  customWidth?: number;
  /**
   * Id used to get sub elements of the InputFormCallout
   */
  testId?: string;
  /**
   * Initial field values. Used by the component to enable submit button if any has been modified by the user.
   * Setting this prop implicitly will set submitButtonMode to DISABLE_TILL_VALID regardless of what has been set
   * by the consumer.
   */
  initialValues?: FormValues;
  /**
   * Layout for the InputFormCallout component
   * @default COMPACT
   */
  layout?: FormInputGroupLayout;
}

const enum PrimaryActionState {
  WAIT_CLICK = "WAIT_CLICK",
  CLICKED = "CLICKED",
}

const btnStyles: IButtonStyles = { root: { height: 25 } };

export interface InputFormCalloutTestIds extends ComponentTestIds {
  title: string;
  primaryButton: string;
  defaultButton: string;
}

export const buildInputFormCalloutTestIds = (testId?: string): InputFormCalloutTestIds => ({
  component: buildTestId(testId),
  title: buildTestId(testId, "-title"),
  primaryButton: buildTestId(testId, "-primary-button"),
  defaultButton: buildTestId(testId, "-default-button"),
});

/**
 *The InputFormCallout renders the InputForm component within a
 callout
 */
export const InputFormCallout = ({
  title,
  targetId,
  layout = FormInputGroupLayout.COMPACT,
  onClose,
  onSubmit,
  componentRef,
  initialValues,
  submitButtonMode = initialValues ? SubmitButtonMode.DISABLE_TILL_VALID : SubmitButtonMode.ENABLE_TILL_CLICKED,
  primaryButtonText,
  defaultButtonText,
  children,
  customWidth,
  testId,
}: InputFormCalloutProps): JSX.Element => {
  const [formStates, setFormStates] = React.useState<FormStates>({} as FormStates);
  const [overallState, setOverallState] = React.useState<ValidationState>();
  const [primaryAction, setPrimaryAction] = React.useState<PrimaryActionState>(PrimaryActionState.WAIT_CLICK);
  const [initialValuesChanged, setInitialValuesChanged] = React.useState<boolean>(false);
  const [initialFocus, setInitialFocus] = React.useState<HTMLElement>();

  const inputFormCalloutTestIds = buildInputFormCalloutTestIds(testId);

  const styles = mergeStyleSets({
    callout: {
      width: customWidth || 360,
      padding: "18px",
    },
    buttons: {
      justifyContent: "flex-start",
      marginTop: 3,
    },
  });

  const thisRef = React.useRef<CalloutComponent>();

  React.useEffect(() => {
    if (componentRef) {
      thisRef.current = { allowResubmit: () => setPrimaryAction(PrimaryActionState.WAIT_CLICK) } as CalloutComponent;
      componentRef(thisRef.current);
    }
    // Only at mount time. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (initialFocus) {
      setTimeout(() => {
        initialFocus.focus();
      }, 100);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFocus]);

  React.useEffect(() => {
    setOverallState(formStates.overall);
  }, [formStates]);

  React.useEffect(() => {
    if (overallState === ValidationState.INVALID) {
      setPrimaryAction(PrimaryActionState.WAIT_CLICK);
    } else if ((!overallState || overallState === ValidationState.VALID)
      && primaryAction === PrimaryActionState.CLICKED) {
      formComponentRef.current?.submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallState, primaryAction]);

  const formComponentRef = React.useRef<InputFormComponent>();
  const setFormComponentRef = (formComponent: InputFormComponent): void => {
    formComponentRef.current = formComponent;
  };

  const onClick = (): void => {
    if (!formComponentRef.current?.isValidationEnabled()) {
      formComponentRef.current?.enableValidation();
    }
    setPrimaryAction(PrimaryActionState.CLICKED);
  };

  const onFormStatesUpdate = (states: FormStates): void => setFormStates(states);

  const primaryButtonDisabled = (
    (submitButtonMode === SubmitButtonMode.DISABLE_TILL_VALID && (
      (formStates.overall === ValidationState.INVALID) || !initialValuesChanged
    ))
    // ENABLE_TILL_CLICKED will not work as intended!!
    // This is due to initial input state now potentially including INVALID, not just UNKNOWN.
    || (submitButtonMode === SubmitButtonMode.ENABLE_TILL_CLICKED && formStates.overall === ValidationState.INVALID)
  );

  const internalInitialState: InputFormInitialState = {
    fieldValues: initialValues,
    haveChanged: setInitialValuesChanged,
  };

  return (
    <FocusTrapCallout
      role="dialog"
      focusTrapProps={{
        firstFocusableTarget: (element: HTMLElement): HTMLElement | null => {
          const focusable: NodeListOf<HTMLElement> = element?.querySelectorAll(
            // eslint-disable-next-line max-len
            "button, div:not([hidden]) > [href], input, select, textarea, [tabindex]:not([tabindex='-1']):not([aria-hidden='true'])",
          );
          const firstFocusable = focusable?.[0];
          setInitialFocus(firstFocusable);
          return firstFocusable;
        },
      }}
      data-test-id={inputFormCalloutTestIds.component}
      isBeakVisible
      setInitialFocus
      target={`#${targetId}`}
      onDismiss={onClose}
      className={styles.callout}
      title={title}
    >
      <Text
        data-test-id={inputFormCalloutTestIds.title}
        block
        styles={{
          root: {
            fontSize: 24,
            fontWeight: 600,
            marginBottom: 10,
          },
        }}
      >
        {title}
      </Text>
      <InputForm
        onSubmit={onSubmit}
        componentRef={setFormComponentRef}
        layout={layout}
        onStatesUpdate={onFormStatesUpdate}
        initialState={internalInitialState}
      >
        {children}
        <Stack className={styles.buttons} tokens={{ childrenGap: 8 }} horizontal>
          <PrimaryButton
            styles={btnStyles}
            onClick={onClick}
            disabled={primaryButtonDisabled}
            data-test-id={inputFormCalloutTestIds.primaryButton}
          >
            {primaryButtonText || Messages.common.ok()}
          </PrimaryButton>
          <DefaultButton
            data-test-id={inputFormCalloutTestIds.defaultButton}
            styles={btnStyles}
            onClick={onClose}
          >
            {defaultButtonText || Messages.common.discard()}
          </DefaultButton>
        </Stack>
      </InputForm>
    </FocusTrapCallout>
  );
};
