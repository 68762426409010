import * as React from "react";
import { IShimmerStyles, ITextFieldStyles, Shimmer, TextField } from "@fluentui/react";
import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { uniqueGUID } from "../../helpers/util";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayoutContext } from "./FormInputGroup";
import { InternalFormContext, InternalFormState } from "./FormInternalTypes";
import { LabelWrapper } from "./LabelWrapper";
import { buildLabelWrapperTestIds, LabelWrapperProps, LabelWrapperTestIds } from "./LabelWrapperTypes";
import { TextInputMultilineConfig, TextInputTypes } from "./TextInput";

export interface ReadOnlyTextProps
  extends Omit<BaseInputProps<string>, "onChange" | "readOnly" | "validator">,
  Pick<LabelWrapperProps, "label"> {
  /**
   * multiline config for the text field.
   */
  multiline?: TextInputMultilineConfig,
  /**
   * Display Shimmer when value is loading
   */
  loading?: boolean;
}

const textFieldStyles: Partial<ITextFieldStyles> = {
  root: { width: "100%" },
  fieldGroup: { height: "24px" },
};

const textAreaStyles: Partial<ITextFieldStyles> = { root: { width: "100%" } };

const shimmerStyles: Partial<IShimmerStyles> = {
  root: {
    width: "100%",
    // This rule needs to be applied to the component that is the direct child of the LabelWrapper.
    // It works because the LabelWrapper applies a 'child gap', but the implementation is a margin
    // applied to the direct child
    marginTop: "5px !important",
  },
  dataWrapper: { position: "relative" },
};

export type ReadOnlyTextTestIds = ComponentTestIds & LabelWrapperTestIds;

export const buildReadOnlyTextTestIds = (testId?: string): ReadOnlyTextTestIds => ({
  ...buildLabelWrapperTestIds(testId),
  component: buildTestId(testId),
});

/**
 * Render a read-only TextInput that does not have an imprint on the Form
 */
export const ReadOnlyText = ({
  testId,
  fieldName,
  label,
  required,
  tooltip,
  disabled,
  defaultValue,
  subField,
  statusInfo,
  placeholder,
  multiline,
  loading,
  ariaLabel,
}: ReadOnlyTextProps): JSX.Element => {
  const { layout } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("ReadOnlyText should be used within form");
  }

  const [key, setKey] = React.useState<string>(uniqueGUID());

  React.useEffect(() => {
    setKey(uniqueGUID());
  }, [defaultValue]);

  const textInputTestIds = buildReadOnlyTextTestIds(testId);

  const selectedStyles = multiline
    ? textAreaStyles
    : textFieldStyles;

  return (
    <LabelWrapper
      errors={[]}
      fieldName={fieldName}
      layout={layout}
      label={label}
      subField={subField}
      required={required}
      tooltip={tooltip}
      statusInfo={statusInfo}
      testId={testId}
    >
      <Shimmer isDataLoaded={!loading} styles={shimmerStyles}>
        <TextField
          key={key}
          id={fieldName}
          readOnly
          disabled={disabled}
          data-test-id={textInputTestIds.component}
          ariaLabel={ariaLabel || label}
          defaultValue={defaultValue}
          type={TextInputTypes.TEXT}
          placeholder={placeholder}
          multiline={!!multiline}
          rows={multiline?.rows}
          resizable={!!((multiline && multiline.resizable === true))}
          styles={selectedStyles}
          borderless={!disabled}
          tabIndex={-1}
        />
      </Shimmer>
    </LabelWrapper>
  );
};
