import * as React from "react";
import { Icon, IIconStyles, Link } from "@fluentui/react";
import { NavigationLink } from "../Navigation/Navigation";
import { CustomNavigation, ListingDisplayNameLinkProps, NavigationType, PageNavigation } from "./ListingTypes";

const iconStyle: IIconStyles = {
  root: {
    width: "24px",
    height: "24px",
    top: "3px",
    position: "absolute",
    display: "flex",
  },
};

const getLinkStyle = (hasIcon?: boolean): React.CSSProperties => ({
  marginLeft: hasIcon ? "31px" : undefined,
  width: hasIcon ? "calc(100% - 31px)" : "100%",
});

// eslint-disable-next-line @typescript-eslint/comma-dangle
const isPageNavigation = <T,>(nav: NavigationType<T>): boolean => "to" in nav;

/**
 * A Name link that supports an optional icon to be displayed inside listings
 */

export const ListingDisplayNameLink = <T extends object >({
  displayName,
  navigation,
  iconName,
}: ListingDisplayNameLinkProps<T>): JSX.Element => (
  <span>
    {iconName && (<Icon iconName={iconName} styles={iconStyle} />)}
    <span style={getLinkStyle(!!iconName)}>
      {isPageNavigation(navigation) ? (
        <NavigationLink
          title={displayName}
          to={(navigation as PageNavigation<T>).to}
          pageKey={(navigation as PageNavigation<T>).pageKey}
          customData={(navigation as PageNavigation<T>).customData}
        >
          {displayName}
        </NavigationLink>
      ) : (
        <Link title={displayName} onClick={(navigation as CustomNavigation).onClick}>
          {displayName}
        </Link>
      )}
    </span>
  </span>
  );
