import * as React from "react";
import { ILabelStyles, IProcessedStyleSet, Label, mergeStyleSets, Stack } from "@fluentui/react";
import { LabelInfoTooltip, RequiredTooltip } from "../Tooltip/Tooltip";
import { BaseInputProps } from "./BaseInput";
import { InternalFormContext, InternalFormState } from "./FormInternalTypes";
import {
  baseLabelStyles,
  labelStyles,
  stackItemStyles,
  stackItemStylesWithToolTip,
  wideSubFieldBorder,
} from "./InputCommon";

const subFieldStyles = { root: { paddingLeft: "5px" } };

const getLabelStyles = ({
  tooltip,
  subField,
}: Pick<FieldWrapperProps, "tooltip" | "subField">): IProcessedStyleSet<ILabelStyles> => (
  mergeStyleSets(
    baseLabelStyles,
    !tooltip ? labelStyles : {},
    subField ? subFieldStyles : {},
  )
);

export interface FieldWrapperProps extends Pick<BaseInputProps<never>, "required" | "tooltip" | "subField"> {
  /**
   * label for FieldWrapper
   */
  label?: string;
  /**
   * Components to be displayed wrapped with FieldWrapper
   */
  children: React.ReactNode;
  /**
   * Minimum width for label wrapper
   * @default: 250px
   */
  minWidth?: number;
}

export const FieldWrapper = (
  { label, minWidth = 250, required, subField, tooltip, children }: FieldWrapperProps,
): JSX.Element => {
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("FieldWrapper should be used within form");
  }

  const labelMarkup = (
    <Stack
      horizontal
      styles={tooltip !== undefined ? stackItemStylesWithToolTip : undefined}
    >
      <Stack horizontal style={{ minWidth: `${parseInt(minWidth.toString(), 10)}px` }}>
        {subField && <span style={wideSubFieldBorder}>{ }</span>}
        <Label styles={getLabelStyles({ tooltip, subField })}>
          {label}
          {label && required && <RequiredTooltip />}
          {label && tooltip !== undefined && (
            <LabelInfoTooltip
              tooltip={tooltip}
            />
          )}
        </Label>
      </Stack>
    </Stack>
  );

  const passPropsToReactElement = (element, props):JSX.Element => {
    if (React.isValidElement(element)) {
      return React.cloneElement(element, props);
    }
    return element;
  };

  const passPropsToChildren = (element, props):JSX.Element | JSX.Element[] => {
    if (!Array.isArray(element)) {
      return passPropsToReactElement(element, props);
    }
    return element.map(childElement => passPropsToReactElement(childElement, props));
  };

  const fieldWrapper = (
    <Stack
      horizontal
      styles={stackItemStyles}
    >
      {labelMarkup}
      <Stack style={{ width: "100%" }}>
        {label && passPropsToChildren(children, { label })}
        {!label && children}
      </Stack>
    </Stack>
  );

  return fieldWrapper;
};
