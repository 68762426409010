import { buildTestId, ComponentTestIds } from "../../helpers/testIdHelper";
import { TagProps } from "../Tags/TagItem";

export enum MetaItemsGroupsDirection {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export enum MetaItemGroupLayoutType {
  MULTI_COLUMNS = "MULTI_COLUMNS",
  MULTI_COLUMNS_COMPACT = "MULTI_COLUMNS_COMPACT",
  SINGLE_COLUMN = "SINGLE_COLUMN",
  SINGLE_COLUMN_COMPACT = "SINGLE_COLUMN_COMPACT",
}

export interface LabelMetaItemAction {
  /**
   * Unique identifier
   */
  id?: string;
  /**
   * Text to be displayed
   */
  label: string;
  /**
   * Function to be executed when the element is clicked
   */
  onClick: () => void;
  /**
   * id used to get the action link
   */
  testId?: string;
  /**
   * Option to enable or disable the action
  */
  disabled?: boolean;
}

export interface LabelMetaItemProps {
  /**
   * Label for the LabelMetaItem
   */
  label: string;
  /**
   * Option to display on the LabelMetaItem
   */
  children: string | JSX.Element;
  /**
   * Callback when clicked on the LabelMetaItem
   */
  action?: LabelMetaItemAction;
  /**
   * Flag to indicate if separator needs to be shown
   */
  hideSeparator?: boolean;
  /**
   * Shows a shimmer if the value is still loading
   */
  loading?: boolean;
  /**
   * Id used to get the label meta item elements for unit tests
   */
  testId?: string;
}

export interface LabelMetaItemTestIds extends ComponentTestIds {
  /**
   * The label of meta item
   */
  label: string,
  /**
   * The value of the meta item
   */
  value: string,
}

export const buildLabelMetaItemTestIds = (baseTestId?: string) : LabelMetaItemTestIds => ({
  component: buildTestId(baseTestId),
  label: buildTestId(baseTestId, "-label"),
  value: buildTestId(baseTestId, "-value"),
});

export interface TagsAction {
  /**
   * Id used to get the action element for unit tests
   */
  testId?: string;
  /**
   * Function to be executed when the element is clicked
   */
  onClick: () => void;
}

export type TagsAddAction = TagsAction;
export interface TagsEditAction extends TagsAction {
  /**
   * Unique identifier
   */
  id: string;
}

export interface Tags {
  /**
   * Id used to get the tags meta item elements for unit tests
   */
  testId?: string;
  /**
   * Tags name-value pairs
   */
  items?: TagProps[];
  /**
   * Add tags action configuration
   */
  add: TagsAddAction;
  /**
   * Edit tags action configuration
   */
  edit: TagsEditAction;
}
export interface MetaItemGroupsLayoutProps {
  /**
   * The direction in which the groups will be displayed
   * @default horizontal
   */
  direction?: MetaItemsGroupsDirection;
  /**
   * Groups to be displayed
   */
  children: JSX.Element | JSX.Element[];
  /**
   * Tags row configuration
   */
  tags?: Tags;
  /**
   * Space between groups in px
   */
  gap?: number;
  /**
   * Width of the LabelMetaItem label in px
   * @default 120
   */
  labelWidth?: number;
}
