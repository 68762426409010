/* eslint-disable no-await-in-loop */
import { v4 as uuidV4 } from "uuid";
import { filterTelemetryURLsByRegion } from "../utils/internalUtils";
import Producer from "./Producer";
import Consumer from "./Consumer";
import IndexedDBHandler from "./IndexedDBHandler";
import ResilientFetch from "./ResilientFetch";
import { SERVICE_LIMIT_MULTIPLIER, TIMER_INTERVAL } from "../utils/constants";
import { TokenAuthenticator, TelemetryClientData, FetchApiSignature } from "../models";

/**
 * Config for TelemetryProcessor
 * storeName - Name of the database
 * timeInterval - time after which we call telemetry service
 * recordProcessor - Information to add to the record once the user authenticates
 * APIEndpoint - /v1/tracking-events or /v1/metrics
 */
export type TelemetryProcessorConfig<T> = {
  storeName: string;
  timeInterval: number;
  fetchApi?: FetchApiSignature;

  readonly recordProcessor: (record: T) => T;
  readonly APIEndpoint: string;
  readonly maxRetries: number;
  readonly serviceLimit: number;
  readonly telemetryURLs?: string[];
};

export default class TelemetryProcessor<T> {
  private isSessionActive: () => Promise<boolean>;
  private config: TelemetryProcessorConfig<T>;

  private producer: Producer<T>;
  private indexedDBHandler: IndexedDBHandler<T>;
  private resilientFetch: ResilientFetch<T>;
  private movingFromIndexedDB: boolean;
  private recordProcessor: (record: T) => T;

  /**
   * Constructor for TelemetryProcessor
   * @param config TelemetryProcessorConfig<T>
   */
  public constructor(config: TelemetryProcessorConfig<T>) {
    this.config = { ...config };
    const consumer = new Consumer<T>(
      this.config.timeInterval,
      this.config.serviceLimit,
      this.processRecords.bind(this),
    );

    this.producer = consumer.producer;
    this.indexedDBHandler = new IndexedDBHandler<T>(this.config.storeName);
    this.resilientFetch = new ResilientFetch<T>(TIMER_INTERVAL, 2, this.config.maxRetries);
    this.recordProcessor = this.config.recordProcessor;

    if (this.config.fetchApi) {
      this.resilientFetch.setInternalFetchApi(this.config.fetchApi);
    }
  }

  public setAuthInstance(authInstance: TokenAuthenticator) {
    this.config.fetchApi = authInstance.getFetchApi;

    this.isSessionActive = authInstance.isSessionActive;
    this.resilientFetch?.setInternalFetchApi(this.config.fetchApi);
  }

  /**
   * Function used by the consumer to fetch logs from the record buffer and post them to console telemetry
   * @param records: Data (logs or metrics) we want to send to console telemetry service
   */
  private async processRecords(records: T[]) {
    if (records.length === 0) {
      return;
    }
    const filterTelemetryURLs = filterTelemetryURLsByRegion(this.config.telemetryURLs, records[0]);
    const urls = filterTelemetryURLs.map(
      telemetryURL => `${telemetryURL.replace(/\/+$/, "")}${this.config.APIEndpoint}`,
    );
    while (urls.length > 0) {
      const success = await this.resilientFetch.process(urls[0], records);
      if (success) {
        break;
      } else {
        urls.shift();
      }
    }
  }

  /**
   * This function is used to fetch records from the IndexedDB and move them to the record buffer.
   */
  private async moveRecordsFromIndexedDB() {
    this.movingFromIndexedDB = true;
    const records = await this.indexedDBHandler.getRecords();
    for (let i = 0; i < records.length; i++) {
      this.producer.add(this.recordProcessor(records[i].payload));
    }
    this.movingFromIndexedDB = false;
  }

  private async addRecordToIndexedDB(numberOfRecords: number, record: TelemetryClientData<T>) {
    if (numberOfRecords < SERVICE_LIMIT_MULTIPLIER * this.config.serviceLimit) {
      const nonce = uuidV4();
      await this.indexedDBHandler.setItem(nonce, record);
    } else {
      // We need to prevent indexedDB from getting over-populated.
      console.debug("Limit Exceeded.");
    }
  }

  /**
   * Add data (log or metric) to the record buffer if authenticated, indexedDB if unauthenticated.
   * @param record: log or metric to add
   */
  public async add(record: TelemetryClientData<T>) {
    const { telemetryURLs } = this.config;
    const numberOfRecords = await this.indexedDBHandler.getLength();

    /**
     * By default we assume that your fetchApi always uses an active session.
     * Otherwise, provide an isSessionActive function.
     */
    let isActive = this.config.fetchApi !== undefined;
    if (this.isSessionActive) {
      isActive = await this.isSessionActive();
    }

    if (!telemetryURLs || !isActive) {
      await this.addRecordToIndexedDB(numberOfRecords, record);
      return;
    }

    if (numberOfRecords > 0 && !this.movingFromIndexedDB) {
      await this.moveRecordsFromIndexedDB();
    }
    this.producer.add(this.recordProcessor(record.payload));
  }
}
