import * as React from "react";
import { IStackTokens, Link, Stack } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { MetaItemGap } from "../../constants/uiConstants";
import { TagsDisplay } from "../Tags/TagsDisplay";
import { LabelMetaItem } from "./LabelMetaItem";
import {
  LabelMetaItemLayoutType,
  MetaItemGroupsLayoutContext,
  MetaItemGroupsLayoutInternalProps,
} from "./MetaItemInternalTypes";
import { MetaItemGroupLayoutType, MetaItemsGroupsDirection } from "./MetaItemTypes";

export const MetaItemGroupsLayout = ({
  direction = MetaItemsGroupsDirection.Horizontal,
  children,
  tags,
  gap = direction && direction === MetaItemsGroupsDirection.Horizontal ? 50 : 30,
  labelWidth = 120,
  layoutType = MetaItemGroupLayoutType.MULTI_COLUMNS,
}: MetaItemGroupsLayoutInternalProps): JSX.Element => {
  const elements = Array.isArray(children) ? children : [children];
  const labelWidthValue = React.useMemo(() => {
    let labelLayoutType = LabelMetaItemLayoutType.WIDE;
    switch (layoutType) {
      case MetaItemGroupLayoutType.SINGLE_COLUMN_COMPACT:
      case MetaItemGroupLayoutType.MULTI_COLUMNS_COMPACT:
        labelLayoutType = LabelMetaItemLayoutType.COMPACT;
        break;
      case MetaItemGroupLayoutType.SINGLE_COLUMN:
      case MetaItemGroupLayoutType.MULTI_COLUMNS:
      default:
        labelLayoutType = LabelMetaItemLayoutType.WIDE;
        break;
    }
    return { labelWidth, labelLayoutType };
  }, [labelWidth, layoutType]);

  const isHorizontal = (
    direction === MetaItemsGroupsDirection.Horizontal
    && (
      layoutType === MetaItemGroupLayoutType.MULTI_COLUMNS
      || layoutType === MetaItemGroupLayoutType.MULTI_COLUMNS_COMPACT
    ));

  const gapCount = elements.length - 1;
  const width = isHorizontal
    ? `calc(calc(100% - ${gap * gapCount}px) / ${elements.length})`
    : "auto";

  const childrenGap = !isHorizontal && direction === MetaItemsGroupsDirection.Vertical ? gap : MetaItemGap;
  const childrenStackTokens: IStackTokens = { childrenGap };

  return (
    <MetaItemGroupsLayoutContext.Provider value={labelWidthValue}>
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack
          tokens={childrenStackTokens}
          horizontal={isHorizontal}
        >
          {elements.map((element, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`element-${key}`} style={{ width: `${width}` }}>
              {element}
            </div>
          ))}
        </Stack>
        {tags && (
          <LabelMetaItem
            testId={tags.testId}
            label={Messages.labels.tags()}
            action={{
              id: tags.edit.id,
              testId: tags.edit.testId,
              label: Messages.actions.edit(),
              onClick: tags.edit.onClick,
            }}
          >
            {tags.items?.length
              ? (
                <TagsDisplay tags={tags.items} />
              )
              : (
                <Link
                  onClick={tags.add.onClick}
                  data-test-id={tags.add.testId}
                  underline
                >
                  {Messages.actions.addTags()}
                </Link>
              )}
          </LabelMetaItem>
        )}
      </Stack>
    </MetaItemGroupsLayoutContext.Provider>
  );
};
