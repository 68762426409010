import * as React from "react";
import { IFocusZone, IPivotItemProps, IPivotStyles, Pivot, PivotItem, registerIcons } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { inputWizardLayoutManager } from "../../models/LayoutManager";
import { FormattedString } from "../FormattedString/FormattedString";
import { Form } from "../Input/Form";
import { FormInputGroup, FormInputGroupLayout } from "../Input/FormInputGroup";
import { FormStates } from "../Input/FormInternalTypes";
import { FormInterface, FormValues, ValidationState } from "../Input/FormTypes";
import { CustomOverflowButton } from "../InternalComponents/CustomOverflowButton";
import { CircleMultiplySolid } from "../Svg/icons";
import { InputWizardComponent, InputWizardProps, InputWizardTabNavigation } from "./InputWizardTypes";

const enum NavigationActionKind {
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
  GOTO = "GOTO",
  LAST = "LAST",
}

type NavigationAction = {
  kind: NavigationActionKind;
  value?: number;
};

const pivotStyles: Partial<IPivotStyles> = {
  root: { marginBottom: "12px" },
  link: { height: "36px" },
};

const FocusZoneId = "input-wizard-focus-zone-id";

registerIcons({ icons: { "error-svg": CircleMultiplySolid } });

const InputWizardPivotId = "input-wizard-pivot";

/**
 * The InputWizard component renders the pivot items within a form with options
 * to navigate between each pivot item.
 */
export const InputWizard = (
  {
    componentRef,
    onSubmit,
    onStatesUpdate,
    onValueUpdate,
    items,
    tabNavigation = InputWizardTabNavigation.FREE,
    validator,
  }: InputWizardProps,
): JSX.Element => {
  function selectedKeyReducer(selectedKey: number, navigationAction: NavigationAction): number {
    const count = items.length;
    let key = selectedKey;
    const currentKey = selectedKey;
    switch (navigationAction.kind) {
      case NavigationActionKind.NEXT:
        if ((key + 1) < count) {
          key += 1;
        }
        break;
      case NavigationActionKind.PREVIOUS:
        if ((key - 1) >= 0) {
          key -= 1;
        }
        break;
      case NavigationActionKind.GOTO:
        if (navigationAction.value !== undefined) {
          if (tabNavigation === InputWizardTabNavigation.FREE
              || (tabNavigation === InputWizardTabNavigation.SEQUENTIAL && navigationAction.value < currentKey)) {
            // For SEQUENTIAL navigation block any tab selection beyond teh current one
            key = navigationAction.value;
          }
        }
        break;
      case NavigationActionKind.LAST:
        key = count - 1;
        break;
      default:
        break;
    }
    if (currentKey !== key) {
      items[currentKey].onUnselecting?.(currentKey);
    }
    return key;
  }

  const pivotContainerRef = React.useRef<HTMLElement | undefined>();
  const { width: pivotWidth } = useResizeObserver(pivotContainerRef);
  const [contentLayoutType, setContentLayoutType] = React.useState<FormInputGroupLayout>();

  const [selectedKey, dispatch] = React.useReducer(selectedKeyReducer, 0);
  const [formStates, setFormStates] = React.useState<FormStates>({} as FormStates);

  const formRef = React.useRef<FormInterface>();
  const setFormRef = (form: FormInterface): void => {
    formRef.current = form;
  };

  const focusZoneRef = React.createRef<IFocusZone>();

  React.useLayoutEffect(() => {
    const inputWizardContentLayoutType = inputWizardLayoutManager.getContentLayoutType(pivotWidth);
    setContentLayoutType(inputWizardContentLayoutType);
    // console.log(`>>> ${pivotWidth} -> ${inputWizardContentLayoutType}`);
  }, [pivotWidth]);

  const enableValidation = (group?: string, noNavigation = false): void => {
    formRef.current?.enableValidation(group);
    if (!noNavigation) {
      dispatch({ kind: NavigationActionKind.LAST });
    }
  };
  const getFormFieldLabelMap = (): Map<string, string> | undefined => formRef.current?.getFormFieldLabelMap();

  const getFormFieldGroupMap = (): Map<string, string> | undefined => formRef.current?.getFormFieldGroupMap();

  const getFormValues = (): FormValues | undefined => formRef.current?.getFormValues();

  const isValidationEnabled = (group?: string): boolean | undefined => formRef.current?.isValidationEnabled(group);

  const hasValidation = (group?: string): boolean | undefined => formRef.current?.hasValidation(group);

  const submit = (): void => {
    formRef.current?.submit();
  };

  const next = (): void => {
    dispatch({ kind: NavigationActionKind.NEXT });
  };

  const previous = (): void => {
    dispatch({ kind: NavigationActionKind.PREVIOUS });
  };

  React.useEffect(() => {
    if (componentRef) {
      componentRef({
        enableValidation,
        isValidationEnabled,
        hasValidation,
        submit,
        next,
        previous,
        getFormValues,
        getFormFieldLabelMap,
        getFormFieldGroupMap,
      } as InputWizardComponent);
    }

    pivotContainerRef.current = document.getElementById(InputWizardPivotId) ?? undefined;

  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // Set focus to the selected tab
    const focusZoneElement = document.getElementById(FocusZoneId);
    const tabsButtons = focusZoneElement?.querySelectorAll("button");
    const selectedTabButton = tabsButtons?.[selectedKey];
    if (selectedTabButton) {
      focusZoneRef.current?.focusElement(selectedTabButton);
    }

    // Notify caller to the change in selected tab
    if (items[selectedKey].onSelected) {
      items[selectedKey].onSelected?.(selectedKey);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey]);

  const onFormStatesUpdate = (
    states: FormStates,
    fieldState: ValidationState,
    field: string,
    group?: string,
  ): void => {
    setFormStates(states);
    if (onStatesUpdate) {
      onStatesUpdate(states, fieldState, field, group);
    }
  };

  const itemsGroups = (): string[] => items.map(item => item.groupName);

  const pivotItems = (): JSX.Element[] => items.map((item, ndx) => {
    const customRenderer = (
      link?: IPivotItemProps,
      defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
    ): JSX.Element | null => {
      if (!link || !defaultRenderer) {
        return null;
      }

      const isGroupInvalid = formStates.groups?.[item.groupName] === ValidationState.INVALID;

      let style = {
        flex: "0 1 100%",
        height: "36px",
      };

      if (tabNavigation === InputWizardTabNavigation.SEQUENTIAL && ndx > selectedKey) {
        style = {
          ...style,
          ...{
            opacity: "0.7",
            color: "#808080",
            filter: "grayscale(100%)",
            cursor: "default",
          },
        };
      }

      const validatonEnabled = formRef.current?.isValidationEnabled()
        || formRef.current?.isValidationEnabled(item.groupName);

      const showValidationError = isGroupInvalid && validatonEnabled && tabNavigation === InputWizardTabNavigation.FREE;

      return (
        <span style={style}>
          {defaultRenderer({
            ...link,
            itemIcon: showValidationError ? link.itemIcon : undefined,
          })}
        </span>
      );
    };

    const headerText = tabNavigation === InputWizardTabNavigation.SEQUENTIAL
      ? `${ndx + 1}. ${item.header}`
      : item.header;

    return (
      <PivotItem
        key={ndx.toString()}
        itemKey={ndx.toString()}
        headerText={headerText}
        headerButtonProps={
          item.testId
            ? { "data-test-id": item.testId }
            : undefined
        }
        alwaysRender
        itemIcon="error-svg"
        onRenderItemLink={customRenderer}
      >
        <FormInputGroup
          groupName={item.groupName}
          layout={contentLayoutType === FormInputGroupLayout.WIDE
            ? FormInputGroupLayout.WIDE
            : FormInputGroupLayout.COMPACT}
          style={{ paddingLeft: "8px", maxWidth: "700px" }}
        >
          {item.description && (
            <div style={{ marginBottom: "20px" }}>
              <FormattedString inputText={item.description} />
            </div>
          )}
          {item.content}
        </FormInputGroup>
      </PivotItem>
    );
  });

  const onLinkClick = (pivotItem?: PivotItem): void => dispatch({
    kind: NavigationActionKind.GOTO,
    value: Number(pivotItem?.props.itemKey),
  });

  return (
    <Form
      componentRef={setFormRef}
      groups={itemsGroups()}
      onStatesUpdate={onFormStatesUpdate}
      onValueUpdate={onValueUpdate}
      onSubmit={onSubmit}
      validator={validator}
    >
      <Pivot
        id={InputWizardPivotId}
        styles={pivotStyles}
        selectedKey={selectedKey.toString()}
        onLinkClick={onLinkClick}
        overflowBehavior="menu"
        overflowButtonAs={CustomOverflowButton}
        overflowAriaLabel={Messages.ariaLabel.more()}
        focusZoneProps={{
          id: FocusZoneId,
          componentRef: focusZoneRef,
        }}
      >
        {pivotItems()}
      </Pivot>
    </Form>
  );
};
