import * as React from "react";
import { ISliderStyles, ITextFieldStyles, mergeStyleSets, Slider, Stack, Text, TextField } from "@fluentui/react";
import * as Messages from "../../codegen/Messages";
import { buildTestId } from "../../helpers/testIdHelper";
import { uniqueGUID } from "../../helpers/util";
import { useErrors } from "../../hooks/useErrors";
import { InfoBlockProps } from "../InfoBlock/InfoBlock";
import { BaseInputProps } from "./BaseInput";
import { FormInputGroupLayout, FormInputGroupLayoutContext } from "./FormInputGroup";
import {
  FormGroupValidationModeContext,
  FormValidationMode,
  FormValidationModeContext,
  InternalFormContext,
  InternalFormState,
} from "./FormInternalTypes";
import { ValidationState } from "./FormTypes";
import { InternalLabelWrapper } from "./InternalLabelWrapper";
import { buildLabelWrapperTestIds, LabelWrapperProps, LabelWrapperTestIds } from "./LabelWrapperTypes";

export interface ComputedField {
  label?: string,
  onChange: (value: number) => number | string;
}

const classNames = mergeStyleSets(
  {
    markerContainer: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    markerStyles: {
      width: "1px",
      height: "6px",
      backgroundColor: "white",
      position: "absolute",
    },
  },
);

const sliderStyles: Partial<ISliderStyles> = {
  root: {
    width: "100%",
    height: "24px",
    marginTop: "5px",
    minWidth: "50px",
  },
  lineContainer: { height: "6px" },
  thumb: { top: "-5px" },
};

const enabledSliderStyles: Partial<ISliderStyles> = {
  activeSection: { background: "#8a2da5" },
  slideBox: { ":hover": { ".ms-Slider-inactive": { background: "#b3d6f2" } } },
};

const disabledSliderStyles: Partial<ISliderStyles> = {
  slideBox: {
    ":hover": {
      ".ms-Slider-inactive": { background: "rgb(243, 242, 241)" },
      ".ms-Slider-active": { background: "rgb(243, 242, 241)" },
    },
  },
};

const disabledTextStyles: Partial<ITextFieldStyles> = { root: { border: "1px solid #a19f9d" } };

const textLabelStyles = { marginTop: "-23px", fontSize: "smaller", width: "max-content" };

const inputTextStyles: Partial<ITextFieldStyles> = {
  root: { width: "75px", height: "24px", paddingTop: "5px" },
  field: { textAlign: "center" },
  fieldGroup: { height: "24px" },
};

export interface SliderInputProps extends BaseInputProps<number>, Pick<LabelWrapperProps, "label"> {
  /**
   * Min value for the SliderInput
   * @default 0
   */
  min?: number;
  /**
   * Max value for the SliderInput
   */
  max: number;
  /**
   * Incremental value for the SliderInput
   * @default 1
   */
  step?: number;
  /**
   * Option to display the message bar for the SliderInput
   */
  statusInfo?: InfoBlockProps;
  /**
   * Field to dislay the value computed with the slider input value
   */
  computedField?: ComputedField;
  /**
   * The default number for the SliderInput
   * @default min
   */
  defaultValue?: number;
}

export type SliderInputTestIds = {
  slider: string,
  input: string,
  computedFieldLabel: string,
  computedFieldValue: string,
} & LabelWrapperTestIds;

export const buildSliderInputTestIds = (testId?: string): SliderInputTestIds => ({
  ...buildLabelWrapperTestIds(testId),
  slider: buildTestId(testId, "-slider"),
  input: buildTestId(testId, "-sliderInput"),
  computedFieldLabel: buildTestId(testId, "-computedFieldLabel"),
  computedFieldValue: buildTestId(testId, "-computedFieldValue"),
});

/**
 * SliderInput component allows the caller to incrementally adjust a value in small steps.
 * It’s mainly used for numeric values.
 */
export const SliderInput = ({
  testId,
  fieldName,
  groupName,
  label,
  disabled,
  tooltip,
  min = 0,
  defaultValue = min,
  max,
  step = 1,
  statusInfo,
  subField,
  required,
  validator,
  onChange,
  ariaLabel,
  computedField,
}: SliderInputProps): JSX.Element => {
  const { groupName: layoutGroup, layout } = React.useContext(
    FormInputGroupLayoutContext,
  );
  const group = groupName || layoutGroup;
  const [allErrors, setFieldErrors] = useErrors(fieldName, group);
  const validationMode = React.useContext(FormValidationModeContext);
  const groupValidationMode = React.useContext(FormGroupValidationModeContext);
  const form: InternalFormState = React.useContext(InternalFormContext);
  if (!Object.keys(form).length) {
    throw new Error("SliderInput should be used within form");
  }
  const [inputValue, setInputValue] = React.useState<number>(defaultValue);
  const [sliderKey, setSliderKey] = React.useState<string>();
  const [textKey, setTextKey] = React.useState<string>();
  const [selectedValue, setSelectedValue] = React.useState<number>();

  const extractInputValue = (formValue: number): number => formValue;

  React.useEffect(() => {
    form.registerFieldLabel(fieldName, label);
    form.registerFieldGroup(fieldName, group);
    form.registerFieldExtractInputValue(fieldName, extractInputValue);

    if (validator) {
      form.registerField(fieldName, group);
    }
    form.setValue(defaultValue, fieldName, group);

    return function cleanup() {
      if (validator) {
        form.unRegisterField(fieldName, group);
      }
      form.deleteField(fieldName, group);
    };
  // Only at mount time. Others props should not have side effect beyond their initial values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldValidation = () : void => {
    const value = form.getValue<number>(fieldName, group) as number;
    const errors = validator?.(value);
    form.setFieldState(
      errors && errors?.length > 0 ? ValidationState.INVALID : ValidationState.VALID,
      fieldName,
      group,
    );
    setFieldErrors(errors);
  };

  /* Set the slider to the closest lower/upper range when value is provided by
  the text input field */
  const getClosestNumberInRange = (value: number): number => {
    const range : number[] = [];
    for (let i = defaultValue; i <= max; i += step) {
      range.push(i);
    }
    return range.reduce((prev, curr) => (Math.abs(curr - value) <= Math.abs(prev - value) ? curr : prev));
  };

  /* Calculate the markers needed to display on the slider , create a span tag for each
  to append it to the parent class */
  const setMarkers = () : void => {
    const markerContainerId = `slider-marker-container-${fieldName}`;
    const markerContainerElement = document.getElementById(markerContainerId);
    if (markerContainerElement !== null) {
      markerContainerElement?.parentNode?.removeChild(markerContainerElement);
    }
    const sliderLineContainer = document.getElementById(fieldName)?.getElementsByClassName("ms-Slider-line")[0];
    const sliderRange = max - defaultValue;
    const markerCount = sliderRange / step;
    const markerContainer = document.createElement("div");
    markerContainer.id = markerContainerId;
    markerContainer.className = classNames.markerContainer;
    sliderLineContainer?.appendChild(markerContainer);
    for (let i = 1; i < markerCount; i++) {
      const marker = document.createElement("span");
      marker.className = classNames.markerStyles;
      marker.style.left = `calc((100% / ${markerCount}) * ${i} )`;
      markerContainer.appendChild(marker);
    }
  };

  React.useEffect(() => {
    setMarkers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderKey]);

  const internalOnTextValidate = (): void => {
    let newValue: number = selectedValue as number;
    if (selectedValue !== undefined) {
      newValue = selectedValue;
      if (Number.isNaN(selectedValue) || undefined) {
        if (inputValue !== undefined) { newValue = inputValue; } else { newValue = defaultValue; }
      }
      const belowMin = newValue <= Number(defaultValue);
      const aboveMax = newValue >= Number(max);
      if (belowMin) newValue = defaultValue;
      else if (aboveMax) newValue = max;
      else newValue = getClosestNumberInRange(newValue);
    } else newValue = defaultValue;

    setInputValue(newValue);
    setSliderKey(uniqueGUID);
    setTextKey(uniqueGUID);
    form.setValue(newValue, fieldName, group);
    if (validator || required) {
      fieldValidation();
    }
  };

  const setSliderStyles = () : React.CSSProperties | undefined => {
    if (computedField?.label) {
      if (layout === FormInputGroupLayout.WIDE) {
        return { paddingTop: "25px" };
      }
    }
    return undefined;
  };

  const internalOnTextChange = (
    _: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string,
  ): void => {
    setSelectedValue(Number(newValue));
  };

  const getComputedValue = (): string => {
    const value = form.getValue<number>(fieldName, group) as number;
    const computedValue = computedField?.onChange(value);

    if (computedValue !== undefined && Number.isNaN(computedValue)) return String(defaultValue);
    return String(computedValue);
  };

  const internalOnSliderChange = (
    value: number,
  ): void => {
    setInputValue(value);
    setSelectedValue(Number(value));
    setTextKey(uniqueGUID);
    form.setValue(value, fieldName, group);
    if (validator || required) {
      fieldValidation();
    }
    onChange?.(value);
  };

  React.useEffect(() => {
    if (validationMode === FormValidationMode.ON || groupValidationMode[group] === FormValidationMode.ON) {
      if (validator || required) {
        fieldValidation();
      }
    }
    // Only if validation mode changes. Others props should not have side effect beyond their initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMode, groupValidationMode]);

  const sliderInputTestIds = buildSliderInputTestIds(testId);

  return (
    <Stack style={setSliderStyles()}>
      <InternalLabelWrapper
        errors={allErrors}
        noHtmlFor
        fieldName={fieldName}
        layout={layout}
        label={label}
        required={required}
        statusInfo={statusInfo}
        subField={subField}
        tooltip={tooltip}
        testId={testId}
      >
        <Stack horizontal tokens={{ childrenGap: 5 }}>
          <Slider
            key={sliderKey}
            id={fieldName}
            data-test-id={sliderInputTestIds.slider}
            min={defaultValue}
            max={max}
            step={step}
            ariaLabel={ariaLabel || label}
            ranged={false}
            showValue={false}
            onChange={internalOnSliderChange}
            disabled={disabled}
            defaultValue={inputValue}
            styles={mergeStyleSets(sliderStyles, disabled ? disabledSliderStyles : enabledSliderStyles)}
          />
          <TextField
            key={textKey}
            data-test-id={sliderInputTestIds.input}
            ariaLabel={Messages.ariaLabel.sliderInput()}
            styles={mergeStyleSets(inputTextStyles, disabled ? disabledTextStyles : undefined)}
            defaultValue={String(inputValue)}
            onBlur={internalOnTextValidate}
            onChange={internalOnTextChange}
            validateOnFocusOut
            disabled={disabled}
          />
          {computedField
        && (
        <Stack style={{ justifyContent: "end" }} tokens={{ childrenGap: 8 }}>
          <Text
            style={textLabelStyles}
            data-test-id={sliderInputTestIds.computedFieldLabel}
          >
            {computedField?.label}
          </Text>
          <TextField
            readOnly
            data-test-id={sliderInputTestIds.computedFieldValue}
            borderless
            value={getComputedValue()}
            ariaLabel={computedField?.label || undefined}
          />
        </Stack>
        )}
        </Stack>
      </InternalLabelWrapper>
    </Stack>
  );
};
