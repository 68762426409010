import * as React from "react";
import { validateNetworkAddress } from "../../helpers";
import { uniqueGUID } from "../../helpers/util";
import { NetworkAddressValidationConfig } from "../../helpers/validationHelper";
import { InternalFormContext, InternalFormState } from "./FormInternalTypes";
import { buildTextInputTestIds, TextInput, TextInputProps, TextInputTestIds } from "./TextInput";

type BaseIpInputProps = Omit<TextInputProps,
"textType"
| "revealPassword"
| "revealPasswordAriaLabel"
| "multiline"
>;

export interface IpInputProps extends BaseIpInputProps {
  validation?: NetworkAddressValidationConfig;
}

export type IpInputTestIds = TextInputTestIds;
export const buildIpInputTestIds = buildTextInputTestIds;

export const IpInput = ({
  validation,
  validator,
  ...textInputProps
}: IpInputProps): JSX.Element => {
  const form: InternalFormState = React.useContext(InternalFormContext);

  if (!Object.keys(form).length) {
    throw new Error("IpInput should be used within form");
  }

  const [renderKey, setRenderKey] = React.useState(uniqueGUID());

  React.useEffect(() => {
    if (validation?.excludeAddresses && validation.excludeAddresses.length > 0) {
      setRenderKey(uniqueGUID());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(validation?.excludeAddresses ?? {})]);

  const fieldValidation = (value: string | undefined): string[] | undefined => {
    const errors: string[] = [];
    errors.push(...validateNetworkAddress(value, validation) || []);
    errors.push(...validator?.(value) || []);
    return errors.length ? errors : undefined;
  };

  return (
    <TextInput
      key={renderKey}
      validator={fieldValidation}
      {...textInputProps}
    />
  );
};
