/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import {
  ChoiceGroup,
  DefaultButton,
  Dropdown,
  DropdownMenuItemType,
  FocusTrapCallout,
  FontWeights,
  IChoiceGroupOption,
  Icon,
  IDropdownOption,
  IDropdownStyles,
  IRenderFunction,
  mergeStyleSets,
  PrimaryButton,
  registerIcons,
  ResponsiveMode,
} from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { FilterAll } from "../../constants/uiConstants";
import { uniqueGUID } from "../../helpers/util";
import { ListingColumn } from "../Listing/ListingTypes";
import { FilterPillTagSvg, FilterSvg } from "../Svg/icons";
import { FilterPillValueSelector } from "./FilterPillValueSelector";
import { buildFilterTestIds, ExtraFilter, FilterLayout, Operator } from "./FilterTypes";
import {
  deepCopy,
  emptyKeyStr,
  getAddModeSequenceValueOptions,
  getEditModeSequenceValueOptions,
  getFieldOptionsByName,
  getTagFieldValueOptionsByKey,
} from "./FilterUtils";

registerIcons({ icons: { "filter-svg": FilterSvg } });
registerIcons({ icons: { "pillTag-svg": FilterPillTagSvg } });

const classNames = mergeStyleSets({
  callout: {
    paddingLeft: "20px",
    paddingRight: " 20px",
    paddingTop: "12px",
    paddingBottom: "16px",
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: "block",
    marginTop: 20,
  },
  actionButtons: { paddingTop: "20px" },
  filterTitleOperator: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  isTagPillIconContainer: {
    display: "inline-block",
    paddingRight: "20px",
  },
  isTagPillIcon: {
    position: "absolute",
    marginTop: "-7px",
  },
  filterTitleValue: { fontWeight: "bold" },
  filterTitleTextContainer: {
    paddingRight: "2px",
    paddingLeft: "15px",
    maxWidth: "500px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "24px",
    backgroundColor: "#e6f2fb",
    marginLeft: "0px",
    marginRight: "8px",
    marginTop: "2px",
    marginBottom: "2px",
    fontSize: "13px",
    borderRadius: "16px",
    border: "1px solid #e6f2fb",
    cursor: "pointer",
    color: "#323130",
    display: "flex",
    alignItems: "center",
    lineHeight: "18px",
    ":hover": {
      borderColor: "#b3d7f2",
      backgroundColor: "#b3d7f2",
    },
    ":active": { border: "1px solid #015cda" },
    ":focus": { border: "1px solid #135995" },
    ":focus::after": { outline: "none !important" },
    whiteSpace: "nowrap",
  },
  filterTitleCloseIconContainer: {
    paddingLeft: "4px",
    marginLeft: "3px",
    borderRadius: "50%",
    paddingRight: "4px",
    lineHeight: "19px",
    ":hover": { backgroundColor: "#b3d7f2" },
  },
  filterTitleNoCloseIconContainer: {
    paddingLeft: "11px",
    marginLeft: "3px",
  },
  AddFilterButtonContainer: {
    display: "flex",
    paddingLeft: "15px",
    paddingRight: "15px",
    lineHeight: "21px",
    height: "24px",
    backgroundColor: "#e6f2fb",
    marginLeft: "0px",
    marginRight: "8px",
    marginTop: "2px",
    marginBottom: "2px",
    fontSize: "13px",
    borderRadius: "16px",
    border: "1px solid #e6f2fb",
    cursor: "pointer",
    color: "#323130",
    alignItems: "center",
    ":hover": {
      borderColor: "#b3d7f2",
      backgroundColor: "#b3d7f2",
    },
    ":active": { border: "1px solid #015cda" },
    ":focus": { border: "1px solid #135995" },
    ":focus::after": { outline: "none !important" },
  },
  AddFilterButtonText: { paddingLeft: "4px" },
  callOutTitle: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "1.33333",
    color: "#323130",
    paddingBottom: "5px",
  },
  filterNameContainerAddButton: { paddingBottom: "10px" },
  operatorSelectContainer: {
    paddingBottom: "16px",
    borderBottom: "1px solid rgba(204,204,204,.8)",
  },
  operatorSelectContainerAddButton: { paddingBottom: "10px" },
  filterValueContainer: {
    paddingBottom: "16px",
    borderBottom: "1px solid rgba(204,204,204,.8)",
  },
  filterValueContainerAddButton: { paddingBottom: "10px" },
  dropdownLayoutFilterValueContainer: {
    paddingBottom: "16px",
    paddingRight: "20px",
  },
  applyButton: {
    height: "24px",
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 600,
    cursor: "pointer",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "0 20px",
    margin: 0,
    marginRight: "10px",
  },
  cancelButton: {
    backgroundColor: "#fff",
    borderColor: "#8a8886",
    color: "#323130",
    fill: "#323130",
    height: "24px",
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 600,
    cursor: "pointer",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "0 20px",
    margin: 0,
  },
  AddFilterIconContainer: {
    paddingTop: "3px",
    lineHeight: "14px",
    marginLeft: "-3px",
    marginRight: "3px",
  },
  InputFieldLabelOfModal: {
    fontSize: "14px",
    fontWeight: "normal",
    color: "rgb(50, 49, 48)",
    boxSizing: "border-box",
    boxShadow: "none",
    margin: "0px",
    display: "inline-block",
    overflowWrap: "break-word",
  },
  InputFieldLabelOfAddModal: {
    padding: "5px 0px",
    width: "80px",
    "::after": {
      content: "' *'",
      color: "rgb(164, 38, 44)",
      paddingRight: "12px",
    },
  },
  InputFieldLabelOfEditModal: {
    padding: "5px 0px",
    "::after": {
      content: "' *'",
      color: "rgb(164, 38, 44)",
      paddingRight: "12px",
    },
  },
  InputFieldLabelOfDropDown: { padding: "0px" },
});

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    width: 440,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    paddingBottom: "0",
    margin: "3px 0px",
    borderColor: "rgba(204,204,204,.8)",
  },
  dropdownItemHeader: {
    color: "rgb(50, 49, 48)",
    FontWeights: 600,
  },
  title: {
    height: "24px",
    lineHeight: "22px",
  },
  label: { fontWeight: "normal" },
  caretDownWrapper: {
    height: "24px",
    lineHeight: "22px",
  },
};

const dropdownLayoutDropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    width: 250,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    paddingBottom: "0",
    margin: "3px 0px",
    borderColor: "rgba(204,204,204,.8)",
  },
  title: {
    height: "24px",
    lineHeight: "22px",
  },
  label: { fontWeight: "normal", padding: 0 },
  caretDownWrapper: {
    height: "24px",
    lineHeight: "22px",
  },
};

export enum IDropdownOptionType {
  Tag = "TAG",
  Non_Tag = "NON_TAG",
}

export interface IDropdownOptionWithType extends IDropdownOption {
  /**
   * Type of current drop down options, the value can be Tag type or Non_Tag
   */
  type?: IDropdownOptionType;
}

const dropdownStylesAddButton: Partial<IDropdownStyles> = {
  root: { display: "flex" },
  dropdownItemsWrapper: { maxHeight: "700px" },
  dropdownItemHeader: {
    color: "rgb(50, 49, 48)",
    FontWeights: 600,
  },
  dropdown: {
    width: 360,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    paddingBottom: "0",
    margin: "3px 0px",
    borderColor: "rgba(204,204,204,.8)",
  },
  title: {
    height: "24px",
    lineHeight: "22px",
  },
  label: { width: "80px", fontWeight: "normal" },
  caretDownWrapper: {
    height: "24px",
    lineHeight: "22px",
  },
};

export interface FilterOperationModalProps {
  /**
   * Current items of data input list
   */
  itemsInList: any[];

  /**
   * Items full data input list.
   * This can be used to track the label of the filter field when there is no current items in list.
   */
  items: any[];

  /**
   * Title of the filter operation modal
   */
  title?: string;

  /**
   * Initial default selected extra filter
   */
  defaultExtraFilter?: ExtraFilter;

  /**
   * All the available selected extra filters
   */
  allExtraFilters?: ExtraFilter[];

  /**
   * Apply action of the extra filter
   */
  onApply: (extraFilter: ExtraFilter) => void;

  /**
   * Delete action of the extra filter
   */
  onDelete?: (extraFilter: ExtraFilter) => void;

  /**
   * Extra filters that can be ignored in the filter dropdown options.
   * This can be used to hide the filter fields in the Add filter modal.
   */
  extraFiltersIgnored?: ExtraFilter[];

  /**
   * Layout type for the Filter
   */
  filterLayout: FilterLayout;

  /**
   * Text filter callback function
   */
  textFilterCallBack?: (item: any) => any;

  /**
   * All the current extra fillers that applied to the list items
   */
  currentExtraFilters?: ExtraFilter[];

  /**
   *Current text filter text
   */
  currentFilterText?: string;

  /**
   * Columns of data input list
   */
  columns?: ListingColumn[];

}

/**
 * FilterOperationModal is a component to add/edit extra filter.
 *
 */
export const FilterOperationModal = (
  {
    title,
    defaultExtraFilter,
    onApply,
    onDelete,
    itemsInList,
    items,
    allExtraFilters,
    extraFiltersIgnored,
    filterLayout,
    textFilterCallBack,
    currentExtraFilters,
    currentFilterText,
    columns,
  }: FilterOperationModalProps,
): JSX.Element => {
  const isEditMode = !!defaultExtraFilter;

  const operationModalCalloutId = "filterOperationModalCalloutId";
  const messageCatalogAllLowercase = Messages.common.all().toLowerCase();
  const messageCatalogAllFirstCapital = Messages.common.all();
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId("callout-button");
  const labelId = useId("callout-label");
  const descriptionId = useId("callout-description");
  const [filterName, setFilterName] = React.useState<IDropdownOption>();
  const [filterVal, setFilterVal] = React.useState<IDropdownOption | IDropdownOption[] | IChoiceGroupOption>();
  const [filterOperator, setFilterOperator] = React.useState<Operator>(
    isEditMode ? defaultExtraFilter?.operator as Operator : Operator.Equal,
  );
  const [defaultSelectedFilterValue, setDefaultSelectedFilterValue] = React.useState<string>();
  const [selectedFilterValueKeys, setSelectedFilterValueKeys] = React.useState<string[]>();
  const [editModeSequenceValueOptions, setEditModeSequenceValueOptions] = React.useState<IDropdownOption<any>[]>();
  const [pillValueSelectorKey, setPillValueSelectorKey] = React.useState<string>(uniqueGUID());
  const [editModeTagNameKey, setEditModeTagNameKey] = React.useState<string>(defaultExtraFilter?.fieldKey || "");
  const [
    editModeTagCurrentExtraFilters, setEditModeTagCurrentExtraFilters,
  ] = React.useState<ExtraFilter[]>(currentExtraFilters || []);

  const onFilterNameChange = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
  ): void => {
    setFilterName({
      key: option?.key?.toString() || "",
      text: option?.text?.toString() || "",
    });
  };

  let filterNameFieldOptions: IDropdownOption[] = [];
  allExtraFilters?.forEach(filter => {
    const isExist = extraFiltersIgnored?.find(f => f.fieldKey === filter.fieldKey);
    if (!isExist && !filter.isTagFilter) {
      filterNameFieldOptions.push({
        key: filter.fieldKey,
        text: filter.name,
      });
    }
  });

  const itemsTagsOptions: IDropdownOptionWithType[] = [];
  itemsInList.forEach(item => {
    if (item.freeformTags) {
      const tagKeys = Object.keys(item.freeformTags);
      tagKeys.forEach(tagKey => {
        const isKeyExist = itemsTagsOptions?.find(f => f.key.toString().toLowerCase() === tagKey.toLowerCase());
        const isPillExist = extraFiltersIgnored?.find(f => f.fieldKey.toLowerCase() === tagKey.toLowerCase());
        if (!isKeyExist && !isPillExist) {
          itemsTagsOptions.push({
            key: tagKey.toLowerCase(),
            text: tagKey,
            type: IDropdownOptionType.Tag,
          });
        }
      });
    }
  });
  if (itemsTagsOptions && itemsTagsOptions.length > 0) {
    filterNameFieldOptions.push(
      { key: "divider_1", text: emptyKeyStr, itemType: DropdownMenuItemType.Divider },
    );
    filterNameFieldOptions.push(
      { key: "tagHeader", text: Messages.common.tags(), itemType: DropdownMenuItemType.Header },
    );
    filterNameFieldOptions = filterNameFieldOptions.concat(itemsTagsOptions);
  }

  const getEditModeTagOptions = (): IDropdownOptionWithType[] => {
    const tempOptions: IDropdownOptionWithType[] = [];
    tempOptions.push(
      { key: "tagHeader", text: Messages.common.tags(), itemType: DropdownMenuItemType.Header },
    );
    items.forEach(item => {
      if (item.freeformTags) {
        const tagKeys = Object.keys(item.freeformTags);
        tagKeys.forEach(tagKey => {
          const isKeyExist = tempOptions?.find(f => f.key.toString().toLowerCase() === tagKey.toLowerCase());
          const isPillExist = extraFiltersIgnored?.find(f => f.fieldKey.toLowerCase() === tagKey.toLowerCase());
          if (!isKeyExist && (!isPillExist || tagKey.toLowerCase() === defaultExtraFilter?.fieldKey.toLowerCase())) {
            tempOptions.push({
              key: tagKey.toLowerCase(),
              text: tagKey,
              type: IDropdownOptionType.Tag,
            });
          }
        });
      }
    });
    return tempOptions;
  };

  const onEditModeTagOptionChange = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
  ): void => {
    setEditModeTagNameKey(option?.key.toString() || "");
    if (defaultExtraFilter) {
      const tempExtraFilter = deepCopy(defaultExtraFilter);
      const tempCurrentExtraFilters = deepCopy(currentExtraFilters);
      tempExtraFilter.name = option?.key.toString() || "";
      tempExtraFilter.fieldKey = option?.key.toString() || "";
      tempCurrentExtraFilters?.forEach(f => {
        if (f.fieldKey === defaultExtraFilter.fieldKey) {
          f.name = option?.key.toString() || "";
          f.fieldKey = option?.key.toString() || "";
        }
      });
      setEditModeSequenceValueOptions(
        getEditModeSequenceValueOptions(
          valueOptions,
          tempExtraFilter,
          items,
          tempCurrentExtraFilters,
          currentFilterText || "",
          textFilterCallBack,
          columns || [],
        ),
      );
      setEditModeTagCurrentExtraFilters(tempCurrentExtraFilters);
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    setEditModeTagCurrentExtraFilters(currentExtraFilters || []);
  }, [currentExtraFilters]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    setPillValueSelectorKey(uniqueGUID());
  }, [editModeSequenceValueOptions]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (isCalloutVisible) {
      setTimeout(() => {
        const firstFocusable = document.getElementById(operationModalCalloutId);
        firstFocusable?.focus();
      }, 100);
    }
  }, [isCalloutVisible]);

  const onPillFilterValueOptionChange = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
  ): void => {
    setFilterVal(option);
    setDefaultSelectedFilterValue(option?.key?.toString());
  };

  const buildRadioOptions = (
    dropdownOptions: IDropdownOption<any>[],
  ): IChoiceGroupOption[] => dropdownOptions as IChoiceGroupOption[];

  const onPillFilterValueRadioOptionChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption,
  ): void => {
    setFilterVal(option);
    setDefaultSelectedFilterValue(option?.key?.toString());
  };

  const onAddModePillFilterValueMultiOptionChange = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
  ): void => {
    let tempArr = filterVal as IDropdownOption[] || [];
    if (option?.selected === true) {
      const targetValueOptions = vOptions;
      if (option.key === FilterAll) {
        tempArr = [...targetValueOptions];
      } else {
        tempArr.push(option);
        const missing = targetValueOptions.filter(item => !tempArr.map(tr => tr.key).includes(item.key.toString()));
        if (missing.length === 1 && missing[0].key === FilterAll) {
          tempArr = [...targetValueOptions];
        }
      }
    } else if (option?.key === FilterAll) {
      tempArr = [];
    } else {
      tempArr = tempArr.filter(el => el.key !== option?.key);
      tempArr = tempArr.filter(el => el.key !== FilterAll);
    }
    setFilterVal(tempArr);
    setSelectedFilterValueKeys(tempArr.map(tr => (tr.key.toString())));
  };

  const onDropdownFilterValueOptionChange = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
  ): void => {
    setFilterVal(option);
    const val = option?.key?.toString();
    setDefaultSelectedFilterValue(val);
    if (val) {
      onApplyAction(val);
    }
  };

  const onApplyAction = (val: string | string[]): void => {
    let newItem: ExtraFilter;
    if (defaultExtraFilter) {
      newItem = {
        name: defaultExtraFilter.name || "",
        operator: filterOperator,
        value: val,
        fieldKey: defaultExtraFilter.fieldKey || "",
        hideAllOption: defaultExtraFilter.hideAllOption,
      };
      if (defaultExtraFilter.customOptions) {
        newItem.customOptions = defaultExtraFilter.customOptions;
      }
      if (defaultExtraFilter.messageCallBack) {
        newItem.messageCallBack = defaultExtraFilter.messageCallBack;
      }
      if (defaultExtraFilter.customFilterOperation) {
        newItem.customFilterOperation = defaultExtraFilter.customFilterOperation;
      }
      if (defaultExtraFilter.callBack) {
        newItem.callBack = defaultExtraFilter.callBack;
      }
      if (defaultExtraFilter.hideDelete) {
        newItem.hideDelete = defaultExtraFilter.hideDelete;
      }
      if (defaultExtraFilter.isTagFilter) {
        newItem.isTagFilter = defaultExtraFilter.isTagFilter;
        newItem.fieldKey = editModeTagNameKey || defaultExtraFilter.fieldKey;
        const tempName = getEditModeTagOptions()?.find(i => i.key === editModeTagNameKey)?.text;
        newItem.name = tempName || defaultExtraFilter.fieldKey;
      }
      if (defaultExtraFilter.operatorMessageCallBack) {
        newItem.operatorMessageCallBack = defaultExtraFilter.operatorMessageCallBack;
      }
      if (defaultExtraFilter.isRadioGroupStyle) {
        newItem.isRadioGroupStyle = defaultExtraFilter.isRadioGroupStyle;
      }
    } else {
      const isCustomFilterOperation = !!allExtraFilters?.find(
        item => item.fieldKey === filterName?.key.toString(),
      )?.customFilterOperation;
      newItem = {
        name: filterName?.text || "",
        operator: isCustomFilterOperation ? Operator.Custom : filterOperator,
        value: val,
        fieldKey: filterName?.key.toString() || "",
      };
      allExtraFilters?.forEach(filter => {
        if (filter.fieldKey === filterName?.key) {
          newItem.hideAllOption = filter.hideAllOption;
          if (filter.customOptions) {
            newItem.customOptions = filter.customOptions;
          }
          if (filter.messageCallBack) {
            newItem.messageCallBack = filter.messageCallBack;
          }
          if (filter.customFilterOperation) {
            newItem.customFilterOperation = filter.customFilterOperation;
          }
          if (filter.callBack) {
            newItem.callBack = filter.callBack;
          }
          if (filter.hideDelete) {
            newItem.hideDelete = filter.hideDelete;
          }
          if (filter.isTagFilter) {
            newItem.isTagFilter = filter.isTagFilter;
          }
          if (filter.operatorMessageCallBack) {
            newItem.operatorMessageCallBack = filter.operatorMessageCallBack;
          }
          if (filter.isRadioGroupStyle) {
            newItem.isRadioGroupStyle = filter.isRadioGroupStyle;
          }
        }
      });
    }
    if (newItem.callBack) {
      newItem.callBack(newItem.value);
    }
    onApply(newItem);
  };

  const onOperatorChange = (
    _: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
  ): void => {
    setFilterOperator(option?.key?.toString() as Operator);
  };

  let valueOptions: IDropdownOption<any>[] = [];
  if (defaultExtraFilter) {
    if (defaultExtraFilter.customOptions) {
      valueOptions = defaultExtraFilter.customOptions;
    } else {
      valueOptions = getFieldOptionsByName(
        items,
        defaultExtraFilter.fieldKey,
        defaultExtraFilter.messageCallBack,
      );
      if (defaultExtraFilter.isTagFilter) {
        valueOptions = getTagFieldValueOptionsByKey(
          items,
          defaultExtraFilter.fieldKey,
        );
      }
    }
    const allOption = valueOptions.find(item => item.key === FilterAll);
    if (!allOption && !defaultExtraFilter.hideAllOption) {
      valueOptions = [
        ...[{
          key: FilterAll,
          text: isEditMode ? messageCatalogAllFirstCapital : messageCatalogAllLowercase,
        }],
        ...valueOptions,
      ];
    }
  }

  React.useEffect(() => {
    if (Array.isArray(defaultExtraFilter?.value)) {
      setSelectedFilterValueKeys(defaultExtraFilter?.value);
    } else {
      const tempValue = valueOptions?.find(v => (v.key === defaultExtraFilter?.value))?.key?.toString();
      if (tempValue) {
        setDefaultSelectedFilterValue(tempValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultExtraFilter]);

  const getPillLabelValue = (): string => {
    let pillAllLabelOptions: IDropdownOption<any>[] = [];
    if (items && defaultExtraFilter) {
      if (defaultExtraFilter.customOptions) {
        pillAllLabelOptions = defaultExtraFilter.customOptions;
      } else {
        pillAllLabelOptions = getFieldOptionsByName(
          items,
          defaultExtraFilter.fieldKey,
          defaultExtraFilter.messageCallBack,
        );
        if (defaultExtraFilter.isTagFilter) {
          pillAllLabelOptions = getTagFieldValueOptionsByKey(
            items,
            defaultExtraFilter.fieldKey,
          );
        }
      }
    }
    let pillLabel = "";
    if (Array.isArray(defaultExtraFilter?.value)) {
      const pillLabelArr = defaultExtraFilter?.value.map(
        val => pillAllLabelOptions?.find(v => v.key === val)?.key.toString() || "",
      );
      if (pillLabelArr) {
        if (pillLabelArr.find(i => i === FilterAll)) {
          pillLabel = FilterAll;
        } else if (pillLabelArr.length === 1) {
          const returnTextKey = pillLabelArr[0];
          pillLabel = pillAllLabelOptions.find(option => option.key === returnTextKey)?.text || "";
          if (defaultExtraFilter && defaultExtraFilter.messageCallBack) {
            pillLabel = defaultExtraFilter.messageCallBack(pillLabel);
          }
        } else {
          pillLabel = Messages.hints.showSelection(pillLabelArr.length.toString());
        }
      }
    } else {
      pillLabel = pillAllLabelOptions?.find(v => v.key === defaultExtraFilter?.value)?.text || "";
      if (defaultExtraFilter && defaultExtraFilter.messageCallBack) {
        pillLabel = defaultExtraFilter.messageCallBack(pillLabel);
      }
    }

    return pillLabel;
  };

  const onRenderInputFieldLabel = (labelText: string): JSX.Element => {
    const returnElement = (
      <span
        className={
          `${classNames.InputFieldLabelOfModal}
          ${filterLayout === FilterLayout.DropDown
            ? classNames.InputFieldLabelOfDropDown
            : isEditMode
              ? classNames.InputFieldLabelOfEditModal
              : classNames.InputFieldLabelOfAddModal
          } `
        }
      >
        {labelText}
      </span>
    );
    return returnElement;
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [vOptions, setVOptions] = React.useState<IDropdownOption<any>[]>([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    if (filterName && filterNameFieldOptions && itemsInList) {
      let tempVal: IDropdownOption<any>[] = [];
      let tempHideAllOption = false;
      allExtraFilters?.forEach(filter => {
        if (filter.fieldKey === filterName.key) {
          tempVal = getFieldOptionsByName(items, filterName.key.toString(), filter.messageCallBack);
          if (filter.isTagFilter) {
            tempVal = getTagFieldValueOptionsByKey(
              items,
              filter.fieldKey,
            );
          }
          if (filter.customOptions) {
            tempVal = filter.customOptions;
          }
          if (filter.hideAllOption) {
            tempHideAllOption = filter.hideAllOption;
          }
        }
      });
      const allOption = tempVal.find(item => item.key === FilterAll);
      if (!allOption && !tempHideAllOption) {
        tempVal = [
          ...[{
            key: FilterAll,
            text: isEditMode ? messageCatalogAllFirstCapital : messageCatalogAllLowercase,
          }],
          ...tempVal,
        ];
      }
      setVOptions(tempVal);
    } else {
      setVOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName]);

  const onRenderMultiSelectTitle = (options: IDropdownOption[]): JSX.Element => {
    let returnText = "";
    if (selectedFilterValueKeys) {
      if (selectedFilterValueKeys.find(i => i === FilterAll)) {
        returnText = isEditMode ? messageCatalogAllFirstCapital : messageCatalogAllLowercase;
      } else if (selectedFilterValueKeys.length === 1) {
        const returnTextKey = selectedFilterValueKeys[0];
        returnText = options.find(option => option.key === returnTextKey)?.text || "";
      } else {
        returnText = Messages.hints.showSelection(selectedFilterValueKeys.length.toString());
      }
    }
    return (<span>{returnText}</span>);
  };

  const isMultiSelectOption = isEditMode
    ? defaultExtraFilter && Array.isArray(defaultExtraFilter.value)
    : Array.isArray(allExtraFilters?.find(i => i.fieldKey === filterName?.key)?.value);

  const isApplyActionDisabled = (): boolean => {
    let isDisable = false;
    if (isEditMode) {
      if (filterOperator && filterVal) {
        if (Array.isArray(filterVal) && filterVal.length <= 0) {
          isDisable = true;
        } else {
          isDisable = false;
        }
      } else {
        isDisable = true;
      }
    } else if (filterName && filterOperator && filterVal) {
      if (Array.isArray(filterVal) && filterVal.length <= 0) {
        isDisable = true;
      } else {
        isDisable = false;
      }
    } else {
      isDisable = true;
    }
    return isDisable;
  };

  const getFilterTitleOperatorMessage = (): string => {
    let operatorMessage = "";
    if (Operator.Equal === defaultExtraFilter?.operator) {
      operatorMessage = defaultExtraFilter?.operatorMessageCallBack?.(Operator.Equal)
        || Messages.common.equals().toLowerCase();
    } else if (Operator.Contains === defaultExtraFilter?.operator) {
      operatorMessage = defaultExtraFilter?.operatorMessageCallBack?.(Operator.Contains)
        || Messages.common.contains().toLowerCase();
    } else if (Operator.Custom === defaultExtraFilter?.operator) {
      operatorMessage = defaultExtraFilter?.operatorMessageCallBack?.(Operator.Custom)
        || "";
    }
    return operatorMessage;
  };

  const onCloseIconAction = (e: React.KeyboardEvent<HTMLElement> | React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    const isCloseKeyDownEvent = (
      e.target as HTMLDivElement
    ).matches(".pill-close-Button-icon-container")
      || (e.target as HTMLDivElement).matches(".pill-close-Button-icon");
    if (isCloseKeyDownEvent && onDelete && defaultExtraFilter) {
      onDelete(defaultExtraFilter);
    }
  };

  return (
    <>
      {filterLayout === FilterLayout.Pill && (
        <div
          data-test-id={buildFilterTestIds(defaultExtraFilter?.testId).component}
          id={buttonId}
          role="presentation"
        >
          {isEditMode ? (
            <DefaultButton
              data-test-id={buildFilterTestIds(defaultExtraFilter?.testId).pill}
              className={classNames.filterTitleTextContainer}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                const isCloseClickEvent = (e.target as HTMLDivElement).matches(".pill-close-Button-icon-container")
                  || (e.target as HTMLDivElement).matches(".pill-close-Button-icon");
                if (!isCloseClickEvent) {
                  e.preventDefault();
                  setVOptions([]);
                  setFilterName(undefined);
                  setSelectedFilterValueKeys(undefined);
                  setFilterVal(undefined);
                  if (isEditMode) {
                    const initValueKey = valueOptions?.find(v => v.key === defaultExtraFilter?.value)?.key.toString();
                    if (initValueKey) {
                      setDefaultSelectedFilterValue(initValueKey);
                    }
                    if (Array.isArray(defaultExtraFilter?.value)) {
                      const defaultExtraFilterValArr = defaultExtraFilter?.value || [];
                      if (defaultExtraFilterValArr.find(v => v === FilterAll)) {
                        setSelectedFilterValueKeys(valueOptions.map(i => i.key.toString()));
                        setFilterVal(valueOptions);
                      } else {
                        setSelectedFilterValueKeys(defaultExtraFilterValArr);
                        const selectedOptions = valueOptions.filter(o => {
                          if (defaultExtraFilterValArr.includes(o.key.toString())) {
                            return true;
                          }
                          return false;
                        });
                        setFilterVal(selectedOptions);
                      }
                      setEditModeSequenceValueOptions(
                        getEditModeSequenceValueOptions(
                          valueOptions,
                          defaultExtraFilter,
                          items,
                          currentExtraFilters,
                          currentFilterText || "",
                          textFilterCallBack,
                          columns || [],
                        ),
                      );
                      setEditModeTagNameKey(defaultExtraFilter?.fieldKey || "");
                    }
                  }
                  toggleIsCalloutVisible();
                }
              }}
            >
              {isEditMode && defaultExtraFilter?.isTagFilter && (
                <span className={classNames.isTagPillIconContainer}>
                  <span className={classNames.isTagPillIcon}>
                    <Icon iconName="pillTag-svg" />
                  </span>
                </span>
              )}
              <span>{defaultExtraFilter?.name}</span>
              <span className={classNames.filterTitleOperator}>
                {getFilterTitleOperatorMessage()}
              </span>
              <span className={classNames.filterTitleValue}>
                {
                  Array.isArray(defaultExtraFilter?.value)
                    ? defaultExtraFilter?.value.find(i => i === FilterAll)
                      ? messageCatalogAllLowercase : getPillLabelValue()
                    : FilterAll === defaultExtraFilter?.value ? messageCatalogAllLowercase : getPillLabelValue()
                }
              </span>
              {!defaultExtraFilter?.hideDelete ? (
                <span
                  className={`${classNames.filterTitleCloseIconContainer} pill-close-Button-icon-container`}
                  role="button"
                  tabIndex={0}
                  onKeyDown={
                    (e: React.KeyboardEvent<HTMLElement>) => {
                      if (e.key === "Enter") {
                        onCloseIconAction(e);
                      }
                    }
                  }
                  onClick={onCloseIconAction}
                >
                  <Icon className="pill-close-Button-icon" iconName="ChromeClose" />
                </span>
              ) : (
                <span className={classNames.filterTitleNoCloseIconContainer} />
              )}
            </DefaultButton>
          ) : allExtraFilters && allExtraFilters.length > 0 && (
            <DefaultButton
              className={classNames.AddFilterButtonContainer}
              onClick={() => {
                setVOptions([]);
                setFilterName(undefined);
                setSelectedFilterValueKeys(undefined);
                setFilterVal(undefined);
                toggleIsCalloutVisible();
              }}
            >
              <div className={classNames.AddFilterIconContainer}>
                <Icon iconName="filter-svg" />
              </div>
              <div
                className={classNames.AddFilterButtonText}
                role="presentation"
              >
                {Messages.actions.addFilter()}
              </div>
            </DefaultButton>
          )}
        </div>
      )}
      {filterLayout === FilterLayout.DropDown && isEditMode && (
        <div
          data-test-id={buildFilterTestIds(defaultExtraFilter?.testId).component}
          id={buttonId}
          role="presentation"
        >
          <div className={classNames.dropdownLayoutFilterValueContainer}>
            <Dropdown
              placeholder={Messages.hints.selectAnOption()}
              data-test-id={buildFilterTestIds(defaultExtraFilter?.testId).valueDropdown}
              onRenderLabel={() => onRenderInputFieldLabel(defaultExtraFilter?.name || "")}
              options={valueOptions}
              styles={dropdownLayoutDropdownStyles}
              onChange={onDropdownFilterValueOptionChange}
              defaultValue={valueOptions?.find(v => v.key === defaultExtraFilter?.value)?.key}
              defaultSelectedKey={defaultExtraFilter?.value}
              responsiveMode={ResponsiveMode.large}
            />
          </div>
        </div>
      )}
      {
        isCalloutVisible && (
          <FocusTrapCallout
            id={operationModalCalloutId}
            className={classNames.callout}
            ariaLabelledBy={labelId}
            ariaDescribedBy={descriptionId}
            gapSpace={0}
            target={`#${buttonId}`}
            onDismiss={toggleIsCalloutVisible}
          >
            <div data-test-id={buildFilterTestIds(defaultExtraFilter?.testId).callout}>
              {!defaultExtraFilter?.isRadioGroupStyle && (
                <div className={classNames.callOutTitle}>
                  {(isEditMode && defaultExtraFilter?.isTagFilter)
                    ? Messages.actions.editTag()
                    : title || Messages.actions.addFilter()}
                </div>
              )}
              {!isEditMode && (
                <div className={classNames.filterNameContainerAddButton}>
                  <Dropdown
                    placeholder={Messages.hints.selectAnOption()}
                    onRenderLabel={() => onRenderInputFieldLabel(Messages.common.filter())}
                    options={filterNameFieldOptions}
                    styles={dropdownStylesAddButton}
                    onChange={onFilterNameChange}
                    required
                    responsiveMode={ResponsiveMode.large}
                  />
                </div>
              )}
              {(isEditMode && defaultExtraFilter?.isTagFilter) && (
                <div className={classNames.filterNameContainerAddButton}>
                  <Dropdown
                    placeholder={Messages.hints.selectAnOption()}
                    onRenderLabel={() => onRenderInputFieldLabel(Messages.common.tag())}
                    options={getEditModeTagOptions()}
                    styles={dropdownStyles}
                    onChange={onEditModeTagOptionChange}
                    selectedKey={editModeTagNameKey}
                    required
                    responsiveMode={ResponsiveMode.large}
                  />
                </div>
              )}
              {!(defaultExtraFilter?.operator === Operator.Custom) && !defaultExtraFilter?.isRadioGroupStyle
                && (
                  <div className={
                    isEditMode
                      ? classNames.operatorSelectContainer : classNames.operatorSelectContainerAddButton
                  }
                  >
                    <Dropdown
                      placeholder={Messages.hints.selectAnOption()}
                      onRenderLabel={() => onRenderInputFieldLabel(Messages.common.operator())}
                      options={[
                        { key: Operator.Equal, text: Messages.common.equals() },
                      ]}
                      styles={isEditMode ? dropdownStyles : dropdownStylesAddButton}
                      onChange={onOperatorChange}
                      defaultSelectedKey={isEditMode ? defaultExtraFilter?.operator : Operator.Equal}
                      required
                      responsiveMode={ResponsiveMode.large}
                    />
                  </div>
                )}
              <div className={
                isEditMode
                  ? classNames.filterValueContainer : classNames.filterValueContainerAddButton
              }
              >
                {!isMultiSelectOption && (
                  <>
                    {
                      defaultExtraFilter?.isRadioGroupStyle && (
                        <ChoiceGroup
                          data-test-id={buildFilterTestIds(defaultExtraFilter?.testId).valueDropdown}
                          defaultSelectedKey={isEditMode ? defaultSelectedFilterValue : undefined}
                          options={buildRadioOptions(isEditMode ? valueOptions : vOptions)}
                          onChange={onPillFilterValueRadioOptionChange}
                          required
                        />
                      )
                    }
                    {
                      !defaultExtraFilter?.isRadioGroupStyle && (
                        <Dropdown
                          data-test-id={buildFilterTestIds(defaultExtraFilter?.testId).valueDropdown}
                          placeholder={Messages.hints.selectAnOption()}
                          onRenderLabel={() => onRenderInputFieldLabel(Messages.common.value())}
                          options={isEditMode ? valueOptions : vOptions}
                          styles={isEditMode ? dropdownStyles : dropdownStylesAddButton}
                          onChange={onPillFilterValueOptionChange}
                          defaultSelectedKey={isEditMode ? defaultSelectedFilterValue : undefined}
                          required
                          responsiveMode={ResponsiveMode.large}
                        />
                      )
                    }
                  </>
                )}
                {isMultiSelectOption && !isEditMode
                  && (
                    <Dropdown
                      placeholder={Messages.hints.selectAnOption()}
                      onRenderLabel={() => onRenderInputFieldLabel(Messages.common.value())}
                      options={
                        getAddModeSequenceValueOptions(
                          filterName,
                          vOptions,
                          items,
                          currentExtraFilters,
                          currentFilterText || "",
                          textFilterCallBack,
                          columns || [],
                          allExtraFilters,
                        )
                      }
                      styles={dropdownStylesAddButton}
                      onChange={onAddModePillFilterValueMultiOptionChange}
                      selectedKeys={selectedFilterValueKeys}
                      multiSelect={isMultiSelectOption}
                      onRenderTitle={onRenderMultiSelectTitle as IRenderFunction<IDropdownOption<any>[]>}
                      required
                      responsiveMode={ResponsiveMode.large}
                    />
                  )}
                {isMultiSelectOption && isEditMode
                  && (
                    <FilterPillValueSelector
                      key={pillValueSelectorKey}
                      label={Messages.common.value()}
                      placeholder={defaultExtraFilter?.isTagFilter
                        ? Messages.hints.filterPlaceholder()
                        : defaultExtraFilter?.name
                          ? Messages.hints.searchForField(defaultExtraFilter.name.toLowerCase())
                          : ""}
                      options={editModeSequenceValueOptions || []}
                      dataItems={items}
                      textFilterCallBack={textFilterCallBack}
                      currentExtraFilterKey={
                        defaultExtraFilter?.isTagFilter
                          ? editModeTagNameKey
                          : defaultExtraFilter?.fieldKey || ""
                      }
                      currentExtraFilters={
                        defaultExtraFilter?.isTagFilter
                          ? editModeTagCurrentExtraFilters : currentExtraFilters
                      }
                      currentFilterText={currentFilterText}
                      dataColumns={columns || []}
                      defaultSelectedKeys={selectedFilterValueKeys || []}
                      setSelectedKeysCallBack={(value: string[]) => {
                        setSelectedFilterValueKeys(value);
                        let tempArr: IDropdownOption<any>[] = [];
                        let isAllSelected = true;
                        const valueWithAll = [FilterAll].concat(value);
                        (editModeSequenceValueOptions || []).forEach(o => {
                          if (!(valueWithAll.includes(o.key.toString()))) {
                            isAllSelected = false;
                          }
                        });
                        if (isAllSelected) {
                          tempArr = [{ key: FilterAll, text: Messages.common.all() } as IDropdownOption]
                            .concat(editModeSequenceValueOptions || []);
                        } else {
                          value.forEach(
                            v => {
                              const temp = (editModeSequenceValueOptions || []).find(
                                option => option.key.toString() === v,
                              );
                              if (temp) {
                                tempArr.push(temp);
                              }
                            },
                          );
                        }
                        setFilterVal(tempArr);
                      }}
                    />
                  )}
              </div>
              <div className={classNames.actionButtons}>
                <PrimaryButton
                  data-test-id={buildFilterTestIds(defaultExtraFilter?.testId).calloutApplyButton}
                  className={classNames.applyButton}
                  text={Messages.actions.apply()}
                  disabled={isApplyActionDisabled()}
                  onClick={() => {
                    if (Array.isArray(filterVal)) {
                      const valArr = filterVal?.map(fv => {
                        if (FilterAll === fv?.key?.toString()) {
                          return FilterAll;
                        }
                        return fv.key.toString() || "";
                      });
                      onApplyAction(valArr);
                    } else {
                      const val = (FilterAll === filterVal?.key?.toString())
                        ? FilterAll : filterVal?.key?.toString() || "";
                      onApplyAction(val);
                    }
                    toggleIsCalloutVisible();
                  }}
                />
                <DefaultButton
                  className={classNames.cancelButton}
                  text={Messages.common.cancel()}
                  onClick={toggleIsCalloutVisible}
                />
              </div>
            </div>
          </FocusTrapCallout>
        )
      }
    </>
  );
};
