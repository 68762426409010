import * as React from "react";
import { CommandButton, IButtonProps, IContextualMenuProps } from "@fluentui/react";

export const CustomOverflowButton = ({ menuProps, ...rest }: IButtonProps): JSX.Element => {
  const customMenuProps = { ...menuProps, isBeakVisible: false } as IContextualMenuProps;

  return (
    <CommandButton
      menuProps={customMenuProps}
      {...rest}
    />
  );
};
