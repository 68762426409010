import * as React from "react";
import {
  ICalloutProps,
  IconButton,
  IIconProps,
  IStackStyles,
  ITooltipHostStyles,
  Link,
  mergeStyleSets,
  Shimmer,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import * as Messages from "../../codegen/Messages";
import { ColorTextPrimary, ColorTextSecondary } from "../../constants/uiConstants";
import {
  LabelMetaItemLayoutType,
  MetaItemGroupsLayoutConfig,
  MetaItemGroupsLayoutContext,
} from "./MetaItemInternalTypes";
import { buildLabelMetaItemTestIds, LabelMetaItemProps } from "./MetaItemTypes";

const classNames = mergeStyleSets({
  button: {
    display: "table",
    boxSizing: "border-box",
    minWidth: 0,
    height: "20px",
    width: "20px",
    margin: "2px",
    fontSize: "13px",
    lineHeight: 1.385,
    position: "absolute",
  },
  icon: {
    boxSizing: "border-box",
    textAlign: "center",
    verticalAlign: "middle",
    width: "12px !important",
    height: "12px !important",
    padding: "0 important",
    color: "rgb(0,0,0)",
    marginTop: "-5px !important",
    fontSize: "13px !important",
  },
});

const labelMetaItemStackStyle: IStackStyles = { root: { border: "1px" } };

/**
 * The LabelMetaItem renders a text or component with an option to display as a
 * hyperlink
*/

export const LabelMetaItem = ({
  label,
  children,
  action,
  hideSeparator = false,
  loading,
  testId,
}: LabelMetaItemProps): JSX.Element => {
  const { labelWidth, labelLayoutType } = React.useContext<MetaItemGroupsLayoutConfig>(MetaItemGroupsLayoutContext);

  const [copied, setCopied] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const tooltipId = useId("tooltip");
  const buttonId = useId("targetButton");

  const showElement = isHovered ? 1 : 0;
  const calloutProps: ICalloutProps = { gapSpace: 0, target: `#${buttonId}` };
  const iconProps: IIconProps = { iconName: "Copy", "aria-describedby": tooltipId, className: classNames.icon };
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: {
      display: "flex",
      flex: "auto",
      maxWidth: "24px",
      width: "20px",
      opacity: showElement,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const copyLabelMetaItemValue = (event): void => {
    if (!copied) setCopied(true);
    if (typeof children !== "string") navigator.clipboard.writeText(children.props.clipboardValue);
    else navigator.clipboard.writeText(children);
  };

  const showCopy = (): void => {
    setIsHovered(true);
  };

  const hideCopy = (): void => {
    setIsHovered(false);
    if (copied) setCopied(false);
  };

  const actionLink = action
    ? (
      <Text id={action.id}>
        (
        <Link
          data-test-id={action.testId}
          onClick={action.onClick}
          disabled={action.disabled}
          underline
        >
          {action.label.toLowerCase()}
        </Link>
        )
      </Text>
    )
    : undefined;

  const separatorMargin = 10;
  const separatorWidth = 2 * separatorMargin + 4;
  const itemLabelWidth = labelWidth || 120;
  const itemLabelCssWidth = labelLayoutType === LabelMetaItemLayoutType.WIDE ? `${itemLabelWidth}px` : undefined;
  const itemValueCssWidth = labelLayoutType === LabelMetaItemLayoutType.WIDE
    ? `calc(100% - ${itemLabelWidth + (hideSeparator ? 0 : separatorWidth)}px)`
    : undefined;

  const clipboardCopy = (): JSX.Element => (
    <TooltipHost
      content={copied ? Messages.actions.copiedToClipboard() : Messages.actions.copyToClipboard()}
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
    >
      <IconButton
        id={buttonId}
        onClick={copyLabelMetaItemValue}
        className={classNames.button}
        iconProps={iconProps}
        aria-describedby={tooltipId}
        styles={{ root: { opacity: showElement } }}
      />
    </TooltipHost>
  );

  return (
    <Stack
      data-test-id={buildLabelMetaItemTestIds(testId).component}
      horizontal={labelLayoutType === LabelMetaItemLayoutType.WIDE}
      styles={labelMetaItemStackStyle}
    >
      <Stack horizontal>
        <span
          style={{
            width: itemLabelCssWidth,
            minWidth: itemLabelCssWidth,
            wordBreak: "break-word",
          }}
        >
          <Text
            data-test-id={buildLabelMetaItemTestIds(testId).label}
            style={{ color: ColorTextSecondary }}
          >
            {label}
          </Text>
          {" "}
          {actionLink}
        </span>
        {labelLayoutType === LabelMetaItemLayoutType.WIDE && (
          <span
            style={{
              textAlign: "center",
              margin: `0px ${separatorMargin}px`,
            }}
          >
            <Text style={{ color: ColorTextSecondary }}>{!hideSeparator ? ":" : ""}</Text>
          </span>
        )}
      </Stack>
      <span
        style={{
          width: itemValueCssWidth,
          minWidth: itemValueCssWidth,
        }}
      >
        <Shimmer
          styles={{ dataWrapper: { position: "relative", top: 0 } }}
          width="100%"
          isDataLoaded={loading === undefined ? true : loading}
        >
          <div data-test-id={buildLabelMetaItemTestIds(testId).value}>
            {(typeof children === "string")
              ? (
                <div
                  style={{ display: "flex" }}
                  onMouseEnter={showCopy}
                  onMouseLeave={hideCopy}
                  onFocus={showCopy}
                  onBlur={hideCopy}
                >
                  <Text
                    style={{
                      color: ColorTextPrimary,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    title={children}
                  >
                    {children}
                  </Text>
                  {children !== "" && clipboardCopy()}
                </div>
              )
              : children}
          </div>
        </Shimmer>
      </span>
    </Stack>
  );
};
