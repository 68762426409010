import * as React from "react";
import { favoriteAccessManager } from "./FavoriteAccessManager";
import { FavoriteAccessItem } from "./FavoriteAccessTypes";

export interface FavoriteAccessHandlers {
  favoriteItems: FavoriteAccessItem[];
  setAsFavorite: (item: FavoriteAccessItem) => void;
  isFavorite: (itemId: string) => boolean;
  removeFromFavorites: (itemId: string) => void;
}

const setAsFavorite = (item: FavoriteAccessItem): void => favoriteAccessManager.addItem(item);
const isFavorite = (itemId: string): boolean => favoriteAccessManager.isFavorite(itemId);
const removeFromFavorites = (itemId: string): void => favoriteAccessManager.clearItem(itemId);

export const useFavoriteAccess = (): FavoriteAccessHandlers => {
  // Fetch the list of favorite items upon initialization
  const [favoriteItems, setFavoriteItems] = React.useState<FavoriteAccessItem[]>(favoriteAccessManager.items);

  React.useEffect(() => {
    // Register a listener callback upon mounting
    const listenerId = favoriteAccessManager.addListener((items: FavoriteAccessItem[]) => {
      // Update the list of items everytime the listener is invoked with the modified list
      setFavoriteItems(items);
    });

    // Unregister the listener callback upon unmounting
    return () => favoriteAccessManager.removeListener(listenerId);
  }, []);

  return {
    favoriteItems,
    setAsFavorite,
    isFavorite,
    removeFromFavorites,
  };
};
