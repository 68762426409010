/* eslint-disable no-shadow */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import * as IndexDB from "localforage";
import { timeoutPromise } from "../utils/internalUtils";
import { TelemetryClientData } from "../models";
import { TelemetryClient } from "../clients/TelemetryClient";

const INDEXEDDB_TIME_OUT_ERROR = "INDEXEDDB_OPEN_TIMEOUT";
const DB_NAME = "telemetry-client";
const DB_VERSION = 1;

export default class IndexedDBHandler<T> {
  private indexDB: LocalForage;
  private static indexedDBTimeoutInMilliseconds = 10 * 1000;

  /**
   * constructor for IndexedDBHandler
   * @param storeName Name of the database
   */
  constructor(storeName: string) {
    this.indexDB = IndexedDBHandler.getDBInstance(DB_NAME, storeName, DB_VERSION);
  }

  private static getDBInstance(dbName: string, storeName: string, version: number) {
    return IndexDB.createInstance({
      driver: IndexDB.INDEXEDDB,
      name: dbName,
      storeName,
      version,
    });
  }

  /**
   * Ideally we should create a test database and drop it.
   * However localforage doesn't support dropping a database yet,
   * pending PR https://github.com/localForage/localForage/pull/708
   * Here we will always create a "test" store under "duplo" database just for testing
   *
   * @returns A boolean to indicate if IndexedDB is missing or a specific issue with IndexedDB
   */
  static async testIndexedDB(dbName: string, storeName: string, version: number): Promise<boolean> {
    const DB = IndexedDBHandler.getDBInstance(dbName, storeName, version);
    try {
      await timeoutPromise(
        DB.ready(),
        new Error(INDEXEDDB_TIME_OUT_ERROR),
        IndexedDBHandler.indexedDBTimeoutInMilliseconds,
      );
      // Need to add some dummy data before testing the driver.
      DB.setItem("test", true);
      const driver = DB.driver();
      if (driver !== IndexDB.INDEXEDDB) {
        return false;
      }
    } catch (err) {
      return false;
    }
    return true;
  }

  /**
   * Save the data in IndexedDB, key would be the nonce and value would be telemetryClientData
   * @param nonce
   * @param telemetryClientData: log/metric + TTL
   */
  async setItem(nonce: string, telemetryClientData: TelemetryClientData<T>): Promise<void> {
    if (TelemetryClient.isIndexedDBAvailable) {
      await this.indexDB.setItem(nonce, telemetryClientData);
    }
  }

  /**
   * Get the record stored in the DB corresponding to the specified nonce
   * @param nonce
   */
  async getItem(nonce: string): Promise<TelemetryClientData<T>> {
    if (TelemetryClient.isIndexedDBAvailable) {
      return this.indexDB.getItem(nonce) as Promise<TelemetryClientData<T>>;
    }
    return null;
  }

  /**
   * clear all the records from IndexedDB
   */
  async clearDb(): Promise<void> {
    if (TelemetryClient.isIndexedDBAvailable) {
      await this.indexDB.clear();
    }
  }

  /**
   * return number of records present in IndexedDB
   */
  async getLength(): Promise<number> {
    if (TelemetryClient.isIndexedDBAvailable) {
      return this.indexDB.length();
    }
    return 0;
  }

  /**
   * fetch all the records stored in IndexedDB and return as a list
   */
  async getRecords() {
    const records: TelemetryClientData<T>[] = [];
    if (TelemetryClient.isIndexedDBAvailable) {
      const keys: string[] = await this.indexDB.keys();
      for (const key of keys) {
        const indexDBData = await this.getItem(key);
        if (indexDBData && indexDBData.TTL && new Date().getTime() <= indexDBData.TTL) {
          records.push(indexDBData);
        }
        await this.indexDB.removeItem(key);
      }
    }
    return records;
  }
}
