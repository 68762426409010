/* eslint-disable no-await-in-loop */
import Producer from "./Producer";
import Timer from "../common/Timer";

export default class Consumer<T> {
  public producer: Producer<T>;
  private timer: Timer;
  private isCallInProgress = false;

  /**
   * constructor consumer
   * @param timeInterval time after which we call telemetry service
   * @param batchSize maximum number of records in a batch
   * @param fn function to be executed either when the time expire or the producer notify the the batch is ready
   */
  public constructor(
    timeInterval: number,
    private batchSize: number,
    private fn: (items: Array<T>) => void | Promise<void>,
  ) {
    this.timer = new Timer(timeInterval, this.consumeRecords.bind(this));
    this.producer = new Producer(this.consumeRecords.bind(this), this.batchSize);
    this.timer.start();
  }

  /**
   * function to be execute when the timer expire or the batch is full
   */
  private async consumeRecords() {
    if (this.isCallInProgress || !this.producer.bufferSize()) {
      return;
    }
    this.isCallInProgress = true;
    this.timer.stop();
    do {
      try {
        await this.fn(this.producer.getBatch());
      } catch (e) {
        console.error("Unhandled exception on fn", e);
      }
    } while (this.producer.bufferSize() >= this.batchSize);
    this.timer.start();
    this.isCallInProgress = false;
  }
}
