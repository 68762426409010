import * as React from "react";
import { mergeStyles } from "@fluentui/react";

const progressTranslucentClass = mergeStyles({
  height: "100%",
  overflow: "hidden",
});

const centerLoading5dotsContainerClass = mergeStyles({
  marginLeft: 5,
  width: "calc(100% - 5px)",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
});

const leftLoading5dotsContainerClass = mergeStyles({
  marginLeft: 5,
  width: "50%",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "start",
});

const loadingDotClass = mergeStyles({
  flex: "0 0 auto",
  position: "relative",
  animation: "loading 3s cubic-bezier(0,.95,1,.05) infinite,loading-opacity 1.5s ease-in-out infinite alternate",
  animationFillMode: "both",
  marginLeft: 6,
  height: 4,
  width: 4,
  backgroundColor: "black",
});

const loadingDot1Animation = mergeStyles({ animationDelay: ".2s" });
const loadingDot2Animation = mergeStyles({ animationDelay: ".1s" });
const loadingDot4Animation = mergeStyles({ animationDelay: "-.1s" });
const loadingDot5Animation = mergeStyles({ animationDelay: "-.2s" });

export enum DotsJustification {
  LEFT = "LEFT",
  CENTER = "CENTER",
}

export interface InProgressIndicatorProps {
  justification: DotsJustification;
}

export const InProgressIndicator = ({ justification }: InProgressIndicatorProps): JSX.Element => {
  let containerClass;
  switch (justification) {
    case DotsJustification.CENTER:
      containerClass = centerLoading5dotsContainerClass;
      break;
    case DotsJustification.LEFT:
    default:
      containerClass = leftLoading5dotsContainerClass;
      break;
  }
  return (
    <div className={`${progressTranslucentClass} ${containerClass}`}>
      <div className={`${loadingDotClass} ${loadingDot1Animation}`} />
      <div className={`${loadingDotClass} ${loadingDot2Animation}`} />
      <div className={`${loadingDotClass}`} />
      <div className={`${loadingDotClass} ${loadingDot4Animation}`} />
      <div className={`${loadingDotClass} ${loadingDot5Animation}`} />
    </div>
  );
};
